import React from "react"
import { ReactComponent as GenerateIndexIcon } from "../../Images/svg/GenerateIndex.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const GenerateIndex = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <GenerateIndexIcon />
  </SvgIcon>
)

export default GenerateIndex