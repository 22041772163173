import React from "react"
import { ReactComponent as ExporttoListIcon } from "../../Images/svg/ExporttoList.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ExporttoList = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <ExporttoListIcon />
  </SvgIcon>
)

export default ExporttoList