export type DateFormatDisplayType = 'American (MM/DD/YYYY)' | 'European (DD/MM/YYYY)' | 'UTC (YYYY/MM/DD) American (MM/DD/YYYY)' |
    'American with dashes (MM-DD-YYYY)' | 'European with dashes (DD-MM-YYYY)' | 'UTC with dashes (YYYY-MM-DD)'
export const DateFormatDisplay = {
    American: 'American (MM/DD/YYYY)' as DateFormatDisplayType,
    European: 'European (DD/MM/YYYY)' as DateFormatDisplayType,
    UTC: 'UTC (YYYY/MM/DD)' as DateFormatDisplayType,
    AmericanWithDashes: 'American with dashes (MM-DD-YYYY)' as DateFormatDisplayType,
    EuropeanWithDashes: 'European with dashes (DD-MM-YYYY)' as DateFormatDisplayType,
    UTCWithDashes: 'UTC with dashes (YYYY-MM-DD)' as DateFormatDisplayType
}

export type DateFormatValueType = 'MM/dd/yyyy' | 'dd/MM/yyyy' | 'yyyy/MM/dd' | 'MM-dd-yyyy' | 'dd-MM-yyyy' | 'yyyy-MM-dd'
export const DateFormatValue = {
    American: 'MM/dd/yyyy' as DateFormatValueType,
    European: 'dd/MM/yyyy' as DateFormatValueType,
    UTC: 'yyyy/MM/dd' as DateFormatValueType,
    AmericanWithDashes: 'MM-dd-yyyy' as DateFormatValueType,
    EuropeanWithDashes: 'dd-MM-yyyy' as DateFormatValueType,
    UTCWithDashes: 'yyyy-MM-dd' as DateFormatValueType
}

export const DateFormatModel = {
    [DateFormatDisplay.American]: DateFormatValue.American,
    [DateFormatDisplay.European]: DateFormatValue.European,
    [DateFormatDisplay.UTC]: DateFormatValue.UTC,
    [DateFormatDisplay.AmericanWithDashes]: DateFormatValue.AmericanWithDashes,
    [DateFormatDisplay.EuropeanWithDashes]: DateFormatValue.EuropeanWithDashes,
    [DateFormatDisplay.UTCWithDashes]: DateFormatValue.UTCWithDashes
}

export default DateFormatModel
