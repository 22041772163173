import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../HelpPageStyles'
import ChartSettingsSection from './AdvancedGraphingSections/ChartSettingsSection'
import TechnicalIndicatorsSection from './AdvancedGraphingSections/TechnicalIndicatorsSection'
import ChronologySection from './AdvancedGraphingSections/ChronologySection'
import ComparisonSection from './AdvancedGraphingSections/ComparisonSection'
import ColorCustomization from './AdvancedGraphingSections/ColorCustomization'

const AdvancedGraphing = () => {
    return (
        <>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Advanced Graphing with Options</h2>
                </div>
            </Box>
            <p style={{ textAlign: 'left' }}>This section will describe how to use the advanced graphing features.</p>
            <div style={{ height: '486px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphing.png' style={helpPageStyles.image} alt='Advanced Graphing Intro'></img>
            </div>

            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Opening Chart Options</h4>
                </div>
            </Box>
            <div style={{ height: '199px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingOptions.png' style={helpPageStyles.image} alt='Advanced Graphing Gear Button'></img>
            </div>

            <p style={{ textAlign: 'left' }}>To get started with Advanced Graphing, click on the gear button. This will display a chart options modal.</p>
            <ChartSettingsSection />
            <ComparisonSection />
            <TechnicalIndicatorsSection />
            <ChronologySection />
            <ColorCustomization />
        </>
    )
}

export default AdvancedGraphing
