import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useState } from 'react'
import styled from '@emotion/styled'
import { CustomBtnBlue, CustomBtnCloseCancel, CustomToggle } from '../GlobalSettings/CustomStyles'

const HomeSearchOptionModal = ({ modalProps }: any) => {

  const [localFilter, setLocalFilter] = useState<string>(modalProps.filterAndSort.filterOption ?? 'contains')
  const [localSort, setLocalSort] = useState<string>(modalProps.filterAndSort.sortOption ?? 'popular')


  const handleClose = () => {
    modalProps.setShowModal(false)
  }
  const handleSave = () => {
    //Do a search quick
    modalProps.setShowModal(false)
    modalProps.filterAndSort.setFilterOption(localFilter)
    modalProps.filterAndSort.setSortOption(localSort)

  }
  const handleSetSearchOption = (event: React.MouseEvent<HTMLElement>, newFilterOption: string) => {
    if (newFilterOption) {
      setLocalFilter(newFilterOption)
    }
  }
  const handleSetSortOption = (event: React.MouseEvent<HTMLElement>, newSortOption: string) => {
    if (newSortOption) {
      setLocalSort(newSortOption)
    }
  }



  return (
    <>
      <Modal show={modalProps.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Search Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToggleButtonGroup
            color='primary'
            value={localFilter}
            exclusive
            onChange={handleSetSearchOption}
            sx={{ paddingBottom: 1 }}>
            <CustomToggle value='contains'>Contains</CustomToggle>
            <CustomToggle value='startsWith'>Starts With</CustomToggle>
            <CustomToggle value='exactMatch'>Exact Match</CustomToggle>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            color='primary'
            value={localSort}
            exclusive
            onChange={handleSetSortOption}
            sx={{ paddingBottom: 1 }}>
            <CustomToggle value='popular'>Top GFD Downloads First</CustomToggle>
            <CustomToggle value='alphabetical'>Sort Alphabetically</CustomToggle>
          </ToggleButtonGroup>

        </Modal.Body>
        <Modal.Footer>
          <CustomBtnCloseCancel variant= 'outlined' onClick={handleClose}>
            Close
          </CustomBtnCloseCancel>
          <CustomBtnBlue variant='contained' onClick={handleSave}>
            Save Changes
          </CustomBtnBlue>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default HomeSearchOptionModal