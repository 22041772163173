export type EquityFieldType = 'Shares Outstanding' | 'Market Cap' | 'EPS' |
    'P/E Ratio' | 'Dividend Yield'
export const EquityField = {
    SharesOutstanding: 'Shares Outstanding' as EquityFieldType,
    MarketCap: 'Market Cap' as EquityFieldType,
    EPS: 'EPS' as EquityFieldType,
    PERatio: 'P/E Ratio' as EquityFieldType,
    DividendYield: 'Dividend Yield' as EquityFieldType
}

export interface EquityFields {
    SharesOutstanding: boolean
    MarketCap: boolean
    EPS: boolean
    PERatio: boolean
    DividendYield: boolean
}

export default EquityField
