import { Box, Button, Grid, TextField } from "@mui/material"
import React, { useState } from "react"
import { GFDToastError, GFDToastSuccess } from "../Common/Utility/GFDToastify"
import { resetForgotPassword, confirmResetForgotPassword } from "../../Services/AuthenticationService"
import { ConfirmResetForgotPasswordRequest, LogoutReasonType, ResetForgotPasswordRequest } from "../../Models/DataModels/Requests/AuthRequests"
import { ResetForgotPasswordResultCodes } from "../../Models/DataModels/Common/AccountModel"
import { UnorderedMultiErrorLines } from "../Common/Utility/MultiLines"
import { ErrorHandler, ErrorHandlerProps } from "../Common/Utility/ErrorHandler"
import { NotOKResponseModel } from "../../Models/DataModels/Common/NotOKResponseModel"
import { ResetForgotPasswordResult } from "../../Models/DataModels/Responses/AccountResponses"
import { useNavigate } from "react-router"
import { paths } from "../../Models/DataModels/Common/RedirectionModel"


export interface ResetForgotPasswordProps {
    signOut: (logoutReason: LogoutReasonType) => void
}


const ResetForgotPassword = ({ signOut }: ResetForgotPasswordProps) => {

    const [newPassword, setNewPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const [isNewPassError, setIsNewPassError] = useState<boolean>(false)
    const [isConfirmPassError, setIsConfirmPassError] = useState<boolean>(false)

    const [errorLinesNewPassword, setErrorTextNewPassword] = useState<string[]>([])
    const [errorLinesConfirmPassword, setErrorTextConfirmPassword] = useState<string[]>([])

    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()

    const ResetForgotPasswordMessages = {
        Success: 'Password Updated. Use your new password next time you log in.',
        OldNewPasswordMatch: 'Your new password cannot match your old password.',
        NewConfirmPasswordMismatch: 'New Password and Confirm Password do not match',
        InvalidPassword_LessThan6Chars: 'Password must have at least 6 characters',
        InvalidPassword_GreaterThan256Chars: 'Password cannot be more than 256 characters long',
        InvalidPassword_NoUpperCase: 'Password must have at least one uppercase letter',
        InvalidPassword_NoLowerCase: 'Password must have at least one lowercase letter',
        InvalidPassword_NoNumber: 'Password must have at least one number',
        InvalidPassword_NoSpecialChar: 'Password must have at least one special character @$!*?&)',
        Failure: 'Error occurred'
    }


    const navigate = useNavigate()

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    const validateChangePassword = (newPW: string, newConfirmPW: string) => {
        let validationResult: ResetForgotPasswordResultCodes = ResetForgotPasswordResultCodes.Success

        if (newPW !== newConfirmPW) {
            validationResult |= ResetForgotPasswordResultCodes.NewConfirmPasswordMismatch
        }

        let passwordRequirementsRegex: RegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!*?&])[A-Za-z0-9@$!*?&]{6,256}$')

        if (!passwordRequirementsRegex.test(newPW)) {
            validationResult |= ResetForgotPasswordResultCodes.InvalidPassword
        }

        return validationResult
    }

    const resetErrorState = () => {
        setIsNewPassError(false)
        setIsConfirmPassError(false)
        setErrorTextNewPassword([])
        setErrorTextConfirmPassword([])
    }

    const displayErrorMessages = (validationResult: ResetForgotPasswordResultCodes, inputNewPassword: string) => {
        if ((validationResult & ResetForgotPasswordResultCodes.Failure) === ResetForgotPasswordResultCodes.Failure) {
            GFDToastError(ResetForgotPasswordMessages.Failure)
            return
        }

        const newPassErrors: string[] = []
        const confirmPassErrors: string[] = []

        if ((validationResult & ResetForgotPasswordResultCodes.OldNewPasswordMatch) === ResetForgotPasswordResultCodes.OldNewPasswordMatch) {
            newPassErrors.push(ResetForgotPasswordMessages.OldNewPasswordMatch)
        }
        if ((validationResult & ResetForgotPasswordResultCodes.NewConfirmPasswordMismatch) === ResetForgotPasswordResultCodes.NewConfirmPasswordMismatch) {
            confirmPassErrors.push(ResetForgotPasswordMessages.NewConfirmPasswordMismatch)
        }
        if ((validationResult & ResetForgotPasswordResultCodes.InvalidPassword) === ResetForgotPasswordResultCodes.InvalidPassword) {
            if (inputNewPassword.length < 6) {
                newPassErrors.push(ResetForgotPasswordMessages.InvalidPassword_LessThan6Chars)
            }
            if (inputNewPassword.length > 256) {
                newPassErrors.push(ResetForgotPasswordMessages.InvalidPassword_GreaterThan256Chars)
            }
            const atLeastOneUpper: RegExp = new RegExp('[A-Z]+')
            if (!atLeastOneUpper.test(inputNewPassword)) {
                newPassErrors.push(ResetForgotPasswordMessages.InvalidPassword_NoUpperCase)
            }
            const atLeastOneLower: RegExp = new RegExp('[a-z]+')
            if (!atLeastOneLower.test(inputNewPassword)) {
                newPassErrors.push(ResetForgotPasswordMessages.InvalidPassword_NoLowerCase)
            }
            const atLeastOneNumber: RegExp = new RegExp('[0-9]+')
            if (!atLeastOneNumber.test(inputNewPassword)) {
                newPassErrors.push(ResetForgotPasswordMessages.InvalidPassword_NoNumber)
            }
            const atLeastOneSpecialChar: RegExp = new RegExp('[@$!*?&]+')
            if (!atLeastOneSpecialChar.test(inputNewPassword)) {
                newPassErrors.push(ResetForgotPasswordMessages.InvalidPassword_NoSpecialChar)
            }
        }

        if (newPassErrors.length > 0) {
            setIsNewPassError(true)
        }
        if (confirmPassErrors.length > 0) {
            setIsConfirmPassError(true)
        }

        setErrorTextNewPassword(newPassErrors)
        setErrorTextConfirmPassword(confirmPassErrors)
    }

    const handleInputChange = (newPW: string, newConfirmPW: string) => {
        resetErrorState()
        let validationResult: ResetForgotPasswordResultCodes = validateChangePassword(newPW, newConfirmPW)
        if (validationResult === ResetForgotPasswordResultCodes.Success) {
            resetErrorState()
        } else {
            displayErrorMessages(validationResult, newPW)
        }
    }

    const handleNewPasswordChange = (updatedNewPassword: string, confirmPW: string) => {
        handleInputChange(updatedNewPassword, confirmPW)
        setNewPassword(updatedNewPassword)
    }

    const handleConfirmPasswordChange = (newPW: string, updatedConfirmPassword: string) => {
        handleInputChange(newPW, updatedConfirmPassword)
        setConfirmPassword(updatedConfirmPassword)
    }

    const getToken = () => {
        let toPop = window.location.href.split('/').pop()
        if (!toPop) {
            GFDToastError('Something went wrong. Please resubmit your email in the Forgot Password page.')
        }

        return toPop
    }

    const token = getToken()

    const submitResetPassword = () => {
        if (!newPassword || !confirmPassword || !token) {
            return
        } else {
            const resetForgotPasswordReq: ResetForgotPasswordRequest = { newPassword: newPassword, newPasswordConfirmation: confirmPassword, resetToken: token }
            resetForgotPassword(resetForgotPasswordReq).then((result: ResetForgotPasswordResult) => {
                const resultCode: ResetForgotPasswordResultCodes = result.resultCode
                if (resultCode === ResetForgotPasswordResultCodes.Success) {
                    GFDToastSuccess('Password succesfully reset')
                    const confirmResetReq: ConfirmResetForgotPasswordRequest = { token: token }
                    confirmResetForgotPassword(confirmResetReq).then(() => {
                        navigate(paths.login)
                    }, () => { })
                }
                else {
                    displayErrorMessages(resultCode, '')
                    navigate(paths.login)
                }
            },
                (notOKResponseModel: NotOKResponseModel) => {
                    GFDToastError('Failed to reset password')
                    setErrorResponse(notOKResponseModel)
                    navigate(paths.login)
                })
        }
    }


    return (
        <React.Fragment>
            <ErrorHandler {...errorHandlerProps} />
            <Box sx={newStyle.page}>
                <div style={resetPasswordStyles.panelBoxHeader}>Reset password</div>
                <div style={resetPasswordStyles.panelBox}>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item paddingY={'2%'}>
                            <TextField id="newPasswordField" label="New Password" variant="outlined" type={'password'}
                                sx={{ paddingBottom: 1, width: '100%' }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight: 'bold' }
                                }}
                                size='small'
                                onChange={(e) => {
                                    handleNewPasswordChange(e.target.value, confirmPassword)
                                }}
                                error={isNewPassError}
                                helperText={<UnorderedMultiErrorLines lines={errorLinesNewPassword} />}
                            />
                        </Grid>
                        <Grid item paddingY={'2%'}>
                            <TextField id="confirmPasswordField" label="Confirm Password" variant="outlined" type={'password'}
                                sx={{ paddingBottom: 1, width: '100%' }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight: 'bold' }
                                }}
                                size='small'
                                onChange={(e) => {
                                    handleConfirmPasswordChange(newPassword, e.target.value)
                                }}
                                error={isConfirmPassError}
                                helperText={<UnorderedMultiErrorLines lines={errorLinesConfirmPassword} />}
                            />
                        </Grid>
                        <Grid item paddingBottom={'5%'} paddingTop={'5%'}>
                            <Button id='submitResetPassword' variant='contained' onClick={submitResetPassword} style={{ background: 'teal', fontSize: '9pt' }} disabled={isConfirmPassError || isNewPassError || confirmPassword.length === 0 || newPassword.length === 0}>Reset Password</Button>
                        </Grid>
                    </Grid>
                </div>
                <section id='resetPasswordForm'>
                </section>
            </Box>
        </React.Fragment>
    )
}

const resetPasswordStyles = {
    panel: {
        border: '1px transparent',
        width: '65%',
        margin: 'auto',
        paddingY: '10%'
    },
    panelBox: {
        background: '#FFF',
        borderRadius: '0 0 1em 0',
        borderBottom: '1px #A4A4A4 solid',
        borderRight: '1px #A4A4A4 solid',
        borderLeft: '1px #A4A4A4 solid',
        width: '50%',
        margin: 'auto',
        padding: '2em 2em',
    },
    panelBoxHeader: {
        background: 'white',
        marginBottom: '0px',
        width: '50%',
        margin: 'auto',
        border: '1px #4C4E52 solid',
        borderRadius: '1em 0 0 0',
        color: 'white',
        fontSize: 'large',
        backgroundColor: 'teal',
        textAlign: 'center' as 'center',
        padding: '1em'
    },
    panelFooter: {
        borderColor: '#90CCCC',
        background: '#90CCCC',
        borderRadius: '0 0 1em 1em',
        fontFamily: 'Lucida Sans Unicode, Myriad Pro, Microsoft Sans Serif, sans - serif',
        fontSize: '10pt',
        color: '#fff',
        padding: '15px 15px',
        borderBottom: 'solid 2px #CDCDCD',
        borderRight: 'solid 1px #CDCDCD',
        borderLeft: 'solid 1px #CDCDCD',
        boxShadow: '5px 5px 5px 5px black'
    },
    resetPasswordPanelBody: {
        background: '#F3F3F3',
        padding: '15px',
        borderRight: 'solid 1px #CDCDCD',
        borderLeft: 'solid 1px #CDCDCD',
    },
    tealHeader: {
        background: '#90CCCC',
        color: '#FFF',
        borderColor: '#90CCCC',
        lineHeight: '1.5em',
        height: '4.5em',
        padding: '0 3em',
        borderRadius: '1em 1em 0 0',
        borderTop: 'solid 2px #CDCDCD',
        borderRight: 'solid 1px #CDCDCD',
        borderLeft: 'solid 1px #CDCDCD'
    },
}

const newStyle = {
    page: {
        height: 'maxHeight',
        background: '#F3F3F3',
        paddingTop: '5%'
    }
}

export default ResetForgotPassword