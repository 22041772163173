import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../HelpPageStyles'
import ModifyWorkbook from './AdvancedAutoTracSections/ModifyWorkbook'
import AddSeriesToWorkbook from './AdvancedAutoTracSections/AddSeriesToWorkbook'
import AutoTracDownloadOptions from './AdvancedAutoTracSections/AutoTracDownloadOptions'
import DownloadQueueTable from './AdvancedAutoTracSections/DownloadQueueTable'
import SeriesFileUpload from './AdvancedAutoTracSections/SeriesFileUpload'

const AdvancedAutoTrac = () => {
    return (
        <>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Advanced Workbook</h2>
                </div>
            </Box>
            <p style={{ textAlign: 'left' }}>This section will describe the advanced features of AutoTrac.</p>
            <ModifyWorkbook />
            <AddSeriesToWorkbook />
            <AutoTracDownloadOptions />
            <DownloadQueueTable />
            <SeriesFileUpload />
        </>
    )
}

export default AdvancedAutoTrac
