import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, AccordionSummaryProps, Divider, Grid, Stack, styled } from '@mui/material'
import { AccessCriterion, AccessRule, AccessSet } from '../../../Models/DataModels/Common/UserAccessModel'
import Item from '../../Common/Utility/Item'
import moment, { Moment } from 'moment'
import ArrowExpand from '../../Icons/ArrowExpandIcon'

export interface AccessCardProps {
    Expires?: Moment
    AccessSet: AccessSet
    DownloadLimits: string[]
}

const AccessCard = ({
    Expires,
    AccessSet,
    DownloadLimits
}: AccessCardProps) => {

    const renderButNotSnippet = () => {
        return <div style={styles.butNot}>{' but not '}</div>
    }

    const renderRowTitle = (title: string) => {
        return (<Grid item md={5} flexDirection='column'>
            <div style={{ fontWeight: 'bold' }}>{title}</div>
        </Grid>)
    }

    const renderSeriesGroups = (rule: AccessRule) => {
        return (<div style={styles.rowDiv}>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Series Groups')}

                <Grid item md={18}>
                    <div style={styles.subRowDiv}>{
                        !rule?.SeriesGroups || !rule?.SeriesGroups?.length ? 'None' : rule.SeriesGroups.join(', ')
                    }</div>
                </Grid>
            </Grid>
        </div>)
    }

    const renderComponents = (rule: AccessRule) => {
        return (<div style={styles.rowDiv}>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Components')}

                <Grid item md={18}>
                    <div>{
                        !rule?.ComponentsIncluded && !rule.ComponentsExcluded ? 'None' :
                            <>{rule.ComponentsIncluded?.join(', ')}
                                {(rule.ComponentsExcluded?.length) ? renderButNotSnippet() : ''}
                                {rule.ComponentsExcluded.join(', ')}</>
                    }</div>
                </Grid>
            </Grid>
        </div>)
    }

    const renderActions = (rule: AccessRule) => {
        return (<div style={styles.rowDiv}>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Actions')}

                <Grid item md={18}>
                    <div>{
                        !rule?.ActionsIncluded?.length && !rule?.ActionsExcluded?.length ? 'None' :
                            <>{rule.ActionsIncluded?.join(', ')}
                                {(rule.ActionsExcluded?.length) ? renderButNotSnippet() : ''}
                                {rule.ActionsExcluded.join(', ')}</>
                    }</div>
                </Grid>
            </Grid>
        </div>)
    }

    const renderDownloadLimits = () => {
        return (<div style={styles.rowDiv}>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Download Limits')}

                <Grid item md={18}>{
                    !AccessSet.SetDownloadLimits || AccessSet.SetDownloadLimits?.length === 0 ? (
                        !DownloadLimits || DownloadLimits?.length === 0
                            ? 'Restricted'
                            : DownloadLimits.map((limit: string, index: number) => <div key={`downloadLimit${index}`} style={styles.subRowDiv}>{limit}</div>)
                    )
                        :
                        AccessSet.SetDownloadLimits.map((limit: string, index: number) => <div key={`accessSetDownloadLimit${index}`} style={styles.subRowDiv}>{limit}</div>)
                }</Grid>
            </Grid>
        </div>)
    }

    const renderCriteria = (title: string, criteria: AccessCriterion[]) => {
        if (!criteria || criteria?.length === 0) return <></>
        const total = criteria.length

        return (<div style={styles.rowDiv}>
            <Grid container spacing={3} columns={25}>
                <>
                    {renderRowTitle(title)}
                    <Grid item md={18}>
                        {
                            criteria?.map((criterion, index) => (
                                <div key={`criteria${index}`}>
                                    <Grid container spacing={3} columns={18}>
                                        <Grid item md={3} sm={4} flexDirection='column'>
                                            <div style={criterion.CriterionValue ? styles.title : {}}>{criterion.CriterionName}
                                                {criterion.CriterionValue ? ':' : ''}</div>
                                        </Grid>
                                        <Grid item md={15} sm={14}>
                                            <div>{criterion.Exclude ? 'NOT in ' : ''}
                                                {criterion.CriterionValue}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {index + 1 < total ? renderDivider() : <></>}
                                </div>
                            ))
                        }
                    </Grid>
                </>

            </Grid>
        </div>)
    }

    const renderRule = (rule: AccessRule, index?: number) => {
        if (!rule) return <></>

        return <Item key={`rule${index}`}>
            {renderSeriesGroups(rule)}
            {renderDivider()}
            {renderComponents(rule)}
            {renderDivider()}
            {renderActions(rule)}
            {renderDivider()}
            {renderDownloadLimits()}
            {renderDivider()}
            {renderCriteria('Geographic Range', rule.GeographicRanges)}
            {renderDivider()}
            {renderCriteria('Temporal Range', rule.TemporalRanges)}
            {rule.AllCriteria && rule.AllCriteria?.length ? renderDivider() : <></>}
            {renderCriteria('Detailed Criteria', rule.AllCriteria)}
        </Item>
    }

    const renderDivider = () => {
        return (<Divider sx={{ borderColor: 'black', paddingTop: 1, marginBottom: '8px' }} />)
    }

    return (
        <div>
            <StyledAccordion defaultExpanded={true} style={styles.accordion}>
                <StyledAccordionSummary
                    aria-controls={`panel-content-${AccessSet.Name.replaceAll(' ', '_')}`}
                    id={`panel-header-${AccessSet.Name.replaceAll(' ', '_')}`}
                >
                    <div>{`${AccessSet.Name} - Expires: ${moment(Expires)?.format('MM/DD/YYYY') || 'Never'}`}</div>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <div>
                        <Stack
                            direction="column"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={2}
                        >
                            {AccessSet.AccessRules.map((rule: AccessRule, index: number) => renderRule(rule, index))}
                        </Stack>
                    </div>
                </StyledAccordionDetails>
            </StyledAccordion>
        </div>
    )
}

const styles = {
    title: {
        fontWeight: 'bold'
    },
    butNot: {
        fontStyle: 'italic',
        display: 'inline'
    },
    accordion: {
        marginBottom: '8px'
    },
    rowDiv: {
        marginTop: '8px'
    },
    subRowDiv: {
        marginBottom: '8px'
    }
}

const StyledAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}))

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        expandIcon={<ArrowExpand />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#ebfafa',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}))


export default AccessCard