import React from "react"
import { ReactComponent as DownUpGraph } from "../../Images/svg/downUpGraph.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const DownUpGraphIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <DownUpGraph />
  </SvgIcon>
)

export default DownUpGraphIcon