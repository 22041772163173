import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { styled } from '@mui/material'

export const StyledAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
  }))

  export default StyledAccordion
  