import React from "react"
import { ReactComponent as DividendsIcon } from "../../Images/svg/DividendsIcon.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const Dividends = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <DividendsIcon />
  </SvgIcon>
)

export default DividendsIcon