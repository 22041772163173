import { Box } from "@mui/material"
import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"

const BasicActions = () => {
    return (
        <React.Fragment>
            <div>
                <Box>
                    <h2 style={{ textDecoration: 'underline' }}>
                        Basic Actions
                    </h2>
                </Box>
                <br />
                <div style={{ height: '365px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/ActionsHelp%20Intro-0.png' style={helpPageStyles.image} alt='Basic Actions Intro 0' />
                </div>
                <br />
                <div style={{ height: '101px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/ActionsHelp%20Intro-1.png' style={helpPageStyles.image} alt='Basic Actions Intro 1' />
                </div>
                <div style={{ height: '115px', paddingTop: '5px', paddingBottom: '120px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/ActionsHelp%20Intro-2.png' style={helpPageStyles.image} alt='Basic Actions Intro 2' />
                </div>
                <div style={{ textAlign: 'left' }}>
                    <p>
                        Action buttons allow you to view or manipulate your data in different ways.
                        <br />
                        This is the Action Toolbar, which will display if you select data in the table.
                    </p>
                </div>

                <div style={{ height: '110px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/ActionsHelp%20IntroTwo-1.png' style={helpPageStyles.image} alt='Basic Actions Two 1' />
                </div>
                <div style={{ textAlign: 'left' }}>
                    <p>
                        These are Quick Action buttons, which are scattered throughout the website and perform actions on single data s.
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BasicActions