import { useNavigate } from 'react-router-dom'
import { paths } from '../../Models/DataModels/Common/RedirectionModel'
import AlertModal, { AlertButtonType, AlertModalProps } from '../Common/Modals/AlertModal'

const regSuccessStyles = {
    textCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const RegistrationSuccess = ({ setShow, showModal, isEmailFailed }: any) => {
    const navigate = useNavigate()

    const contents = (notificationFailed: boolean): JSX.Element => {
        return (
            <div>
                <p style={regSuccessStyles.textCenter}><strong>Registration Successful!</strong></p>
                {notificationFailed ?
                    <>
                        <p style={regSuccessStyles.textCenter}>Thank you for Registering.</p>
                        <p style={regSuccessStyles.textCenter}>Registration was successful, but the system was unable to send the temporary password.</p>
                        <p style={regSuccessStyles.textCenter}>Click on login and use the forgot password link to reset the password.</p>
                    </>
                :
                    <>
                        <p style={regSuccessStyles.textCenter}>Thank you for Registering. An Email has been sent to you containing login details.</p>
                        <p style={regSuccessStyles.textCenter}>You may login by selecting the button below.</p>
                    </>
                }
            </div>
        )
    }

    const handleLogin = () => {
        setShow(false)
        navigate(paths.login)
    }

    const registrationSuccessProps: AlertModalProps = {
        showModal: showModal,
        setShowModal: setShow,
        AlertTitle: 'Registered',
        AlertContent: contents(isEmailFailed),
        AlertButtons: [
            {
                type: AlertButtonType.OK,
                display: 'Login',
                onClick: handleLogin,
                isPrimary: true
            }
        ],
        Size: 'lg',
        showCloseButton: false,
        onAlertClose: ():boolean => {return false}
    }

    return AlertModal(registrationSuccessProps)
}

export default RegistrationSuccess
