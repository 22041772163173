import React from "react"
import { ReactComponent as DeselectAllIcon } from "../../Images/svg/DeselectAll.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const DeselectAll = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <DeselectAllIcon />
  </SvgIcon>
)

export default DeselectAll