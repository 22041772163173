import moment from "moment"
import { LabelValuePair } from "../Models/DataModels/Common/LabelValuePairModel"
import { AppConfigurations } from "../Models/DataModels/Common/AppConfigurationsModel"

export const localStorageKeys = {
    selectedResultsData: 'selectedResultsData',
    lastLoggedInUser: 'lastLoggedInUser',
    rememberMe: 'rememberMe'
}

export const getSavedResultsFromStorageAsMap = (): Map<number, any> => {
    const storedArray: any[] = getSavedResultsFromStorageAsArray()

    const map = new Map<number, any>()

    if (Array.isArray(storedArray)) {

      storedArray.forEach(entry => {
        if (entry && entry?.id && !map.has(entry?.id)) {
          map.set(entry.id, entry)
        }
      })
    }

    return map
}

export const getSavedResultsFromStorageAsArray = (): any[] => {
    return getLocalStorageOrDefault(localStorageKeys.selectedResultsData, true, [])
}

export const getLocalStorageOrDefault = (key: any, parseJSON: boolean, defaultValue: any) => {
    const stored = localStorage.getItem(key)
    if (!stored) {
        return defaultValue
    }
    if (parseJSON) {
        return JSON.parse(stored)
    }
    return stored
}

export const setLocalStorage = (key: any, value: string) => {
    localStorage.setItem(key, value)
}

export const removeLocalStorage = (key: any) => {
    localStorage.removeItem(key)
}

export const getRememberMe = () => {
    return localStorage.getItem(localStorageKeys.rememberMe)
}

export const setRememberMe = (username: string) => {
    localStorage.setItem(localStorageKeys.rememberMe, username)
}

export const removeRememberMe = () => {
    localStorage.removeItem(localStorageKeys.rememberMe)
}

const dropdownFieldsStorageKeyPrefix: string = 'finaeonDropdownFields'
const labelValueKeyRetrivalDateString: string = 'RetrievalDate'

const labelValueRetrivalDateKey = (key: string): string => {
    return `${dropdownFieldsStorageKeyPrefix}-${key}-${labelValueKeyRetrivalDateString}`
}

export const getStoredLabelValuePair = (dropdownEntryKey: string): LabelValuePair[] | null => {
    const retrivalKey: string = labelValueRetrivalDateKey(dropdownEntryKey)
    try {
        const getStoredLabelValuePairRetrivalDateString: string | null = localStorage.getItem(retrivalKey)
        if (!getStoredLabelValuePairRetrivalDateString) {
            return null
        }
        const keyExpirationDate: moment.Moment = moment(getStoredLabelValuePairRetrivalDateString).add(AppConfigurations.labelValueStorageExpirationInDays, 'days')
        if (moment() > keyExpirationDate) {
            return null
        }
    } catch (ex) {
        return null
    }
    const storageKey: string = createDropdownFieldsStorageKey(dropdownEntryKey)
    const getStoredLabelValuePairString: string | null = localStorage.getItem(storageKey)
    if (!getStoredLabelValuePairString) {
        return null
    }
    try {
        const storedLabelValuePair: LabelValuePair[] = JSON.parse(getStoredLabelValuePairString)
        return storedLabelValuePair
    } catch (error) {
        return null
    }
}

const createDropdownFieldsStorageKey = (dropdownEntryKey: string) => {
    return `${dropdownFieldsStorageKeyPrefix}-${dropdownEntryKey}`
}

export const setLabelValuePair = (dropdownEntryKey: string, dropDownEntries: LabelValuePair[]) => {
    const storageKey: string = createDropdownFieldsStorageKey(dropdownEntryKey)
    const serialized: string = JSON.stringify(dropDownEntries)
    localStorage.setItem(storageKey, serialized)
    const currentDate: moment.Moment = moment()
    const retrievalKey: string = labelValueRetrivalDateKey(dropdownEntryKey)
    localStorage.setItem(retrievalKey, currentDate.toISOString())
}

export const removeStoredLabelValuePair = (dropdownEntryKey: string) => {
    const storageKey: string = createDropdownFieldsStorageKey(dropdownEntryKey)
    localStorage.removeItem(storageKey)
}

export const clearAllLabelValuePair = () => {
    const labelValueStoragekeys: string[] = []
    for(let i = 0; i < localStorage.length; i++) {
        const itemKey: string | null = localStorage.key(i)
        if (itemKey && itemKey.startsWith(dropdownFieldsStorageKeyPrefix)) {
            labelValueStoragekeys.push(itemKey)
        }
    }
    if (labelValueStoragekeys.length > 0) {
        labelValueStoragekeys.forEach((keyString: string) => {
            localStorage.removeItem(keyString)
        })
    }
}

const dropdownFieldsLastUpdatedKeyString: string = 'dropdownFieldsLastUpdated'

export const getStoredDropdownFieldsLastUpdated = (): moment.Moment | null => {
    const storedLastUpdatedString: string | null = localStorage.getItem(dropdownFieldsLastUpdatedKeyString)
    if (storedLastUpdatedString) {
        try {
            const storedLastUpdated: moment.Moment = moment(storedLastUpdatedString)
            return storedLastUpdated
        } catch (error) {
            return null
        }
    }
    return null
}

export const setStoredDropdownFieldsLastUpdated = (lastUpdatedDateForStore: moment.Moment) => {
    const lastUpdatedStoreString: string = lastUpdatedDateForStore.toISOString()
    localStorage.setItem(dropdownFieldsLastUpdatedKeyString, lastUpdatedStoreString)
}
