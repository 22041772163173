import { AppConfigurations } from "../../../Models/DataModels/Common/AppConfigurationsModel"
import { SearchResultData } from "../../../Models/DataModels/Common/ToolsModel"
import { GFDToastError } from "../../Common/Utility/GFDToastify"

export const createSearchResultsDownloadData = (data: SearchResultData[]): string[][] => {
    const downloadDataContents: string[][] = []
    downloadDataContents.push(['ticker', 'name', 'id', 'bill', 'scale_num', 'units', 'close', 'date'])
    data.forEach((value: SearchResultData) => {
        downloadDataContents.push([value.ticker, value.seriesName, value.seriesID.toString(), value.bill, value.scaleNum, value.units, value.closePrice.toString(), value.closeDate])
    })
    return downloadDataContents
}

export const validateChartCount = (data: any): boolean => {
    if (!Array.isArray(data)) {
        GFDToastError('Invalid Data for charting')
    }
    const checkData: any[] = data
    const chartLimit: number = AppConfigurations.selectedSeriesChartingLimit
    if (checkData.length > chartLimit) {
        GFDToastError(`Cannot chart more than ${chartLimit} series`)
        return false
    }
    return true
}
