import { Backdrop, Link } from '@mui/material'
import React from 'react'
import ProgressSpinner from '../../Icons/ProgressSpinner'
import FinaeonIcon from '../../Icons/FinaeonIcon'

export interface LoadingScreenProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    cancelAction?: () => void
}

const LoadingScreen = ({
    showModal,
    setShowModal,
    cancelAction
}: LoadingScreenProps) => {

    const handleClose = () => {
        if (cancelAction) {
            cancelAction()
            setShowModal(false)
        }
    }

    return (
        <>
            <Backdrop
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={showModal}
            >
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '50px'
                        }}>
                        <img src='https://finaeonsiteimages.blob.core.windows.net/siteimages/ProgressLeaves.gif' alt='Loading...' width='200px' height='200px' />
                    </div>
                    {cancelAction && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50px',
                                paddingTop: '100px'
                            }}
                        >
                            <Link
                                component='button'
                                variant='h3'
                                underline='none'
                                color='white'
                                onClick={handleClose}
                            >
                                Cancel
                            </Link>
                        </div>
                    )}
                </div>
            </Backdrop>
        </>
    )
}

export default LoadingScreen
