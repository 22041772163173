import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Slider from 'react-slick'
import StyledAccordion from '../../Common/Utility/StyledAccordion'
import { enqueuePowerDownload, getPowerDownloads } from '../../../Services/AutoTracService'
import { PowerDownload, PowerDownloadsResponse } from '../../../Models/DataModels/Responses/AutoTracResponses'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import StyledAccordionSummary from '../../Common/Utility/StyledAccordionSummary'
import StyledAccordionDetails from '../../Common/Utility/StyledAccordionDetails'
import { GFDToastSuccess } from '../../Common/Utility/GFDToastify'

export interface PowerDownloadsProps {
  refreshDownloadQueue: () => void,
  signOut: (logoutReason: LogoutReasonType) => void
}

const PowerDownloads = ({
  refreshDownloadQueue,
  signOut
}: PowerDownloadsProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [powerDownloads, setPowerDownloads] = useState<PowerDownload[]>([])
  const [response, setResponse] = useState<NotOKResponseModel | null>() 

  useEffect(() => {
    getPowerDownloads()
    .then((response: PowerDownloadsResponse) => {
      setPowerDownloads(response?.powerDownloads || [])
    },
    (notOKResponseModel: NotOKResponseModel) => {
      setPowerDownloads([])
      setResponse(notOKResponseModel)
    })
  }, [])

  const download = (downloadID: number, downloadName: string) => {
    enqueuePowerDownload(downloadID)
    .then((response: any) => {
      GFDToastSuccess(`${downloadName} queued for download!`)
      refreshDownloadQueue()
    },
    (notOKResponseModel: NotOKResponseModel) => {
      setPowerDownloads([])
      setResponse(notOKResponseModel)
    })
  }

  const errorHandlerProps: ErrorHandlerProps = {
    response,
    signOut
  }

  return <>
    {powerDownloads?.length ?
    <StyledAccordion expanded={expanded} onChange={(e, expanded) => setExpanded(expanded)} 
        disableGutters={true} 
        style={{marginTop: '8px', marginBottom: '16px'}}
    >
        <StyledAccordionSummary
            aria-controls='compare-content'
            id='compare-header'
            style={{margin: '0px', color: '#027e7e', fontSize: 20, borderColor: '#027e7e'}}
        >
            <Typography>GFD Power Downloads</Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails style={{padding: 5}}>
          <div style={powerDownloadsStyles.slider}>
            <Slider {...settings}>
              {powerDownloads?.map((powerDownload: any, index: number) => <div key={`powerDownloadItem${index}`}>
                <img 
                  src="https://gfdcdnstorage1.blob.core.windows.net/images/GFD_CSV.jpg" 
                  title={`Download Workbook for '${powerDownload.longName}'`}
                  alt={`Download Workbook for '${powerDownload.longName}'`}
                  style={powerDownloadsStyles.sliderImage}
                  onClick={() => download(powerDownload.id, powerDownload.shortName)} />
                <Typography sx={powerDownloadsStyles.downloadName}>{powerDownload.shortName}</Typography>
              </div>)}
            </Slider>
          </div>
        </StyledAccordionDetails>
    </StyledAccordion>
    : <></>}
    <ErrorHandler {...errorHandlerProps}/>
    </>
}

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
}

function NextArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'red',
        borderRadius: 3,
        paddingTop: 3,
        paddingBottom: 21,
        paddingLeft: 2,
        paddingRight: 23
      }}
      onClick={onClick}
    />
  )
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'green',
        borderRadius: 3,
        paddingTop: 3,
        paddingBottom: 21,
        paddingLeft: 3,
        paddingRight: 23
      }}
      onClick={onClick}
    />
  )
}

const powerDownloadsStyles = {
  slider: {
    textAlign: 'center' as 'center',
    paddingBottom: 20
  },
  sliderImage: {
    minWidth: 50,
    minHeight: 50,
    maxWidth: 100,
    maxHeight: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer'
  },
  downloadName: {
    textAlign: 'center',
  }
}

export default PowerDownloads
