import React from "react"
import { ReactComponent as AddWorkbookIcon } from "../../Images/svg/AddWorkbook.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const AddWorkbook = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <AddWorkbookIcon />
  </SvgIcon>
)

export default AddWorkbook