import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { promises } from "dns";

export interface GlobalStorageState {
    testStorage: string[]
}

const initialState: GlobalStorageState = {
    testStorage: []
}

export const testAPICallAsync = createAsyncThunk(
    'storage/apiRequest',
    async (parameters: any, {getState, dispatch}) => { return Promise.reject }
)

export const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {
        testReducer: (state: GlobalStorageState) => {},
    },
    extraReducers: (builder) => {
        builder
            .addCase(testAPICallAsync.fulfilled, (state: GlobalStorageState, action) => {
            })
            .addCase(testAPICallAsync.rejected, (state: GlobalStorageState, action) => {
            })
    }
})

export const { testReducer } = storageSlice.actions

export default storageSlice.reducer
