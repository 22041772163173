import React, { useState } from 'react'
import { CapitalizationSummary } from '../../../../../../Models/DataModels/Common/ToolsModel'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'
import { Payload } from 'recharts/types/component/DefaultLegendContent'
import { chartSizes } from '../../../../../../Models/DataModels/Common/AppConfigurationsModel'
import { graphCompanyLogoSVG, renderTitleOnGraph, sourceTextSVGNoLink } from '../../../../../Common/Modals/GraphUtil'
import GraphDownloadOptions, { GraphDownloadOptionsProps } from '../../../../../Common/Modals/GraphDownloadOptions/GraphDownloadOptions'
import OutputFormatValue, { OutputFormatValueType } from '../../../../../../Models/DataModels/Common/OutputFormatModel'
import { CreateDataFileRequest, FileDownloadRequest } from '../../../../../../Models/DataModels/Requests/ToolsRequests'
import { createDownloadDataFile } from '../../../../../../Services/ToolsService'
import { CreateDataFileResponse } from '../../../../../../Models/DataModels/Responses/ToolsResponses'
import { NotOKResponseModel } from '../../../../../../Models/DataModels/Common/NotOKResponseModel'
import { downloadFileFromServer } from '../../../../../../Services/DownloadService'
import { createDownloadData } from './CapitalizationUtil'

export interface CapSummaryPieChartProps {
    chartTitle: string,
    headerName: string,
    capSummaryData: CapitalizationSummary[],
    availableColorCodes: string[],
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void
}

const CapSummaryPieChart = ({
    chartTitle,
    headerName,
    capSummaryData,
    availableColorCodes,
    setErrorResponse
}: CapSummaryPieChartProps) => {

    const othersKey: string = 'Others'
    const othersColor: string = '#3b3b3b'
    const othersGroupPercentThreshold: number = 0.018
    const othersEntries: CapitalizationSummary[] = []

    const modifyData = (): CapitalizationSummary[] => {

        const originalData: CapitalizationSummary[] = []
        capSummaryData.forEach((entry: CapitalizationSummary) => originalData.push(Object.assign({}, entry)))

        let originalSelectedTotal: number = 0
        if (originalData?.length > 0) {
            originalData.forEach((value: CapitalizationSummary) => originalSelectedTotal += value.marketCap)
        }

        const aboveThresholdEntries: CapitalizationSummary[] = []
        originalData.forEach((value: CapitalizationSummary) => {
            const entryPercent: number = value.marketCap / originalSelectedTotal
            if (entryPercent > othersGroupPercentThreshold) {
                aboveThresholdEntries.push(value)
            } else {
                othersEntries.push(value)
            }
        })

        const sortedAboveThresholdEntries: CapitalizationSummary[] = aboveThresholdEntries?.sort((a: CapitalizationSummary, b: CapitalizationSummary) => b.marketCap - a.marketCap) || []
        let groupedOthersEntry: CapitalizationSummary | null = null
        if (othersEntries.length > 0) {
            othersEntries.forEach((value: CapitalizationSummary) => {
                if (groupedOthersEntry === null) {
                    groupedOthersEntry = {
                        sector: othersKey,
                        pct: 0,
                        marketCap: value.marketCap,
                        numberOfStks: 0,
                        date: value.date,
                        top10: 0
                    }
                } else {
                    groupedOthersEntry.marketCap += value.marketCap
                }
            })
        }
        if (groupedOthersEntry) {
            sortedAboveThresholdEntries.push(groupedOthersEntry)
        }

        return sortedAboveThresholdEntries
    }

    const [dataSource] = useState<CapitalizationSummary[]>(modifyData())

    let selectedTotal: number = 0
    if (dataSource?.length > 0) {
        dataSource.forEach((value: CapitalizationSummary) => selectedTotal += value.marketCap)
    }

    const renderCustomizedLabelOnly = (props: any) => {
        const { x, y, width, height, cx, cy, midAngle, innerRadius, outerRadius, color, startAngle, endAngle, points, stroke, percent, index, fill } = props
        const labelText: string = `${dataSource[index].sector}: ${(percent * 100).toFixed(2)}%`

        const mark: JSX.Element = index === 0 ? sourceTextSVGNoLink('99%', cy * 2 - 20) : <></>
        return (
            <>
                <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
                    {labelText}
                </text>
                {mark}
            </>
        )
    }

    const othersDetails = (): string => {
        const otherLabels: string[] = []
        othersEntries.forEach((value: CapitalizationSummary) => {
            const singlePercentage: number = value.marketCap / selectedTotal
            const legendLabel: string = `${value.sector}: ${(singlePercentage * 100).toFixed(2)}%`
            otherLabels.push(legendLabel)
        })
        return otherLabels.length > 0 ? otherLabels.join(', ') : ''
    }

    const legendData = (): Payload[] => {

        const payload: Payload[] = []

        if (othersEntries) {
            const othersPayloadIndex: number = dataSource.findIndex((value: CapitalizationSummary) => value.sector === othersKey)
            if (othersPayloadIndex > 0) {
                const othersPayload: CapitalizationSummary = dataSource[othersPayloadIndex]
                const singlePercentage: number = othersPayload.marketCap / selectedTotal
                const legendLabel: string = `${othersPayload.sector}: ${(singlePercentage * 100).toFixed(2)}%`
                payload.push(
                    {
                        id: othersPayload.sector,
                        type: 'square',
                        value: `${legendLabel} - ${othersDetails()}`,
                        color: othersColor
                    }
                )
            }
        }

        return payload
    }

    const downloadDataFile = (format: OutputFormatValueType) => {
        const dataFileRequest: CreateDataFileRequest = {
            fileName: 'Capitalization Summary Pie Chart',
            fileData: createDownloadData(headerName, capSummaryData),
            outputFormat: format
        }
        createDownloadDataFile(dataFileRequest).then(
            (fileCreateResponse: CreateDataFileResponse) => {
                const downloadRequest: FileDownloadRequest = {
                    filename: fileCreateResponse.filename,
                    mimeType: fileCreateResponse.mimeType
                }
                downloadFileFromServer(downloadRequest, setErrorResponse)
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
            }
        )
    }

    const downloadCSV = () => {
        downloadDataFile(OutputFormatValue.CSV)
    }

    const downloadExcel = () => {
        downloadDataFile(OutputFormatValue.Excel)
    }

    const imageID: string = 'capSummaryPieChart'

    const graphDownloadOptionsProps: GraphDownloadOptionsProps = {
        downloadCSV: downloadCSV,
        downloadExcel: downloadExcel,
        imageIDValue: imageID
    }

    return (
        <>
            <div style={{ paddingBottom: 5 }}>
                <GraphDownloadOptions {...graphDownloadOptionsProps} />
            </div>
            <ResponsiveContainer id={imageID} width='100%' height={chartSizes.large} className={'large-graph-container'}>
                <PieChart>
                    <Pie width={400} height={400}
                        data={dataSource}
                        dataKey='marketCap'
                        nameKey='sector'
                        fill='#8884d8'
                        minAngle={1}
                        labelLine={false}
                        label={renderCustomizedLabelOnly}
                        outerRadius='75%'
                    >
                        {dataSource.map((entry: CapitalizationSummary, index: number) => {
                            const pieSliceColor: string = entry?.sector === othersKey ? othersColor : availableColorCodes[index]
                            return <Cell key={`cell-${index}`} fill={pieSliceColor} aria-label={entry?.sector || `Category-${index}`} />
                        })}
                    </Pie>
                    <Tooltip />
                    <Legend payload={(legendData())} />
                    {graphCompanyLogoSVG()}
                    {renderTitleOnGraph(chartTitle)}
                </PieChart>
            </ResponsiveContainer>
        </>
    )
}

export default CapSummaryPieChart
