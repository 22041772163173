import React from "react"
import { ReactComponent as CheckSubscribedIcon } from "../../Images/svg/CheckSubscribed.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CheckSubscribed = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <CheckSubscribedIcon />
  </SvgIcon>
)

export default CheckSubscribed