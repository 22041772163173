import { Box } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Modal, ModalTitle } from "react-bootstrap"
import { localStorageKeys, removeLocalStorage } from "../../../Services/LocalStorageService"
import { CustomBtnBlue, CustomBtnCloseCancel } from "../GlobalSettings/CustomStyles"

export interface HomeSearchClearSavedModalProps {
    all: boolean,
    showClearSavedModal: boolean,
    setShowClearSavedModal: (value: boolean) => void,
    processDeleteAllSavedResults?: () => void,
    setClearSaved?: (value: boolean) => void
}

const HomeSearchClearSavedModal = ({
    all,
    showClearSavedModal,
    setShowClearSavedModal,
    processDeleteAllSavedResults,
    setClearSaved
}: HomeSearchClearSavedModalProps) => {
    const [modalText, setModalText] = useState<string>()
    const handleClose = () => {
        setShowClearSavedModal(false)
    }

    const handleClear = () => {
        if (processDeleteAllSavedResults) {
            processDeleteAllSavedResults()
            removeLocalStorage(localStorageKeys.selectedResultsData)
        }
        if (setClearSaved) {
            setClearSaved(true)
        }
        setShowClearSavedModal(false)
    }

    useEffect(() => {
        if (all) {
            setModalText('Are you sure you want to clear the Saved Results?')
        } else {
            setModalText('Are you sure you want to clear selected from the Saved Results?')
        }
    }, [])

    return (
        <React.Fragment>
            <Modal
                show={showClearSavedModal}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <ModalTitle>
                        Clear saved tickers?
                    </ModalTitle>
                </Modal.Header>
                <Modal.Body>
                    <Box style={{ textAlign: 'center' }}>{modalText}</Box>
                </Modal.Body>

                <Modal.Footer>
                    <CustomBtnCloseCancel variant="outlined" onClick={handleClose}>Cancel</CustomBtnCloseCancel>
                    <CustomBtnBlue variant="contained" onClick={handleClear}>Yes</CustomBtnBlue>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default HomeSearchClearSavedModal
