import getAPICallResult, { APIRequest, HttpRequest } from "./APIService"

export const getSeriesInfo = async (request: string) => {
    const apiRequest: APIRequest = {
        relativePath: '/Metadata/GetSeriesInfo/' + request,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const GetCountryNameByCode = async (request: string) => {
    const apiRequest: APIRequest = {
        relativePath: '/Metadata/GetCountryNameByCode/' + request,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const GetCountryCodeByName = async (request: string) => {
    const apiRequest: APIRequest = {
        relativePath: '/Metadata/GetCountryCodeByName/' + request,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

const MetadataService = () => {
    return (
      <div></div>
    )
}
export default MetadataService
