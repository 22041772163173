
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { EmptyStatusCode, NotOKResponseModel, ServerErrorMessage } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { paths } from '../../../Models/DataModels/Common/RedirectionModel'
import { MessageResponseTypes, MessageResponse, NotOKResponse } from '../../../Models/DataModels/Responses/NotOKResponse'
import { GFDToastError, GFDToastInfo, GFDToastSuccess, GFDToastWarning } from './GFDToastify'
import { LogoutReason, LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'

export interface ErrorHandlerProps {
    response?: NotOKResponseModel | null,
    signOut: (logoutReason: LogoutReasonType) => void,
    setMessages?: (messages: MessageResponse[]) => void
}

export const ErrorHandler = ({
    setMessages,
    response,
    signOut
}: ErrorHandlerProps) => {
    const navigate = useNavigate()

    const AlertUserSignOutAndRedirectToLogin = (message?: string) => {
        if (message) AlertUser(message)
        signOut(LogoutReason.Kicked)
        RedirectToLogin()
    }
    
    const AlertUser = (message: string) => {
        GFDToastError(message)
    }
    
    const RedirectToLogin = () => {        
        navigate(paths.login)
    }

    const alertMessages = (notOKResponse: NotOKResponse) => {
        const sendBack: boolean = !notOKResponse.logOut && Boolean(setMessages)
        const messages: MessageResponse[] = []
        notOKResponse.messages.forEach(message => {
            switch (message.type) {
                case MessageResponseTypes.Error:
                    sendBack ?
                        messages.push({ message: message.message, type: MessageResponseTypes.Error })
                        : GFDToastError(message.message)
                    break
                case MessageResponseTypes.Info:
                    sendBack ?
                        messages.push({ message: message.message, type: MessageResponseTypes.Info })
                        : GFDToastInfo(message.message)
                    break
                case MessageResponseTypes.Internal:
                    sendBack ?
                        messages.push({ message: message.message, type: MessageResponseTypes.Error })
                        : GFDToastError(message.message)
                    break
                case MessageResponseTypes.Success:
                    sendBack ?
                        messages.push({ message: message.message, type: MessageResponseTypes.Success })
                        : GFDToastSuccess(message.message)
                    break
                case MessageResponseTypes.Warning:
                    sendBack ?
                        messages.push({ message: message.message, type: MessageResponseTypes.Warning })
                        : GFDToastWarning(message.message)
                    break
                case MessageResponseTypes.Unknown:
                    sendBack ?
                        messages.push({ message: message.message, type: MessageResponseTypes.Info })
                        : GFDToastInfo(message.message)
                    break
            }
        })
        if (sendBack && setMessages) setMessages(messages)
        if (notOKResponse.logOut) AlertUserSignOutAndRedirectToLogin()
    }
    
    const handleErrorResponse = () => {
        if (!response) return
        
        if (setMessages) setMessages([]) // clear first
        
        if (response.isBackgroundCall) return

        if (response.notOKResponse) {
            const notOKResponse = response.notOKResponse
            alertMessages(notOKResponse)
        } else {
            const error = response.statusCode === EmptyStatusCode ? ServerErrorMessage : `Status Code: [${response.statusCode}]; Error: [${response.anyOtherError ? response.anyOtherError.toString() : ''}]`
            AlertUser(error)
        }
    }

    useEffect(() => {
        handleErrorResponse()
    }, [response])

    return <></>
}
