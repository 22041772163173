
import Modal from 'react-bootstrap/Modal'
import { Paper } from '@mui/material'
import {useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { getSeriesInfo } from '../../../Services/MetadataService'
import { ErrorHandler, ErrorHandlerProps } from '../Utility/ErrorHandler'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { TxtBtnCloseCancel } from '../GlobalSettings/CustomStyles'

export interface TickerInfoModalProps {
  seriesID?: any,
  clearSeriesID: () => void,
  signOut: (logoutReason: LogoutReasonType) => void
}

const TickerInfoModal = ({
  seriesID,
  clearSeriesID,
  signOut
}: TickerInfoModalProps) => {

  const [showModal, setShowModal] = useState<boolean>(false)
  const [infoResponse, setInfoResponse] = useState<any>()
  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
  
  const handleClose = () => {
    setShowModal(false)
    clearSeriesID()
  }

  const isStock = () => {
    const sector: string = infoResponse?.GFDSector || '' 
    return ['Securities', 'Securities - Current', 'Securities - Delisted'].includes(sector)
  }

  useEffect(() => {
    if (seriesID && !requestInProgress) {
      setRequestInProgress(true)
      getSeriesInfo(seriesID).then((response) => {
        setRequestInProgress(false)
        setInfoResponse(response)
        setShowModal(true)
      },
      (notOKResponseModel: NotOKResponseModel) => {
        setRequestInProgress(false)
        setErrorResponse(notOKResponseModel)
      })
    }
  }, [seriesID])
  
  const errorHandlerProps: ErrorHandlerProps = {
    response: errorResponse,
    signOut: signOut
  }

  return (
    <>
      <ErrorHandler {...errorHandlerProps}/>
      { infoResponse ?
      <Modal show={showModal} size={'xl'} onHide={handleClose} style={{ padding: '20 px' }} >
        <Modal.Header closeButton>
          <Modal.Title>{infoResponse.description}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Paper elevation={3} style={{ padding: 5, backgroundColor: '#f5f5f5' }}>
            <h3 style={{ borderCollapse: 'collapse', borderBottom: '1px solid #D3D3D3' }}>General Information</h3>
            <div style={{ display: 'flex', flexFlow: 'row', padding: 5 }}>

              <Grid container rowSpacing={1} columnSpacing={0} columns={{ xs: 1, sm: 2, md: 6 }}>

                {infoResponse.description === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Name:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.description}</p>
                  </Grid></>
                }

                {(infoResponse["Corporate Action Data"] === "") || (infoResponse["Corporate Action Data"] === "1/1/0001 to 1/1/0001") ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Corporate Action:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse["Corporate Action Data"]}</p>
                  </Grid></>
                }

                {infoResponse.symbol === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Symbol:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.symbol}</p>
                  </Grid></>
                }

                {infoResponse.GFDSector === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Series Category:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.GFDSector}</p>
                  </Grid>
                </>}

                {infoResponse.series === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Series Type:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.series}</p>
                  </Grid>
                </>}

                {infoResponse.startDate === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Begin Date:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.startDate}</p>
                  </Grid>
                </>}
                {infoResponse.units === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Unit:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.units}</p>
                  </Grid>
                </>}
                {infoResponse.endDate === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>End Date:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.endDate}</p>
                  </Grid>
                </>}
                {infoResponse.scale === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Scale:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.scale}</p>
                  </Grid>
                </>}

                {infoResponse.periodicity === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Periodicity:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    {infoResponse.periodicity.split(/<BR>|<br>/).map((info: string, index: number) => {
                      return <p key={`periodicity${index}`} style={rowStyle}>{info}</p>
                    })
                    }
                  </Grid>
                </>}

                {infoResponse.dataCount === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Data Count:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.dataCount}</p>
                  </Grid>
                </>}

                {infoResponse.country === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Country:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.country}</p>
                  </Grid>
                </>}
                {isStock() || infoResponse.baseValue === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Base Value:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.baseDate} = {infoResponse.baseValue}</p>
                  </Grid>
                </>}

                {infoResponse.currency === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Currency:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.currency}</p>
                  </Grid>
                </>}

                {(infoResponse["Dividend Data"] === "") || (infoResponse["Dividend Data"] === "1/1/0001 to 1/1/0001") ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Cash Dividends:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse["Dividend Data"]}</p>
                  </Grid>
                </>}

                {(infoResponse["Share Data"] === "") || (infoResponse["Share Data"] === "1/1/0001 to 1/1/0001") ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Shares:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse["Share Data"]}</p>
                  </Grid>
                </>}

                {(infoResponse["Annual Fundamental Data"] === "") || (infoResponse["Annual Fundamental Data"] === "1/1/0001 to 1/1/0001") ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Annual Fundamentals:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse["Annual Fundamental Data"]}</p>
                  </Grid>
                </>}

                {(infoResponse["Quarterly Fundamental Data"] === "") || (infoResponse["Quarterly Fundamental Data"] === "1/1/0001 to 1/1/0001") ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Quarterly Fundamentals:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse["Quarterly Fundamental Data"]}</p>
                  </Grid>
                </>}

                {(infoResponse["Annual Ratio Data"] === "") || (infoResponse["Annual Ratio Data"] === "1/1/0001 to 1/1/0001") ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Annual Ratio:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse["Annual Ratio Data"]}</p>
                  </Grid>
                </>}

                {(infoResponse["Quarterly Ratio Data"] === "") || (infoResponse["Quarterly Ratio Data"] === "1/1/0001 to 1/1/0001") ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Quarterly Ratio:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse["Quarterly Ratio Data"]}</p>
                  </Grid>
                </>}

                {infoResponse.equityType === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Type:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.equityType}</p>
                  </Grid>
                </>}

                {infoResponse.industry === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Industry:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.industry}</p>
                  </Grid>
                </>}

                {infoResponse.exchange === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Exchange:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.exchange}</p>
                  </Grid>
                </>}

                {infoResponse.sector === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Sector:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.sector}</p>
                  </Grid>
                </>}

                {infoResponse.SIC1 === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>SIC:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.SIC1}</p>
                  </Grid>
                </>}

                {infoResponse.naicsCode === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>NAICS:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.naicsCode}</p>
                  </Grid>
                </>}

                {infoResponse.cusipCode === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>CUSIP:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.cusipCode}</p>
                  </Grid>
                </>}

                {(infoResponse.address1 === "") && (infoResponse.address2 === "") ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Address:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    {infoResponse.address1 === "" ? <></> : <p style={rowStyle}>{infoResponse.address1}</p>}
                    {infoResponse.address2 === "" ? <></> : <p style={rowStyle}>{infoResponse.address2}</p>}
                  </Grid>
                </>}

                {infoResponse.cik === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>CIK:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.cik}</p>
                  </Grid>
                </>}

                {(infoResponse.weburl === "") || (infoResponse.weburl === "http://") ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Web Address:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.weburl}</p>
                  </Grid>
                </>}

                {infoResponse.phoneNum === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Phone Number:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.phoneNum}</p>
                  </Grid>
                </>}

                {infoResponse.fiscalEnd === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Fiscal Year End:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.fiscalEnd}</p>
                  </Grid>
                </>}

                {infoResponse.stateIncorporation === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Incorporation:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.stateIncorporation}</p>
                  </Grid>
                </>}

                {infoResponse["Index Members"] === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Index Members:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse["Index Members"]}</p>
                  </Grid>
                </>}

                {infoResponse["Index Membership"] === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Index Membership:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse["Index Membership"]}</p>
                  </Grid>
                </>}

                {infoResponse.adjustment === "" ? <></> : <>
                  <Grid item md={1}>
                    <p style={rowStyle}><strong>Adjustment:</strong></p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={rowStyle}>{infoResponse.adjustment}</p>
                  </Grid>
                </>}

              </Grid>

            </div>

            
          </Paper>
          {infoResponse.metadata !== "" ?
            <Paper elevation={3} style={{ padding: 5, backgroundColor: '#f5f5f5', marginTop: '10px' }}>
              <h3 style={{ borderCollapse: 'collapse', borderBottom: '1px solid #D3D3D3' }}>{isStock() ? 'Business Description' : 'Description'}</h3>
              <div style={{padding: 5}}>
                {infoResponse.metadata.split(/<br \/><br \/>|<br \/>|<BR>/).map((s:string, index: number)=><p key={`description${index}`}>{s}</p>)}
              </div>
              
            </Paper>
            : <></>}
          {infoResponse.corpHist !== "" ?
            <Paper elevation={3} style={{ padding: 5, backgroundColor: '#f5f5f5', marginTop: '10px' }}>
              <h3 style={{ borderCollapse: 'collapse', borderBottom: '1px solid #D3D3D3' }}>Corporate History</h3>
              <div  style={{padding: 5}}>
                {infoResponse.corpHist.split(/<br \/><br \/>|<br \/>|<BR>/).map((s:string, index: number)=><p key={`corpHistory${index}`}>{s}</p>)}
              </div>
            </Paper>
            : <></>}
        </Modal.Body>
        <Modal.Footer>
          <TxtBtnCloseCancel sx={{marginRight: '10px'}} onClick={handleClose}>
            Close
          </TxtBtnCloseCancel>
        </Modal.Footer>
      </Modal>
      : <></>}
    </>
  )
}

const rowStyle = {
  marginBottom: '0px',
}

export default TickerInfoModal