import { Box } from "@mui/material"
import { helpPageStyles } from "../../HelpPageStyles"

const ConstiuentMembershipSearch = () => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Constituent Membership Search</h2>
                </div>

            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h4>Constituent Membership</h4>
                </div>
            </Box>
            <div style={{ textAlign: 'left' }}>
                <p>
                    Constituent Membership gives one the ability to look into various indexes and sub indexes and see the consituent members (Including historical members) that make up those indexes.  To start we will select which category we want, Major US Indices or GFD Foreign Indices (Picture below):
                </p>
                <div style={{ height: '110px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/constituentMembership01.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Constituent Membership Search Help Image 1'></img>
                </div>
                <p style={{ paddingTop: 25 }}>
                    Once we have selected the category we can move on and select an index we are curious about.  In this case i will select S and P 500 technology (Picture below):
                </p>
                <div style={{ height: '349px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/constituentMembership02.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Constituent Membership Search Help Image 2'></img>
                </div>
                <p style={{ paddingTop: 25 }}>
                    Now that I have selected an index, I can select a sub index (NOTE: IF ONE EXISTS, some indexes do not have sub indexes).  In this case i will select "S&P 500 Application software index 45103010" (See Picture Below):
                </p>
                <div style={{ height: '561px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/constituentMembership03.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Constituent Membership Search Help Image 3'></img>
                </div>
                <p style={{ paddingTop: 25 }}>
                    There are a few different sub indexes we can choose from (picture above).  The number you see in the list is called the GICS number or "Global Industry Classification Standard", this is useful if you want to narrow your searches down to a particular industry.  To learn more about how GICS works see: <a style={helpPageStyles.hyperlinkStyle} href="https://en.wikipedia.org/wiki/Global_Industry_Classification_Standard">GICS wiki</a>
                </p>

            </div>
        </>
    )
}

export default ConstiuentMembershipSearch