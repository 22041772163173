import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'

const ChronologySection = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Chronology</h4>
                </div>
            </Box>
            <div style={{ height: '290px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChronology.png' style={helpPageStyles.image} alt='Advanced Graphing Chronology'></img>
            </div>
            <p>
                Chronology option allows displaying overlay to the graph showing events that took place during certain period of time.
            </p>
            <div style={{ height: '172px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChronologyOptions.png' style={helpPageStyles.image} alt='Advanced Graphing Chronology Options'></img>
            </div>
            <p>
                Select a Historical Era Type to set the overlay to the graph on what event took place in the date range of selected primary series.
            </p>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: Only the applicable options will be enabled. In addition, events that took place must be within the range of primary series.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Options</h5>
                </div>
            </Box>
            <ul>
                <li>
                    <b>None</b>
                    <ul>
                        <li>No historical era overlay will be shown on the graph.</li>
                    </ul>
                </li>
                <li>
                    <b>Recession</b>
                    <ul>
                        <li>
                            Displays the recession that took place in the range of primary series to the graph.
                            <div style={{ height: '271px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChronologyRecession.png' style={helpPageStyles.image} alt='Advanced Graphing Chronology Recession'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Head of State</b>
                    <ul>
                        <li>
                            Displays the head of state in the range of primary series to the graph.
                            <div style={{ height: '275px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChronologyState.png' style={helpPageStyles.image} alt='Advanced Graphing Chronology State'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Head of Government</b>
                    <ul>
                        <li>
                            Displays the head of government in the range of primary series to the graph.
                            <div style={{ height: '280px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChronologyGovernment.png' style={helpPageStyles.image} alt='Advanced Graphing Chronology Government'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Bear Market</b>
                    <ul>
                        <li>
                            Displays the bear market times in the range of primary series to the graph.
                            <div style={{ height: '274px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChronologyBearMarket.png' style={helpPageStyles.image} alt='Advanced Graphing Chronology Bear Market'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Gold Standard</b>
                    <ul>
                        <li>
                            Displays the gold standard times in the range of primary series to the graph.
                            <div style={{ height: '274px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChronologyGoldStandard.png' style={helpPageStyles.image} alt='Advanced Graphing Chronology Gold Standard'></img>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default ChronologySection
