import { AccessCacheQueueRequest } from "../Models/DataModels/Common/AccessCacheModel"
import getAPICallResult, { APIRequest, HttpRequest } from "./APIService"

const controller = 'User'
const resource = 'AccessCacheQueue'
export enum AccessCacheQueueAction {
    list = 'List',
    create = 'Create',
    update = 'Update'
}

export const accessCacheQueueService = async (request: AccessCacheQueueRequest,
    action: AccessCacheQueueAction) => {
    const apiRequest : APIRequest = {
        relativePath: `${controller}/${resource}/${action}`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}
