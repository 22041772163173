import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'

const TechnicalIndicatorsSection = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Technical Indicators</h4>
                </div>
            </Box>
            <div style={{ height: '662px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingTechIndicators.png' style={helpPageStyles.image} alt='Advanced Graphing Technical Indicators'></img>
            </div>
            <div>
                <ul>
                    <li>
                        <b>Chart Indicators</b>
                        <ul>
                            <li>
                                Adds chart indicator to the graph.
                                <div style={{ height: '559px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingTechIndicatorsChartIndicators.png' style={helpPageStyles.image} alt='Advanced Graphing Technical Indicators Chart Indicators'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Technical Indicators (lower)</b>
                        <ul>
                            <li>
                                Adds selected technical indicator to a separate graph.
                                <div style={{ height: '612px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingTechIndicatorsSection.png' style={helpPageStyles.image} alt='Advanced Graphing Technical Indicators Technical Indicators'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Value Indicators</b>
                        <ul>
                            <li>
                                Adds selected value indicator to a separate graph.
                                <div style={{ height: '447px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingTechIndicatorsValueIndicators.png' style={helpPageStyles.image} alt='Advanced Graphing Value Indicators'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Financial Ratios (single securities only)</b>
                        <ul>
                            <li>
                                Adds selected financial ratio to a separate graph.
                                <div style={{ height: '829px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingTechIndicatorsFinRatios.png' style={helpPageStyles.image} alt='Advanced Graphing Technical Indicators Financial Ratios'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Indicator selection and configuration</h5>
                </div>
            </Box>
            <div style={{ height: '184px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingTechIndicatorsTooltip.png' style={helpPageStyles.image} alt='Advanced Graphing Technical Indicators Tool Tip'></img>
            </div>
            <p>
                Select an indicator to graph. Hover over the question mark to see the description about the indicator.
            </p>
            <div style={{ height: '145px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingTechIndicatorsInputs.png' style={helpPageStyles.image} alt='Advanced Graphing Technical Indicators Inputs'></img>
            </div>
            <p>
                Depending on the selection, additional inputs would be appear. Set the values.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Sample Graphs</h5>
                </div>
            </Box>
            <div style={{ height: '687px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingTechIndicatorsSampleGraphs.png' style={helpPageStyles.image} alt='Advanced Graphing Technical Indicators Sample Graphs'></img>
            </div>
        </div>
    )
}

export default TechnicalIndicatorsSection
