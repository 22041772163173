import { isNullOrUndefinedOrWhiteSpace } from './ValidationService'
import moment from 'moment'
import { AppConfigurations } from '../Models/DataModels/Common/AppConfigurationsModel'

export const ConcatenateOrgAddressParts = (address: string, address2: string, city: string, state: string, zipCode: string, country: string): string => {
  return address + ' ' + address2 + '\n' +
    city + ' ' + state + '\n' +
    zipCode + '\n' +
    country + '\n'
}

export const ConcatenateUserAddressParts = (street1: string, street2: string, city: string, stateOrProvince: string, postalZone: string, country: string): string => {
  let fullAddress: string = ''
  fullAddress = fullAddress.concat(street1)
  if (!isNullOrUndefinedOrWhiteSpace(street2)) {
    fullAddress = fullAddress.concat('\n' + street2)
  }
  fullAddress = fullAddress.concat('\n')
  if (!isNullOrUndefinedOrWhiteSpace(city)) {
    fullAddress = fullAddress.concat(city + ', ')
  }
  fullAddress = fullAddress.concat(stateOrProvince + '\n' + postalZone + '\n' + country)
  return fullAddress
}

export const seriesMinDate: moment.Moment = moment(`${AppConfigurations.minimumDate}`)
export const seriesMaxDate: moment.Moment = moment()

const seriesMaxDateAddDay: moment.Moment = seriesMaxDate.add(1, 'days')

export const validDateInRange = (inputDate: moment.Moment | null): boolean => {
  if (inputDate?.isValid() && inputDate >= seriesMinDate && inputDate < seriesMaxDateAddDay) {
    return true
  }
  return false
}
