import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"
import { Box, Link } from "@mui/material"
import { pathParts } from "../../../../Models/DataModels/Common/RedirectionModel"
import { useNavigate } from "react-router-dom"
import { BlueBlueText, GreenGreenText, ImageNumberReference, MarkedOrangeOrangeText, MarkedRedRedText, PurplePurpleText } from "../../HelpPageCommonComponents"

const FamaFrench = () => {

    const navigate = useNavigate()

    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }}>
                <br />
                <Box
                    sx={helpPageStyles.heading}>
                    <h2 style={{ textDecoration: 'underline' }}>
                        Fama-French Stock Screener
                    </h2>
                </Box>
                <div style={{ height: '592px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench.png' alt='Fama-French Stock Screener' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The Fama-French screening tool lets you analyze a portfolio of equities.
                    To begin using the Fama French tool, click the green icon box, located within the <MarkedRedRedText /> box ({ImageNumberReference(1)}).
                    This will lead you to the Screening Factors, as seen in the image below.
                </p>
                <div style={{ height: '775px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench%20Options.png' alt='Fama-French Screening Factors Options' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>The Screening Name, Country of Listing, Index, and at least one criteria are required in order to continue.</p>
                <p>The screening date is based on a quarterly system. By default, the value for the date is the last full quarter.</p>
                <br />
                <div style={{ height: '213px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench%20Criteria.png' alt='Fama-French Criteria' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p></p>
                <div style={{ height: '483px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench%20Toolbar.png' alt='Fama-French Toolbar' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>By clicking the "Create Index" button, you can create your own custom index. This index will be added to the <Link onClick={() => { navigate(pathParts.help.financialToolsSections.myIndices) }}>Custom Indices tool</Link>.</p>
                <div style={{ height: '173px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench%20QuickActions.png' alt='Fama-French QuickActions' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    There are Quick Action buttons associated with each row which will allow you to manipulate or obtain data for the respective row.
                    The <GreenGreenText /> button ({ImageNumberReference(1)}) will display a pop-up with more detailed information about the constituent.
                    The <BlueBlueText /> button ({ImageNumberReference(2)}) will take you to a page which will graph the constituent data.
                    The <MarkedOrangeOrangeText /> button ({ImageNumberReference(3)}) will create a pop-up with options for you to download the data.
                </p>
                <div style={{ height: '270px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench%20ConstituentExpand.png' alt='Fama-French Expand Constituents' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    Once your screening reports have been created, they can be accessed on the main Fama French page.
                    Clicking on the headers for each report will expand them.
                    From there you can create the custom index by clicking the Create Index button (<PurplePurpleText /> box {ImageNumberReference(1)}).
                    You can also click the plus button (<BlueBlueText /> box {ImageNumberReference(2)}) if you want to view the options you selected for the screening criteria.
                    Clicking the "Click to View Constituents" button (<MarkedRedRedText /> box {ImageNumberReference(3)}) will expand the constituents list, where you can manipulate the data respective to each of the rows, as seen in the image below.
                </p>
                <div style={{ height: '374px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench%20ConstituentList.png' alt='Fama-French Expanded Constituents List' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <div style={{ height: '280px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench%20CustomIndex.png' alt='Fama-French Custom Index' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    This is the pop-up that the "Create Index" button will display.
                    On this page, you can customize the custom index you want to create.
                    Once you click the "Save Index" button, the custom index will be created and added to the Custom Indices tool.
                </p>

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <br />
                        <br />
                        <h4>
                            Example Report
                        </h4>
                    </div>
                </Box>
                <div style={{ height: '771px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench%20OptionsExample.png' alt='Fama-French Criteria Options Example' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    In the above image, we are creating an example report.
                    All the necessary non-criteria values (screening name, country, index, and date) are input in the <GreenGreenText /> boxes (labled as {ImageNumberReference(1)}).

                    Although only one criteria is necessary to generate a report, here we are using three (market cap, risk, and investment), outlined by the <MarkedRedRedText /> boxes (labled as {ImageNumberReference(2)}).
                </p>
                <div style={{ height: '297px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20FamaFrench%20Criteria%20Example.png' alt='Fama-French Screening Options Example' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The picture shows the results of using the previous screening criteria options, with 1 passed result.
                </p>
            </div>
        </React.Fragment>
    )
}

export default FamaFrench