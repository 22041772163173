import React from "react"
import { ReactComponent as FamaFrenchIcon } from "../../Images/svg/famaFrench.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const FamaFrenchSVG = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <FamaFrenchIcon />
  </SvgIcon>
)

export default FamaFrenchSVG
