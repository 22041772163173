export const sessionStorageKeys = {
    selectedResultsForGraphData: 'selectedResultsForGraphData',
    selectedComparisonGraphData: 'selectedComparisonGraphData',
    selectedResultsForChartsData: 'selectedResultsForChartsData',
    graphCustomColors: 'graphCustomColors'
}

export const getSessionStorageOrDefault = (key: any, defaultValue: any) => {
    const stored = sessionStorage.getItem(key)
    if (!stored) {
        return defaultValue
    }
    return JSON.parse(stored)
}
