import { Box } from "@mui/material"

export interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 3, pt: 1, pb: 3 }}>
          <div className='typographyStyle'>
            {children}
          </div>
        </Box>
      )}
    </div>
  )
}
