import React from "react"
import { ReactComponent as RefreshMembersListIcon } from "../../Images/svg/RefreshMembersList.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const RefreshMembersList = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <RefreshMembersListIcon />
  </SvgIcon>
)

export default RefreshMembersList