import { AppConfigurations } from "../Models/DataModels/Common/AppConfigurationsModel"
import { UserInfo } from "../Models/DataModels/Common/UserInfoModel"

const milliSecondsInSeconds = 1000

const accesibilitypath: string = '/'
const isSecure: boolean = true

const AuthTokenName: string = 'auth'
const RefreshTokenName: string = 'refresh'
const UserInfoName: string = 'user'

export const setCookie = (cookieKey: string, cookieValue: any, expires: Date, path: string, secure: boolean) => {
    let cookieText = `${encodeURIComponent(cookieKey)}=${encodeURIComponent(cookieValue)}`

    if (expires instanceof Date) {
        const expireUTCString = expires.toUTCString()
        cookieText += `; expires=${expireUTCString}`
    }
    if (path) {
        cookieText += `; path=${path}`
    }
    if (secure) {
        cookieText += `; secure`
    }
    document.cookie = cookieText
}

export const getCookie = (cookieKey: string) => {
    const cookieName = `${encodeURIComponent(cookieKey)}=`
    const httpCookies = document.cookie
    let value = null
    const startIndex = httpCookies.indexOf(cookieName)
    if (startIndex > -1) {
        let endIndex = httpCookies.indexOf(';', startIndex)
        if (endIndex === -1) {
            endIndex = httpCookies.length
        }
        value = decodeURIComponent(httpCookies.substring(startIndex + cookieName.length, endIndex))
    }

    return value
}

export const removeCookie = (cookieKey: string, path: string, secure: boolean) => {
    setCookie(cookieKey, '', new Date(0), path, secure)
}

const generateCookieExpirationDate = () => {
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + (AppConfigurations.CookieMaxAgeInSeconds * milliSecondsInSeconds))
    return expirationDate
}

const generateSessionExpirationDate = () => {
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + (AppConfigurations.SessionMaxAgeInSeconds * milliSecondsInSeconds))
    return expirationDate
}

const SEPARATOR: string = '___TIME___'
const INDEX_Value: number = 0
const INDEX_Expiration: number = 1

export const setAuthTokenToCookie = (cookieValue: any) => {
    const sessionExpirationDate: Date = generateSessionExpirationDate()
    const cookieExpirationDate: Date = generateCookieExpirationDate()
    const valueWithExp: string = cookieValue + SEPARATOR + sessionExpirationDate
    setCookie(AuthTokenName, valueWithExp, cookieExpirationDate, accesibilitypath, isSecure)
}

export const setRefreshTokenToCookie = (cookieValue: any) => {
    const sessionExpirationDate = generateSessionExpirationDate()
    const cookieExpirationDate: Date = generateCookieExpirationDate()
    const valueWithExp: string = cookieValue + SEPARATOR + sessionExpirationDate
    setCookie(RefreshTokenName, valueWithExp, cookieExpirationDate, accesibilitypath, isSecure)
}

export const setUserInfoToCookie = (userInfo?: UserInfo | null) => {
    const sessionExpirationDate = generateSessionExpirationDate()
    const cookieExpirationDate: Date = generateCookieExpirationDate()
    const cookieValue = JSON.stringify(userInfo)
    const valueWithExp: string = cookieValue + SEPARATOR + sessionExpirationDate
    setCookie(UserInfoName, valueWithExp, cookieExpirationDate, accesibilitypath, isSecure)
}

export const getAuthTokenFromCookie = () => {
    const storedValue: string = getCookie(AuthTokenName) as string
    if (storedValue) {
        return storedValue.split(SEPARATOR)[INDEX_Value]
    }
    return null
}

export const getAuthExpirationFromCookie = () => {
    const storedValue: string = getCookie(AuthTokenName) as string
    if (storedValue) {
        return new Date(storedValue.split(SEPARATOR)[INDEX_Expiration])
    }
    return null
}

export const getRefreshTokenFromCookie = () => {
    const storedValue: string = getCookie(RefreshTokenName) as string
    if  (storedValue) {
        return storedValue.split(SEPARATOR)[INDEX_Value]
    }
    return null
}

export const getRefreshExpirationFromCookie = () => {
    const storedValue: string = getCookie(RefreshTokenName) as string
    return new Date(storedValue.split(SEPARATOR)[INDEX_Expiration])
}

export const getUserInfoFromCookie = (): UserInfo | null => {
    try {
        const storedValue: string = getCookie(UserInfoName) as string
        if (storedValue) {
            const cookieValue = storedValue.split(SEPARATOR)[INDEX_Value]
            const parsedObject = JSON.parse(cookieValue)
            const userInfo = parsedObject as UserInfo
            return userInfo
        }
    } catch(ex) {
        console.log('get user info from cookie exception', ex)
        return null
    }
    return null
}

export const getUserInfoExpirationFromCookie = () => {
    const storedValue: string = getCookie(UserInfoName) as string
    if (storedValue) {
        return new Date(storedValue.split(SEPARATOR)[INDEX_Expiration])
    }
    return new Date(0)
}

export const removeAuthTokenFromCookie = () => {
    removeCookie(AuthTokenName, accesibilitypath, isSecure)
}

export const removeRefreshTokenFromCookie = () => {
    removeCookie(RefreshTokenName, accesibilitypath, isSecure)
}

export const removeUserInfoFromCookie = () => {
    removeCookie(UserInfoName, accesibilitypath, isSecure)
}

export const removeAllTokensFromCookie = () => {
    removeAuthTokenFromCookie()
    removeRefreshTokenFromCookie()
    removeUserInfoFromCookie()
}
