import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/slick-carousel/slick/slick.css'
import '../node_modules/slick-carousel/slick/slick-theme.css'
import { Provider } from 'react-redux'
import finaeonReduxStore from './Models/DataModels/Redux/finaeonReduxStore'

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(rootElement)

root.render(
  <React.StrictMode>
    <Provider store={finaeonReduxStore}>
      <App />
    </Provider>
  </React.StrictMode>
)
