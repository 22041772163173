export type DataFillMethodDisplayType = 'Backward' | 'Forward' | 'None'
export const DataFillMethodDisplay = {
    Backward: 'Backward' as DataFillMethodDisplayType,
    Forward: 'Forward' as DataFillMethodDisplayType,
    None: 'None' as DataFillMethodDisplayType
}

export type DataFillMethodValueType = 'BackFill' | 'ForwardFill' | 'None'
export const DataFillMethodValue = {
    Backward: 'BackFill' as DataFillMethodValueType,
    Forward: 'ForwardFill' as DataFillMethodValueType,
    None: 'None' as DataFillMethodValueType
}

export const DataFillMethodModel = {
    [DataFillMethodDisplay.Backward]: DataFillMethodValue.Backward,
    [DataFillMethodDisplay.Forward]: DataFillMethodValue.Forward,
    [DataFillMethodDisplay.None]: DataFillMethodValue.None
}

export default DataFillMethodModel
