import React from "react"
import { ReactComponent as TwoOverlappingMagnifyingGlasses } from "../../Images/svg/searchTheSearch_001.svg"
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const TwoOverlappingMagnifyingGlassesIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <TwoOverlappingMagnifyingGlasses />
  </SvgIcon>
)

export default TwoOverlappingMagnifyingGlassesIcon
