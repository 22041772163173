import { useState } from 'react'
import WorkbookSelection, { WorkbookSelectionProps } from '../../AutoTrac/SubComponents/WorkbookSelection'
import AlertModal, { AlertButtonType, AlertModalProps } from './AlertModal'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'

export interface WorkbookSelectionModalProps {
  userInfo: UserInfo | null,
  onFinal: (returnValue: boolean, workbook: string) => void,
  showModal: boolean,
  setShowModal: any,
  signOut: (logoutReason: LogoutReasonType) => void,
  clearMessagesToggle: boolean,
  clearMessages: () => void
}

const WorkbookSelectionModal = ({
  userInfo,
  onFinal,
  showModal,
  setShowModal,
  signOut,
  clearMessages,
  clearMessagesToggle
}: WorkbookSelectionModalProps) => {
  const [selected, setSelected] = useState<string>('')
  
  const body = () => {
    const workbookSelectionProps: WorkbookSelectionProps = {
      userInfo,
      onSelectWorkbook: setSelected,
      ExpandAll: true,
      signOut,
      clearMessages,
      clearMessagesToggle
    }
    
    return (<div style={Styles.WorkbookSelection}>
      <WorkbookSelection {...workbookSelectionProps}/>
    </div>)
  }
  
  const saveModalProps: AlertModalProps = {
    showModal,
    setShowModal,
    AlertTitle: 'Add Tickers To AutoTrac?',
    AlertContent: body(),
    AlertButtons: [
      {
        type: AlertButtonType.Cancel,
        display: 'Cancel',
        onClick: () => {
            setShowModal(false)
            onFinal(false, selected)
        },
        isPrimary: false,
      },
      {
        type: AlertButtonType.OK,
        display: 'Save',
        onClick: () => {
            if (!selected) return
            setShowModal(false)
            onFinal(true, selected)
        },
        isPrimary: true,
      },
    ],
    onAlertClose: () => {
      onFinal(false, selected)
      return true
    }
  }

  return (
    <AlertModal {...saveModalProps}/>
  )
}

const Styles = { 
  WorkbookSelection: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    height: '40vh',
    overflow: 'auto',
  }
}

export default WorkbookSelectionModal