import React from "react"
import { ReactComponent as DownLoad_LockedIcon } from "../../Images/svg/DownLoad_Locked.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const DownLoad_Locked = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <DownLoad_LockedIcon />
  </SvgIcon>
)

export default DownLoad_Locked