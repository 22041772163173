import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'
import { ImageNumberReference } from '../../../HelpPageCommonComponents'

const ColorCustomization = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Color Customization</h4>
                </div>
            </Box>
            <div style={{ height: '663px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustom.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization'></img>
            </div>
            <p>
                Color customization allows changing the color of the lines in the graph.
            </p>
            <div style={{ height: '678px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustomButtonFewSeries.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization Button Few Series'></img>
            </div>
            <p>
                First, graph at least 1 series. Customize colors button will be displayed. Click on Customize Colors button.
            </p>
            <div style={{ height: '663px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustomPickModal.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization Pick Modal'></img>
            </div>
            <p>
                A modal with each series' current colors are displayed. Initial preset colors are set by default.
            </p>
            <p>
                Color can be updated in 2 ways. First way is by entering the color code. Another way is by selecting the color from the color picker.
            </p>
            <p>
                To enter the color code, replace the code after the # symbol with the code of the color you wish to change to.
            </p>
            <div style={{ height: '663px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustomSetColorCode.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization Set Color Code'></img>
            </div>
            <p>
                Color will be updated upon entering the valid code code.
            </p>
            <div style={{ height: '440px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustomSetColorByPicker.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization Set Color By Picker'></img>
            </div>
            <p>
                To update the color from the color picker, click on the current color to the left of color code.
            </p>
            <div style={{ height: '711px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustomSetColorByPickerSelect-1.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization Select Color'></img>
            </div>
            <ul>
                <li>{ImageNumberReference(1)} allows you to select the darkness of the color.</li>
                <li>{ImageNumberReference(2)} allows you to select the color from the spectrum.</li>
                <li>{ImageNumberReference(3)} allows you to select the opacity of the color.</li>
            </ul>
            <p>
                Tips: Color customization modal can be moved. First, place the cursor by the header area of the modal and verify that the cursor changes to 4 sided 4 arrow cursor. Then, click and drag to where you wish to move the modal.
            </p>
            <div style={{ height: '598px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustomDrag.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization Drag'></img>
            </div>
            <p>
                The advantage of doing this that you can see the graph changing color as you change the color.
            </p>
            <div style={{ height: '618px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustomChangeColorLive.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization Live Change'></img>
            </div>
            <p>
                If you wish to change the colors back to preset default colors, click on reset colors button.
            </p>
            <div style={{ height: '287px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustomResetColor.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization Reset Color'></img>
            </div>
            <p>
                Colors are resetted to preset default colors and reset colors button is no longer visible.
            </p>
            <div style={{ height: '663px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingColorCustomResetColorResult.png' style={helpPageStyles.image} alt='Advanced Graphing Color Customization Reset Color Result'></img>
            </div>
        </div>
    )
}

export default ColorCustomization
