import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'
import { ItalicizedWorkbookText } from '../../../HelpPageCommonComponents'

const AutoTracDownloadOptions = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Download Options</h4>
                </div>
            </Box>
            <div style={{ height: '922px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options'></img>
            </div>
            <p>
                This section will describe the Download Options. Download Options contains options about what data needs to be present in the result output data file.
                Options are saved after the download for the selected <ItalicizedWorkbookText />. This is another advantage of using AutoTrac since the options do not need to be
                configured every time you need to download data with specific options.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Date Range</h5>
                </div>
            </Box>
            <p>
                The date range of the result data output file can be filtered by year or by date.
                The initial value will be defaulted to earliest available year or date of all the series in the <ItalicizedWorkbookText />.
            </p>
            <div style={{ height: '86px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_DateRange_ByYear.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Date Range By Year'></img>
            </div>
            <p>
                Select the "From Year" and "To Year" from the dropdown fields consisting earliest available year to latest available year.
            </p>
            <div style={{ height: '84px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_DateRange_ByDate.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Date Range By Date'></img>
            </div>
            <p>
                Select the "From Date" and "To Date" from the datepicker fields consisting earliest available date to latest available date.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Data Fields</h5>
                </div>
            </Box>
            <div style={{ height: '98px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_DataFields.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Data Fields'></img>
            </div>
            <p>
                Adds a column with the data for the options that are checked.
            </p>
            <div>
                <ul>
                    <li>
                        <b>Open</b>
                        <ul>
                            <li>Adds a Open column with data to the output data file.</li>
                        </ul>
                    </li>
                    <li>
                        <b>High</b>
                        <ul>
                            <li>Adds a High column with data to the output data file.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Low</b>
                        <ul>
                            <li>Adds a Low column with data to the output data file.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Close</b>
                        <ul>
                            <li>Adds a Close column with data to the output data file.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Volume</b>
                        <ul>
                            <li>Adds a Volume column with data to the output data file.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Open Interest</b>
                        <ul>
                            <li>Adds a OpenInt column with data to the output data file.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Close Only</b>
                        <ul>
                            <li>Only adds a Close column with data to the output data file.</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <p>Sample: </p>
            <div style={{ height: '245px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_DataFieldsSample.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Data Fields Sample'></img>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Adjustments</h5>
                </div>
            </Box>
            <div style={{ height: '218px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_Adjustments.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Adjustments'></img>
            </div>
            <p>Add adjustments to the price data and/or adds more columns to the result output file.</p>
            <div>
                <ul>
                    <li>
                        <b>Split Adjusted</b>
                        <ul>
                            <li>Price data in the output data file are split adjusted.</li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Inflation Adjusted</b>
                        <ul>
                            <li>Adds Inflation adjusted price data columns to the output data file.</li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Average</b>
                        <ul>
                            <li>Adds a Period_Average column with data to the output data file.</li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Annual Percent Change</b>
                        <ul>
                            <li>Adds a Annual Percent Change column with data to the output data file.</li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Period Percent Change</b>
                        <ul>
                            <li>Adds a Period Percent Change related columns with data to the output data file.</li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Total Return</b>
                        <ul>
                            <li>Adds a Total Return columns with data to the output data file.</li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Moving Average</b>
                        <ul>
                            <li>Adds a Moving Average column with calculated data based on the selection to the output data file.</li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Currency</b>
                        <ul>
                            <li>
                                Sets the price values of the series to the selected currency.
                                Default is Source Currency which is the currency that the series is based.
                                If another currency besides "Source Currency" is selected, price values are converted to selected currency.
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Data Frequency</b>
                        <ul>
                            <li>The increments of the data or how often the price data should be outputted.</li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Date Format</b>
                        <ul>
                            <li>Date Format to display inthe output data file.</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Data Fill Method</h5>
                </div>
            </Box>
            <div style={{ height: '63px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_DataFill.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Data Fill Method'></img>
            </div>
            <p>
                This options fills in empty data.
            </p>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: This is only available for Pivoted worksheet.
            </p>
            <ul>
                <li>
                    <b>Backward</b>
                    <ul>
                        <li>Fills in empty data with the next available previous data.</li>
                    </ul>
                </li>
                <li>
                    <b>Forward</b>
                    <ul>
                        <li>Fills in empty data with the next available recent data.</li>
                    </ul>
                </li>
                <li>
                    <b>None</b>
                    <ul>
                        <li>Empty data is not filled.</li>
                    </ul>
                </li>
            </ul>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Equity Fields</h5>
                </div>
            </Box>
            <div style={{ height: '64px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_Equity.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Equity Fields'></img>
            </div>
            <p>Checking the boxes will add the applicable column to the data file.</p>
            <div>
                <p>Sample:</p>
                <div style={{ height: '140px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_Equity_Sample.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Equity Fields Sample'></img>
                </div>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Corporate Actions</h5>
                </div>
            </Box>
            <div style={{ height: '56px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_CorporateActions.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Corporate Actions'></img>
            </div>
            <ul>
                <li>
                    Splits and Dividends
                    <ul>
                        <li>
                            Checking this option adds a new tab in the result output file for excel. The sheet contains information about splits and dividends of the series.
                            Stacked for CSV output file.
                        </li>
                        <li>
                            <div style={{ height: '262px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_CorporateActions_SplitDividends.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Splits and Dividends'></img>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Fundamentals and Ratios</h5>
                </div>
            </Box>
            <div style={{ height: '95px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_FundamentalsRatios.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Fundamentals and Ratios'></img>
            </div>
            <p>
                Checking this option adds a new tab in the result output file for excel. Stacked for CSV output file.
            </p>
            <ul>
                <li>
                    Annual Fundamentals
                    <ul>
                        <li>
                            Sample:
                        </li>
                        <li>
                            <div style={{ height: '151px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_CorporateActions_AnnualFundamentals.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Annual Fundamentals'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    Annual Ratios
                    <ul>
                        <li>
                            Sample:
                        </li>
                        <li>
                            <div style={{ height: '178px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_CorporateActions_AnnualRatios.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Annual Ratios'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    Estimates
                    <ul>
                        <li>
                            Adds EPS Estimates tab if available.
                        </li>
                    </ul>
                </li>
                <li>
                    Quarterly Fundamentals
                    <ul>
                        <li>
                            Sample:
                        </li>
                        <li>
                            <div style={{ height: '176px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_CorporateActions_QuarterlyFundamentals.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Quarterly Fundamentals'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    Quarterly Ratios
                    <ul>
                        <li>
                            Sample:
                        </li>
                        <li>
                            <div style={{ height: '188px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_CorporateActions_QuarterlyRatios.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Quarterly Ratios'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    Share Information
                    <ul>
                        <li>
                            Sample:
                        </li>
                        <li>
                            <div style={{ height: '146px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_CorporateActions_ShareInformation.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Share Information'></img>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Worksheet Format</h5>
                </div>
            </Box>
            <div style={{ height: '104px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_WorksheetFormat.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options Worksheet Format'></img>
            </div>
            <ul>
                <li>
                    Stacked
                    <ul>
                        <li>
                            Stacked format consists of Ticker column and other selected columns in the result output data file.
                        </li>
                        <li>
                            Sample:
                        </li>
                        <li>
                            <div style={{ height: '476px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_WorksheetFormat_Stacked.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options WorksheetFormat Stacked'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    Pivoted
                    <ul>
                        <li>
                            Pivoted format consists of category columns per series in the result output data file.
                        </li>
                        <li>
                            Sample:
                        </li>
                        <li>
                            <div style={{ height: '168px' }}>
                                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Options_WorksheetFormat_Pivoted.png' style={helpPageStyles.image} alt='Advanced AutoTrac Options WorksheetFormat Pivoted'></img>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    Output Format
                    <ul>
                        <li>
                            Sets the output format of the result output data file.
                            <ul>
                                <li>
                                    EXCEL
                                    <ul>
                                        <li>Sets the output format to excel (.xlsx)</li>
                                    </ul>
                                </li>
                                <li>
                                    CSV
                                    <ul>
                                        <li>Sets the output format to comma separate values (.csv)</li>
                                    </ul>
                                </li>
                                <li>
                                    ZIP
                                    <ul>
                                        <li>Sets the output format to a zip (.zip) format consisting of csv file per series in the <ItalicizedWorkbookText />.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default AutoTracDownloadOptions
