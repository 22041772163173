import { useEffect, useState } from "react"
import { CustomIndexMember } from "../../../Models/DataModels/Responses/ToolsResponses"
import CustomIndexSelectionModal, { CustomIndexSelectionModalProps } from "../Modals/CusomIndexSelectionModal"
import { LogoutReasonType } from "../../../Models/DataModels/Requests/AuthRequests"

export interface AddTickersToCustomIndexProps {
    showCustomIndexSelectionModal: boolean,
    setShowCustomIndexSelectionModal: (value: boolean) => void,
    customIndexData: any,
    clearMessagesToggle: boolean,
    clearMessages: () => void,
    signOut: (logoutReason: LogoutReasonType) => void
}

const AddTickersToCustomIndex = ({
    showCustomIndexSelectionModal,
    setShowCustomIndexSelectionModal,
    customIndexData,
    clearMessagesToggle,
    clearMessages,
    signOut
}: AddTickersToCustomIndexProps) => {
    const [selectedTickersToAddToCustomIndex, setSelectedTickersToAddToCustomIndex] = useState<Map<string, CustomIndexMember>>(new Map<string, CustomIndexMember>())
    
    const processAddToCustomIndex = (data: any) => {
        if (!customIndexData || !customIndexData?.length) return

        const values = Object.values(data).map((d: any) => {
            const value = { 
                key: d.symbol, 
                value: {
                    tickerID: d.id,
                    tickerName: d.symbol,
                    description: d.description
                }
            }
            return value
        })

        const tickerMap = new Map(values.map((obj) => [obj.key, obj.value]))

        setSelectedTickersToAddToCustomIndex(tickerMap)

        if (tickerMap.size === 0) return

        setShowCustomIndexSelectionModal(true)
    }

    useEffect(() => {
        processAddToCustomIndex(customIndexData)
    }, [customIndexData])

    const customIndexSelectionModalProps: CustomIndexSelectionModalProps = {
        membersToBeAdded: selectedTickersToAddToCustomIndex,
        showModal: showCustomIndexSelectionModal,
        setShowModal: setShowCustomIndexSelectionModal,
        clearMessages,
        clearMessagesToggle,
        signOut
    }

    return <>
        {showCustomIndexSelectionModal ? <CustomIndexSelectionModal {...customIndexSelectionModalProps} /> : <></>}
    </>
}

export default AddTickersToCustomIndex
