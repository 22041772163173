import { Box } from "@mui/material"
import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"
import InfoSvg from "../../../Icons/InfoSvgIcon"
import GraphwLines from "../../../Icons/GraphwLines"
import DownLoad from "../../../Icons/DownLoad"

const QuickActions = () => {
    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }}>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <br />
                        <br />
                        <br />
                        <h2 style={{ textDecoration: 'underline' }}>
                            Quick Actions
                        </h2>
                    </div>
                </Box>
                <br />
                <div style={{ height: '188px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20QuickActions.png' style={helpPageStyles.image} alt='Quick Actions Buttons' />
                </div>
                <br />
                <div>
                    There are Quick Action buttons which are associated with each row of data. The quick actions will only affect the data for its respective row.
                    The images in the following images will be using _SPXTRD as an example.
                    <br />
                    <br />

                    The Info <InfoSvg /> button will display more in-depth information for the data in its row, as seen in the image below:
                    <br />
                    <div style={{ height: '599px' }}>
                        <img src="https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20QuickActions%20InfoModal.png" style={helpPageStyles.image} alt='Quick Actions Info Modal' />
                    </div>
                    <br />
                    Depending on the index series, index members (all the series/tickers under the index) data may be available.
                    <div style={{ height: '685px' }}>
                        <img src="https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20QuickActions%20InfoModalWithIndexMembers.png" style={helpPageStyles.image} alt='Quick Actions Info Modal Index Members' />
                    </div>
                    <br />
                    <br />
                    The Graph <GraphwLines /> button will graph only the data in the individual row in a line graph format:
                    <br />
                    <div style={{ height: '582px' }}>
                        <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20QuickActions%20LineGraphExample.png' style={helpPageStyles.image} alt='Quick Actions Graph' />
                    </div>
                    <br />
                    <br />
                    The download <DownLoad /> button will download the data in the row.
                    The download options in the pop-up for individual data rows mirror that of the download options pop-up in the action toolbar:
                    <br />
                    <div style={{ height: '600px' }}>
                        <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20QuickActions%20DownloadModal.png' style={helpPageStyles.image} alt='Quick Actions Download' />
                    </div>
                    <br />
                </div>
            </div>
        </React.Fragment>
    )
}

export default QuickActions