import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import DateYearRangeInput from './DateYearRangeInput'
import { getLocallyStoredCountries, getLocallyStoredEventTopics, getLocallyStoredEventTypes } from '../../../Services/DropDownValuesService'
import { SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import React from 'react'
import { renderAutoComputeOption } from './GFDatabase2Point0'

const EventsInTime = ({ searchEngineOptions, theme }: any) => {

  const [countryList, setCountryList] = useState<LabelValuePair[]>([])
  const [eventTypeList, setEventTypeList] = useState<LabelValuePair[]>([])
  const [topicList, setTopicList] = useState<LabelValuePair[]>([])
  const [eventTypeToolTipMessage, setEventTypeToolTipMessage] = useState<string>('')

  const setToolTipMessage = () => {
    switch (searchEngineOptions.eventType) {
      case 'Bear Market':
        setEventTypeToolTipMessage('A record of events that note when a financial market entered a bear market.')
        break
      case 'Event':
        setEventTypeToolTipMessage('A general event of note.')
        break
      case 'Events':
        setEventTypeToolTipMessage('A general event of note.')
        break
      case 'Gold Standard':
        setEventTypeToolTipMessage('A record when a government adopts or removes the use of gold as collateral backing for its currency.')
        break
      case 'Head of Government':
        setEventTypeToolTipMessage('A person that is noted as the leader of a government body.')
        break
      case 'Head of Military':
        setEventTypeToolTipMessage('A person that is noted as the leader of a military body.')
        break
      case 'Head of Parliament':
        setEventTypeToolTipMessage('A person that is noted as the leader of a parliamentary body.')
        break
      case 'Head of State':
        setEventTypeToolTipMessage('A person that is noted as the leader of a country or commonwealth.')
        break
      case 'Recession':
        setEventTypeToolTipMessage('A record of events that note when a country enters and exists an economic recession.')
        break
      case 'Sovereign Default':
        setEventTypeToolTipMessage('A record of events that note when a country fails to pay the debt it has previously issued.')
        break
      default:
        setEventTypeToolTipMessage('')
        break
    }
  }

  useEffect(() => {
    getLocallyStoredCountries(SearchDatabaseTypes.GFDatabase).then((value: LabelValuePair[] | null) => value && setCountryList(value), () => { })
    getLocallyStoredEventTypes().then((value: LabelValuePair[] | null) => value && setEventTypeList(value), () => { })
    getLocallyStoredEventTopics().then((value: LabelValuePair[] | null) => value && setTopicList(value), () => { })
  }, [])

  useEffect(() => {
    setToolTipMessage()
  }, [searchEngineOptions.eventType])

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectCountry'
              options={countryList}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setCountry(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Select Country or Group'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={eventsInTimeStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectEventType'
              options={eventTypeList}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setEventType(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label={
                    <React.Fragment>
                      <span>Select Event Type </span>
                      {searchEngineOptions.eventType &&
                        <Tooltip title={eventTypeToolTipMessage}>
                          <HelpOutlineIcon />
                        </Tooltip>}
                    </React.Fragment>
                  }
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={eventsInTimeStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectTopic'
              options={topicList}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setTopic(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Filter by Topic'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={eventsInTimeStyles.wholeLineInput}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <DateYearRangeInput setIsDatesValid={searchEngineOptions.setDatesValid} setValidStartYear={searchEngineOptions.setStartYear} setValidEndYear={searchEngineOptions.setEndYear} setIsOnlyInYearsRange={(isOnlyInYearsRange: boolean) => { }} startYearGridItemSizeOverride={4} EndYearGridItemSizeOverride={4} inRangeCheckboxGridItemSizeOverride={4} hideActiveInRangeCheckBox />
        </Grid>
      </div>
    </ThemeProvider>
  )
}

const eventsInTimeStyles = {
  row: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap'
  },
  wholeLineInput: {
    paddingBottom: 1,
    width: '100%'
  }
}

export default EventsInTime
