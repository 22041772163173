import moment from "moment"
import { AppConfigurations } from "../Models/DataModels/Common/AppConfigurationsModel"

const extraLoggingEnabled: boolean = AppConfigurations.extraLoggingEnabled

console.log('extraLoggingEnabled:', extraLoggingEnabled)

export const LOG = (message: string) => {
    if (extraLoggingEnabled) {
        const currentTime: moment.Moment = moment()
        console.log(`${currentTime.format('YYYY-MM-DD HH:mm:ss.SSS Z')} - ${message}`)
    }
}
