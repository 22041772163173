import { Box, Button, styled } from '@mui/material'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { CustomBtnBlue, CustomBtnCloseCancel } from '../GlobalSettings/CustomStyles'

const ResetAllConfirmation = ({ modalProps }: any) => {
    const handleClose = () => {
        modalProps.setIsCancel(false)
        modalProps.setShowModal(false)
    }

    const handleCancel = () => {
        modalProps.cancelScreening()
        modalProps.setIsCancel(false)
        modalProps.setShowModal(false)
    }

    const handleReset = () => {
        modalProps.resetAll()
        modalProps.setIsCancel(false)
        modalProps.setShowModal(false)
    }

    return (
        <React.Fragment>
            <Modal
                show={modalProps.showModal}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reset All Values?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {modalProps.isCancel ? <Box style={{ textAlign: 'center' }}>Canceling will take you back to the Fama French List and wipe all changes, are you sure?</Box> : <Box style={{ textAlign: 'center' }}> Resetting all will wipe ALL of the values and start you over, are you sure you want to do this?</Box>}
                </Modal.Body>

                <Modal.Footer>
                <CustomBtnCloseCancel variant='outlined' onClick={handleClose}>Cancel</CustomBtnCloseCancel>
                <CustomBtnBlue variant='contained' onClick={modalProps.isCancel ? handleCancel : handleReset}>Yes</CustomBtnBlue>
                    
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
    
}

export default ResetAllConfirmation