export type FundamentalsAndRatiosFieldType = 'Annual Fundamentals' | 'Annual Ratios' | 'Estimates' |
    'Quarterly Fundamentals' | 'Quarterly Ratios'
export const FundamentalsAndRatiosField = {
    AnnualFundamentals: 'Annual Fundamentals' as FundamentalsAndRatiosFieldType,
    AnnualRatios: 'Annual Ratios' as FundamentalsAndRatiosFieldType,
    Estimates: 'Estimates' as FundamentalsAndRatiosFieldType,
    QuarterlyFundamentals: 'Quarterly Fundamentals' as FundamentalsAndRatiosFieldType,
    QuarterlyRatios: 'Quarterly Ratios' as FundamentalsAndRatiosFieldType
}

export interface FundamentalsAndRatiosFields {
    AnnualFundamentals: boolean
    AnnualRatios: boolean
    Estimates: boolean
    QuarterlyFundamentals: boolean
    QuarterlyRatios: boolean
}

export default FundamentalsAndRatiosField
