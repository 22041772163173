import { Button, Radio, ToggleButton, Checkbox, Chip, Fab, Tab, styled } from "@mui/material"
import { createTheme } from '@mui/material/styles'

export const CustomBtn = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#81d041",
    border: "1px solid #81d041",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#6fb436",
      border: "1px solid #6fb436",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  "&.MuiButton-outlined": {
    color: "#007ea8",
    backgroundColor: "transparent",
    border: "1px solid #007ea8",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#979797",
      border: "1px solid #979797",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const CustomBtnBlue = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#007ea8",
    border: "1px solid #007ea8",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#0d7091",
      border: "1px solid #0d7091",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const CustomBtnOutlined = styled(Button)(({ theme }) => ({

  "&.MuiButton-outlined": {
    color: "#007ea8",
    backgroundColor: "transparent",
    border: "1px solid #007ea8",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#979797",
      border: "1px solid #979797",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const CustomBtnCloseCancel = styled(Button)(({ theme }) => ({

  "&.MuiButton-outlined": {
    color: "#007ea8",
    backgroundColor: "transparent",
    border: "1px solid #007ea8",
    padding: "6px 16px",
    marginRight: "10px",
    "&:hover": {
      color: "#b9394a",
      backgroundColor: "#fce3e7",
      border: "1px solid #b9394a",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const SyncBtn = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#8e95ce",
    border: "1px solid #8e95ce",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#737ab5",
      border: "1px solid #737ab5",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const MessageBtn = styled(Button)(({ theme }) => ({

  "&.MuiButton-outlined": {
    color: "#007ea8",
    backgroundColor: "transparent",
    border: "1px solid ##007ea8",
  },
  "&:hover": {
    color: "#007ea8",
    backgroundColor: "#f5fcfe",
  },
}))

export const LoggoffBtn = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#ce4256",
    border: "1px solid #ce4256",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#b33749",
      border: "1px solid #b33749",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const BtnHeaderOutlined = styled(Button)(({ theme }) => ({

  "&.MuiButton-outlined": {
    color: "#007ea8",
    backgroundColor: "transparent",
    border: "1px solid #007ea8",
    "&:hover": {
      color: "#007ea8",
      backgroundColor: "#f5fcfe",
      border: "1px solid #007ea8",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const customTheme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: "rgba(0,0,0,0.6)",
          "&.Mui-checked": {
            color: "#007ea8",
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#333333",
          "&.Mui-typography-h6": {
            color: "#333333"
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "#007ea8",
          "&.MuiCheckbox-indeterminate": {
            color: "#007ea8 !important"
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          color: "#333333",
          "&.MuiToolbar-gutters": {
            backgroundColor: "rgba(232, 245, 253, 0.3)",
            width: "100%"
          },
          "&.MuiTablePagination-toolbar": {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          flex: "1 1 2%",
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "p[class^='MuiTablePagination']": {
            margin: 'auto'
          },
          "p[class^='MuiTablePagination-displayedRows']": {
            display: 'inline'
          }
        }
      }
    }
  }
})

export const AddBtn = styled(Button)(({ theme }) => ({

  "&.MuiButton-root": {
    color: "#007ea8",
    backgroundColor: "transparent",
    "&:hover": {
      color: "#007ea8",
      backgroundColor: "#e9f9fe",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const RemoveBtn = styled(Button)(({ theme }) => ({

  "&.MuiButton-root": {
    color: "#b9394a",
    backgroundColor: "transparent",
    "&:hover": {
      color: "#b9394a",
      backgroundColor: "#fce3e7",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const CustomToggle = styled(ToggleButton)(({ theme }) => ({

  "&.Mui-selected": {
    color: "#007ea8",
    backgroundColor: "#e9f9fe",
    "&:hover": {
      color: "#007ea8",
      backgroundColor: "#d1f3fe",
    },
  },
  "&:hover": {
    color: "#007ea8",
    backgroundColor: "#f5fcfe",
  },
}))

export const BtnEnabledDisabled = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#007ea8",
    border: "1px solid #007ea8",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#0d7091",
      border: "1px solid #0d7091",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  "&.Mui-disabled": {
    color: "#FFFFFF",
    backgroundColor: "#6cbbd5",
    border: "1px solid #6cbbd5",
  },
}))

export const BtnHeaderEnabledDisabled = styled(Button)(({ theme }) => ({

  "&.MuiButton-outlined": {
    color: "#007ea8",
    backgroundColor: "transparent",
    border: "1px solid #007ea8",
    "&:hover": {
      color: "#007ea8",
      backgroundColor: "#f5fcfe",
      border: "1px solid #007ea8",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.3)",
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.3)",
  },
}))

export const RadioBlue = styled(Radio)(({ theme }) => ({
  "&.Mui-checked": {
    "&.MuiRadio-colorPrimary": {
      position: "relative",
      zIndex: 0,
      border: "none",
      color: "#007ea8",
      borderRadius: 3
    },
    "&.MuiRadio-colorPrimary:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "transparent",
      zIndex: -1,
      borderColor: "transparent"
    }
  },
  "&:not(.Mui-checked) .MuiRadio-colorPrimary": {
    position: "relative",
    zIndex: 1,
    border: "none",
    borderRadius: 0
  },
  "&:not(.Mui-checked) .MuiRadio-colorPrimary:after": {
    content: '""',
    left: 4,
    top: 4,
    height: 15,
    width: 15,
    position: "absolute",
    backgroundColor: "white",
    zIndex: -1,
    borderColor: "transparent"
  }
}))

export const CheckboxBlue = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": {
    "&.MuiCheckbox-colorPrimary": {
      position: "relative",
      zIndex: 0,
      border: "none",
      color: "#007ea8",
      borderRadius: 3
    },
    "&.MuiCheckbox-colorPrimary:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "transparent",
      zIndex: -1,
      borderColor: "transparent"
    }
  },
  "&:not(.Mui-checked) .MuiCheckbox-colorPrimary": {
    position: "relative",
    zIndex: 1,
    border: "none",
    borderRadius: 0
  },
  "&:not(.Mui-checked) .MuiCheckbox-colorPrimary:after": {
    content: '""',
    left: 4,
    top: 4,
    height: 15,
    width: 15,
    position: "absolute",
    backgroundColor: "white",
    zIndex: -1,
    borderColor: "transparent"
  }
}))

export const TxtBtnCloseCancel = styled(Button)(({ theme }) => ({

  "&.MuiButton-textPrimary": {
    color: "#007ea8",
    backgroundColor: "transparent",
    border: "1px solid #007ea8",
    "&:hover": {
      color: "#b9394a",
      backgroundColor: "#fce3e7",
      border: "1px solid #b9394a",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const BtnClearReset = styled(Button)(({ theme }) => ({

  "&.Mui-selected": {
    color: "#007ea8",
    border: "1px solid #007ea8",
    backgroundColor: "#e9f9fe",
  },
  "&:hover": {
    color: "#007ea8",
    border: "1px solid #007ea8",
    backgroundColor: "#f5fcfe",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
}))

export const CustomBtnAddEdit = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#81d041",
    border: "1px solid #81d041",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#6fb436",
      border: "1px solid #6fb436",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const DeleteBtn = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#ce4256",
    border: "1px solid #ce4256",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#b33749",
      border: "1px solid #b33749",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const DownloadBtn = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#f1917f",
    border: "1px solid #f1917f",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#de7966",
      border: "1px solid #de7966",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const BtnZoom = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#007ea8",
    border: "1px solid #007ea8",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#0d7091",
      border: "1px solid #0d7091",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  "&.Mui-disabled": {
    color: "#FFFFFF",
    backgroundColor: "#6cbbd5 !important",
    border: "1px solid #6cbbd5"
  },
}))

export const CustomChip = styled(Chip)(({ theme }) => ({
  "&.MuiChip-filled": {
    backgroundColor: "#e1e1fe",
    color: "#3f3f6c"
  },
}))

export const BtnGraphSearch = styled(Button)(({ theme }) => ({

  "&.MuiButton-contained": {
    color: "#FFFFFF",
    backgroundColor: "#527a7a",
    border: "1px solid #527a7a",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#3f6161",
      border: "1px solid #3f6161",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const CustomFab = styled(Fab)(({ theme }) => ({

  "&.MuiFab-extended": {
    color: "#FFFFFF",
    backgroundColor: "#8c9fdd",
    border: "1px solid #8c9fdd",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#7588bc",
      border: "1px solid #7588bc",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))

export const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    color: "#007ea8",
  },
}))