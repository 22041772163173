import { Refresh } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import { CSSProperties, useEffect } from 'react'
import { AccessSet, UserAccessCollection } from '../../../Models/DataModels/Common/UserAccessModel'
import { GFDToastError } from '../../Common/Utility/GFDToastify'
import AccessCard, { AccessCardProps } from './AccessCard'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'

export interface AccessProps {
  AccessInfo?: UserAccessCollection,
  TriggerRefreshAccessInfo: () => void,
  AccessInfoRequestInTransit: boolean,
  AccessInfoError: string | undefined,
  ClearAccessInfoError: () => void,
}

const Access = ({
  AccessInfo,
  TriggerRefreshAccessInfo,
  AccessInfoRequestInTransit,
  AccessInfoError,
  ClearAccessInfoError
}: AccessProps) => {
  
  useEffect(() => {
    if (AccessInfoError?.trim()) {
      GFDToastError(AccessInfoError)
      ClearAccessInfoError()
    }
  }, [AccessInfoError])

  const refreshAll = () => {
    TriggerRefreshAccessInfo()
  }

  const renderRefreshAll = () => {
    return (<>
      <div style={styles.refreshAll as CSSProperties}>
        {AccessInfoRequestInTransit ?
          renderProgress()
          :
          <IconButton title='Refresh Access Info' aria-label='Refresh Access Info' component='label' onClick={refreshAll}>
            <Refresh />
          </IconButton>
        }
      </div>
      <br />
    </>)
  }

  const renderProgress = () => {
    return <>
      <IconButton aria-label='Refreshing Access Info' component='label'>
        <CircularProgress style={globalStyles.circularProgress} title='Refreshing Access Info' aria-label='Refreshing Access Info' />
      </IconButton>
    </>
  }

  const renderAccessTitle = () => {
    const title = 'Access'
  
    return (<>
      {title}
    </>)
  }

  const renderAccessInfo = () => {
    if (!AccessInfo) return <></>

    return (<>
      <h5>{renderAccessTitle()}</h5>
      <div style={styles.scroll}>
        {AccessInfo?.UserAccesses?.map((userAccess, index) => {
          const accessCardProps: AccessCardProps = {
            Expires: userAccess.Expires,
            AccessSet: AccessInfo.AccessSets.find(set => set.SetID === userAccess.SetID) || {
              Name: 'Not found',
              SetID: userAccess.SetID,
              AccessRules: [],
              SetDownloadLimits: []
            } as AccessSet,
            DownloadLimits: AccessInfo.DownloadLimits
          }

          return <AccessCard key={index} {...accessCardProps}/>
        })}
      </div>
    </>)
  }

  return (
    <div>
      {renderRefreshAll()}
      {renderAccessInfo()}
    </div>
  )
}

const styles = {
  refreshAll: {
    float: 'right',
    clear: 'both'
  },
  scroll: {
    overflow: 'auto'
  }
}

export default Access