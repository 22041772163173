import { Box } from "@mui/material"
import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"
import { BlueBlueText, GreenGreenText, ImageNumberReference, MarkedGreyGreyText, MarkedOrangeOrangeText, MarkedRedRedText, MarkedSalmonPinkText, MarkedYellowYellowText, PurplePurpleText } from "../../HelpPageCommonComponents"

const CurrencyConverter = () => {
    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }}>
                <Box sx={helpPageStyles.heading}>
                    <div>
                        <br />
                        <br />
                        <h2 style={{ textDecoration: 'underline' }}>
                            Currency Converter
                        </h2>
                    </div>
                </Box>
                <div style={{ height: '603px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CurrencyConverter.png' alt='Currency Converter' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The Currency Converter tool provides a graphical representation of the historical exchange rate between two or more currencies.
                    You can also download the data generated from the Currency Converter tool.
                </p>
                <div style={{ height: '611px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CurrencyConverter%20MainOptions.png' alt='Currency Converter Main Options' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The <MarkedRedRedText /> box ({ImageNumberReference(1)}) contains options for either converting a single currency to the target currency, or multiple currencies to the target currency.
                    The default option is for Single Currency Conversion.
                </p>
                <p>
                    The <MarkedYellowYellowText /> box ({ImageNumberReference(2)}) contains the dropdown options for the source currency to be converted into the target currency.
                </p>
                <p>
                    The <BlueBlueText /> box ({ImageNumberReference(3)}) contains the dropdown options for the target currency for the source currency to be converted into.
                </p>
                <p>
                    The <GreenGreenText /> box ({ImageNumberReference(4)}) contains the options for the adjustments to be made for the generated data.
                    Although Split Adjusted is checked by default, you can choose to not have any adjustments made (uncheck Split Adjusted).
                    Conversely, multiple options may be checked.
                </p>
                <p>
                    The <PurplePurpleText /> box ({ImageNumberReference(5)}) contains the dropdown options for data frequency (daily, weekly, monthly, quarterly, or annual).
                </p>
                <p>
                    The <MarkedOrangeOrangeText /> box ({ImageNumberReference(6)}) contains the dropdown options for the date format (American, European, or UTC).
                </p>
                <p>
                    The <MarkedGreyGreyText /> box ({ImageNumberReference(7)}) contains the options for the output format. If you choose to download your data, this is the format in which it will be dowloaded.
                </p>
                <br />
                <div style={{ height: '466px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CurrencyConverterOptions.png' alt='Currency Converter Options' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The <MarkedRedRedText /> box ({ImageNumberReference(1)}) contains the options for obtaining the desired data. Much like the Capitalization tool,
                    the <MarkedSalmonPinkText /> arrow ({ImageNumberReference(2)}) points to the "Download" button which will allow you to download the data in the format selected in the above Currency Converter options.
                    This will be an Excel spreedsheet unless you elect otherwise in the Output Format option.
                    The <BlueBlueText /> arrow ({ImageNumberReference(3)}) points to the "Graph Data" button which will provide a graphical representation of the requested data, as seen in the example image below.
                </p>
                <br />
                <div style={{ height: '583px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CurrencyConverterGraph.png' alt='Currency Converter Graph' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The graph generated for your Currency Converter selections may also be downloaded by selecting "image" via the "Download Type" dropdown box and pressing the red download icon the right of the box.
                </p>
            </div>
        </React.Fragment>
    )
}

export default CurrencyConverter