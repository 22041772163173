import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'

const ComparisonSection = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Comparison</h4>
                </div>
            </Box>
            <div style={{ height: '474px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingComparison.png' style={helpPageStyles.image} alt='Advanced Graphing Comparison'></img>
            </div>
            <p>
                Comparison allows comparing primary series (series entered in the text box) against multiple series.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Compare Series</h5>
                </div>
            </Box>
            <div style={{ height: '556px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingComparisonCompareSeries.png' style={helpPageStyles.image} alt='Advanced Graphing Comparison Compare Series'></img>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h6>Adding Comparison Series</h6>
                </div>
            </Box>
            <div style={{ height: '569px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingComparisonCompareSeriesAdd.png' style={helpPageStyles.image} alt='Advanced Graphing Comparison Compare Series Add'></img>
            </div>
            <p>
                Select Comparison Type other than "None". This will display the "Search Ticker to Compare" text box and "Tickers for Comparison" table.
                Click on "Search Ticker to Compare" text box and start entering the keywords for the series such as series' symbol or description.
                Possible matches are displayed as you type. Select the series that you want to compare with by clicking on the series or use the arrows keys to go through the list and hit the enter key to add.
                Alternatively, you may enter the series' symbol and click on the plus button. Symbol must be valid to add.
            </p>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: You may compare up to 10 series.
            </p>
            <div style={{ height: '532px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingComparisonCompareSeriesRemove.png' style={helpPageStyles.image} alt='Advanced Graphing Comparison Compare Series Remove'></img>
            </div>

            <p>Clicking on the circled red x will remove the series.</p>
            <div>
                <ul>
                    <li>
                        <b>Direct</b>
                        <ul>
                            <li>
                                A direct comparison uses the date range of the primary series to compare the adjusted closing price of the primary series to one of more other series.
                                <div style={{ height: '388px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingComparisonCompareSeriesDirectGraph.png' style={helpPageStyles.image} alt='Advanced Graphing Comparison Compare Series Direct Graph'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Indirect</b>
                        <ul>
                            <li>
                                Graphs comparison series as is without modification.
                                <div style={{ height: '384px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingComparisonCompareSeriesIndirectGraph.png' style={helpPageStyles.image} alt='Advanced Graphing Comparison Compare Series Indirect Graph'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Dual Scale</b>
                        <ul>
                            <li>
                                Graphs comparison series on a secondary scale.
                                <div style={{ height: '389px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingComparisonCompareSeriesDualScaleGraph.png' style={helpPageStyles.image} alt='Advanced Graphing Comparison Compare Series Dual Scale Graph'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Percentage</b>
                        <ul>
                            <li>
                                A percentage comparison uses the date range of the primary series to compare the movement, on a percentage basis, of the primary series to one or more of other series.
                                <div style={{ height: '379px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingComparisonCompareSeriesPercentageGraph.png' style={helpPageStyles.image} alt='Advanced Graphing Comparison Compare Series Percentage Graph'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Divide By</h5>
                </div>
            </Box>
            <p>
                A "divide by" comparison uses the date range of the primary series to compare the adjusted closing price of that primary series to ONE other series.  Specifically, the closing price of the primary series is divided by the closing price of the secondary series used in the comparison.
            </p>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: Only applicable to 1 comparison series.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Subtract From</h5>
                </div>
            </Box>
            <p>
                A "subtract from" comparison uses the date range of the primary series to compare the adjusted closing price of that primary series to ONE other series.  Specifically, the closing price of the primary series is subtracted by the closing price of the secondary series used in the comparison.
            </p>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: Only applicable to 1 comparison series.
            </p>
        </div>
    )
}

export default ComparisonSection
