import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Cell, LabelList, Tooltip, ReferenceLine } from 'recharts'
import { Payload } from 'recharts/types/component/DefaultLegendContent'
import { SearchResultData } from '../../../Models/DataModels/Common/ToolsModel'
import { graphCompanyLogoSVG, sourceTextSVG } from '../../Common/Modals/GraphUtil'
import { getNextKellyColorSkipLight } from '../../../Services/ColorService'
import { Box, Paper } from '@mui/material'
import { AppConfigurations, chartSizes } from '../../../Models/DataModels/Common/AppConfigurationsModel'

export interface SelectedSearchResultsBarChartProps {
    imageID: string,
    chartTitle: string,
    searchData: SearchResultData[]
}

const SelectedSearchResultsBarChart = ({
    imageID,
    chartTitle,
    searchData
}: SelectedSearchResultsBarChartProps) => {

    const BarChartTooltip = ({ payload }: any) => {
        const entry: any | null = payload && payload.length > 0 ? payload[0].payload : null
        const toolTipLabel: string = `${entry?.ticker} - ${entry?.seriesName}`
        return (
            <Paper elevation={3} style={{ padding: 2 }} >
                <div>
                    {`${toolTipLabel}: `}
                    <span>
                        <b>
                            <p>
                                Close Price: {entry?.closePrice}
                            </p>
                        </b>
                    </span>
                </div>
            </Paper>
        )
    }

    const legendData = (): Payload[] => {
        const payload: Payload[] = []
        searchData.forEach(
            (entry: SearchResultData, index: number) => {
                const nextColorCode: string = getNextKellyColorSkipLight(index + 1)
                payload.push(
                    {
                        id: entry.ticker,
                        type: 'square',
                        value: `${entry.ticker}: ${entry.closePrice}`,
                        color: nextColorCode
                    }
                )
            }
        )
        return payload
    }

    return (
        <Box
            id={imageID}
            marginBottom={'40px'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            style={{ backgroundColor: 'white' }}
        >
            <strong style={{ paddingLeft: '10%', fontSize: '20px' }}>{chartTitle}</strong>
            <ResponsiveContainer width='100%' height={chartSizes.large} className='large-graph-container'>
                <BarChart
                    width={500}
                    height={300}
                    data={searchData}
                    margin={{
                        top: 50,
                        right: 30,
                        left: 20,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='ticker' hide={false} />
                    <YAxis type='number' />
                    <Tooltip content={BarChartTooltip} />
                    {searchData.length <= AppConfigurations.selectedResutltsBarChartLegendDisplayThreshold && <Legend payload={(legendData())} />}
                    <Bar dataKey='closePrice' fill='#82ca9d' name='Close Price'>
                        {searchData.map(
                            (entry: SearchResultData, index: number) => {
                                const nextColorCode: string = getNextKellyColorSkipLight(index + 1)
                                return <Cell key={`cell=${index}`} fill={nextColorCode} name={entry.ticker} />
                            }
                        )}
                        <LabelList dataKey='ticker' position='top' angle={-90} offset={40} />
                    </Bar>
                    <ReferenceLine y={0} stroke='#000' />
                    {graphCompanyLogoSVG()}
                    {sourceTextSVG()}
                </BarChart>
            </ResponsiveContainer>
        </Box>

    )
}

export default SelectedSearchResultsBarChart
