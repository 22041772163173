import { Box, Button } from "@mui/material"
import React from "react"
import { Modal } from "react-bootstrap"
import { CustomBtnBlue, CustomBtnCloseCancel } from "../GlobalSettings/CustomStyles"

const SearchFieldChangeModal = ({ modalProps }: any) => {
    const handleClose = () => {
        modalProps.setStocksShowModal(false)
    }
    const handleConfirmChange = () => {
        modalProps.setConfirmChange(true)
        modalProps.setStocksShowModal(false)
    }

    return (
        <React.Fragment>
            <Modal show={modalProps.stocksShowModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <strong>Confirm Search Text Deletion</strong>
                </Modal.Header>
                <Modal.Body>
                    <Box style={{ textAlign: 'center' }}>
                        <p>Changing the search field will clear your your search terms.</p>
                        <p>Are you sure?</p>
                    </Box>
                </Modal.Body>
                <Modal.Footer>
                    <CustomBtnCloseCancel variant='outlined' onClick={handleClose}>Cancel</CustomBtnCloseCancel>
                    <CustomBtnBlue variant='contained' style={{ marginRight: '5%' }} onClick={handleConfirmChange}>Accept</CustomBtnBlue>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default SearchFieldChangeModal