import { Box, Link, List, ListItem } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { graphingParts, pathParts } from '../../../Models/DataModels/Common/RedirectionModel'
import BasicGraphing from './GraphHelpSections/BasicGraphing'
import AdvancedGraphing from './GraphHelpSections/AdvancedGraphing'
import ToolsSpecificGraphing from './GraphHelpSections/ToolsSpecificGraphing'
import TipsGraphing from './GraphHelpSections/TipsGraphing'

export interface GraphHelpProps {
    subSection: string
}

const GraphHelp = ({
    subSection
}: GraphHelpProps) => {

    const basicSection = useRef<HTMLInputElement>(null)
    const advancedSection = useRef<HTMLInputElement>(null)
    const toolsSection = useRef<HTMLInputElement>(null)
    const tipsSection = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()

    const executeScrollBasic = () => basicSection?.current?.scrollIntoView()
    const executeScrollAdvanced = () => advancedSection?.current?.scrollIntoView()
    const executeScrollTools = () => toolsSection?.current?.scrollIntoView()
    const executeScrollTips = () => tipsSection?.current?.scrollIntoView()

    const subSectionSwitch = () => {
        switch (subSection) {
            case graphingParts.graphingSections.basic:
                executeScrollBasic()
                break
            case graphingParts.graphingSections.advanced:
                executeScrollAdvanced()
                break
            case graphingParts.graphingSections.tools:
                executeScrollTools()
                break
            case graphingParts.graphingSections.tips:
                executeScrollTips()
                break
            default:
                break
        }
    }

    useEffect(() => {
        setTimeout(subSectionSwitch, 200);
    }, [subSection])

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                p: 1,
                m: 1,
                borderRadius: 1
            }}>
            <Box style={{ width: 1200 }}>
                <List>
                    <ListItem sx={graphHelpStyles.pointer}>
                        <p><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Keywords:</span> graph, basic, advanced, tools, tips, tricks</p>
                    </ListItem>
                    <ListItem sx={graphHelpStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.graphingSections.basic) }}>1. Basic Graphing</Link>
                    </ListItem>
                    <ListItem sx={graphHelpStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.graphingSections.advanced) }}>2. Advanced Graphing with Options</Link>
                    </ListItem>
                    <ListItem sx={graphHelpStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.graphingSections.tools) }}>3. Tools Specific Graphing</Link>
                    </ListItem>
                    <ListItem sx={graphHelpStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.graphingSections.tips) }}>4. Tips and Tricks</Link>
                    </ListItem>
                </List>
                <div ref={basicSection}>
                    <BasicGraphing />
                </div>
                <div ref={advancedSection}>
                    <AdvancedGraphing />
                </div>
                <div ref={toolsSection}>
                    <ToolsSpecificGraphing />
                </div>
                <div ref={tipsSection}>
                    <TipsGraphing />
                </div>
            </Box>
        </Box>
    )
}

const graphHelpStyles = {
    pointer: {
        cursor: 'pointer'
    }
}

export default GraphHelp
