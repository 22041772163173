import React from "react"
import { ReactComponent as CheckUnSubscribedIcon } from "../../Images/svg/CheckUnSubscribed.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CheckUnSubscribed = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <CheckUnSubscribedIcon />
  </SvgIcon>
)

export default CheckUnSubscribed