import React from 'react'
import AlertModal, { AlertModalProps, AlertButtonType } from '../../../../Common/Modals/AlertModal'
import { ChartDefinition } from '../../../../../Models/DataModels/Common/ToolsModel'
import CCChart from './CCChart'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'

export interface CCChartModalProps {
    showModal: boolean,
    setShow: (show: boolean) => void,
    chartDef: ChartDefinition,
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void
}

const CCChartModal = ({
    showModal,
    setShow,
    chartDef,
    setErrorResponse
}: CCChartModalProps) => {

    const chartModalTitle = (): string => {
        if (chartDef) {
            if (chartDef.charts?.length > 1) {
                return 'Currency Comparison'
            } else if (chartDef.charts?.length > 0) {
                return chartDef.charts[0].title
            }
        }
        return ''
    }

    const CCChartAlertModalProps: AlertModalProps = {
        showModal: showModal,
        setShowModal: setShow,
        AlertTitle: chartModalTitle(),
        AlertContent: <CCChart chartDef={chartDef} setErrorResponse={setErrorResponse} />,
        AlertButtons: [ 
            { 
                type: AlertButtonType.OK,
                display: 'Close',
                onClick:  () => setShow(false),
                isPrimary: false,
          }
        ],
        Size: 'xl',
    }

    return AlertModal(CCChartAlertModalProps)
}

export default CCChartModal
