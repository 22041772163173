import React, { useEffect, useRef } from 'react'
import BasicSearch from './SearchHelpSections/BasicSearch'
import AdvancedSearch from './SearchHelpSections/AdvancedSearch'
import { Box, Link, List, ListItem } from '@mui/material'
import USUKSearch from './SearchHelpSections/USUKSearch'
import EventsInTimeSearch from './SearchHelpSections/EventsInTimeSearch'
import ConstiuentMembershipSearch from './SearchHelpSections/ConstiuentMembershipSearch'
import SearchWithTools from './SearchHelpSections/SearchWithTools'
import { useNavigate } from 'react-router-dom'
import { pathParts } from '../../../Models/DataModels/Common/RedirectionModel'


const SearchHelp = ({ subSection }: any) => {

  const basicSearch = useRef<HTMLInputElement>(null)
  const advancedSearch = useRef<HTMLInputElement>(null)
  const USUKSearchSection = useRef<HTMLInputElement>(null)
  const eventsInTimeSearchSection = useRef<HTMLInputElement>(null)
  const constituentMembershipSection = useRef<HTMLInputElement>(null)
  const searchTools = useRef<HTMLInputElement>(null)

  const navigate = useNavigate()

  const executeScrollBasic = () => basicSearch?.current?.scrollIntoView()
  const executeScrollAdvanced = () => advancedSearch?.current?.scrollIntoView()
  const executeScrollUSUK = () => USUKSearchSection?.current?.scrollIntoView()
  const executeScrollEventsInTime = () => eventsInTimeSearchSection?.current?.scrollIntoView()
  const executeScrollConstituentMembership = () => constituentMembershipSection?.current?.scrollIntoView()
  const executeScrollSearchTools = () => searchTools?.current?.scrollIntoView()

  const subSectionSwitch = () => {
    switch (subSection) {
      case 'basicSearch':
        executeScrollBasic()
        break
      case 'advancedSearch':
        executeScrollAdvanced()
        break
      case 'USUKStock':
        executeScrollUSUK()
        break
      case 'eventsInTime':
        executeScrollEventsInTime()
        break
      case 'constituentMembership':
        executeScrollConstituentMembership()
        break
      case 'toolsSearch':
        executeScrollSearchTools()
        break
      default:
        return
    }
  }

  useEffect(() => {
    setTimeout(subSectionSwitch, 200);
  }, [subSection])

  return (
    <div >
      {/* Sections here will have nav numbers and hyperlinks at the top, these will navigate WITHIN the document and also the dropdowns will correspond with these sections NAv numbers here sections go here with refs on each for navigating the page */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: 'center',
          p: 1,
          m: 1,
          borderRadius: 1
        }}>
        <Box style={{ width: 1200 }}>
          {/* Nav links go here */}
          <List>
            <ListItem sx={searchHelpStyles.pointer}>
              <p><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Keywords:</span> Search, Find, Options</p>
            </ListItem>
            <ListItem sx={searchHelpStyles.pointer}>
              <Link onClick={() => {
                navigate(pathParts.help.searchHelpItems.basic)
              }}>1. Basic Search and Options</Link>
            </ListItem>
            <ListItem sx={searchHelpStyles.pointer}>
              <Link onClick={() => {
                navigate(pathParts.help.searchHelpItems.advanced)
              }}>2. Advanced Search with Engine Options</Link>
            </ListItem>
            <ListItem sx={searchHelpStyles.pointer}>
              <Link onClick={() => {
                navigate(pathParts.help.searchHelpItems.USUKStock)
              }}>3. US and UK Stock Search</Link>
            </ListItem>
            <ListItem sx={searchHelpStyles.pointer}>
              <Link onClick={() => {
                navigate(pathParts.help.searchHelpItems.eventsInTime)
              }}>4. Events-In-Time Search</Link>
            </ListItem>
            <ListItem sx={searchHelpStyles.pointer}>
              <Link onClick={() => {
                navigate(pathParts.help.searchHelpItems.constituentMembership)
              }}>5. Constituent Membership</Link>
            </ListItem>
            <ListItem sx={searchHelpStyles.pointer}>
              <Link onClick={() => {
                navigate(pathParts.help.searchHelpItems.toolsSearch)
              }}>6. Searching with Tools</Link>
            </ListItem>
          </List>
          <div ref={basicSearch}>
            <BasicSearch></BasicSearch>
          </div>
          <div ref={advancedSearch}>
            <AdvancedSearch></AdvancedSearch>
          </div>
          <div ref={USUKSearchSection}>
            <USUKSearch></USUKSearch>
          </div>
          <div ref={eventsInTimeSearchSection}>
            <EventsInTimeSearch></EventsInTimeSearch>
          </div>
          <div ref={constituentMembershipSection}>
            <ConstiuentMembershipSearch></ConstiuentMembershipSearch>
          </div>
          <div ref={searchTools}>
            <SearchWithTools></SearchWithTools>
          </div>
        </Box>
      </Box>
    </div>
  )
}
const searchHelpStyles = {

  pointer: {
    cursor: 'pointer'
  }
}

export default SearchHelp