import { GFDToastError } from "../Components/Common/Utility/GFDToastify"
import { NotOKResponseModel } from "../Models/DataModels/Common/NotOKResponseModel"
import { FileDownloadRequest } from "../Models/DataModels/Requests/ToolsRequests"
import { downloadFile } from "./ToolsService"

export const downloadFileFromServer = (downloadRequest: FileDownloadRequest,
  setErrorResponse: (response: NotOKResponseModel | null) => void,
  onComplete?: (result: boolean) => void) => {
  downloadFile(downloadRequest).then(
    async (fileDownloadResponse: any) => {
      const b = await fileDownloadResponse.blob()
      let downloadLink = document.createElement('a')
      let url = URL.createObjectURL(b)
      downloadLink.href = url
      downloadLink.download = downloadRequest.filename
      downloadLink.click()
      downloadLink.parentNode?.removeChild(downloadLink)
      if (onComplete) onComplete(true)
    },
    //Reject promise
    (notOKResponseModel: NotOKResponseModel) => {
      setErrorResponse(notOKResponseModel)
      if (onComplete) onComplete(false)
    }
  ).catch(err => {
    GFDToastError(err)
    if (onComplete) onComplete(false)
  })
}
