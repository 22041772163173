import { useEffect, useState } from "react"
import { NotOKResponseModel } from "../../../../Models/DataModels/Common/NotOKResponseModel"
import { LogoutReasonType } from "../../../../Models/DataModels/Requests/AuthRequests"
import { ErrorHandlerProps, ErrorHandler } from "../../../Common/Utility/ErrorHandler"
import { PopulateCurrencyRequest } from "../../../../Models/DataModels/Requests/PopulationRequests"
import { CurrencyGroups, blankEntry, nativeCurrencyEntry } from "../../../../Models/DataModels/Common/FieldPopulationModel"
import { LabelValuePair } from "../../../../Models/DataModels/Common/LabelValuePairModel"
import { populateCurrencies } from "../../../../Services/FieldPopulationService"
import CurrencyConverterOptions from "./CurrencyConverterSubComponents/CurrencyConverterOptions"
import CurrencyConvertIcon from "../../../Icons/CurrencyConvertIcon"
import { Grid } from "@mui/material"

export interface CurrencyConverterProps {
  signOut: (logoutReason: LogoutReasonType) => void
}

const CurrencyConverter = ({
  signOut
}: CurrencyConverterProps) => {

  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
  const [currencyOptions, setCurrencyOptions] = useState<LabelValuePair[]>([blankEntry])

  const errorHandlerProps: ErrorHandlerProps = {
    response: errorResponse,
    signOut: signOut
  }

  const requestPopulateCurrencies = () => {
    const request: PopulateCurrencyRequest = {
      group: CurrencyGroups.All
    }
    populateCurrencies(request).then(
      (resCurrencies: LabelValuePair[]) => {
        const filteredOptions = resCurrencies.filter((value: LabelValuePair) => value.value !== nativeCurrencyEntry.value)
        setCurrencyOptions([...filteredOptions])
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        setErrorResponse(notOKResponseModel)
      }
    )
  }

  useEffect(requestPopulateCurrencies, [])

  return (
    <>
  <Grid container spacing={0} columns={3}>
    <Grid item sm={2} md={2} lg={1} flexGrow={1}>
      <div style={headerStyles.headerWrapper}>
        <CurrencyConvertIcon style={headerStyles.customIcon}></CurrencyConvertIcon>
        <h3 style={{ fontWeight: 'bold' }}>Currency Conversion</h3>
      </div>
    </Grid>
  </Grid>
  <Grid container spacing={2} columns={16}>
    <Grid item md={12}>
      <div style={headerStyles.reportWrapper}>
        <ErrorHandler {...errorHandlerProps} />
      <CurrencyConverterOptions setErrorResponse={setErrorResponse} currencyOptions={currencyOptions} />
      </div>
    </Grid>
  </Grid>
    </>
  )
}

const headerStyles = {
  customIcon: {
    width: '30px',
    height: '30px',
    marginRight: '10px'
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center', 
    width: '100%',
    background: 'linear-gradient(90deg, #c8ed7e, #eafcc7)',
    padding: '12px 20px 8px 20px',
    borderRadius: '40px'
  },
  
  reportWrapper: {
    paddingTop: '20px'
  }
}

export default CurrencyConverter
