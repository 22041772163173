import { Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, ComposedChart, Tooltip, ReferenceArea } from 'recharts'
import { CAPEModel, EventData } from '../../../../../Models/DataModels/Common/CAPERatioModel'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { graphCompanyLogoSVG, rechartsSourceTextLabelObject } from '../../../../Common/Modals/GraphUtil'
import GraphDownloadOptions, { GraphDownloadOptionsProps } from '../../../../Common/Modals/GraphDownloadOptions/GraphDownloadOptions'
import OutputFormatValue, { OutputFormatValueType } from '../../../../../Models/DataModels/Common/OutputFormatModel'
import { chartSizes } from '../../../../../Models/DataModels/Common/AppConfigurationsModel'

export interface CAPEChartProps {
  checkIsTrial: () => boolean,
  graphData?: CAPEModel | null,
  downloadRequest: (outputFormat: OutputFormatValueType, retainGraph: boolean) => void
}

const CAPEChart = ({
  checkIsTrial,
  graphData,
  downloadRequest
}: CAPEChartProps) => {

  const colors = {
    main: '#ff7300',
    comparison: '#1f7300'
  }

  const eventsColors = {
    main: '#f0aa8f',
    comparison: '#b7ceb1'
  }

  const [isoColors, setISOColors] = useState<{ [key: string]: string }>({})
  const [yAxisMin, setYAxisMin] = useState<number>(0)
  const [yAxisMax, setYAxisMax] = useState<number>(0)

  useEffect(() => {
    const sColors: { [key: string]: string } = {}

    if (graphData) {
      const numbers: number[] = []
      graphData.dataPoints.reduce((accum, point) => {
        if (point.value || point.value === 0) {
          accum.push(point.value)
        }

        if (point.compareValue || point.compareValue === 0) {
          accum.push(point.compareValue)
        }

        return accum
      }, numbers)

      const min = Math.min(...numbers)
      const max = Math.max(...numbers)

      setYAxisMin(min)
      setYAxisMax(max)

      sColors[graphData.mainISO] = colors.main
      if (graphData.comparisonISO && graphData.comparisonISO !== graphData.mainISO) {
        sColors[graphData.comparisonISO] = colors.comparison
      }
    }

    setISOColors(sColors)

  }, [graphData])

  const dateFormatter = (dateValue: number) => {
    return moment(dateValue).format('MM/DD/YYYY')
  }

  var eventTooltip: string[] = []

  const getAllApplicableEvents = (pointStartDateAsNumber: number, pointEndDateAsNumber: number, fullEventsDataArray: EventData[]): string[] => {
    const inRangeEvents: EventData[] = []
    fullEventsDataArray.forEach((eData: EventData) => {
      if (
        (eData.startDateAsNumber === pointStartDateAsNumber || eData.endDateAsNumber === pointEndDateAsNumber) ||
        (eData.endDateAsNumber === pointStartDateAsNumber || eData.startDateAsNumber === pointEndDateAsNumber) ||
        (eData.startDateAsNumber < pointStartDateAsNumber && eData.endDateAsNumber > pointStartDateAsNumber) ||
        (eData.startDateAsNumber < pointEndDateAsNumber && eData.endDateAsNumber > pointEndDateAsNumber) ||
        (eData.startDateAsNumber > pointStartDateAsNumber && eData.endDateAsNumber < pointEndDateAsNumber)
      ) {
        inRangeEvents.push(eData)
      }
    })
    if (inRangeEvents.length > 0) {
      const eventsArray: string[] = inRangeEvents.map((value: EventData, index: number) => {
        return `${value.iso} : ${value.description} (${dateFormatter(value.startDateAsNumber)} to ${dateFormatter(value.endDateAsNumber)})`
      })
      return eventsArray
    }
    return []
  }

  const renderEvents = () => {
    return graphData?.events?.map((point: EventData, index: number, fullEventsDataArray: EventData[]) => {
      return (
        <ReferenceArea key={index} x1={point.startDateAsNumber} x2={point.endDateAsNumber} y1={yAxisMax} y2={yAxisMin} stroke={isoColors[point.iso] || 'black'} fill={point.iso === graphData.mainISO ? eventsColors.main : eventsColors.comparison}
          onMouseEnter={() => {
            eventTooltip = getAllApplicableEvents(point.startDateAsNumber, point.endDateAsNumber, fullEventsDataArray)
          }}
          onMouseLeave={() => {
            eventTooltip = []
          }}
          strokeOpacity={0.3}>
        </ReferenceArea>
      )
    })
  }

  const getEventTooltip = () => {
    const mainEventLabel: JSX.Element[] = eventTooltip.map((eventDescription: string, index: number) => <p key={`event${index}`} className='label'>{`${eventDescription}`}</p>)
    return (
      <div>
        {mainEventLabel}
      </div>
    )
  }

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          {payload.map((entry: any, index: number) => <p key={`customCapeToolTip${index}`} className='label'>{`${entry.name} - ${dateFormatter(label)} : ${entry.value}`}</p>)}
          {getEventTooltip()}
        </div>
      )
    }

    if (eventTooltip) {
      <div className='custom-tooltip'>
        {getEventTooltip()}
      </div>
    }

    return null
  }

  const downloadDataFile = (format: OutputFormatValueType, retainGraph: boolean) => {
    downloadRequest(format, retainGraph)
  }

  const downloadCSV = () => {
    downloadDataFile(OutputFormatValue.CSV, true)
  }

  const downloadExcel = () => {
    downloadDataFile(OutputFormatValue.Excel, true)
  }

  const imageID: string = 'capeRatiosGraph'

  const graphDownloadOptionsProps: GraphDownloadOptionsProps = {
    downloadCSV: downloadCSV,
    downloadExcel: downloadExcel,
    imageIDValue: imageID
  }

  return (
    graphData ?
      <div style={{ marginTop: '8px' }}>
        {checkIsTrial() ?
          <text
            style={{
              fontSize: 48,
              fontWeight: 'bold',
              fill: 'grey',
              wordWrap: 'break-word',
              display: 'inline',
              pointerEvents: 'none',
              position: 'absolute',
              width: '70%',
              height: '50%',
              opacity: '0.1'
            }}
            textAnchor='middle'
          >
            For full demo, contact Global Financial Data <br />
            at (949) 542-4200 or email <br />
            sales@globalfinancialdata.com.
          </text>
          : <></>
        }

        {graphData?.dataPoints?.length ?
          <>
            <div style={{ paddingBottom: 5 }}>
              <GraphDownloadOptions {...graphDownloadOptionsProps} />
            </div>
            <ResponsiveContainer id={imageID} width='100%' height={chartSizes.standard} >
              <ComposedChart
                width={500}
                height={300}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                data={graphData?.dataPoints}
                barCategoryGap={0}
                barGap={0}
              >
                <CartesianGrid />

                <XAxis type='number' scale='time' tickFormatter={dateFormatter} dataKey='dateAsNumber'
                  domain={[graphData.dataPoints[0].dateAsNumber, graphData.dataPoints[graphData.dataPoints.length - 1].dateAsNumber]} label={rechartsSourceTextLabelObject} />
                <YAxis label={{ value: graphData?.yLabel, angle: -90, position: 'insideLeft', dx: -20 }} domain={[yAxisMin, yAxisMax]} scale={'linear'} />
                <Legend verticalAlign='top' height={36} />
                <Tooltip content={<CustomTooltip />} />
                {renderEvents()}
                <Line type='monotone' dataKey='value' stroke={colors.main} name={graphData.mainLine} dot={false} connectNulls />
                {graphData.comparisonLine ?
                  <Line type='monotone' dataKey='compareValue' name={graphData.comparisonLine} stroke={colors.comparison} dot={false} connectNulls />
                  : <></>
                }
                {graphCompanyLogoSVG()}
              </ComposedChart>
            </ResponsiveContainer>
          </>
          :
          <></>
        }
      </div>
      : <></>
  )
}

export default CAPEChart
