import React, { useEffect, useState } from 'react'
import AlertModal, { AlertButtonType, AlertModalProps } from '../AlertModal'
import { LoginResponse } from '../../../../Models/DataModels/Responses/AuthResponses'
import { agreeCookie } from '../../../../Services/AccountService'
import { InternalErrorMessage, NotOKResponseModel } from '../../../../Models/DataModels/Common/NotOKResponseModel'
import { GFDToastError } from '../../Utility/GFDToastify'
import { CookieAgreedResponse } from '../../../../Models/DataModels/Responses/AccountResponses'
import LegalModal, { LegalTypes } from './LegalModal'
import { blobURL, fetchCookieAgreementText } from '../../../../Services/AzureBlobService'

export interface CookieAgreementModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    loginRes: LoginResponse | null,
    onAgreed: (response: LoginResponse) => void,
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void
}

const defaultCookieAgreementtext: string = 'Global Financial Data uses cookies to improve its website. In addition, cookies also are used to maintain login authorization, and personal information stored in the cookies including name, email, and addresses are used for account management.'

const CookieAgreementModal = ({
    showModal,
    setShowModal,
    loginRes,
    onAgreed,
    setErrorResponse
}: CookieAgreementModalProps) => {

    const [showTermsModal, setShowTermsModal] = useState<boolean>(false)
    const [legalType, setLegalType] = useState<LegalTypes>(LegalTypes.Privacy)
    const [cookieAgreementText, setcookieAgreementText] = useState<string>('')

    const linkText = (linkText: string, legalTypeClicked: LegalTypes) => {
        return (
            <span
                style={{
                    color: '#0d6efd',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    setLegalType(legalTypeClicked)
                    setShowTermsModal(true)
                }}
            >{linkText}</span>
        )
    }

    const StaticInfoText = (): JSX.Element => {
        return (
            <>
                &nbsp;
                For more details and how to manage cookies, please see our {linkText('privacy policy', LegalTypes.Privacy)} and {linkText('cookies policy', LegalTypes.Cookie)}.
            </>
        )
    }

    const CookieAgreementContents = (): JSX.Element => {
        return (
            <>
                {cookieAgreementText}
                <StaticInfoText />
                <LegalModal showModal={showTermsModal} setShowModal={setShowTermsModal} type={legalType} />
            </>
        )
    }

    const agreeClicked = () => {
        agreeCookie(loginRes?.token).then(
            (agreeCookieResponse: CookieAgreedResponse) => {
                if (agreeCookieResponse?.cookieAgreed) {
                    if (loginRes) {
                        onAgreed(loginRes)
                    }
                    setShowModal(false)
                } else {
                    GFDToastError(InternalErrorMessage)
                }
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
            }
        )
    }

    const alerModelProps: AlertModalProps = {
        showModal: showModal,
        setShowModal: setShowModal,
        onAlertClose: () => false,
        AlertTitle: 'This website uses cookies',
        AlertContent: <CookieAgreementContents />,
        AlertButtons: [
            {
                type: AlertButtonType.OK,
                display: 'DECLINE',
                onClick: () => {
                    GFDToastError('Cookie must be agreed to use this website')
                    setShowModal(false)
                },
                isPrimary: false,
            },
            {
                type: AlertButtonType.OK,
                display: 'ACCEPT',
                onClick: () => {
                    agreeClicked()
                },
                isPrimary: true,
            }
        ],
        Size: 'lg',
        showCloseButton: false
    }

    const getCookieAgreementText = () => {
        fetchCookieAgreementText().then(
            (responseCookieAgreementText: string) => {
                setcookieAgreementText(responseCookieAgreementText)
            },
            () => {}
        ).catch(
            () => {
                setcookieAgreementText(defaultCookieAgreementtext)
            }
        )
    }

    useEffect(() => {
        getCookieAgreementText()
    }, [])

    return <AlertModal {...alerModelProps} />
}

export default CookieAgreementModal
