import { ApplicationNameType, ApplicationType } from "../Common/AppConfigurationsModel"
import { PaginationRequest } from "./SearchRequests"

export type LogoutReasonType = 'Logout' | 'Timeout' | 'Bumped' | 'Kicked'

export const LogoutReason = {
    Logout: 'Logout' as LogoutReasonType,
    Timeout: 'Timeout' as LogoutReasonType,
    Bumped: 'Bumped' as LogoutReasonType,
    Kicked: 'Kicked' as LogoutReasonType,
}

export interface LoginRequest {
  username: string,
  password: string,
  applicationType: ApplicationType,
  applicationNameType: ApplicationNameType
}

export interface AnonymousLoginRequest {
  applicationType: ApplicationType,
  applicationNameType: ApplicationNameType
}

export interface SwitchToAdminAccountRequest {
  password: string
}

export interface RefreshRequest {
  authenticationToken: string,
  refreshToken: string
}

export interface LogoutRequest {
  logoutReason: LogoutReasonType
}

export interface ImpersonateRequest {
  impersonatedUserID: number,
  applicationType: string
}

export interface ForgotPasswordRequest {
  email: string
}

export interface ConfirmResetForgotPasswordRequest {
  token: string
}

export interface ResetForgotPasswordRequest {
  newPassword: string,
  newPasswordConfirmation: string,
  resetToken: string
}

export interface WorkbookListRequest {
  paginationRequest: PaginationRequest,
  searchTerm: string
}
