import React from 'react'
import { errorRedColor } from '../GlobalSettings/GlobalStyles'

export const UnorderedMultiErrorLines = ({lines}: any): JSX.Element => {
    if (!lines || lines.length === 0) {
        return (
            <>
            </>
        )
    }

    return (
        <ul style={{color: errorRedColor}}>
            {lines.map((line: string, index: number) => (
                <li key={index}>{line}</li>
            ))}
        </ul>
    )
}

const MultiLines = (lines: string[]) => {
    return (
        <>
            {
            lines.map((line: string, index: number) => (
                <div key={index}>{line}</div>
            ))}
        </>
    )
}

export default MultiLines
