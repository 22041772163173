import React from "react"
import { ReactComponent as GraphwLinesIcon } from "../../Images/svg/GraphwLines.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const GraphwLines = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <GraphwLinesIcon />
  </SvgIcon>
)

export default GraphwLines