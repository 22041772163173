import React from "react"
import { ReactComponent as ZoomIcon } from "../../Images/svg/Zoom.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const Zoom = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <ZoomIcon />
  </SvgIcon>
)

export default Zoom