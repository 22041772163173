import React from "react"
import { ReactComponent as SearchUserIcon } from "../../Images/svg/SearchUser.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const SearchUser = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <SearchUserIcon />
  </SvgIcon>
)

export default SearchUser