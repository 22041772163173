import { Box } from "@mui/material"
import React from "react"
import Modal from 'react-bootstrap/Modal'
import { CustomBtnCloseCancel, BtnClearReset } from "../GlobalSettings/CustomStyles"

const YieldCurveClearGroupsConfirmationModal = ({ modalProps }: any) => {

    const handleClose = () => {
        modalProps.setShowModal(false)
    }

    const handleClear = () => {
        modalProps.setClearCountryGroup(true)
        modalProps.setShowModal(false)
    }

    return (
        <React.Fragment>
            <Modal show={modalProps.showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change country group?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Box style={{ textAlign: 'center' }}>
                        <p>Changing the country group will clear the current results.</p>
                        Are you sure?
                    </Box>
                </Modal.Body>

                <Modal.Footer>
                    <CustomBtnCloseCancel variant='outlined' style={{ marginRight: '5%' }} onClick={handleClose}>Cancel</CustomBtnCloseCancel>
                    <BtnClearReset variant='outlined' style={{ marginRight: '5%' }} onClick={handleClear}>Clear</BtnClearReset>
                </Modal.Footer>
            </Modal>

        </React.Fragment>
    )
}

export default YieldCurveClearGroupsConfirmationModal