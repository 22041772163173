import { Moment } from "moment"
import Adjustments from "./AdjustmentModel"
import { CurrencyDefaultValue } from "./CurrencyModel"
import { DataFields } from "./DataFieldModel"
import DataFillMethodValue, { DataFillMethodValueType } from "./DataFillMethodModel"
import DataFrequencyValue, { DataFrequencyValueType } from "./DataFrequencyModel"
import DateModeValue, { DateModeValueType } from "./DateModeModel"
import { DateFormatValue, DateFormatValueType } from "./DateFormatModel"
import { MovingAverageValue, MovingAverageValueType } from "./MovingAverageModel"
import OutputFormatValue, { OutputFormatValueType } from "./OutputFormatModel"
import moment from 'moment'
import { EquityFields } from "./EquityFieldModel"
import { CorporateActionFields } from "./CorporateActionFieldModel"
import { FundamentalsAndRatiosFields } from "./FundamentalsAndRatiosFieldModel"
import { WorksheetFormatValueType, WorksheetFormatValue } from "./WorksheetFormatModel"
import { DownloadSettingDictionary } from "../Responses/AutoTracResponses"

export interface DownloadSettingsModel {
    SecuritiesIncluded: boolean,
    CorporateActionsIncluded: boolean,
    DateMode: DateModeValueType,
    FromYear: number,
    ToYear: number,
    FromDate: Moment,
    ToDate: Moment,
    DataFields: DataFields,
    Adjustments: Adjustments,
    Currency: string,
    DataFrequency: DataFrequencyValueType,
    DateFormat: DateFormatValueType,
    MovingAverage: MovingAverageValueType,
    OutputFormat: OutputFormatValueType,
    DataFillMethod: DataFillMethodValueType,
    EquityFields: EquityFields,
    CorporateActionFields: CorporateActionFields,
    FundamentalsAndRatiosFields: FundamentalsAndRatiosFields,
    WorksheetFormat: WorksheetFormatValueType,
    Series: number[],
    hasSplitAdjusted?: boolean,
    hasInflationAdjusted?: boolean,
    hasPerCapita?: boolean,
    hasPercentGDP?: boolean
}

export const DownloadSettingsDateFormat = 'MM/DD/YYYY' 
export const defaultStartDate = moment('01/01/1900', DownloadSettingsDateFormat)
export const defaultEndDate = moment()
export const minYear = 1900
export const maxYear = defaultEndDate.year()

export const DefaultDownloadSettings: DownloadSettingsModel = {
    SecuritiesIncluded: false,
    CorporateActionsIncluded: false,
    DateMode: DateModeValue.ByYear,
    FromYear: minYear,
    ToYear: maxYear,
    FromDate: defaultStartDate,
    ToDate: defaultEndDate,
    DataFields: {
        Open: true,
        High: true,
        Low: true,
        Close: true,
        Volume: false,
        OpenInterest: false,
        CloseOnly: false
    },
    Adjustments: {
        SplitAdjusted: true,
        InflationAdjusted: false,
        Average: false,
        AnnualizedFlow: false,
        PerCapita: false,
        AnnualPercentChange: false,
        PeriodPercentChange: false,
        TotalReturn: false,
        PercentGDP: false
    },
    Currency: CurrencyDefaultValue,
    DataFrequency: DataFrequencyValue.Daily,
    DateFormat: DateFormatValue.American,
    MovingAverage: MovingAverageValue.None,
    OutputFormat: OutputFormatValue.Excel,
    DataFillMethod: DataFillMethodValue.Backward,
    EquityFields: {
        SharesOutstanding: false,
        MarketCap: false,
        EPS: false,
        PERatio: false,
        DividendYield: false
    },
    CorporateActionFields: {
        SplitsAndDividends: false,
        ShareInformation: false
    },
    FundamentalsAndRatiosFields: {
        AnnualFundamentals: false,
        AnnualRatios: false,
        Estimates: false,
        QuarterlyFundamentals: false,
        QuarterlyRatios: false
    },
    WorksheetFormat: WorksheetFormatValue.Stacked,
    Series: [],
    hasSplitAdjusted: true,
    hasInflationAdjusted: true,
    hasPerCapita: true,
    hasPercentGDP: true
}

export const CreateDownloadSettingsModelWithDefaults = (): DownloadSettingsModel => {
    const model: DownloadSettingsModel = {
        ...DefaultDownloadSettings,
        DataFields: {
            ...DefaultDownloadSettings.DataFields
        },
        Adjustments: {
            ...DefaultDownloadSettings.Adjustments
        },
        EquityFields: {
            ...DefaultDownloadSettings.EquityFields
        },
        CorporateActionFields: {
            ...DefaultDownloadSettings.CorporateActionFields
        },
        FundamentalsAndRatiosFields: {
            ...DefaultDownloadSettings.FundamentalsAndRatiosFields
        }
    } // create with copy of defaults
    return model
}

const GetBooleanValue = (value: string) => {
    return value === 'True' ? true : false
}

export const GetDownloadSettingsModel = (
    dictionary?: DownloadSettingDictionary,
    minDate?: moment.Moment | null,
    maxDate?: moment.Moment | null
): DownloadSettingsModel => {

    const parseOutputFormat = (itemValue: string): OutputFormatValueType => {
        if (itemValue && (itemValue === OutputFormatValue.Excel || itemValue === OutputFormatValue.CSV || itemValue === OutputFormatValue.Zip)) {
            return itemValue.trim() as OutputFormatValueType
        } else {
            return OutputFormatValue.Excel
        }
    }
    
    const model: DownloadSettingsModel = CreateDownloadSettingsModelWithDefaults()

    try {
        dictionary?.item?.forEach(item => {
            switch(item.key) {
                case 'SecuritiesIncluded':
                    model.SecuritiesIncluded = GetBooleanValue(item.value)
                    break
                case 'CorporateActionsIncluded':
                    model.CorporateActionsIncluded = GetBooleanValue(item.value)
                    break
                case 'SplitAdjustedIncluded':
                    model.hasSplitAdjusted = GetBooleanValue(item.value)
                    break
                case 'InflationAdjustedIncluded':
                    model.hasInflationAdjusted = GetBooleanValue(item.value)
                    break
                case 'PerCapitaIncluded':
                    model.hasPerCapita = GetBooleanValue(item.value)
                    break
                case 'PercentGDPIncluded':
                    model.hasPercentGDP = GetBooleanValue(item.value)
                    break
                // data fields
                case 'Open':
                    model.DataFields.Open = GetBooleanValue(item.value)
                    break
                case 'Close':
                    model.DataFields.Close = GetBooleanValue(item.value)
                    break
                case 'High':
                    model.DataFields.High = GetBooleanValue(item.value)
                    break
                case 'Low':
                    model.DataFields.Low = GetBooleanValue(item.value)
                    break
                case 'Volume':
                    model.DataFields.Volume = GetBooleanValue(item.value)
                    break
                case 'OpenInt':
                    model.DataFields.OpenInterest = GetBooleanValue(item.value)
                    break
                case 'ForceCloseOnly':
                    model.DataFields.CloseOnly = GetBooleanValue(item.value)
                    break
                // dates
                case 'DateTo':
                    if (item.value.length === 4) {
                        model.ToYear = parseInt(item.value)
                        model.DateMode = DateModeValue.ByYear
                    } else {
                        model.ToDate = moment(item.value, 'MM-DD-YYYY')
                        model.DateMode = DateModeValue.ByDate
                    }
                    break
                case 'DateFrom':
                    if (item.value.length === 4) {
                        model.FromYear = parseInt(item.value)
                        model.DateMode = DateModeValue.ByYear
                    } else {
                        model.FromDate = moment(item.value, 'MM-DD-YYYY')
                        model.DateMode = DateModeValue.ByDate
                    }
                    break
                // adjustments
                case 'SplitAdjusted':
                    model.Adjustments.SplitAdjusted = GetBooleanValue(item.value)
                    break
                case 'InflationAdjusted':
                    model.Adjustments.InflationAdjusted = GetBooleanValue(item.value)
                    break
                case 'Average':
                    model.Adjustments.Average = GetBooleanValue(item.value)
                    break
                case 'AnnualFlow':
                    model.Adjustments.AnnualizedFlow = GetBooleanValue(item.value)
                    break
                case 'PerCapita':
                    model.Adjustments.PerCapita = GetBooleanValue(item.value)
                    break
                case 'AnnualPercent':
                    model.Adjustments.AnnualPercentChange = GetBooleanValue(item.value)
                    break
                case 'PercentChange':
                    model.Adjustments.PeriodPercentChange = GetBooleanValue(item.value)
                    break
                case 'TotalReturns':
                    model.Adjustments.TotalReturn = GetBooleanValue(item.value)
                    break
                case 'PercentGDP':
                    model.Adjustments.PercentGDP = GetBooleanValue(item.value)
                    break
                // selects
                case 'Currency':
                    model.Currency = item.value ? item.value.trim() : CurrencyDefaultValue
                    break
                case 'Periodicity':
                    model.DataFrequency = item.value ? item.value.trim() as DataFrequencyValueType : DataFrequencyValue.Daily
                    break
                case 'DateFormat':
                    model.DateFormat = item.value ? item.value.trim() as DateFormatValueType : DateFormatValue.American
                    break
                case 'MovingAverage':
                    model.MovingAverage = item.value ? item.value.trim() as MovingAverageValueType : MovingAverageValue.None
                    break
                case 'Format':
                    model.OutputFormat = parseOutputFormat(item.value)
                    break
                case 'DataFill':
                    model.DataFillMethod = item.value ? item.value.trim() as DataFillMethodValueType : DataFillMethodValue.None
                    break
                // equity fields
                case 'SharesOutstanding':
                    model.EquityFields.SharesOutstanding = GetBooleanValue(item.value)
                    break
                case 'MarketCap':
                    model.EquityFields.MarketCap = GetBooleanValue(item.value)
                    break
                case 'EarningsPerShare':
                    model.EquityFields.EPS = GetBooleanValue(item.value)
                    break
                case 'PriceEarningsRatio':
                    model.EquityFields.PERatio = GetBooleanValue(item.value)
                    break
                case 'DividendYield':
                    model.EquityFields.DividendYield = GetBooleanValue(item.value)
                    break
                // corporate action fields
                case 'Dividends':
                    model.CorporateActionFields.SplitsAndDividends = GetBooleanValue(item.value)
                    break
                case 'ShareInformation':
                    model.CorporateActionFields.ShareInformation = GetBooleanValue(item.value)
                    break
                // fundamentals and ratios fields: {
                case 'AnnualFundamentals':
                    model.FundamentalsAndRatiosFields.AnnualFundamentals = GetBooleanValue(item.value)
                    break
                case 'AnnRatio':
                    model.FundamentalsAndRatiosFields.AnnualRatios = GetBooleanValue(item.value)
                    break
                case 'Estimates':
                    model.FundamentalsAndRatiosFields.Estimates = GetBooleanValue(item.value)
                    break
                case 'QuartFundamentals':
                    model.FundamentalsAndRatiosFields.QuarterlyFundamentals = GetBooleanValue(item.value)
                    break
                case 'QuartRatio':
                    model.FundamentalsAndRatiosFields.QuarterlyRatios= GetBooleanValue(item.value)
                    break
                // others
                case 'WorksheetFormat':
                    model.WorksheetFormat = GetBooleanValue(item.value) as WorksheetFormatValueType
                    break
                case 'fileList':
                    model.Series = (item.value || '').split(';').map(entry => Number(entry))
            }
        })
    } catch(e) {
        console.log('Exception when loading download settings', e )
    }

    // reset to min and max, if given
    const InvalidDate = 'Invalid date'
    if (minDate && minDate.format() !== InvalidDate) {
        model.FromDate = minDate
        model.FromYear = minDate.year()
    }
    if (maxDate && maxDate.format() !== InvalidDate) {
        model.ToDate = maxDate
        model.ToYear = maxDate.year()
    }
    return model
}
