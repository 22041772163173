import { DataFillMethodValueType } from "../Common/DataFillMethodModel"
import { DataFrequencyValueType } from "../Common/DataFrequencyModel"
import DateModeValue from "../Common/DateModeModel"
import { DateFormatValueType } from "../Common/DateFormatModel"
import { DownloadSettingsModel, DownloadSettingsDateFormat } from "../Common/DownloadSettingsModel"
import { MovingAverageValueType } from "../Common/MovingAverageModel"
import { OutputFormatValueType } from "../Common/OutputFormatModel"
import { WorksheetFormatValueType } from "../Common/WorksheetFormatModel"
import { PaginationRequest } from "./SearchRequests"
import { SortFieldType, SortOrderType } from "../Common/SortModel"

export interface DownloadQueueRequest {
  pagination: PaginationRequest,
  sortField: SortFieldType,
  sortOrder: SortOrderType
}

export interface WorkbookRequest {
  workbookName: string,
  pagination?: PaginationRequest,
  sortField?: SortFieldType,
  sortOrder?: SortOrderType
}

export interface WorkbooksRequest {
  workbookNames: string[],
}

export interface WorkbookRenameRequest {
  currentName: string,
  newName: string
}

export interface WorkbookCopyRequest {
  sourceWorkbookName: string,
  targetWorkbookName: string
}

export interface WorkbookTickersByCUSIPRequest {
  workbookName: string,
  CUSIPs: string[]
}

export interface WorkbookTickersByIDRequest {
  workbookName: string,
  tickerIDs: number[]
}

export interface WorkbookTickersByNameRequest {
  workbookName: string,
  tickerNames: string[]
}

export interface DownloadSettingsToSave {
  SecuritiesIncluded?: boolean,
  CorporateActionsIncluded?: boolean,
  SplitAdjustedIncluded?: boolean,
  InflationAdjustedIncluded?: boolean,
  PerCapitaIncluded?: boolean,
  PercentGDPIncluded?: boolean,
  FromDate?: string,
  ToDate?: string,
  Open?: boolean,
  High?: boolean,
  Low?: boolean,
  Close?: boolean,
  Volume?: boolean,
  OpenInterest?: boolean,
  CloseOnly?: boolean,
  SplitAdjusted?: boolean,
  InflationAdjusted?: boolean,
  Average?: boolean,
  AnnualizedFlow?: boolean,
  PerCapita?: boolean,
  AnnualPercentChange?: boolean,
  PeriodPercentChange?: boolean,
  TotalReturn?: boolean,
  PercentGDP?: boolean,
  Currency?: string,
  DataFrequency?: DataFrequencyValueType,
  DateFormat?: DateFormatValueType,
  MovingAverage?: MovingAverageValueType,
  OutputFormat?: OutputFormatValueType,
  DataFillMethod?: DataFillMethodValueType,
  SharesOutstanding?: boolean,
  MarketCap?: boolean,
  EPS?: boolean,
  PERatio?: boolean,
  DividendYield?: boolean,
  SplitsAndDividends?: boolean,
  ShareInformation?: boolean,
  AnnualFundamentals?: boolean,
  AnnualRatios?: boolean,
  Estimates?: boolean,
  QuarterlyFundamentals?: boolean,
  QuarterlyRatios?: boolean,
  WorksheetFormat?: WorksheetFormatValueType,
  FileList?: string
}

export type DownloadRequestSourceValueType = 'Workbook' | 'PowerDownload' | 'OnDemand'

export const DownloadRequestSource = {
  Workbook: 'Workbook' as DownloadRequestSourceValueType,
  PowerDownload: 'PowerDownload' as DownloadRequestSourceValueType,
  OnDemand: 'OnDemand' as DownloadRequestSourceValueType
}

export interface WorkbookDownloadRequest {
  workbookName: string,
  downloadSettings: DownloadSettingsToSave,
  source?: DownloadRequestSourceValueType,
  isComposite: boolean
}

export interface DownloadPropertiesRequest {
  tickerIDs: number[],
  isComposite?: boolean
}

export const CreateWorkbookDownloadRequest = (
  workbookName: string,
  downloadSettings: DownloadSettingsModel,
  source: DownloadRequestSourceValueType = DownloadRequestSource.Workbook,
  isComposite: boolean = false
): WorkbookDownloadRequest => {
  const downloadSettingsToSave = {} as DownloadSettingsToSave
  if (downloadSettings.DateMode === DateModeValue.ByDate) {
    downloadSettingsToSave.FromDate = downloadSettings.FromDate.format(DownloadSettingsDateFormat)
    downloadSettingsToSave.ToDate = downloadSettings.ToDate.format(DownloadSettingsDateFormat)
  } else {
    downloadSettingsToSave.FromDate = downloadSettings.FromYear.toString()
    downloadSettingsToSave.ToDate = downloadSettings.ToYear.toString()
  }
  
  downloadSettingsToSave.Close = downloadSettings.DataFields.Close
  downloadSettingsToSave.CloseOnly = downloadSettings.DataFields.CloseOnly

  if (downloadSettings.DataFields.CloseOnly) {
    downloadSettingsToSave.Open = false
    downloadSettingsToSave.High = false
    downloadSettingsToSave.Low = false
    downloadSettingsToSave.Volume = false
    downloadSettingsToSave.OpenInterest = false
  } else {
    downloadSettingsToSave.Open = downloadSettings.DataFields.Open
    downloadSettingsToSave.High = downloadSettings.DataFields.High
    downloadSettingsToSave.Low = downloadSettings.DataFields.Low
    downloadSettingsToSave.Volume = downloadSettings.DataFields.Volume
    downloadSettingsToSave.OpenInterest = downloadSettings.DataFields.OpenInterest
  }

  downloadSettingsToSave.SplitAdjustedIncluded = downloadSettings.hasSplitAdjusted
  downloadSettingsToSave.InflationAdjustedIncluded = downloadSettings.hasInflationAdjusted
  downloadSettingsToSave.PerCapitaIncluded = downloadSettings.hasPerCapita
  downloadSettingsToSave.PercentGDPIncluded = downloadSettings.hasPercentGDP

  downloadSettingsToSave.SplitAdjusted = downloadSettings.hasSplitAdjusted ? downloadSettings.Adjustments.SplitAdjusted : false
  downloadSettingsToSave.InflationAdjusted = downloadSettings.hasInflationAdjusted ? downloadSettings.Adjustments.InflationAdjusted : false
  downloadSettingsToSave.Average = downloadSettings.Adjustments.Average
  downloadSettingsToSave.AnnualPercentChange = downloadSettings.Adjustments.AnnualPercentChange
  downloadSettingsToSave.PeriodPercentChange = downloadSettings.Adjustments.PeriodPercentChange

  downloadSettingsToSave.SecuritiesIncluded = downloadSettings.SecuritiesIncluded
  downloadSettingsToSave.CorporateActionsIncluded = downloadSettings.CorporateActionsIncluded

  if (downloadSettings.SecuritiesIncluded) {
    downloadSettingsToSave.TotalReturn = downloadSettings.Adjustments.TotalReturn
  } else {
    downloadSettingsToSave.PerCapita = downloadSettings.hasPerCapita ? downloadSettings.Adjustments.PerCapita : false
    downloadSettingsToSave.AnnualizedFlow = downloadSettings.Adjustments.AnnualizedFlow
    downloadSettingsToSave.PercentGDP = downloadSettings.hasPercentGDP ? downloadSettings.Adjustments.PercentGDP : false
  }
  
  downloadSettingsToSave.Currency = downloadSettings.Currency
  downloadSettingsToSave.DataFrequency = downloadSettings.DataFrequency
  downloadSettingsToSave.DateFormat = downloadSettings.DateFormat
  downloadSettingsToSave.MovingAverage = downloadSettings.MovingAverage
  downloadSettingsToSave.OutputFormat = downloadSettings.OutputFormat
  downloadSettingsToSave.DataFillMethod = downloadSettings.DataFillMethod

  const applyZacksLimitation: boolean = (downloadSettings.Series && downloadSettings.Series?.length > 10)
  if (downloadSettings.SecuritiesIncluded) {
    if (downloadSettings.DataFields.CloseOnly
      || applyZacksLimitation) {
      downloadSettingsToSave.SharesOutstanding = false
      downloadSettingsToSave.MarketCap = false
      downloadSettingsToSave.EPS = false
      downloadSettingsToSave.PERatio = false
      downloadSettingsToSave.DividendYield = false
      downloadSettingsToSave.ShareInformation = false
    } else {
      downloadSettingsToSave.SharesOutstanding = downloadSettings.EquityFields.SharesOutstanding
      downloadSettingsToSave.MarketCap = downloadSettings.EquityFields.MarketCap
      downloadSettingsToSave.EPS = downloadSettings.EquityFields.EPS
      downloadSettingsToSave.PERatio = downloadSettings.EquityFields.PERatio
      downloadSettingsToSave.DividendYield = downloadSettings.EquityFields.DividendYield
      downloadSettingsToSave.ShareInformation = downloadSettings.CorporateActionFields.ShareInformation
    }

    downloadSettingsToSave.AnnualFundamentals = applyZacksLimitation ? false : downloadSettings.FundamentalsAndRatiosFields.AnnualFundamentals
    downloadSettingsToSave.AnnualRatios = applyZacksLimitation ? false : downloadSettings.FundamentalsAndRatiosFields.AnnualRatios
    downloadSettingsToSave.Estimates = applyZacksLimitation ? false : downloadSettings.FundamentalsAndRatiosFields.Estimates
    downloadSettingsToSave.QuarterlyFundamentals = applyZacksLimitation ? false : downloadSettings.FundamentalsAndRatiosFields.QuarterlyFundamentals
    downloadSettingsToSave.QuarterlyRatios = applyZacksLimitation ? false : downloadSettings.FundamentalsAndRatiosFields.QuarterlyRatios
  }

  if (downloadSettings.CorporateActionsIncluded) {
    downloadSettingsToSave.SplitsAndDividends = downloadSettings.CorporateActionFields.SplitsAndDividends
  }

  downloadSettingsToSave.WorksheetFormat = downloadSettings.WorksheetFormat

  if (source === DownloadRequestSource.OnDemand && downloadSettings.Series) {
    downloadSettingsToSave.FileList = downloadSettings.Series.join(';')
  }

  return {
    workbookName: workbookName,
    downloadSettings: downloadSettingsToSave,
    source,
    isComposite
  }
}
