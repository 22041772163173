import React from "react"
import { ReactComponent as ReloadWorkbookListIcon } from "../../Images/svg/ReloadWorkbookList.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ReloadWorkbookList = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <ReloadWorkbookListIcon />
  </SvgIcon>
)

export default ReloadWorkbookList