import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { autoTracParts, pathParts } from '../../../Models/DataModels/Common/RedirectionModel'
import { Box, Link, List, ListItem } from '@mui/material'
import { helpPageStyles } from '../HelpPageStyles'
import BasicAutoTrac from './AutoTracHelpSections/BasicAutoTrac'
import AdvancedAutoTrac from './AutoTracHelpSections/AdvancedAutoTrac'
import TipsAutoTrac from './AutoTracHelpSections/TipsAutoTrac'

export interface AutoTracHelpProps {
    subSection: string
}

const AutoTracHelp = ({
    subSection
}: AutoTracHelpProps) => {

    const basicSection = useRef<HTMLInputElement>(null)
    const advancedSection = useRef<HTMLInputElement>(null)
    const tipsSection = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()

    const executeScrollBasic = () => basicSection?.current?.scrollIntoView()
    const executeScrollAdvanced = () => advancedSection?.current?.scrollIntoView()
    const executeScrollTips = () => tipsSection?.current?.scrollIntoView()

    const subSectionSwitch = () => {
        switch (subSection) {
            case autoTracParts.autoTracSections.basic:
                executeScrollBasic()
                break
            case autoTracParts.autoTracSections.advanced:
                executeScrollAdvanced()
                break
            case autoTracParts.autoTracSections.tips:
                executeScrollTips()
                break
            default:
                break
        }
    }

    useEffect(() => {
        setTimeout(subSectionSwitch, 200);
    }, [subSection])

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                p: 1,
                m: 1,
                borderRadius: 1
            }}>
            <Box style={{ width: 1200 }}>
                <List>
                    <ListItem sx={helpPageStyles.pointer}>
                        <p><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Keywords:</span> workbook, queue, advanced, tips</p>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.autoTracSections.basic) }}>1. Basic Workbooks and Download Queue</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.autoTracSections.advanced) }}>2. Advanced Workbook</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.autoTracSections.tips) }}>3. Tips and Tricks</Link>
                    </ListItem>
                </List>
                <div ref={basicSection}>
                    <BasicAutoTrac />
                </div>
                <div ref={advancedSection}>
                    <AdvancedAutoTrac />
                </div>
                <div ref={tipsSection}>
                    <TipsAutoTrac />
                </div>
            </Box>
        </Box>
    )
}

export default AutoTracHelp
