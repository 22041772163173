import React from "react"
import { ReactComponent as BarGraphIcon } from "../../Images/svg/BarGraph.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const BarGraph = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <BarGraphIcon />
  </SvgIcon>
)

export default BarGraph