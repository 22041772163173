import React from "react"
import { ReactComponent as RenameWorkbookIcon } from "../../Images/svg/RenameWorkbook.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const RenameWorkbook = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <RenameWorkbookIcon />
  </SvgIcon>
)

export default RenameWorkbook