import { Options } from '@material-table/core'

const MaterialTableOptions: Options<any> = {
    search: true,
    selection: true,
    selectionProps: rowData => ({
        inputProps: {
            'title': 'Select/Deselect',
            'aria-label': 'Select/Deselect'
        }
    }),
    padding: 'dense',
    toolbarButtonAlignment: 'left',
    headerStyle: {
        fontWeight: 'bold',
        position: 'sticky',
        top: 0,
        zIndex: 'inherit',
    },
    paginationPosition: 'top',
    pageSize: 100,
    pageSizeOptions: [10, 25, 50, 100],
    paginationType: 'stepped',
    emptyRowsWhenPaging: false,
    draggable: false
}

export default MaterialTableOptions



