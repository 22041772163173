import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ResponsiveContainer, ComposedChart, CartesianGrid, Legend, Tooltip, Area, LabelList, Line, XAxis, YAxis, Bar, ReferenceArea, LineChart, BarChart } from 'recharts'
import { ScaleType } from 'recharts/types/util/types'
import { Candlestick, CustomTooltip, CustomizedDot, HLCstick, OHLCstick, VolumeTooltip, addUnixTimeStampProperty, customTicks, customTicksRightScale, prepareCandlestickData, replaceSpecialDotSeparator, unixTimestampDateFormatter } from './GraphAndChartUtil'
import moment from 'moment'
import { AdditionalGraphDataKeyValuePair, ChartDefinition, ChartMetaData, ChartScaleTypes, ChartTypes, ChartView } from '../../../Models/DataModels/Common/ToolsModel'
import { CreateChartDownloadDataRequest, GraphRequest } from '../../../Models/DataModels/Requests/GraphRequests'
import { graphByFileName, graphDownload } from '../../../Services/GraphService'
import { getNextChartDarkColor, getNextAreaColor, getNextChartLightColor } from '../../../Services/ColorService'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import OutputFormatValue, { OutputFormatValueType } from '../../../Models/DataModels/Common/OutputFormatModel'
import { CreateChartDownloadDataResponse } from '../../../Models/DataModels/Responses/GraphResponses'
import { FileDownloadRequest } from '../../../Models/DataModels/Requests/ToolsRequests'
import { downloadFile } from '../../../Services/ToolsService'
import { GFDToastError, GFDToastInfo } from '../../Common/Utility/GFDToastify'
import GraphDownloadOptions, { GraphDownloadOptionsProps } from '../../Common/Modals/GraphDownloadOptions/GraphDownloadOptions'
import { graphCompanyLogoSVG, rechartsSourceTextLabelObject, renderTrialUserWatermarkText } from '../../Common/Modals/GraphUtil'
import { additionalChartNames } from '../../../Models/DataModels/Common/ChartOptionsModel'
import Zoom from '../../Icons/ZoomIcon'
import TrialOptionsModal from '../../Common/Modals/TrialOptionsModal'
import { CustomBtn, CustomBtnOutlined } from '../../Common/GlobalSettings/CustomStyles'
import { GraphColors, LineColor } from '../../../Models/DataModels/Common/ColorModel'
import ColorSelectionModal, { ColorSelectionModalProps, ColorType } from '../../Common/Modals/ColorSelectionModal'
import { getSessionStorageOrDefault, sessionStorageKeys } from '../../../Services/SessionStorageService'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'
import { AppConfigurations, chartSizes } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import GraphDateRangeSlider, { GraphDateRangeSliderProps } from './GraphDateRangeSlider'
import { MessageResponse } from '../../../Models/DataModels/Responses/NotOKResponse'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../Common/Utility/ComponentMessageHandler'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'

export interface GraphAndChartProps {
    lastResponse: ChartDefinition | null,
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void,
    setShowLoadingModal: (showLoadingModal: boolean) => void
    checkIsTrial: () => boolean
}

let zoomedGraphRequest: GraphRequest | null = null

const GraphAndChart = ({
    lastResponse,
    setErrorResponse,
    checkIsTrial,
    setShowLoadingModal
}: GraphAndChartProps) => {

    const [refAreaLeft, setRefAreaLeft] = useState<any>('')
    const [refAreaRight, setRefAreaRight] = useState<any>('')

    const [dataSource, setDataSource] = useState<any[]>([])
    const [metaDataArray, setMetaDataArray] = useState<ChartMetaData[]>([])
    const [chronologyAreaChartCount, setchronologyAreaChartCount] = useState<number>(0)

    const [colorPalette, setColorPalette] = useState<GraphColors>(getSessionStorageOrDefault(sessionStorageKeys.graphCustomColors, { lineColors: [] }))
    const [showColorPaletteModal, setShowColorPaletteModal] = useState<boolean>(false)
    const [isCustomized, setIsCustomized] = useState<boolean>(Boolean(colorPalette?.lineColors?.length))

    const [techIndicatorDataSource, setTechIndicatorDataSource] = useState<any[]>([])
    const [techIndicatorMetaDataArray, setTechIndicatorMetaDataArray] = useState<ChartMetaData[]>([])

    const [valueIndicatorDataSource, setValueIndicatorDataSource] = useState<any[]>([])
    const [valueIndicatorMetaDataArray, setValueIndicatorMetaDataArray] = useState<ChartMetaData[]>([])

    const [finRatioDataSource, setFinRatioDataSource] = useState<any[]>([])
    const [finRatioMetaDataArray, setFinRatioMetaDataArray] = useState<ChartMetaData[]>([])

    const [zoomedResponse, setZoomedResponse] = useState<ChartDefinition | null>(null)

    const [hiddenCharts, setHiddenCharts] = useState<string[]>([])

    const [showModal, setShowModal] = useState<boolean>(false)
    const [chartSettings, setChartSettings] = useState<any>('')

    const [displayScaleChangedMessage, setDisplayScaleChangedMessage] = useState<boolean>(false)
    const [chartMessages, setChartMessages] = useState<MessageResponse[]>([])

    const [sliderMinDate, setSliderMinDate] = useState<number>(0)
    const [sliderMaxDate, setSliderMaxDate] = useState<number>(0)
    const [sliderStart, setSliderStart] = useState<number>(0)
    const [sliderEnd, setSliderEnd] = useState<number>(0)

    const setChartData = (chartDef: ChartDefinition | null) => {
        if (chartDef?.charts && chartDef.charts.length > 0 && chartDef.charts[0]?.dataSources?.length > 0) {
            const primaryChartDataPointsWithUnixtimeStampDate: any[] = addUnixTimeStampProperty(chartDef.charts[0].dataSources[0])
            setDataSource(primaryChartDataPointsWithUnixtimeStampDate)
            setMetaDataArray(chartDef.charts[0].metaDataArray)
        } else {
            setDataSource([])
            setMetaDataArray([])
        }

        setTechIndicatorDataSource([])
        setTechIndicatorMetaDataArray([])

        setValueIndicatorDataSource([])
        setValueIndicatorMetaDataArray([])

        setFinRatioDataSource([])
        setFinRatioMetaDataArray([])

        const additionalGraphsData: AdditionalGraphDataKeyValuePair[] | null = chartDef?.additionalGraphsData || null

        if (additionalGraphsData && additionalGraphsData.length > 0) {
            additionalGraphsData.forEach((value: AdditionalGraphDataKeyValuePair) => {
                const chartViewIndex: number = value.value
                switch (value.key) {
                    case additionalChartNames.technicalIndicator:
                        if (chartDef?.charts && chartDef.charts.length > chartViewIndex && chartDef.charts[chartViewIndex]?.dataSources?.length > 0) {
                            const techDataPointsWithUnixtimeStampDate: any[] = addUnixTimeStampProperty(chartDef.charts[chartViewIndex].dataSources[0])
                            setTechIndicatorDataSource(techDataPointsWithUnixtimeStampDate)
                            setTechIndicatorMetaDataArray(chartDef.charts[chartViewIndex].metaDataArray)
                        } else {
                            setTechIndicatorDataSource([])
                            setTechIndicatorMetaDataArray([])
                        }
                        break
                    case additionalChartNames.valueIndicator:
                        if (chartDef?.charts && chartDef.charts.length > chartViewIndex && chartDef.charts[chartViewIndex]?.dataSources?.length > 0) {
                            const valueIndicatorDataPointsWithUnixtimeStampDate: any[] = addUnixTimeStampProperty(chartDef.charts[chartViewIndex].dataSources[0])
                            setValueIndicatorDataSource(valueIndicatorDataPointsWithUnixtimeStampDate)
                            setValueIndicatorMetaDataArray(chartDef.charts[chartViewIndex].metaDataArray)
                        } else {
                            setValueIndicatorDataSource([])
                            setValueIndicatorMetaDataArray([])
                        }
                        break
                    case additionalChartNames.financialRatio:
                        if (chartDef?.charts && chartDef.charts.length > chartViewIndex && chartDef.charts[chartViewIndex]?.dataSources?.length > 0) {
                            const finRatioDataPointsWithUnixtimeStampDate: any[] = addUnixTimeStampProperty(chartDef.charts[chartViewIndex].dataSources[0])
                            setFinRatioDataSource(finRatioDataPointsWithUnixtimeStampDate)
                            setFinRatioMetaDataArray(chartDef.charts[chartViewIndex].metaDataArray)
                        } else {
                            setFinRatioDataSource([])
                            setFinRatioMetaDataArray([])
                        }
                        break
                    default:
                        break
                }
            })
        }
    }

    useEffect(() => {
        let firstChartView: ChartView | null = null
        let requestObject: GraphRequest | null = null
        if (zoomedResponse) {
            firstChartView = zoomedResponse?.charts && zoomedResponse.charts.length > 0 && zoomedResponse.charts[0] ? zoomedResponse.charts[0] : null
            requestObject = zoomedResponse?.chartRequest || null
            if (dataSource.length > 1) {
                setSliderStart(dataSource[0].dateUnixTimeStamp)
                setSliderEnd(dataSource[dataSource.length - 1].dateUnixTimeStamp)
            }
        } else {
            firstChartView = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] ? lastResponse.charts[0] : null
            requestObject = lastResponse?.chartRequest || null
            if (dataSource.length > 1) {
                setSliderMinDate(dataSource[0].dateUnixTimeStamp)
                setSliderMaxDate(dataSource[dataSource.length - 1].dateUnixTimeStamp)
                setSliderStart(dataSource[0].dateUnixTimeStamp)
                setSliderEnd(dataSource[dataSource.length - 1].dateUnixTimeStamp)
            }
        }
        const optionScaleType: ScaleType = requestObject?.settings?.scaleType === ChartScaleTypes.Log ? 'log' : 'linear'
        if (optionScaleType === 'log' && firstChartView?.scaleType === ChartScaleTypes.Linear) {
            setDisplayScaleChangedMessage(true)
        }
    }, [dataSource])

    useEffect(() => {
        if (displayScaleChangedMessage) {
            GFDToastInfo('Scale automatically adjusted to Linear')
            GFDToastInfo('Note: Scale settings in Chart Options was set to Linear')
            setDisplayScaleChangedMessage(false)
        }
    }, [displayScaleChangedMessage])

    useEffect(() => {
        setChartData(lastResponse)
        zoomedGraphRequest = null
        setZoomedResponse(null)
        setChartSettings(lastResponse?.chartRequest?.settings)
        setChartMessages(lastResponse?.chartMessages || [])
    }, [lastResponse])

    useEffect(() => {
        const chronologyMetaDataArray: ChartMetaData[] = metaDataArray.filter((value: ChartMetaData) => value.chartName === 'area')
        if (!chronologyMetaDataArray || chronologyMetaDataArray.length === 0) {
            setchronologyAreaChartCount(0)
            return
        }
        const chronologyMetaData: ChartMetaData = chronologyMetaDataArray[0]
        const chronologyAreas: JSX.Element[] = generateAreaChronology(chronologyMetaData.minValue)
        setchronologyAreaChartCount(chronologyAreas.length)
    }, [metaDataArray])

    useEffect(() => {
        if (chronologyAreaChartCount > AppConfigurations.chronologyAreaLabelDisplayLimit) {
            GFDToastInfo('Filter the date range or zoom in to see the detailed events in time')
        }
    }, [chronologyAreaChartCount])

    const zoomOutButtonEnabled = () => {
        return Boolean(zoomedResponse)
    }

    const graphingOptions: GraphRequest | null = lastResponse?.chartRequest || null

    const generateXAxis = () => {
        const xAxis: JSX.Element = <XAxis type='number' domain={['dataMin', 'dataMax']} dataKey='dateUnixTimeStamp' scale='time' tickFormatter={unixTimestampDateFormatter} allowDuplicatedCategory={true} label={rechartsSourceTextLabelObject} allowDataOverflow />
        return xAxis
    }

    const determineScaleType = (): ScaleType => {
        let firstChartView: ChartView | null = null
        let requestObject: GraphRequest | null = null
        if (zoomedResponse) {
            firstChartView = zoomedResponse?.charts && zoomedResponse.charts.length > 0 && zoomedResponse.charts[0] ? zoomedResponse.charts[0] : null
            requestObject = zoomedResponse?.chartRequest || null
        } else {
            firstChartView = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] ? lastResponse.charts[0] : null
            requestObject = lastResponse?.chartRequest || null
        }
        const optionScaleType: ScaleType = requestObject?.settings?.scaleType === ChartScaleTypes.Log ? 'log' : 'linear'
        if (optionScaleType === 'log' && firstChartView?.scaleType === ChartScaleTypes.Linear) {
            return 'linear'
        }
        return optionScaleType
    }

    const generateYAxis = () => {
        const arrayOfJSXYAxis: JSX.Element[] = []

        const scaleType: ScaleType = determineScaleType()

        metaDataArray.forEach((metaData: ChartMetaData, chartIndex: number) => {
            const chartName: string = metaData.chartName
            const dataKey: string = `${chartName}.value`
            const isPrimaryScale: boolean = metaData.isPrimaryScale
            const yAxisIdValue: string = isPrimaryScale ? 'left' : 'right'
            const orientationValue: 'left' | 'right' = isPrimaryScale ? 'left' : 'right'

            let domainRange: any[] = ['auto', 'auto']

            if (chartIndex === 0) {
                if (scaleType === 'log') {
                    domainRange = ['auto', 'auto']
                } else if (graphingOptions?.settings && (graphingOptions.settings.chartType === ChartTypes.Candlestick || graphingOptions.settings.chartType === ChartTypes.OHLC || graphingOptions.settings.chartType === ChartTypes.HLC)) {
                    domainRange = [metaData.minPriceValue, metaData.maxPriceValue]
                } else {
                    if (zoomedResponse) {
                        const zoomedPrimaryScaleMin = zoomedResponse?.charts[0]?.graphPrimaryScaleMin && zoomedResponse?.charts[0]?.graphPrimaryScaleMin < 0 ? zoomedResponse?.charts[0]?.graphPrimaryScaleMin : 0
                        const zoomedPrimaryScaleMax = zoomedResponse?.charts[0]?.graphPrimaryScaleMax || 'dataMax'
                        domainRange = [zoomedPrimaryScaleMin, zoomedPrimaryScaleMax]
                    } else {
                        const primaryScaleMin = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] && lastResponse?.charts[0]?.graphPrimaryScaleMin && lastResponse?.charts[0]?.graphPrimaryScaleMin < 0 ? lastResponse?.charts[0]?.graphPrimaryScaleMin : 0
                        const primaryScaleMax = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] && lastResponse?.charts[0]?.graphPrimaryScaleMax || 'dataMax'
                        domainRange = [primaryScaleMin, primaryScaleMax]
                    }
                }
                arrayOfJSXYAxis.push(<YAxis key={`${chartName}YAxis${chartIndex}`} yAxisId={yAxisIdValue} orientation={orientationValue} scale={scaleType} domain={domainRange} interval={'preserveStartEnd'} allowDecimals={false} tickCount={3} tick={customTicks} />)
            } else if (!isPrimaryScale) {
                if (zoomedResponse) {
                    const zoomedPrimaryScaleMin = zoomedResponse?.charts[0]?.graphSecondaryScaleMin && zoomedResponse?.charts[0]?.graphSecondaryScaleMin < 0 ? zoomedResponse?.charts[0]?.graphSecondaryScaleMin : 0
                    const zoomedPrimaryScaleMax = zoomedResponse?.charts[0]?.graphSecondaryScaleMax || 'dataMax'
                    domainRange = [zoomedPrimaryScaleMin, zoomedPrimaryScaleMax]
                } else {
                    const secondaryScaleMin = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] && lastResponse?.charts[0]?.graphSecondaryScaleMin && lastResponse?.charts[0]?.graphSecondaryScaleMin < 0 ? lastResponse?.charts[0]?.graphSecondaryScaleMin : 0
                    const secondaryScaleMax = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] && lastResponse?.charts[0]?.graphSecondaryScaleMax || 'dataMax'
                    domainRange = [secondaryScaleMin, secondaryScaleMax]
                }
                arrayOfJSXYAxis.push(<YAxis key={`${chartName}YAxis${chartIndex}`} yAxisId={yAxisIdValue} orientation={orientationValue} scale='linear' domain={domainRange} interval='preserveStartEnd' dataKey={dataKey} tick={customTicksRightScale} />)
            }
        })
        return arrayOfJSXYAxis
    }

    const generateCharts = () => {
        const JSXChartsArray: JSX.Element[] = []

        if (!metaDataArray.length) return JSXChartsArray

        // Chronology area must be rendered first so that other lines are darker.
        const chronologyMetaDataArray: ChartMetaData[] = metaDataArray.filter((value: ChartMetaData) => value.chartName === 'area')
        if (chronologyMetaDataArray && chronologyMetaDataArray.length > 0) {
            const chronologyMetaData: ChartMetaData = chronologyMetaDataArray[0]
            const chronologyAreas: JSX.Element[] = generateAreaChronology(chronologyMetaData.minValue)
            JSXChartsArray.push(...chronologyAreas)
        }

        metaDataArray.forEach((metaData: ChartMetaData, chartIndex: number) => {
            const chartName: string = metaData.chartName
            const chartDisplayName: string = replaceSpecialDotSeparator(chartName)
            const dataKey: string = `${chartName}.value`
            const series: string[] = graphingOptions?.comparison?.symbols ? graphingOptions.comparison.symbols : []
            const isPrimaryScale: boolean = metaData.isPrimaryScale
            const isActiveDot: boolean = (graphingOptions?.settings?.chartType === ChartTypes.Candlestick ||
                graphingOptions?.settings?.chartType === ChartTypes.OHLC ||
                graphingOptions?.settings?.chartType === ChartTypes.HLC) ? false : true
            let color = getNextChartDarkColor(chartIndex)
            const yAxisIdValue: string = isPrimaryScale ? 'left' : 'right'
            let lightColor: string = getNextChartLightColor(chartIndex)

            let lineColor: LineColor | undefined

            if (zoomOutButtonEnabled()) {
                lineColor = colorPalette.lineColors.find(line => line.name === chartName)
            } else if (colorPalette.lineColors.length > chartIndex) {
                lineColor = colorPalette.lineColors[chartIndex]
                lineColor.name = chartName
                lineColor.index = chartIndex
            }

            if (lineColor) {
                color = lineColor.strokeColor
                lightColor = lineColor.fillColor
            } else {
                lineColor = {
                    name: chartName,
                    index: chartIndex,
                    strokeColor: color,
                    fillColor: lightColor
                }
                colorPalette.lineColors.push(lineColor)
            }
            lineColor.hidden = false

            if (chartIndex === 0 && graphingOptions?.settings) {
                switch (graphingOptions.settings.chartType) {
                    case ChartTypes.Candlestick:
                        const pricesdataCandleStick: any[] = prepareCandlestickData(chartName, dataSource)
                        JSXChartsArray.push(<Bar key={`${chartName}Bar${chartIndex}`} yAxisId={yAxisIdValue} data={pricesdataCandleStick} name={chartDisplayName} dataKey='openClose' shape={<Candlestick />} hide={hiddenCharts.includes(dataKey)} />)
                        break
                    case ChartTypes.OHLC:
                        const pricesdataOHLCStick: any[] = prepareCandlestickData(chartName, dataSource)
                        JSXChartsArray.push(<Bar key={`${chartName}Bar${chartIndex}`} yAxisId={yAxisIdValue} data={pricesdataOHLCStick} name={chartDisplayName} dataKey='openClose' shape={<OHLCstick />} hide={hiddenCharts.includes(dataKey)} />)
                        break
                    case ChartTypes.HLC:
                        const pricesdataHLCStick: any[] = prepareCandlestickData(chartName, dataSource)
                        JSXChartsArray.push(<Bar key={`${chartName}Bar${chartIndex}`} yAxisId={yAxisIdValue} data={pricesdataHLCStick} name={chartDisplayName} dataKey='openClose' shape={<HLCstick />} hide={hiddenCharts.includes(dataKey)} />)
                        break
                    case ChartTypes.Mountain:
                        JSXChartsArray.push(<Area key={`${chartName}Area${chartIndex}`} yAxisId={yAxisIdValue} name={chartDisplayName} type='monotone' dataKey={dataKey} stroke={color} fill={color} dot={<CustomizedDot />} connectNulls hide={hiddenCharts.includes(dataKey)} />)
                        break
                    case ChartTypes.Bar:
                        JSXChartsArray.push(<Bar key={`${chartName}Bar${chartIndex}`} yAxisId={yAxisIdValue} name={chartDisplayName} dataKey={dataKey} stroke={lightColor} fill={color} hide={hiddenCharts.includes(dataKey)} />)
                        break
                    default:
                        JSXChartsArray.push(<Line key={`${chartName}Line${chartIndex}`} yAxisId={yAxisIdValue} name={chartDisplayName} type='monotone' dataKey={dataKey} stroke={color} dot={<CustomizedDot />} connectNulls hide={hiddenCharts.includes(dataKey)} />)
                        break
                }
            } else if (chartName === 'area') {
                lineColor.hidden = true
                // Do nothing for this case since chronology area is already done first
            } else if (series.includes(chartName)) {
                if (graphingOptions?.settings?.chartType === ChartTypes.Mountain) {
                    JSXChartsArray.push(<Area key={`${chartName}Area${chartIndex}`} yAxisId={yAxisIdValue} name={chartDisplayName} type='monotone' dataKey={dataKey} stroke={color} fill={color} dot={<></>} connectNulls hide={hiddenCharts.includes(dataKey)} />)
                } else if (graphingOptions?.settings?.chartType === ChartTypes.Bar) {
                    JSXChartsArray.push(<Bar key={`${chartName}Bar${chartIndex}`} yAxisId={yAxisIdValue} name={chartDisplayName} dataKey={dataKey} stroke={'black'} fill={color} hide={hiddenCharts.includes(dataKey)} />)
                } else {
                    JSXChartsArray.push(<Line key={`${chartName}Line${chartIndex}`} yAxisId={yAxisIdValue} name={chartDisplayName} type='monotone' dataKey={dataKey} stroke={color} activeDot={isActiveDot} dot={<></>} connectNulls hide={hiddenCharts.includes(dataKey)} />)
                }
            } else {
                JSXChartsArray.push(<Line key={`${chartName}Line${chartIndex}`} yAxisId={yAxisIdValue} name={chartDisplayName} type='monotone' dataKey={dataKey} stroke={color} activeDot={isActiveDot} dot={<></>} connectNulls hide={hiddenCharts.includes(dataKey)} />)
            }
        })

        if (!zoomOutButtonEnabled()
            && colorPalette.lineColors.length > metaDataArray.length) {
            colorPalette.lineColors = colorPalette.lineColors.slice(0, metaDataArray.length)
        }

        return JSXChartsArray
    }

    const generateAreaChronology = (areaBaseValue: number): JSX.Element[] => {
        const chronologyAreaArray: JSX.Element[] = []
        let uniqueKeys: any = []
        dataSource.forEach((dataSet: any) => {
            const areaObject: any = dataSet['area']
            areaObject && uniqueKeys.push(Object.keys(areaObject)[0])
        })

        //Use unique function (new Set()) to filter the unique ones (could also use this unique array later on to decide how many labels to make)
        uniqueKeys = [...new Set(uniqueKeys)]

        const yAxisIdValue: string = 'left'

        //Loop through unique keys and make a line for each
        uniqueKeys.forEach((key: any, keyIndex: number) => {
            const color = getNextAreaColor(keyIndex)
            chronologyAreaArray.push(
                <Area key={key} name={key} yAxisId={yAxisIdValue} type='monotone' dataKey={'area[' + key + ']'} stroke={color} fill={color} legendType='square' connectNulls baseValue='dataMin'>
                    {chronologyAreaChartCount <= AppConfigurations.chronologyAreaLabelDisplayLimit && (
                        <LabelList offset={-270} style={{ writingMode: 'vertical-rl' }} fontSize={15} position='insideBottom' formatter={(areaValue: any) => {
                            return areaValue === areaBaseValue + 1 ? key : null
                        }} />
                    )}
                </Area>)
        })

        return chronologyAreaArray
    }

    const createZoomGraphRequest = (graphStartDate: moment.Moment, graphEndDate: moment.Moment): GraphRequest | null => {
        const graphRequest: GraphRequest | null = structuredClone(graphingOptions)
        if (graphRequest?.settings) {
            graphRequest.settings.isCustomDate = true
            graphRequest.settings.startDate = graphStartDate.toISOString()
            graphRequest.settings.endDate = graphEndDate.toISOString()
        }
        return graphRequest
    }

    const zoom = () => {
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setRefAreaLeft('')
            setRefAreaRight('')
            return
        }

        let refStartDate: moment.Moment = moment(refAreaLeft)
        let refEndDate: moment.Moment = moment(refAreaRight)

        if (!refStartDate.isValid() || !refEndDate.isValid()) {
            return
        }

        if (refStartDate > refEndDate) {
            refStartDate = moment(refAreaRight)
            refEndDate = moment(refAreaLeft)
        }

        const graphRequest: GraphRequest | null = structuredClone(graphingOptions)
        if (graphRequest?.settings) {
            graphRequest.settings.isCustomDate = true
            graphRequest.settings.startDate = refStartDate.toISOString()
            graphRequest.settings.endDate = refEndDate.toISOString()
        }

        doGraphRequest(graphRequest)

        zoomedGraphRequest = graphRequest

        setRefAreaLeft('')
        setRefAreaRight('')
    }

    const zoomBySlider = (startDateUnixTimestamp: number, endDateUnixTimestamp: number) => {
        const sliderZoomStartDate: moment.Moment = moment(startDateUnixTimestamp)
        const sliderZoomEndDate: moment.Moment = moment(endDateUnixTimestamp)
        const sliderZoomGraphRequest: GraphRequest | null = createZoomGraphRequest(sliderZoomStartDate, sliderZoomEndDate)
        doGraphRequest(sliderZoomGraphRequest)
        zoomedGraphRequest = sliderZoomGraphRequest
    }

    const doGraphRequest = (graphRequest: GraphRequest | null) => {
        if (!graphRequest) {
            return
        }
        setShowLoadingModal(true)
        graphByFileName(graphRequest).then(
            (dateFilteredResponse: ChartDefinition) => {
                setChartData(dateFilteredResponse)
                setZoomedResponse(dateFilteredResponse)
                setShowLoadingModal(false)
                setChartMessages(dateFilteredResponse?.chartMessages || [])
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
                setShowLoadingModal(false)
                setChartMessages([])
            }
        )
    }

    const zoomOut = () => {
        setChartData(lastResponse)
        zoomedGraphRequest = null
        setZoomedResponse(null)
        setChartMessages(lastResponse?.chartMessages || [])
    }

    const volumeLegendFontColor = (value: string, entry: any) => {
        return <span style={{ color: volumeChartStrokeColor }}>{value}</span>
    }

    const volumeChartName: string = `${graphingOptions?.primaryTicker} - Volume`
    const volumeDataKey: string = metaDataArray.length > 0 ? `${metaDataArray[0].chartName}.prices.volume` : `${graphingOptions?.primaryTicker}.prices.volume`
    const volumeColorIndex: number = 4
    const volumeChartStrokeColor: string = getNextChartDarkColor(volumeColorIndex)
    const volumeChartFillColor: string = getNextChartLightColor(volumeColorIndex)

    let addtionalGraphIndex: number = 0

    const techMetaData: ChartMetaData | null = techIndicatorMetaDataArray.length > 0 ? techIndicatorMetaDataArray[0] : null
    const techChartName: string = techMetaData?.chartName || ''
    const techDataKey: string = `${techChartName}.value`
    let techIndicatorColor: string = getNextChartDarkColor(addtionalGraphIndex)
    if (colorPalette?.technicalIndicator) {
        techIndicatorColor = colorPalette.technicalIndicator.strokeColor
        colorPalette.technicalIndicator.hidden = !Boolean(techMetaData)
        colorPalette.technicalIndicator.name = techDataKey
    } else {
        colorPalette.technicalIndicator = {
            strokeColor: techIndicatorColor,
            fillColor: techIndicatorColor,
            hidden: !Boolean(techMetaData),
            name: techDataKey,
            index: 0
        }
    }

    addtionalGraphIndex++
    const valueIndicatorMetaData: ChartMetaData | null = valueIndicatorMetaDataArray.length > 0 ? valueIndicatorMetaDataArray[0] : null
    const valueIndicatorChartName: string = valueIndicatorMetaData?.chartName || ''
    const valueIndicatorDataKey: string = `${valueIndicatorChartName}.value`
    let valueIndicatorColor: string = getNextChartDarkColor(addtionalGraphIndex)
    if (colorPalette?.valueIndicator) {
        valueIndicatorColor = colorPalette.valueIndicator.strokeColor
        colorPalette.valueIndicator.hidden = !Boolean(valueIndicatorMetaData)
        colorPalette.valueIndicator.name = valueIndicatorDataKey
    } else {
        colorPalette.valueIndicator = {
            strokeColor: valueIndicatorColor,
            fillColor: valueIndicatorColor,
            hidden: !Boolean(valueIndicatorMetaData),
            name: valueIndicatorDataKey,
            index: 0
        }
    }

    addtionalGraphIndex++
    const finRatioMetaData: ChartMetaData | null = finRatioMetaDataArray.length > 0 ? finRatioMetaDataArray[0] : null
    const finRatioChartName: string = finRatioMetaData?.chartName || ''
    const finRatioDataKey: string = `${finRatioChartName}.value`
    let finRatioColor: string = getNextChartDarkColor(addtionalGraphIndex)
    if (colorPalette?.financialRatioIndicator) {
        finRatioColor = colorPalette.financialRatioIndicator.strokeColor
        colorPalette.financialRatioIndicator.hidden = !Boolean(finRatioMetaData)
        colorPalette.financialRatioIndicator.name = finRatioDataKey
    } else {
        colorPalette.financialRatioIndicator = {
            strokeColor: finRatioColor,
            fillColor: finRatioColor,
            hidden: !Boolean(finRatioMetaData),
            name: finRatioDataKey,
            index: 0
        }
    }

    const yAxisIdValue: string = 'left'
    const orientationValue: 'left' | 'right' = 'left'
    const additionalGraphsScaleType: ScaleType = 'linear'
    const techMinValue = techMetaData?.minValue || '0'
    const techMaxValue = techMetaData?.maxValue || 'dataMax'
    const valueIndicatorMinValue = valueIndicatorMetaData?.minValue || '0'
    const valueIndicatorMaxValue = valueIndicatorMetaData?.maxValue || 'dataMax'
    const finRaioMinValue = finRatioMetaData?.minValue || '0'
    const finRaioMaxValue = finRatioMetaData?.maxValue || 'dataMax'

    const imageID: string = 'chartRef'

    const downloadDataFile = (format: OutputFormatValueType) => {
        const downloadGraphRequest: GraphRequest | null = zoomedGraphRequest || graphingOptions
        if (!downloadGraphRequest) {
            return
        }
        const graphCSVDownloadRequest: CreateChartDownloadDataRequest = {
            chartRequest: downloadGraphRequest,
            format: format
        }
        setShowLoadingModal(true)
        graphDownload(graphCSVDownloadRequest).then(
            (fileCreateResponse: CreateChartDownloadDataResponse) => {
                const downloadRequest: FileDownloadRequest = {
                    filename: fileCreateResponse.filename,
                    mimeType: fileCreateResponse.mimeType
                }
                downloadFile(downloadRequest).then(
                    async (fileDownloadResponse: any) => {
                        const b = await fileDownloadResponse.blob()
                        let downloadLink = document.createElement('a')
                        let url = URL.createObjectURL(b)
                        downloadLink.href = url
                        downloadLink.download = downloadRequest.filename
                        downloadLink.click()
                    },
                    //Reject promise
                    (notOKResponseModel: NotOKResponseModel) => {
                        setErrorResponse(notOKResponseModel)
                    }
                ).catch(err => GFDToastError(err))
                setShowLoadingModal(false)
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
                setShowLoadingModal(false)
            }
        )
    }

    const downloadCSV = () => {
        downloadDataFile(OutputFormatValue.CSV)
    }

    const downloadExcel = () => {
        downloadDataFile(OutputFormatValue.Excel)
    }

    const graphDownloadOptionsProps: GraphDownloadOptionsProps = {
        downloadCSV: downloadCSV,
        downloadExcel: downloadExcel,
        imageIDValue: imageID
    }

    const handleLegendClick = (data: any) => {
        if (data?.dataKey) {
            if (hiddenCharts.includes(data.dataKey)) {
                const remainingHiddenCharts: string[] = hiddenCharts.filter((hiddenChartName: string) => hiddenChartName !== data.dataKey)
                setHiddenCharts(remainingHiddenCharts)
            } else {
                setHiddenCharts([...hiddenCharts, data.dataKey])
            }
        }
    }

    const handleViewOptionsClick = () => {
        setShowModal(true)
    }

    const modalProps = {
        showModal: showModal,
        setShowModal: setShowModal
    }

    const handleButtonStyle = () => {
        if (zoomOutButtonEnabled()) {
            return { backgroundColor: '#007ea8', color: '#FFF' }
        } else {
            return globalStyles.disabledButton
        }
    }

    const resetColors = () => {
        const defaultLineColors = colorPalette?.lineColors?.map((line, index) => {
            line.strokeColor = getNextChartDarkColor(index)
            return line
        })

        setColorPalette({ lineColors: [...defaultLineColors] })
        sessionStorage.removeItem(sessionStorageKeys.graphCustomColors)
        setIsCustomized(false)
    }

    const saveCustomColors = () => {
        sessionStorage.setItem(sessionStorageKeys.graphCustomColors, JSON.stringify(colorPalette))
    }

    const isAnyColorCustomized = (customizedColorPalette: GraphColors): boolean => {
        let isCustomized: boolean = false
        const customizedLineColors: LineColor[] = customizedColorPalette.lineColors
        customizedLineColors.forEach((lineColor: LineColor, index: number) => {
            const currentIndexDefaultColorCode: string = getNextChartDarkColor(index)
            if (lineColor.strokeColor !== currentIndexDefaultColorCode) {
                isCustomized = true
            }
        })
        if (isCustomized) {
            return true
        }

        let otherGraphColorIndex: number = 0
        if (customizedColorPalette?.technicalIndicator) {
            const technicalIndicatorDefaultColorCode: string = getNextChartDarkColor(otherGraphColorIndex)
            if (customizedColorPalette.technicalIndicator.strokeColor !== technicalIndicatorDefaultColorCode) {
                return true
            }
        }
        otherGraphColorIndex++
        if (customizedColorPalette?.valueIndicator) {
            const valueIndicatorIndicatorDefaultColorCode: string = getNextChartDarkColor(otherGraphColorIndex)
            if (customizedColorPalette.valueIndicator.strokeColor !== valueIndicatorIndicatorDefaultColorCode) {
                return true
            }
        }
        otherGraphColorIndex++
        if (customizedColorPalette?.financialRatioIndicator) {
            const financialRatioIndicatorDefaultColorCode: string = getNextChartDarkColor(otherGraphColorIndex)
            if (customizedColorPalette.financialRatioIndicator.strokeColor !== financialRatioIndicatorDefaultColorCode) {
                return true
            }
        }
        return false
    }

    const colorSelectionModalProps: ColorSelectionModalProps = {
        colorSettings: colorPalette,
        setColor: (lineColor: LineColor, type: ColorType) => {
            const lineColors = colorPalette.lineColors.map(entry => {
                const copy: LineColor = {
                    name: entry.name,
                    index: entry.index,
                    strokeColor: entry.strokeColor,
                    fillColor: entry.fillColor
                }
                return copy
            })

            let technicalIndicator = colorPalette.technicalIndicator
            let valueIndicator = colorPalette.valueIndicator
            let financialRatioIndicator = colorPalette.financialRatioIndicator
            let entry: LineColor | null | undefined

            if (type === ColorType.Main && lineColor && lineColor.index >= 0 && lineColor.index < colorPalette.lineColors.length) {
                const lineColorState = lineColors[lineColor.index]
                lineColorState.strokeColor = lineColor.strokeColor
                lineColorState.fillColor = lineColor.fillColor
            }

            switch (type) {
                case ColorType.TechnicalIndicator:
                    entry = colorPalette.technicalIndicator
                    technicalIndicator = entry ? {
                        name: entry.name,
                        index: entry.index,
                        strokeColor: entry.strokeColor,
                        fillColor: entry.fillColor
                    } : lineColor
                    technicalIndicator.strokeColor = lineColor.strokeColor
                    technicalIndicator.fillColor = lineColor.fillColor
                    break
                case ColorType.ValueIndicator:
                    entry = colorPalette.valueIndicator
                    valueIndicator = entry ? {
                        name: entry.name,
                        index: entry.index,
                        strokeColor: entry.strokeColor,
                        fillColor: entry.fillColor
                    } : lineColor
                    valueIndicator.strokeColor = lineColor.strokeColor
                    valueIndicator.fillColor = lineColor.fillColor
                    break
                case ColorType.FinancialRatioIndicator:
                    entry = colorPalette.financialRatioIndicator
                    financialRatioIndicator = entry ? {
                        name: entry.name,
                        index: entry.index,
                        strokeColor: entry.strokeColor,
                        fillColor: entry.fillColor
                    } : lineColor
                    financialRatioIndicator.strokeColor = lineColor.strokeColor
                    financialRatioIndicator.fillColor = lineColor.fillColor
                    break
            }

            setColorPalette({ lineColors: [...lineColors], technicalIndicator, valueIndicator, financialRatioIndicator })
            setIsCustomized(true)
        },
        showModal: showColorPaletteModal,
        setShowModal: setShowColorPaletteModal,
        onAlertClose: () => {
            const isColorCustomized: boolean = isAnyColorCustomized(colorPalette)
            if (isColorCustomized) {
                saveCustomColors()
            }
            return true
        }
    }

    const dateRangeSliderProps: GraphDateRangeSliderProps = {
        minUnixDate: sliderMinDate,
        maxUnixDate: sliderMaxDate,
        sliderStart,
        sliderEnd,
        zoom: zoomBySlider
    }

    const componentMessageHandlerProps: ComponentMessageHandlerProps = {
        messages: chartMessages,
        setMessages: setChartMessages,
        signOut: (logoutReason: LogoutReasonType) => { }
    }

    return (
        <>
            {(dataSource?.length > 0 || zoomedResponse) && (
                <Grid container columns={12} justifyContent='flex-end' sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <Grid item md={4}>
                        {checkIsTrial() && <Button style={{ marginLeft: '15%' }} onClick={handleViewOptionsClick}>View default trial graphing options</Button>}
                    </Grid>
                    <Grid item md={4}>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <CustomBtn startIcon={<Zoom />} variant='contained' style={handleButtonStyle()} onClick={zoomOut} disabled={!zoomOutButtonEnabled()}>Zoom Out</CustomBtn>
                            </Grid>
                            <Grid item style={{ marginLeft: '16px' }}>
                                <ColorSelectionModal {...colorSelectionModalProps} />
                                <CustomBtnOutlined variant='outlined' onClick={() => setShowColorPaletteModal(true)}>Customize Colors</CustomBtnOutlined>
                            </Grid>
                            <Grid item style={{ marginLeft: '16px' }}>
                                {isCustomized ? <CustomBtnOutlined variant='outlined' onClick={resetColors}>Reset Colors</CustomBtnOutlined> : <></>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4}>
                        <GraphDownloadOptions {...graphDownloadOptionsProps} />
                    </Grid>
                </Grid>
            )}
            <ComponentMessageHandler {...componentMessageHandlerProps} />
            <ResponsiveContainer id={imageID} height={chartSizes.medium} className='large-graph-container prevent-select'>
                <ComposedChart
                    width={1000}
                    height={600}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    data={dataSource}
                    barCategoryGap={0}
                    barGap={0}
                    onMouseDown={(e: any) => {
                        if (e && e.activeLabel) {
                            setRefAreaLeft(e.activeLabel)
                        }
                    }}
                    onMouseMove={(e: any) => {
                        if (e && e.activeLabel) {
                            refAreaLeft && setRefAreaRight(e.activeLabel)
                        }
                    }}
                    onMouseUp={(e: any) => {
                        zoom()
                    }}
                >
                    <CartesianGrid />
                    {generateXAxis()}
                    {generateYAxis()}
                    <Legend
                        onClick={handleLegendClick}
                        wrapperStyle={{
                            padding: '0 2rem',
                            overflow: 'auto',
                            maxHeight: 140
                        }}
                    />
                    {generateCharts()}
                    {dataSource.length > 0 && <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'red', strokeWidth: 1 }} />}
                    {graphCompanyLogoSVG()}
                    {checkIsTrial() && renderTrialUserWatermarkText()}
                    {refAreaLeft && refAreaRight && (
                        <ReferenceArea
                            yAxisId='left'
                            x1={refAreaLeft}
                            x2={refAreaRight}
                            strokeOpacity={0.3}
                        />
                    )}
                </ComposedChart>
            </ResponsiveContainer>
            {(dataSource?.length > 0 || zoomedResponse) && (
                <ResponsiveContainer className='no-background'>
                    <div style={{ paddingLeft: 100, paddingRight: 50 }}>
                        <GraphDateRangeSlider {...dateRangeSliderProps} />
                    </div>
                </ResponsiveContainer>
            )}
            {graphingOptions?.settings?.includeVolumeChart && (
                <>
                    <p style={{ paddingTop: 10 }}>Volume</p>
                    <ResponsiveContainer width='100%' height={chartSizes.small} className='half-container prevent-select'>
                        <BarChart
                            width={1000}
                            height={600}
                            data={dataSource}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            barCategoryGap={0}
                            barGap={0}
                            onMouseDown={(e: any) => {
                                if (e && e.activeLabel) {
                                    setRefAreaLeft(e.activeLabel)
                                }
                            }}
                            onMouseMove={(e: any) => {
                                if (e && e.activeLabel) {
                                    refAreaLeft && setRefAreaRight(e.activeLabel)
                                }
                            }}
                            onMouseUp={(e: any) => {
                                zoom()
                            }}
                        >
                            <CartesianGrid />
                            {generateXAxis()}
                            <YAxis yAxisId={yAxisIdValue} orientation={orientationValue} scale={'linear'} domain={['auto', 'auto']} interval={'preserveStartEnd'} allowDecimals={false} tickCount={3} tick={customTicks} />
                            <Tooltip content={<VolumeTooltip />} cursor={{ stroke: 'red', strokeWidth: 1 }} />
                            <Legend formatter={volumeLegendFontColor} />
                            <Bar key={`${volumeChartName}Bar${0}`} yAxisId={yAxisIdValue} data={dataSource} name={volumeChartName} dataKey={volumeDataKey} stroke={volumeChartStrokeColor} fill={volumeChartFillColor} />
                            {refAreaLeft && refAreaRight && (
                                <ReferenceArea
                                    yAxisId={yAxisIdValue}
                                    x1={refAreaLeft}
                                    x2={refAreaRight}
                                    strokeOpacity={0.3}
                                />
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                </>
            )}
            {techIndicatorDataSource.length > 0 && (
                <>
                    <p style={{ paddingTop: 10 }}>Technical Indicators</p>
                    <ResponsiveContainer width='100%' height={chartSizes.small} className='half-container prevent-select'>
                        <LineChart
                            width={1000}
                            height={600}
                            data={techIndicatorDataSource}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            barCategoryGap={0}
                            barGap={0}
                            onMouseDown={(e: any) => {
                                if (e && e.activeLabel) {
                                    setRefAreaLeft(e.activeLabel)
                                }
                            }}
                            onMouseMove={(e: any) => {
                                if (e && e.activeLabel) {
                                    refAreaLeft && setRefAreaRight(e.activeLabel)
                                }
                            }}
                            onMouseUp={(e: any) => {
                                zoom()
                            }}
                        >
                            <CartesianGrid />
                            <XAxis type='number' domain={['dataMin', 'dataMax']} dataKey='dateUnixTimeStamp' scale='time' tickFormatter={unixTimestampDateFormatter} allowDuplicatedCategory={false} label={rechartsSourceTextLabelObject} />
                            <YAxis yAxisId={yAxisIdValue} orientation={orientationValue} scale={additionalGraphsScaleType} domain={[techMinValue, techMaxValue]} interval={'preserveStartEnd'} allowDecimals={false} tickCount={3} tick={customTicks} />
                            <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'red', strokeWidth: 1 }} />
                            <Legend />
                            <Line key={`${techChartName}Line${0}`} yAxisId={yAxisIdValue} name={techChartName} type='monotone' dataKey={techDataKey} stroke={techIndicatorColor} dot={<></>} connectNulls />
                            {refAreaLeft && refAreaRight && (
                                <ReferenceArea
                                    yAxisId={yAxisIdValue}
                                    x1={refAreaLeft}
                                    x2={refAreaRight}
                                    strokeOpacity={0.3}
                                />
                            )}
                        </LineChart>
                    </ResponsiveContainer>
                </>
            )}
            {valueIndicatorDataSource.length > 0 && (
                <>
                    <p style={{ paddingTop: 10 }}>Value Indicators</p>
                    <ResponsiveContainer width='100%' height={chartSizes.small} className='half-container prevent-select'>
                        <LineChart
                            width={1000}
                            height={600}
                            data={valueIndicatorDataSource}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            barCategoryGap={0}
                            barGap={0}
                            onMouseDown={(e: any) => {
                                if (e && e.activeLabel) {
                                    setRefAreaLeft(e.activeLabel)
                                }
                            }}
                            onMouseMove={(e: any) => {
                                if (e && e.activeLabel) {
                                    refAreaLeft && setRefAreaRight(e.activeLabel)
                                }
                            }}
                            onMouseUp={(e: any) => {
                                zoom()
                            }}
                        >
                            <CartesianGrid />
                            <XAxis type='number' domain={['dataMin', 'dataMax']} dataKey='dateUnixTimeStamp' scale='time' tickFormatter={unixTimestampDateFormatter} allowDuplicatedCategory={false} label={rechartsSourceTextLabelObject} />
                            <YAxis yAxisId={yAxisIdValue} orientation={orientationValue} scale={additionalGraphsScaleType} domain={[valueIndicatorMinValue, valueIndicatorMaxValue]} interval={'preserveStartEnd'} allowDecimals={false} tickCount={3} tick={customTicks} />
                            <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'red', strokeWidth: 1 }} />
                            <Legend />
                            <Line key={`${valueIndicatorChartName}Line${0}`} yAxisId={yAxisIdValue} name={valueIndicatorChartName} type='monotone' dataKey={valueIndicatorDataKey} stroke={valueIndicatorColor} dot={<></>} connectNulls />
                            {refAreaLeft && refAreaRight && (
                                <ReferenceArea
                                    yAxisId={yAxisIdValue}
                                    x1={refAreaLeft}
                                    x2={refAreaRight}
                                    strokeOpacity={0.3}
                                />
                            )}
                        </LineChart>
                    </ResponsiveContainer>
                </>
            )}
            {finRatioDataSource.length > 0 && (
                <>
                    <p style={{ paddingTop: 10 }}>Financial Ratio</p>
                    <ResponsiveContainer width='100%' height={chartSizes.small} className='half-container prevent-select'>
                        <LineChart
                            width={1000}
                            height={600}
                            data={finRatioDataSource}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            barCategoryGap={0}
                            barGap={0}
                            onMouseDown={(e: any) => {
                                if (e && e.activeLabel) {
                                    setRefAreaLeft(e.activeLabel)
                                }
                            }}
                            onMouseMove={(e: any) => {
                                if (e && e.activeLabel) {
                                    refAreaLeft && setRefAreaRight(e.activeLabel)
                                }
                            }}
                            onMouseUp={(e: any) => {
                                zoom()
                            }}
                        >
                            <CartesianGrid />
                            <XAxis type='number' domain={['dataMin', 'dataMax']} dataKey='dateUnixTimeStamp' scale='time' tickFormatter={unixTimestampDateFormatter} allowDuplicatedCategory={false} label={rechartsSourceTextLabelObject} />
                            <YAxis yAxisId={yAxisIdValue} orientation={orientationValue} scale={additionalGraphsScaleType} domain={[finRaioMinValue, finRaioMaxValue]} interval={'preserveStartEnd'} allowDecimals={false} tickCount={3} tick={customTicks} />
                            <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'red', strokeWidth: 1 }} />
                            <Legend />
                            <Line key={`${finRatioChartName}Line${0}`} yAxisId={yAxisIdValue} name={finRatioChartName} type='monotone' dataKey={finRatioDataKey} stroke={finRatioColor} dot={<></>} connectNulls />
                            {refAreaLeft && refAreaRight && (
                                <ReferenceArea
                                    yAxisId={yAxisIdValue}
                                    x1={refAreaLeft}
                                    x2={refAreaRight}
                                    strokeOpacity={0.3}
                                />
                            )}
                        </LineChart>
                    </ResponsiveContainer>
                </>
            )}
            {checkIsTrial() && <TrialOptionsModal modalProps={modalProps} chartSettings={chartSettings}></TrialOptionsModal>}
        </>
    )
}

export default GraphAndChart
