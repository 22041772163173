import { Box } from "@mui/material"
import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"
import { BlueBlueText, BrownBrownText, GreenGreenText, ImageNumberReference, MarkedRedRedText, MarkedSalmonPinkText, PurplePurpleText } from "../../HelpPageCommonComponents"

const Capitalization = () => {
    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }}>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <br />
                        <br />
                        <br />
                        <h2 style={{ textDecoration: 'underline' }}>
                            Capitalization
                        </h2>
                    </div>
                </Box>
                <div style={{ height: '536px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20Capitalization.png' alt='Capitalization' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>The Capitalization tool allows you to rank company sizes by their market caps. Additional options provided by the tool give you the ability to further filter your data as desired. </p>
                <br />
                <br />

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <h4>
                            Capitalization Options
                        </h4>
                    </div>
                </Box>

                <div style={{ height: '825px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CapitalizationConstituentSummary.png' alt='Capitalization Constituent Summary' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    You may choose to view either constituent data or summary data.
                    By default, constituent data options are displayed. For example, by selecting summary data, the options below will be displayed:
                </p>
                <div style={{ height: '407px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20Capitalization%20SummaryOptions.png' alt='Capitalization Summary Options' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    You can see that the options remain mostly the same, with the exception of a few final options, like choosing the summary type.
                    There's also an option to view the historical summary chart for a selected number of years. You can choose to view up to 500 years.
                </p>
                <br />

                <div style={{ height: '825px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20Capitalization%20ConstituentOptions.png' alt='Capitalization Options' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The <MarkedRedRedText /> box ({ImageNumberReference(1)}) contains the option for selecting the index.
                    Choosing an index will narrow the results to only members which comprise the index.
                </p>
                <p>
                    The <BlueBlueText /> box ({ImageNumberReference(2)}) contains the options for selecting either, or both, a listing country and operating country.
                    The listing country is the country in which the selected exchange is located.
                    The operating country is the country in which the exchange primarily operates.
                </p>
                <p>
                    The <GreenGreenText /> box ({ImageNumberReference(3)}) contains the options for selecting either a sector, or sector and index.
                </p>
                <p>
                    The <PurplePurpleText /> box ({ImageNumberReference(4)}) contains the options for setting a custom date range.
                </p>

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <h4>
                            Capitalization Results
                        </h4>
                    </div>
                </Box>
                <div style={{ height: '294px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20Capitalization%20ResultsSummary.png' alt='Capitalization Results Summary' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The <MarkedRedRedText /> box ({ImageNumberReference(1)}) contains options for charting all results as a pie chart, a bar chart, or to export the data (download the results as an Excel spreadsheet).
                    You may also select individual rows- selecting one or more will cause a toolbar to be displayed, as shown in the picture below.
                </p>
                <br />
                <div style={{ height: '291px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20Capitalization%20ResultsSummaryToolbar.png' alt='Capitalization Results Summary Toolbar' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    Within the red box on the toolbar there are several options for your selected data.
                    These options are located within the <MarkedRedRedText /> box ({ImageNumberReference(1)}), where you can graph the selected data as a pie chart, a bar chart, or export them.
                    The <BlueBlueText /> arrow ({ImageNumberReference(2)}) indicates the pie chart icon, which will graph the selected rows as a pie chart.
                    The <PurplePurpleText /> arrow ({ImageNumberReference(3)}) indicates the bar chart icon, which will graph the data as a bar chart.
                    The <GreenGreenText /> arrow ({ImageNumberReference(4)}) indicates the export results icon, which you may click to download the selected data in Excel spreedsheet format.
                </p>
                <br />
                <div style={{ height: '767px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CapitalizationResults.png' alt='Capitalization Results' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    You may choose to view the Capitalization Results in either a list view or grid view.
                    Both of these options are found in the <MarkedRedRedText /> box ({ImageNumberReference(1)}) in the above image.
                </p>
                <br />

                <div style={{ height: '432px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CapitalizationGridResults.png' alt='Capitalization Results Grid' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>Above is an example of a grid view format for a set of Capitalization Results.</p>
                <br />
                <div style={{ height: '374px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20Capitalization%20ToolBar.png' alt='Capitalization Results ToolBar' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The <MarkedRedRedText /> box ({ImageNumberReference(1)}) contains options to download, export, or add your data to a workbook. The box to the right, pointed to by the <GreenGreenText /> arrow ({ImageNumberReference(2)}), contains an icon which you may click to export all the search results.
                </p>
                <p>
                    The box on the left contains options for you to manipulate selected results only. The <BrownBrownText /> arrow ({ImageNumberReference(3)}) points to the option which will allow you to add the selected results to an AutoTrac workbook.
                    The <GreenGreenText /> arrow ({ImageNumberReference(4)}) will export only the selected results, unlike the export icon in the above mentioned box, which will export all results.
                    The <MarkedSalmonPinkText /> arrow ({ImageNumberReference(5)}) will allow you to download the selected results with additional options in a pop-up.
                </p>
            </div>

        </React.Fragment>
    )
}

export default Capitalization