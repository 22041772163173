import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useEffect, useState } from 'react'
import AutoTracTickerSearch from '../../Common/Utility/AutoTracTickerSearch'
import { SearchOptionsData } from '../../../Models/DataModels/Common/SearchModels'
import AddTicker from '../../Icons/AddTickerIcon'

export interface AutoTracSearchProps {
  isWorkbookSelected: boolean,
  addTickerToWorkbook: (tickerName: string) => void,
  resetSearchTermToggle: boolean
}

const Search = ({ isWorkbookSelected, addTickerToWorkbook, resetSearchTermToggle }: AutoTracSearchProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [addTickerIconColorIsPrimary, setAddTickerIconColorIsPrimary] = useState<boolean>(false)

  useEffect(() => {
    if (isWorkbookSelected && searchTerm && searchTerm?.indexOf('~') !== -1) {
      setAddTickerIconColorIsPrimary(true)
    } else {
      setAddTickerIconColorIsPrimary(false)
    }
  }, [searchTerm, isWorkbookSelected])

  const onAddTickerToWorkbook = () => {
    addTickerToWorkbook(searchTerm)
  }

  const onSetSearchTerm = (searchTerm: string, searchOptions: SearchOptionsData) => {
    setSearchTerm(searchTerm)
  }

  return (
    <div
      style={{ padding: '8px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
    >
      <Tooltip title='Add Ticker to Selected Workbook'>
        <IconButton 
          color={addTickerIconColorIsPrimary ? 'primary' : 'default'}
          sx={{ p: '10px' }}
          aria-label='add ticker'
          onClick={onAddTickerToWorkbook}>
            <AddTicker style={{fontSize: '1.25em', marginLeft: '10px'}} />
        </IconButton>
      </Tooltip>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

      <AutoTracTickerSearch onSetSearchTerm={onSetSearchTerm} resetSearchTermToggle={resetSearchTermToggle}/>
    </div>
  )
}

export default Search
