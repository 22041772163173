import React from "react"
import { ReactComponent as InfoSvgIcon } from "../../Images/svg/InfoSvg.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const InfoSvg = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <InfoSvgIcon />
  </SvgIcon>
)

export default InfoSvg