import React from "react"
import { ReactComponent as EditIndicesIcon } from "../../Images/svg/EditIndices.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const EditIndices = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <EditIndicesIcon />
  </SvgIcon>
)

export default EditIndices