export const graphCompanyLogoSVG = () => {
    return (
        <svg>
            <image href={require('../../../Images/GFDFinaeonLogo3.png')} height='32px' width='168px' x='100' y='20' />
        </svg>
    )
}

const sourceText: string = 'Source: Global Financial Data'

export const sourceTextSVG = () => {
    return (
        <a href='http://www.globalfinancialdata.com' style={{ textDecoration: 'none' }} target='_blank' >
            <text
                x='99%'
                y='95%'
                style={{ fontSize: 12, fontWeight: 'bold' }}
                textAnchor='end'
            >
                {sourceText}
            </text>
        </a>
    )
}

export const sourceTextSVGNoLink = (xOverride?: number | string, yOverride?: number | string) => {
    return (
        <text
            x={xOverride || '99%'}
            y={yOverride || '95%'}
            style={{ fontSize: 12, fontWeight: 'bold' }}
            textAnchor='end'
        >
            {sourceText}
        </text>
    )
}

export const rechartsSourceTextLabelObject = {
    value: sourceText,
    position: 'insideBottomRight',
    offset: 40
}

export const renderTitleOnGraph = (displayText: string) => {
    return (
        <text
            x='30%'
            y='3%'
            style={{ fontSize: 14, fontWeight: 'bold' }}
            textAnchor='center'
        >
            {displayText}
        </text>
    )
}

export const renderTrialUserWatermarkText = () => {
    const xPercentStartValue: number = 20
    const yPercentStartValue: number = 25
    return (
        <>
            <text
                x={`${xPercentStartValue}%`}
                y={`${yPercentStartValue}%`}
                style={{
                    fontSize: 48,
                    fontWeight: 'bold',
                    fill: 'grey',
                    pointerEvents: 'none',
                    opacity: '0.1',
                }}
            >
                For full demo, contact Global Financial Data
            </text>
            <text
                x={`${xPercentStartValue}%`}
                y={`${yPercentStartValue + 10}%`}
                style={{
                    fontSize: 48,
                    fontWeight: 'bold',
                    fill: 'grey',
                    pointerEvents: 'none',
                    opacity: '0.1',
                }}
            >
                at (949) 542-4200 or email
            </text>
            <text
                x={`${xPercentStartValue}%`}
                y={`${yPercentStartValue + 20}%`}
                style={{
                    fontSize: 48,
                    fontWeight: 'bold',
                    fill: 'grey',
                    pointerEvents: 'none',
                    opacity: '0.1',
                }}
            >
                sales@globalfinancialdata.com.
            </text>
        </>
    )
}
