import React from "react"
import { ReactComponent as RefreshDownloadQueueIcon } from "../../Images/svg/RefreshDownloadQueue.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const RefreshDownloadQueue = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <RefreshDownloadQueueIcon />
  </SvgIcon>
)

export default RefreshDownloadQueue