import React from "react"
import { ReactComponent as SplitIcon } from "../../Images/svg/SplitIcon.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const Split = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <SplitIcon />
  </SvgIcon>
)

export default SplitIcon