import { Box } from "@mui/material"
import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"

const YieldCurve = () => {
    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }}>
                <Box sx={helpPageStyles.heading}>
                    <div>
                        <br />
                        <br />
                        <br />
                        <h2 style={{ textDecoration: 'underline' }}>
                            Yield Curve
                        </h2>
                    </div>
                </Box>
                <div style={{ height: '490px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20YieldCurve.png' alt='Yield Curve' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The Yield Curve tool will give you the ability to graphically view the yields on a country's sovereign debt, as seen below.
                </p>

                <div style={{ height: '494px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20YieldCurve%20Example.png' alt='Yield Curve Example' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    You can select an economic group to narrow the list of countries available in the drop-down for the yield curve.
                    This is not a requirement; if you do not select a group, all available countries will be displayed.
                </p>
                <p>
                    For example, selecting the BRICS economic group will populate the countries drop-down with Brazil, China, India, Russian Federation, and South Africa.
                </p>

            </div>
        </React.Fragment>
    )
}

export default YieldCurve