import SearchIcon from '@mui/icons-material/Search'
import React, { useEffect, useState } from 'react'
import { AutoCompleteRequest } from '../../../Models/DataModels/Requests/SearchRequests'
import { autoComplete } from '../../../Services/SearchService'
import { AutoCompleteData } from '../../../Models/DataModels/Responses/SearchResponses'
import { Autocomplete, Box, Grid, MenuItem, TextField, Tooltip } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import HomeSearchOptionModal from '../../Common/Modals/HomeSearchOptionModal'
import TimelineIcon from '@mui/icons-material/Timeline'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from '../../../Models/DataModels/Common/RedirectionModel'
import SearchFieldChangeModal from '../../Common/Modals/SeachFieldChangeModal'
import { SearchDatabaseType } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { getTitle } from './SelectEngine'
import { CustomBtnBlue, BtnGraphSearch, BtnHeaderEnabledDisabled } from '../../Common/GlobalSettings/CustomStyles'

export interface TickerHomeSearchProps {
  onSearchClick: any,
  tickerSearchProps: any,
  engineOption: SearchDatabaseType,
  checkIsTrial: () => boolean,
}

const TickerHomeSearch = ({
  onSearchClick,
  tickerSearchProps,
  engineOption,
  checkIsTrial
}: any) => {

  const location: any = useLocation()
  const navigate = useNavigate()
  const defaultOptions: [any] = [{ label: '' }]

  const [searchTermInput, setSearchTermInput] = useState<string>('')
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<[any]>(defaultOptions)
  const [labelValue, setLabelValue] = useState<string>('')
  const [useSearchFieldTypes, setUseSearchFieldTypes] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [stocksShowModal, setStocksShowModal] = useState<boolean>(false)
  const [changeSearchField, setChangeSearchField] = useState<any>()
  const [confirmChange, setConfirmChange] = useState<boolean>(false)
  const [searchCommitted, setSearchCommitted] = useState<boolean>(false)

  const searchFieldTypes = {
    US: [
      { label: 'Ticker', value: 'Symbol' },
      { label: 'CIK', value: 'CIK' },
      { label: 'CUSIP', value: 'CUSIP' },
      { label: 'SIC', value: 'SIC' },
      { label: 'NAICS', value: 'NAICS' }
    ],
    UK: [
      { label: 'Ticker', value: 'Symbol' },
      { label: 'CIK', value: 'CIK' },
      { label: 'NAICS', value: 'NAICS' },
      { label: 'SIC', value: 'SIC' },
    ]
  }

  const searchOption = 'contains'
  const sortOption = 'popular'

  const invertedAllowedRegex = {
    basicSearch: /([^a-zA-Z0-9-._, +]+)/gi,
    numbers: /([^0-9]+)/gi
  }

  const isNumericSearchFieldTypes = (currentSearchField: string): boolean => {
    if (currentSearchField === 'SIC' || currentSearchField === 'NAICS') {
      return true
    }
    return false
  }

  const removeInvalidChars = (inputString: string): string => {
    const cleanedString: string = useSearchFieldTypes && isNumericSearchFieldTypes(changeSearchField) ? inputString.replace(invertedAllowedRegex.numbers, '') : inputString.replace(invertedAllowedRegex.basicSearch, '')
    return cleanedString
  }

  const autoCompleteDataFactory = (label: string, value: string): AutoCompleteData => {
    return { label: label, value: value }
  }

  const onOptionChange = (e: React.SyntheticEvent<Element, Event>, value: any) => {

    const optionValue = value?.value || ''
    setSearchTermInput(optionValue)
    onSearchClick(optionValue)
    setSearchCommitted(true)

    if (engineOption !== 'eventsInTime') {
      setAutoCompleteOptions(defaultOptions)
    }
  }

  const checkIfEnterKey = (e: any) => {
    if (e.key === 'Enter') {
      setSearchCommitted(true)
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault()
    const inputtedString: string = removeInvalidChars(e.target.value).trim()
    if (engineOption !== 'eventsInTime') {
      autoCompleteForSearch(inputtedString)
    }
    setSearchTermInput(inputtedString)
  }

  const autoCompleteForSearch = (textValue: string) => {
    const req: AutoCompleteRequest = {
      searchTerm: textValue,
      database: engineOption,
      matchOption: searchOption,
      sortOption: sortOption
    }
    autoComplete(req).then((res) => {
      let autoValues: [any] = ['']
      res.map((a: any) => {
        autoValues.push(autoCompleteDataFactory(a, a))
      })
      autoValues.shift()
      setAutoCompleteOptions(autoValues)
    }, () => { })
  }

  const renderItems = () => {
    let result: any = []
    tickerSearchProps.currentEngine === 'USStocks' ?
      searchFieldTypes.US.forEach((item: any, index: number) => {
        result.push(<MenuItem key={`searchFieldType${index}`} value={item.value}>{item.label}</MenuItem>)
      })
      :
      searchFieldTypes.UK.forEach((item: any, index: number) => {
        result.push(<MenuItem key={`searchFieldType${index}`} value={item.value}>{item.label}</MenuItem>)
      })

    return result
  }

  const handleShowModal = () => {
    tickerSearchProps.setLastSearchTerm(searchTermInput)
    setShowModal(true)
  }
  const openGraphing = () => {
    navigate(paths.graph.lineGraph, { state: { prevPage: location.pathname } })
  }

  const modalProps = {
    showModal: showModal,
    setShowModal: setShowModal,
    filterAndSort: tickerSearchProps.filterAndSort
  }

  const stocksModalProps = {
    stocksShowModal: stocksShowModal,
    setStocksShowModal: setStocksShowModal,
    setConfirmChange: setConfirmChange
  }

  useEffect(() => {
    setAutoCompleteOptions(defaultOptions)
  }, [engineOption])

  const renderOptionsButton = () => {
    return (
      <React.Fragment>
        <Box>
          <CustomBtnBlue variant='contained' style={{ borderRadius: '5px 3px 0px 5px', width: '5%' }} onClick={() => handleShowModal()} title='Search Options' aria-label='Search Options'>
            <SettingsIcon />
          </CustomBtnBlue>
        </Box>
      </React.Fragment>
    )
  }

  useEffect(() => {
    setSearchTermInput('')
    setLabelValue(getTitle(engineOption))
    if (tickerSearchProps.currentEngine === 'USStocks' || tickerSearchProps.currentEngine === 'UKStocks') {
      setUseSearchFieldTypes(true)
    } else {
      setUseSearchFieldTypes(false)
    }
  }, [tickerSearchProps.currentEngine])

  useEffect(() => {
    if (searchTermInput.length > 0 && searchTermInput.trim().length > 0) {
      setStocksShowModal(true)
    }
    if (searchTermInput.length > 0 && searchTermInput.trim().length > 0) {
      setStocksShowModal(true)
    }
    else {
      if (tickerSearchProps.currentEngine === 'USStocks') {
        tickerSearchProps.searchEngineOptions.stocksDataUS.setSearchField(changeSearchField)
      }
      else { tickerSearchProps.searchEngineOptions.stocksDataUK.setSearchField(changeSearchField) }
    }
  }, [changeSearchField])

  useEffect(() => {
    if (confirmChange === true) {
      if (tickerSearchProps.currentEngine === 'USStocks') {
        tickerSearchProps.searchEngineOptions.stocksDataUS.setSearchField(changeSearchField)
      }
      else { tickerSearchProps.searchEngineOptions.stocksDataUK.setSearchField(changeSearchField) }
      setConfirmChange(false)
    }
  }, [confirmChange])

  useEffect(() => {
    setSearchTermInput('')
    onSearchClick('')
    setAutoCompleteOptions(defaultOptions)
  }, [tickerSearchProps.searchEngineOptions.stocksDataUS.searchField, tickerSearchProps.searchEngineOptions.stocksDataUK.searchField])

  useEffect(() => {
    if (searchCommitted) {
      onSearchClick(searchTermInput)
      setSearchCommitted(false)
    }
  }, [searchCommitted])

  const renderSearchandGraphButtons = () => {
    return (
      <React.Fragment>
        <Box>
          <BtnGraphSearch variant='contained' style={{ borderRadius: '3px 5px 5px 0px' }} onClick={() => setSearchCommitted(true)} title='Search' aria-label='Search'>
            <SearchIcon />
          </BtnGraphSearch>
        </Box>
        <Box sx={{ paddingLeft: 3, borderLeft: '0.18em solid #e3e2e2', marginLeft: 3 }}>
          <Tooltip title={checkIsTrial() ? 'You must be subscribed to unlock graphing. To graph series that you have access as a trial user, select the subscribed filter in search results and click on graph button from quick actions.' : ''}>
            <div>
              <BtnHeaderEnabledDisabled variant='outlined' size='medium' endIcon={<TimelineIcon />} onClick={openGraphing} disabled={checkIsTrial()}>
                Graphing
              </BtnHeaderEnabledDisabled>
            </div>
          </Tooltip>
        </Box>
      </React.Fragment>
    )
  }

  return (
    <Box sx={TickerHomeSearchStyles.rowStyle}>
      {engineOption !== 'eventsInTime' && renderOptionsButton()}

      {useSearchFieldTypes ?
        <TextField
          label='Search Field Type'
          style={{ width: '20%' }}
          size='small'
          select
          value={tickerSearchProps.currentEngine === 'USStocks' ? tickerSearchProps.searchEngineOptions.stocksDataUS.searchField : tickerSearchProps.searchEngineOptions.stocksDataUK.searchField}
          onChange={(e) => {
            e.preventDefault()
            tickerSearchProps.currentEngine === 'USStocks' ? setChangeSearchField(e.target.value) : setChangeSearchField(e.target.value)
          }}
          onKeyDown={(e) => {
            checkIfEnterKey(e)
          }}
        >
          {renderItems()}
        </TextField>
        :
        <></>
      }

      {engineOption !== 'eventsInTime' ?
        <Autocomplete
          disablePortal
          id='universeQuickSearchBar'
          value={searchTermInput}
          options={autoCompleteOptions}
          style={{ width: '50%' }}
          size='small'
          onChange={onOptionChange}
          clearOnBlur={false}
          renderInput={(params) => <TextField {...params}
            label={`${labelValue}: Use Search Options to refine results`}
            onChange={onInputChange}
            onKeyDown={(e) => {
              checkIfEnterKey(e)
            }}
          />}
        />
        :
        <Grid container direction={'row'}>
          <TextField
            id='eventsSearchBar'
            type='text'
            sx={{
              paddingLeft: 7,
              width: '54%',
              "& .MuiInputBase-root": {
                height: '70%',
              },
              "& label": {
                paddingLeft: 7,
              },
            }}
            value={searchTermInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const inputtedString: string = removeInvalidChars(event.target.value)
              setSearchTermInput(inputtedString)
            }}
            onKeyDown={(e) => {
              checkIfEnterKey(e)
            }}
            label={`${labelValue}: Use Search Options to refine results`}
          ></TextField>
          {renderSearchandGraphButtons()}
        </Grid>
      }

      {engineOption !== 'eventsInTime' ?
        renderSearchandGraphButtons()
        :
        <React.Fragment></React.Fragment>}
      {showModal ? <HomeSearchOptionModal modalProps={modalProps} /> : <></>}
      {searchTermInput && stocksShowModal ? <SearchFieldChangeModal modalProps={stocksModalProps} /> : <></>}
    </Box>
  )
}

const TickerHomeSearchStyles = {
  rowStyle: {
    display: 'flex',
    flex: 'flex',
    flexDirection: 'row',
    height: '100%'
  }
}

export default TickerHomeSearch
