import { Grid } from '@mui/material'
import Search, { SearchProps } from './SubComponents/Search'
import { getAuthTokenFromCookie } from "../../Services/CookieAccessService"
import Item from '../Common/Utility/Item'
import { RedirectLogin } from '../../Services/RedirectionService'
import { LogoutReasonType } from '../../Models/DataModels/Requests/AuthRequests'
import { CSSProperties, Dispatch, SetStateAction } from 'react'
import { UserInfo } from '../../Models/DataModels/Common/UserInfoModel'
import { RefreshResponse } from '../../Models/DataModels/Responses/AuthResponses'
import SessionTimer, { SessionTimerProps } from '../Common/Modals/SessionTimer/SessionTimer'
import { globalStyles } from '../Common/GlobalSettings/GlobalStyles'

export interface HomeProps {
  userInfo: UserInfo | null,
  checkIsTrial: () => boolean,
  checkIsAnonymous: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  showSaved: boolean,
  setShowSaved: Dispatch<SetStateAction<boolean>>,
  processRefreshTokenResponse: (response: RefreshResponse) => void
}

const SearchHome = ({
  userInfo,
  checkIsTrial,
  checkIsAnonymous,
  signOut,
  showSaved,
  setShowSaved,
  processRefreshTokenResponse
}: HomeProps) => {

  const auth: any = getAuthTokenFromCookie()
  if (!auth) {
    return RedirectLogin()
  }

  const searchProps: SearchProps = {
    userInfo,
    checkIsTrial,
    checkIsAnonymous,
    signOut,
    showSaved,
    setShowSaved,
    isComposite: false
  }

  const sessionTimerProps: SessionTimerProps = {
    processRefreshTokenResponse,
    signOut: signOut
  }

  return (
    <div style={ { ...globalStyles.mainPageBody } as CSSProperties }>
      <Grid container spacing={1} columns={16}>
        <Grid item md={16}>
          <Item>
            <Search {...searchProps} />
          </Item>
        </Grid>
      </Grid>
      <SessionTimer {...sessionTimerProps} />
    </div>
  )
}

export default SearchHome
