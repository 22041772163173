import { 
    CustomIndicesRequest, 
    CustomIndexMembersRequest, 
    CustomIndexSaveRequest, 
    CustomIndexDeleteRequest,
    CustomIndexRequest,
    SyncCustomIndexRequest
} from "../Models/DataModels/Requests/ToolsRequests"
import getAPICallResult, { APIRequest, HttpRequest } from "./APIService"

const controller = 'Screening'

export const getCustomIndices = async (request: CustomIndicesRequest) => {
    const apiRequest : APIRequest = {
        relativePath: `/${controller}/GetCustomIndices`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const saveCustomIndex = async (request: CustomIndexSaveRequest) => {
    const apiRequest : APIRequest = {
        relativePath: `/${controller}/SaveCustomIndex`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const getCustomIndexMembers = async (request: CustomIndexMembersRequest) => {
    const apiRequest : APIRequest = {
        relativePath: `/${controller}/GetCustomIndexMembers`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const deleteCustomIndex = async (request: CustomIndexDeleteRequest) => {
    const apiRequest : APIRequest = {
        relativePath: `/${controller}/DeleteCustomIndex`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const getCustomIndex = async (request: CustomIndexRequest) => {
    const apiRequest : APIRequest = {
        relativePath: `/${controller}/GetCustomIndex`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const syncCustomIndex = async (request: SyncCustomIndexRequest) => {
    const apiRequest : APIRequest = {
        relativePath: `/${controller}/SyncCustomIndex`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const exportCustomIndices = async (request: CustomIndicesRequest) => {
    const apiRequest : APIRequest = {
        relativePath: '/Export/ExportCustomIndices',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
  }
