import { styled } from '@mui/material'
import {TreeItem } from '@mui/x-tree-view'

export const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  fontSize: 'inherit',
  '.MuiTreeItem-label': {
    fontSize: 'inherit!important'
  }
}))

export default StyledTreeItem
