import { useEffect, useState } from "react"
import CAPEChart, { CAPEChartProps } from "./CAPERatios/CAPEChart"
import { CAPERatioInputs, CAPERatioInputProps } from "./CAPERatios/CAPERatioInputs"
import { CAPERatioRequest, CapeParameterSet, CapeRequestTypes, FileDownloadRequest } from "../../../../Models/DataModels/Requests/ToolsRequests"
import { downloadFile, getCAPERatioData } from "../../../../Services/ToolsService"
import { CAPEDataResponse } from "../../../../Models/DataModels/Responses/ToolsResponses"
import { CAPEModel, CAPEModelData, EventData } from "../../../../Models/DataModels/Common/CAPERatioModel"
import moment from "moment"
import { NotOKResponseModel } from "../../../../Models/DataModels/Common/NotOKResponseModel"
import { LogoutReasonType } from "../../../../Models/DataModels/Requests/AuthRequests"
import { ComponentMessageHandler, ComponentMessageHandlerProps } from "../../../Common/Utility/ComponentMessageHandler"
import { MessageResponse, MessageResponseValueType } from "../../../../Models/DataModels/Responses/NotOKResponse"
import { OutputFormatValueType } from "../../../../Models/DataModels/Common/OutputFormatModel"
import { ToolsAccesibilityProps } from "../../../../Models/DataModels/Common/ToolsModel"

export interface CAPERatioProps {
  checkIsTrial: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  accessibilityProps: ToolsAccesibilityProps
}

const CAPERatio = ({
  checkIsTrial,
  signOut,
  accessibilityProps
}: CAPERatioProps) => {
  const [graphData, setGraphData] = useState<CAPEModel | null>()

  const [CAPEData, setCAPEData] = useState<CAPEDataResponse | null>()
  const [comparisonCAPEData, setComparisonCAPEData] = useState<CAPEDataResponse | null>()
  const [isComparison, setIsComparison] = useState<boolean>(false)

  const [mainRequestInProgress, setMainRequestInProgress] = useState<boolean>(false)
  const [comparisonRequestInProgress, setComparisonRequestInProgress] = useState<boolean>(false)
  const [downloadRequestInProgress, setDownloadRequestInProgress] = useState<boolean>(false)

  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
  const [messages, setMessages] = useState<MessageResponse[]>([])

  const [capeRequests, setCapeRequests] = useState<CAPERatioRequest[]>([])

  const displayMessage = (message: string, type: MessageResponseValueType) => {
    const componentMessage: MessageResponse = {
      message: message as string,
      type
    }
    setErrorResponse(null)
    setMessages([componentMessage])
    return
  }

  const displayResponse = (model: NotOKResponseModel) => {
    if (model) {
      setMessages([])
      setErrorResponse(model as NotOKResponseModel)
      return
    }
  }

  const resetMessage = () => {
    setMessages([])
    setErrorResponse(null)
  }

  const loadHash = (response: CAPEDataResponse, loadCompare: boolean, hash: { [key: number]: CAPEModelData }) => {
    let dateNumber
    let model: CAPEModelData | null

    response.capeData.forEach((capeData, index) => capeData.dataPoints.reduce((accum, point) => {
      dateNumber = moment(point.date).valueOf()
      model = accum[dateNumber]
      if (!model) {
        model = {
          dateAsNumber: moment(point.date).valueOf()
        }
        accum[dateNumber] = model
      }

      if (loadCompare || index > 0) {
        model.compareValue = point.value
      } else {
        model.value = point.value
      }

      return accum
    }, hash))
  }

  const getMarketEvents = (response: CAPEDataResponse): EventData[] => {
    let entry: EventData
    const events = response.marketEvents.map((event) => {
      entry = {
        iso: response.iso,
        startDateAsNumber: moment(event.start).valueOf(),
        endDateAsNumber: moment(event.end).valueOf(),
        description: event.description
      }
      return entry
    })

    return events
  }

  const getSortedValues = (hash: { [key: number]: CAPEModelData }) => {
    return Object.values(hash).sort((m, c) => m.dateAsNumber - c.dateAsNumber)
  }

  useEffect(() => {
    if (isComparison) {
      if (CAPEData && comparisonCAPEData
        && CAPEData.capeData?.length > 0 && comparisonCAPEData.capeData?.length > 0) {
        const hash: { [key: number]: CAPEModelData } = {}
        loadHash(CAPEData, false, hash)
        loadHash(comparisonCAPEData, true, hash)

        const capeModel: CAPEModel = {
          xLabel: 'Date',
          yLabel: CAPEData.capeData[0].isExcessCAPEYield ? 'Excess CAPE Yield' : 'CAPE Ratio',
          dataPoints: getSortedValues(hash),
          mainLine: `${CAPEData.capeData[0].capeIndex} ${CAPEData.capeData[0].name}`,
          comparisonLine: `${comparisonCAPEData.capeData[0].capeIndex} ${comparisonCAPEData.capeData[0].name}`,
          events: getMarketEvents(CAPEData),
          mainISO: CAPEData.iso,
          comparisonISO: comparisonCAPEData.iso
        }

        const otherEvents = getMarketEvents(comparisonCAPEData)
        capeModel.events = [...capeModel.events, ...otherEvents]

        setGraphData(capeModel)
      }
    } else {
      if (CAPEData && CAPEData.capeData?.length > 0) {
        const hash: { [key: number]: CAPEModelData } = {}
        loadHash(CAPEData, false, hash)

        const capeModel: CAPEModel = {
          xLabel: 'Date',
          yLabel: CAPEData.capeData[0].isExcessCAPEYield ? 'Excess CAPE Yield' : 'CAPE Ratio',
          mainLine: `${CAPEData.capeData[0].capeIndex} ${CAPEData.capeData[0].name}`,
          dataPoints: getSortedValues(hash),
          events: getMarketEvents(CAPEData),
          mainISO: CAPEData.iso,
          comparisonISO: CAPEData.iso
        }

        if (CAPEData.capeData.length > 1) {
          capeModel.comparisonLine = `${CAPEData.capeData[1].capeIndex} ${CAPEData.capeData[1].name}`
        }

        setGraphData(capeModel)
      }
    }
  }, [CAPEData, comparisonCAPEData])

  const viewChart = (request: CAPERatioRequest, comparisonRequest?: CAPERatioRequest) => {
    if (!request) return

    setGraphData(null)
    setIsComparison(Boolean(comparisonRequest))
    setCAPEData(null)
    setComparisonCAPEData(null)

    setMainRequestInProgress(true)
    getCAPERatioData(request).then((response) => {
      if (response && response.length > 0) setCAPEData(response[0])
      setMainRequestInProgress(false)
    },
      (notOKResponseModel: NotOKResponseModel) => {
        displayResponse(notOKResponseModel)
        setMainRequestInProgress(false)
      })

    if (comparisonRequest) {
      setComparisonRequestInProgress(true)
      getCAPERatioData(comparisonRequest).then((response) => {
        if (response && response.length > 0) setComparisonCAPEData(response[0])
        setComparisonRequestInProgress(false)
      },
        (notOKResponseModel: NotOKResponseModel) => {
          displayResponse(notOKResponseModel)
          setComparisonRequestInProgress(false)
        })
    }
  }

  const download = (request: CAPERatioRequest, retainGraph: boolean) => {
    if (!request) return

    if (!retainGraph) {
      setGraphData(null)
      setIsComparison(false)
      setCAPEData(null)
      setComparisonCAPEData(null)
    }

    setDownloadRequestInProgress(true)
    getCAPERatioData(request).then(async (response: any) => {
      const downloadRequest: FileDownloadRequest = {
        filename: response.filename,
        mimeType: response.mimeType
      }
      downloadFile(downloadRequest).then(async (fileDownloadResponse: any) => {
        const b = await fileDownloadResponse.blob()
        let downloadLink = document.createElement('a')
        let url = URL.createObjectURL(b)
        downloadLink.href = url
        downloadLink.download = downloadRequest.filename
        downloadLink.click()
        setDownloadRequestInProgress(false)
      }, (notOKResponseModel: NotOKResponseModel) => {
        displayResponse(notOKResponseModel)
        setDownloadRequestInProgress(false)
      })
    }, (notOKResponseModel: NotOKResponseModel) => {
      displayResponse(notOKResponseModel)
      setDownloadRequestInProgress(false)
    })
  }

  const downloadRequest = (outputFormat: OutputFormatValueType, retainGraph: boolean) => {
    if (capeRequests.length > 0) {

      const parameterSetList: CapeParameterSet[] = []
      capeRequests.forEach((value: CAPERatioRequest) => {
        parameterSetList.push(value.parameterSetList[0])
      })

      const graphDataDownlaodRequest: CAPERatioRequest = {
        requestType: CapeRequestTypes.Download,
        parameterSetList: parameterSetList,
        isExcessCAPEYield: capeRequests[0].isExcessCAPEYield,
        fetchBearMarketEvents: false,
        startDate: capeRequests[0].startDate,
        endDate: capeRequests[0].endDate,
        outputFormat: outputFormat,
        dateFormat: capeRequests[0].dateFormat
      }

      download(graphDataDownlaodRequest, retainGraph)
    }
  }

  const CAPEChartProps: CAPEChartProps = {
    checkIsTrial,
    graphData,
    downloadRequest
  }

  const CAPERatioInputProps: CAPERatioInputProps = {
    viewChart,
    download,
    requestInProgress: mainRequestInProgress || comparisonRequestInProgress || downloadRequestInProgress,
    displayMessage,
    resetMessage,
    setCapeRequests,
    accessibilityProps
  }

  const componentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages,
    setMessages,
    response: errorResponse,
    signOut
  }

  return (
    <div>
      <CAPERatioInputs {...CAPERatioInputProps} />
      <div>
        <ComponentMessageHandler {...componentMessageHandlerProps} />
      </div>
      <br />
      <CAPEChart {...CAPEChartProps} />
    </div>
  )
}

export default CAPERatio
