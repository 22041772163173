import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'
import { ImageNumberReference, ItalicizedWorkbookText, MarkedRedRedText } from '../../../HelpPageCommonComponents'

const SeriesFileUpload = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Series File Upload</h4>
                </div>
            </Box>
            <div style={{ height: '738px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_FileUpload.png' style={helpPageStyles.image} alt='Advanced AutoTrac File Upload'></img>
            </div>
            <p>
                Series can be added to a <ItalicizedWorkbookText /> using Quik Search or from other areas of the site. Multiple series can be added to a <ItalicizedWorkbookText /> by <b>file upload</b> from the AutoTrac page.
            </p>
            <p>Select the workbook you wish to add series to and click on "File Upload" button to get started.</p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Adding series to a workbook by symbol / ticker CSV file.</h5>
                </div>
            </Box>
            <div style={{ height: '394px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_FileUpload_Clicked.png' style={helpPageStyles.image} alt='Advanced AutoTrac File Upload Clicked'></img>
            </div>
            <p>
                Prepare a csv file with symbol / ticker on each line of the file.
            </p>
            <p>
                Sample file contents:
            </p>
            <div style={{ height: '230px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_FileUpload_SymbolSample.png' style={helpPageStyles.image} alt='Advanced AutoTrac File Upload Symbol Sample'></img>
            </div>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: 1 Symbol or ticker per line.
            </p>
            <div style={{ height: '560px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_FileUpload_Drag.png' style={helpPageStyles.image} alt='Advanced AutoTrac File Upload Drag'></img>
            </div>
            <p>
                Locate the file in file explorer and drag and drop the file to the area within <MarkedRedRedText /> box ({ImageNumberReference(1)}).
                Alternatively, you can click on the area withing the <MarkedRedRedText /> box ({ImageNumberReference(1)}) and selected csv file from file explorer.
            </p>
            <div style={{ height: '533px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_FileUpload_Preview.png' style={helpPageStyles.image} alt='Advanced AutoTrac File Upload Preview'></img>
            </div>
            <p>
                Verify the symbols that were parsed and click on "ADD TICKERS TO WORKBOOK" button to add the series to the <ItalicizedWorkbookText />.
            </p>
            <div style={{ height: '633px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_FileUpload_Success.png' style={helpPageStyles.image} alt='Advanced AutoTrac File Upload Success'></img>
            </div>
            <p>
                If all the Symbols/Tickers in the csv file are valid, they are added to the selected <ItalicizedWorkbookText />.
            </p>
            <div style={{ height: '693px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_FileUpload_Invalid.png' style={helpPageStyles.image} alt='Advanced AutoTrac File Upload Invalid'></img>
            </div>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: If there is at least 1 invalid symbol/ticker present in the upload csv file, series will not be added to the <ItalicizedWorkbookText />.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Adding series to a workbook by CUSIP CSV file.</h5>
                </div>
            </Box>
            <div style={{ height: '329px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_FileUpload_CUSIPSample.png' style={helpPageStyles.image} alt='Advanced AutoTrac File Upload CUSIP Sample'></img>
            </div>
            <p>
                Same upload process is applicable for CUSIP CSV file. Prepare the CUSIP CSV as you see in the above image and upload the file.
            </p>
            <p>Sample Preview:</p>
            <div style={{ height: '418px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_FileUpload_CUSIPSamplePreview.png' style={helpPageStyles.image} alt='Advanced AutoTrac File Upload CUSIP Sample Preview'></img>
            </div>
        </div>
    )
}

export default SeriesFileUpload
