

export const criteriaMapping = (criteria: any): string => {
    let criteriaValue = criteria
    if (typeof(criteriaValue) === 'number') {
        criteriaValue = criteriaValue.toString()
    }

    switch (criteriaValue) {
        case '1778':
            return 'Market Cap'
        case '1786':
            return 'PE Ratio'
        case '1812':
            return 'Beta'
        case '1800':
            return 'Momentum-M3'
        case '1804':
            return 'Momentum-M12'
        case '1808':
            return 'Momentum-M36'
        case '1782':
            return 'Dividend Yield'
        default:
            return ''

    }
}

  
export default criteriaMapping




