import { Divider, FormControlLabel, FormGroup, Grid, MenuItem, TextField, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { financialRatiosLabelValueOptions, valueIndicatorsLabelValueOptions } from "../../../../Models/DataModels/Common/ChartOptionsModel"
import { LabelValuePair } from "../../../../Models/DataModels/Common/LabelValuePairModel"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { CheckboxBlue } from "../../GlobalSettings/CustomStyles"

const TechnicalIndicators = (technicalIndicatorsSetters: any) => {

  const errorMessages = {
    requirePeriodGreaterThanZero: 'Period must be greater than 0',
    requirePeriodGreaterThanOne: 'Period must be greater than 1',
    requireStdDevGreaterThanZero: 'Std Dev must be greater than 0',
    requireShiftGreaterThanZero: 'Shift must be greater than 0',
    requirePeriodShortGreaterThanZero: 'Period Short must be greater than 0',
    requirePeriodLongGreaterThanPeriodShort: 'Period Long must be greater than Period Short',
    requirePeriodEMAGreaterThanZero: 'Period EMA must be greater than 0',
    requirePeriodKGreaterThanZero: 'Period K must be greater than 0',
    requirePeriodDGreaterThanZero: 'Period D must be greater than 0'
  }

  const isTrialUser: boolean = technicalIndicatorsSetters?.technicalIndicatorsSetters?.checkIsTrial() || false

  const validateChartIndicators = () => {
    setIsPeriodInputError(false)
    setIsStdDevInputError(false)
    setIsShiftInputError(false)
    setChartIndicatorErrorMessages('')
    const period: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicatorPeriod)
    let errorMessagesBuilder: string[] = []
    switch (technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator) {
      case 'none':
        break
      case 'averageTrueRange':
        if (period <= 1) {
          setIsPeriodInputError(true)
          setChartIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanOne)
        }
        break
      case 'bollingerBands':
        if (period <= 1) {
          setIsPeriodInputError(true)
          errorMessagesBuilder.push(errorMessages.requirePeriodGreaterThanOne)
        }
        const stdDev: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicatorStdDev)
        if (stdDev === 0) {
          setIsStdDevInputError(true)
          errorMessagesBuilder.push(errorMessages.requireStdDevGreaterThanZero)
        }
        if (errorMessagesBuilder.length > 0) {
          setChartIndicatorErrorMessages(errorMessagesBuilder.join(' and '))
        }
        break
      case 'envelopes':
        if (period <= 1) {
          setIsPeriodInputError(true)
          setChartIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanOne)
        }
        const shiftValue: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicatorShift)
        if (shiftValue === 0) {
          setIsShiftInputError(true)
          setChartIndicatorErrorMessages(errorMessages.requireShiftGreaterThanZero)
        }
        break
      case 'medianPrice': case 'typicalPrice': case 'weightedClose':
        break
      default:
        if (period === 0) {
          setIsPeriodInputError(true)
          setChartIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanZero)
        }
        break
    }
  }

  const validateTechIndicators = () => {
    setIsTechPeriodInputError(false)
    setIsTechShortPeriodInputError(false)
    setIsTechLongPeriodInputError(false)
    setIsTechPeriodAInputError(false)
    setIsTechPeriodEMAInputError(false)
    setIsTechPeriodAInputError(false)
    setIsTechPeriodKInputError(false)
    setIsTechPeriodDInputError(false)
    setTechIndicatorErrorMessages('')
    const period: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriod)
    const periodShort: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorShortPeriod)
    const periodLong: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorLongPeriod)
    const periodA: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodA)
    const periodEMA: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodEMA)
    const periodK: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodK)
    const periodD: number = parseFloat(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodD)
    let errorMessagesBuilder: string[] = []
    switch (technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator) {
      case 'none':
        break
      case 'accumulationDistribution':
        break
      case 'chaikinOscillator':
        if (periodShort === 0) {
          setIsTechShortPeriodInputError(true)
          errorMessagesBuilder.push(errorMessages.requirePeriodGreaterThanZero)
        }
        if (periodLong <= periodShort) {
          setIsTechLongPeriodInputError(true)
          errorMessagesBuilder.push(errorMessages.requirePeriodLongGreaterThanPeriodShort)
        }
        setTechIndicatorErrorMessages(errorMessagesBuilder.join(' and '))
        break
      case 'commodityChannelIndex':
        if (period === 0) {
          setIsTechPeriodInputError(true)
          setTechIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanZero)
        }
        break
      case 'averageDirectionalMovement':
        if (period <= 1) {
          setIsTechPeriodInputError(true)
          setTechIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanOne)
        }
        break
      case 'forceIndex':
        if (periodEMA === 0) {
          setIsTechPeriodEMAInputError(true)
          setTechIndicatorErrorMessages(errorMessages.requirePeriodEMAGreaterThanZero)
        }
        break
      case 'moneyFlow':
        if (period <= 1) {
          setIsTechPeriodInputError(true)
          setTechIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanOne)
        }
        break
      case 'MACD':
        if (periodShort === 0) {
          setIsTechShortPeriodInputError(true)
          errorMessagesBuilder.push(errorMessages.requirePeriodGreaterThanZero)
        }
        if (periodLong <= periodShort) {
          setIsTechLongPeriodInputError(true)
          errorMessagesBuilder.push(errorMessages.requirePeriodLongGreaterThanPeriodShort)
        }
        setTechIndicatorErrorMessages(errorMessagesBuilder.join(' and '))
        break
      case 'onBalanceVolume':
        break
      case 'rateOfChange':
        if (period === 0) {
          setIsTechPeriodInputError(true)
          setTechIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanZero)
        }
        break
      case 'relativeStrengthIndex':
        if (period === 0) {
          setIsTechPeriodInputError(true)
          setTechIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanZero)
        }
        break
      case 'standardDeviation':
        if (period <= 1) {
          setIsTechPeriodInputError(true)
          setTechIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanOne)
        }
        break
      case 'stochasticIndicator':
        if (periodK === 0) {
          setIsTechPeriodKInputError(true)
          errorMessagesBuilder.push(errorMessages.requirePeriodKGreaterThanZero)
        }
        if (periodD === 0) {
          setIsTechPeriodDInputError(true)
          errorMessagesBuilder.push(errorMessages.requirePeriodDGreaterThanZero)
        }
        setTechIndicatorErrorMessages(errorMessagesBuilder.join(' and '))
        break
      case 'williamsR':
        if (period === 0) {
          setIsTechPeriodInputError(true)
          setTechIndicatorErrorMessages(errorMessages.requirePeriodGreaterThanZero)
        }
        break
      default:
        break
    }
  }

  const setChartIndicatorToolTipMessages = () => {
    switch (technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator) {
      case 'averageTrueRange':
        setChartIndicatorInfoToolTipMessage('Measure of volatility that captures gaps and limits between periods')
        setChartIndicatorPeriodToolTipMessage('Number of periods to consider. Must be greater than 1.')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('')
        break
      case 'bollingerBands':
        setChartIndicatorInfoToolTipMessage('Depicts volatility as standard deviation boundary line range from a moving avg of price.')
        setChartIndicatorPeriodToolTipMessage('Number of periods for the center line moving average. Must be greater than 1.')
        setChartIndicatorStdDevTipMessage('Width of bands. Standard deviations from the moving average. Must be greater than 0.')
        setChartIndicatorShiftToolTipMessage('')
        break
      case 'detrendedPriceOscillator':
        setChartIndicatorInfoToolTipMessage('Depicts the difference between price and an offset simple moving average.')
        setChartIndicatorPeriodToolTipMessage('Number of periods in the moving average. Must be greater than 0.')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('')
        break
      case 'envelopes':
        setChartIndicatorInfoToolTipMessage('Price band channel overlay that is offset from the moving average of price')
        setChartIndicatorPeriodToolTipMessage('Number of periods in the moving average. Must be greater than 1.')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('Percent offset for envelope width. Must be greater than 0. Typical values range from 2 to 10.')
        break
      case 'movingAverage':
        setChartIndicatorInfoToolTipMessage('Average price over a lookback window.')
        setChartIndicatorPeriodToolTipMessage('Number of periods in the lookback window. Must be greater than 0.')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('')
        break
      case 'exponentialMovingAverage':
        setChartIndicatorInfoToolTipMessage('Rolling moving average that puts more weight on current price')
        setChartIndicatorPeriodToolTipMessage('Number of periods in the moving average. Must be greater than 0.')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('')
        break
      case 'triangularMovingAverage':
        setChartIndicatorInfoToolTipMessage('Faster multi-smoothed EMA of the price over a lookback window.')
        setChartIndicatorPeriodToolTipMessage('Number of periods in the moving average. Must be greater than 0.')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('')
        break
      case 'medianPrice':
        setChartIndicatorInfoToolTipMessage('Midpoint price over a specific for a period.')
        setChartIndicatorPeriodToolTipMessage('')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('')
        break
      case 'typicalPrice':
        setChartIndicatorInfoToolTipMessage('The average of the high, low and close.')
        setChartIndicatorPeriodToolTipMessage('')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('')
        break
      case 'weightedClose':
        setChartIndicatorInfoToolTipMessage('The average of the high, low and twice that of the close.')
        setChartIndicatorPeriodToolTipMessage('')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('')
        break
      default:
        setChartIndicatorInfoToolTipMessage('')
        setChartIndicatorPeriodToolTipMessage('')
        setChartIndicatorStdDevTipMessage('')
        setChartIndicatorShiftToolTipMessage('')
        break
    }
  }

  const setTechIndicatorInfoToolTipMessages = () => {
    switch (technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator) {
      case 'accumulationDistribution':
        setTechIndicatorInfoToolTipMessage('Rolling accumulation of Chaikin Money Flow Volume.')
        setTechnicalIndicatorPeriodToolTipMessage('')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'chaikinOscillator':
        setTechIndicatorInfoToolTipMessage('Difference between fast and slow Exponential Moving Averages (EMA) of the Accumulation/Distribution Line.')
        setTechnicalIndicatorPeriodToolTipMessage('')
        setTechnicalIndicatorShortPeriodToolTipMessage('Number of periods in the ADL fast EMA. Must be greater than 0 and smaller than Period Long.')
        setTechnicalIndicatorLongPeriodToolTipMessage('Number of periods in the ADL slow EMA. Must be greater Period Short.')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'commodityChannelIndex':
        setTechIndicatorInfoToolTipMessage('Oscillator depicting deviation from typical price range, often used to identify cyclical trends.')
        setTechnicalIndicatorPeriodToolTipMessage('Number of periods in the moving average. Must be greater than 0.')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'averageDirectionalMovement':
        setTechIndicatorInfoToolTipMessage('Measure of price directional movement. Used to measure strength of trend.')
        setTechnicalIndicatorPeriodToolTipMessage('Number of periods to consider. Must be greater than 1.')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'forceIndex':
        setTechIndicatorInfoToolTipMessage('Depicts volume-based buying and selling pressure based on the change in price.')
        setTechnicalIndicatorPeriodToolTipMessage('')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('Lookback window for the EMA of Force Index. Must be greater than 0')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'moneyFlow':
        setTechIndicatorInfoToolTipMessage('Price-volume oscillator that shows buying and selling momentum.')
        setTechnicalIndicatorPeriodToolTipMessage('Number of periods in the lookback period. Must be greater than 1.')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'MACD':
        setTechIndicatorInfoToolTipMessage('Simple oscillator view of two converging / diverging exponential moving averages and their differences.')
        setTechnicalIndicatorPeriodToolTipMessage('')
        setTechnicalIndicatorShortPeriodToolTipMessage('Number of periods for the faster moving average. Must be greater than 0.')
        setTechnicalIndicatorLongPeriodToolTipMessage('Number of periods for the slower moving average. Must be greater than Period Short.')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'onBalanceVolume':
        setTechIndicatorInfoToolTipMessage('Rolling accumulation of volume based on Close price direction.')
        setTechnicalIndicatorPeriodToolTipMessage('')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'rateOfChange':
        setTechIndicatorInfoToolTipMessage('Percent change of price over a lookback window.')
        setTechnicalIndicatorPeriodToolTipMessage('Number of periods in the moving average of OBV. Must be greater than 0.')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'relativeStrengthIndex':
        setTechIndicatorInfoToolTipMessage('Oscillator that measures strength of the winning/losing streak over specified lookback periods on a scale of 0 to 100, to depict overbought and oversold conditions.')
        setTechnicalIndicatorPeriodToolTipMessage('Number of periods in the lookback period. Must be greater than 0.')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'standardDeviation':
        setTechIndicatorInfoToolTipMessage('Standard Deviation of price over a rolling lookback window.')
        setTechnicalIndicatorPeriodToolTipMessage('Number of periods in the lookback period. Must be greater than 1.')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      case 'stochasticIndicator':
        setTechIndicatorInfoToolTipMessage('Momentum oscillator that compares current price with recent highs and lows.')
        setTechnicalIndicatorPeriodToolTipMessage('')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('Lookback period for the oscillator (%K). Must be greater than 0.')
        setTechnicalIndicatorPeriodDToolTipMessage('Smoothing period for the signal (%D). Must be greater than 0.')
        break
      case 'williamsR':
        setTechIndicatorInfoToolTipMessage('Momentum oscillator that compares current price with recent highs and lows.')
        setTechnicalIndicatorPeriodToolTipMessage('Number of periods in the lookback period. Must be greater than 0.')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
      default:
        setTechIndicatorInfoToolTipMessage('')
        setTechnicalIndicatorPeriodToolTipMessage('')
        setTechnicalIndicatorShortPeriodToolTipMessage('')
        setTechnicalIndicatorLongPeriodToolTipMessage('')
        setTechnicalIndicatorPeriodAToolTipMessage('')
        setTechnicalIndicatorPeriodEMATToolipMessage('')
        setTechnicalIndicatorPeriodKToolTipMessage('')
        setTechnicalIndicatorPeriodDToolTipMessage('')
        break
    }
  }

  const setValueIndicatorsInfoToolTipMessages = () => {
    switch (technicalIndicatorsSetters.technicalIndicatorsSetters.valueIndicator) {
      case 'earn_per_share_ttm':
        setvalueIndicatorsToolTipMessage('A ratio of net earnings over common shares outstanding.')
        break
      case 'dividend_per_share_ttm':
        setvalueIndicatorsToolTipMessage('A ratio of total declared dividends over common shares outstanding.')
        break
      case 'dividend_yield':
        setvalueIndicatorsToolTipMessage('A ratio of the total cash dividends paid over the market price of a share.')
        break
      case 'SMCAP':
        setvalueIndicatorsToolTipMessage('The market price of a stock multiplied by the number of shares outstanding.')
        break
      case 'payout_ratio':
        setvalueIndicatorsToolTipMessage('A ratio of total dividends over net income.')
        break
      case 'pe_ratio_ttm':
        setvalueIndicatorsToolTipMessage('A ratio of market price over earnings per share')
        break
      case 'total_shares_outstanding':
        setvalueIndicatorsToolTipMessage('The number of total shares issued minus shares repurchased.')
        break
      default:
        setvalueIndicatorsToolTipMessage('')
        break
    }
  }

  const setFinRatioInfoToolTipMessages = () => {
    switch (technicalIndicatorsSetters.technicalIndicatorsSetters.financialRatio) {
      case 'book_value_per_share_ttm':
        setFinRatioInfoToolTipMessage('A ratio of equity over common shares outstanding.')
        break
      case 'cash_flow_per_share_ttm':
        setFinRatioInfoToolTipMessage('A ratio of net cash flows over common shares outstanding.')
        break
      case 'earn_per_share_ttm':
        setFinRatioInfoToolTipMessage('A ratio of net earnings over common shares outstanding.')
        break
      case 'dividend_per_share_ttm':
        setFinRatioInfoToolTipMessage('A ratio of total declared dividends over common shares outstanding.')
        break
      case 'revenue_per_share_ttm':
        setFinRatioInfoToolTipMessage('A ratio of total revenue over common shares outstanding.')
        break
      case 'asset_turnover_ttm':
        setFinRatioInfoToolTipMessage('A ratio of total revenue over total assets.')
        break
      case 'current_ratio_mrq':
        setFinRatioInfoToolTipMessage('A ratio of total assets over total liabilities.')
        break
      case 'dividend_yield':
        setFinRatioInfoToolTipMessage('A ratio of the total cash dividends paid over the market price of a share.')
        break
      case 'ebit_profit_margin_ttm':
        setFinRatioInfoToolTipMessage('A ratio of operating sales over operating earnings.')
        break
      case 'effective_tax_rate_ttm':
        setFinRatioInfoToolTipMessage('The average tax rate that is paid.')
        break
      case 'gross_profit_margin_ttm':
        setFinRatioInfoToolTipMessage('A ratio of revenue less cost of goods sold over revenue.')
        break
      case 'interest_coverage_ttm':
        setFinRatioInfoToolTipMessage('A ratio of a company\'s earnings before interest and taxes (EBIT) over it\'s interest expense.')
        break
      case 'lt_debt_to_equity_mrq':
        setFinRatioInfoToolTipMessage('A ratio of a company\'s long-term debt held over shareholder\'s equity.')
        break
      case 'SMCAP':
        setFinRatioInfoToolTipMessage('The market price of a stock multiplied by the number of shares outstanding.')
        break
      case 'net_profit_margin_ttm':
        setFinRatioInfoToolTipMessage('A ratio of net income over net sales.')
        break
      case 'nwc_turnover_ttm':
        setFinRatioInfoToolTipMessage('A ratio of net sales over average working capital.')
        break
      case 'operating_profit_margin_ttm':
        setFinRatioInfoToolTipMessage('A ratio of operating income over revenues.')
        break
      case 'payout_ratio':
        setFinRatioInfoToolTipMessage('A ratio of total dividends over net income.')
        break
      case 'price_book_mrq':
        setFinRatioInfoToolTipMessage('A ratio of the market price of a share over the book value of a share.')
        break
      case 'price_cash_flow_ttm':
        setFinRatioInfoToolTipMessage('A ratio of market capitalization over operating cash flow.')
        break
      case 'pe_ratio_ttm':
        setFinRatioInfoToolTipMessage('A ratio of market price over earnings per share')
        break
      case 'price_sales_ttm':
        setFinRatioInfoToolTipMessage('A ratio of market capitalization over total sales. ')
        break
      case 'price_tangible_book_mrq':
        setFinRatioInfoToolTipMessage('A ratio of market price over tangible net assets.')
        break
      case 'quick_ratio_mrq':
        setFinRatioInfoToolTipMessage('A ratio of current assets less inventory over current liabilities.')
        break
      case 'return_on_equity_ttm':
        setFinRatioInfoToolTipMessage('A ratio of net income over shareholder\'s equity.')
        break
      case 'total_shares_outstanding':
        setFinRatioInfoToolTipMessage('The number of total shares issued minus shares repurchased.')
        break
      default:
        setFinRatioInfoToolTipMessage('')
        break
    }
  }

  const [chartIndicatorInfoToolTipMessage, setChartIndicatorInfoToolTipMessage] = useState<string>('')
  const [chartIndicatorPeriodToolTipMessage, setChartIndicatorPeriodToolTipMessage] = useState<string>('')
  const [chartIndicatorStdDevTipMessage, setChartIndicatorStdDevTipMessage] = useState<string>('')
  const [chartIndicatorShiftToolTipMessage, setChartIndicatorShiftToolTipMessage] = useState<string>('')

  const [techIndicatorInfoToolTipMessage, setTechIndicatorInfoToolTipMessage] = useState<string>('')
  const [technicalIndicatorPeriodToolTipMessage, setTechnicalIndicatorPeriodToolTipMessage] = useState<string>('')
  const [technicalIndicatorShortPeriodToolTipMessage, setTechnicalIndicatorShortPeriodToolTipMessage] = useState<string>('')
  const [technicalIndicatorLongPeriodToolTipMessage, setTechnicalIndicatorLongPeriodToolTipMessage] = useState<string>('')
  const [technicalIndicatorPeriodAToolTipMessage, setTechnicalIndicatorPeriodAToolTipMessage] = useState<string>('')
  const [technicalIndicatorPeriodEMAToolTipMessage, setTechnicalIndicatorPeriodEMATToolipMessage] = useState<string>('')
  const [technicalIndicatorPeriodKToolTipMessage, setTechnicalIndicatorPeriodKToolTipMessage] = useState<string>('')
  const [technicalIndicatorPeriodDToolTipMessage, setTechnicalIndicatorPeriodDToolTipMessage] = useState<string>('')

  const [valueIndicatorsToolTipMessage, setvalueIndicatorsToolTipMessage] = useState<string>('')
  const [finRatioInfoToolTipMessage, setFinRatioInfoToolTipMessage] = useState<string>('')

  const [chartIndicatorErrorMessages, setChartIndicatorErrorMessages] = useState<string>('')
  const [isPeriodInputError, setIsPeriodInputError] = useState<boolean>(false)
  const [isStdDevInputError, setIsStdDevInputError] = useState<boolean>(false)
  const [isShiftInputError, setIsShiftInputError] = useState<boolean>(false)
  const [techIndicatorErrorMessages, setTechIndicatorErrorMessages] = useState<string>('')
  const [isTechPeriodInputError, setIsTechPeriodInputError] = useState<boolean>(false)
  const [isTechShortPeriodInputError, setIsTechShortPeriodInputError] = useState<boolean>(false)
  const [isTechLongPeriodInputError, setIsTechLongPeriodInputError] = useState<boolean>(false)
  const [isTechPeriodAInputError, setIsTechPeriodAInputError] = useState<boolean>(false)
  const [isTechPeriodEMAInputError, setIsTechPeriodEMAInputError] = useState<boolean>(false)
  const [isTechPeriodKInputError, setIsTechPeriodKInputError] = useState<boolean>(false)
  const [isTechPeriodDInputError, setIsTechPeriodDInputError] = useState<boolean>(false)

  useEffect(() => {
    validateChartIndicators()
    setChartIndicatorToolTipMessages()
  }, [technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator,
  technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicatorPeriod,
  technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicatorStdDev,
  technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicatorShift
  ])

  useEffect(() => {
    validateTechIndicators()
    setTechIndicatorInfoToolTipMessages()
  }, [technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator,
  technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriod,
  technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorShortPeriod,
  technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorLongPeriod,
  technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodA,
  technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodEMA,
  technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodK,
  technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodD,
  ])

  useEffect(() => {
    setValueIndicatorsInfoToolTipMessages()
  }, [technicalIndicatorsSetters.technicalIndicatorsSetters.valueIndicator])

  useEffect(() => {
    setFinRatioInfoToolTipMessages()
  }, [technicalIndicatorsSetters.technicalIndicatorsSetters.financialRatio])

  useEffect(() => {
    if (isPeriodInputError || isStdDevInputError || isShiftInputError ||
      isTechPeriodInputError || isTechShortPeriodInputError || isTechLongPeriodInputError || isTechPeriodAInputError ||
      isTechPeriodEMAInputError || isTechPeriodKInputError || isTechPeriodDInputError) {
      technicalIndicatorsSetters.technicalIndicatorsSetters.setIsApplyButtonDisabled(true)
    } else {
      technicalIndicatorsSetters.technicalIndicatorsSetters.setIsApplyButtonDisabled(false)
    }
  }, [isPeriodInputError, isStdDevInputError, isShiftInputError,
    isTechPeriodInputError, isTechShortPeriodInputError, isTechLongPeriodInputError, isTechPeriodAInputError,
    isTechPeriodEMAInputError, isTechPeriodKInputError, isTechPeriodDInputError
  ])

  const financialRatioMenuItems = () => {
    return financialRatiosLabelValueOptions.map((value: LabelValuePair, index: number) => <MenuItem key={index} value={value.value}>{value.label}</MenuItem>)
  }

  const valueIndicatorsMenuItems = () => {
    return valueIndicatorsLabelValueOptions.map((value: LabelValuePair, index: number) => <MenuItem key={index} value={value.value}>{value.label}</MenuItem>)
  }

  return (
    <div>
      <Grid container spacing={1} columns={13} sx={{ paddingBottom: 2 }}>
        <Grid item md={5}>
          <TextField
            value={technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator}
            onChange={(e) => {
              technicalIndicatorsSetters.technicalIndicatorsSetters.setChartIndicator(e.target.value)
            }}
            select
            label={
              <>
                <span>Chart Indicators   </span>
                {technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator !== 'none' &&
                  <Tooltip title={chartIndicatorInfoToolTipMessage}>
                    <HelpOutlineIcon />
                  </Tooltip>
                }
              </>
            }
            sx={{ minWidth: 200 }}
            size={'small'}
            disabled={isTrialUser}
          >
            <MenuItem value={'none'}>None</MenuItem>
            <MenuItem value={'averageTrueRange'}>Average True Range</MenuItem>
            <MenuItem value={'bollingerBands'}>Bollinger Bands</MenuItem>
            <MenuItem value={'detrendedPriceOscillator'}>Detrended Price Oscillator</MenuItem>
            <MenuItem value={'envelopes'}>Envelopes</MenuItem>
            <MenuItem value={'movingAverage'}>Moving Average</MenuItem>
            <MenuItem value={'exponentialMovingAverage'}>Exponential Moving Average</MenuItem>
            <MenuItem value={'triangularMovingAverage'}>Triangular Moving Average</MenuItem>
            <MenuItem value={'medianPrice'}>Median Price</MenuItem>
            <MenuItem value={'typicalPrice'}>Typical Price</MenuItem>
            <MenuItem value={'weightedClose'}>Weighted Close</MenuItem>
          </TextField>
        </Grid>
        <Grid item md={2}>
          {(technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator !== 'none' && technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator !== 'medianPrice' && technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator !== 'typicalPrice' && technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator !== 'weightedClose') ? (
            <TextField
              value={technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicatorPeriod}
              onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setChartIndicatorPeriod(e.target.value)}
              label={
                <>
                  <span>Period   </span>
                  <Tooltip title={chartIndicatorPeriodToolTipMessage}>
                    <HelpOutlineIcon />
                  </Tooltip>
                </>
              }
              sx={{ minWidth: 120 }}
              size={'small'}
              type='number'
              inputProps={{ inputMode: 'numeric', min: 0 }}
              error={isPeriodInputError}
            ></TextField>
          ) : <></>}
        </Grid>
        <Grid item md={2}>
          {technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator === 'bollingerBands' ? (
            <TextField
              value={technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicatorStdDev}
              onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setChartIndicatorStdDev(e.target.value)}
              label={
                <>
                  <span>StdDev   </span>
                  <Tooltip title={chartIndicatorStdDevTipMessage}>
                    <HelpOutlineIcon />
                  </Tooltip>
                </>
              }
              sx={{ minWidth: 120 }}
              size={'small'}
              type='number'
              inputProps={{ inputMode: 'numeric', min: 0 }}
              error={isStdDevInputError}
            ></TextField>
          ) : <></>}
          {technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicator === 'envelopes' ? (
            <TextField
              value={technicalIndicatorsSetters.technicalIndicatorsSetters.chartIndicatorShift}
              onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setChartIndicatorShift(e.target.value)}
              label={
                <>
                  <span>Shift   </span>
                  <Tooltip title={chartIndicatorShiftToolTipMessage}>
                    <HelpOutlineIcon />
                  </Tooltip>
                </>
              }
              sx={{ minWidth: 120 }}
              size={'small'}
              type='number'
              inputProps={{ inputMode: 'numeric', min: 0 }}
              error={isShiftInputError}
            ></TextField>
          ) : <></>}
        </Grid>
        <Grid item md={3}>
          <span style={{ color: 'red', fontStyle: 'italic', fontSize: 13 }}>{chartIndicatorErrorMessages}</span>
        </Grid>
      </Grid>

      <Grid container spacing={1} columns={13} sx={{ paddingBottom: 2 }}>
        <Grid item md={technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'none' ? 2.5 : 5}>
          <TextField
            value={technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator}
            onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setTechnicalIndicator(e.target.value)}
            select
            label={
              <>
                <span>Technical Indicators (lower)   </span>
                {technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator !== 'none' &&
                  <Tooltip title={techIndicatorInfoToolTipMessage}>
                    <HelpOutlineIcon />
                  </Tooltip>
                }
              </>
            }
            sx={{ minWidth: 200 }}
            size={'small'}
            disabled={isTrialUser}
          >
            <MenuItem value={'none'}>None</MenuItem>
            <MenuItem value={'accumulationDistribution'}>Accumulation Distribution</MenuItem>
            <MenuItem value={'chaikinOscillator'}>Chaikin Oscillator</MenuItem>
            <MenuItem value={'commodityChannelIndex'}>Commodity Channel Index</MenuItem>
            <MenuItem value={'averageDirectionalMovement'}>Average Directional Movement</MenuItem>
            <MenuItem value={'forceIndex'}>Force Index</MenuItem>
            <MenuItem value={'moneyFlow'}>Money Flow</MenuItem>
            <MenuItem value={'MACD'}>MACD</MenuItem>
            <MenuItem value={'onBalanceVolume'}>On Balance Volume</MenuItem>
            <MenuItem value={'rateOfChange'}>Rate Of Change</MenuItem>
            <MenuItem value={'relativeStrengthIndex'}>Relative Strength Index</MenuItem>
            <MenuItem value={'standardDeviation'}>Standard Deviation</MenuItem>
            <MenuItem value={'stochasticIndicator'}>Stochastic Indicator</MenuItem>
            <MenuItem value={'williamsR'}>WilliamsR</MenuItem>
          </TextField>
        </Grid>
        {technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'none' ?
          <Grid item md={6}>
            <Typography sx={{ fontStyle: 'italic', fontSize: 12 }}>This option will be graphed on a separate graph.</Typography>
          </Grid>
          :
          <>
            <Grid item md={2}>
              {/* */}
              {(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'commodityChannelIndex' || technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'averageDirectionalMovement' || technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'moneyFlow' || technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'rateOfChange' || technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'relativeStrengthIndex' || technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'standardDeviation' || technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'williamsR') ?
                <TextField
                  value={technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriod}
                  onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setTechnicalIndicatorPeriod(e.target.value)}
                  label={
                    <>
                      <span>Period   </span>
                      <Tooltip title={technicalIndicatorPeriodToolTipMessage}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    </>
                  }
                  sx={{ minWidth: 120 }}
                  size={'small'}
                  type='number'
                  inputProps={{ inputMode: 'numeric', min: 0 }}
                  error={isTechPeriodInputError}
                >
                </TextField>
                : <></>
              }
              {(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'chaikinOscillator' || technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'MACD') ?
                <TextField
                  value={technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorShortPeriod}
                  onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setTechnicalIndicatorShortPeriod(e.target.value)}
                  label={
                    <>
                      <span>ShortPeriod   </span>
                      <Tooltip title={technicalIndicatorShortPeriodToolTipMessage}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    </>
                  }
                  sx={{ minWidth: 120 }}
                  size={'small'}
                  type='number'
                  inputProps={{ inputMode: 'numeric', min: 0 }}
                  error={isTechShortPeriodInputError}>
                </TextField> : <></>
              }
              {(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'forceIndex') ?
                <TextField
                  value={technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodEMA}
                  onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setTechnicalIndicatorPeriodEMA(e.target.value)}
                  label={
                    <>
                      <span>PeriodEMA   </span>
                      <Tooltip title={technicalIndicatorPeriodEMAToolTipMessage}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    </>
                  }
                  sx={{ minWidth: 120 }}
                  size={'small'}
                  type='number'
                  inputProps={{ inputMode: 'numeric', min: 0 }}
                  error={isTechPeriodEMAInputError}>
                </TextField> : <></>
              }
              {(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'stochasticIndicator') ?
                <TextField
                  value={technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodK}
                  onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setTechnicalIndicatorPeriodK(e.target.value)}
                  label={
                    <>
                      <span>PeriodK   </span>
                      <Tooltip title={technicalIndicatorPeriodKToolTipMessage}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    </>
                  }
                  sx={{ minWidth: 120 }}
                  size={'small'}
                  type='number'
                  inputProps={{ inputMode: 'numeric', min: 0 }}
                  error={isTechPeriodKInputError}>
                </TextField> : <></>
              }
            </Grid>
            <Grid item md={2}>
              {(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'chaikinOscillator' || technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'MACD') ?
                <TextField
                  value={technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorLongPeriod}
                  onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setTechnicalIndicatorLongPeriod(e.target.value)}
                  label={
                    <>
                      <span>LongPeriod   </span>
                      <Tooltip title={technicalIndicatorLongPeriodToolTipMessage}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    </>
                  }

                  sx={{ minWidth: 120 }}
                  size={'small'}
                  type='number'
                  inputProps={{ inputMode: 'numeric', min: 0 }}
                  error={isTechLongPeriodInputError}>
                </TextField> : <></>
              }
              {(technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicator === 'stochasticIndicator') ?
                <TextField
                  value={technicalIndicatorsSetters.technicalIndicatorsSetters.technicalIndicatorPeriodD}
                  onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setTechnicalIndicatorPeriodD(e.target.value)}
                  label={
                    <>
                      <span>PeriodD   </span>
                      <Tooltip title={technicalIndicatorPeriodDToolTipMessage}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    </>
                  }
                  sx={{ minWidth: 120 }}
                  size={'small'}
                  type='number'
                  inputProps={{ inputMode: 'numeric', min: 0 }}
                  error={isTechPeriodDInputError}>
                </TextField> : <></>
              }
            </Grid>
            <Grid item md={3}>
              <span style={{ color: 'red', fontStyle: 'italic', fontSize: 13 }}>{techIndicatorErrorMessages}</span>
            </Grid>
          </>
        }
      </Grid>

      <Divider sx={{ borderColor: 'black', paddingTop: 1 }} />

      <Grid container spacing={1} columns={12} sx={{ paddingBottom: 2, paddingTop: 3 }}>
        <Grid item md={3.5}>
          <TextField
            value={technicalIndicatorsSetters.technicalIndicatorsSetters.valueIndicator}
            onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setValueIndicator(e.target.value)}
            select
            label={
              <>
                <span>Value Indicators   </span>
                {technicalIndicatorsSetters.technicalIndicatorsSetters.valueIndicator !== 'none' &&
                  <Tooltip title={valueIndicatorsToolTipMessage}>
                    <HelpOutlineIcon />
                  </Tooltip>
                }
              </>
            }
            sx={{ minWidth: 300 }}
            size={'small'}
            disabled={isTrialUser}
          >
            {valueIndicatorsMenuItems()}
          </TextField>
        </Grid>
        <Grid item md={6}>
          <FormGroup>
            <FormControlLabel control={
              <CheckboxBlue
                id='primaryGraphOptionCheckBox'
                checked={technicalIndicatorsSetters.technicalIndicatorsSetters.isValueIndicatorOnPrimaryGraph}
                onChange={(e: any) => {
                  technicalIndicatorsSetters.technicalIndicatorsSetters.setIsValueIndicatorOnPrimaryGraph(e.target.checked)
                }}
                disabled={isTrialUser}
              />}
              label='Graph on primary graph'
              sx={{ fontStyle: 'italic', fontSize: 12 }} />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container spacing={1} columns={12} sx={{ paddingBottom: 2 }}>
        <Grid item md={3.5}>
          <TextField
            value={technicalIndicatorsSetters.technicalIndicatorsSetters.financialRatio}
            onChange={(e) => technicalIndicatorsSetters.technicalIndicatorsSetters.setFinancialRatio(e.target.value)}
            select
            label={
              <>
                <span>Financial Ratios (single securities only)   </span>
                {technicalIndicatorsSetters.technicalIndicatorsSetters.financialRatio !== 'none' &&
                  <Tooltip title={finRatioInfoToolTipMessage}>
                    <HelpOutlineIcon />
                  </Tooltip>
                }
              </>
            }
            sx={{ minWidth: 300 }}
            size={'small'}
            disabled={isTrialUser}
          >
            {financialRatioMenuItems()}
          </TextField>
        </Grid>
        <Grid item md={6}>
          <Typography sx={{ fontStyle: 'italic', fontSize: 12 }}>This option will be graphed on a separate graph.</Typography>
        </Grid>
      </Grid>

    </div>
  )
}

export default TechnicalIndicators