import { DeleteScreeningReportRequest, ExportScreenReport, ExportScreenResults, GetReportResultsByScreenIDRequest, GetScreenFiltersByIDRequest, LoadScreeningReportRequest, SaveScreenStatisticsRequest, ScreenReportListRequest, ScreenStatisticsRequest } from "../Models/DataModels/Requests/ScreeningRequests"
import getAPICallResult, { APIRequest, HttpRequest } from "./APIService"

export const getScreenStatistics = async (request: ScreenStatisticsRequest) => {
    const apiRequest : APIRequest = {
        relativePath: '/Screening/GetScreenStatistics',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const getScreenResults = async (request: ScreenStatisticsRequest) => {
    const apiRequest : APIRequest = {
        relativePath: '/Screening/GetScreenResults',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const saveScreenResults = async (request: SaveScreenStatisticsRequest) => {
  const apiRequest : APIRequest = {
      relativePath: '/Screening/SaveScreeningReport',
      method: HttpRequest.Post,
      requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const getScreenReportsByUser = async (request: ScreenReportListRequest) => {
  const apiRequest : APIRequest = {
      relativePath: '/Screening/GetScreeningReportsByUser',
      method: HttpRequest.Post,
      requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const getReportResultsByScreenID = async (request: GetReportResultsByScreenIDRequest) => {
  const apiRequest : APIRequest = {
      relativePath: '/Screening/GetScreenResultsByID',
      method: HttpRequest.Post,
      requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const loadScreeningReport = async (request: LoadScreeningReportRequest) => {
  const apiRequest : APIRequest = {
      relativePath: '/Screening/LoadScreeningReport',
      method: HttpRequest.Post,
      requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const deleteScreeningReportByScreenID = async (request: DeleteScreeningReportRequest) => {
  const apiRequest : APIRequest = {
      relativePath: '/Screening/DeleteScreeningReport',
      method: HttpRequest.Delete,
      requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const getScreenFiltersByID = async (request: GetScreenFiltersByIDRequest) => {
  const apiRequest : APIRequest = {
      relativePath: '/Screening/GetScreenFiltersByID',
      method: HttpRequest.Post,
      requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const exportScreenResults = async (request: ExportScreenResults) => {
  const apiRequest : APIRequest = {
      relativePath: '/export/ScreenResultsExportData',
      method: HttpRequest.Post,
      requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const exportScreenReport = async (request: ExportScreenReport) => {
  const apiRequest : APIRequest = {
      relativePath: '/export/ScreenReportExportData',
      method: HttpRequest.Post,
      requestObject: request
  }
  return getAPICallResult(apiRequest)
}
