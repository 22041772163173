import { Divider, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, FormGroup, FormControl, ToggleButtonGroup } from '@mui/material'
import React, { ReactNode } from 'react'
import DataFrequencyValue, { DataFrequencyValueType } from '../../../../Models/DataModels/Common/DataFrequencyModel'
import DateFormatModel, { DateFormatValueType } from '../../../../Models/DataModels/Common/DateFormatModel'
import OutputFormatValue, { OutputFormatValueType } from '../../../../Models/DataModels/Common/OutputFormatModel'
import { currencyConverterAdjustmentOptions } from '../../../../Models/DataModels/Common/AdjustmentModel'
import { CheckboxBlue, CustomToggle } from '../../GlobalSettings/CustomStyles'

export interface CurrencyConverterDownloadOptionsProps {
    splitAdjusted: boolean,
    setSplitAdjusted: (splitAdjusted: boolean) => void,
    average: boolean,
    setAverage: (average: boolean) => void,
    annualPercentChange: boolean,
    setAnnualPercentChange: (periodPercentChange: boolean) => void,
    periodPercentChange: boolean,
    setPeriodPercentChange: (periodPercentChange: boolean) => void,
    dataFrequency: DataFrequencyValueType,
    setDataFrequency: (dataFrequency: DataFrequencyValueType) => void,
    dateFormat: DateFormatValueType,
    setDateFormat: (dateFormat: DateFormatValueType) => void,
    outputFormat: OutputFormatValueType,
    setOutputFormat: (dateFormat: OutputFormatValueType) => void
}

const CurrencyConverterDownloadOptions = ({
    splitAdjusted,
    setSplitAdjusted,
    average,
    setAverage,
    annualPercentChange,
    setAnnualPercentChange,
    periodPercentChange,
    setPeriodPercentChange,
    dataFrequency,
    setDataFrequency,
    dateFormat,
    setDateFormat,
    outputFormat,
    setOutputFormat
}: CurrencyConverterDownloadOptionsProps) => {

    const ccAdjustmentOptions = {
        [currencyConverterAdjustmentOptions.SplitAdjusted]: {
            label: currencyConverterAdjustmentOptions.SplitAdjusted,
            value: splitAdjusted,
            onChange: setSplitAdjusted
        },
        [currencyConverterAdjustmentOptions.Average]: {
            label: currencyConverterAdjustmentOptions.Average,
            value: average,
            onChange: setAverage
        },
        [currencyConverterAdjustmentOptions.PeriodPercentChange]: {
            label: currencyConverterAdjustmentOptions.PeriodPercentChange,
            value: periodPercentChange,
            onChange: setPeriodPercentChange
        },
        [currencyConverterAdjustmentOptions.AnnualPercentChange]: {
            label: currencyConverterAdjustmentOptions.AnnualPercentChange,
            value: annualPercentChange,
            onChange: setAnnualPercentChange
        }
    }
    
    const renderDivider = () => {
        return (<Divider sx={{ borderColor: 'black', paddingTop: 1, marginBottom: '8px' }} />)
    }

    const renderRowTitle = (title: string) => {
        return (<Grid item md={5} flexDirection='column'>
            <div style={{ fontWeight: 'bold', marginTop: '8px' }}>{title}</div>
        </Grid>)
    }

    const renderAdjustmentCheckBoxes = () => {
        return Object.values(ccAdjustmentOptions).map(value => <FormControlLabel
            key={`${value.label}Label`}
            style={{ ...Styles.FormControlLabel }}
            control={<CheckboxBlue id={`${value.label}CheckBox`}
                checked={value.value}
                onChange={(event, checked) => value.onChange(checked)} />}
            label={value.label}
            labelPlacement='end'
        />)
    }

    const renderDataFrequencyMenuItems = () => {
        return Object.values(DataFrequencyValue).map(
            value => <MenuItem key={value} value={value}>{value}</MenuItem>
        )
    }

    const renderDateFormat = () => {
        return Object.keys(DateFormatModel).map(
            format => <MenuItem key={format} value={DateFormatModel[format]}>{format}</MenuItem>
        )
    }

    const renderOutputFormatToggleButtons = () => {
        return Object.values(OutputFormatValue).map(
            format => <CustomToggle key={format} size='small' value={format}>{format}</CustomToggle>
        )
    }

    const handleChangeDateFormat = (
        event: SelectChangeEvent<DateFormatValueType>,
        child: ReactNode,
    ) => {
        setDateFormat(event.target.value as DateFormatValueType)
    }

    const handleChangeDataFrequency = (
        event: SelectChangeEvent<DataFrequencyValueType>,
        child: ReactNode,
    ) => {
        setDataFrequency(event.target.value as DataFrequencyValueType)
    }

    const handleChangeOutputFormat = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string,
    ) => {
        if (newValue === null) return

        setOutputFormat(newValue as OutputFormatValueType)
    }
    
    return (
        <>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Adjustments')}
                <Grid item md={18}>
                    <FormGroup aria-label='position' row>
                        {renderAdjustmentCheckBoxes()}
                    </FormGroup>
                </Grid>
            </Grid>
            {renderDivider()}
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Data Frequency')}
                <Grid item md={18}>
                    <FormControl variant='outlined' sx={{ minWidth: 200, marginTop: '8px', marginBottom: '8px' }}>
                        <InputLabel id='data-frequency-label'>Data Frequency</InputLabel>
                        <Select
                            labelId='data-frequency-label'
                            id='data-frequency-select'
                            value={dataFrequency}
                            label='Data Frequency'
                            onChange={handleChangeDataFrequency}
                            size='small'
                        >
                            {renderDataFrequencyMenuItems()}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {renderDivider()}
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Date Format')}
                <Grid item md={18}>
                    <FormControl variant='outlined' sx={{ minWidth: 200, marginTop: '8px', marginBottom: '8px' }}>
                        <InputLabel id='date-format-select-label'>Date Format</InputLabel>
                        <Select
                            labelId='date-format-select-label'
                            id='date-format-select'
                            value={dateFormat}
                            label='Date Format'
                            onChange={handleChangeDateFormat}
                            size='small'
                        >
                            {renderDateFormat()}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {renderDivider()}
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Output Format')}
                <Grid item md={10}>
                    <ToggleButtonGroup
                        id='ccFileFormatToggleButton'
                        color='primary'
                        style={{ marginTop: '4px', marginLeft: '10px', marginRight: '8px', flexWrap: 'wrap' }}
                        value={outputFormat}
                        exclusive
                        onChange={handleChangeOutputFormat}
                        aria-label='selected output format'
                    >
                        {renderOutputFormatToggleButtons()}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </>
    )
}

const Styles = {
    FormControlLabel: {
        marginRight: '28px'
    },
    CheckBox: {
        paddingRight: '4px'
    },
    FormLabel: {
        color: 'rgb(33,37,41)'
    }
}

export default CurrencyConverterDownloadOptions
