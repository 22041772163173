import moment from "moment"
import { GraphRequest } from "../Requests/GraphRequests"
import { CurrencyConversionChartRequest, GetCapitalizationSummaryChartRequest } from "../Requests/ToolsRequests"
import { MessageResponse } from "../Responses/NotOKResponse"

export interface TDataPrice {
    date: Date,
    prices: any
}

export interface CurrencyConversionData {
    symbol: string,
    description: string,
    data: TDataPrice[]
}

export interface CapitalizationData {
    seriesID: number,
    symbol: string,
    name: string,
    operatingCountry: string,
    listingCountry: string,
    sector: string,
    industry: string,
    value: number,
    weight: number,
    pctChange: number,
    date: Date,
    currency: string,
}

export enum ScreeningSummaryCategory {
    None,
    Sector,
    Industry,
    Country
}

export interface CapitalizationSummary {
    sector: string,
    pct: number,
    marketCap: number,
    numberOfStks: number,
    date: Date,
    top10: number
}

export interface ChartCoordinates {
    left: number,
    right: number,
    top: number,
    bottom: number
}

export type ChartType = 'Line' | 'Bar' | 'Mountain' | 'Candlestick' | 'OHLC' | 'Pie' | 'StackedArea' | 'HLC'

export const ChartTypes = {
    Line: 'Line' as ChartType,
    Bar: 'Bar' as ChartType,
    Mountain: 'Mountain' as ChartType,
    Candlestick: 'Candlestick' as ChartType,
    OHLC: 'OHLC' as ChartType,
    Pie: 'Pie' as ChartType,
    StackedArea: 'StackedArea' as ChartType,
    HLC: 'HLC' as ChartType
}

export type ChartScaleType = 'Linear' | 'Log' | 'Percentage'

export const ChartScaleTypes = {
    Linear: 'Linear' as ChartScaleType,
    Log: 'Log' as ChartScaleType,
    Percentage: 'Percentage' as ChartScaleType
}

export type ChartComparisonType = 'None' | 'Compare' | 'DivideBy' | 'SubractFrom'

export const ChartComparisonTypes = {
    None: 'None' as ChartComparisonType,
    Compare: 'Compare' as ChartComparisonType,
    DivideBy: 'DivideBy' as ChartComparisonType,
    SubractFrom: 'SubractFrom' as ChartComparisonType
}

export type ChartComparisonScale = 'Indirect' | 'Direct' | 'DualScale' | 'Percentage'

export const ChartComparisonScales = {
    Indirect: 'Indirect' as ChartComparisonScale,
    Direct: 'Direct' as ChartComparisonScale,
    DualScale: 'DualScale' as ChartComparisonScale,
    Percentage: 'Percentage' as ChartComparisonScale
}

export type SeriesAdjustmentType = 'Subtract' | 'Divide' | 'Multiply' | 'Add'

export const SeriesAdjustmentTypes = {
    Subtract: 'Subtract' as SeriesAdjustmentType,
    Divide: 'Divide' as SeriesAdjustmentType,
    Multiply: 'Multiply' as SeriesAdjustmentType,
    Add: 'Add' as SeriesAdjustmentType
}

export type DateRangeSelection = 'Unselected' | 'Month1' | 'Month3' | 'Month6' | 'YearToDate' | 'Year1' | 'Year2' | 'Year5' | 'Year10' | 'Year20' | 'Max'

export const DateRangeSelections = {
    Unselected: 'Unselected' as DateRangeSelection,
    Month1: 'Month1' as DateRangeSelection,
    Month3: 'Month3' as DateRangeSelection,
    Month6: 'Month6' as DateRangeSelection,
    YearToDate: 'YearToDate' as DateRangeSelection,
    Year1: 'Year1' as DateRangeSelection,
    Year2: 'Year2' as DateRangeSelection,
    Year5: 'Year5' as DateRangeSelection,
    Year10: 'Year10' as DateRangeSelection,
    Year20: 'Year20' as DateRangeSelection,
    Max: 'Max' as DateRangeSelection
}

export type Periodicity = 'naturalfrequency' | 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'annual'

export const PeriodicityTypes = {
    NaturalFrequency: 'naturalfrequency' as Periodicity,
    Daily: 'daily' as Periodicity,
    Weekly: 'weekly' as Periodicity,
    Monthly: 'monthly' as Periodicity,
    Quarterly: 'quarterly' as Periodicity,
    Annually: 'annual' as Periodicity
}

export interface ChartMetaData {
    chartName: string,
    minValue: number,
    maxValue: number,
    minPriceValue: number,
    maxPriceValue: number,
    isPrimaryScale: boolean
}

export interface ChartView {
    coordinates: ChartCoordinates,
    type: ChartType,
    scaleType: ChartScaleType,
    title: string,
    fillColor: string,
    dataSources: any[][],
    metaDataArray: ChartMetaData[],
    graphMinValue: number,
    graphMaxValue: number,
    graphPriceValueMin: number,
    graphPriceValueMax: number,
    graphPrimaryScaleMin: number,
    graphPrimaryScaleMax: number,
    graphSecondaryScaleMin: number,
    graphSecondaryScaleMax: number
}

export interface AdditionalGraphDataKeyValuePair {
    key: string,
    value: number
}

export interface ChartDefinition {
    charts: ChartView[],
    additionalGraphsData?: AdditionalGraphDataKeyValuePair[]
    chartMessages?: MessageResponse[],
    chartRequest?: GraphRequest,
    currencyChartRequest?: CurrencyConversionChartRequest,
    capitalizationSummaryChartRequest?: GetCapitalizationSummaryChartRequest
}

export interface ChartSearchResultDataRequest {
    seriesIDs: number[]
    selectedDate?: moment.Moment | null
    currency?: string
    currencyName?: string
}

export interface ChartSearchResultDataResponse {
    title: string
    searchResultData: SearchResultData[]
}

export interface SearchResultData {
    seriesID: number
    ticker: string
    seriesName: string
    bill: string
    scaleNum: string
    units: string
    closePrice: number
    closeDate: string
}

export interface ToolsAccesibilityProps {
    canUseTool: boolean | null | undefined,
    canUseCapeTool: boolean | null | undefined,
    loadRequestInProgress: boolean
  }
  
  export interface ToolsStockProps {
      hasUSStocksAccess: boolean,
      hasUKStocksAccess: boolean
  }