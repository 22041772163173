import { ReactNode, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Divider, FormControlLabel, FormLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, ToggleButtonGroup, FormHelperText } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import MovingAverageModel, {
    MovingAverageValue,
    MovingAverageValueType
} from '../../../Models/DataModels/Common/MovingAverageModel'
import OutputFormatValue, { OutputFormatValueType } from '../../../Models/DataModels/Common/OutputFormatModel'
import { CurrencyOption, CurrencyDefaultValue, CurrencyDefaultLabel } from '../../../Models/DataModels/Common/CurrencyModel'
import DataFrequencyValue, { DataFrequencyValueType } from '../../../Models/DataModels/Common/DataFrequencyModel'
import DateMode, { DateModeValueType } from '../../../Models/DataModels/Common/DateModeModel'
import { defaultEndDate, defaultStartDate, DownloadSettingsDateFormat, DownloadSettingsModel, maxYear, minYear } from '../../../Models/DataModels/Common/DownloadSettingsModel'
import DataField from '../../../Models/DataModels/Common/DataFieldModel'
import { Adjustment } from '../../../Models/DataModels/Common/AdjustmentModel'
import DateFormatModel, { DateFormatValue, DateFormatValueType } from '../../../Models/DataModels/Common/DateFormatModel'
import DataFillMethodModel, { DataFillMethodValue, DataFillMethodValueType } from '../../../Models/DataModels/Common/DataFillMethodModel'
import EquityField from '../../../Models/DataModels/Common/EquityFieldModel'
import CorporateActionField from '../../../Models/DataModels/Common/CorporateActionFieldModel'
import FundamentalsAndRatiosField from '../../../Models/DataModels/Common/FundamentalsAndRatiosFieldModel'
import WorksheetFormatModel, { WorksheetFormatDisplay, WorksheetFormatValue, WorksheetFormatValueType } from '../../../Models/DataModels/Common/WorksheetFormatModel'
import { populateCurrencies } from '../../../Services/FieldPopulationService'
import { CSSProperties } from '@mui/material/styles/createTypography'
import { Typography } from '@mui/material'
import { DownloadRequestSource, DownloadRequestSourceValueType } from '../../../Models/DataModels/Requests/AutoTracRequests'
import { CheckboxBlue, CustomToggle } from '../GlobalSettings/CustomStyles'

export interface DownloadOptionsProps {
    downloadSettings: DownloadSettingsModel,
    setDisableSaveDownloadSettings?: (value: boolean) => void,
    saveDownloadSettings: (downloadSettings: DownloadSettingsModel) => void,
    triggerSaveDownloadSettings: boolean,
    resetTriggerDownloadSettings: () => void,
    readOnly?: boolean,
    seriesMinDate?: moment.Moment | null,
    seriesMaxDate?: moment.Moment | null,
    downloadRequestSource?: DownloadRequestSourceValueType
}

const DownloadOptions = ({
    downloadSettings,
    setDisableSaveDownloadSettings,
    saveDownloadSettings,
    triggerSaveDownloadSettings,
    resetTriggerDownloadSettings,
    readOnly,
    seriesMinDate,
    seriesMaxDate,
    downloadRequestSource
}: DownloadOptionsProps) => {
    const [series, setSeries] = useState<number[]>(downloadSettings?.Series || [])
    const [minimumYear, setMinimumYear] = useState<number>(seriesMinDate?.year() || downloadSettings?.FromYear || minYear)
    const [maximumYear, setMaximumYear] = useState<number>(seriesMaxDate?.year() || downloadSettings?.ToYear || maxYear)
    const [minimumDate, setMinimumDate] = useState<moment.Moment>(seriesMinDate || downloadSettings?.FromDate || defaultStartDate)
    const [maximumDate, setMaximumDate] = useState<moment.Moment>(seriesMaxDate || downloadSettings?.ToDate || defaultEndDate)
    const [fromDateError, setFromDateError] = useState<string | null>(null)
    const [toDateError, setToDateError] = useState<string | null>(null)
    const [fromYearError, setFromYearError] = useState<string | null>(null)
    const [toYearError, setToYearError] = useState<string | null>(null)
    const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>([])
    const [securitiesIncluded, setSecuritiesIncluded] = useState<boolean>(Boolean(downloadSettings?.SecuritiesIncluded))
    const [corporateActionsIncluded, setCorporateActionsIncluded] = useState<boolean>(Boolean(downloadSettings?.CorporateActionsIncluded))
    const [hasInflationAdjusted, setHasInflationAdjusted] = useState<boolean>(Boolean(downloadSettings?.hasInflationAdjusted))
    const [hasPerCapita, setHasPerCapita] = useState<boolean>(Boolean(downloadSettings?.hasPerCapita))
    const [hasPercentGDP, setHasPercentGDP] = useState<boolean>(Boolean(downloadSettings?.hasPercentGDP))
    const [hasSplitAdjusted, setHasSplitAdjusted] = useState<boolean>(Boolean(downloadSettings?.hasSplitAdjusted))
    const [selectedDateMode, setSelectedDateMode] = useState<DateModeValueType>(downloadSettings?.DateMode || DateMode.ByDate)
    const [fromYear, setFromYear] = useState<number>(downloadSettings?.FromYear || minYear)
    const [toYear, setToYear] = useState<number>(downloadSettings?.ToYear || maxYear)
    const [fromDate, setFromDate] = useState<moment.Moment>(downloadSettings?.FromDate || seriesMinDate || defaultStartDate)
    const [toDate, setToDate] = useState<moment.Moment>(downloadSettings?.ToDate || seriesMaxDate || defaultEndDate)
    const [open, setOpen] = useState(downloadSettings?.DataFields ? downloadSettings.DataFields.Open : true)
    const [high, setHigh] = useState(downloadSettings?.DataFields ? downloadSettings.DataFields.High : true)
    const [low, setLow] = useState(downloadSettings?.DataFields ? downloadSettings.DataFields.Low : true)
    const [close, setClose] = useState(true) // always true
    const [volume, setVolume] = useState(downloadSettings?.DataFields ? downloadSettings.DataFields.Volume : false)
    const [openInterest, setOpenInterest] = useState(downloadSettings?.DataFields ? downloadSettings.DataFields.OpenInterest : false)
    const [closeOnly, setCloseOnly] = useState(downloadSettings?.DataFields ? downloadSettings.DataFields.CloseOnly : false)
    const [movingAverage, setMovingAverage] = useState(downloadSettings?.MovingAverage ? downloadSettings.MovingAverage : MovingAverageValue.None)
    const [outputFormat, setOutputFormat] = useState(downloadSettings?.OutputFormat ? downloadSettings.OutputFormat : OutputFormatValue.Excel)
    const [currency, setCurrency] = useState(downloadSettings?.Currency ? downloadSettings.Currency : CurrencyDefaultValue)
    const [dataFrequency, setDataFrequency] = useState(downloadSettings?.DataFrequency ? downloadSettings.DataFrequency : DataFrequencyValue.Daily)
    const [dateFormat, setDateFormat] = useState(Object.values(DateFormatValue).includes(downloadSettings?.DateFormat) ? downloadSettings.DateFormat : DateFormatValue.American)
    const [splitAdjusted, setSplitAdjusted] = useState(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.SplitAdjusted : false)
    const [inflationAdjusted, setInflationAdjusted] = useState(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.InflationAdjusted : false)
    const [average, setAverage] = useState(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.Average : false)
    const [annualizedFlow, setAnnualizedFlow] = useState(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.AnnualizedFlow : false)
    const [perCapita, setPerCapita] = useState(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.PerCapita : false)
    const [annualPercentChange, setAnnualPercentChange] = useState(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.AnnualPercentChange : false)
    const [totalReturn, setTotalReturn] = useState(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.TotalReturn : false)
    const [periodPercentChange, setPeriodPercentChange] = useState(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.PeriodPercentChange : false)
    const [percentGDP, setPercentGDP] = useState(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.PercentGDP : false)
    const [dataFillMethod, setDataFillMethod] = useState(downloadSettings?.DataFillMethod ? downloadSettings?.DataFillMethod : DataFillMethodValue.Backward)
    const [sharesOutstanding, setSharesOutstanding] = useState(downloadSettings?.EquityFields ? downloadSettings.EquityFields.SharesOutstanding : false)
    const [marketCap, setMarketCap] = useState(downloadSettings?.EquityFields ? downloadSettings.EquityFields.MarketCap : false)
    const [eps, setEPS] = useState(downloadSettings?.EquityFields ? downloadSettings.EquityFields.EPS : false)
    const [peRatio, setPERatio] = useState(downloadSettings?.EquityFields ? downloadSettings.EquityFields.PERatio : false)
    const [dividendYield, setDividendYield] = useState(downloadSettings?.EquityFields ? downloadSettings.EquityFields.DividendYield : false)
    const [splitsAndDividends, setSplitsAndDividend] = useState(downloadSettings?.CorporateActionFields ? downloadSettings.CorporateActionFields.SplitsAndDividends : false)
    const [shareInformation, setShareInformation] = useState(downloadSettings?.CorporateActionFields ? downloadSettings.CorporateActionFields.ShareInformation : false)
    const [annualFundamentals, setAnnualFundamentals] = useState(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.AnnualFundamentals : false)
    const [annualRatios, setAnnualRatios] = useState(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.AnnualRatios : false)
    const [estimates, setEstimates] = useState(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.Estimates : false)
    const [quarterlyFundamentals, setQuarterlyFundamentals] = useState(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.QuarterlyFundamentals : false)
    const [quarterlyRatios, setQuarterlyRatios] = useState(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.QuarterlyRatios : false)
    const [worksheetFormat, setWorksheetFormat] = useState<WorksheetFormatValueType>(downloadSettings ? downloadSettings.WorksheetFormat : WorksheetFormatValue.Stacked)

    useEffect(() => {
        setSeries(downloadSettings?.Series || [])
        setMinimumYear(seriesMinDate?.year() || downloadSettings?.FromYear || minYear)
        setMaximumYear(seriesMaxDate?.year() || downloadSettings?.ToYear || maxYear)
        setMinimumDate(seriesMinDate || downloadSettings?.FromDate || defaultStartDate)
        setMaximumDate(seriesMaxDate || downloadSettings?.ToDate || defaultEndDate)

        setSecuritiesIncluded(Boolean(downloadSettings?.SecuritiesIncluded))
        setCorporateActionsIncluded(Boolean(downloadSettings?.CorporateActionsIncluded))
        setHasInflationAdjusted(Boolean(downloadSettings?.hasInflationAdjusted))
        setHasPerCapita(Boolean(downloadSettings?.hasPerCapita))
        setHasPercentGDP(Boolean(downloadSettings?.hasPercentGDP))
        setHasSplitAdjusted(Boolean(downloadSettings?.hasSplitAdjusted))

        setSelectedDateMode(downloadSettings?.DateMode || DateMode.ByDate)
        setFromYear(downloadSettings?.FromYear || minYear)
        setToYear(downloadSettings?.ToYear || maxYear)
        setFromDate(downloadSettings?.FromDate || seriesMinDate || defaultStartDate)
        setToDate(downloadSettings?.ToDate || seriesMaxDate || defaultEndDate)
        setOpen(downloadSettings?.DataFields ? downloadSettings.DataFields.Open : true)
        setHigh(downloadSettings?.DataFields ? downloadSettings.DataFields.High : true)
        setLow(downloadSettings?.DataFields ? downloadSettings.DataFields.Low : true)

        setVolume(downloadSettings?.DataFields ? downloadSettings.DataFields.Volume : false)
        setOpenInterest(downloadSettings?.DataFields ? downloadSettings.DataFields.OpenInterest : false)
        setCloseOnly(downloadSettings?.DataFields ? downloadSettings.DataFields.CloseOnly : false)
        setMovingAverage(downloadSettings?.MovingAverage ? downloadSettings.MovingAverage : MovingAverageValue.None)
        setOutputFormat(downloadSettings?.OutputFormat ? downloadSettings.OutputFormat : OutputFormatValue.Excel)
        setCurrency(downloadSettings?.Currency ? downloadSettings.Currency : CurrencyDefaultValue)
        setDataFrequency(downloadSettings?.DataFrequency ? downloadSettings.DataFrequency : DataFrequencyValue.Daily)
        setDateFormat(Object.values(DateFormatValue).includes(downloadSettings?.DateFormat) ? downloadSettings.DateFormat : DateFormatValue.American)
        setSplitAdjusted(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.SplitAdjusted : false)
        setInflationAdjusted(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.InflationAdjusted : false)
        setAverage(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.Average : false)
        setAnnualizedFlow(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.AnnualizedFlow : false)
        setPerCapita(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.PerCapita : false)
        setAnnualPercentChange(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.AnnualPercentChange : false)
        setTotalReturn(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.TotalReturn : false)
        setPeriodPercentChange(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.PeriodPercentChange : false)
        setPercentGDP(downloadSettings?.Adjustments ? downloadSettings?.Adjustments.PercentGDP : false)
        setDataFillMethod(downloadSettings?.DataFillMethod ? downloadSettings?.DataFillMethod : DataFillMethodValue.Backward)
        setSharesOutstanding(downloadSettings?.EquityFields ? downloadSettings.EquityFields.SharesOutstanding : false)
        setMarketCap(downloadSettings?.EquityFields ? downloadSettings.EquityFields.MarketCap : false)
        setEPS(downloadSettings?.EquityFields ? downloadSettings.EquityFields.EPS : false)
        setPERatio(downloadSettings?.EquityFields ? downloadSettings.EquityFields.PERatio : false)
        setDividendYield(downloadSettings?.EquityFields ? downloadSettings.EquityFields.DividendYield : false)
        setSplitsAndDividend(downloadSettings?.CorporateActionFields ? downloadSettings.CorporateActionFields.SplitsAndDividends : false)
        setShareInformation(downloadSettings?.CorporateActionFields ? downloadSettings.CorporateActionFields.ShareInformation : false)
        setAnnualFundamentals(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.AnnualFundamentals : false)
        setAnnualRatios(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.AnnualRatios : false)
        setEstimates(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.Estimates : false)
        setQuarterlyFundamentals(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.QuarterlyFundamentals : false)
        setQuarterlyRatios(downloadSettings?.FundamentalsAndRatiosFields ? downloadSettings.FundamentalsAndRatiosFields.QuarterlyRatios : false)
        setWorksheetFormat(downloadSettings ? downloadSettings.WorksheetFormat : WorksheetFormatValue.Stacked)
    }, [downloadSettings])

    useEffect(() => {
        const year = seriesMaxDate?.year() || downloadSettings?.ToYear || maxYear
        const date = seriesMaxDate || downloadSettings?.ToDate || defaultEndDate

        setMaximumYear(year)
        setMaximumDate(date)

        if (!Boolean(readOnly) && downloadRequestSource === DownloadRequestSource.OnDemand) {
            if (fromYear > year) setFromYear(year)
            if (fromDate > date) setFromDate(date)

            setToYear(year)
            setToDate(date)
        }
    }, [seriesMaxDate])

    useEffect(() => {
        const year = seriesMinDate?.year() || downloadSettings?.FromYear || minYear
        const date = seriesMinDate || downloadSettings?.FromDate || defaultStartDate

        setMinimumYear(year)
        setMinimumDate(date)

        if (!Boolean(readOnly) && downloadRequestSource === DownloadRequestSource.OnDemand) {
            if (toYear < year) setToYear(year)
            if (toDate < date) setToDate(date)

            setFromYear(year)
            setFromDate(date)
        }
    }, [seriesMinDate])

    const getExcelAlert = () => {
        return <Typography variant='caption' style={{ color: '#212529' }}>
            {'If total data count exceeds the limit of 1048575, data will be split into multiple worksheets in the Excel workbook.'}
        </Typography>
    }

    const zacksFields = {
        equity: 'Equity Data',
        shareInformation: 'Share Information',
        fundamentals: 'Fundamental Data'
    }

    const getZacksAlert = (field: string) => {
        let data = 'information'
        switch (field) {
            case zacksFields.equity:
                data = 'equity'
                break
            case zacksFields.shareInformation:
                data = 'share information'
                break
            case zacksFields.fundamentals:
                data = 'fundamentals'
                break
        }
        return <Typography variant='caption' style={{ color: 'brown' }}>
            {`***${field} can only be downloaded if 10 or less series are selected.  You can utilize AutoTrac workbooks to set up sets of 10 series and download the ${data} for each set at will.`}
        </Typography>
    }

    useEffect(() => {
        populateCurrencies({ group: 'Download' })
            .then((result: CurrencyOption[]) => {
                setCurrencyOptions(result)
            },
                //Reject promise
                () => {
                    console.log('failed to get currencies!')
                    setCurrencyOptions([{ label: CurrencyDefaultLabel, value: CurrencyDefaultValue }])
                })
    }, [])

    const validateDates = () => {
        if (selectedDateMode === DateMode.ByDate && fromDate > toDate) {
            return false
        }
        return true
    }

    const getFromDateError = () => {
        return '"From Date" must be less than or equal to "To Date"'
    }

    const getToDateError = () => {
        return '"To Date" must be greater than or equal to "From Date"'
    }

    const validateYears = () => {
        if (selectedDateMode === DateMode.ByYear && fromYear > toYear) {
            return false
        }
        return true
    }

    const invalidDateError = 'Invalid date'

    const getFromYearError = () => {
        return '"From Year" must be less than or equal to "To Year"'
    }

    const getToYearError = () => {
        return '"To Year" must be greater than or equal to "From Year"'
    }

    const getMinimumYearError = () => {
        return `"From Year" cannot be less than minimum year available ${minimumYear}`
    }

    const getMaximumYearError = () => {
        return `"To Year" cannot be greater than maximum year available ${maximumYear}`
    }

    const getMinimumFromDateError = () => {
        return `"From Date" cannot be less than minimum date available ${minimumDate?.format(DownloadSettingsDateFormat)}`
    }

    const getMaximumFromDateError = () => {
        return `"From Date" cannot be greater than maximum date available ${maximumDate?.format(DownloadSettingsDateFormat)}`
    }

    const getMinimumToDateError = () => {
        return `"To Date" cannot be less than minimum date available ${minimumDate?.format(DownloadSettingsDateFormat)}`
    }

    const getMaximumToDateError = () => {
        return `"To Date" cannot be greater than maximum date available ${maximumDate?.format(DownloadSettingsDateFormat)}`
    }

    const runFromDateChecks = () => {
        if (!fromDate?.isValid()) {
            setFromDateError(invalidDateError)
        } else if (fromDate < minimumDate) {
            setFromDateError(getMinimumFromDateError())
        } else if (!validateDates()) {
            setFromDateError(getFromDateError())
        } else if (fromDate > maximumDate) {
            setFromDateError(getMaximumFromDateError())
        } else {
            setFromDateError(null)
        }
    }

    const runToDateChecks = () => {
        if (!toDate?.isValid()) {
            setToDateError(invalidDateError)
        } else if (toDate > maximumDate) {
            setToDateError(getMaximumToDateError())
        } else if (!validateDates()) {
            setToDateError(getToDateError())
        } else if (toDate < minimumDate) {
            setToDateError(getMinimumToDateError())
        } else {
            setToDateError(null)
        }
    }

    const runFromYearChecks = () => {
        if (fromYear < minimumYear) {
            setFromYearError(getMinimumYearError())
        } else if (!validateYears()) {
            setFromYearError(getFromYearError())
        } else {
            setFromYearError(null)
        }
    }

    const runToYearChecks = () => {
        if (toYear > maximumYear) {
            setToYearError(getMaximumYearError())
            return
        } else if (!validateYears()) {
            setToYearError(getToYearError())
        } else {
            setToYearError(null)
        }
    }

    useEffect(() => {
        if (readOnly || !setDisableSaveDownloadSettings) return
        const disable: boolean = Boolean(fromDateError || toDateError || fromYearError || toYearError)
        setDisableSaveDownloadSettings(disable)
    }, [fromDateError, toDateError, fromYearError, toYearError])

    useEffect(() => {
        runFromDateChecks()
        runToDateChecks()
    }, [fromDate, toDate])

    useEffect(() => {
        runFromYearChecks()
        runToYearChecks()
    }, [fromYear, toYear])

    useEffect(() => {
        if (!triggerSaveDownloadSettings) return

        saveDownloadSettings({
            SecuritiesIncluded: securitiesIncluded,
            CorporateActionsIncluded: corporateActionsIncluded,
            hasInflationAdjusted,
            hasPerCapita,
            hasPercentGDP,
            hasSplitAdjusted,
            DateMode: selectedDateMode,
            FromYear: fromYear,
            ToYear: toYear,
            FromDate: fromDate,
            ToDate: toDate,
            DataFields: {
                Open: open,
                High: high,
                Low: low,
                Close: close,
                Volume: volume,
                OpenInterest: openInterest,
                CloseOnly: closeOnly
            },
            Adjustments: {
                SplitAdjusted: splitAdjusted,
                InflationAdjusted: inflationAdjusted,
                Average: average,
                AnnualizedFlow: annualizedFlow,
                PerCapita: perCapita,
                AnnualPercentChange: annualPercentChange,
                PeriodPercentChange: periodPercentChange,
                TotalReturn: totalReturn,
                PercentGDP: percentGDP
            },
            Currency: currency,
            DataFrequency: dataFrequency,
            DateFormat: dateFormat,
            MovingAverage: movingAverage,
            OutputFormat: outputFormat,
            DataFillMethod: dataFillMethod,
            EquityFields: {
                SharesOutstanding: sharesOutstanding,
                MarketCap: marketCap,
                EPS: eps,
                PERatio: peRatio,
                DividendYield: dividendYield
            },
            CorporateActionFields: {
                SplitsAndDividends: splitsAndDividends,
                ShareInformation: shareInformation
            },
            FundamentalsAndRatiosFields: {
                AnnualFundamentals: annualFundamentals,
                AnnualRatios: annualRatios,
                Estimates: estimates,
                QuarterlyFundamentals: quarterlyFundamentals,
                QuarterlyRatios: quarterlyRatios
            },
            WorksheetFormat: worksheetFormat,
            Series: series
        })
        console.log('download settings passed back!')
        resetTriggerDownloadSettings()
    }, [triggerSaveDownloadSettings])

    const DataFieldOptions = {
        [DataField.Open]: {
            label: DataField.Open,
            value: open,
            onChange: setOpen,
            disabled: closeOnly
        },
        [DataField.High]: {
            label: DataField.High,
            value: high,
            onChange: setHigh,
            disabled: closeOnly
        },
        [DataField.Low]: {
            label: DataField.Low,
            value: low,
            onChange: setLow,
            disabled: closeOnly
        },
        [DataField.Close]: {
            label: DataField.Close,
            value: close,
            onChange: setClose,
            disabled: true
        },
        [DataField.Volume]: {
            label: DataField.Volume,
            value: volume,
            onChange: setVolume,
            disabled: closeOnly
        },
        [DataField.OpenInterest]: {
            label: DataField.OpenInterest,
            value: openInterest,
            onChange: setOpenInterest,
            disabled: closeOnly
        },
        [DataField.CloseOnly]: {
            label: DataField.CloseOnly,
            value: closeOnly,
            onChange: setCloseOnly,
            disabled: false
        },
    }

    const applyZacksLimitation: boolean = Boolean(series.length && series.length > 10)

    const disableForZacksLimitations = {
        disabled: applyZacksLimitation
    }

    const EquityFieldOptions = {
        [EquityField.SharesOutstanding]: {
            label: EquityField.SharesOutstanding,
            value: closeOnly ? false : sharesOutstanding,
            onChange: setSharesOutstanding,
            ...disableForZacksLimitations
        },
        [EquityField.MarketCap]: {
            label: EquityField.MarketCap,
            value: closeOnly ? false : marketCap,
            onChange: setMarketCap,
            ...disableForZacksLimitations
        },
        [EquityField.EPS]: {
            label: EquityField.EPS,
            value: closeOnly ? false : eps,
            onChange: setEPS,
            ...disableForZacksLimitations
        },
        [EquityField.PERatio]: {
            label: EquityField.PERatio,
            value: closeOnly ? false : peRatio,
            onChange: setPERatio,
            ...disableForZacksLimitations
        },
        [EquityField.DividendYield]: {
            label: EquityField.DividendYield,
            value: closeOnly ? false : dividendYield,
            onChange: setDividendYield,
            ...disableForZacksLimitations
        },
    }

    const CorporateActionFieldOptions = {
        [CorporateActionField.SplitsAndDividends]: {
            label: CorporateActionField.SplitsAndDividends,
            value: splitsAndDividends,
            onChange: setSplitsAndDividend,
            disabled: false
        }
    }

    const FundamentalsAndRatiosFieldOptions = {
        [FundamentalsAndRatiosField.AnnualFundamentals]: {
            label: FundamentalsAndRatiosField.AnnualFundamentals,
            value: annualFundamentals,
            onChange: setAnnualFundamentals,
            ...disableForZacksLimitations
        },
        [FundamentalsAndRatiosField.AnnualRatios]: {
            label: FundamentalsAndRatiosField.AnnualRatios,
            value: annualRatios,
            onChange: setAnnualRatios,
            ...disableForZacksLimitations
        },
        [FundamentalsAndRatiosField.Estimates]: {
            label: FundamentalsAndRatiosField.Estimates,
            value: estimates,
            onChange: setEstimates,
            ...disableForZacksLimitations
        },
        [FundamentalsAndRatiosField.QuarterlyFundamentals]: {
            label: FundamentalsAndRatiosField.QuarterlyFundamentals,
            value: quarterlyFundamentals,
            onChange: setQuarterlyFundamentals,
            ...disableForZacksLimitations
        },
        [FundamentalsAndRatiosField.QuarterlyRatios]: {
            label: FundamentalsAndRatiosField.QuarterlyRatios,
            value: quarterlyRatios,
            onChange: setQuarterlyRatios,
            ...disableForZacksLimitations
        },
        [CorporateActionField.ShareInformation]: {
            label: CorporateActionField.ShareInformation,
            value: closeOnly ? false : shareInformation,
            onChange: setShareInformation,
            disabled: closeOnly || applyZacksLimitation
        }
    }

    const YearOptions = {
        'FromYear': {
            label: 'From Year',
            value: fromYear,
            onChange: setFromYear,
            yearError: fromYearError
        },
        'ToYear': {
            label: 'To Year',
            value: toYear,
            onChange: setToYear,
            yearError: toYearError
        }
    }

    const DateOptions = {
        'FromDate': {
            label: 'From Date',
            value: fromDate,
            onChange: setFromDate,
            dateError: fromDateError
        },
        'ToDate': {
            label: 'To Date',
            value: toDate,
            onChange: setToDate,
            dateError: toDateError
        }
    }

    const getAdjustmentOptionsBasedOnSecurities = () => {
        return securitiesIncluded ? AdjustmentOptionsForSecurities : AdjustmentOptionsForNoSecurities
    }

    const AdjustmentOptionsForSecurities = {
        [Adjustment.TotalReturn]: {
            label: Adjustment.TotalReturn,
            value: totalReturn,
            onChange: setTotalReturn
        }
    }

    const AdjustmentOptionsForNoSecurities = {
        [Adjustment.AnnualizedFlow]: {
            label: Adjustment.AnnualizedFlow,
            value: annualizedFlow,
            onChange: setAnnualizedFlow
        },
        [Adjustment.PerCapita]: {
            label: Adjustment.PerCapita,
            value: downloadSettings.hasPerCapita ?? true ? perCapita : false,
            onChange: setPerCapita,
            disabled: !(downloadSettings.hasPerCapita ?? true)
        },
        [Adjustment.PercentGDP]: {
            label: Adjustment.PercentGDP,
            value: downloadSettings.hasPercentGDP ?? true ? percentGDP : false,
            onChange: setPercentGDP,
            disabled: !(downloadSettings.hasPercentGDP ?? true)
        }
    }

    const AdjustmentOptions = {
        [Adjustment.SplitAdjusted]: {
            label: Adjustment.SplitAdjusted,
            value: downloadSettings.hasSplitAdjusted ?? true ? splitAdjusted : false,
            onChange: setSplitAdjusted,
            disabled: !(downloadSettings.hasSplitAdjusted ?? true)
        },
        [Adjustment.InflationAdjusted]: {
            label: Adjustment.InflationAdjusted,
            value: downloadSettings.hasInflationAdjusted ?? true ? inflationAdjusted : false,
            onChange: setInflationAdjusted,
            disabled: !(downloadSettings.hasInflationAdjusted ?? true)
        },
        [Adjustment.Average]: {
            label: Adjustment.Average,
            value: average,
            onChange: setAverage
        },
        [Adjustment.AnnualPercentChange]: {
            label: Adjustment.AnnualPercentChange,
            value: annualPercentChange,
            onChange: setAnnualPercentChange
        },
        [Adjustment.PeriodPercentChange]: {
            label: Adjustment.PeriodPercentChange,
            value: periodPercentChange,
            onChange: setPeriodPercentChange
        },
        ...getAdjustmentOptionsBasedOnSecurities()
    }

    const WorksheetFormatOptions = {
        [WorksheetFormatDisplay.Stacked]: {
            label: WorksheetFormatDisplay.Stacked,
            value: WorksheetFormatModel[WorksheetFormatDisplay.Stacked],
            disabled: false
        },
        [WorksheetFormatDisplay.Pivoted]: {
            label: WorksheetFormatDisplay.Pivoted,
            value: WorksheetFormatModel[WorksheetFormatDisplay.Pivoted],
            disabled: movingAverage !== MovingAverageValue.None
        }
    }

    const renderDataFieldCheckBoxes = () => {
        return Object.values(DataFieldOptions).map((value: any, index: number) => <FormControlLabel
            key={`dataFieldCheckbox${index}`}
            style={{ ...Styles.FormControlLabel }}
            control={<CheckboxBlue
                checked={value.value}
                onChange={(event, checked) => value.onChange(checked)}
                disabled={Boolean(value.disabled)} />}
            label={value.label}
            labelPlacement="end"
        />)
    }

    const renderEquityFieldCheckBoxes = () => {
        return Object.values(EquityFieldOptions).map((value: any, index: number) => <FormControlLabel
            key={`equityFieldCheckBox${index}`}
            style={{ ...Styles.FormControlLabel }}
            control={<CheckboxBlue
                checked={value.value}
                onChange={(event, checked) => value.onChange(checked)}
                disabled={Boolean(value.disabled)}
            />}
            label={value.label}
            labelPlacement="end"
        />)
    }

    const renderCorporateActionFieldCheckBoxes = () => {
        return Object.values(CorporateActionFieldOptions).map((value: any, index: number) => <FormControlLabel
            key={`corporateActionFieldCheckBox${index}`}
            style={{ ...Styles.FormControlLabel }}
            control={<CheckboxBlue
                checked={value.value}
                onChange={(event, checked) => value.onChange(checked)}
                disabled={Boolean(value.disabled)}
            />}
            label={value.label}
            labelPlacement="end"
        />)
    }

    const renderFundamentalsAndRatiosFieldCheckBoxes = () => {
        return Object.values(FundamentalsAndRatiosFieldOptions).map((value: any, index: number) => <FormControlLabel
            key={`fundamentalsAndRatiosFieldCheckBox${index}`}
            style={{ ...Styles.FormControlLabel }}
            control={<CheckboxBlue
                checked={value.value}
                onChange={(event, checked) => value.onChange(checked)}
                disabled={Boolean(value.disabled)}
            />}
            label={value.label}
            labelPlacement="end"
        />)
    }

    const renderYearMenuItems = () => {
        const menuItems = []
        for (let i = minimumYear; i <= maximumYear; i++) {
            menuItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        }
        return menuItems
    }

    const renderCurrencyMenuItems = () => {
        return currencyOptions.map(
            currency => <MenuItem key={currency.label} value={currency.value}>{currency.label}</MenuItem>
        )
    }

    const renderOutputFormatToggleButtons = () => {
        return Object.values(OutputFormatValue).map(
            (format: any, index: number) => <CustomToggle key={`outputFormat${index}`} size='small' value={format}>{format}</CustomToggle>
        )
    }

    const renderWorksheetFormatToggleButtons = () => {
        return Object.values(WorksheetFormatOptions).map(
            (format: any, index: number) => <CustomToggle size='small' key={format.label} value={format.value} disabled={format.disabled}>{format.label}</CustomToggle>
        )
    }

    const renderDataFrequencyMenuItems = () => {
        return Object.values(DataFrequencyValue).map(
            (value: any, index: number) => <MenuItem key={value} value={value}>{value}</MenuItem>
        )
    }

    const renderDataFillMethodToggleButtons = () => {
        return Object.keys(DataFillMethodModel).map(
            (method: any, index: number) => <CustomToggle key={`dataFillMethod${index}`} size='small' value={DataFillMethodModel[method]}>{method}</CustomToggle>
        )
    }

    const renderDateFormat = () => {
        return Object.keys(DateFormatModel).map(
            (format: any, index: number) => <MenuItem key={format} value={DateFormatModel[format]}>{format}</MenuItem>
        )
    }

    const renderYearOptions = () => {
        return Object.values(YearOptions).map((value: any, index: number) =>
            <Grid key={`yearOption${index}`} item md={5}>
                <FormControl variant='outlined' sx={{ minWidth: 120 }}
                    {...(value.yearError !== null ? { error: true } : {})}
                >
                    <InputLabel id={`${value.label.replace(' ', '')}-select-label`}>{value.label}</InputLabel>
                    <Select
                        labelId={`${value.label.replace(' ', '')}-select-label`}
                        id={`${value.label.replace(' ', '')}select`}
                        value={value.value?.toString() || ''}
                        label={value.label}
                        onChange={(event, child) => value.onChange(parseInt(event.target.value))}
                    >
                        {renderYearMenuItems()}
                    </Select>
                    <FormHelperText>{value.yearError}</FormHelperText>
                </FormControl>
            </Grid>
        )
    }

    const renderByYear = () => {
        return (<>
            {renderYearOptions()}
        </>)
    }

    const renderDateOptions = () => {
        return Object.values(DateOptions).map((value: any, index: number) =>
            <Grid key={`dateOption${index}`} item md={5}>
                <DatePicker
                    label={value.label}
                    value={value.value}
                    minDate={minimumDate}
                    maxDate={maximumDate}
                    onChange={(newValue) => {
                        value.onChange(newValue as moment.Moment)
                    }}
                    sx={{ fontWeight: 'bold' }}
                    slotProps={{
                        textField: {
                            error: value.dateError !== null,
                            helperText: value.dateError
                        }
                    }}
                />
            </Grid>
        )
    }

    const renderByDate = () => {
        return (<>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                {renderDateOptions()}
            </LocalizationProvider>
        </>)
    }

    const renderRowTitle = (title: string) => {
        return (<Grid item md={5} flexDirection='column'>
            <div style={{ fontWeight: 'bold', marginTop: '8px' }}>{title}</div>
        </Grid>)
    }

    const renderDivider = () => {
        return (<Divider sx={{ borderColor: 'black', paddingTop: 1, marginBottom: '8px' }} />)
    }

    const handleChangeSelectedDataMode = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string,
    ) => {
        if (newValue === null) return

        setSelectedDateMode(newValue as DateModeValueType)
    }

    const handleChangeMovingAverage = (
        event: React.MouseEvent<HTMLElement>,
        newMode: string,
    ) => {
        if (newMode === null) return

        const newValue = newMode as MovingAverageValueType
        setMovingAverage(newValue)

        newValue !== MovingAverageValue.None && setWorksheetFormat(WorksheetFormatValue.Stacked)
    }

    const handleChangeDataFillMethod = (
        event: React.MouseEvent<HTMLElement>,
        newMode: string,
    ) => {
        if (newMode === null) return

        setDataFillMethod(newMode as DataFillMethodValueType)
    }

    const handleChangeCurrency = (
        event: SelectChangeEvent<string>,
        child: ReactNode,
    ) => {
        setCurrency(event.target.value)
    }

    const handleChangeDataFrequency = (
        event: SelectChangeEvent<DataFrequencyValueType>,
        child: ReactNode,
    ) => {
        setDataFrequency(event.target.value as DataFrequencyValueType)
    }

    const handleChangeDateFormat = (
        event: SelectChangeEvent<DateFormatValueType>,
        child: ReactNode,
    ) => {
        setDateFormat(event.target.value as DateFormatValueType)
    }

    const handleChangeWorksheetFormat = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string,
    ) => {
        if (newValue === null) return

        setWorksheetFormat(newValue as unknown as WorksheetFormatValueType)
    }

    const handleChangeOutputFormat = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string,
    ) => {
        if (newValue === null) return

        setOutputFormat(newValue as OutputFormatValueType)
    }

    const renderDownloadData = () => {
        return (<Grid container spacing={3} columns={25}>
            {renderRowTitle('Download Data')}

            <Grid item md={6}>
                <ToggleButtonGroup
                    value={selectedDateMode}
                    color='secondary'
                    exclusive
                    onChange={handleChangeSelectedDataMode}
                    aria-label='selected date mode'
                >
                    <CustomToggle size='small' value={DateMode.ByYear}>{DateMode.ByYear}</CustomToggle>
                    <CustomToggle size='small' value={DateMode.ByDate}>{DateMode.ByDate}</CustomToggle>
                </ToggleButtonGroup>
            </Grid>
            {selectedDateMode === DateMode.ByDate ? renderByDate() : renderByYear()}
        </Grid>)
    }

    const renderDataFields = () => {
        return (<Grid container spacing={3} columns={25}>
            {renderRowTitle('Data Fields')}

            <Grid item md={18}>
                <FormGroup aria-label="position" row>
                    {renderDataFieldCheckBoxes()}
                </FormGroup>
            </Grid>
        </Grid>)
    }

    const renderAdjustmentCheckBoxes = () => {
        return Object.values(AdjustmentOptions).map((value: any, index: number) => <FormControlLabel
            key={`adjustmentCheckBox${index}`}
            style={{ ...Styles.FormControlLabel }}
            control={<CheckboxBlue
                checked={value.value}
                onChange={(event, checked) => value.onChange(checked)} />}
                disabled={value.disabled}
            label={value.label}
            labelPlacement="end"
        />)
    }

    const renderMovingAverageToggleButtons = () => {
        return Object.keys(MovingAverageModel).map(
            (key:any, index: number) => <CustomToggle key={`ma${index}`} size='small' value={MovingAverageModel[key]}>{key}</CustomToggle>
        )
    }

    const renderAdjustments = () => {
        return (<>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Adjustments')}

                <Grid item md={18}>
                    <FormGroup aria-label="position" row>
                        {renderAdjustmentCheckBoxes()}
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('')}

                <Grid item md={18}>
                    <FormLabel
                        id='movingAverageLabel'
                        key='movingAverageLabel'
                        style={Styles.FormLabel}
                    >
                        Moving Average
                    </FormLabel>
                    <ToggleButtonGroup
                        color='secondary'
                        style={{ marginLeft: '10px', marginRight: '8px', flexWrap: 'wrap' }}
                        value={movingAverage}
                        exclusive
                        onChange={handleChangeMovingAverage}
                        aria-label='selected moving average'
                    >
                        {renderMovingAverageToggleButtons()}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </>)
    }

    const renderSelects = () => {
        return (
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('')}

                <Grid item md={6}>
                    <FormControl variant='outlined' sx={{ minWidth: 200, marginTop: '8px', marginBottom: '8px' }}>
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Select
                            labelId="currency-label"
                            id="currency-select"
                            value={currency}
                            label="Currency"
                            onChange={handleChangeCurrency}
                        >
                            {renderCurrencyMenuItems()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl variant='outlined' sx={{ minWidth: 200, marginTop: '8px', marginBottom: '8px' }}>
                        <InputLabel id="data-frequency-label">Data Frequency</InputLabel>
                        <Select
                            labelId="data-frequency-label"
                            id="data-frequency-select"
                            value={dataFrequency}
                            label="Data Frequency"
                            onChange={handleChangeDataFrequency}
                        >
                            {renderDataFrequencyMenuItems()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl variant='outlined' sx={{ minWidth: 200, marginTop: '8px', marginBottom: '8px' }}>
                        <InputLabel id="date-format-select-label">Date Format</InputLabel>
                        <Select
                            labelId="date-format-select-label"
                            id="date-format-select"
                            value={dateFormat}
                            label="Date Format"
                            onChange={handleChangeDateFormat}
                        >
                            {renderDateFormat()}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    const renderDataFillMethod = () => {
        return (<>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Data Fill Method')}

                <Grid item md={18}>
                    <ToggleButtonGroup
                        color='primary'
                        style={{ marginTop: '4px', marginRight: '8px', flexWrap: 'wrap' }}
                        value={dataFillMethod}
                        exclusive
                        onChange={handleChangeDataFillMethod}
                        aria-label='selected data fill method'
                    >
                        {renderDataFillMethodToggleButtons()}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </>)
    }

    const renderEquityFields = () => {
        return (<>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Equity Fields')}

                <Grid item md={18}>
                    <FormGroup aria-label="position" row>
                        {renderEquityFieldCheckBoxes()}
                    </FormGroup>
                    {
                        applyZacksLimitation ?
                            <FormGroup aria-label="position" row>
                                {getZacksAlert(zacksFields.equity)}
                            </FormGroup>
                            : <></>
                    }
                </Grid>
            </Grid>
        </>)
    }

    const renderCorporateActions = () => {
        return (<>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Corporate Actions')}

                <Grid item md={18}>
                    <FormGroup aria-label="position" row>
                        {renderCorporateActionFieldCheckBoxes()}
                    </FormGroup>
                </Grid>
            </Grid>
        </>)
    }

    const renderFundamentalsAndRatios = () => {
        return (<>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Fundamentals and Ratios')}

                <Grid item md={18}>
                    <FormGroup aria-label="position" row>
                        {renderFundamentalsAndRatiosFieldCheckBoxes()}
                    </FormGroup>
                    {
                        applyZacksLimitation ?
                            <FormGroup aria-label="position" row>
                                {getZacksAlert(zacksFields.fundamentals)}
                            </FormGroup>
                            : <></>
                    }
                </Grid>
            </Grid>
        </>)
    }

    const renderWorksheetFormat = () => {
        return (<>
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Worksheet Format')}

                <Grid item md={6}>
                    <ToggleButtonGroup
                        color='secondary'
                        style={{ marginTop: '4px', marginRight: '8px', flexWrap: 'wrap' }}
                        value={worksheetFormat}
                        exclusive
                        onChange={handleChangeWorksheetFormat}
                        aria-label='selected worksheet format'
                    >
                        {renderWorksheetFormatToggleButtons()}
                    </ToggleButtonGroup>
                </Grid>

                <Grid item md={10}>
                    <FormLabel
                        id='outputFormatLabel'
                        key='outputFormatLabel'
                        style={Styles.FormLabel}
                    >
                        Output Format
                    </FormLabel>
                    <ToggleButtonGroup
                        color='secondary'
                        style={{ marginTop: '4px', marginLeft: '10px', marginRight: '8px', flexWrap: 'wrap' }}
                        value={outputFormat}
                        exclusive
                        onChange={handleChangeOutputFormat}
                        aria-label='selected output format'
                    >
                        {renderOutputFormatToggleButtons()}
                    </ToggleButtonGroup>
                    {outputFormat?.toLowerCase()?.includes('excel') ?
                        <><br />{getExcelAlert()}</> : <></>}
                </Grid>
            </Grid>
        </>)
    }

    const renderFooter = () => {
        return <>
            <Divider sx={{ borderColor: 'black', paddingTop: 1 }} />
            <Typography variant='caption' style={{ color: '#212529', letterSpacing: '0', marginTop: '0', lineHeight: '1' }}>
                Please allow for some time if you are downloading more than 50 series at a time, or if you are selecting Adjustment Options for your downloads as these can take a while to process, thank you.
            </Typography>
        </>
    }
    const readOnlyProp: CSSProperties = (readOnly ?? false) ? { pointerEvents: 'none' } : {}

    return (
        <div style={{ marginLeft: '8px', marginRight: '16px', ...readOnlyProp }}>
            {renderDownloadData()}
            {renderDivider()}
            {renderDataFields()}
            {renderDivider()}
            {renderAdjustments()}
            {renderDivider()}
            {renderSelects()}
            {renderDivider()}
            {renderDataFillMethod()}
            {renderDivider()}
            {securitiesIncluded ? <>
                {closeOnly ? <></> : <>
                    {renderEquityFields()}
                    {renderDivider()}
                </>}
            </>
                : <></>}

            {corporateActionsIncluded ? <>
                {renderCorporateActions()}
                {renderDivider()}
            </>
                : <></>}

            {securitiesIncluded ? <>
                {renderFundamentalsAndRatios()}
                {renderDivider()}
            </>
                : <></>}
            {renderWorksheetFormat()}
            {renderFooter()}
        </div>
    )
}

const Styles = {
    FormControlLabel: {
        marginRight: '28px'
    },
    FormLabel: {
        color: 'rgb(33,37,41)'
    }
}

export default DownloadOptions
