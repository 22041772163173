export const ImageNumberReference = (number: number): JSX.Element => {
    return <span style={{ fontStyle: 'italic' }}><b style={{ fontSize: '24px', padding: '1px' }}><span style={{ border: '1px solid black', paddingLeft: '4px', paddingRight: '4px' }}>{number}</span></b> in the image</span>
}

export const ItalicizedWorkbookText = (): JSX.Element => {
    return <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>workbook</span>
}

export const RedRedText = (): JSX.Element => {
    return <span style={{ color: 'red', fontWeight: 'bold' }}>red</span>
}

export const BlueBlueText = (): JSX.Element => {
    return <span style={{ color: 'blue', fontWeight: 'bold' }}>blue</span>
}

export const GreenGreenText = (): JSX.Element => {
    return <span style={{ color: 'green', fontWeight: 'bold' }}>green</span>
}

export const OrangeOrangeText = (): JSX.Element => {
    return <span style={{ color: 'orange', fontWeight: 'bold' }}>orange</span>
}

export const YellowYellowText = (): JSX.Element => {
    return <span style={{ color: 'yellow', fontWeight: 'bold' }}>yellow</span>
}

export const GoldenYellowText = (): JSX.Element => {
    return <span style={{ color: '#FFDB58', fontWeight: 'bold' }}>yellow</span>
}

export const PurplePurpleText = (): JSX.Element => {
    return <span style={{ color: 'purple', fontWeight: 'bold' }}>purple</span>
}

export const SalmonPinkText = (): JSX.Element => {
    return <span style={{ color: 'salmon', fontWeight: 'bold' }}>pink</span>
}

export const MargentaPinkText = (): JSX.Element => {
    return <span style={{ color: '#FF00FF', fontWeight: 'bold' }}>pink</span>
}

export const GreyGreyText = (): JSX.Element => {
    return <span style={{ color: 'grey', fontWeight: 'bold' }}>grey</span>
}

export const LightgreenLightgreenText = (): JSX.Element => {
    return <span style={{ color: 'GreenYellow', fontWeight: 'bold' }}>light green</span>
}

export const BrownBrownText = (): JSX.Element => {
    return <span style={{ color: 'brown', fontWeight: 'bold' }}>brown</span>
}

export const MarkedRedRedText = (): JSX.Element => {
    return <mark style={{ backgroundColor: 'black' }}><RedRedText /></mark>
}

export const MarkedYellowYellowText = (): JSX.Element => {
    return <mark style={{ backgroundColor: 'black' }}><YellowYellowText /></mark>
}

export const MarkedOrangeOrangeText = (): JSX.Element => {
    return <mark style={{ backgroundColor: 'black' }}><OrangeOrangeText /></mark>
}

export const MarkedSalmonPinkText = (): JSX.Element => {
    return <mark style={{ backgroundColor: 'black' }}><SalmonPinkText /></mark>
}

export const MarkedGreyGreyText = (): JSX.Element => {
    return <mark style={{ backgroundColor: 'black' }}><GreyGreyText /></mark>
}

export const MarkedLightgreenLightgreenText = (): JSX.Element => {
    return <mark style={{ backgroundColor: 'black' }}><LightgreenLightgreenText /></mark>
}

export const MarkedGoldenYellowText = (): JSX.Element => {
    return <mark style={{ backgroundColor: 'black' }}><GoldenYellowText /></mark>
}

export const MarkedMargentaPinkText = (): JSX.Element => {
    return <mark style={{ backgroundColor: 'black' }}><MargentaPinkText /></mark>
}
