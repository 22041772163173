import { Box, Button, styled } from "@mui/material"
import React from "react"
import { Modal } from "react-bootstrap"
import { CustomBtnBlue, CustomBtnCloseCancel } from "../GlobalSettings/CustomStyles"

const FamaFrenchDeleteModal = ({ modalProps }: any) => {

    const handleClose = () => {
        modalProps.setShowModal(false)
    }

    const handleDelete = () => {
        modalProps.setToDelete(true)
        modalProps.setShowModal(false)
    }

    return (
        <React.Fragment>
            <Modal
                show={modalProps.showModal}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Reports?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Box style={{ textAlign: 'center' }}>Are you sure you want to delete this report?</Box>
                </Modal.Body>

                <Modal.Footer>
                            <CustomBtnCloseCancel variant='outlined' onClick={handleClose}>Cancel</CustomBtnCloseCancel>
                            <CustomBtnBlue variant='contained' onClick={handleDelete}>Yes</CustomBtnBlue>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default FamaFrenchDeleteModal


