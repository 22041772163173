import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField,styled } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnonymousLogin, CheckAnonymousLoginEnabled, loginUser, checkBetaAccess } from '../../Services/AuthenticationService'
import { LoginRequest, LogoutReasonType, LogoutReason, AnonymousLoginRequest } from '../../Models/DataModels/Requests/AuthRequests'
import { CheckAnonymousLoginEnabledResult, LoginResponse, CheckBetaAccessResult } from '../../Models/DataModels/Responses/AuthResponses'
import { Box, Container } from '@mui/system'
import { useEffect } from 'react'
import { pathParts, paths } from '../../Models/DataModels/Common/RedirectionModel'
import { EmptyStatusCode, NotOKResponseModel } from '../../Models/DataModels/Common/NotOKResponseModel'
import { ErrorHandler, ErrorHandlerProps } from '../Common/Utility/ErrorHandler'
import { clearAllLabelValuePair, getLocalStorageOrDefault, getRememberMe, getStoredDropdownFieldsLastUpdated, localStorageKeys, removeLocalStorage, removeRememberMe, setLocalStorage, setRememberMe, setStoredDropdownFieldsLastUpdated } from '../../Services/LocalStorageService'
import CookieAgreementModal, { CookieAgreementModalProps } from '../Common/Modals/Legal/CookieAgreementModal'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { removeAllTokensFromCookie } from '../../Services/CookieAccessService'
import { AppConfigurations } from '../../Models/DataModels/Common/AppConfigurationsModel'
import { getDropdownFieldsLastUpdated } from '../../Services/FieldPopulationService'
import moment from 'moment'
import { CustomBtnBlue, CheckboxBlue } from '../Common/GlobalSettings/CustomStyles'
import { GFDToastError } from '../Common/Utility/GFDToastify'

const LoginErrorMessages = {
    invalidInput: 'Invalid Username or Password.',
    usernameIsRequired: 'Username is required.',
    passwordIsRequired: 'Password is required.',
    usernameIsInvalid: 'Username is invalid.'
}

export interface LoginProps {
    login: (loginResponse: LoginResponse) => void,
    signOut: (logoutReason: LogoutReasonType) => void
}

const Login = ({
    login,
    signOut
}: LoginProps) => {

    const navigate = useNavigate()

    const [isAnonymousLoginEnabled, setIsAnonymousLoginEnabled] = useState<boolean>(false)
    const [userName, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isRememberMe, setIsRememberMe] = useState<boolean>(false)
    const [errorInputUsername, setErrorInputUsername] = useState<boolean>(false)
    const [errorInputPassword, setErrorInputPassword] = useState<boolean>(false)
    const [helpTextUsername, setHelpTextUsername] = useState<string>('')
    const [helpTextPassword, setHelpTextPassword] = useState<string>('')
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
    const [showCookieAgreementModal, setShowCookieAgreementModal] = useState<boolean>(false)
    const [userLoginResponse, setUserLoginResponse] = useState<LoginResponse | null>(null)

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleMouseDownPassword = () => setShowPassword(!showPassword)

    const resetErrorStates = () => {
        setErrorInputUsername(false)
        setErrorInputPassword(false)
        setHelpTextUsername('')
        setHelpTextPassword('')
        setErrorResponse(null)
    }

    const setUsernameError = (error: string) => {
        setHelpTextUsername(error)
        setErrorInputUsername(true)
    }

    const setPasswordError = (error: string) => {
        setHelpTextPassword(error)
        setErrorInputPassword(true)
    }

    const validateUsername = () => {
        if (userName === '') {
            setUsernameError(LoginErrorMessages.usernameIsRequired)
            return false
        }
        return true
    }

    const validatePassword = () => {
        if (password === '') {
            setPasswordError(LoginErrorMessages.passwordIsRequired)
            return false
        }
        return true
    }

    const isInputsValid = () => {
        resetErrorStates()
        let isValid: boolean = true
        if (validateUsername() === false) {
            isValid = false
        }
        if (validatePassword() === false) {
            isValid = false
        }
        return isValid
    }

    const processRememberMe = () => {
        var storedUsername: any = getRememberMe()
        if (storedUsername) {
            setUsername(storedUsername)
            setIsRememberMe(true)
        }
        else {
            setUsername('')
            setIsRememberMe(false)
        }
    }

    const onRegisterClick = () => {
        navigate(paths.register)
    }

    const onForgotPasswordClick = () => {
        navigate(paths.forgotPassword)
    }

    const betaAccessCheckEnabled: boolean = process.env.REACT_APP_BETA_ACCESS_CHECK === 'true'

    const processNavigate = () => {
        process.env.NODE_ENV !== 'development' && betaAccessCheckEnabled ? doesUserHaveBeta() : navigate(pathParts.search.searchDefault)
    }

    const processLoginResponse = (response: LoginResponse) => {
        login(response)

        getDropdownFieldsLastUpdated().then(
            (dropdownFieldsLastUpdatedString: string) => {
                const dropdownFieldsLastUpdated: moment.Moment = moment(dropdownFieldsLastUpdatedString)
                const storedLastUpdated: moment.Moment | null = getStoredDropdownFieldsLastUpdated()
                if (!storedLastUpdated || dropdownFieldsLastUpdated > storedLastUpdated) {
                    clearAllLabelValuePair()
                    setStoredDropdownFieldsLastUpdated(dropdownFieldsLastUpdated)
                }
                processNavigate()
            },
            (notOKResponseModel: NotOKResponseModel) => {
                clearAllLabelValuePair()
                setErrorResponse(notOKResponseModel)
                processNavigate()
            }
        )
    }

    const doLoginRequest = () => {
        if (!isInputsValid()) {
            return
        }

        const loginReq: LoginRequest = {
            username: userName,
            password: password,
            applicationType: AppConfigurations.applicationType,
            applicationNameType: AppConfigurations.applicationNameType
        }
        loginUser(loginReq).then((loginRes: LoginResponse) => {
            if (getLocalStorageOrDefault(localStorageKeys.lastLoggedInUser, false, '') !== loginReq.username) {
                removeLocalStorage(localStorageKeys.selectedResultsData)
            }
            setLocalStorage(localStorageKeys.lastLoggedInUser, loginReq.username)
            
            if (isRememberMe) {
                setRememberMe(loginReq.username)
            }
            else {
                removeRememberMe()
            }

            // token must be present for a successful login
            if (loginRes && loginRes?.token) {
                if (loginRes?.user?.isCookieAgreed) {
                    processLoginResponse(loginRes)
                } else {
                    setUserLoginResponse(loginRes)
                    setShowCookieAgreementModal(true)
                }
            } else {
                console.log(loginRes)
                GFDToastError('Unable to login')
            }
        },
            //Reject promise
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
                if (error?.statusCode !== EmptyStatusCode) {
                    setErrorInputUsername(true)
                    setErrorInputPassword(true)
                }
            })
    }

    const doAnonymousLoginRequest = () => {
        removeLocalStorage(localStorageKeys.selectedResultsData)
        setLocalStorage(localStorageKeys.lastLoggedInUser, 'Anonymous')

        const anonymousLoginRequest: AnonymousLoginRequest = {
            applicationType: AppConfigurations.applicationType,
            applicationNameType: AppConfigurations.applicationNameType
        }
        AnonymousLogin(anonymousLoginRequest).then(
            (result: LoginResponse) => {
                processLoginResponse(result)
            },
            //Reject promise
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
            }
        )
    }

    const isAnonymousLoginEnabledRequest = () => {
        CheckAnonymousLoginEnabled().then(
            (anonLoginEnabledResult: CheckAnonymousLoginEnabledResult) => {
                if (anonLoginEnabledResult?.isAnonymousLoginEnabled) {
                    setIsAnonymousLoginEnabled(true)
                } else {
                    setIsAnonymousLoginEnabled(false)
                }
            },
            (error: NotOKResponseModel) => {
                setIsAnonymousLoginEnabled(false)
            }
        )
    }

    const doesUserHaveBeta = () => {
        checkBetaAccess().then(
            (hasBetaAccessResult: CheckBetaAccessResult) => {
                if (hasBetaAccessResult?.hasBetaAccessResult) {
                    navigate(pathParts.search.searchDefault)
                }
                else {
                    removeAllTokensFromCookie()
                    signOut(LogoutReason.Kicked)
                }

            },
            (error: NotOKResponseModel) => {
                removeAllTokensFromCookie()
                signOut(LogoutReason.Kicked)
                setErrorResponse(error)
            }
        )
    }

    useEffect(() => {
        processRememberMe()
        isAnonymousLoginEnabledRequest()
    }, [])

    const renderError = () => {
        if (errorResponse
            && !errorResponse.isBackgroundCall
            && errorResponse.statusCode !== EmptyStatusCode
            && errorResponse.notOKResponse
            && !errorResponse.notOKResponse.internal) {
            return <>{errorResponse.notOKResponse.messages.map((message: any, index: number) => <label key={`loginError${index}`} style={loginStyles.error}>{message.message}</label>)}</>
        }
        return <></>
    }

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    const cookieAgreementModalProps: CookieAgreementModalProps = {
        showModal: showCookieAgreementModal,
        setShowModal: setShowCookieAgreementModal,
        loginRes: userLoginResponse,
        onAgreed: processLoginResponse,
        setErrorResponse: setErrorResponse
    }

    return (
        <div className='bodyLogin'>
            <Container className='bodyLogin'>
                <CookieAgreementModal {...cookieAgreementModalProps} />
                <ErrorHandler {...errorHandlerProps} />
                <input type='hidden' id='loginerror' />
                <div id='logoHeader' style={loginStyles.logoHeader}>
                    <div id='Logo'>
                        <img src={require('../../Images/Logo350pxlWhite.png')} alt='Global Financial Data' />
                    </div>
                </div>
                <Box sx={loginStyles.panel}>
                    <div id='tealHeader' style={loginStyles.tealHeader}></div>
                    <div id='registrationPanelBody' style={loginStyles.registrationPanelBody}>
                        <img src={require('../../Images/GFDFinaeonLogo-Cropped.png')} alt='GFD Finaeon' style={loginStyles.registrationPanelBodyCHILDimg} />
                        <section id='loginForm'>
                            {isAnonymousLoginEnabled &&
                                <>
                                    <br />
                                    <p>Academic users can login one of two ways.</p>
                                    <p>If you login anonymously, you can search the database, access individual data series and graph individual series.</p>
                                    <p>If you login with your username and password, you can also access your personalized home page, which helps you to customize your access, and download multiple series into a single worksheet.</p>
                                    <p>To gain personal access to Global Financial Data, please create your own account. You must use your university email address in creating the account.</p>
                                    <p>You must be logged in through your university’s proxy server using your university email address both to create your account and to log into the database. Any account created outside of the university will not be activated. Logins outside of the university’s server will not receive access.</p>
                                    <Button id='LoginAnon' variant='contained' onClick={doAnonymousLoginRequest}>Log in Anonymously</Button>
                                    <p>To login to an existing account, please enter your email address and password in the boxes below, then click the Login button.</p>
                                    <br />
                                </>
                            }

                            <Grid container direction='column'>
                                <Grid item lg={5} md={12}>
                                    <TextField id='userNameField' label='User Name' variant='outlined'
                                        sx={{ paddingBottom: 1, width: '50%' }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { fontWeight: 'bold' }
                                        }}
                                        size='small'
                                        value={userName}
                                        onChange={(e) => {
                                            setUsername(e.target.value)
                                            resetErrorStates()
                                        }}
                                        error={errorInputUsername}
                                        helperText={helpTextUsername} />
                                </Grid>
                                <Grid item lg={5} md={12}>
                                    <TextField id='passwordField' label='Password' variant='outlined'
                                        type={showPassword ? 'text' : 'password'}
                                        sx={{ paddingBottom: 1, width: '50%' }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { fontWeight: 'bold' }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton style={{ color: '#8c9fdd' }}
                                                        title={showPassword ? 'Hide Password' : 'Show Password'}
                                                        aria-label={showPassword ? 'Hide Password' : 'Show Password'}
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Tooltip title='Hide Password'><Visibility /></Tooltip> : <Tooltip title='Show Password'><VisibilityOff /></Tooltip>}
                                                    </IconButton>
                                                </InputAdornment>)
                                        }}
                                        size='small'
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                            resetErrorStates()
                                        }}
                                        error={errorInputPassword}
                                        helperText={helpTextPassword}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                doLoginRequest()
                                            }
                                        }} />
                                </Grid>
                                <Grid item lg={5} md={12}>{renderError()}</Grid>
                                <Grid item>
                                    <FormControlLabel control={<CheckboxBlue checked={isRememberMe} onChange={(e) => setIsRememberMe(e.currentTarget.checked)} />} label={'Remember me?'} />

                                </Grid>
                                <Grid item>
                                    <CustomBtnBlue id='submitLogin' variant='contained' onClick={doLoginRequest}>Log in</CustomBtnBlue>
                                </Grid>
                                <Grid item>
                                    <p style={{ paddingTop: '2%', marginBottom: '0px' }}>
                                        <Button style={loginStyles.adminLinks} onClick={onRegisterClick}>Register</Button>
                                    </p>
                                    <p style={{ paddingTop: '1%', paddingBottom: '10%' }}>
                                        <Button style={loginStyles.adminLinks} onClick={onForgotPasswordClick}>Forgot Password</Button>
                                    </p>
                                </Grid>
                            </Grid>
                        </section>
                    </div>
                    <div style={loginStyles.panelFooter}>
                        <span>
                            Global Financial Data, Inc.<br />
                            29122 Rancho Viejo Rd. Suite 214<br />
                            San Juan Capistrano, CA 92675
                        </span>
                        <span style={{
                            float: 'right'
                        }}>
                            &copy; 2013 - {new Date().getFullYear()} Global Financial Data, Inc. All rights reserved.
                        </span>
                    </div>
                </Box>
            </Container>
        </div>
    )
}

const loginStyles = {
    error: {
        color: '#d32f2f'
    },
    star: {
        fontFamily: 'Trebuchet MS, Microsoft Sans Serif, Myriad Pro, Lucida Sans Unicode, sans-serif'
    },
    panel: {
        border: '1px transparent'
    },

    logoHeader: {
        paddingTop: '70px',
        marginBottom: '25px'
    },

    tealHeader: {
        background: '#238080',
        color: '#FFF',
        fontSize: '8pt',
        borderColor: '#238080',
        fontWeight: 'normal',
        lineHeight: '1.5em',
        height: '4.5em',
        padding: '0 3em',
        fontFamily: 'Lucida Sans Unicode, Myriad Pro, Microsoft Sans Serif, sans - serif',
        verticalAlign: 'middle',
        borderRadius: '1em 1em 0 0'
    },

    registrationPanelBody: {
        background: '#fff',
        padding: '15px'
    },
    //https://stackoverflow.com/questions/5196583/target-elements-with-multiple-classes-within-one-rule
    // see rule here, this is for registrationPanel with child image
    registrationPanelBodyCHILDimg: {
        width: '242px',
        height: '63px',
        marginTop: '15px',
        marginBottom: '25px'
    },

    panelFooter: {
        borderColor: '#238080',
        background: '#238080',
        borderRadius: '0 0 1em 1em',
        fontFamily: 'Lucida Sans Unicode, Myriad Pro, Microsoft Sans Serif, sans - serif',
        color: '#fff',
        padding: '10px 15px'
    },

    validationSummaryErrors: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '15px',
        border: '1px solid transparent',
        borderRadius: '4px',
        color: '#b94a48',
        backgroundColor: '#f2dede',
        borderColor: 'ebccd1'
    },

    fieldValidationError: {
        marginTop: '20px',
        padding: '3px',
        color: '#b94a48'
    },
    label: {
        display: 'inline-block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    CheckBox: {
        color: '#007ea8',
    },
    adminLinks: {
        color: '#007ea8',
    }
}

export default Login