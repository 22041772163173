import AlertModal, { AlertButtonType, AlertModalProps } from "./AlertModal"

export interface AlertTickerLimitModalProps {
  tickerLimitAlertMessage: string,
  showTickerLimitModal: boolean, 
  setShowTickerLimitModal: (value: boolean) => void
}

const AlertTickerLimitModal = ({
    tickerLimitAlertMessage,
    showTickerLimitModal,
    setShowTickerLimitModal
}: AlertTickerLimitModalProps) => {

    const alertModalProps: AlertModalProps = {
        showModal: showTickerLimitModal,
        setShowModal: setShowTickerLimitModal,
        AlertTitle: 'Too many series',
        AlertContent: tickerLimitAlertMessage,
        AlertButtons: [
            {
            type: AlertButtonType.Cancel,
            display: 'OK',
            isPrimary: false
            }
        ],
        onAlertClose: () => {
            return true
        }
    }

    return <AlertModal {...alertModalProps} />
}

export default AlertTickerLimitModal
