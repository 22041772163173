import React from "react"
import { ReactComponent as AddTickerIcon } from "../../Images/svg/AddTicker.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const AddTicker = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <AddTickerIcon />
  </SvgIcon>
)

export default AddTicker