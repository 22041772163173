export interface NotOKResponse {
    internal: boolean,
    success: boolean,
    logOut: boolean,
    messages: MessageResponse[]
}

export interface MessageResponse {
    message: string,
    type: MessageResponseValueType
}

export type MessageResponseValueType = 'Unknown' | 'Info' | 'Warning' | 'Error' | 'Success' | 'Internal'

export const MessageResponseTypes = {
    Unknown: 'Unknown' as MessageResponseValueType,
    Info: 'Info' as MessageResponseValueType,
    Warning: 'Warning' as MessageResponseValueType,
    Error: 'Error' as MessageResponseValueType,
    Success: 'Success' as MessageResponseValueType,
    Internal: 'Internal' as MessageResponseValueType
}
