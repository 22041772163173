import { Box } from "@mui/material"
import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"
import { BlueBlueText, GreenGreenText, ImageNumberReference, MarkedRedRedText, MarkedSalmonPinkText, PurplePurpleText } from "../../HelpPageCommonComponents"

const CAPEratios = () => {
    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }}>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <br />
                        <br />
                        <br />
                        <h2 style={{ textDecoration: 'underline' }}>CAPE Ratios</h2>
                    </div>
                </Box>
                <div style={{ height: '524px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CAPEratios.png' alt='CAPE Ratios' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    The cyclically adjusted price-to-earnings ratio is a ratio which is used to assess if stocks are undervalued or overvalued. This ratio may also be refferred to as the Shiller P/E Ratio.
                    The CAPE Ratios tool allows you to view the CAPE Ratio for a selected country and index. The tool also optionally provides the ability to compare two different ratios.
                    You may choose to download your data and/or view it graphically.
                </p>
                <br />

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <h4>
                            CAPE Ratios Options
                        </h4>
                    </div>
                </Box>
                <div style={{ height: '510px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CAPEratioOptions.png' alt='CAPE Ratios Options' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    Once you have selected the country and index for the CAPE Ratio, you may elect to modify the data you want with additional options.
                    In the <MarkedRedRedText /> box ({ImageNumberReference(1)}) indicated by the <PurplePurpleText /> ({ImageNumberReference(2)}) arrow,
                    there are checkboxes where you can choose to view Excess CAPE Yields and/or Bull and Bear Market Events. Selecting either or both of these options will alter the data that is generated for you.
                </p>
                <p>
                    In order to select a second comparison country and index, you may check the "Compare Index" checkbox by the <GreenGreenText /> arrow ({ImageNumberReference(3)}). Once the checkbox has been selected,
                    the dropdown for the list of Countries and Index, as well as the CAPE Period options for the comparison company, will appear.
                </p>
                <br />

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <h4>
                            CAPE Ratios Data
                        </h4>
                    </div>
                </Box>
                <div style={{ height: '821px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20CAPEratiosResults.png' alt='CAPE Ratios Graph' style={helpPageStyles.image} />
                </div>
                <br />
                <p>In the <MarkedRedRedText /> box ({ImageNumberReference(1)}) are the options to obtain your CAPE Ratio data.
                    By clicking the "Graph Data" button, indicated by the <BlueBlueText /> arrow ({ImageNumberReference(2)}), you can generate a graphical representation of your data similar to the one in the above picture.
                    The <MarkedSalmonPinkText /> arrow ({ImageNumberReference(3)}) indicates the "Download Data" button which will download an Excel spreadsheet containing the requested information.</p>
                The larger <GreenGreenText /> arrow ({ImageNumberReference(4)}) points to a dropdown menu, where you can opt to download the generated graph as an image or the Excel spreedsheet.
                Clicking the icon to the right of the box, indicated by the smaller <GreenGreenText /> arrow ({ImageNumberReference(5)}), will download the type of data selected in the dropdown box.
            </div>
        </React.Fragment>
    )
}

export default CAPEratios