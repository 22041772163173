export type DataFrequencyValueType = 'Daily' | 'Weekly' | 'Monthly' | 'Quarterly' | 'Annual'
export const DataFrequencyValue = {
    Daily: 'Daily' as DataFrequencyValueType,
    Weekly: 'Weekly' as DataFrequencyValueType,
    Monthly: 'Monthly' as DataFrequencyValueType,
    Quarterly: 'Quarterly' as DataFrequencyValueType,
    Annual: 'Annual' as DataFrequencyValueType
}

export default DataFrequencyValue
