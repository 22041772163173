import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'
import { ItalicizedWorkbookText } from '../../../HelpPageCommonComponents'
import SelectAll from '../../../../Icons/SelectAllIcon'
import DeselectAll from '../../../../Icons/DeselectAllIcon'
import ReloadWorkbookList from '../../../../Icons/ReloadWorkbookListIcon'
import AddWorkbook from '../../../../Icons/AddWorkbookIcon'
import RenameWorkbook from '../../../../Icons/RenameWorkbookIcon'
import DeleteWorkbook from '../../../../Icons/DeleteWorkbookIcon'

const ModifyWorkbook = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Modify Workbook</h4>
                </div>
            </Box>
            <div style={{ height: '396px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Modify_Controls.png' style={helpPageStyles.image} alt='Advanced AutoTrac Modify Controls'></img>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Select All</h5>
                </div>
            </Box>
            <div style={{ height: '274px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Modify_SelectAll.png' style={helpPageStyles.image} alt='Advanced AutoTrac Modify Select All'></img>
            </div>
            <p>
                Clicking on <SelectAll /> button will select all the available <ItalicizedWorkbookText />s in the list.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Deselect All</h5>
                </div>
            </Box>
            <p>Clicking on <DeselectAll /> button will deselect all the selected <ItalicizedWorkbookText />s in the list.</p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Reload Workbook List</h5>
                </div>
            </Box>
            <p>
                Clicking on <ReloadWorkbookList /> button will update the list with the currently available <ItalicizedWorkbookText />s.
                This button is not commonly used, but it can be useful to check that <ItalicizedWorkbookText />s were properly added or deleted.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Add Workbook</h5>
                </div>
            </Box>
            <div style={{ height: '319px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Modify_Add.png' style={helpPageStyles.image} alt='Advanced AutoTrac Modify Add'></img>
            </div>
            <p>
                Clicking on <AddWorkbook /> button will add the <ItalicizedWorkbookText />. After clicking on <AddWorkbook /> button, type in the name of the <ItalicizedWorkbookText />.
                Then, hit enter key or click way. Note that there are contraints.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Rename workbook</h5>
                </div>
            </Box>
            <p>
                Clicking on <RenameWorkbook /> button will rename the <ItalicizedWorkbookText />. After selecting the <ItalicizedWorkbookText /> and clicking on <RenameWorkbook /> button, you can update the name.
                Then, hit enter key or click way. Note that there are contraints.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Delete Workbook</h5>
                </div>
            </Box>
            <div style={{ height: '419px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_Modify_Delete.png' style={helpPageStyles.image} alt='Advanced AutoTrac Delete'></img>
            </div>
            <p>
                Select the <ItalicizedWorkbookText /> that you wish to delete. Click on <DeleteWorkbook /> button. Confirmation modal will be displayed. Click on "Yes" button to confirm.
                If you wish to delete all the available <ItalicizedWorkbookText />s, you can click on <SelectAll /> button and then click on <DeleteWorkbook /> button to delete all the <ItalicizedWorkbookText />s.
                However, deletion is irreversible so use the <DeleteWorkbook /> button with caution.
            </p>
        </div>
    )
}

export default ModifyWorkbook
