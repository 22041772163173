import { Grid, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchEngine from './SearchEngine'
import SelectEngine, { getTitle } from './SelectEngine'
import TickerHomeSearch from './TickerHomeSearch'
import moment from 'moment'
import { useParams, useNavigate } from 'react-router-dom'
import { SearchRequestModel } from '../../../Models/DataModels/Common/SearchModels'
import { SortFieldType, SortOrderType } from '../../../Models/DataModels/Common/SortModel'
import { GFDatabaseRequest, GFDIndicesRequest, RealEstateDatabaseRequest, USStocksRequest, UKStocksRequest, GFDUniverseRequest, ConstituentMembershipRequest, EventsInTimeRequest, PaginationRequest } from '../../../Models/DataModels/Requests/SearchRequests'
import { SearchResponse } from '../../../Models/DataModels/Responses/SearchResponses'
import { searchGFDatabase, searchGFDIndices, searchRealEstateDatabase, searchUSStocks, searchUKStocks, searchUniverse, searchConstituentMembership, searchEventsInTime, searchGFDatabase2Point0 } from '../../../Services/SearchService'
import { SortProps } from './SearchResults'
import { emptyTableData } from './SearchPage'
import { UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { ALLCountryGroupEntry, SearchDatabaseType, SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { GFDToastError } from '../../Common/Utility/GFDToastify'
import { SubscriptionFilterType } from '../../../Models/DataModels/Common/SubscriptionFilter'
import { SearchFieldType } from '../../../Models/DataModels/Common/SearchFieldType'

let engineBasedSearchInProgress: boolean = false

export interface SearchHeaderProps {
    userInfo: UserInfo | null,
    checkIsTrial: () => boolean,
    signOut: (logoutReason: LogoutReasonType) => void,
    engineOption: SearchDatabaseType,
    setEngineOption: (engineOption: SearchDatabaseType) => void,
    setTableData: (tableData: any) => void,
    setShowSaved: (showSelected: boolean) => void,
    expandedOptions: any,
    searchInProgress: any,
    setSearchInProgress: (searchInProgress: boolean) => void,
    searchTitle: string,
    setSearchTitle: (searchTitle: string) => void,
    pageNumber: number,
    setPageNumber: (pageNumber: number) => void,
    perPageCount: number,
    setPerPageCount: (perPageCount: number) => void,
    totalCount: number,
    setTotalCount: (totalCount: number) => void,
    subSearchTerm: string,
    setSubSearchTerm: (subSearchTerm: string) => void,
    sortProps: SortProps,
    setSortProps: (sort: SortProps) => void,
    subscriptionFilter: SubscriptionFilterType,
    setSubscriptionFilter: (type: SubscriptionFilterType) => void,
    setSearchRequestModel: (model: SearchRequestModel | null) => void
}

const SearchHeader = ({
    userInfo,
    checkIsTrial,
    signOut,
    engineOption,
    setEngineOption,
    setTableData,
    setShowSaved,
    expandedOptions,
    searchInProgress,
    setSearchInProgress,
    searchTitle,
    setSearchTitle,
    pageNumber,
    setPageNumber,
    perPageCount,
    setPerPageCount,
    totalCount,
    setTotalCount,
    subSearchTerm,
    setSubSearchTerm,
    sortProps,
    setSortProps,
    subscriptionFilter,
    setSubscriptionFilter,
    setSearchRequestModel
}: SearchHeaderProps) => {

    const membershipIndexTypes = {
        none: 'None',
        majorUS: 'MajorUS',
        gfdForeign: 'GFDForeign'
    }

    const membershipTypes = {
        current: 'Current',
        historical: 'Historical',
        asOfDate: 'AsOfDate'
    }

    const MembershipDateFormat = 'YYYY-MM-DD'

    const { searchSection } = useParams()

    const { engine } = useParams()
    const navigate = useNavigate()

    const [lastSearchTerm, setLastSearchTerm] = useState<string>('')
    const [searchRequest, setSearchRequest] = useState<any>()

    const [countryGroup, setCountryGroup] = useState<string>(ALLCountryGroupEntry.value)
    const [country, setCountry] = useState<string>('')
    const [seriesCategory, setSeriesCategory] = useState<string>('')
    const [seriesType, setSeriesType] = useState<string>('')
    const [mainIndicator, setMainIndicator] = useState<string>('')
    const [startYear, setStartYear] = useState<number | null>()
    const [endYear, setEndYear] = useState<number | null>()
    const [datesValid, setDatesValid] = useState<boolean>(true)
    const [isOnlyInYearsRange, setIsOnlyInYearsRange] = useState<boolean>(false)

    const [subDatabase, setSubDatabase] = useState<string>('')

    const [securitiesCategory, setSecuritiesCategory] = useState<string>('')
    const [equityType, setEquityType] = useState<string>('')
    const [exchange, setExchange] = useState<string>('')
    const [sector, setSector] = useState<string>('')
    const [industry, setIndustry] = useState<string>('')
    const [searchField, setSearchField] = useState<SearchFieldType>(SearchFieldType.Symbol)

    const [membershipType, setMembershipType] = useState<string>(membershipTypes.current)
    const [date, setDate] = useState<moment.Moment | null>(null)
    const [category, setCategory] = useState<string>('')
    const [subIndex, setSubIndex] = useState<string>('')
    const [index, setIndex] = useState<string>('')

    const [eventType, setEventType] = useState<string>('')
    const [topic, setTopic] = useState<string>('')

    const [filterOption, setFilterOption] = useState<string>('contains')
    const [sortOption, setSortOption] = useState<string>('popular')

    const [searchesCommitted, setSearchesCommitted] = useState<boolean>(false)
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()

    const clearOptions = () => {
        setLastSearchTerm('')
        setCategory('')
        setCountry('')
        setCountryGroup(ALLCountryGroupEntry.value)
        setSeriesCategory('')
        setSeriesType('')
        setMainIndicator('')
        setStartYear(null)
        setEndYear(null)
        setDatesValid(true)
        setSubDatabase('')
        setSecuritiesCategory('')
        setEquityType('')
        setExchange('')
        setSector('')
        setIndustry('')
        setSearchField(SearchFieldType.Symbol)
        setMembershipType('')
        setDate(null)
        setIndex('')
        setSubIndex('')
        setEventType('')
        setTopic('')
        setSubscriptionFilter(SubscriptionFilterType.All)
        setSortProps({ sortField: SortFieldType.None, sortOrder: SortOrderType.None })
    }

    const GFDatabaseData2Point0 = {
        country: country,
        setCountry: setCountry,
        seriesCategory: seriesCategory,
        setSeriesCategory: setSeriesCategory,
        seriesType: seriesType,
        setSeriesType: setSeriesType,
        mainIndicator: mainIndicator,
        setMainIndicator: setMainIndicator,
        startYear: startYear,
        setStartYear: setStartYear,
        endYear: endYear,
        setEndYear: setEndYear,
        setDatesValid: setDatesValid,
        countryGroup: countryGroup,
        setCountryGroup: setCountryGroup,
        isOnlyInYearsRange: isOnlyInYearsRange,
        setIsOnlyInYearsRange: setIsOnlyInYearsRange
    }

    const GFDatabaseData = {
        country: country,
        setCountry: setCountry,
        seriesCategory: seriesCategory,
        setSeriesCategory: setSeriesCategory,
        seriesType: seriesType,
        setSeriesType: setSeriesType,
        mainIndicator: mainIndicator,
        setMainIndicator: setMainIndicator,
        startYear: startYear,
        setStartYear: setStartYear,
        endYear: endYear,
        setEndYear: setEndYear,
        setDatesValid: setDatesValid,
        countryGroup: countryGroup,
        setCountryGroup: setCountryGroup,
        isOnlyInYearsRange: isOnlyInYearsRange,
        setIsOnlyInYearsRange: setIsOnlyInYearsRange
    }

    const GFDIndicesData = {
        country: country,
        setCountry: setCountry,
        seriesType: seriesType,
        setSeriesType: setSeriesType,
        sector: sector,
        setSector: setSector,
        startYear: startYear,
        setStartYear: setStartYear,
        endYear: endYear,
        setEndYear: setEndYear,
        setDatesValid: setDatesValid,
        countryGroup: countryGroup,
        setCountryGroup: setCountryGroup,
        isOnlyInYearsRange: isOnlyInYearsRange,
        setIsOnlyInYearsRange: setIsOnlyInYearsRange
    }

    const GFDUniverseData = {
        country: country,
        setCountry: setCountry,
        seriesCategory: seriesCategory,
        setSeriesCategory: setSeriesCategory,
        seriesType: seriesType,
        setSeriesType: setSeriesType,
        mainIndicator: mainIndicator,
        setMainIndicator: setMainIndicator,
        startYear: startYear,
        setStartYear: setStartYear,
        endYear: endYear,
        setEndYear: setEndYear,
        setDatesValid: setDatesValid,
        countryGroup: countryGroup,
        setCountryGroup: setCountryGroup,
        isOnlyInYearsRange: isOnlyInYearsRange,
        setIsOnlyInYearsRange: setIsOnlyInYearsRange
    }

    const realEstateData = {
        subDatabase: subDatabase,
        setSubDatabase: setSubDatabase,
        startYear: startYear,
        setStartYear: setStartYear,
        endYear: endYear,
        setEndYear: setEndYear,
        setDatesValid: setDatesValid,
        countryGroup: countryGroup,
        setCountryGroup: setCountryGroup,
        country: country,
        setCountry: setCountry,
        isOnlyInYearsRange: isOnlyInYearsRange,
        setIsOnlyInYearsRange: setIsOnlyInYearsRange
    }

    const stocksDataUS = {
        country: country,
        setCountry: setCountry,
        securitiesCategory: securitiesCategory,
        setSecuritiesCategory: setSecuritiesCategory,
        equityType: equityType,
        setEquityType: setEquityType,
        exchange: exchange,
        setExchange: setExchange,
        sector: sector,
        setSector: setSector,
        industry: industry,
        setIndustry: setIndustry,
        searchField: searchField,
        setSearchField: setSearchField,
        startYear: startYear,
        setStartYear: setStartYear,
        endYear: endYear,
        setEndYear: setEndYear,
        setDatesValid: setDatesValid,
        countryGroup: countryGroup,
        setCountryGroup: setCountryGroup,
        isOnlyInYearsRange: isOnlyInYearsRange,
        setIsOnlyInYearsRange: setIsOnlyInYearsRange
    }

    const stocksDataUK = {
        country: country,
        setCountry: setCountry,
        securitiesCategory: securitiesCategory,
        setSecuritiesCategory: setSecuritiesCategory,
        equityType: equityType,
        setEquityType: setEquityType,
        exchange: exchange,
        setExchange: setExchange,
        sector: sector,
        setSector: setSector,
        searchField: searchField,
        setSearchField: setSearchField,
        industry: industry,
        setIndustry: setIndustry,
        startYear: startYear,
        setStartYear: setStartYear,
        endYear: endYear,
        setEndYear: setEndYear,
        setDatesValid: setDatesValid,
        countryGroup: countryGroup,
        setCountryGroup: setCountryGroup,
        isOnlyInYearsRange: isOnlyInYearsRange,
        setIsOnlyInYearsRange: setIsOnlyInYearsRange
    }

    const constituentMembershipData = {
        indexType: category || membershipIndexTypes.none,
        index: index,
        setIndex: setIndex,
        subIndex: subIndex,
        setSubIndex: setSubIndex,
        membership: membershipType,
        setMembershipType: setMembershipType,
        date: date,
        setDate: setDate,
        category: category,
        setCategory: setCategory
    }

    const eventsInTimeData = {
        country: country,
        setCountry: setCountry,
        eventType: eventType,
        setEventType: setEventType,
        topic: topic,
        setTopic: setTopic,
        startYear: startYear,
        setStartYear: setStartYear,
        endYear: endYear,
        setEndYear: setEndYear,
        setDatesValid: setDatesValid
    }

    const searchEngineOptions = {
        GFDatabaseData2Point0: GFDatabaseData2Point0,
        GFDatabaseData: GFDatabaseData,
        GFDIndicesData: GFDIndicesData,
        GFDUniverseData: GFDUniverseData,
        realEstateData: realEstateData,
        stocksDataUS: stocksDataUS,
        stocksDataUK: stocksDataUK,
        constituentMembershipData: constituentMembershipData,
        eventsInTimeData: eventsInTimeData
    }

    const sideBarSearch = {
        searchGFDatabase2Point0: (request: GFDatabaseRequest) => processRequest({ request, requestType: SearchDatabaseTypes.GFDatabase2Point0 } as SearchRequestModel),
        searchGFDatabase: (request: GFDatabaseRequest) => processRequest({ request, requestType: SearchDatabaseTypes.GFDatabase } as SearchRequestModel),
        searchGFDIndices: (request: GFDIndicesRequest) => processRequest({ request, requestType: SearchDatabaseTypes.GFDIndices } as SearchRequestModel),
        searchRealEstateDatabase: (request: RealEstateDatabaseRequest) => processRequest({ request, requestType: SearchDatabaseTypes.RealEstate } as SearchRequestModel),
        searchUSStocks: (request: USStocksRequest) => processRequest({ request, requestType: SearchDatabaseTypes.USStocks } as SearchRequestModel),
        searchUKStocks: (request: UKStocksRequest) => processRequest({ request, requestType: SearchDatabaseTypes.UKStocks } as SearchRequestModel),
        searchUniverse: (request: GFDUniverseRequest) => processRequest({ request, requestType: SearchDatabaseTypes.GFDUniverse } as SearchRequestModel),
        searchConstituentMembership: (request: ConstituentMembershipRequest) => processRequest({ request, requestType: SearchDatabaseTypes.ConstituentMembership } as SearchRequestModel),
        searchEventsInTime: (request: EventsInTimeRequest) => processRequest({ request, requestType: SearchDatabaseTypes.EventsInTime } as SearchRequestModel)
    }

    const doGFDatabase2Point0Search = (searchString: string) => {
        const req: GFDatabaseRequest = {
            searchField: SearchFieldType.All,
            searchOptions: {
                fields: 'string',
                matchOption: filterOption,
                sortOption: sortOption,
            },
            searchFilters: {
                country: country,
                group: countryGroup,
                seriesCategory: seriesCategory,
                seriesType: seriesType,
                mainIndicator: mainIndicator,
                startYear: startYear,
                endYear: endYear,
                isOnlyInYearsRange: isOnlyInYearsRange
            },
            searchTerm: searchString
        }
        sideBarSearch.searchGFDatabase2Point0(req)
    }

    const doGFDatabaseSearch = (searchString: string) => {
        const req: GFDatabaseRequest = {
            searchField: SearchFieldType.All,
            searchOptions: {
                fields: 'string',
                matchOption: filterOption,
                sortOption: sortOption,
            },
            searchFilters: {
                country: country,
                group: countryGroup,
                seriesCategory: seriesCategory,
                seriesType: seriesType,
                mainIndicator: mainIndicator,
                startYear: startYear,
                endYear: endYear,
                isOnlyInYearsRange: isOnlyInYearsRange
            },
            searchTerm: searchString
        }
        sideBarSearch.searchGFDatabase(req)
    }

    const doGFDIndicesSearch = (searchString: string) => {
        const req: GFDIndicesRequest = {
            searchField: SearchFieldType.All,
            searchOptions: {
                fields: 'string',
                matchOption: filterOption,
                sortOption: sortOption,
                limitOption: '',
            },
            searchFilters: {
                country: country,
                group: countryGroup,
                seriesType: seriesType,
                sector: sector,
                startYear: startYear,
                endYear: endYear,
                isOnlyInYearsRange: isOnlyInYearsRange
            },
            searchTerm: searchString
        }
        sideBarSearch.searchGFDIndices(req)
    }

    const doGFDUniverseSearch = (searchString: string) => {
        const req: GFDUniverseRequest = {
            searchField: SearchFieldType.All,
            searchOptions: {
                fields: 'string',
                matchOption: filterOption,
                sortOption: sortOption,
                limitOption: '',
            },
            searchFilters: {
                country: country,
                group: countryGroup,
                seriesCategory: seriesCategory,
                seriesType: seriesType,
                mainIndicator: mainIndicator,
                startYear: startYear,
                endYear: endYear,
                isOnlyInYearsRange: isOnlyInYearsRange
            },
            searchTerm: searchString
        }
        sideBarSearch.searchUniverse(req)
    }

    const doRealEstateSearch = (searchString: string) => {
        const req: RealEstateDatabaseRequest = {
            searchField: SearchFieldType.All,
            searchOptions: {
                fields: 'string',
                matchOption: filterOption,
                sortOption: sortOption,

            },
            searchFilters: {
                subDatabase: subDatabase,
                country: country,
                group: countryGroup,
                startYear: startYear,
                endYear: endYear,
                isOnlyInYearsRange: isOnlyInYearsRange
            },

            searchTerm: searchString
        }
        sideBarSearch.searchRealEstateDatabase(req)
    }

    const doUSStocksSearch = (searchString: string) => {
        const req: USStocksRequest = {
            searchField: searchField,
            searchTerm: searchString,
            searchFilters: {
                country: country,
                group: countryGroup,
                securitiesCategory: securitiesCategory,
                equityType: equityType,
                exchange: exchange,
                sector: sector,
                industry: industry,
                startYear: startYear,
                endYear: endYear,
                isOnlyInYearsRange: isOnlyInYearsRange
            },
            searchOptions: {
                fields: 'string',
                matchOption: filterOption,
                sortOption: sortOption,
                limitOption: '',
            }
        }
        sideBarSearch.searchUSStocks(req)
    }

    const doUKStocksSearch = (searchString: string) => {
        const req: UKStocksRequest = {
            searchField: searchField,
            searchTerm: searchString,
            searchFilters: {
                country: country,
                group: countryGroup,
                securitiesCategory: securitiesCategory,
                equityType: equityType,
                exchange: exchange,
                sector: sector,
                industry: industry,
                startYear: startYear,
                endYear: endYear,
                isOnlyInYearsRange: isOnlyInYearsRange
            },
            searchOptions: {
                fields: 'string',
                matchOption: filterOption,
                sortOption: sortOption,
                limitOption: '',
            }
        }
        sideBarSearch.searchUKStocks(req)
    }

    const doConstituentMembershipSearch = () => {
        let dateFilter = {}
        if (membershipType === membershipTypes.asOfDate) {
            dateFilter = {
                date: date?.format(MembershipDateFormat).toString()
            }
        }

        const req: ConstituentMembershipRequest = {
            searchFilters: {
                indexType: category || membershipIndexTypes.none,
                index: subIndex || index,
                membership: membershipType,
                ...dateFilter
            },
            limit: 'string'
        }
        sideBarSearch.searchConstituentMembership(req)
    }

    const doEventsInTimeSearch = (searchTerm: string) => {
        const req: EventsInTimeRequest = {
            searchFilters: {
                keyword: searchTerm,
                eventType: eventType,
                topic: topic,
                startYear: startYear,
                endYear: endYear,
                country: country
            },
            limit: 'string'
        }
        sideBarSearch.searchEventsInTime(req)
    }

    const _searchQuik = (searchTerm: string | null) => {
        let searchString = searchTerm ?? ''

        setLastSearchTerm(searchString)

        switch (engineOption) {
            case SearchDatabaseTypes.GFDatabase2Point0:
                doGFDatabase2Point0Search(searchString)
                break
            case SearchDatabaseTypes.GFDatabase:
                doGFDatabaseSearch(searchString)
                break
            case SearchDatabaseTypes.GFDIndices:
                doGFDIndicesSearch(searchString)
                break
            case SearchDatabaseTypes.GFDUniverse:
                doGFDUniverseSearch(searchString)
                break
            case SearchDatabaseTypes.RealEstate:
                doRealEstateSearch(searchString)
                break
            case SearchDatabaseTypes.USStocks:
                doUSStocksSearch(searchString)
                break
            case SearchDatabaseTypes.UKStocks:
                doUKStocksSearch(searchString)
                break
            case SearchDatabaseTypes.ConstituentMembership:
                doConstituentMembershipSearch()
                break
            case SearchDatabaseTypes.EventsInTime:
                doEventsInTimeSearch(searchString)
                break
        }
    }

    const processErrorResponse = (notOKResponseModel: NotOKResponseModel) => {
        setSearchInProgress(false)
        if (notOKResponseModel?.statusCode !== 499) {
            setErrorResponse(notOKResponseModel)
        }
    }

    const _searchGFDatabase2Point0 = (searchRequest: GFDatabaseRequest) => {
        searchGFDatabase2Point0(searchRequest).then(
            (res) => {
                processResponse(res)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _searchGFDatabase = (searchRequest: GFDatabaseRequest) => {
        searchGFDatabase(searchRequest).then(
            (res) => {
                processResponse(res)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _searchGFDIndices = (searchRequest: GFDatabaseRequest) => {
        searchGFDIndices(searchRequest).then(
            (res) => {
                processResponse(res)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _searchRealEstateDatabase = (searchRequest: GFDatabaseRequest) => {
        searchRealEstateDatabase(searchRequest).then(
            (res) => {
                processResponse(res)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _searchUSStocks = (searchRequest: GFDatabaseRequest) => {
        searchUSStocks(searchRequest).then(
            (res) => {
                processResponse(res)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _searchUKStocks = (searchRequest: GFDatabaseRequest) => {
        searchUKStocks(searchRequest).then(
            (res) => {
                processResponse(res)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _searchUniverse = (searchRequest: GFDatabaseRequest) => {
        searchUniverse(searchRequest).then(
            (res) => {
                processResponse(res)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _searchConstituentMembership = (searchRequest: ConstituentMembershipRequest) => {
        searchConstituentMembership(searchRequest).then(
            (res) => {
                processResponse(res)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _searchEventsInTime = (searchRequest: EventsInTimeRequest) => {
        searchEventsInTime(searchRequest).then(
            (res) => {
                processResponse(res)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const resetSearch = () => {
        setSearchRequest('')
        setTableData(emptyTableData)
        setSearchTitle('')
        setTotalCount(0)
    }

    const tickerSearchProps = {
        currentEngine: engineOption,
        searchEngineOptions: searchEngineOptions,
        setLastSearchTerm,
        filterAndSort: {
            filterOption: filterOption,
            setFilterOption: setFilterOption,
            sortOption: sortOption,
            setSortOption: setSortOption
        }
    }

    const processResponse = (response: SearchResponse) => {

        if (response?.pagination) {
            if (pageNumber !== response.pagination.pageNumber) setPageNumber(response.pagination.pageNumber)
            if (perPageCount !== response.pagination.perPageCount) setPerPageCount(response.pagination.perPageCount)

            if (response.pagination.totalCount || response.pagination.totalCount === 0) {
                setTotalCount(response.pagination.totalCount)
            }
        }

        const responseSortOrderType: SortOrderType = response?.sortOrder as SortOrderType || SortOrderType.None
        const responseSortFieldType: SortFieldType = response?.sortField as SortFieldType || SortFieldType.None

        if (subSearchTerm !== response?.subSearchTerm || '') setSubSearchTerm(response?.subSearchTerm || '')
        if (sortProps.sortOrder !== responseSortOrderType) sortProps.sortOrder = responseSortOrderType
        if (sortProps.sortField !== responseSortFieldType) sortProps.sortField = responseSortFieldType

        response?.masterlist?.forEach((entry: any) => {
            if (entry.startDate) entry.startDate = moment(entry.startDate, 'M/DD/yyyy').utc().format()
            if (entry.endDate) entry.endDate = moment(entry.endDate, 'M/DD/yyyy').utc().format()
        })

        setTableData(response)
        setSearchInProgress(false)
    }

    const processRequest = (requestModel: SearchRequestModel) => {
        if (searchInProgress) return

        if (requestModel && requestModel?.request && requestModel?.requestType !== SearchDatabaseTypes.Unknown) {
            setSearchRequest(requestModel.request)

            doSearch(requestModel, true, true)
        } else {
            resetSearch()
        }
    }

    const getPaginationRequest = (resetPageNumber: boolean): PaginationRequest => {
        let page = pageNumber
        if (!page || page <= 0) {
            page = 1
        }

        let perPage = perPageCount
        if (!perPage) {
            perPage = 100
        }

        return {
            pageNumber: resetPageNumber ? 1 : page,
            perPageCount: perPage,
            isTotalCountRequested: resetPageNumber
        } as PaginationRequest
    }

    const doSearch = (requestModel: SearchRequestModel, resetPageNumber: boolean, resetData: boolean) => {
        setSearchRequestModel(requestModel)

        const request = {
            ...requestModel.request,
            pagination: getPaginationRequest(resetPageNumber),
            filterResultsBySubSearchTerm: resetData ? '' : subSearchTerm,
            sortOrder: sortProps.sortOrder,
            sortField: sortProps.sortField,
            subscriptionFilter: resetData ? SubscriptionFilterType.All : subscriptionFilter
        }

        setSearchInProgress(true)

        if (resetData) {
            setTableData(emptyTableData)
            setSubSearchTerm('')
            setSubscriptionFilter(SubscriptionFilterType.All)
        }

        const title = getTitle(requestModel.requestType)
        setSearchTitle(title)

        switch (requestModel.requestType) {
            case SearchDatabaseTypes.ConstituentMembership:
                _searchConstituentMembership(request as ConstituentMembershipRequest)
                break
            case SearchDatabaseTypes.EventsInTime:
                _searchEventsInTime(request as EventsInTimeRequest)
                break
            case SearchDatabaseTypes.GFDatabase2Point0:
                _searchGFDatabase2Point0(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.GFDatabase:
                _searchGFDatabase(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.GFDIndices:
                _searchGFDIndices(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.RealEstate:
                _searchRealEstateDatabase(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.UKStocks:
                _searchUKStocks(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.USStocks:
                _searchUSStocks(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.GFDUniverse:
                _searchUniverse(request as GFDatabaseRequest)
                break
            default: // should not hit this
                setSearchInProgress(false)
                break
        }
    }

    const onSearchClick = (searchValue: string) => {
        setLastSearchTerm(searchValue)
        setSearchesCommitted(true)
        engineBasedSearchInProgress = true
    }

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    useEffect(() => {
        if (searchSection) {
            if (searchSection === 'selectedResults') {
                setShowSaved(true)
            } else {
                setShowSaved(false)
            }
        }
    }, [])

    const fireSearch = (resetPageNumber: boolean) => {
        if (searchInProgress || !searchRequest || !engineOption || engineBasedSearchInProgress) return

        doSearch({
            request: searchRequest,
            requestType: engineOption
        } as SearchRequestModel,
            resetPageNumber,
            false)
    }

    useEffect(() => {
        fireSearch(false)
    }, [sortProps, pageNumber])

    useEffect(() => {
        if (pageNumber !== 1) {
            setPageNumber(1)
            return
        }
        fireSearch(true)
    }, [perPageCount])

    useEffect(() => {
        fireSearch(true)
    }, [subSearchTerm, subscriptionFilter])

    useEffect(() => {
        setSearchesCommitted(true)
        engineBasedSearchInProgress = true
    }, [filterOption, sortOption, country, countryGroup,
        endYear, equityType, exchange, mainIndicator,
        seriesCategory, seriesType, securitiesCategory, subDatabase, startYear, eventType, topic, date, index, subIndex, membershipType, industry, isOnlyInYearsRange])

    useEffect(() => {
        if (industry?.length === 0) {
            setSearchesCommitted(true)
        }
    }, [sector])

    useEffect(() => {
        clearOptions()
        setSearchesCommitted(true)
        engineBasedSearchInProgress = true
    }, [engineOption])

    useEffect(() => {
        if (searchesCommitted) {
            if (datesValid) {
                _searchQuik(lastSearchTerm)
            } else {
                GFDToastError('Fix all errors!')
            }
            setSearchesCommitted(false)
            engineBasedSearchInProgress = false
        }
    }, [searchesCommitted])

    return (
        <div style={{ paddingBottom: '5px' }}>
            <ErrorHandler {...errorHandlerProps} />
            <Grid container columns={17} style={searchStyles.grid}>
                <Grid item md={3} style={searchStyles.grid}>
                    <SelectEngine userInfo={userInfo} engineOption={engineOption} setEngineOption={setEngineOption} checkIsTrial={checkIsTrial} clearOptions={clearOptions} />
                </Grid>
                <Grid item md={1} style={searchStyles.grid}>
                </Grid>
                <Grid item md={13} style={searchStyles.grid}>
                    <TickerHomeSearch onSearchClick={onSearchClick} tickerSearchProps={tickerSearchProps} engineOption={engineOption} checkIsTrial={checkIsTrial} />
                </Grid>
            </Grid>
            <Box style={searchStyles.grid}>
                <SearchEngine engineOption={engineOption} searchEngineOptions={searchEngineOptions} searchTitle={searchTitle} expandedOptions={expandedOptions} />
            </Box>
        </div>
    )
}

const searchStyles = {
    grid: {
        marginTop: '5px'
    }
}

export default SearchHeader
