import { CircularProgress, Divider, IconButton, MenuItem, TextField, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { CSSProperties, useEffect, useState } from 'react'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import DefaultMaterialTheme from '../../Common/GlobalSettings/DefaultMaterialTheme'
import MaterialTable from '@material-table/core'
import MaterialTableOptions from '../../Common/GlobalSettings/MaterialTableOptions'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { LoginResponse } from '../../../Models/DataModels/Responses/AuthResponses'
import { AccessCacheQueueFields, AccessCacheQueueListResponse, AccessCacheQueueRequest, AccessCacheQueueSortFieldEnum, AccessCacheQueueSortFields, AccessCacheQueueSortProps, AccessCacheType, AccessCacheUpdateQueue } from '../../../Models/DataModels/Common/AccessCacheModel'
import { AccessCacheQueueAction, accessCacheQueueService } from '../../../Services/AccessCacheService'
import { SortOrderString, SortOrderType } from '../../../Models/DataModels/Common/SortModel'
import RefreshDownloadQueue from '../../Icons/RefreshDownloadQueueIcon'
import GFDTablePagination, { GFDTablePaginationProps } from '../../Common/Utility/GFDTablePagination'
import { CustomBtnBlue, CustomBtnCloseCancel } from '../../Common/GlobalSettings/CustomStyles'
import EnhancedTableHead from '../../Common/Utility/EnhancedTableHead'

export interface AccessCacheQueueProps {
  login: (loginResponse: LoginResponse) => void,
  signOut: (logoutReason: LogoutReasonType) => void
}

const AccessCacheQueue = ({
  login,
  signOut
}: AccessCacheQueueProps) => {
  const [items, setItems] = useState<AccessCacheUpdateQueue[]>([])
  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
  const [listInProgress, setListInProgress] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [perPageCount, setPerPageCount] = useState(100)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [sortData, setSortData] = useState<AccessCacheQueueSortProps>({ 
    sortField: AccessCacheQueueSortFieldEnum.None, 
    sortOrder: SortOrderType.None 
  })

  const [cacheType, setCacheType] = useState<AccessCacheType>(AccessCacheType.All)
  const [cacheID, setCacheID] = useState<number>(0)
  const [createMode, setCreateMode] = useState<boolean>(false)
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false)
  const [inputError, setInputError] = useState<string>('')
  
  const getListRequest = (resetPageNumber: boolean, requestTotalCount: boolean): AccessCacheQueueRequest => {
    return {
      pagination: {
        perPageCount: !perPageCount || perPageCount <= 0 ? 100 : perPageCount,
        pageNumber: !pageNumber || pageNumber <= 0 || resetPageNumber ? 1 : pageNumber,
        isTotalCountRequested: requestTotalCount
      },
      sortField: sortData.sortField,
      sortOrder: sortData.sortOrder
    }
  }

  useEffect(() => {
    list(true, true)
  }, [])

  useEffect(() => {
    list(false, false)
  }, [pageNumber, sortData])

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1)
      return
    }
    list(true, false)
  }, [perPageCount])

  useEffect(() => {
    if (cacheType === AccessCacheType.All) setCacheID(0)
  }, [cacheType])

  const clearAlert = () => {
    setInputError('')
  }

  const list = (resetPageNumber: boolean, requestTotalCount: boolean) => {
    if (listInProgress) return

    const listRequest: AccessCacheQueueRequest = getListRequest(resetPageNumber, requestTotalCount)

    setListInProgress(true)

    accessCacheQueueService(listRequest, AccessCacheQueueAction.list)
      .then((result: any) => {
        const response = result as AccessCacheQueueListResponse

        setItems(() => response.accessCacheQueueItems)

        if (response.pagination) {
          if (pageNumber !== response.pagination.pageNumber) {
            setPageNumber(response.pagination.pageNumber)
          }
          if (perPageCount !== response.pagination.perPageCount) {
            setPerPageCount(response.pagination.perPageCount)
          }
          if (response.pagination.totalCount || response.pagination.totalCount === 0) {
            setTotalCount(response.pagination.totalCount)
          }
        }

        setListInProgress(false)
      },
      (notOKResponseModel: NotOKResponseModel) => {
        setListInProgress(false)
        setErrorResponse(notOKResponseModel)
      })
  }

  const columns: any[] = [
    { title: 'ID', field: 'queueID', sorting: true, type: 'numeric', sortField: AccessCacheQueueSortFieldEnum.QueueID },
    { title: 'Cache Type', field: 'cacheType', sorting: true, type: 'string', sortField: AccessCacheQueueSortFieldEnum.CacheType },
    { title: 'Cache ID', field: 'cacheID', sorting: true, type: 'numeric', sortField: AccessCacheQueueSortFieldEnum.CacheID },
    { title: 'Status', field: 'status', sorting: true, type: 'string', sortField: AccessCacheQueueSortFieldEnum.Status },
    { title: 'Updated', field: 'updated', sorting: true, type: 'datetime', sortField: AccessCacheQueueSortFieldEnum.Updated },
    { title: 'Updater', field: 'updater', sorting: true, type: 'string', sortField: AccessCacheQueueSortFieldEnum.Updater },
    { title: 'Created', field: 'created', sorting: true, type: 'datetime', sortField: AccessCacheQueueSortFieldEnum.Created },
    { title: 'Creator', field: 'creator', sorting: true, type: 'string', sortField: AccessCacheQueueSortFieldEnum.Creator }
  ]

  const getDefaultSort = (column: string) => {
    if (AccessCacheQueueFields.includes(column) &&
      sortData.sortField === AccessCacheQueueSortFields[column]) {
        return SortOrderString[sortData.sortOrder]
    }
    return undefined
  }

  const onSort = (columnNumber: number) => {
    const props: AccessCacheQueueSortProps = {
      sortField: sortData.sortField,
      sortOrder: sortData.sortOrder
    }

    try {
      const column = columns[columnNumber]
      const columnName = column.field
      const field: AccessCacheQueueSortFieldEnum = AccessCacheQueueSortFields[columnName] || AccessCacheQueueSortFieldEnum.None

      if (field !== props.sortField) {
        props.sortField = field

        if (column.defaultSort === 'asc') {
          props.sortOrder = SortOrderType.Descending
        } else if (column.defaultSort === 'desc') {
          props.sortField = AccessCacheQueueSortFieldEnum.None
          props.sortOrder = SortOrderType.None
        } else {
          props.sortOrder = SortOrderType.Ascending
        }
      } else {
        if (props.sortOrder === SortOrderType.Ascending) {
          props.sortOrder = SortOrderType.Descending
        } else if (props.sortOrder === SortOrderType.Descending) {
          props.sortField = AccessCacheQueueSortFieldEnum.None
          props.sortOrder = SortOrderType.None
        } else {
          props.sortOrder = SortOrderType.Ascending
        }
      }

      setSortData(props)
    }
    catch (e) {
      console.log('Exception while sorting: ', e)
    }
  }

  const renderTitle = () => {
    const title = 'Access Cache Queue'
  
    return (<>
      {title}
      <IconButton title='Refresh in Progress'
        aria-label='Refresh in Progress' component='label' sx={{ 
        p: '10px',
        color: '#1976d2',
        visibility: listInProgress ? 'visible' : 'hidden'}}>
        <CircularProgress title='Refresh in Progress' aria-label='Refresh in Progress'/>
      </IconButton>
      <IconButton sx={{ 
          visibility: listInProgress ? 'hidden' : 'visible'}}
          title='Refresh List' aria-label='Refresh List' component='label' 
          onClick={() => {
            list(false, true)
          }}>
        <RefreshDownloadQueue style={{fontSize: '1.2em'}}></RefreshDownloadQueue>
      </IconButton>
    </>)
  }

  const pageData: GFDTablePaginationProps = {
    perPageCount: perPageCount,
    pageNumber: pageNumber,
    totalCount: totalCount,
    setPageNumber: setPageNumber,
    setPerPageCount: setPerPageCount
  }

  const renderCacheTypes = () => {
    return Object.values(AccessCacheType).map(
      value => <MenuItem key={value} value={value}>{value}</MenuItem>
    )
  }

  const handleCacheTypeChange = (
    event: any
  ) => {
    clearAlert()
    setCacheType(event.target.value as AccessCacheType || AccessCacheType.All)
  }

  const handleCacheIDChange = (
    event: any
  ) => {
    clearAlert()
    const value = event.target.value
    if (cacheType !== AccessCacheType.All && value && value >= 0) {
      setCacheID(value)
    } else {
      setCacheID(0)
    }
  }

  const saveEntry = () => {
    clearAlert()
    if (saveInProgress) return

    if (cacheType !== AccessCacheType.All && cacheID <= 0) {
      setInputError('Please enter a Cache ID')
      return
    }

    const saveRequest: AccessCacheQueueRequest = {
      cacheID,
      cacheType
    }

    setSaveInProgress(true)

    accessCacheQueueService(saveRequest, AccessCacheQueueAction.create)
      .then((result: any) => {
        list(true, true)
        resetEntry()
        setSaveInProgress(false)
      },
      (notOKResponseModel: NotOKResponseModel) => {
        setSaveInProgress(false)
        setErrorResponse(notOKResponseModel)
      })
  }

  const resetEntry = () => {
    clearAlert()
    setCacheType(AccessCacheType.All)
    setCacheID(0)
    setCreateMode(false)
  }

  const readOnlyProp: CSSProperties = (saveInProgress ?? false) ? { pointerEvents: 'none'} : {}

  const renderEditor = () => {
    return createMode ? 
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', ...readOnlyProp }}>
        <div style={{ overflow: 'auto', display: 'flex', flex: '0 0 200px', margin: 8 }}>
          <TextField
            sx={{ width: '100%'}}
            select
            id='cacheTypeInput'
            label='Cache Type'
            variant='standard'
            value={cacheType}
            onChange={handleCacheTypeChange}
            size='small'
          >
            {renderCacheTypes()}
          </TextField>
        </div>
        <div style={{ overflow: 'auto', display: 'flex', flex: '0 0 200px', margin: 8 }}>
          <TextField
              sx={{ width: '100%'}}
              id='cacheIDInput' 
              label='Cache ID'
              variant='standard'
              type='number'
              value={cacheID}
              onChange={handleCacheIDChange}
              size='small'
              error={inputError !== ''}
              helperText={inputError}
          />
        </div>
        <div style={{ overflow: 'auto', display: 'flex', flex: '0 0 1', alignItems: 'center', margin: 8 }}>
          <CustomBtnCloseCancel size='small' variant={"outlined"} onClick={resetEntry} >
            Cancel Entry
          </CustomBtnCloseCancel>
        </div>
        <div style={{ overflow: 'auto', display: 'flex', flex: '0 0 1', alignItems: 'center', margin: 8 }}>
          <CustomBtnBlue size='small' variant={"contained"} onClick={saveEntry} >
            Save Entry
          </CustomBtnBlue>
        </div>
        <div style={{ overflow: 'auto', display: saveInProgress ? 'flex' : 'none', flex: '0 0 1', alignItems: 'center', margin: 8 }}>
          <Tooltip title='Save in Progress'>
            <IconButton aria-label='Save in Progress' component='label' sx={{ 
              p: '10px',
              color: '#007ea8'
            }}>
              <CircularProgress aria-label='Save in Progress' />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      : <div style={{ marginBottom: 10, marginTop: 6 }}>
        <CustomBtnBlue variant={"contained"} onClick={() => setCreateMode(true)} >
          Create Access Cache Queue Entry
        </CustomBtnBlue>
      </div>
  }

  const renderTable = () => {
    return <div style={{ overflow: 'auto' }}>
      <ThemeProvider theme={DefaultMaterialTheme}>
        <MaterialTable
          columns={columns}
          data={items}
          title={renderTitle()}
          options={{
            ...MaterialTableOptions,
            paginationPosition: 'top',
            maxBodyHeight: '100%',
            selection: false
          }}
          components={{
            Pagination: props => (<GFDTablePagination {...pageData} />),
            Header:
              props => (
                <EnhancedTableHead { ...{ sortData: { getDefaultSort, onSort }, columns } } />
              )
          }}
        />
      </ThemeProvider>
    </div>
  }

  const errorHandlerProps: ErrorHandlerProps = {
    response: errorResponse,
    signOut: signOut
  }

  return (
    <div>
      <ErrorHandler {...errorHandlerProps}/>

      {renderEditor()}

      <Divider sx={{ borderColor: 'black', marginBottom: 5, width: '100%' }} />

      {renderTable()}
    </div>
  )
}

export default AccessCacheQueue
