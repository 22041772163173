import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../HelpPageStyles'
import { ImageNumberReference, ItalicizedWorkbookText, MarkedOrangeOrangeText, MarkedRedRedText, MarkedYellowYellowText, PurplePurpleText } from '../../HelpPageCommonComponents'
import DownLoad from '../../../Icons/DownLoad'

const BasicAutoTrac = () => {
    return (
        <>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Basic Workbooks and Download Queue</h2>
                </div>
            </Box>
            <p style={{ textAlign: 'left' }}>
                This section will describe the basic usage of AutoTrac feature.
                AutoTrac feature allows saving the list of series. This list of series is called a <ItalicizedWorkbookText />.
                The benefit of having the <ItalicizedWorkbookText /> (list of series) is that it makes it easy to download specific multiple series of data.
                Let's say that you need to download a set of series' data everyday. You can do this by searching for each series and then by downloading the data. Then, you can consolidate
                the data by putting all the data together into a text file or excel. However, this process can be very tedious and can take some time. By using AutoTrac, you can store a list
                of series into a <ItalicizedWorkbookText /> and download multiple series of data with the latest data with a click of a button.
            </p>
            <div style={{ height: '776px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_Intro.png' style={helpPageStyles.image} alt='AutoTrac Intro'></img>
            </div>
            <div style={{ textAlign: 'left' }}>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Creating a workbook</h4>
                    </div>
                </Box>
                <div style={{ height: '302px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_navigate.png' style={helpPageStyles.image} alt='Basic AutoTrac Navigation'></img>
                </div>
                <p>
                    After logging in, click on the 'AUTOTRAC' in the header. This will take you to the AutoTrac page.
                </p>
                <div style={{ height: '366px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_AddWorkbook.png' style={helpPageStyles.image} alt='Basic AutoTrac Add Workbook'></img>
                </div>
                <p>
                    The first step is to create a <ItalicizedWorkbookText />. Click on "Add Workbook" button. This will allow the name of the workbook to be entered.
                    Enter the name of the <ItalicizedWorkbookText />. After entering, click off of text field or hit the enter key. This will create a <ItalicizedWorkbookText />
                </p>
                <div style={{ height: '356px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_AddWorkbook_Success.png' style={helpPageStyles.image} alt='Basic AutoTrac Add Workbook Success'></img>
                </div>
                <p>
                    A success message will be displayed upon successful entry.
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Adding series into a workbook</h4>
                    </div>
                </Box>
                <div style={{ height: '303px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_AddSeries_Select.png' style={helpPageStyles.image} alt='Basic AutoTrac Add Series Select'></img>
                </div>
                <p>
                    Click on the name of the <ItalicizedWorkbookText /> you wish to add series to. Verify that the <ItalicizedWorkbookText /> name is present on the table header.
                </p>
                <div style={{ height: '377px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_AddSeries_AddTicker.png' style={helpPageStyles.image} alt='Basic AutoTrac Add Series Add Ticker'></img>
                </div>
                <p>
                    Similar to search feature, enter keywords in the "GFD Universe Quick Search" text box to search for series. Closest series will be displayed as you type. Select the series and click on "Add Ticker to Selected Workbook" button.
                </p>
                <div style={{ height: '417px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_AddSeries_AddTicker_Success.png' style={helpPageStyles.image} alt='Basic AutoTrac Add Series Add Ticker Success'></img>
                </div>
                <p>
                    Success message is displayed if addition was successful. Repeat this for all the series you wish to add to the <ItalicizedWorkbookText />.
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Workbook Download and Download Queue</h4>
                    </div>
                </Box>
                <div style={{ height: '426px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_Download.png' style={helpPageStyles.image} alt='Basic AutoTrac Download Buton'></img>
                </div>
                <p>
                    After entering all the series you wish to download, click on the download button. This will display the download options modal.
                </p>
                <div style={{ height: '1038px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_downloadOptions.png' style={helpPageStyles.image} alt='Basic AutoTrac Download Options'></img>
                </div>
                <p>
                    Click on "Download" button to queue the download with default options.
                    The details of Download Options will be described in Advanced AutoTrac section.
                </p>
                <div style={{ height: '286px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_status_init.png' style={helpPageStyles.image} alt='Basic AutoTrac Status Init'></img>
                </div>
                <p>
                    Download Queue section will be expanded upon clicking on the "Download" button. Newly created download will have the status of "Initialized".
                </p>
                <div style={{ height: '271px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_status_processing.png' style={helpPageStyles.image} alt='Basic AutoTrac Status Init'></img>
                </div>
                <p>
                    Once the download starts, download will have the status of "Processing".
                </p>
                <div style={{ height: '221px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_status_check.png' style={helpPageStyles.image} alt='Basic AutoTrac Status Check'></img>
                </div>
                <p>
                    Status will be checked periodically, but clicking on the refresh button will check the current status.
                </p>
                <div style={{ height: '278px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_status_Finished.png' style={helpPageStyles.image} alt='Basic AutoTrac Status Finished'></img>
                </div>
                <p>
                    Completed download will have the status of "Finished". You can now download the data file. Click on the name of <ItalicizedWorkbookText /> to download the data file.
                </p>
                <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                    Note: Workbook queue item with the status of "Finished" is available for 5 days. The entry will be removed after 5 days.
                    You must click on the <ItalicizedWorkbookText /> name link with 5 days to retrieve the data file.
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Power Downloads</h4>
                    </div>
                </Box>
                <div style={{ height: '201px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_powerDownloads.png' style={helpPageStyles.image} alt='Basic AutoTrac Power Downloads'></img>
                </div>
                <p>
                    Power Downloads allows downloading data file from the preset <ItalicizedWorkbookText />s containing a list of series.
                    Clicking on "GFD Power Downloads" accordian (in <MarkedRedRedText /> {ImageNumberReference(1)}) will expand the available power download <ItalicizedWorkbookText />s.
                    Left and Right arrows (in <MarkedOrangeOrangeText /> {ImageNumberReference(2)}) and the dots (in <MarkedYellowYellowText /> {ImageNumberReference(3)}) can be used to browse through the available <ItalicizedWorkbookText />s.
                </p>
                <div style={{ height: '222px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_powerDownloads_selected.png' style={helpPageStyles.image} alt='Basic AutoTrac Power Downloads Selected'></img>
                </div>
                <p>
                    Once you find something that you wish to download, click on the <ItalicizedWorkbookText /> in the list. This will queue the download.
                </p>
                <div style={{ height: '589px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_powerDownloads_clicked.png' style={helpPageStyles.image} alt='Basic AutoTrac Power Downloads Clicked'></img>
                </div>
                <p>
                    Data file can be downloaded once the status is "Finished".
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Direct Download and AutoTrac Download</h4>
                    </div>
                </Box>
                <div style={{ height: '362px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_DownloadTypes.png' style={helpPageStyles.image} alt='Basic AutoTrac Power Download Types'></img>
                </div>
                <p>
                    There are 2 types of downloads. <b>Direct Download</b> (also called streaming download) initiates the download as soon as you click on the download button.
                    This is applicable for downloading 1 series. <b>AutoTrac download</b> are downloads that are queued and processed in the backend. This is applicable for downloading more than 1 series.
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h5>Direct Download</h5>
                    </div>
                </Box>
                <div style={{ height: '776px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_DownloadTypes_Direct.png' style={helpPageStyles.image} alt='Basic AutoTrac Power Download Types Direct'></img>
                </div>
                <p>
                    Quick Action download is applicable for downloading 1 series. Click on Download button, optionally enter the Name, and click on Direct Download to download single series of data.
                    This download will start as you click on the download button.
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h5>AutoTrac Download</h5>
                    </div>
                </Box>
                <div style={{ height: '384px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_DownloadTypes_AutoTrac_Select.png' style={helpPageStyles.image} alt='Basic AutoTrac Power Download Types AutoTrac Select'></img>
                </div>
                <p>
                    Select multiple series you wish to download and click on the download button (<DownLoad />) on the toolbar.
                </p>
                <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                    Note: This is available in other areas such as selected results and financial tools results.
                </p>
                <div style={{ height: '933px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_DownloadTypes_AutoTrac_Download.png' style={helpPageStyles.image} alt='Basic AutoTrac Power Download Types AutoTrac Download'></img>
                </div>
                <p>
                    Verify that the series you selected appears in <PurplePurpleText /> ({ImageNumberReference(1)}). Then, enter the name of the download (<MarkedRedRedText /> box ({ImageNumberReference(2)})). The name of the download is required for AutoTrac download (or downloading multiple series).
                    After applying the download options, click on the "Enqueue Download" button (<MarkedOrangeOrangeText /> box ({ImageNumberReference(3)})).
                </p>
                <div style={{ height: '248px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracBasic_DownloadTypes_AutoTrac_Download_Queued.png' style={helpPageStyles.image} alt='Basic AutoTrac Power Download Types AutoTrac Download Queued'></img>
                </div>
                <p>
                    The download is now queued. All the queued downloads are available in the AutoTrac page. To retrieve the download, go to the AutoTrac page and expand the download queue to find and retrieve the download.
                    When the above modal appears, click on OK button to close the modal OR click on "Go to Download Queue" button to go to Download Queue section of AutoTrac page. If you queued a download with more than 50 series,
                    it is likely that the download will take some time to complete. For this case, you can just click on OK button to close the modal and continue what you're doing and check the Download Queue in the AutoTrac page later.
                    If you only downloaded only a few series, it is likely that the download will complete sooner. For this case, you can click on "Go to Download Queue" button to go directly to the Download Queue. It is likely that the download
                    is already complete or you may just need to wait a little bit to retrieve the download.
                </p>
            </div>
        </>
    )
}

export default BasicAutoTrac
