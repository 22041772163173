export const enum SortFieldType {
  None = 'None',
  Symbol = 'Symbol',
  Description = 'Description',
  Country = 'Country',
  SeriesType = 'SeriesType',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  Topics = 'Topics',
  DataCount = 'DataCount',
  WorksheetName = 'WorksheetName',
  CreateTime = 'CreateTime',
  StartTime = 'StartTime',
  FinishTime = 'FinishTime',
  FilePath = 'FilePath',
  QueueID = 'QueueID',
  Status = 'Status',
  Source = 'Source',
  TickerName = 'Symbol'
}

export const SortFieldNames = {
  Symbol: 'symbol',
  Description: 'description',
  Country: 'country',
  SeriesType: 'series',
  StartDate: 'startDate',
  EndDate: 'endDate',
  Topics: 'topics',
  DataCount: 'dataCount',
  WorksheetName: 'worksheetName',
  CreateTime: 'createTime',
  StartTime: 'startTime',
  FinishTime: 'finishTime',
  FilePath: 'filePath',
  QueueID: 'queueID',
  Status: 'status',
  Source: 'source',
  TickerName: 'tickerName'
}

export const SortFields: {[key: string]: SortFieldType } = {
  'symbol': SortFieldType.Symbol,
  'description': SortFieldType.Description,
  'country': SortFieldType.Country,
  'series': SortFieldType.SeriesType,
  'startDate': SortFieldType.StartDate,
  'endDate': SortFieldType.EndDate,
  'topics': SortFieldType.Topics,
  'dataCount': SortFieldType.DataCount,
  'worksheetName': SortFieldType.WorksheetName,
  'createTime': SortFieldType.CreateTime,
  'startTime': SortFieldType.StartTime,
  'finishTime': SortFieldType.FinishTime,
  'filePath': SortFieldType.FilePath,
  'queueID': SortFieldType.QueueID,
  'status': SortFieldType.Status,
  'source': SortFieldType.Source,
  'tickerName': SortFieldType.TickerName
}

export const SearchFields = Object.keys(SortFields)

export const enum SortOrderType {
  None = 'None',
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export type SortOrderStringType = 'asc' | 'desc' | undefined
export const SortOrderStringValue = {
  Ascending: 'asc' as SortOrderStringType,
  Descending: 'desc' as SortOrderStringType,
  Undefined: undefined as SortOrderStringType,
}

export const SortOrders: {[key: string]: SortOrderType } = {
  'asc': SortOrderType.Ascending,
  'desc': SortOrderType.Descending
}

export const SortOrderString = {
  [SortOrderType.None]: SortOrderStringValue.Undefined,
  [SortOrderType.Ascending]: SortOrderStringValue.Ascending,
  [SortOrderType.Descending]: SortOrderStringValue.Descending
}

export const enum CustomIndexSortFieldType {
  None,
  Symbol,
  Description,
  MemberCount,
  Weight,
  Periodicity,
  LastUpdatedDate,
  Status,
  SeriesBegin,
  SeriesEnd,
  ScreenReportID
}

export const CustomIndexSortFieldNames = {
  Symbol: 'symbol',
  Description: 'description',
  MemberCount: 'memberCount',
  Weight: 'weight',
  Periodicity: 'periodicity',
  LastUpdatedDate: 'lastUpdatedDate',
  Status: 'status',
  SeriesBegin: 'seriesBegin',
  SeriesEnd: 'seriesEnd',
  ScreenReportID: 'screenReportID'
}

export const CustomIndexSortFields: {[key: string]: CustomIndexSortFieldType } = {
  [CustomIndexSortFieldNames.Symbol]: CustomIndexSortFieldType.Symbol,
  [CustomIndexSortFieldNames.Description]: CustomIndexSortFieldType.Description,
  [CustomIndexSortFieldNames.MemberCount]: CustomIndexSortFieldType.MemberCount,
  [CustomIndexSortFieldNames.Weight]: CustomIndexSortFieldType.Weight,
  [CustomIndexSortFieldNames.Periodicity]: CustomIndexSortFieldType.Periodicity,
  [CustomIndexSortFieldNames.LastUpdatedDate]: CustomIndexSortFieldType.LastUpdatedDate,
  [CustomIndexSortFieldNames.Status]: CustomIndexSortFieldType.Status,
  [CustomIndexSortFieldNames.SeriesBegin]: CustomIndexSortFieldType.SeriesBegin,
  [CustomIndexSortFieldNames.SeriesEnd]: CustomIndexSortFieldType.SeriesEnd,
  [CustomIndexSortFieldNames.ScreenReportID]: CustomIndexSortFieldType.ScreenReportID
}

export const CustomIndexFields = Object.keys(CustomIndexSortFields)

function comparator<T>(a: T, b: T, orderBy: keyof T) {
  if (a[orderBy] < b[orderBy]) {
    return -1;
  }
  if (a[orderBy] > b[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: SortOrderStringType,
  orderBy: Key,
): (
  a: { [key in Key]: any },
  b: { [key in Key]: any },
) => number {
  switch (order) {
    case SortOrderStringValue.Ascending:
      return (a, b) => comparator(a, b, orderBy)
    case SortOrderStringValue.Descending:
      return (a, b) => -comparator(a, b, orderBy)
    default:
      return (a, b) => 0
  }
}

export function sort<T>(array: readonly T[] | null | undefined, comparator: (a: T, b: T) => number) {
  return array?.slice()?.sort(comparator) || []
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
