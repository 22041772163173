import React, { useState, useEffect } from 'react'
import { HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr'
import { getAuthTokenFromCookie } from '../../../Services/CookieAccessService'
import { UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'
import { NotificationHubActiveUsers } from '../../../Models/DataModels/SignalR/NotificationHubActiveUsersResponse'
import { NotificationHubUser } from '../../../Models/DataModels/SignalR/NotificationHubUser'
import moment from 'moment'
import { SignalRMessageRequest, SignalRRegisterRequest, SignalRSignOutRequest } from '../../../Models/DataModels/SignalR/NotificationHubRequests'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from '../../../Models/DataModels/Common/RedirectionModel'
import AlertModal, { AlertButtonType, AlertModalProps } from '../Modals/AlertModal'
import { List, ListItem, ListItemText } from '@mui/material'
import { LogoutReason, LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'

const url = process.env.REACT_APP_URL || 'https://localhost:44375'

const getHashCode = (value: string): number => {
    var hash = 0, i, chr
    if (!value || !value.length) return hash

    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i)
        hash = ((hash << 5) - hash) + chr
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}

export interface SignalRNotificationProps {
    userInfo?: UserInfo | null,
    loginTime: string,
    loggedIn?: boolean | null,
    authToken: string | null,
    setActiveUsers: (users: NotificationHubUser[]) => void,
    signOut: (logoutReason: LogoutReasonType) => void,
    signalRSignOutRequest?: SignalRSignOutRequest | null,
    signalRMessageRequest?: SignalRMessageRequest | null,
    setSignalRMessageRequest: (request: SignalRMessageRequest | null) => void,
    setSignalRSignOutRequest: (request: SignalRSignOutRequest | null) => void,
}

const SignalRNotification = ({
    userInfo,
    loginTime,
    loggedIn,
    authToken,
    setActiveUsers,
    signOut,
    signalRMessageRequest,
    signalRSignOutRequest,
    setSignalRMessageRequest,
    setSignalRSignOutRequest
}: SignalRNotificationProps) => {
    const location = useLocation()
    const navigate = useNavigate()

    const [showModal, setShowModal] = useState<boolean>(false)
    const [messageReceived, setMessageReceived] = useState<string[]>([])
    const [connection, setConnection] = useState<HubConnection>()

    const setNewConnection = () => {
        const options: IHttpConnectionOptions = {
            accessTokenFactory: () => {
                return getAuthTokenFromCookie() || ''
            },
            withCredentials: false
        }

        const newConnection = new HubConnectionBuilder()
            .withUrl(`${url}/notificationHub`, options)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build()

        setConnection(newConnection)
    }

    const deregisterSignalRUser = async () => {
        if (connection?.state === HubConnectionState.Connected) {
            try {
                await connection.invoke('Deregister')
                //console.log('Deregistered for Connection ID ', connection?.connectionId)
            }
            catch (e) {
                //console.log(e)
            }
        }
        else {
            //console.log('Deregister SignalR user: No connection to server yet. Connecting now...')
            connect()
        }
    }

    const registerSignalRUser = async () => {
        if (!authToken || !userInfo) {
            //console.log('Auth token or user info not set', authToken, userInfo)
            return
        }

        //console.log('Registering user...')
        const hash = getHashCode(authToken)
        let action = location.pathname || 'Login'
        if (action === '/') action = '/home'

        const request: SignalRRegisterRequest = {
            userName: `${userInfo.firstName} ${userInfo.lastName}`,
            organization: userInfo.orgName || '',
            specialCategory: userInfo.specialCategory || '',
            action: action,
            loginTime: loginTime || moment().format('YYYY-MM-DD HH:mm:ss'),
            hash: hash,
            applicationType: AppConfigurations.applicationType,
            applicationName: AppConfigurations.applicationName
        }

        if (connection?.state === HubConnectionState.Connected) {
            try {
                await connection.invoke(
                    'Register',
                    request)
                //console.log('Registered for Connection ID ', connection?.connectionId)
            }
            catch (e) {
                //console.log(e)
            }
        }
        else {
            //console.log('Register SignalR user: No connection to server yet. Connecting now...')
            connect()
        }
    }

    const getActiveConnections = async () => {
        if (connection?.state === HubConnectionState.Connected) {
            try {
                const response: NotificationHubActiveUsers = await connection.invoke('GetAllActiveConnections')

                if (response?.activeUsers) {
                    //console.log('Active Users : ', response?.activeUsers)
                    //console.log('Filtered Users : ', response?.activeUsers?.filter(user => user !== null) || [])
                    setActiveUsers(response?.activeUsers?.filter(user => user !== null) || [])
                }
                else {
                    //console.log('Active users response:', response?.apiResponse)
                }
            }
            catch (e) {
                //console.log(e)
            }
        }
        else {
            //console.log('Get active connections: No connection to server yet.')
        }
    }

    const connect = () => {
        if (!authToken) {
            //console.log('Not attempting to connect, no token.')
            return
        }

        if (connection && connection.state === HubConnectionState.Disconnected) {
            connection.start()
                .then(
                    result => {
                        //console.log('Connected!')

                        //console.log('Registering user after connection...')
                        registerSignalRUser()

                        connection.on('ReceiveMessage', message => {
                            receiveMessage(message as string)
                        })

                        connection.on('SignOut', () => {
                            signOut(LogoutReason.Kicked)
                            navigate(paths.login)
                        })

                        connection.on('RefreshConnections', () => {
                            getActiveConnections()
                        })
                    },
                    () => { })
                .catch(e => {
                    //console.log('Connection failed: ', e)
                })
        }
    }

    const sendMessage = async (request: SignalRMessageRequest) => {
        if (connection?.state === HubConnectionState.Connected) {
            try {
                await connection.send('SendGlobalMessage', request)
            }
            catch (e) {
                //console.log(e)
            }
        }
        else {
            //console.log('Send Global Message: No connection to server yet.')
        }
    }

    const sendSignOut = async (request: SignalRSignOutRequest) => {
        if (connection?.state === HubConnectionState.Connected) {
            try {
                await connection.send('SignOutUser', request)
            }
            catch (e) {
                //console.log(e)
            }
        }
        else {
            //console.log('Send Sign Out: No connection to server yet.')
        }
    }

    const receiveMessage = (message: string | undefined | null) => {
        if (!message?.trim()) return
        setMessageReceived(previousValues => [...previousValues, message])
        setShowModal(true)
    }

    const renderMessages = () => {
        return <List>{messageReceived.map((message, index) => <ListItem key={index}><ListItemText>{message}</ListItemText></ListItem>)}</List>
    }

    const alertModalProps: AlertModalProps = {
        showModal,
        setShowModal,
        AlertTitle: 'Message from GFD Administrator',
        AlertContent: renderMessages(),
        AlertButtons: [
            {
                type: AlertButtonType.OK,
                display: 'OK',
                onClick: () => {
                    setMessageReceived(() => [])
                    setShowModal(false)
                },
                isPrimary: true
            }
        ],
        onAlertClose: () => {
            setMessageReceived(() => [])
            return true
        }
    }

    useEffect(() => {
        if (!connection) setNewConnection()
    }, [])

    useEffect(() => {
        if (signalRMessageRequest) {
            sendMessage(signalRMessageRequest)
            setSignalRMessageRequest(null)
        }
    }, [signalRMessageRequest])

    useEffect(() => {
        if (signalRSignOutRequest) {
            sendSignOut(signalRSignOutRequest)
            setSignalRSignOutRequest(null)
        }
    }, [signalRSignOutRequest])

    useEffect(() => {
        if (!authToken || !userInfo) {
            //console.log('UseEffect to register user: Auth token or user info not set', authToken, userInfo)
            return
        }

        if (!connection) {
            setNewConnection()
            return
        }

        if (connection && connection.state === HubConnectionState.Disconnected) {
            connect()
            return
        }

        registerSignalRUser()
    }, [connection, connection?.connectionId, userInfo, location.pathname, loginTime, authToken, connection?.state])

    useEffect(() => {
        if (loggedIn) {
            registerSignalRUser()
            return
        }
        if ((loggedIn === false || !authToken) && connection) {
            deregisterSignalRUser()
        }
    }, [loggedIn, authToken])

    return (
        <div>
            <AlertModal {...alertModalProps} />
        </div>
    )
}

export default SignalRNotification
