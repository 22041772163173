import MaterialTable from '@material-table/core'
import { Accordion, AccordionDetails, AccordionSummary, Button, Fab, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import MaterialTableOptions from '../../../../Common/GlobalSettings/MaterialTableOptions'
import { useEffect, useMemo, useState } from 'react'
import { exportScreenReport, getReportResultsByScreenID, getScreenFiltersByID } from '../../../../../Services/ScreeningService'
import { ExportScreenReport, GetReportResultsByScreenIDRequest, GetScreenFiltersByIDRequest } from '../../../../../Models/DataModels/Requests/ScreeningRequests'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { criteriaMapping } from '../../../../Common/Utility/CriteriaMapping'
import GenerateIndex from '../../../../Icons/GenerateIndexIcon'
import GraphwLines from '../../../../Icons/GraphwLines'
import PieGraph from '../../../../Icons/PieGraphIcon'
import BarGraph from '../../../../Icons/BarGraph'
import DownLoad from '../../../../Icons/DownLoad'
import AddtoWorkbook from '../../../../Icons/AddtoWorkbookIcon'
import ExporttoList from '../../../../Icons/ExporttoListIcon'
import AddSelectedSeries from '../../../../Icons/AddSelectedSeriesIcon'
import CustomIndexEditor, { CustomIndexEditorProps } from '../CustomIndices/CustomIndexEditor'
import AlertModal, { AlertModalProps } from '../../../../Common/Modals/AlertModal'
import { LogoutReasonType } from '../../../../../Models/DataModels/Requests/AuthRequests'
import { CreateDataFileResponse, CustomIndex } from '../../../../../Models/DataModels/Responses/ToolsResponses'
import { syncCustomIndex } from '../../../../../Services/CustomIndexService'
import { FileDownloadRequest, SyncCustomIndexRequest } from '../../../../../Models/DataModels/Requests/ToolsRequests'
import moment from 'moment'
import { AppConfigurations } from '../../../../../Models/DataModels/Common/AppConfigurationsModel'
import { TickerData } from '../../../../../Models/DataModels/Responses/AutoTracResponses'
import AddNewScreening from '../../../../Icons/AddNewScreeningIcon'
import React from 'react'
import { Sync } from '@mui/icons-material'
import { GFDToastError, GFDToastInfo, GFDToastSuccess } from '../../../../Common/Utility/GFDToastify'
import { CircularProgress } from '@mui/material'
import { globalStyles } from '../../../../Common/GlobalSettings/GlobalStyles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { paths } from '../../../../../Models/DataModels/Common/RedirectionModel'
import QuickActions, { QuickActionsProps } from '../../../../Common/Modals/QuickActions'
import SelectToolbarLineGraphModal from '../../../../Common/Modals/SelectToolbarLineGraphModal'
import WorkbookSelectionModal, { WorkbookSelectionModalProps } from '../../../../Common/Modals/WorkbookSelectionModal'
import { addTickersToWorkbookByID } from '../../../../../Services/AutoTracService'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'
import { ChartType, ChartTypes } from '../../../../../Models/DataModels/Common/ToolsModel'
import SelectToolbarSelectedResultsChartSetDateModal, { SelectToolbarSelectedResultsChartSetDateModalProps } from '../../../../Common/Modals/SelectToolbarSelectedResultsChartSetDateModal'
import { sessionStorageKeys } from '../../../../../Services/SessionStorageService'
import { downloadFileFromServer } from '../../../../../Services/DownloadService'
import AddTickersToCustomIndex, { AddTickersToCustomIndexProps } from '../../../../Common/Utility/AddTickersToCustomIndex'
import DownloadAction, { DownloadActionProps } from '../../../../Common/Utility/DownloadAction'
import AlertTickerLimitModal, { AlertTickerLimitModalProps } from '../../../../Common/Modals/AlertTickerLimitModal'
import { CustomBtnAddEdit, SyncBtn } from '../../../../Common/GlobalSettings/CustomStyles'
import { spreadAddKeyPropertiesToData } from './FamaFrenchUtil'
import DefaultMaterialTheme from '../../../../Common/GlobalSettings/DefaultMaterialTheme'
import { CSSProperties } from '@mui/material/styles/createTypography'
import { SortOrderStringType, getComparator, sort } from '../../../../../Models/DataModels/Common/SortModel'
import EnhancedTableHead from '../../../../Common/Utility/EnhancedTableHead'
import { UserInfo } from '../../../../../Models/DataModels/Common/UserInfoModel'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../../../Common/Utility/ComponentMessageHandler'
import { MessageResponse } from '../../../../../Models/DataModels/Responses/NotOKResponse'
import LoadingScreen from '../../../../Common/Modals/LoadingScreen'

export interface FamaFrenchCardProps {
  userInfo: UserInfo | null,
  famaScreenID: any,
  expansionState: any,
  screeningName: any,
  customIndexID?: number | null,
  setCustomIndexID: (customIndexID: number | null) => void,
  signOut: (logoutReason: LogoutReasonType) => void,
  setErrorResponse: (error: NotOKResponseModel | null) => void
}

const FamaFrenchCard = ({ userInfo, famaScreenID, expansionState, screeningName, customIndexID, setCustomIndexID, signOut, setErrorResponse }: FamaFrenchCardProps) => {

  const navigate = useNavigate()
  const { expandedScreenID } = useParams()
  const location: any = useLocation()

  const [tableData, setTableData] = useState<any[]>([])
  const [filterData, setFilterData] = useState<[]>([])
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<string | boolean>(false)
  const [fetchConsituentsInProgress, setFetchConsituentsInProgress] = useState<boolean>(false)

  const [constituentMessages, setConstituentMessages] = useState<MessageResponse[]>([])
  const [constituentResponse, setConstituentResponse] = useState<NotOKResponseModel | null>()

  const [selectedReportRequest, setSelectedReportRequest] = useState<GetReportResultsByScreenIDRequest | null>(null)
  const [exportInProgress, setExportInProgress] = useState<boolean>(false)

  const [indexID, setIndexID] = useState<number | null>(customIndexID || null)
  const [customIndex, setCustomIndex] = useState<CustomIndex>()
  const [showCustomIndexEditor, setShowCustomIndexEditor] = useState<boolean>(false)

  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
  const [syncInProgress, setSyncInProgress] = useState<boolean>(false)

  const [chartType, setChartType] = useState<ChartType>(ChartTypes.Pie)
  const [showSelectedResultsChartSetDateModal, setShowSelectedResultsChartSetDateModal] = useState<boolean>(false)

  const [showLineGraphModal, setShowLineGraphModal] = useState<boolean>(false)
  const [lineGraphModalData, setLineGraphModalData] = useState<any[]>([])

  const [tickerLimitAlertMessage, setTickerLimitAlertMessage] = useState(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. Please try with ${AppConfigurations.maximumTickers} series or less.`)
  const [showTickerLimitModal, setShowTickerLimitModal] = useState<boolean>(false)

  const [clearMessagesToggle, setClearMessagesToggle] = useState(false)
  const [selectedWorkbook, setSelectedWorkbook] = useState<string>('')
  const [selectedTickerIDs, setSelectedTickerIDs] = useState<number[]>([])
  const [doAddToWorkbook, setDoAddToWorkbook] = useState<boolean>(false)
  const [showWorkbookSelectionModal, setShowWorkbookSelectionModal] = useState<boolean>(false)

  const [customIndexData, setCustomIndexData] = useState<any[]>([])
  const [showCustomIndexSelectionModal, setShowCustomIndexSelectionModal] = useState<boolean>(false)

  const [availableEntitiyIDs, setAvailableEntitiyIDs] = useState<number[]>([])

  const renderQuickActions = (rowData: any) => {
    const quickActionProps: QuickActionsProps = {
      rowData: rowData,
      maxTickerLimit: AppConfigurations.maximumTickers,
      signOut: signOut
    }
    return <QuickActions {...quickActionProps} />
  }

  const columns = [
    {
      'title': 'ID',
      'field': 'id',
      'hidden': true
    },
    {
      'title': 'Symbol',
      'field': 'symbol',
      'hidden': false
    },
    {
      'title': 'Description',
      'field': 'description',
      'hidden': false
    },
    {
      'title': 'MarketCap',
      'field': 'marketCap',
      'hidden': !availableEntitiyIDs.includes(1778)
    },
    {
      'title': 'PE Ratio',
      'field': 'PERatio',
      'hidden': !availableEntitiyIDs.includes(1786)
    },
    {
      'title': 'Beta',
      'field': 'beta',
      'hidden': !availableEntitiyIDs.includes(1812)
    },
    {
      'title': 'Momentum M3',
      'field': 'momentumM3',
      'hidden': !availableEntitiyIDs.includes(1800)
    },
    {
      'title': 'Momentum M12',
      'field': 'momentumM12',
      'hidden': !availableEntitiyIDs.includes(1804)
    },
    {
      'title': 'Momentum M36',
      'field': 'momentumM36',
      'hidden': !availableEntitiyIDs.includes(1808)
    },
    {
      'title': 'Div Yield',
      'field': 'divYield',
      'hidden': !availableEntitiyIDs.includes(1782)
    },
    {
      title: 'Quick Actions',
      sorting: false,
      field: 'button',
      render: (rowData: any) => renderQuickActions(rowData)
    }
  ]

  const processExport = (selectedSeries: number[] | null) => {
    if (!selectedReportRequest) {
      return
    }

    const exportRequest: ExportScreenReport = {
      screeningReportRequest: selectedReportRequest,
      seriesIDs: selectedSeries || null
    }

    setExportInProgress(true)
    exportScreenReport(exportRequest).then(
      (fileCreateResponse: CreateDataFileResponse) => {
        const downloadRequest: FileDownloadRequest = {
          filename: fileCreateResponse.filename,
          mimeType: fileCreateResponse.mimeType
        }
        downloadFileFromServer(downloadRequest, setErrorResponse)
        setExportInProgress(false)
      },
      (notOKResponseModel: NotOKResponseModel | null) => {
        setErrorResponse(notOKResponseModel)
        setExportInProgress(false)
      }
    )
  }

  const handleSelectedExportButton = (selectedData: any[]) => {
    if (!selectedData || selectedData.length === 0) {
      return
    }
    const selecteSeriesIDs: number[] = selectedData.map((value: any) => value.seriesID)
    processExport(selecteSeriesIDs)
  }

  const handleExportAllButton = () => {
    processExport(null)
  }

  const renderExportAllButton = (): JSX.Element => {
    if (!tableData || tableData.length === 0) {
      return <></>
    }

    return (
      <>
        <Tooltip title={'Export All Fama French Results'} >
          <span>
            <IconButton
              disabled={exportInProgress}
              onClick={handleExportAllButton}
              aria-label='Export All Fama French Results'
            >
              <ExporttoList
                style={{ fontSize: '1.25em' }}
              />
            </IconButton>
          </span>
        </Tooltip>
        {exportInProgress && (
          <IconButton aria-label='Export in Progress' component='label' sx={{
            p: '10px',
            color: '#1976d2',
            visibility: 'visible'
          }}>
            <CircularProgress title='Export in Progress' aria-label='Export in Progress' />
          </IconButton>
        )}
      </>
    )
  }

  const populateFilters = () => {
    const request: GetScreenFiltersByIDRequest = {
      screenID: famaScreenID
    }
    //do call for data or if no longer needed set to blank data array
    getScreenFiltersByID(request).then(
      (res: any) => {
        if (res.filters) {
          setShowFilters(!showFilters)
          setFilterData(res.filters)
        }
      },
      () => {

      })
  }

  const createDateRangeString = (startDate: any, endDate: any): string => {
    const startDateObject: moment.Moment = moment(startDate)
    const endDateObject: moment.Moment = moment(endDate)

    if (startDateObject.isValid() && endDateObject.isValid() && startDateObject.year() !== 1 && endDateObject.year() !== 1) {
      return `${startDateObject.format('YYYY-MM-DD')} to ${endDateObject.format('YYYY-MM-DD')}`
    }

    return ''
  }

  const processFilters = () => {
    const filtersArray: any = []

    if (filterData) {
      filterData.forEach((filter: any, index: number) => {
        let sortLabel = ''

        if (filter.sort) {
          if (filter.sort.level === 1) {
            sortLabel = 'Primary'
          } else if (filter.sort.level === 2) {
            sortLabel = 'Secondary'
          } else {
            sortLabel = 'None'
          }
        }

        const dateRangeString: string = createDateRangeString(filter.pctChgStart, filter.pctChgEnd)
        const inlineDateRangeString: string = dateRangeString === '' ? '' : dateRangeString + ', '

        filtersArray.push(
          <div key={`fiterDate${index}`}>
            <p>
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{criteriaMapping(filter.entityID)}:</span> {filter.min ?? '(No Value Given)'} to {filter.max ?? '(No Value Given)'},
              <span> </span>
              <span style={{ textDecoration: 'underline' }}>Rank:</span> {filter.rank === 'None' ? <>None</> : filter.rank + ' ' + filter.rankValue + ', ' + inlineDateRangeString + filter.rankBy}
              <span> </span>
              <span style={{ textDecoration: 'underline' }}>Sort:</span> {(filter.sort.order === 'None' || filter.sort.level === 99) ? <>None</> : sortLabel + ' ' + filter.sort.order}
            </p>
          </div>
        )
      })
    }

    return filtersArray
  }

  const handleChangeAccordian = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setConstituentResponse(null)
    setConstituentMessages([])
    if (newExpanded && panel !== 'expand') {
      setTableData([])
    }
    setExpanded((newExpanded && panel === 'expand') ? true : false)
  }

  const onSetID = (id: number) => {
    setCustomIndexID(id)
    setIndexID(id)
  }

  const customEditorProps: CustomIndexEditorProps = {
    screenReportID: famaScreenID,
    customIndexID,
    setCustomIndexID: onSetID,
    callback: () => setShowCustomIndexEditor(false),
    signOut
  }

  const customIndexModalProps: AlertModalProps = {
    showModal: showCustomIndexEditor,
    setShowModal: setShowCustomIndexEditor,
    AlertTitle: '',
    AlertContent: <div style={{ width: '100%' }}><CustomIndexEditor {...customEditorProps} /></div>,
    AlertButtons: [
    ],
    Size: 'lg',
    showCloseButton: false,
    onAlertClose: () => {
      return true
    }
  }

  const syncCustomIndexMembers = () => {
    if (syncInProgress) {
      GFDToastInfo('Sync already in progress')
      return
    }
    const request: SyncCustomIndexRequest = {
      screenReportID: famaScreenID
    }

    setSyncInProgress(true)
    syncCustomIndex(request).then(
      (res: any) => {
        setSyncInProgress(false)
        GFDToastSuccess('Sync done')
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        setSyncInProgress(false)
        GFDToastError('Sync failed')
        setErrorResponse(notOKResponseModel)
      })
  }

  const onViewChartClick = (selectedData: any[]) => {
    const selectedResultsArray: any[] = selectedData
    if (selectedResultsArray.length === 1) {
      navigate(`${paths.graph.lineGraph}/${selectedResultsArray[0].symbol}`, { state: { prevPage: location.pathname } })
    } else if (selectedResultsArray.length > 1) {
      setLineGraphModalData(selectedResultsArray)
      setShowLineGraphModal(true)
    }
  }

  const selectToolbarLineGraphModalProps = {
    showLineGraphModal: showLineGraphModal,
    setShowLineGraphModal: setShowLineGraphModal,
    selectedResults: lineGraphModalData
  }

  const validateChartingLimit = (selectedData: any[]): boolean => {
    const chartLimit: number = AppConfigurations.selectedSeriesChartingLimit
    if (selectedData.length > chartLimit) {
      GFDToastError(`Cannot chart more than ${chartLimit} series`)
      return false
    }
    return true
  }

  const onBarChartClick = (selectedData: any[]) => {
    if (!validateChartingLimit(selectedData)) {
      return
    }
    sessionStorage.setItem(sessionStorageKeys.selectedResultsForChartsData, JSON.stringify([...selectedData.values()]))
    setChartType(ChartTypes.Bar)
    setShowSelectedResultsChartSetDateModal(true)
  }

  const onPieChartClick = (selectedData: any[]) => {
    if (!validateChartingLimit(selectedData)) {
      return
    }
    sessionStorage.setItem(sessionStorageKeys.selectedResultsForChartsData, JSON.stringify([...selectedData.values()]))
    setChartType(ChartTypes.Pie)
    setShowSelectedResultsChartSetDateModal(true)
  }

  const selectToolbarSelectedResultsChartSetDateModalProps: SelectToolbarSelectedResultsChartSetDateModalProps = {
    chartType: chartType,
    show: showSelectedResultsChartSetDateModal,
    setShow: setShowSelectedResultsChartSetDateModal,
    lastPathFromCharts: location.pathname
  }

  const [tickersToDownload, setTickersToDownload] = useState<TickerData[] | null>(null)
  const [triggerProcessDownload, setTriggerProcessDownload] = useState<boolean>(false)

  const renderDownloadAction = () => {
    const downloadActionProps: DownloadActionProps = {
      showButton: false,
      downloadTriggerProps: {
        triggerProcessDownload,
        resetTriggerProcessDownload: () => setTriggerProcessDownload(false),
        resetRowData: () => setTickersToDownload(null)
      },
      isComposite: false,
      rowData: tickersToDownload,
      maxTickerLimit: AppConfigurations.maximumTickers || 300,
      signOut: signOut
    }
    return <DownloadAction {...downloadActionProps} />
  }

  const alertTickerLimitModalProps: AlertTickerLimitModalProps = {
    showTickerLimitModal,
    setShowTickerLimitModal,
    tickerLimitAlertMessage
  }

  const processDownload = (data: any) => {
    if (!data || !data?.length) return
    const mappedData = data?.map((element: any) => ({ ...element, id: element.seriesID }))

    const tickers: TickerData[] = Object.values(mappedData)

    setTickersToDownload(tickers)
    setTriggerProcessDownload(true)
  }

  const messageToggleProps = {
    clearMessagesToggle,
    clearMessages: () => {
      setClearMessagesToggle(!clearMessagesToggle)
    }
  }

  const onFinalWorkbookSelection = (returnValue: boolean, workbook: string) => {
    setSelectedWorkbook(workbook)
    setDoAddToWorkbook(returnValue)
  }

  const executeAddToWorkbook = () => {
    if (!doAddToWorkbook
      || !selectedWorkbook
      || !selectedTickerIDs
      || !selectedTickerIDs?.length) return

    addTickersToWorkbookByID({ workbookName: selectedWorkbook, tickerIDs: selectedTickerIDs })
      .then((result: any) => {
        if (result?.messages?.length) {
          const notOKResponseModel: NotOKResponseModel = {
            isBackgroundCall: false,
            statusCode: 200,
            notOKResponse: {
              internal: false,
              success: true,
              logOut: false,
              messages: result?.messages
            }
          }
          setErrorResponse(notOKResponseModel)
        }
      },
        //Reject promise
        (notOKResponseModel: NotOKResponseModel) => {
          setErrorResponse(notOKResponseModel)
        })
  }

  const processAddToWorkbook = (data: any) => {
    if (!data || !data?.length) return

    const tickerIDs = Object.values(data).map((d: any) => d?.id)
    setSelectedTickerIDs(tickerIDs)

    if (tickerIDs.length === 0) return
    if (tickerIDs.length > AppConfigurations.maximumTickers) {
      setTickerLimitAlertMessage(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. ${tickerIDs.length} series have been selected. Please try with ${AppConfigurations.maximumTickers} or less.`)
      setShowTickerLimitModal(true)
      return
    }

    setShowWorkbookSelectionModal(true)
  }

  const workbookSelectionModalProps: WorkbookSelectionModalProps = {
    userInfo,
    onFinal: onFinalWorkbookSelection,
    showModal: showWorkbookSelectionModal,
    setShowModal: setShowWorkbookSelectionModal,
    signOut,
    ...messageToggleProps
  }

  const processAddToCustomIndex = (data: any) => {
    setCustomIndexData(data)
    setShowCustomIndexSelectionModal(true)
  }

  const addTickersToCustomIndexProps: AddTickersToCustomIndexProps = {
    showCustomIndexSelectionModal,
    setShowCustomIndexSelectionModal,
    customIndexData,
    signOut,
    ...messageToggleProps
  }

  useEffect(() => {
    if (location.state?.prevPage === paths.graph.lineGraph) {
      setExpanded(true)
      const request: GetReportResultsByScreenIDRequest = {
        screenID: expandedScreenID as any
      }
      getReportResultsByScreenID(request).then(
        (res: any) => {
          const resWithExtraData: any[] = spreadAddKeyPropertiesToData(res)
          setTableData(resWithExtraData)
          setSelectedReportRequest(request)
        },
        () => {
          setSelectedReportRequest(null)
        }
      )
    } else {
      setTableData([])
    }
  }, [])

  useEffect(() => {
    if (expansionState === famaScreenID && expanded === true) {
      const request: GetReportResultsByScreenIDRequest = {
        screenID: famaScreenID
      }
      setFetchConsituentsInProgress(true)
      getReportResultsByScreenID(request).then(
        (res: any) => {
          const resWithExtraData: any[] = spreadAddKeyPropertiesToData(res)
          setTableData(resWithExtraData)
          setSelectedReportRequest(request)
          setFetchConsituentsInProgress(false)
        },
        (notOKResponseModel: NotOKResponseModel) => {
          setSelectedReportRequest(null)
          setFetchConsituentsInProgress(false)
          setConstituentResponse(notOKResponseModel)
        }
      )
    } else {
      setTableData([])
    }
  }, [expanded])

  useEffect(() => {
    if (doAddToWorkbook) {
      executeAddToWorkbook()
      setDoAddToWorkbook(false)
    }
  }, [doAddToWorkbook])

  useEffect(() => {
    if (expansionState === false) {
      setTableData([])
      setExpanded(false)
    }
  }, [expansionState])

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      if (tableData[0]?.entityIDs) {
        setAvailableEntitiyIDs(tableData[0].entityIDs)
      } else {
        setAvailableEntitiyIDs([])
      }
    }
  }, [tableData])

  const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
  const [sortColumn, setSortColumn] = useState<string>('')
  const sortedData: any[] = useMemo(
    () =>
      sort(tableData, getComparator(sortOrder, sortColumn)),
    [sortOrder, sortColumn, tableData],
  )

  const constituentComponentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages: constituentMessages,
    setMessages: setConstituentMessages,
    response: constituentResponse,
    signOut
  }

  return (
    <>
      <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} />
      {showLineGraphModal && <SelectToolbarLineGraphModal modalProps={selectToolbarLineGraphModalProps} />}
      {showSelectedResultsChartSetDateModal && <SelectToolbarSelectedResultsChartSetDateModal {...selectToolbarSelectedResultsChartSetDateModalProps} />}
      <AddTickersToCustomIndex {...addTickersToCustomIndexProps} />
      <AlertTickerLimitModal {...alertTickerLimitModalProps} />
      {renderDownloadAction()}
      <WorkbookSelectionModal {...workbookSelectionModalProps} />

      {showCustomIndexEditor && <AlertModal {...customIndexModalProps} />}
      {
        customIndex?.lastUpdatedDate ?
          <Grid container spacing={2} columns={16} style={{ marginTop: '0px' }}>
            <Grid item md={4}>
              {`Index: (Last Generated on ${moment(customIndex?.lastUpdatedDate).format(AppConfigurations.dateFormat) || ''}`}
            </Grid>
          </Grid>
          : <></>
      }

      <React.Fragment>
        <Grid container spacing={2} columns={16}>
          <Grid item md={3} style={{ marginTop: '5px', marginBottom: '5px' }}>
            {
              customIndexID ?
                <>
                  <Tooltip title='Edit Index'>
                    <CustomBtnAddEdit variant='contained' style={{ borderRadius: 5, marginBottom: 5 }}
                      onClick={
                        () => setShowCustomIndexEditor(true)
                      }
                      aria-label='Edit Index'
                    >
                      <GenerateIndex style={{ fontSize: '2em' }}></GenerateIndex>
                    </CustomBtnAddEdit>
                  </Tooltip>
                  <Tooltip title='Sync Index Members'>
                    <SyncBtn variant='contained' style={{ borderRadius: 5, marginLeft: 5, marginBottom: 5 }}
                      onClick={syncCustomIndexMembers}
                    >
                      <Sync style={{ fontSize: '2em' }}></Sync>
                    </SyncBtn>
                  </Tooltip>
                  {syncInProgress ?
                    <IconButton title='Sync in progress' aria-label='Refresh Activity Info' component='label'>
                      <CircularProgress style={globalStyles.circularProgress} title='Sync in progress' aria-label='Refresh Activity Info' />
                    </IconButton> : <></>}
                </>
                :
                <Tooltip title='Create Index'>
                  <Button variant='contained' style={{ backgroundColor: menuThemeColor, color: 'white', borderRadius: 5, marginBottom: 5 }}
                    onClick={
                      () => setShowCustomIndexEditor(true)
                    }
                  >
                    <AddNewScreening style={{ fontSize: '2em' }}></AddNewScreening>
                  </Button>
                </Tooltip>
            }
          </Grid>

          {!showFilters ?
            <Grid item md={10} style={{ minWidth: '20%', marginTop: '5px', marginBottom: '5px' }}>
              <Fab color='primary' size='small' style={iconStyles.showInputIcon} onClick={populateFilters} title='Click to show Fama-French Inputs' aria-label='Click to show Fama-French Inputs'>
                <AddIcon />
              </Fab>
              <span style={{ fontSize: 18, fontStyle: 'italic', marginLeft: '10px' }}>Show Fama-French Inputs</span>
            </Grid>
            :
            <>
              <Grid item md={10} style={{ marginTop: '5px', marginBottom: '5px' }}>
                <div style={{ display: 'inline', verticalAlign: 'top' }}>
                  <Fab color='primary' size='small' style={iconStyles.showInputIcon} onClick={populateFilters} title='Click to hide Fama-French Inputs' aria-label='Click to hide Fama-French Inputs'>
                    <RemoveIcon />
                  </Fab>
                </div>
                <div style={{ fontSize: 18, fontStyle: 'italic', display: 'inline-block', marginLeft: '10px' }}>
                  Hide Fama-French Inputs
                  <br />
                  {processFilters()}
                </div>
              </Grid>
            </>
          }
        </Grid>
        <ComponentMessageHandler {...constituentComponentMessageHandlerProps} />
        <Accordion expanded={expanded === true} onChange={handleChangeAccordian('expand')}>
          <AccordionSummary id={screeningName + 'Table'} >
            {expanded ? <div style={chipStyles.customChip as CSSProperties}>
              <span style={style.span}>Click to Hide Constituents</span>
            </div>
              :
              <div style={chipStyles.customChip as CSSProperties}>
                <span style={style.span}>Click to View Constituents</span>
              </div>
            }
            <IconButton title='Request in Progress'
              aria-label='Request in Progress' component='label' sx={{
                p: '10px',
                color: '#1976d2',
                visibility: fetchConsituentsInProgress ? 'visible' : 'hidden'
              }}>
              <CircularProgress title='Request in Progress' aria-label='Request in Progress' />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <ThemeProvider theme={DefaultMaterialTheme}>
              <MaterialTable
                columns={columns}
                data={sortedData}
                title={<Typography variant='h6'>{' ' + screeningName}<span>{renderExportAllButton()}</span></Typography>}
                options={
                  {
                    ...MaterialTableOptions,
                    maxBodyHeight: '500px',
                    selectionProps: rowData => ({
                      inputProps: {
                        'title': 'Select/Deselect',
                        'aria-label': 'Select/Deselect'
                      },
                      disabled: rowData?.accessibleSeriesID !== rowData?.id,
                      color: 'primary'
                    })
                  }
                }
                actions={[
                  {
                    tooltip: 'Graph Selected with Lines',
                    icon: () => <GraphwLines style={{ fontSize: '1.2em' }} />,
                    onClick: (evt: any, data: any) => onViewChartClick(data)
                  },
                  {
                    tooltip: 'Graph Selected with Piechart',
                    icon: () => <PieGraph style={{ fontSize: '1.2em' }} />,
                    onClick: (evt: any, data: any) => onPieChartClick(data)
                  },
                  {
                    tooltip: 'Graph Selected with Bar Graph',
                    icon: () => <BarGraph style={{ fontSize: '1.2em' }} />,
                    onClick: (evt: any, data: any) => onBarChartClick(data)
                  },
                  {
                    tooltip: 'Download Selected Series',
                    icon: () => <DownLoad style={{ fontSize: '1.2em' }} />,
                    onClick: (evt: any, data: any) => processDownload(data)
                  },
                  {
                    tooltip: 'Add to Workbook',
                    icon: () => <AddtoWorkbook style={{ fontSize: '1.2em' }} />,
                    onClick: (evt: any, data: any) => processAddToWorkbook(data)
                  },
                  {
                    tooltip: 'Export Selected',
                    icon: () => <ExporttoList style={{ fontSize: '1.2em' }} />,
                    onClick: (evt: any, data: any) => handleSelectedExportButton(data)
                  },
                  {
                    tooltip: 'Add Selected Series to a Custom Index',
                    icon: () => <AddSelectedSeries style={{ fontSize: '1.2em' }} />,
                    onClick: (evt: any, data: any) => processAddToCustomIndex(data)
                  }
                ]}
                components={{
                  Header:
                    props => (
                      <EnhancedTableHead {...{ sortData: { sortColumn, sortOrder, setSortColumn, setSortOrder }, columns, allowSelection: true, onSelectAll: props?.onAllSelected, selectedCount: props?.selectedCount, rowCount: props?.dataCount }} />
                    )
                }}
              />
            </ThemeProvider>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    </>
  )
}

const menuThemeColor: string = '#5d6cda'

const chipStyles = {
  customChip: {
    border: `2px solid ${menuThemeColor}`,
    padding: '10px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const iconStyles = {
  showInputIcon: {
    backgroundColor: menuThemeColor,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
  }
}

const style = {
  span: {
    color: menuThemeColor,
    textDecoration: 'underline'
  }
}

export default FamaFrenchCard
