import { Autocomplete, Divider, FormControl, FormControlLabel, Grid, Paper, RadioGroup, TextField, Tooltip, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React, { useEffect, useMemo, useState } from 'react'
import OutputFormatValue, { OutputFormatValueType } from '../../../../../Models/DataModels/Common/OutputFormatModel'
import DataFrequencyValue, { DataFrequencyValueType } from '../../../../../Models/DataModels/Common/DataFrequencyModel'
import { DateFormatValue, DateFormatValueType } from '../../../../../Models/DataModels/Common/DateFormatModel'
import { USDCurrencyEntry, blankEntry } from '../../../../../Models/DataModels/Common/FieldPopulationModel'
import { LabelValuePair } from '../../../../../Models/DataModels/Common/LabelValuePairModel'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'
import { CurrencyConversionChartRequest, CurrencyConversionDataCountRequest, CurrencyConversionDownloadRequest, FileDownloadRequest } from '../../../../../Models/DataModels/Requests/ToolsRequests'
import { downloadFile, getCurrencyConversionChart, getCurrencyConversionData, getCurrencyConversionDataCount } from '../../../../../Services/ToolsService'
import { CurrencyConversionDataCountResponse, CurrencyConversionFileDataResponse } from '../../../../../Models/DataModels/Responses/ToolsResponses'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import MaterialTable, { MTableBody } from '@material-table/core'
import { GFDToastError } from '../../../../Common/Utility/GFDToastify'
import { ChartDefinition } from '../../../../../Models/DataModels/Common/ToolsModel'
import CCChartModal from './CCChartModal'
import CurrencyConverterDownloadOptions, { CurrencyConverterDownloadOptionsProps } from '../../../../Common/Modals/CurrencyConverterSubComponents/CurrencyConverterDownloadOptions'
import { AppConfigurations } from '../../../../../Models/DataModels/Common/AppConfigurationsModel'
import DeleteSelected from '../../../../Icons/DeleteSelectedIcon'
import DownloadIcon from '../../../../Icons/DownloadIcon'
import GraphData from '../../../../Icons/GraphDataIcon'
import { customConditionalLabelToolTip, customInLineLabelToolTip } from '../../../../Common/Modals/CustomToolTips'
import { currencyConversionTooltipKeys, getTooltipMessage } from '../../../../../Models/DataModels/Common/TooltipContents'
import { customTheme, RadioBlue, DownloadBtn, CustomBtnBlue } from '../../../../Common/GlobalSettings/CustomStyles'
import EnhancedTableHead from '../../../../Common/Utility/EnhancedTableHead'
import { SortOrderStringType, getComparator, sort } from '../../../../../Models/DataModels/Common/SortModel'
import LoadingScreen from '../../../../Common/Modals/LoadingScreen'

export interface CurrencyConverterOptionsProps {
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void,
    currencyOptions: LabelValuePair[]
}

const CurrencyConverterOptions = ({
    setErrorResponse,
    currencyOptions
}: CurrencyConverterOptionsProps) => {

    const errorMessages = {
        blankSourceCurrency: 'Select Source Currency',
        blankTargetCurrency: 'Select Target Currency',
        duplicateTargetCurrency: 'Duplicate Target Currency',
        sourceTargetSame: 'Source and Target Currency cannot be the same',
        sourceTargetSameSubmit: 'Source and Target Currency cannot be the same',
        noTargetCurrencyAdded: 'Add a target currency',
        noChartData: 'No Chart Data'
    }

    type CurrencyConverterInputOptions = 'single' | 'multi'
    const currencyConverterOptionsTypes = {
        single: 'single' as CurrencyConverterInputOptions,
        multi: 'multi' as CurrencyConverterInputOptions
    }

    const [displaySourceToolTip, setDisplaySourceToolTip] = useState<boolean>(false)
    const [displayTargetToolTip, setDisplayTargetToolTip] = useState<boolean>(false)
    const [sourceFocused, setSourceFocused] = useState<boolean>(false)
    const [targetFocused, setTargetFocused] = useState<boolean>(false)

    const [ccInputOption, setCcInputOption] = useState<string>(currencyConverterOptionsTypes.single)
    const [selectedSourceCurrency, setSelectedSourceCurrency] = useState<LabelValuePair>(USDCurrencyEntry)
    const [selectedTargetCurrency, setSelectedTargetCurrency] = useState<LabelValuePair>(blankEntry)
    const [addedTargetCurrencies, setAddedTargetCurrencies] = useState<LabelValuePair[]>([])

    const [splitAdjusted, setSplitAdjusted] = useState<boolean>(true)
    const [average, setAverage] = useState<boolean>(false)
    const [annualPercentChange, setAnnualPercentChange] = useState<boolean>(false)
    const [periodPercentChange, setPeriodPercentChange] = useState<boolean>(false)
    const [dataFrequency, setDataFrequency] = useState<DataFrequencyValueType>(DataFrequencyValue.Annual)
    const [dateFormat, setDateFormat] = useState<DateFormatValueType>(DateFormatValue.American)
    const [outputFormat, setOutputFormat] = useState<OutputFormatValueType>(OutputFormatValue.Excel)
    const [isDownloadButtonEnabled, setIsDownloadButtonEnabled] = useState<boolean>(false)
    const [isGraphButtonEnabled, setIsGraphButtonEnabled] = useState<boolean>(false)

    const [dataCount, setDataCount] = useState<number>(0)

    const [showChart, setShowChart] = useState<boolean>(false)
    const [currencyChartDef, setCurrencyChartDef] = useState<ChartDefinition>({ charts: [] })
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)

    const downloadOptions: CurrencyConverterDownloadOptionsProps = {
        splitAdjusted: splitAdjusted,
        setSplitAdjusted: setSplitAdjusted,
        average: average,
        setAverage: setAverage,
        annualPercentChange: annualPercentChange,
        setAnnualPercentChange: setAnnualPercentChange,
        periodPercentChange: periodPercentChange,
        setPeriodPercentChange: setPeriodPercentChange,
        dataFrequency: dataFrequency,
        setDataFrequency: setDataFrequency,
        dateFormat: dateFormat,
        setDateFormat: setDateFormat,
        outputFormat: outputFormat,
        setOutputFormat: setOutputFormat
    }

    const renderDivider = () => {
        return (<Divider sx={{ borderColor: 'black', paddingTop: 1, marginBottom: '8px' }} />)
    }

    const renderRowTitle = (title: string) => {
        return (<Grid item md={5} flexDirection='column'>
            <div style={{ fontWeight: 'bold', marginTop: '8px' }}>{title}</div>
        </Grid>)
    }

    const renderTableTitle = (): JSX.Element => {
        return (
            <Grid container spacing={3} columns={25} justifyContent='center'>
                <Grid item>
                    <span><strong>Target Currencies</strong></span>
                </Grid>
                <Grid item>
                    {ccInputOption === currencyConverterOptionsTypes.multi &&
                        dataCount > AppConfigurations.autoTracDataCountLimit ?
                        <span style={{ color: 'red' }}>
                            <strong>Data Count: </strong>{dataCount}
                            <span style={{ fontStyle: 'italic' }}> (Exceeded limit of {AppConfigurations.autoTracDataCountLimit}. Remove entries from Target Currencies)</span>
                        </span>
                        :
                        <span><strong>Data Count: </strong>{dataCount}</span>
                    }
                </Grid>

            </Grid>
        )
    }

    const getDataCount = () => {
        const request: CurrencyConversionDataCountRequest = {
            source: selectedSourceCurrency.value,
            targetList: addedTargetCurrencies.map((value: LabelValuePair) => value.value)
        }
        getCurrencyConversionDataCount(request).then(
            (response: CurrencyConversionDataCountResponse) => {
                setDataCount(response.totalDataCount)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                setErrorResponse(notOKResponseModel)
            }
        )
    }

    const getData = () => {
        const request: CurrencyConversionDownloadRequest = {
            source: selectedSourceCurrency.value,
            targetList: ccInputOption === currencyConverterOptionsTypes.multi ? addedTargetCurrencies.map((value: LabelValuePair) => value.value) : [selectedTargetCurrency.value],
            splitAdjusted: splitAdjusted,
            average: average,
            periodPctChg: periodPercentChange,
            annualPctChg: annualPercentChange,
            periodicity: dataFrequency,
            dateFormat: dateFormat,
            outputFormat: outputFormat
        }
        setShowLoadingModal(true)
        getCurrencyConversionData(request).then(
            (response: CurrencyConversionFileDataResponse) => {
                const downloadRequest: FileDownloadRequest = {
                    filename: response.filename,
                    mimeType: response.mimeType
                }
                downloadFile(downloadRequest).then(
                    async (fileDownloadResponse: any) => {
                        const b = await fileDownloadResponse.blob()
                        let downloadLink = document.createElement('a')
                        let url = URL.createObjectURL(b)
                        downloadLink.href = url
                        downloadLink.download = downloadRequest.filename
                        downloadLink.click()
                    },
                    //Reject promise
                    (notOKResponseModel: NotOKResponseModel) => {
                        setErrorResponse(notOKResponseModel)
                    }
                ).catch(err => GFDToastError(err))
                setShowLoadingModal(false)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                setErrorResponse(notOKResponseModel)
                setShowLoadingModal(false)
            }
        )
    }

    const chartRequest = () => {
        const request: CurrencyConversionChartRequest = {
            source: selectedSourceCurrency.value,
            targetList: ccInputOption === currencyConverterOptionsTypes.multi ? addedTargetCurrencies.map((value: LabelValuePair) => value.value) : [selectedTargetCurrency.value],
            splitAdjusted: splitAdjusted,
            periodicity: dataFrequency,
            dateFormat: dateFormat
        }
        setShowLoadingModal(true)
        getCurrencyConversionChart(request).then(
            (response: ChartDefinition) => {
                if (response && response.charts && response.charts.length > 0) {
                    setCurrencyChartDef(response)
                    setShowChart(true)
                } else {
                    setCurrencyChartDef({ charts: [] })
                    GFDToastError(errorMessages.noChartData)
                }
                setShowLoadingModal(false)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                setErrorResponse(notOKResponseModel)
                setShowLoadingModal(false)
            }
        )
    }

    const validate = (): boolean => {
        if (selectedSourceCurrency.value === '') {
            GFDToastError(errorMessages.blankSourceCurrency)
            return false
        }

        if (ccInputOption === currencyConverterOptionsTypes.single) {
            if (selectedTargetCurrency.value === '') {
                GFDToastError(errorMessages.blankTargetCurrency)
                return false
            } else if (selectedSourceCurrency.value === selectedTargetCurrency.value) {
                GFDToastError(errorMessages.sourceTargetSame)
                return false
            } else {
                return true
            }
        } else {
            if (addedTargetCurrencies.length === 0) {
                GFDToastError(errorMessages.noTargetCurrencyAdded)
                return false
            } else if (addedTargetCurrencies.find((element: LabelValuePair) => element.value === selectedSourceCurrency.value)) {
                GFDToastError(errorMessages.sourceTargetSameSubmit)
                return false
            } else {
                return true
            }
        }
    }

    const handleDownload = () => {
        if (!validate()) {
            return
        }
        getData()
    }

    const handleGraphData = () => {
        if (!validate()) {
            return
        }
        chartRequest()
    }

    const handleAddTargetCurrency = () => {
        if (selectedTargetCurrency.value === '') {
            GFDToastError(errorMessages.blankTargetCurrency)
        } else if (addedTargetCurrencies.find((element: LabelValuePair) => element.value === selectedTargetCurrency.value)) {
            GFDToastError(errorMessages.duplicateTargetCurrency)
            return
        } else if (selectedSourceCurrency.value === selectedTargetCurrency.value) {
            GFDToastError(errorMessages.sourceTargetSame)
            return
        } else {
            setAddedTargetCurrencies([...addedTargetCurrencies, selectedTargetCurrency])
        }
    }

    const removeCurrencyFromList = (data: LabelValuePair | LabelValuePair[]) => {
        let filterAddedList: LabelValuePair[] = []
        if (Array.isArray(data)) {
            if (data.length === 0) {
                return
            }
            const uniqueCurrencies: string[] = []
            data.forEach((value: LabelValuePair, index: number) => uniqueCurrencies.push(value.value))
            filterAddedList = addedTargetCurrencies.filter((element: LabelValuePair) => !uniqueCurrencies.includes(element.value))
        } else {
            filterAddedList = addedTargetCurrencies.filter((element: LabelValuePair) => element.value !== data.value)
        }
        setAddedTargetCurrencies(filterAddedList)
    }

    useEffect(() => {
        if (selectedSourceCurrency.value !== '' && addedTargetCurrencies.length > 0) {
            getDataCount()
        }
    }, [addedTargetCurrencies])

    useEffect(() => {
        if (ccInputOption === currencyConverterOptionsTypes.single) {
            if (selectedSourceCurrency.value !== '' && selectedTargetCurrency.value !== '') {
                setIsDownloadButtonEnabled(true)
                setIsGraphButtonEnabled(true)
            } else {
                setIsDownloadButtonEnabled(false)
                setIsGraphButtonEnabled(false)
            }
        } else {
            if (selectedSourceCurrency.value !== '' && addedTargetCurrencies.length > 0 && dataCount <= AppConfigurations.autoTracDataCountLimit) {
                if (addedTargetCurrencies.length > AppConfigurations.currencyConverterLineGraphCountLimit) {
                    setIsDownloadButtonEnabled(false)
                    setIsGraphButtonEnabled(false)
                } else {
                    setIsDownloadButtonEnabled(true)
                    setIsGraphButtonEnabled(true)
                }
            } else {
                setIsDownloadButtonEnabled(false)
                setIsGraphButtonEnabled(false)
            }
        }
        selectedSourceCurrency.value === blankEntry.value && !sourceFocused ? setDisplaySourceToolTip(false) : setDisplaySourceToolTip(true)
        selectedTargetCurrency.value === blankEntry.value && !targetFocused ? setDisplayTargetToolTip(false) : setDisplayTargetToolTip(true)
    }, [ccInputOption, selectedSourceCurrency, selectedTargetCurrency, addedTargetCurrencies, dataCount])

    const columns: any[] = [
        { title: '_id', field: 'value', hidden: true },
        { title: 'Code', field: 'value', sorting: false },
        { title: 'Description', field: 'label', sorting: false }
    ]

    const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
    const [sortColumn, setSortColumn] = useState<string>('')
    const sortedData: any[] = useMemo(
        () =>
            sort(addedTargetCurrencies, getComparator(sortOrder, sortColumn)),
        [sortOrder, sortColumn, addedTargetCurrencies],
    )

    return (
        <Paper elevation={3} style={{ padding: 15 }}>
            <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} />
            <Grid container spacing={3} columns={25}>
                {renderRowTitle('Options')}
                <Grid item md={18}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby='ccOptionsRadioButtonsGroupLabel'
                            name='ccRadioButtonsGroup'
                            value={ccInputOption}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                setCcInputOption(value)
                            }}
                        >
                            <FormControlLabel value={currencyConverterOptionsTypes.single} control={<RadioBlue id='ccSingleOption' />} label={customInLineLabelToolTip('Single Currency Conversion', getTooltipMessage(currencyConversionTooltipKeys.singleConversion))} />
                            <FormControlLabel value={currencyConverterOptionsTypes.multi} control={<RadioBlue id='ccMultiOption' />} label={customInLineLabelToolTip('Multiple Currency Conversion Comparison', getTooltipMessage(currencyConversionTooltipKeys.multiConversion))} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            {renderDivider()}
            <Grid container spacing={3} columns={25} alignItems='center'>
                {renderRowTitle('Source Currency')}
                <Grid item md={10} flexGrow={1}>
                    <Autocomplete
                        disablePortal
                        id='ccSourceCurrencySelection'
                        onChange={(e, value) => {
                            if (value) {
                                setSelectedSourceCurrency(value)
                            } else {
                                setSelectedSourceCurrency(blankEntry)
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.label || ''}
                        options={currencyOptions}
                        value={selectedSourceCurrency}
                        sx={{ width: '100%' }}
                        size='small'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={customConditionalLabelToolTip('Select Source Currency', getTooltipMessage(currencyConversionTooltipKeys.sourceCurrency), displaySourceToolTip)}
                                variant='outlined'
                                margin='dense'
                                onFocus={() => {
                                    setSourceFocused(true)
                                    setDisplaySourceToolTip(true)
                                }}
                                onBlur={() => {
                                    setSourceFocused(false)
                                    selectedSourceCurrency.value === blankEntry.value ? setDisplaySourceToolTip(false) : setDisplaySourceToolTip(true)
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            {renderDivider()}
            <Grid container spacing={3} columns={25} alignItems='center'>
                {renderRowTitle('Target Currency')}
                <Grid item md={10} flexGrow={1}>
                    <Autocomplete
                        disablePortal
                        id='ccTargetCurrencySelection'
                        onChange={(e, value) => {
                            if (value) {
                                setSelectedTargetCurrency(value)
                            } else {
                                setSelectedTargetCurrency(blankEntry)
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.label || ''}
                        options={currencyOptions}
                        value={selectedTargetCurrency}
                        sx={{ width: '100%' }}
                        size='small'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={customConditionalLabelToolTip('Select Target Currency', getTooltipMessage(currencyConversionTooltipKeys.targetCurrency), displayTargetToolTip)}
                                variant='outlined'
                                margin='dense'
                                onFocus={() => {
                                    setTargetFocused(true)
                                    setDisplayTargetToolTip(true)
                                }}
                                onBlur={() => {
                                    setTargetFocused(false)
                                    selectedTargetCurrency.value === blankEntry.value ? setDisplayTargetToolTip(false) : setDisplayTargetToolTip(true)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={8}>
                    {ccInputOption === currencyConverterOptionsTypes.multi &&
                        <Tooltip title={getTooltipMessage(currencyConversionTooltipKeys.plusButton)}>
                            <CustomBtnBlue id='ccAddToTargetButton' variant='contained' style={{ borderRadius: 5, marginTop: 5, marginBottom: 5 }}
                                onClick={handleAddTargetCurrency}
                            >
                                <ControlPointIcon sx={{ fontSize: 20 }} />
                            </CustomBtnBlue>
                        </Tooltip>
                    }
                </Grid>
            </Grid>
            {ccInputOption === currencyConverterOptionsTypes.multi &&
                <Grid container spacing={3} columns={25}>
                    <Grid item md={25}>
                        <ThemeProvider theme={customTheme}>
                            <MaterialTable
                                columns={columns}
                                data={sortedData}
                                title={renderTableTitle()}
                                options={{
                                    draggable: false,
                                    selection: true,
                                    selectionProps: rowData => ({
                                        inputProps: {
                                            'title': 'Select/Deselect',
                                            'aria-label': 'Select/Deselect'
                                        }
                                    }),
                                    search: false,
                                    padding: 'dense',
                                    toolbarButtonAlignment: 'left',
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100],
                                    paginationType: 'stepped',
                                    maxBodyHeight: '300px',
                                    emptyRowsWhenPaging: false,
                                }}
                                actions={[
                                    {
                                        tooltip: getTooltipMessage(currencyConversionTooltipKeys.trashButton),
                                        icon: () => <DeleteSelected />,
                                        onClick: (evt, data: LabelValuePair | LabelValuePair[]) => {
                                            removeCurrencyFromList(data)
                                        }
                                    }
                                ]}
                                components={{
                                    Body: props => (
                                        (<>
                                            <MTableBody {...props} />
                                        </>)
                                    ),
                                    Header:
                                        props => (
                                            <EnhancedTableHead {...{ sortData: { sortColumn, sortOrder, setSortColumn, setSortOrder }, columns, allowSelection: true, onSelectAll: props?.onAllSelected, selectedCount: props?.selectedCount, rowCount: props?.dataCount }} />
                                        )
                                }}
                            />
                        </ThemeProvider>
                    </Grid>
                </Grid>
            }
            {renderDivider()}
            <CurrencyConverterDownloadOptions {...downloadOptions} />
            {renderDivider()}
            <Grid container spacing={3} columns={16} justifyContent='flex-end'>
                <Grid item>
                    <DownloadBtn
                        id='downloadCCDataButton'
                        // style= {!isDownloadButtonEnabled ? {backgroundColor: 'rgba(0,0,0,0.12)'} : {backgroundColor: '#f1917f'}} 
                        variant='contained'
                        onClick={handleDownload}
                        startIcon={<DownloadIcon />}
                        disabled={!isDownloadButtonEnabled}>Download</DownloadBtn>
                </Grid>
                <Grid item>
                    <CustomBtnBlue
                        id='graphCCDataButton'
                        // style= {!isGraphButtonEnabled ? {backgroundColor: 'rgba(0,0,0,0.12)'} : {backgroundColor: '#007ea8'}} 
                        variant='contained'
                        onClick={handleGraphData}
                        startIcon={<GraphData />}
                        disabled={!isGraphButtonEnabled}>Graph Data</CustomBtnBlue>
                </Grid>
            </Grid>
            {ccInputOption === currencyConverterOptionsTypes.multi && addedTargetCurrencies.length > AppConfigurations.currencyConverterLineGraphCountLimit &&
                <Grid container spacing={3} columns={16} justifyContent='flex-end' sx={{ paddingTop: 2 }}>
                    <Grid item>
                        <span style={{ color: 'red' }}>
                            <span style={{ fontStyle: 'italic' }}>Multiple Currency Conversion download and graphing is allowed up to {AppConfigurations.currencyConverterLineGraphCountLimit} currency pairs. Remove entries from Target Currencies</span>
                        </span>
                    </Grid>
                </Grid>
            }
            <CCChartModal showModal={showChart} setShow={setShowChart} chartDef={currencyChartDef} setErrorResponse={setErrorResponse} />
        </Paper>
    )
}

export default CurrencyConverterOptions
