import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../HelpPageStyles'

const ToolsSpecificGraphing = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Tools Specific Graphing</h2>
                </div>
            </Box>
            <p>
                This section will describe about tools specific graphing.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Graphing from Quick Actions</h4>
                </div>
            </Box>
            <div style={{ height: '649px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/toolsSpecificGraphingQuickActions.png' style={helpPageStyles.image} alt='Tools Specific Graphing Quick Actions'></img>
            </div>
            <p>
                A quick way to graph is to use the Quick Actions from search results. Click on the graph button to graph.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Graphing from Search Page</h4>
                </div>
            </Box>
            <div style={{ height: '500px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/toolsSpecificGraphingSearchPage.png' style={helpPageStyles.image} alt='Tools Specific Graphing Search Page'></img>
            </div>
            <p>
                Select at least 1 series from the the result. This will display the tool bar. Click on the Graph button.
                Clicking on the graph button will display a modal that allows you to select a primary series and up to 10 series to compare for graphing.
            </p>
            <div style={{ height: '1151px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/toolsSpecificGraphingSearchPageComparisonModal.png' style={helpPageStyles.image} alt='Tools Specific Graphing Search Page Comparison Modal'></img>
            </div>
            <p>
                Select the primary series and check the checkboxes for the series you wish to graph for comparison. After your selection, click on graph button.
                To cancel, click on the cancel button or click on the x button on the top right hand corner of the modal.
            </p>
            <div style={{ height: '1187px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/toolsSpecificGraphingSearchPageComparisonModalLimit.png' style={helpPageStyles.image} alt='Tools Specific Graphing Search Page Comparison Modal Limit'></img>
            </div>
            <br />
            <br />
            <div style={{ height: '392px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/toolsSpecificGraphingSearchPageComparisonModalResultGraph.png' style={helpPageStyles.image} alt='Tools Specific Graphing Search Page Comparison Modal Limit Result Graph'></img>
            </div>
            <p>
                The result graph will be a series graphed with the comparison type of indirect (graphed as is).
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Graphing from Selected Results</h4>
                </div>
            </Box>
            <div style={{ height: '179px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/toolsSpecificGraphingSelectedResults.png' style={helpPageStyles.image} alt='Tools Specific Graphing Search Results'></img>
            </div>
            <p>
                Graphing can be also performed from selected results page.
                After selecting the series from the search page, click on "View Selected Results" button in the toolbar.
            </p>
            <div style={{ height: '739px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/toolsSpecificGraphingSelectedResultsSelection.png' style={helpPageStyles.image} alt='Tools Specific Graphing Search Results Selection'></img>
            </div>
            <p>
                At selected results page, select the series you wish to graph and/or compare against. Selecting the series will display the toolbar. Click on the graph button.
                This will display the same series comparison modal from previous steps. Follow the same instruction to graph.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Graphing from Fama French Reports</h4>
                </div>
            </Box>
            <div style={{ height: '668px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/toolsSpecificGraphingFamaFrench.png' style={helpPageStyles.image} alt='Tools Specific Graphing Fama French'></img>
            </div>
            <p>
                Graphing can also be done from Fama French page. From the results of Fama French, click on the graph button in the Quick Actions.
            </p>
        </div>
    )
}

export default ToolsSpecificGraphing
