import { Box } from '@mui/material'
import React from 'react'
import { ImageNumberReference, MarkedRedRedText } from '../../HelpPageCommonComponents'

const EventsInTimeSearch = () => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Event in Time Search</h2>
                </div>

            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h4>Events-In-Time Options</h4>
                </div>
            </Box>
            <div style={{ textAlign: 'left' }}>
                <p>
                    Events-In-Time is a search engine which allows a user to look through various historical events related to finance (wars, bear markets, recessions etc...).  Below we can see some of the various event types, in this case we selected recessions:
                </p>
                <div style={{ height: '558px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/eventsInTime01.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Event in Time Search Help Image 1'></img>
                </div>
                <p style={{ paddingTop: 25 }}>
                    Above, in <span style={{ color: 'blue', fontWeight: 'bold' }}>blue</span> ({ImageNumberReference(1)}), you can see the Events-In-Time search bar.  You can enter your terms here to search for events or event types.
                </p>

                <p>
                    In <MarkedRedRedText /> ({ImageNumberReference(2)} above) you can see the event types.  We have various event types, in this case we selected recession and we can see that the table has updated.  In this case its showing various recessions that happened in the UK from 1558-1688.  If we continued through the pages we would see other countries as well.
                </p>
                <div style={{ height: '520px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/eventsInTime02.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Event in Time Search Help Image 2'></img>
                </div>
                <p style={{ paddingTop: 25 }}>
                    We have many differnt types of options available for users when selecting on the Events-In-Time. In <span style={{ color: 'green', fontWeight: 'bold' }}>green</span> ({ImageNumberReference(1)} above), we can see a few of the different options we offer for the Topic.
                    In <MarkedRedRedText /> ({ImageNumberReference(2)} above) we can see an example row highlighted.  In this case its a war, and a death because an important person died in the war.  This means you could use war or death in the topic to find these types of events.
                </p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Country or Country Group</span> Here we can select the country or country group, examples would be 'USA' for country or 'BRICS' if its a country group</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Event Type</span> Event type is any number of various events, ranging from recessions to bear markets.</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Event Topic</span> Topics can be a number of different values, from wars and crusades to peace times and other indicators like health. </p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Start Year</span> Start Year of that particular event.</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>End Year</span> End Year of that particular event.</p>

                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>SELECTION NOTE:</span> At this current moment in time in order to select and take actions with the Events-In-Time, the list must be worked down to 100 or less events (Done by using the filter options and search term provided).  Once the table is displaying 100 or less results you can select and take actions.  (See Selection and Actions section for more details.)  </p>

            </div>
        </>

    )
}

export default EventsInTimeSearch