// US/UK stocks
export const equityTypeToolTipList = [
    { label: 'ADR', toolTip: `American Depository Receipt: A certificate that is issued from a US bank that represent one or more shares of a foreign company's stock.` },
    { label: 'Agency Bond', toolTip: 'A bond issued by a government-sponsored enterprise.' },
    { label: 'Closed-End Fund', toolTip: 'A fund that issues shares one time to make investments.' },
    { label: 'Colonial Bond', toolTip: 'A debt security issued by a government that was under colonial rule.' },
    { label: 'Common Stock', toolTip: 'A security that represents direct ownership in a company. Voting rights are often granted while having a subordinate position if bankruptcy occurs.' },
    { label: 'Corporate Bond', toolTip: 'A debt security issued by a corporation.' },
    { label: 'ETF-Closed End', toolTip: 'Exchange Traded Fund: A fund that has a one-time issuance of shares to invest in a set of securities or other asset class where the shares can be openly traded on an exchange.' },
    { label: 'ETF-Index', toolTip: 'Exchange Traded Fund: A fund created to track an index where the shares can be openly traded on an exchange.' },
    { label: 'ETF-Stock', toolTip: 'Exchange Traded Fund: A fund created to track an individual stock where the shares can be openly traded on an exchange.' },
    { label: 'Finance', toolTip: 'A security that represents ownership in a company that is created for specific financial or investment purposes.' },
    { label: 'Foreign Ordinary', toolTip: 'Shares of a company that are listed on a foreign stock exchange.' },
    { label: 'Government Bond', toolTip: 'A debt security issued by a government that is listed and traded on an exchange.' },
    { label: 'Municipal Bond', toolTip: 'A debt security issued by a municipal government that is listed and traded on an exchange.' },
    { label: 'Preferred Stock', toolTip: 'A security that represents direct ownership in a company. The shares are generally do not have voting rights and have a superior position to common stock if bankruptcy occurs.' },
    { label: 'Preferred-Convertible', toolTip: 'A type of preferred stock that allows the holder of the security to convert the preferred stock to common stock.' },
    { label: 'Preferred-Trust', toolTip: 'A type of preferred stock that acts like a hybrid of stock and debt where the shares are issued by a trust.' },
    { label: 'REIT', toolTip: 'Real Estate Investment Trust: A company that owns, operates and/or finances real estate properties.' },
    { label: 'Right', toolTip: 'A contract issued by a company that gives the holder the option to purchase shares of a company in the future often at a discounted price to the market.' },
    { label: 'Rights', toolTip: 'A contract issued by a company that gives the holder the option to purchase shares of a company in the future often at a discounted price to the market.' },
    { label: 'Scrip', toolTip: 'A type of credit issued by a company to shareholders that entitles to holder to some form of compensation.' },
    { label: 'Special Product-Index', toolTip: 'Specific securities that are designed to perform based on an underlying index.' },
    { label: 'Special Product-Stock', toolTip: 'Specific securities that are designed to perform based on an underlying stock.' },
    { label: 'State Bond', toolTip: 'A debt security issued by a state level government.' },
    { label: 'Unit', toolTip: 'A security that is composed of a mix of stock and warrants.' },
    { label: 'Units', toolTip: 'A security that is composed of a mix of stock and warrants.' },
    { label: 'Utilities - Multiline', toolTip: '' },
    { label: 'Warrant', toolTip: 'A long-term contract that allows the holder to purchase shares of the issuing company often at a discounted price.' },
    { label: 'Warrants', toolTip: 'A long-term contract that allows the holder to purchase shares of the issuing company often at a discounted price.' }
]

// GFDUniverse/GFDatabase
export const mainIndicatorToolTipList =
    [
        { label: 'Annual Change in Balance of Payments', toolTip: 'The annual percentage change in the official Balance of Payments from the year prior for a country or global region.' },
        { label: 'Annual Change in Central Government Debt', toolTip: 'The annual percentage change in the aggregate debt of the central government from the year prior for a country or global region.' },
        { label: 'Annual Change in Currency in Circulation', toolTip: 'The annual percentage change in the aggregate currency in circulation from the year prior for a country or global region.' },
        { label: 'Annual Change in Exports', toolTip: 'The annual percentage change in exports from a year prior for a country or global region.' },
        { label: 'Annual Change in Foreign Exchange Reserves', toolTip: 'The annual percentage change in foreign exchange reserves held from the year prior for a country or global region.' },
        { label: 'Annual Change in GDP Exports', toolTip: 'The annual percentage change in the exports component of GDP from the year prior for a country or global region.' },
        { label: 'Annual Change in GDP Government Consumption', toolTip: 'The annual percentage change in government consumption component of GDP from  the year prior for a country or global region.' },
        { label: 'Annual Change in GDP Household Consumption', toolTip: 'The annual percentage change in household consumption component of GDP from the year prior for a country or global region.' },
        { label: 'Annual Change in GDP Imports', toolTip: 'The annual percentage change in the imports component of GDP from the year prior for a country or global region.' },
        { label: 'Annual Change in GDP Investment', toolTip: 'The annual percentage change in the aggregate expenditures by the government(s) from a year prior for a country or global region.' },
        { label: 'Annual Change in Government Expenditures', toolTip: 'TThe annual percentage change in the aggregate revenues (taxes) received by the government(s) from a year prior for a country or global region.' },
        { label: 'Annual Change in Government Revenues', toolTip: 'The annual percentage change in imports from a year prior for a country or global region.' },
        { label: 'Annual Change in Imports', toolTip: 'The annual percentage change in the official Balance of Payments from the year prior for a country or global region.' },
        { label: 'Annual Change in Industrial Production', toolTip: 'The annual percentage change in industrial production from a year prior for a country or global region.' },
        { label: 'Annual Change in M1', toolTip: 'The annual percentage change in M1 from a year prior for a country.' },
        { label: 'Annual Change in M2', toolTip: 'The annual percentage change in M2 from a year prior for a country.' },
        { label: 'Annual Change in M3', toolTip: 'The annual percentage change in M3 from a year prior for a country.' },
        { label: 'Annual Change in Manufacturing Production', toolTip: 'The annual percentage change in manufacturing production from a year prior for a country or global region.' },
        { label: 'Annual Change in Real GDP', toolTip: 'The annual percentage change in Real GDP from a year prior for a country or global region.' },
        { label: 'Annual Consumer Price Inflation Rate', toolTip: 'The annual percentage change in the consumer price index (CPI) from a year prior for a country or global region.' },
        { label: 'Annual Core Consumer Price Inflation Rate', toolTip: 'The annual percentage change in the core consumer price index (Core CPI) from a year prior for a country or global region.' },
        { label: 'Annual Employment Growth', toolTip: 'The annual percentage change in the employment rate from a year prior for a country or global region.' },
        { label: 'Annual Energy Consumer Price Inflation Rate', toolTip: 'The annual percentage change in the energy component of the consumer price index (CPI) from a year prior for a country.' },
        { label: 'Annual Food Consumer Price Inflation Rate', toolTip: 'The annual percentage change in the food component of the consumer price index (CPI) from a year prior for a country.' },
        { label: 'Annual GDP Growth', toolTip: 'The annual percentage change in Annual GDP from a year prior for a country or global region.' },
        { label: 'Annual Growth in Civilian Labor Force', toolTip: 'The annual percentage change in the civilian labor force from a year prior for a country.' },
        { label: 'Annual Harmonized Consumer Price Inflation Rate', toolTip: 'The annual percentage change in the harmonized consumer price index (HCPI) from a year prior for a country or global region.' },
        { label: 'Annual Housing Consumer Price Inflation Rate', toolTip: 'The annual percentage change in the housing component of the consumer price index (CPI) from a year prior for a country.' },
        { label: 'Annual Population Growth', toolTip: 'The annual percentage change in the aggregate population from a year prior for a country or global region.' },
        { label: 'Annual Producer Price Inflation Rate', toolTip: 'The annual percentage change in the producer price index (PPI) from a year prior for a country or global region.' },
        { label: 'Annual Services Consumer Price Inflation Rate', toolTip: 'The annual percentage change in the services component of the consumer price index (CPI) from a year prior for a country.' },
        { label: 'Argentina Peso Exchange Rate', toolTip: 'Foreign exchange data involving the Argentine Peso.' },
        { label: 'Australia Dollar Exchange Rate', toolTip: 'Foreign exchange data involving the Australian Dollar.' },
        { label: 'Balance of Payments - Credits', toolTip: `The aggregate running credit balance for a country's international trade activity.` },
        { label: 'Balance of Payments - Debits', toolTip: `The aggregate running debit balance for a country's international trade activity.` },
        { label: 'Balance of Payments - Net', toolTip: `The aggregate running net balance for a country's international trade activity.` },
        { label: 'Bolivia Bolivar Exchange Rate', toolTip: 'Foreign exchange data involving the Bolivian Bolivar.' },
        { label: 'Bond-Bill Premium', toolTip: 'Data tracking the premium paid to bonds over treasury bills for a country or global region.' },
        { label: 'Brazil Real Exchange Rate', toolTip: 'Foreign exchange data involving the Brazil Real.' },
        { label: 'British Pound Exchange Rate', toolTip: 'Foreign exchange data involving the British Pound.' },
        { label: 'Business Confidence', toolTip: 'Data that tracks perceptions of future business and/or economic conditions.' },
        { label: 'Canada Dollar Exchange Rate', toolTip: 'Foreign exchange data involving the Canadian Dollar.' },
        { label: 'CAPE Ratio', toolTip: `A historical record of Cyclically-Adjusted Price-to-Earnings (CAPE) Ratio for a country's major index or a United States' Sector Index.` },
        { label: 'Cape Yield', toolTip: `A historical record of Excess Cyclically-Adjusted Price-to-Earnings (CAPE) Yield for a country's major index or a United States' Sector Index.` },
        { label: 'Capitalization', toolTip: 'The aggregate stock market capitalization for a country or global region.' },
        { label: 'Car Registrations', toolTip: 'The aggregate amount of passenger car registrations for a country.' },
        { label: 'Central Bank Rate', toolTip: `An official interest rate provided by the country's central bank or other monetary authority.` },
        { label: 'Central Government Debt', toolTip: 'The aggregate level of outstanding debt that is issued by a central government.' },
        { label: 'Chile Peso Exchange Rate', toolTip: 'Foreign exchange data involving the Chilean Peso.' },
        { label: 'China Yuan Exchange Rate', toolTip: 'Foreign exchange data involving the Chinese Yuan.' },
        { label: 'Colombia Peso Exchange Rate', toolTip: 'Foreign exchange data involving the Colombian Peso.' },
        { label: 'Commodity Indices', toolTip: 'Series that are made up of indexes that track commodities.' },
        { label: 'Commodity Prices', toolTip: 'Series that track the price of one or more commodities.' },
        { label: 'Commodity Prices - US Farm', toolTip: 'Series that track the price of one or more agricultural goods in the United States.' },
        { label: 'Consumer Confidence', toolTip: 'Data that tracks perceptions of future consumer conditions.' },
        { label: 'Consumer Price Index', toolTip: 'The Consumer Price Index (CPI) tracks the prices paid by consumers for goods and services over time.' },
        { label: 'Consumer Price Index - Core', toolTip: 'The Core Consumer Price Index (Core CPI) tracks the prices paid by consumers for fundamental goods and services over time.' },
        { label: 'Consumer Price Index - Energy', toolTip: 'The Energy Consumer Price Index tracks the energy cost component that contributes to the Consumer Price Index.' },
        { label: 'Consumer Price Index - Food', toolTip: 'The Food Consumer Price Index tracks the food cost component that contributes to the Consumer Price Index.' },
        { label: 'Consumer Price Index - Housing', toolTip: 'The Housing Consumer Price Index tracks the housing cost component that contributes to the Consumer Price Index.' },
        { label: 'Consumer Price Index - Services', toolTip: 'The Services Consumer Price Index tracks the services cost component that contributes to the Consumer Price Index.' },
        { label: 'Corporate Debt', toolTip: 'The aggregate level of outstanding debt that is issued by corporations from a specific country.' },
        { label: 'Corporate Lending Rate', toolTip: 'A record of the lending rate offered to corporations in a country.' },
        { label: 'Covid-19 Cases', toolTip: 'A record of Covid-19 infections in a country.' },
        { label: 'Covid-19 Deaths', toolTip: 'A record of deaths attributed to Covid-19  in a country.' },
        { label: 'Currency in Circulation', toolTip: 'The aggregate amount of notes and coins issued and in the economy not including those that are deposited in the central bank.' },
        { label: 'Current Account - Credits', toolTip: `The aggregate running credit balance in the current account for a country's international trade activity.` },
        { label: 'Current Account - Debits', toolTip: `The aggregate running debit balance in the current account for a country's international trade activity.` },
        { label: 'Current Account - Net', toolTip: `The aggregate running net balance in the current account for a country's international trade activity.` },
        { label: 'Denmark Krone Exchange Rate', toolTip: 'Foreign exchange data involving the Danish Krone.' },
        { label: 'Deposit Rate', toolTip: 'The short-term rate given demand deposits that are placed in a specific country.' },
        { label: 'Dividend Yield', toolTip: 'The yield provided by dividends from stocks for a given index or exchange.' },
        { label: 'Domestic Debt', toolTip: 'The aggregate level of new and outstanding debt issued by a country or global region.' },
        { label: 'Egypt Peso Exchange Rate', toolTip: 'Foreign exchange data involving the Egyptian Peso.' },
        { label: 'EMBI+ Government Bond Spread', toolTip: 'The basis point difference of debt issued by the government of an emerging country or global region compared to debt of a similar maturity that is issued by the United States government.' },
        { label: 'Employment - Total', toolTip: 'An aggregate of all people who are currently employed within a country or global region.' },
        { label: 'Equity-Bill Premium', toolTip: 'The excess premium (in percentage points) earned from equities over that of government treasury bills.' },
        { label: 'Equity-Bond Premium', toolTip: 'The excess premium (in percentage points) earned from equities over that of government bonds.' },
        { label: 'Euro Exchange Rate', toolTip: 'Foreign exchange data involving the European Union Euro.' },
        { label: 'Exchange Rate Index - Nominal', toolTip: 'Foreign exchange data where a domestic currency is toolTipd against a weighted basket of foreign currencies.' },
        { label: 'Exchange Rate Index - Real', toolTip: 'Foreign exchange data where a domestic currency is toolTipd against a weighted basket of foreign currencies (NEER), and then adjusted for price inflation by the representative GDP Deflator.' },
        { label: 'Export Price Index', toolTip: 'The Export Price Index tracks the changes in prices of goods and services that are sold to foreign buyers over time.' },
        { label: 'Exports', toolTip: 'The aggregate toolTip of goods and services that are exported from a country or global region.' },
        { label: 'Exports - Goods', toolTip: 'The aggregate toolTip of goods that are exported from a country or global region.' },
        { label: 'Exports - Services', toolTip: 'The aggregate toolTip of services that are exported from a country or global region.' },
        { label: 'External Balance', toolTip: 'The net position of goods and services that occur as a part of international trade.' },
        { label: 'External Balance - Goods', toolTip: 'The net position of goods that occur as a part of international trade.' },
        { label: 'External Balance - Services', toolTip: 'The net position of services that occur as a part of international trade.' },
        { label: 'External Debt', toolTip: 'The aggregate level of debt issued by a country or countries that make up a global region that is currently held by foreign parties.' },
        { label: 'Foreign Exchange Reserves', toolTip: 'The aggregate toolTip of liquid foreign exchange assets held by a country or countries that make up a global region.' },
        { label: 'GDP', toolTip: 'The aggregate toolTip of goods and services produced within a country or global region.' },
        { label: 'GDP - Exports', toolTip: 'The aggregate toolTip of goods and services that are exported to another country for the purposes of calculating net trade for a country or global region.' },
        { label: 'GDP - Government Consumption', toolTip: 'The aggregate toolTip of goods and services produced for government consumption within a country or global region.' },
        { label: 'GDP - Household Consumption', toolTip: 'The aggregate toolTip of goods and services produced for household consumption within a country or global region.' },
        { label: 'GDP - Imports', toolTip: 'The aggregate toolTip of goods and services that are imported from another country for the purposes of calculating net trade for a country or global region.' },
        { label: 'GDP - Investment', toolTip: 'The aggregate toolTip of goods and services produced for investment purposes within a country or global region.' },
        { label: 'GDP Deflator', toolTip: 'The percentage change in how the price of goods and services that contribute to Gross Domestic Product (GDP) change over time.' },
        { label: 'GFD Indices - Bonds', toolTip: `GFD's custom indices that track the price and performance of public and private issued debt of a country or global region.` },
        { label: 'GFD Indices - Commodities', toolTip: `GFD's custom indices that track the price and performance of one or more commodities.` },
        { label: 'Gini Coefficient', toolTip: 'A ratio that measures the economic inequality that exists within a country.' },
        { label: 'Gold Reserves', toolTip: 'The aggregate toolTip of gold held by the central bank of a country or countries that make up a global region.' },
        { label: 'Government Bond 1-Year', toolTip: 'Data that tracks the historical yield of a 1-Year (or 12-Month) government debt security.' },
        { label: 'Government Bond 10-Year', toolTip: 'Data that tracks the historical yield of a 10-Year government debt security.' },
        { label: 'Government Bond 2-Year', toolTip: 'Data that tracks the historical yield of a 2-Year government debt security.' },
        { label: 'Government Bond 3-Year', toolTip: 'Data that tracks the historical yield of a 3-Year government debt security.' },
        { label: 'Government Bond 30-Year', toolTip: 'Data that tracks the historical yield of a 30-Year government debt security.' },
        { label: 'Government Bond 5-Year', toolTip: 'Data that tracks the historical yield of a 5-Year government debt security.' },
        { label: 'Government Borowing/Lending Projection', toolTip: 'An estimate of expected future borrowing by the central government of a country.' },
        { label: 'Government Debt - Consolidated', toolTip: 'An aggregate amount of debt issued by all levels of government within a country.' },
        { label: 'Government Debt - Domestic', toolTip: 'An aggregate amount of debt held by parties within a country.' },
        { label: 'Government Debt - External', toolTip: 'An aggregate amount of debt held by parties outside of a country or global region.' },
        { label: 'Government Debt - Total', toolTip: 'An aggregate amount of debt held by parties inside and outside of a country or global region.' },
        { label: 'Government Debt Projection', toolTip: 'An estimate of expected future debt levels by the central government of a country.' },
        { label: 'Government Expenditures', toolTip: 'An aggregate amount of spending (outlays) performed by the central government of a country.' },
        { label: 'Government Expenditures, PC of GDP', toolTip: 'A ratio of the aggregate government expenditures as a percentage of total GDP.' },
        { label: 'Government Final Consumption Expenditure', toolTip: 'An aggregate of that comprises the final amount of expenditures for goods and services that are placed by the government.' },
        { label: 'Government Primary Expenditures, PC of GDP', toolTip: 'A ratio of primary government expenditures compared to the Gross Domestic Product (GDP) for a country.' },
        { label: 'Government Revenue, PC of GDP', toolTip: 'A ratio of primary government revenues compared to the Gross Domestic Product (GDP) for a country.' },
        { label: 'Government Revenues', toolTip: 'An aggregate of that comprises the amount of revenues received by the government.' },
        { label: 'Government Surplus/Deficit', toolTip: `A record of the net financial position (surplus or deficit) based on the central government's budget to revenues that are received.` },
        { label: 'Govt Debt Share of GDP', toolTip: 'A ratio of the accumulated government debt compared to the Gross Domestic Product (GDP) for a country.' },
        { label: 'Gross Fixed Capital Formation', toolTip: 'An aggregate toolTip of the amount of capital expenditures (investment) that contributes to Gross Domestic Product (GDP).' },
        { label: 'Harmonized Consumer Price Index', toolTip: 'The price index that tracks the prices of good and services over time where the prices in rural areas contribute equally to those in urban areas and where the cost of housing is excluded from consideration.' },
        { label: 'Health Care Expenditures', toolTip: 'An aggregate toolTip of the amount of health expenditures for a given country.' },
        { label: 'Hong Kong Dollar Exchange Rate', toolTip: 'The annual percentage change in the official Balance of Payments from the year prior for a country or global region.' },
        { label: 'Housing Prices', toolTip: 'Data that tracks the changes in housing prices for a given country or global region.' },
        { label: 'Hungary Forint Exchange Rate', toolTip: 'Foreign exchange data involving the Hungarian Forint.' },
        { label: 'Iceland Krona Exchange Rate', toolTip: 'Foreign exchange data involving the Icelandic Krona.' },
        { label: 'Import Cover', toolTip: 'An estimate of the time available for a given country or global region to financially satisfy the financial obligations of imports based on the aggregate of gold, SDR, foreign currency that are held by a monetary authority and/or the IMF.' },
        { label: 'Import Price Index', toolTip: 'The Import Price Index measures the prices of imported goods and services over time.' },
        { label: 'Imports', toolTip: 'An aggregate toolTip of all imported goods and services for a given country of global region.' },
        { label: 'Imports - Goods', toolTip: 'An aggregate toolTip of all imported goods for a given country of global region.' },
        { label: 'Imports - Services', toolTip: 'An aggregate toolTip of all imported services for a given country of global region.' },
        { label: 'Inactive Population (15+)', toolTip: 'An aggregate of people 15 years of age and over who are not in or actively looking to be in the workforce.' },
        { label: 'Inactivity Rate (15+)', toolTip: 'A ratio of people 15 years of age and over who are not in or actively looking to be in the workforce versus the total population of those who are 15 year of age and over.' },
        { label: 'India Rupee Exchange Rate', toolTip: 'Foreign exchange data involving the Indian Rupee.' },
        { label: 'Indonesia Rupiah Exchange Rate', toolTip: 'Foreign exchange data involving the Indonesian Rupiah.' },
        { label: 'Industrial Production', toolTip: 'An aggregate level of output produced by the industrial sector within a given country or global region.' },
        { label: 'Industrial Production Index', toolTip: 'The Industrial Production Index (IPI) tracks the changes in production to capacity over time among the industrial sector within a given country.' },
        { label: 'Interbank Rate - 1 month', toolTip: 'The interest rate charged for a 1-month loan between banks in a given country.' },
        { label: 'Interbank Rate - 12 months', toolTip: 'The interest rate charged for a 12-month loan between banks in a given country.' },
        { label: 'Interbank Rate - 3 months', toolTip: 'The interest rate charged for a 3-month loan between banks in a given country.' },
        { label: 'Interbank Rate - 6 months', toolTip: 'The interest rate charged for a 6-month loan between banks in a given country.' },
        { label: 'Interest Paid on Public Debt', toolTip: 'A ratio of the amount of interest paid on public debt versus the Gross Domestic Product (GDP) for a given country.' },
        { label: 'Israel Sheqalim Exchange Rate', toolTip: 'Foreign exchange data involving the Israeli Sheqalim.' },
        { label: 'Japan Yen Exchange Rate', toolTip: 'Foreign exchange data involving the Japanese Yen.' },
        { label: 'Jordan Dinar Exchange Rate', toolTip: 'Foreign exchange data involving the Jordanian Dinar.' },
        { label: 'Kuwait Dinar Exchange Rate', toolTip: 'Foreign exchange data involving the Kuwaiti Dinar.' },
        { label: 'Labor Force - Civilian', toolTip: 'An aggregate of all people excluding active military, government, and agricultural workers or are imprisoned and are classified as employed or unemployed within a country.' },
        { label: 'Leading Indicators', toolTip: 'A composite value that is composed of several economic indicators that have been shown to have a relationship that reasonably leads the future trend of the business cycle within a given country or global region.' },
        { label: 'M1', toolTip: 'An aggregate measure of money supply for a given country that includes the amount of currency in circulation as well as demand and savings deposits.' },
        { label: 'M2', toolTip: 'An aggregate measure of money supply for a given country that includes all items under the definition of M1 plus small scale time deposits and deposits in money market funds.' },
        { label: 'M3', toolTip: 'An aggregate measure of money supply for a given country that includes all items under the definition of M2 plus large scale time deposits and deposits in money market funds as well as the value of repurchase agreements.' },
        { label: 'Malaysia Ringgit Exchange Rate', toolTip: 'Foreign exchange data involving the Malaysian Ringgit.' },
        { label: 'Mexico Peso Exchange Rate', toolTip: 'Foreign exchange data involving the Mexican Peso.' },
        { label: 'Mortgage Lending Rate', toolTip: 'The rate charged for mortgages in a given country.' },
        { label: 'New Zealand Dollar Exchange Rate', toolTip: 'Foreign exchange data involving the New Zealand Dollar.' },
        { label: 'Norway Krone Exchange Rate', toolTip: 'Foreign exchange data involving the Norwegian Krone.' },
        { label: 'Overnight Interest Rate', toolTip: 'The rate changed for overnight interbank loans in a given country.' },
        { label: 'P/E Ratio', toolTip: 'The composite Price to Earnings (P/E) Ratio for the equity markets in a given country or global region.' },
        { label: 'Pakistan Rupee Exchange Rate', toolTip: 'Foreign exchange data involving the Pakistani Rupee.' },
        { label: 'Paraguay Guarani Exchange Rate', toolTip: 'Foreign exchange data involving the Paraguyan Guarani.' },
        { label: 'Patent Applications', toolTip: 'A record of the aggregate level of patent applications presented to the representative governmental agency of a given country.' },
        { label: 'Peru Sol Exchange Rate', toolTip: 'Foreign exchange data involving the Peruvian Sol.' },
        { label: 'Philippines Peso Exchange Rate', toolTip: 'Foreign exchange data involving the Philippine Peso.' },
        { label: 'Poland Zloty Exchange Rate', toolTip: 'Foreign exchange data involving the Polish Zloty.' },
        { label: 'Population', toolTip: 'An aggregate of level of all people who reside in a country or global region.' },
        { label: 'Population Projections', toolTip: 'An estimated trend of future changes in the population in a country or global region.' },
        { label: 'Primary Balance, PC of GDP', toolTip: 'A ratio of the primary balance excluding net interest payments on public debt versus the Gross Domestic Product (GDP) for a given country.' },
        { label: 'Private Debt', toolTip: 'A ratio of the aggregate level of debt taken out by private parties versus the Gross Domestic Product (GDP) for a given country.' },
        { label: 'Producer Price Index', toolTip: 'The Producer Price Index (PPI) tracks the prices received by domestic producers for good and services over time.' },
        { label: 'Producer Prices: Domestic Consumer Goods', toolTip: 'The Domestic Consumer Goods Index tracks the prices received by domestic producers of consumer goods over time.' },
        { label: 'Producer Prices: Domestic Durable Consumer Goods', toolTip: 'The Domestic Durable Consumer Goods Index tracks the prices received by domestic producers of durable consumer goods over time.' },
        { label: 'Producer Prices: Domestic Intermediate Goods', toolTip: 'The Domestic Intermediate Goods Index tracks the prices received by domestic producers of intermediate goods over time.' },
        { label: 'Producer Prices: Domestic Investment Goods', toolTip: 'The Domestic Investment Goods Index tracks the prices received by domestic producers of investment goods over time.' },
        { label: 'Producer Prices: Domestic Nondurable Consumer Goods', toolTip: 'The Domestic Nondurable Consumer Goods Index tracks the prices received by domestic producers of nondurable consumer goods over time.' },
        { label: 'Purchasing Managers Index', toolTip: `The Purchasing Managers' Index (PMI) is a composite consisting of surveys of managers in the manufacturing and services industries that gauges the perceived trend in market conditions.` },
        { label: 'R&D Expenditures', toolTip: 'An aggregate of measure of all expenditures for research and development (R & D) in a given country.' },
        { label: 'Real GDP', toolTip: 'The inflation adjusted aggregate value of goods and services produced within a country or global region.' },
        { label: 'Recessions', toolTip: 'A record of economic recessions that have occurred in a given country or global region over time.' },
        { label: 'Registered Unemployment Level', toolTip: 'The aggregate level or corresponding rate of unemployment based on official records from governmental unemployment offices.' },
        { label: 'Romania Leu Exchange Rate', toolTip: 'Foreign exchange data involving the Romanian Leu.' },
        { label: 'Russia Ruble Exchange Rate', toolTip: 'Foreign exchange data involving the Russian Ruble.' },
        { label: 'Saudi Arabia Riyal Exchange Rate', toolTip: 'Foreign exchange data involving the Saudi Arabian Riyal.' },
        { label: 'Share Value Index', toolTip: `GFD's custom indices that tracks the aggregate number of shares outstanding for a stock exchange or major index.` },
        { label: 'Share Value Index Annual Pct Chg', toolTip: `GFD's custom indices that tracks the annual percentage change in the aggregate number of shares outstanding for a stock exchange or major index.` },
        { label: 'Singapore Dollar Exchange Rate', toolTip: 'Foreign exchange data involving the Singapore Dollar.' },
        { label: 'South Africa Rand Exchange Rate', toolTip: 'Foreign exchange data involving the South African Rand.' },
        { label: 'South Korea Won Exchange Rate', toolTip: 'Foreign exchange data involving the South Korean Won.' },
        { label: 'Stock Index Composite', toolTip: 'Broad-based indexes designed to capture the changes in a particular stock market.' },
        { label: 'Stock Indices - Small and Midcap', toolTip: 'Stock indexes that are designed to capture the changes in small and/or midcap level companies.' },
        { label: 'Sweden Krona Exchange Rate', toolTip: 'Foreign exchange data involving the Swedish Krona.' },
        { label: 'Switzerland Franc Exchange Rate', toolTip: 'Foreign exchange data involving the Swiss Franc.' },
        { label: 'Taiwan Dollar Exchange Rate', toolTip: 'Foreign exchange data involving the Taiwanese Dollar.' },
        { label: 'Terms of Trade', toolTip: 'A measure of the net difference in the terms of trade between exports and imports.' },
        { label: 'Thailand Baht Exchange Rate', toolTip: 'Foreign exchange data involving the Thai Baht.' },
        { label: 'Total Debt Securities', toolTip: 'An aggregate value of total debt securities new and outstanding for a given country.' },
        { label: 'Total Domestic Debt', toolTip: 'A ratio of the aggregate amount of private and public debt versus the Gross Domestic Product (GDP) for a given country.' },
        { label: 'Total Harmonized Unemployment', toolTip: 'An aggregate of unemployed people that are part of the active workforce where data is a combination of surveys and official records from unemployment agencies.' },
        { label: 'Total Manufacturing Production', toolTip: 'An index of the total production of the manufacturing industries for a given country or global region relative to a base year of production.' },
        { label: 'Total Mining Production', toolTip: 'An index of the total production of the mining industries for a given country or global region relative to a base year of production.' },
        { label: 'Total Return Indices - Stocks', toolTip: 'The accumulated return of a stock index.' },
        { label: 'Total Returns - Bills', toolTip: 'The accumulated return of short-term government debt for a given country.' },
        { label: 'Total Returns - Bonds', toolTip: 'The accumulated return of government long-term government debt for a given country.' },
        { label: 'Total Returns - Stocks', toolTip: 'The accumulated return of the equity markets for a given country.' },
        { label: 'Treasury Bill Yield', toolTip: 'Data that tracks the historical yield of short-term government debt issued by a country.' },
        { label: 'Turkey Lira Exchange Rate', toolTip: 'Foreign exchange data involving the Turkish Lira.' },
        { label: 'Unemployed Population (15+)', toolTip: 'An aggregate of people 15 years of age and over who are actively in the workforce and are currently unemployed.' },
        { label: 'Unemployment - Total', toolTip: 'An aggregate of people of who are unemployed within a given country or global region.' },
        { label: 'Unemployment Rate', toolTip: 'The annual percentage change in the official Balance of Payments from the year prior for a country or global region.' },
        { label: 'Unemployment Rate (Harmonized)', toolTip: 'A ratio of people who are unemployed using a harmonized method versus the total workforce population for a given country or global region.' },
        { label: 'United Arab Emirates Dirham Exchange Rate', toolTip: 'Foreign exchange data involving the United Arab Emirates Dirham.' },
        { label: 'Uruguay Peso Exchange Rate', toolTip: 'Foreign exchange data involving the Uruguayan Peso.' },
        { label: 'US Dollar Exchange Rate', toolTip: 'Foreign exchange data involving the United States Dollar.' },
        { label: 'Venezuela Bolivar Exchange Rate', toolTip: 'Foreign exchange data involving the Venezuelan Bolivar.' }
    ]

// GFDUniverse/GFDatabase
export const seriesCategoryToolTipList = [
    { label: 'Asset Allocation', toolTip: 'Data that represent the performance of a specific type of financial asset class.' },
    { label: 'Commodity', toolTip: 'Data that focus on at least one raw material or agricultural product.' },
    { label: 'Development', toolTip: 'Data that represent changes over time in different areas of economic development.' },
    { label: 'Economic', toolTip: 'Data that focus on broad changes in economic and financial activity.' },
    { label: 'Epidemics', toolTip: 'Data that covers epidemic health statistics.' },
    { label: 'Equity', toolTip: 'Data that covers the performance of equity markets.' },
    { label: 'Exchange Rate', toolTip: 'Data that covers changes in exchange rates.' },
    { label: 'Fixed Income', toolTip: 'Data that covers the range of fixed payment debt as well as official and market interest rates.' },
    { label: 'GFD Indices', toolTip: `Data that covers GFD's proprietary indexes.` },
    { label: 'Government and Debt', toolTip: 'Data that tracks changes in levels of outstanding government debt.' },
    { label: 'International Trade', toolTip: 'Data that tracks the flow of global economic trade.' },
    { label: 'National Accounts', toolTip: 'Data that tracks national statistics such as GDP and income.' },
    { label: 'Real Estate Database', toolTip: 'Data covering aspects of the global real estate markets such as inventories, sales and valuation.' },
    { label: 'Securities', toolTip: 'Data that covers current and historical stocks' }
]

// All Engines
export const engineToolTipList = [
    { label: 'GFDUniverse', toolTip: 'Quickly search among all the databases available from Global Financial Data (GFD).' },
    { label: 'GFDatabase2Point0', toolTip: 'Our widest collection of data covering macroeconomic and financial trends.' },
    { label: 'GFDatabase', toolTip: 'Our widest collection of data covering macroeconomic and financial trends.' },
    { label: 'GFDIndices', toolTip: 'A collection of our proprietary data that covers a range of macroeconomic and financial trends.' },
    { label: 'RealEstate', toolTip: 'A specific collection that specifically targets trends in Real Estate.' },
    { label: 'USStocks', toolTip: `A collection of historical and current pricing and fundamental data covering the United States and Canada.` },
    { label: 'UKStocks', toolTip: `A collection of historical data covering the exchanges outside of the United States and Canada.` },
    { label: 'ConstituentMembership', toolTip: 'A historical record of the individual companies that comprise major stock indexes.' },
    { label: 'EventsInTime', toolTip: 'A historical record of economic, political and technological events.' }
]

//GFD Universe, GFDatabase, GFD Indices, Real Estate Database, US Stocks, UK Stocks
export const countryGroupToolTipList = [
    { label: 'ALL', toolTip: 'All countries' },
    { label: 'African Union', toolTip: 'Countries that comprise the African Union.' },
    { label: 'APEC', toolTip: 'Countries that comprise the Asia-Pacific Economic Cooperation.' },
    { label: 'BRICS', toolTip: 'Brazil, Russia, India, China, South Africa.' },
    { label: 'Developed Markets', toolTip: 'Countries that have well established economies and financial markets.' },
    { label: 'East Asia & Pacific', toolTip: 'Countries that are geographically located in the East Asia and Pacific regions.' },
    { label: 'Emerging Markets', toolTip: 'Countries that do not have developed economies and financial markets.' },
    { label: 'Equity Developed', toolTip: 'Countries that have well established equity markets.' },
    { label: 'Equity Emerging', toolTip: 'Countries that do not have developed equity markets.' },
    { label: 'Equity Frontier', toolTip: 'Counties that have newly established equity markets.' },
    { label: 'EU-28', toolTip: 'Countries that represent the expanded region of the European Union.' },
    { label: 'Euro-19', toolTip: 'Countries that represent the members European Union that have fully adopted the Euro as their domestic currency.' },
    { label: 'Europe & Central Asia', toolTip: 'Countries that are geographically located in Europe and Central Asia.' },
    { label: 'G-20', toolTip: 'Countries and multinational organizations that comprise the intergovernmental forum of the G-20' },
    { label: 'G-7', toolTip: 'Countries and multinational organizations that comprise the intergovernmental forum of the G-7' },
    { label: 'High income: non-OECD', toolTip: 'Countries who have an average income that are within the higher range of all country-level average incomes and are not part of the Organisation for Economic Cooperation and Development.' },
    { label: 'High income: OECD', toolTip: 'Countries who have an average income that are within the higher range of all country-level average incomes and are members of the Organisation for Economic Cooperation and Development' },
    { label: 'Latin America & Caribbean', toolTip: 'Countries that are geographically located in the Latin America and the Caribbean regions.' },
    { label: 'Low income', toolTip: 'Countries who have a relatively low average income.' },
    { label: 'Lower middle income', toolTip: 'Countries who have an average income that are within the lower middle range of all country-level average incomes.' },
    { label: 'Middle East & North Africa', toolTip: 'Countries that are geographically located in the Middle East and North Africa.' },
    { label: 'North America', toolTip: 'Countries that are geographically located in North America.' },
    { label: 'OECD', toolTip: 'Countries that comprise the Organisation for Economic Cooperation and Development (OECD).' },
    { label: 'OPEC', toolTip: 'Countries that comprise the Organization of the Petroleum Exporting Countries (OPEC).' },
    { label: 'South Asia', toolTip: 'Countries that are geographically located in South Asia.' },
    { label: 'Sub-Saharan Africa', toolTip: 'Countries that are geographically located in Sub-Saharan Africa.' },
    { label: 'Upper middle income', toolTip: 'Countries who have an average income that are within the upper middle range of all country-level average incomes.' },
    { label: 'USSR', toolTip: 'Countries that encompass what was once the Union of Soviet Socialist Republics (USSR).' }
]