import React from "react"
import { ReactComponent as ViewSelectedResultsIcon } from "../../Images/svg/ViewSelectedResults.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ViewSelectedResults = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <ViewSelectedResultsIcon />
  </SvgIcon>
)

export default ViewSelectedResults