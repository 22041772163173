

import { MessageResponse, MessageResponseTypes } from '../../../Models/DataModels/Responses/NotOKResponse'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { Alert, AlertColor, IconButton } from '@mui/material'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { ErrorHandler, ErrorHandlerProps } from './ErrorHandler'
import { Close } from '@mui/icons-material'

export interface ComponentMessageHandlerProps {
    response?: NotOKResponseModel | null,
    signOut: (logoutReason: LogoutReasonType) => void,
    messages: MessageResponse[],
    setMessages: (messages: MessageResponse[]) => void
}

const severity = {
    [MessageResponseTypes.Error]: 'error',
    [MessageResponseTypes.Info]: 'info',
    [MessageResponseTypes.Warning]: 'warning',
    [MessageResponseTypes.Success]: 'success',
    [MessageResponseTypes.Internal]: 'error'
}

export const ComponentMessageHandler = ({
    messages,
    setMessages,
    response,
    signOut
}: ComponentMessageHandlerProps) => {
    const errorHandlerProps: ErrorHandlerProps = {
        response,
        signOut,
        setMessages
    }

    const reset = () => {
        setMessages([])
    }

    return <>
        <div style={{ display: `${messages?.length ? 'flex' : 'none'}`, flexDirection: 'row' }}>
            <div style={{ flexGrow: 1 }}>
                {messages?.map((message, index) => <Alert
                    style={{ padding: '0px 0px 0px 8px' }}
                    key={index}
                    severity={severity[message.type] as AlertColor}
                >
                    {message.message}
                </Alert>)}
            </div>
            <IconButton onClick={reset} style={{ flexGrow: 0 }} title='Close' aria-label='Close'>
                <Close />
            </IconButton>
            <ErrorHandler {...errorHandlerProps} />
        </div>
    </>
}
