import { LoginRequest, RefreshRequest, LogoutRequest, ImpersonateRequest, SwitchToAdminAccountRequest, ForgotPasswordRequest, ResetForgotPasswordRequest, ConfirmResetForgotPasswordRequest, AnonymousLoginRequest } from "../Models/DataModels/Requests/AuthRequests"
import getAPICallResult, { APIRequest, HttpRequest } from "./APIService"

export const loginUser = async (req: LoginRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Auth/Login',
    method: HttpRequest.Post,
    requestObject: req,
    isAnonymous: true
  }
  return getAPICallResult(apiRequest)
}

export const logoutUser = async (req: LogoutRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Auth/Logout',
    method: HttpRequest.Post,
    requestObject: req,
  }
  return getAPICallResult(apiRequest)
}

export const impersonateUser = async (req: ImpersonateRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Auth/Impersonate',
    method: HttpRequest.Post,
    requestObject: req,
  }
  return getAPICallResult(apiRequest)
}

export const switchToAdminAccount = async (req: SwitchToAdminAccountRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Auth/SwitchToAdminAccount',
    method: HttpRequest.Post,
    requestObject: req,
  }
  return getAPICallResult(apiRequest)
}

export const refreshTokenAPICall = async (req: RefreshRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Auth/Refresh',
    method: HttpRequest.Post,
    requestObject: req
  }
  return getAPICallResult(apiRequest)
}

export const CheckAnonymousLoginEnabled = async () => {
  const apiRequst: APIRequest = {
    relativePath: '/Auth/CheckAnonymousLoginEnabled',
    method: HttpRequest.Get,
    isAnonymous: true
  }
  return getAPICallResult(apiRequst, true)
}

export const AnonymousLogin = async (request: AnonymousLoginRequest) => {
  const apiRequst: APIRequest = {
    relativePath: '/Auth/AnonymousLogin',
    method: HttpRequest.Post,
    requestObject: request,
    isAnonymous: true
  }
  return getAPICallResult(apiRequst)
}

export const forgotPassword = async (req: ForgotPasswordRequest) => {
  const apiRequst: APIRequest = {
    relativePath: 'User/forgotPassword',
    method: HttpRequest.Post,
    requestObject: req,
    isAnonymous: true
  }
  return getAPICallResult(apiRequst)
}

export const resetForgotPassword = async (req: ResetForgotPasswordRequest) => {
  const apiRequst: APIRequest = {
    relativePath: 'User/resetForgotPassword',
    method: HttpRequest.Put,
    requestObject: req,
    isAnonymous: true
  }
  return getAPICallResult(apiRequst)
}

export const confirmResetForgotPassword = async (req: ConfirmResetForgotPasswordRequest) => {
  const apiRequest: APIRequest = {
    relativePath: 'User/confirmResetPassword',
    method: HttpRequest.Post,
    requestObject: req,
    isAnonymous: true
  }
  return getAPICallResult(apiRequest)
}

export const checkBetaAccess = () => {
  const apiRequst: APIRequest = {
    relativePath: '/Auth/CheckIfBeta',
    method: HttpRequest.Get,
  }
  return getAPICallResult(apiRequst)
}

const AuthenticationService = () => {
  return (
    <div></div>
  )
}

export default AuthenticationService
