import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material"
import ViewSelectedResults from "../../Icons/ViewSelectedResultsIcon"
import { useLocation, useNavigate } from "react-router-dom"
import { paths } from "../../../Models/DataModels/Common/RedirectionModel"
import DeleteSelected from "../../Icons/DeleteSelectedIcon"
import HomeSearchClearSavedModal, { HomeSearchClearSavedModalProps } from "../Modals/HomeSearchClearSavedModal"
import { getLocalStorageOrDefault, localStorageKeys, removeLocalStorage } from "../../../Services/LocalStorageService"

export interface SelectToolbarProps {
  savedIDs: number[],
  setSavedIDs: (savedIDs: number[]) => void,
  savedResults: Map<number, any>,
  setShowSavedResults: Dispatch<SetStateAction<boolean>>
}

const SelectToolbar = ({
  savedIDs,
  setSavedIDs,
  savedResults,
  setShowSavedResults
}: SelectToolbarProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [showClearSavedModal, setShowClearSavedModal] = useState<boolean>(false)

  const processDeleteAllSavedResults = () => {
    savedResults.clear()
    removeLocalStorage(localStorageKeys.selectedResultsData)
    setSavedIDs([])
  }

  const clearSavedTickersModalProps: HomeSearchClearSavedModalProps = {
    all: true,
    showClearSavedModal,
    setShowClearSavedModal,
    processDeleteAllSavedResults
  }

  useEffect(() => {
    const stored = getLocalStorageOrDefault(localStorageKeys.selectedResultsData, true, null)
    if (!stored) {
      processDeleteAllSavedResults()
    }
  }, [pathname])

  return (
    <React.Fragment>
      <div style={selectToolBarStyles.rowStyle}>
        {savedIDs.length === 0 ? <></> : 
        <Toolbar
          sx={{
            bgcolor: '#ebebf9',
            borderLeft: '0.125em',
            borderLeftColor: '#c9c8c8',
            borderLeftStyle: 'solid',
            marginTop: '4px',
            marginBottom: '10px',
            boxShadow: '1px 2px 3px 0px rgba(0,0,0,0.3)'
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="black"
            variant="h6"
            component="div"
          >
            {savedIDs.length} saved
          </Typography>

          <Tooltip title='View Saved Results'>
            <span>
              <IconButton
                onClick={() => {
                  setShowSavedResults(true)
                  navigate(paths.searchHome.selectedResults)
                }}
                disabled={!savedIDs.length} style={{...savedIDs.length ? {} : { pointerEvents: 'none' }}}
                aria-label='Add to Saved Results'
              >
                <ViewSelectedResults style={{ fontSize: '1.25em' }} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title='Clear Saved Results'>
            <span>
              <IconButton
                onClick={() => {
                  setShowClearSavedModal(true)
                }}
                disabled={!savedIDs.length} style={{...savedIDs.length ? {} : { pointerEvents: 'none' }}}
                aria-label='Clear Saved Results'
              >
                <DeleteSelected style={{ fontSize: '1.25em' }} />
              </IconButton>
              </span>
          </Tooltip>
        </Toolbar>}
      </div>
      
      {showClearSavedModal ? <HomeSearchClearSavedModal {...clearSavedTickersModalProps} /> : <></>}
    </React.Fragment>)
}

const selectToolBarStyles = {
  rowStyle: {
    display: 'flex',
    flex: 'flex',
    flexDirection: 'row' as 'row',
    height: '100%'
  }
}

export default SelectToolbar
