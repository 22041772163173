
import { CSSProperties } from '@mui/material/styles/createTypography'
import GraphAndChart from './GraphAndChart'
import GraphSearch, { GraphSearchProps } from './GraphSearch'
import { GraphRequest } from '../../../Models/DataModels/Requests/GraphRequests'
import { useState } from 'react'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { graphByFileName } from '../../../Services/GraphService'
import { sessionStorageKeys } from '../../../Services/SessionStorageService'
import { convertRequestToPartialModalData } from './GraphAndChartUtil'

export interface GraphAndChartResultsProps {
  checkIsTrial: () => boolean,
  setErrorResponse: (errorResponse: NotOKResponseModel | null) => void,
  setShowLoadingModal: (showLoadingModal: boolean) => void
}

const GraphAndChartResults = ({
  checkIsTrial,
  setErrorResponse,
  setShowLoadingModal
}: GraphAndChartResultsProps) => {
  const [graphData, setGraphData] = useState<any>()

  const viewChart = (graphRequest: GraphRequest) => {
    if (!graphRequest || !graphRequest?.primaryTicker) return

    setShowLoadingModal(true)
    graphByFileName(graphRequest).then(
      (response) => {
        setGraphData(response)
        setShowLoadingModal(false)
        sessionStorage.removeItem(sessionStorageKeys.selectedResultsForGraphData)
      },
      //Reject promise
      (error: NotOKResponseModel) => {
        setGraphData(null)
        setErrorResponse(error)
        setShowLoadingModal(false)
        sessionStorage.removeItem(sessionStorageKeys.selectedResultsForGraphData)
      }
    )
  }

  const modalDataFromPreviousRequest: any = convertRequestToPartialModalData(graphData?.chartRequest || null, graphData?.charts && graphData.charts.length > 0 ? graphData?.charts[0] : null)

  const graphSearchProps: GraphSearchProps = {
    viewChart: viewChart,
    modalDataFromPreviousRequest: modalDataFromPreviousRequest,
    checkIsTrial: checkIsTrial
  }

  return (
    <div style={graphStyles.searchContainer as CSSProperties}>
      <GraphSearch {...graphSearchProps} />
      <GraphAndChart lastResponse={graphData} setErrorResponse={setErrorResponse} setShowLoadingModal={setShowLoadingModal} checkIsTrial={checkIsTrial} />
    </div>
  )
}

const graphStyles = {
  searchInput: {
    marginRight: 5,
    borderColor: '#ccc'
  },
  searchBtn: {
    marginRight: 5,
    borderColor: '#ccc'
  },
  searchRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: 5
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    position: 'relative'
  }
}

export default GraphAndChartResults
