export const spreadAddKeyPropertiesToData = (responseData: any[]): any[] => {
    if (!responseData || responseData.length === 0) {
        return []
    }
    const responseDataWithID: any[] = responseData.map((value: any) => {
        const eIDs: number = value.values.map((item: any) => item.entityID)
        let entityData: any = {}
        value.values.forEach((item: any) => {
            if (item.entityID === 1778) {
                entityData.marketCap = item.value
            }
            if (item.entityID === 1786) {
                entityData.PERatio = item.value
            }
            if (item.entityID === 1812) {
                entityData.beta = item.value
            }
            if (item.entityID === 1800) {
                entityData.momentumM3 = item.value
            }
            if (item.entityID === 1804) {
                entityData.momentumM12 = item.value
            }
            if (item.entityID === 1808) {
                entityData.momentumM36 = item.value
            }
            if (item.entityID === 1782) {
                entityData.divYield = item.value
            }
        })
        return {
            ...value,
            id: value.seriesID,
            entityIDs: eIDs,
            ...entityData
        }
    })

    return responseDataWithID
}
