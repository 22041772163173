import { LabelValuePair } from './LabelValuePairModel'

export const valueIndicatorsLabelValueOptions: LabelValuePair[] = [
    { label: 'None', value: 'none' },
    { label: 'Earnings Per Share', value: 'earn_per_share_ttm' },
    { label: 'Dividends Per Share', value: 'dividend_per_share_ttm' },
    { label: 'Dividend Yield', value: 'dividend_yield' },
    { label: 'Market Cap', value: 'SMCAP' },
    { label: 'Payout Ratio', value: 'payout_ratio' },
    { label: 'Price to Earnings Ratio', value: 'pe_ratio_ttm' },
    { label: 'Total Shares Outstanding', value: 'total_shares_outstanding' }
]

export const financialRatiosLabelValueOptions: LabelValuePair[] = [
    { label: 'None', value: 'none' },
    { label: 'Book Value Per Share', value: 'book_value_per_share_ttm' },
    { label: 'Cash Flow Per Share', value: 'cash_flow_per_share_ttm' },
    { label: 'Revenues Per Share', value: 'revenue_per_share_ttm' },
    { label: 'Asset Turnover', value: 'asset_turnover_ttm' },
    { label: 'Current Ratio', value: 'current_ratio_mrq' },
    { label: 'EBIT Profit Margin', value: 'ebit_profit_margin_ttm' },
    { label: 'Effective Tax Rate', value: 'effective_tax_rate_ttm' },
    { label: 'Gross Profit Margin', value: 'gross_profit_margin_ttm' },
    { label: 'Interest Coverage Ratio', value: 'interest_coverage_ttm' },
    { label: 'Long-term Debt to Equity', value: 'lt_debt_to_equity_mrq' },
    { label: 'Net Profit Margin', value: 'net_profit_margin_ttm' },
    { label: 'NWC Turnover', value: 'nwc_turnover_ttm' },
    { label: 'Operating Profit Margin', value: 'operating_profit_margin_ttm' },
    { label: 'Price to Book Ratio', value: 'price_book_mrq' },
    { label: 'Price to Cash Flow Ratio', value: 'price_cash_flow_ttm' },
    { label: 'Price to Sales Ratio', value: 'price_sales_ttm' },
    { label: 'Price to Tangible Book Ratio', value: 'price_tangible_book_mrq' },
    { label: 'Quick Ratio', value: 'quick_ratio_mrq' },
    { label: 'Return On Equity', value: 'return_on_equity_ttm' },
]

export const additionalChartNames = {
    technicalIndicator: 'TechnicalIndicator',
    valueIndicator: 'ValueIndicator',
    financialRatio: 'FinancialRatio'
}
