import { PopulateConstituentIndicesRequest, PopulateConstituentSubIndicesRequest, PopulateCountryRequest, PopulateEquityTypesRequest, PopulateExchangeTypesRequest, PopulateIndustryRequest, PopulateMainIndicatorsRequest, PopulateSectorsRequest, PopulateSeriesCategoryRequest, PopulateSeriesTypeRequest, PopulateCurrencyRequest, CountryGroupRequest } from "../Models/DataModels/Requests/PopulationRequests"
import getAPICallResult, { APIRequest, HttpRequest } from "./APIService"

export const populateCountry = async (request: PopulateCountryRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateCountry/' + request.database,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateCountry2D = async (request: PopulateCountryRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateCountry2D/' + request.database,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateSeriesCategory = async (request: PopulateSeriesCategoryRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateSeriesCategory/' + request.database,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateSeriesType = async (request: PopulateSeriesTypeRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateSeriesType',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const populateMainIndicators = async (request: PopulateMainIndicatorsRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateMainIndicators/' + request.database,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateSectors = async (request: PopulateSectorsRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateSectors/' + request.database,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateExchangeType = async (request: PopulateExchangeTypesRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateExchanges/' + request.database,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateIndustry = async (request: PopulateIndustryRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateIndustry',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}


export const populateEquityTypes = async (request: PopulateEquityTypesRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateEquityTypes/' + request.database,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateEventTypes = async () => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/populateEventTypes',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateEventTopics = async () => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateEITTopics',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateConstituentIndices = async (request: PopulateConstituentIndicesRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateConstituentMemberIndices/' + request.category,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateConstituentSubIndices = async (request: PopulateConstituentSubIndicesRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateConstituentMemberSubIndices/' + request.index,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateCurrencies = async (request: PopulateCurrencyRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateCurrency/' + request.group,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateCountryForYieldCurve = async () => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateCountryForYieldCurve',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateCAPERatioIndices = async () => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateCapeRatioIndexes',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const populateCountryExchange = async () => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/PopulateCountryExchange',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const selectCountryGroup = async (request:CountryGroupRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Metadata/CountryGroups',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const getCountryGroups = async () => {
    const apiRequest: APIRequest = {
        relativePath: '/Metadata/GetCountryGroups',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const getCountriesByGroupYieldCurve = async (request:CountryGroupRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Metadata/CountriesforYieldCurveGroups',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)

}

export const getDropdownFieldsLastUpdated = async () => {
    const apiRequest: APIRequest = {
        relativePath: '/Reference/dropdownFieldsLastUpdated',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}
