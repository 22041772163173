export type WorksheetFormatDisplayType = 'Stacked' | 'Pivoted'
export const WorksheetFormatDisplay = {
    Stacked: 'Stacked' as WorksheetFormatDisplayType,
    Pivoted: 'Pivoted' as WorksheetFormatDisplayType
}

export type WorksheetFormatValueType = true | false
export const WorksheetFormatValue = {
    Stacked: false as WorksheetFormatValueType,
    Pivoted: true as WorksheetFormatValueType
}

export const WorksheetFormatModel = {
    [WorksheetFormatDisplay.Stacked]: WorksheetFormatValue.Stacked,
    [WorksheetFormatDisplay.Pivoted]: WorksheetFormatValue.Pivoted
}
export default WorksheetFormatModel
