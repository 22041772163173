import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { ALLCountryGroupEntry, SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import DateYearRangeInput from './DateYearRangeInput'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import { getLocallyStoredCountriesByGroup, getLocallyStoredCountryGroups, getLocallyStoredEquityTypes, getLocallyStoredExchangeTypes, getLocallyStoredIndustries, getLocallyStoredSectors } from '../../../Services/DropDownValuesService'
import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { equityTypeToolTipList, countryGroupToolTipList } from '../../Common/Utility/SearchPageToolTips'
import { renderAutoComputeOption } from './GFDatabase2Point0'

const UKStocks = ({ searchEngineOptions, theme }: any) => {

  const [countryGroupList, setCountryGroupList] = useState<LabelValuePair[]>([ALLCountryGroupEntry])
  const [countriesByGroup, setCountriesByGroup] = useState<LabelValuePair[]>([])
  const [equityTypeList, setEquityTypeList] = useState<LabelValuePair[]>([])
  const [exchangeList, setExchangeList] = useState<LabelValuePair[]>([])
  const [sectorList, setSectorList] = useState<LabelValuePair[]>([])
  const [industryList, setIndustryList] = useState<LabelValuePair[]>([])
  const [equityTypeToolTip, setEquityTypeToolTip] = useState<string>('')
  const [countryGroupToolTip, setCountryGroupToolTip] = useState<string>('')

  const securitiesCategoryList = [{ label: 'Securities - Current', value: 'Securities - Current' },
  { label: 'Securities - Delisted', value: 'Securities - Delisted' }]

  useEffect(() => {
    getLocallyStoredCountryGroups().then((value: LabelValuePair[] | null) => value && setCountryGroupList(value), () => { })
    getLocallyStoredExchangeTypes(SearchDatabaseTypes.UKStocks).then((value: LabelValuePair[] | null) => value && setExchangeList(value), () => { })
    getLocallyStoredSectors(SearchDatabaseTypes.UKStocks).then((value: LabelValuePair[] | null) => value && setSectorList(value), () => { })
    getLocallyStoredIndustries(SearchDatabaseTypes.UKStocks, '').then((value: LabelValuePair[] | null) => value && setIndustryList(value), () => { })
    getLocallyStoredEquityTypes(SearchDatabaseTypes.UKStocks).then((value: LabelValuePair[] | null) => value && setEquityTypeList(value), () => { })
  }, [])

  useEffect(() => {
    getLocallyStoredIndustries(SearchDatabaseTypes.UKStocks, searchEngineOptions.sector).then((value: LabelValuePair[] | null) => value && setIndustryList(value), () => { })
  }, [searchEngineOptions.sector])

  useEffect(() => {
    getLocallyStoredCountriesByGroup(searchEngineOptions.countryGroup).then((value: LabelValuePair[] | null) => value && setCountriesByGroup(value), () => { })
  }, [searchEngineOptions.countryGroup])

  useEffect(() => {
    const toolTip: string = equityTypeToolTipList.find((element: any) => element.label === searchEngineOptions?.equityType)?.toolTip || ''
    setEquityTypeToolTip(toolTip)
  }, [searchEngineOptions.equityType])

  useEffect(() => {
    const toolTip: string = countryGroupToolTipList.find((element: any) => element.label === searchEngineOptions?.countryGroup)?.toolTip || ''
    setCountryGroupToolTip(toolTip)
  }, [searchEngineOptions.countryGroup])

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              options={countryGroupList}
              defaultValue={ALLCountryGroupEntry}
              isOptionEqualToValue={(option: any, value) => option?.value === value?.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setCountryGroup(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label={<React.Fragment>
                    <span>Filter by Country Group </span>
                    {searchEngineOptions.countryGroup &&
                      <Tooltip title={countryGroupToolTip}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    }
                  </React.Fragment>}
                  size='small'
                />
              }
              renderOption={renderAutoComputeOption}
              sx={UKStockStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectCountry'
              options={countriesByGroup}
              isOptionEqualToValue={(option: any, value) => option.value === value.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof value?.value ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setCountry(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Filter by Country of Incorporation'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={UKStockStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectSecuritiesCategory'
              options={securitiesCategoryList}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof value?.value ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setSecuritiesCategory(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Select Current or Delisted'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={UKStockStyles.wholeLineInput}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectEquityType'
              options={equityTypeList}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof value?.value ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setEquityType(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label={<React.Fragment>
                    <span>Filter by Equity Type </span>
                    {searchEngineOptions.equityType &&
                      <Tooltip title={equityTypeToolTip}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    }
                  </React.Fragment>
                  }
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={UKStockStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectExchange'
              options={exchangeList}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof value?.value ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setExchange(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Filter by Exchange Type'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={UKStockStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectSector'
              value={searchEngineOptions.sector}
              options={sectorList}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof value?.value ? actualValue = value?.value : actualValue = ''
                if (searchEngineOptions.industry !== '') {
                  searchEngineOptions.setIndustry('')
                }
                searchEngineOptions.setSector(actualValue || '')
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Filter by Sector'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={UKStockStyles.wholeLineInput}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectIndustry'
              value={searchEngineOptions.industry}
              options={industryList}
              onChange={(e, value) => {
                let actualValue: any = ''
                value?.value ? actualValue = value?.value : actualValue = ''
                if (searchEngineOptions.sector === '') {
                  searchEngineOptions.setSector(value?.meta)
                }
                searchEngineOptions.setIndustry(actualValue || '')
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Filter by Industry'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={UKStockStyles.wholeLineInput}
            />
          </Grid>
          <DateYearRangeInput setIsDatesValid={searchEngineOptions.setDatesValid} setValidStartYear={searchEngineOptions.setStartYear} setValidEndYear={searchEngineOptions.setEndYear} setIsOnlyInYearsRange={searchEngineOptions.setIsOnlyInYearsRange} startYearGridItemSizeOverride={2} EndYearGridItemSizeOverride={2} inRangeCheckboxGridItemSizeOverride={3} />
        </Grid>
      </div>
    </ThemeProvider >
  )
}

const UKStockStyles = {
  row: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap'
  },
  wholeLineInput: {
    paddingBottom: 1,
    width: '100%'
  }
}

export default UKStocks
