import { Box } from '@mui/material'
import React from 'react'
import { ImageNumberReference, MarkedRedRedText } from '../../HelpPageCommonComponents'

const USUKSearch = () => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>US and UK Search Engines</h2>
                </div>

            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h4>US Stock Options</h4>
                </div>
            </Box>
            <div style={{ textAlign: 'left' }}>
                <p>
                    Both US and UK Stock Engines will have a new drop down which will appear next to the search bar (see image below):
                </p>
                <div style={{ height: '621px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/USUKSearch01.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='US and UK Search Engines Help 1'></img>
                </div>
                <p style={{ paddingTop: 25 }}>
                    Above highlighted in <MarkedRedRedText /> ({ImageNumberReference(1)}), you can see the new dropdown called "Search Field Type", with it you can change what your search term means to the search engine.  If you would like to use CIK number or SIC number to search for stocks, you can change it to your desired type and search using the numbers.
                </p>
                <p>
                    For US stocks we have the following types:
                </p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Ticker</span> We also call this symbol or series, this is the normal stock ticker you would use when looking for a stock while trading, example would be "IBM".</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>CIK</span> A Central Index Key or CIK number is a unique number assigned to an individual, company, filing agent or foreign government by the United States Securities and Exchange Commission. Example: 0000789019 for    "MICROSOFT CORP"</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>CUSIP</span> A CUSIP is a nine-character numeric or alphanumeric code (e.g. 037833100 for Apple) that uniquely identifies a North American financial security for the purposes of facilitating clearing and settlement of trades.</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>SIC</span> The Standard Industrial Classification (SIC) was a system for classifying industries by a four-digit code as a method of standardizing industry classification for statistical purposes across agencies.  Its no longer actively updated since 1987, but it can be useful for narrowing down industries.  It has since been replaced by been replaced by the North American Industry Classification System (NAICS code)</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>NAICS</span> The North American Industry Classification System or NAICS is a classification of business establishments by type of economic activity (the process of production). It is used by government and business in Canada, Mexico, and the United States of America. It has largely replaced the older Standard Industrial Classification (SIC) system.  Like the SIC, this can be useful in narrowing down any industries of interest for your search.</p>


                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                        m: 1,
                        borderRadius: 1
                    }}>
                    <div>
                        <h4>UK Stock Options</h4>
                    </div>
                </Box>
                <div style={{ height: '505px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/USUKSearch02.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='US and UK Search Engines Help 2'></img>
                </div>
                <p style={{ paddingTop: 25 }}>
                    Above highlighted in <MarkedRedRedText /> ({ImageNumberReference(1)}), you can see the new dropdown called "Search Field Type", with it you can change what your search term means to the search engine.  If you would like to use CIK number or SIC number to search for stocks, you can change it to your desired type and search using the numbers.
                </p>
                <p>
                    For the UK stocks we have American identifiers.  This is because certain UK stocks are currently listed or have been listed in the Americas at some point, so this is why we have some with SIC and CIK and NACIS.  UK stocks we have the following types:
                </p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Ticker</span> We also call this symbol or series, this is the normal stock ticker you would use when looking for a stock while trading, example would be "IBM".</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>CIK</span> A Central Index Key or CIK number is a unique number assigned to an individual, company, filing agent or foreign government by the United States Securities and Exchange Commission. Example: 0000789019 for    "MICROSOFT CORP"</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>SIC</span> The Standard Industrial Classification (SIC) was a system for classifying industries by a four-digit code as a method of standardizing industry classification for statistical purposes across agencies.  Its no longer actively updated since 1987, but it can be useful for narrowing down industries.  It has since been replaced by been replaced by the North American Industry Classification System (NAICS code)</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>NAICS</span> The North American Industry Classification System or NAICS is a classification of business establishments by type of economic activity (the process of production). It is used by government and business in Canada, Mexico, and the United States of America. It has largely replaced the older Standard Industrial Classification (SIC) system.  Like the SIC, this can be useful in narrowing down any industries of interest for your search.</p>

                <p>Please note we do not offer CUSIP searches for UK stocks.</p>
            </div>
        </>
    )
}

export default USUKSearch