import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'
import { ItalicizedWorkbookText } from '../../../HelpPageCommonComponents'

const DownloadQueueTable = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Download Queue Table</h4>
                </div>
            </Box>
            <div style={{ height: '761px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_DownloadQueueTable.png' style={helpPageStyles.image} alt='Advanced AutoTrac Download Queue Table'></img>
            </div>
            <p>
                This section provides explanation about Download Queue Table. All the download that were queued appears here along with the status.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Viewing Download Queue Table</h5>
                </div>
            </Box>
            <div style={{ height: '166px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_DownloadQueueTable_Expand.png' style={helpPageStyles.image} alt='Advanced AutoTrac Download Queue Table Expand'></img>
            </div>
            <p>
                If the download queue table is not displayed, click on "Download Queue" to expand the table.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Download Queue Table Columns</h5>
                </div>
            </Box>
            <div style={{ height: '633px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_DownloadQueueTable_TableStatus.png' style={helpPageStyles.image} alt='Advanced AutoTrac Download Queue Table Table Status'></img>
            </div>
            <ul>
                <li>
                    <b>Workbook Name</b>
                    <ul>
                        <li>
                            The name of the <ItalicizedWorkbookText />. The name would turn to a green underlined text once the <ItalicizedWorkbookText /> is ready for download.
                            You can click on the <ItalicizedWorkbookText /> name to download the processed data.
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Create Time</b>
                    <ul>
                        <li>
                            The time when the download item was created and had the status of "Initialized".
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Start Time</b>
                    <ul>
                        <li>
                            The time when the download started and had the status of "Processing".
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Finish Time</b>
                    <ul>
                        <li>
                            The time when the download finished and had the status of "Finished".
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Status</b>
                    <ul>
                        <li>
                            The status of the download. When the download item is created, the download item will have a status of "Initialized".
                            Once the system starts processing the download, it will have the status of "Processing". After the system completed the processing of output data file and is ready for download,
                            it will have the status of "Finished".
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Settings</b>
                    <ul>
                        <li>
                            Clicking on View link will display the modal with the download options that were set for the download item.
                            Note: View link is available after the download is "Finished" and modal that appear is view only.
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Source</b>
                    <ul>
                        <li>
                            Source shows where the download was started.
                            <ul>
                                <li>
                                    <b>Workbook</b>
                                    <ul>
                                        <li>Download was queued from AutoTrac.</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>OnDemand</b>
                                    <ul>
                                        <li>Download was queued from other areas of the site (i.e. Search Results, Selected Results).</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>PowerDownload</b>
                                    <ul>
                                        <li>Download was queued from PowerDownload.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default DownloadQueueTable
