import moment, { Moment } from 'moment'
import { SortOrderType } from './SortModel'
import { PaginationRequest } from '../Requests/SearchRequests'
import { PaginationResponse } from '../Responses/SearchResponses'

export enum AccessCacheType {
    All = 'All',
    SpecificSet = 'SpecificSet',
    SpecificSeries = 'SpecificSeries'
}

export enum AccessCacheUpdateStatus {
    Ready = 'Ready',
    Processing = 'Processing',
    Done = 'Done',
    Failed = 'Failed',
    Cancelled = 'Cancelled'
}

export enum AccessCacheQueueSortFieldEnum
{
    None = 'None',
    QueueID = 'QueueID',
    CacheType = 'CacheType',
    CacheID = 'CacheID',
    Status = 'Status',
    Created = 'Created',
    Updated = 'Updated',
    Creator = 'Creator',
    Updater = 'Updater',
    Message = 'Message'
}
  
export const AccessCacheQueueFieldNames = {
    QueueID: 'queueID',
    CacheType: 'cacheType',
    CacheID: 'cacheID',
    Status: 'status',
    Created: 'created',
    Updated: 'updated',
    Creator: 'creator',
    Updater: 'updater',
    Message: 'message'
}
  
export const AccessCacheQueueSortFields: {[key: string]: AccessCacheQueueSortFieldEnum } = {
    [AccessCacheQueueFieldNames.CacheType]: AccessCacheQueueSortFieldEnum.CacheType,
    [AccessCacheQueueFieldNames.CacheID]: AccessCacheQueueSortFieldEnum.CacheID,
    [AccessCacheQueueFieldNames.Status]: AccessCacheQueueSortFieldEnum.Status,
    [AccessCacheQueueFieldNames.Updated]: AccessCacheQueueSortFieldEnum.Updated,
    [AccessCacheQueueFieldNames.Updater]: AccessCacheQueueSortFieldEnum.Updater,
    [AccessCacheQueueFieldNames.Created]: AccessCacheQueueSortFieldEnum.Created,
    [AccessCacheQueueFieldNames.Creator]: AccessCacheQueueSortFieldEnum.Creator
}
  
export const AccessCacheQueueFields = Object.keys(AccessCacheQueueSortFields)

export interface AccessCacheQueueSortProps {
    sortField: AccessCacheQueueSortFieldEnum,
    sortOrder: SortOrderType
}

export interface AccessCacheUpdateQueue
{
    queueID: number,
    cacheID?: number,
    cacheType?: AccessCacheType,
    status: AccessCacheUpdateStatus,
    created: Date,
    creator: string,
    updated: Date,
    updater: string,
    message: string
}

export interface AccessCacheQueueRequest {
    queueID?: number,
    cacheID?: number,
    cacheType?: AccessCacheType,
    status?: AccessCacheUpdateStatus,
    pagination?: PaginationRequest,
    sortField?: AccessCacheQueueSortFieldEnum,
    sortOrder?: SortOrderType
}

export interface AccessCacheQueueListResponse
{
    accessCacheQueueItems: AccessCacheUpdateQueue[],
    pagination: PaginationResponse,
    sortField: AccessCacheQueueSortFieldEnum,
    sortOrder: SortOrderType
}
