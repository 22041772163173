import React from 'react'
import AlertModal, { AlertButtonType, AlertModalProps } from '../AlertModal'
import { AppConfigurations } from '../../../../Models/DataModels/Common/AppConfigurationsModel'

export enum LegalTypes {
    Privacy,
    Cookie,
    Terms
}

export interface LegalMetadata {
    url: string,
    title: string
}

export interface LegalModalProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    type: LegalTypes
}

const LegalModal = ({
    showModal,
    setShowModal,
    type
}: LegalModalProps) => {

    const getLegalContents = (legalType: LegalTypes): LegalMetadata => {
        let metaData: LegalMetadata
        switch (legalType) {
            case LegalTypes.Privacy:
                metaData = {
                    url: AppConfigurations.privacyPolicyPDFURL,
                    title: 'Privacy Policy'
                }
                break
            case LegalTypes.Cookie:
                metaData = {
                    url: AppConfigurations.cookiePolicyPDFURL,
                    title: 'Cookie Policy'
                }
                break
            case LegalTypes.Terms:
                metaData = {
                    url: AppConfigurations.termsPDFURL,
                    title: 'Terms of Use'
                }
                break
            default:
                metaData = {
                    url: AppConfigurations.termsPDFURL,
                    title: 'Terms of Use'
                }
                break
        }
        return metaData
    }

    const metaData: LegalMetadata = getLegalContents(type)

    const LegalContents = (): JSX.Element => {
        return (
            <object width='100%' height='1000' data={metaData.url} type='application/pdf' aria-label='legal contents'>
                <p><a href='https://globalfinancialdata.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>Privacy Policy</a></p>
                <p><a href='https://globalfinancialdata.com/privacy-policy/#cookies' target='_blank' rel='noopener noreferrer'>Cookie Policy</a></p>
                <p><a href='https://www.globalfinancialdata.com/terms-of-use/' target='_blank' rel='noopener noreferrer'>Terms and Conditions</a></p>
            </object>
        )
    }

    const alerModelProps: AlertModalProps = {
        showModal: showModal,
        setShowModal: setShowModal,
        AlertTitle: metaData.title,
        AlertContent: <LegalContents />,
        AlertButtons: [
            {
                type: AlertButtonType.Cancel,
                display: 'Close',
                onClick: () => {
                    setShowModal(false)
                },
                isPrimary: true
            }
        ],
        Size: 'xl'
    }

    return <AlertModal {...alerModelProps} />
}

export default LegalModal
