import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useState } from 'react'
import styled from '@emotion/styled'
import { CustomBtnBlue, CustomBtnCloseCancel, CustomToggle } from '../GlobalSettings/CustomStyles'

const SearchOptionModal = ({ modalProps, searchOptionsProps }: any) => {

  const [_searchOption, _setSearchOption] = useState<string>(searchOptionsProps.searchOptionsData.searchOption)
  const [_sortOption, _setSortOption] = useState<string>(searchOptionsProps.searchOptionsData.sortOption)

  const handleClose = () => {
    modalProps.setShowModal(false)
  }
  const handleSave = () => {
    modalProps.setShowModal(false)
  }

  const handleSetSearchOption = (event: React.MouseEvent<HTMLElement>, newSearchOption: string) => {
    if (newSearchOption !== null) {
      searchOptionsProps.setSearchOption(newSearchOption)
      _setSearchOption(newSearchOption)
    }
  }

  const handleSetSortOption = (event: React.MouseEvent<HTMLElement>, newSortOption: string) => {
    if (newSortOption !== null) {
      searchOptionsProps.setSortOption(newSortOption)
      _setSortOption(newSortOption)
    }
  }

  return (
    <>
      <Modal show={modalProps.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalProps.modalDescription}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToggleButtonGroup
            color='primary'
            value={_searchOption}
            exclusive
            onChange={handleSetSearchOption}
            sx={{ paddingBottom: 1 }}>
            <CustomToggle value='contains'>Contains</CustomToggle>
            <CustomToggle value='startsWith'>Starts With</CustomToggle>
            <CustomToggle value='exactMatch'>Exact Match</CustomToggle>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            color='primary'
            value={_sortOption}
            exclusive
            onChange={handleSetSortOption}
            sx={{ paddingBottom: 1 }}>
            <CustomToggle value='popular'>Top GFD Downloads First</CustomToggle>
            <CustomToggle value='alphabetical'>Sort Alphabetically</CustomToggle>
          </ToggleButtonGroup>
          
        </Modal.Body>
        <Modal.Footer>
        <CustomBtnCloseCancel variant='outlined' onClick={handleClose}>
            Close
          </CustomBtnCloseCancel>
          <CustomBtnBlue variant='contained' onClick={handleSave}>
            Save Changes
          </CustomBtnBlue>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default SearchOptionModal