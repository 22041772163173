import { AccessCriterionResponse, UserAccessActivityResponse, UserAccessCollectionResponse } from '../Responses/AccountResponses'
import moment, { Moment } from 'moment'
import Activity from '../../../Components/Account/AccountSubComponents/Activity'

export interface AccessRule {
    SeriesGroups: string[]
    ComponentsIncluded: string[]
    ComponentsExcluded: string[]
    ActionsIncluded: string[]
    ActionsExcluded: string[]
    GeographicRanges: AccessCriterion[]
    TemporalRanges: AccessCriterion[]
    AllCriteria: AccessCriterion[]
}

export interface AccessSet {
    Name: string
    SetID: number
    AccessRules: AccessRule[]
    SetDownloadLimits: string[]
}

export interface AccessCriterion {
    CriterionName: string
    CriterionValue: string
    Exclude: boolean
}

export interface UserAccess {
    SetID: number
    Expires?: Moment
}

export interface UserAccessCollection {
    AccessSets: AccessSet[]
    UserAccesses: UserAccess[]
    DownloadLimits: string[]
}

export interface UserAccessActivity {
    period: string
    logins: number
    seriesGraphed: number
    gfdDownloaded: number
    usStocksDownloaded: number
    ukStocksDownloaded: number
    realEstateDownloaded: number
    gfdIndicesDownloaded: number
    eventsInTimeDownloaded: number
    customIndexDownloaded: number
}

export const LoadAccessCriterionResponse = (list: AccessCriterionResponse[]): AccessCriterion[] | undefined => {
    if (!list) return undefined
    return list.map(response => {
        return {
            CriterionName: response.criterionName,
            CriterionValue: response.criterionValue,
            Exclude: response.exclude
        } as AccessCriterion
    })
}

export const YearsAbsolute = "Years Absolute"
export const YearsRelative = "Years Relative"

export const MakeVerboseTemporalRange = (criteria: AccessCriterion[]) => {
    criteria?.forEach(criterion => {
        const years = criterion?.CriterionValue?.split('..')
        let year1
        let year2
        if (years?.length >= 2) year2 = years[1]
        if (years?.length >= 1) year1 = years[0]

        let value = criterion.CriterionValue

        if (criterion?.CriterionName === YearsAbsolute) {
            value = (year1 ? `From ${year1}` : `Everything`) + 
                (year2 ? ` until ${year2}` : ` onward`)
        } else if (criterion?.CriterionName === YearsRelative) {
            value = year1 ? (
                        year2 ? `From ${year1} year(s) ago until ${year2} year(s) ago`
                        : `Last ${year1} years ago`
                    ) : 
                    (year2 ? `Everything until ${year2} year(s) ago` : 'Everything onward')
        }
        criterion.CriterionValue = value
    })
}

export const LoadAccessInfoResponse = (response: UserAccessCollectionResponse): UserAccessCollection | undefined => {
    if (!response) return undefined
    const model: UserAccessCollection = {
        AccessSets: response.accessSets.map(set => {
            return {
                Name: set.name,
                SetID: set.setID,
                AccessRules: set.accessDetails.map(rule => {
                    const accessRule = {
                        SeriesGroups: rule.seriesGroups.reduce((accum: string[], group) => {
                            if (group !== null)
                            {
                                accum.push(group)
                            }
                            return accum
                        }, []),
                        ComponentsIncluded: rule.componentsIncluded,
                        ComponentsExcluded: rule.componentsExcluded,
                        ActionsIncluded: rule.actionsIncluded,
                        ActionsExcluded: rule.actionsExcluded,
                        GeographicRanges: LoadAccessCriterionResponse(rule.geographicRanges),
                        TemporalRanges: LoadAccessCriterionResponse(rule.temporalRanges),
                        AllCriteria: LoadAccessCriterionResponse(rule.allCriteria)
                    } as AccessRule
                    MakeVerboseTemporalRange(accessRule.TemporalRanges)
                    return accessRule
                }),
                SetDownloadLimits: [ ...set.setDownloadLimits ]
            } as AccessSet
        }),
        UserAccesses: response.userAccesses.map((access): UserAccess => {
            return {
                SetID: access.setID,
                Expires: access.expiryDate
            } as UserAccess
        }),
        DownloadLimits: [
            ...response.downloadLimits
        ]
    }
    
    return model
}

export const LoadActivityInfoResponse = (response: UserAccessActivityResponse[]): UserAccessActivity[] => {
    let model: UserAccessActivity[] = []

    if (!response || response.length === 0) return model

    model = response.map(activity => {
        
        const activityModel: UserAccessActivity = {
            period: moment([activity.year, activity.month-1]).format('MMMM YYYY'),
            ...activity
        }

        return activityModel as UserAccessActivity
    })

    return model
}
