import { CompositeWeightValueType } from "../Common/CompositeWeightModel"
import { DataFrequencyValueType } from "../Common/DataFrequencyModel"
import { DateFormatValueType } from "../Common/DateFormatModel"
import { OutputFormatValueType } from "../Common/OutputFormatModel"
import { CustomIndexSortFieldType, SortFieldType, SortOrderType } from "../Common/SortModel"
import { ScreenStatisticsRequest } from "./ScreeningRequests"
import { PaginationRequest } from "./SearchRequests"

export interface CurrencyConversionDataCountRequest {
    source: string,
    targetList: string[]
}

export interface CurrencyConversionDownloadRequest {
    source: string,
    targetList: string[],
    splitAdjusted?: boolean,
    average?: boolean,
    periodPctChg?: boolean,
    annualPctChg?: boolean,
    periodicity?: DataFrequencyValueType,
    dateFormat?: DateFormatValueType,
    outputFormat?: OutputFormatValueType
}

export interface CurrencyConversionChartRequest {
    source: string,
    targetList: string[],
    splitAdjusted?: boolean,
    periodicity?: DataFrequencyValueType,
    dateFormat?: DateFormatValueType,
    startDate?: string,
    endDate?: string
}

export interface CreateCurrencyConversionChartDownloadDataRequest {
    chartRequest: CurrencyConversionChartRequest,
    outputFormat: OutputFormatValueType
}

export interface GetCapitalizationListRequest {
    date?: Date,
    startDate?: Date,
    index: string,
    operatingCountry: string,
    listingCountry: string,
    sector: string,
    industry: string,
    currency: string,
    dateFormat?: DateFormatValueType
}

// Summary needs to be a string intead of enum
export interface GetCapitalizationSummaryRequest {
    date?: Date,
    index: string,
    operatingCountry: string,
    listingCountry: string,
    sector: string,
    industry: string,
    currency: string,
    summary: string,
    dateFormat?: DateFormatValueType
}

export interface GetCapitalizationSummaryChartRequest extends GetCapitalizationSummaryRequest {
    rangeStartYear: number,
    rangeEndYear: number
}

export type CapeRequestType = 'Data' | 'Download'
export const CapeRequestTypes = {
    Data: 'Data' as CapeRequestType,
    Download: 'Download' as CapeRequestType
}

export interface CreateCapitalizationSummaryChartDownloadDataRequest {
    chartRequest: GetCapitalizationSummaryChartRequest,
    outputFormat: OutputFormatValueType
}

export interface CapeParameterSet {
    years: number[],
    index: string
}

export interface CAPERatioRequest {
    requestType: CapeRequestType
    parameterSetList: CapeParameterSet[],
    isExcessCAPEYield: boolean,
    fetchBearMarketEvents: boolean,
    startDate?: Date,
    endDate?: Date
    outputFormat?: string
    dateFormat?: DateFormatValueType
}

export interface FileDownloadRequest {
    filename: string,
    mimeType: string
}

export interface CustomIndicesRequest {
    pagination: PaginationRequest | null,
    sortField: CustomIndexSortFieldType,
    sortOrder: SortOrderType,
    searchTerm?: string | null,
    excludeFamaFrenchIndices?: boolean,
    dateFormat?: DateFormatValueType
}

export interface CustomIndexSaveRequest {
    customIndexID?: number,
    screenReportID?: number,
    screeningParameters?: ScreenStatisticsRequest,
    symbol?: string,
    description?: string,
    weight?: CompositeWeightValueType,
    periodicity?: DataFrequencyValueType,
    isTotalReturn?: boolean,
    startDate?: Date,
    generateIndex: boolean,
    generateNow: boolean,
    addMembers?: string[],
    removeMembers?: number[]
}

export interface CustomIndexMembersRequest {
    customIndexID: number,
    pagination: PaginationRequest,
    sortField: SortFieldType,
    sortOrder: SortOrderType
}

export interface CustomIndexDeleteRequest {
    customIndexIDs: number[]
}

export interface CustomIndexRequest {
    customIndexID: number
}

export interface SyncCustomIndexRequest {
    screenReportID?: number,
    customIndexID?: number
}

export interface CreateDataFileRequest {
    fileName: string,
    fileData: string[][],
    outputFormat: OutputFormatValueType
}
