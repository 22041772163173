import React, { ReactNode, useState } from 'react'
import AlertModal, { AlertButtonType, AlertModalProps } from './AlertModal'
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import DateFormatModel, { DateFormatValue, DateFormatValueType } from '../../../Models/DataModels/Common/DateFormatModel'

export interface ExportOutputFormatSelectionModalProps {
    show: boolean,
    setShow: (show: boolean) => void,
    exportAction: (outputFormat: DateFormatValueType) => void
}

const ExportOutputFormatSelectionModal = ({
    show,
    setShow,
    exportAction
}: ExportOutputFormatSelectionModalProps) => {

    const [dateFormat, setDateFormat] = useState(DateFormatValue.AmericanWithDashes)

    const renderDateFormat = () => {
        return Object.keys(DateFormatModel).map(
            (format: any, index: number) => <MenuItem key={format} value={DateFormatModel[format]}>{format}</MenuItem>
        )
    }

    const handleChangeDateFormat = (
        event: SelectChangeEvent<DateFormatValueType>,
        child: ReactNode,
    ) => {
        setDateFormat(event.target.value as DateFormatValueType)
    }

    const OutputFormatSelection = (): JSX.Element => {
        return (
            <Grid container justifyContent='center'>
                <Grid item>
                    <FormControl variant='outlined' sx={{ minWidth: 200, marginTop: '8px', marginBottom: '8px' }}>
                        <InputLabel id='date-format-select-label'>Date Format</InputLabel>
                        <Select
                            labelId='date-format-select-label'
                            id='date-format-select'
                            value={dateFormat}
                            label='Date Format'
                            onChange={handleChangeDateFormat}
                        >
                            {renderDateFormat()}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    const alermodalProps: AlertModalProps = {
        showModal: show,
        setShowModal: setShow,
        onAlertClose: () => false,
        AlertTitle: 'Select Export output format',
        AlertContent: <OutputFormatSelection />,
        Size: 'lg',
        showCloseButton: false,
        centered: true,
        AlertButtons: [
            {
                type: AlertButtonType.OK,
                display: 'Export',
                onClick: () => {
                    exportAction(dateFormat)
                    setShow(false)
                },
                isPrimary: true,
            },
            {

                type: AlertButtonType.Cancel,
                display: 'Cancel',
                onClick: () => {
                    setShow(false)
                },
                isPrimary: false,
            }
        ]
    }

    return (
        <AlertModal {...alermodalProps} />
    )
}

export default ExportOutputFormatSelectionModal
