import { Box, Grid, MenuItem, TextField } from '@mui/material'
import React, { useState } from 'react'
import GraphDataDownloadButton from './GraphDataDownloadButton'
import GraphImageDownloadButton from './GraphImageDownloadButton'
import { LabelValuePair } from '../../../../Models/DataModels/Common/LabelValuePairModel'

type GraphDownloadOptionType = 'Data' | 'Image' | 'ImageAnnotation'

const graphDownloadOptions = {
    Data: 'Data' as GraphDownloadOptionType,
    Image: 'Image' as GraphDownloadOptionType,
    ImageAnnotation: 'ImageAnnotation' as GraphDownloadOptionType
}

const graphDownloadOptionsLabelValuePairs: LabelValuePair[] = [
    { label: 'Download Data', value: graphDownloadOptions.Data },
    { label: 'Save Image', value: graphDownloadOptions.Image }
]

export interface GraphDownloadOptionsProps {
    downloadCSV: () => void,
    downloadExcel: () => void,
    imageIDValue: string
}

const GraphDownloadOptions = ({
    downloadCSV,
    downloadExcel,
    imageIDValue
}: GraphDownloadOptionsProps) => {

    const [selectedOption, setSelectedOption] = useState<string>(graphDownloadOptionsLabelValuePairs[0].value)

    const optionComponent = () => {
        switch (selectedOption) {
            case graphDownloadOptions.Data:
                return <GraphDataDownloadButton downloadCSV={downloadCSV} downloadExcel={downloadExcel} />
            case graphDownloadOptions.Image:
                return <GraphImageDownloadButton imageIDValue={imageIDValue} />
            case graphDownloadOptions.ImageAnnotation: default:
                return <></>
        }
    }

    return (
        <Grid container justifyContent='flex-end'>
            <Grid item>
                <Box sx={{ minWidth: 120 }}>
                    <TextField
                        id='graphDownloadOptionSelection'
                        fullWidth
                        value={selectedOption}
                        onChange={(e: any) => {
                            setSelectedOption(e.target.value)
                        }}
                        select
                        label='Download Type'
                        size='small'
                    >
                        {graphDownloadOptionsLabelValuePairs.map(
                            (value: LabelValuePair, index: number) => <MenuItem key={`graphOption${index}`} value={value.value}>{value.label}</MenuItem>
                        )}
                    </TextField>
                </Box>
            </Grid>
            <Grid item>
                {optionComponent()}
            </Grid>
        </Grid>
    )
}

export default GraphDownloadOptions
