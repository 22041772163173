export const capitalizationTooltipKeys = {
    constituent: {
        index: 'capIndex' as string,
        countryListing: 'capCountryListing' as string,
        countryOperation: 'capCountryOperation' as string,
        sector: 'capSector' as string,
        industry: 'capIndustry' as string,
        yearOverYear: 'capYOY' as string,
        currency: 'capCurrency' as string
    },
    summary: {
        index: 'capSummaryIndex' as string,
        countryListing: 'capSummaryCountryListing' as string,
        countryOperation: 'capSummaryCountryOperation' as string,
        sector: 'capSummarySector' as string,
        industry: 'capSummaryIndustry' as string,
        info: 'capSummaryInfo' as string,
        historicalChart: 'capHistoricalChart' as string,
        currency: 'capSummaryCurrency' as string
    }
}

export const yieldCurveTooltipKeys = {
    group: 'yieldCurveGroup' as string,
    country: 'yieldCurveCountry' as string,
    setDate: 'yieldCurveSetDate' as string
}

export const currencyConversionTooltipKeys = {
    singleConversion: 'ccSingleConversion' as string,
    multiConversion: 'ccMultiConversion' as string,
    sourceCurrency: 'ccSourceCurrency' as string,
    targetCurrency: 'ccTargetCurrency' as string,
    plusButton: 'ccPlusButton' as string,
    trashButton: 'ccTrashButton' as string
}

const appToolTips = new Map<string, string>([
    [capitalizationTooltipKeys.constituent.index, 'Make a selection to narrow the focus to the members that make up the selected index.'],
    [capitalizationTooltipKeys.constituent.countryListing, 'Make a selection to narrow the focus to a country where the exchange is located.'],
    [capitalizationTooltipKeys.constituent.countryOperation, 'Make a selection to narrow the focus to a country where the company began operation or primarily operates.'],
    [capitalizationTooltipKeys.constituent.sector, 'Make a selection to focus on a wider range of possible business activity than "Industry".'],
    [capitalizationTooltipKeys.constituent.industry, 'Make a selection to focus on a narrower range of possible business activity than "Sector".'],
    [capitalizationTooltipKeys.constituent.yearOverYear, 'Select the box to provide the annual percentage change from the month/year selected above to the month/year to select here.'],
    [capitalizationTooltipKeys.constituent.currency, 'Make a selection to convert the result market cap value to selected currency. The Market Cap value will default to source currency of the series or sector if not selected.'],
    [capitalizationTooltipKeys.summary.index, 'Make a selection to narrow the focus to the selected index.'],
    [capitalizationTooltipKeys.summary.countryListing, 'Make a selection to narrow the focus to a country where the exchange is located.'],
    [capitalizationTooltipKeys.summary.countryOperation, 'Make a selection to narrow the focus to a country where the company began operation or primarily operates.'],
    [capitalizationTooltipKeys.summary.sector, 'Make a selection to focus on a wider range of possible business activity than "Industry".'],
    [capitalizationTooltipKeys.summary.industry, 'Make a selection to focus on a narrower range of possible business activity than "Sector".'],
    [capitalizationTooltipKeys.summary.info, 'Make a selection to identify how you want the resulting summary information presented (Sector, Industry or Operating Country)'],
    [capitalizationTooltipKeys.summary.historicalChart, 'Select the box to create a summary chart based on your previous selections using the desired number of years that you enter.'],
    [capitalizationTooltipKeys.summary.currency, 'Make a selection to convert the result market cap value to selected currency. The Market Cap value will default to source currency of the series or sector if not selected.'],
    [yieldCurveTooltipKeys.group, 'Make a selection to narrow the focus to an official economic group or group based on an economic condition.  If you do not want to narrow the countries to one of these groups, please select "ALL".'],
    [yieldCurveTooltipKeys.country, 'Select the country that you would like to examine.'],
    [yieldCurveTooltipKeys.setDate, 'Select the date that you want to see displayed for the selected country.'],
    [currencyConversionTooltipKeys.singleConversion, 'Select this option to compare ONE target currency to the source currency.'],
    [currencyConversionTooltipKeys.multiConversion, 'Select this option to compare more than one target currencies to the source currency.'],
    [currencyConversionTooltipKeys.sourceCurrency, 'The foreign currency that will be converted to the target currency.'],
    [currencyConversionTooltipKeys.targetCurrency, 'The foreign currency that will be converted from the source currency.'],
    [currencyConversionTooltipKeys.plusButton, 'Select the button to add the target currency that is shown in the target currency field.'],
    [currencyConversionTooltipKeys.trashButton, 'Select the button to remove the selected target currency from the list.']
])

export const getTooltipMessage = (key: string): string => {
    return appToolTips.get(key) || ''
}
