import React, { Dispatch, SetStateAction, useState } from 'react'
import SavedResultsList, { SavedResultsListProps } from './SavedResultsList'
import SearchPage, { SearchPageProps } from './SearchPage'
import { UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { SearchDatabaseType, SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'

export interface SearchProps {
  userInfo: UserInfo | null,
  checkIsTrial: () => boolean,
  checkIsAnonymous: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  showSaved: boolean,
  setShowSaved: Dispatch<SetStateAction<boolean>>,
  isComposite: boolean
}

const Search = ({
  userInfo,
  checkIsTrial,
  checkIsAnonymous,
  signOut,
  showSaved,
  setShowSaved,
  isComposite
}: SearchProps) => {

  const [engineOption, setEngineOption] = useState<SearchDatabaseType>(SearchDatabaseTypes.GFDUniverse)

  const searchPageProps: SearchPageProps = {
    userInfo: userInfo,
    engineOption: engineOption,
    setEngineOption: setEngineOption,
    checkIsTrial: checkIsTrial,
    checkIsAnonymous,
    signOut: signOut,
    setShowSaved,
  }

  const savedResultsListProps: SavedResultsListProps = {
    userInfo,
    engineOption: engineOption,
    checkIsTrial: checkIsTrial,
    signOut: signOut,
    setShowSaved,
    isComposite
  }

  return (
    <React.Fragment>
      {!showSaved ?
        <SearchPage {...searchPageProps} />
        :
        <React.Fragment>
          <SavedResultsList {...savedResultsListProps} />
        </React.Fragment>
      }
      </React.Fragment>
  )
}

export default Search
