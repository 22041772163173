import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../HelpPageStyles'

const BasicGraphing = () => {
    return (
        <>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Basic Graphing</h2>
                </div>
            </Box>
            <p style={{ textAlign: 'left' }}>This section will describe how to use the basic graphing features.</p>
            <div style={{ height: '524px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingIntro.png' style={helpPageStyles.image} alt='Basic Graphing Intro'></img>
            </div>
            <div style={{ textAlign: 'left' }}>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Navigate to Graph Page</h4>
                    </div>
                </Box>
                <div style={{ height: '277px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingGraphButton.png' style={helpPageStyles.image} alt='Basic Graphing Graph Button'></img>
                </div>
                <p>
                    After logging in, click on the 'Graphing' button. This will take you to the graphing page.
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Controls</h4>
                    </div>
                </Box>
                <div style={{ height: '200px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingControls.png' style={helpPageStyles.image} alt='Basic Graphing Controls'></img>
                </div>
                <ol>
                    <li>
                        <b>Graph Quik Search</b>
                        <ul>
                            <li>Used for entering the series to graphs</li>
                        </ul>
                    </li>
                    <li>
                        <b>Graph button</b>
                        <ul>
                            <li>Graphs the Series entered in the Graph Quick Search text box</li>
                        </ul>
                    </li>
                    <li>
                        <b>Chart Options button</b>
                        <ul>
                            <li>This will display the chart options modal that will allow for advanced graphing. Advanced graphing will be descibed in details in later section.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Back button</b>
                        <ul>
                            <li>Navigates back to the previous page.</li>
                        </ul>
                    </li>
                </ol>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Graphing Series</h4>
                    </div>
                </Box>
                <div style={{ height: '400px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingQuikSearch.png' style={helpPageStyles.image} alt='Basic Graphing Quick Search'></img>
                </div>

                <p>
                    Click on Graph Quick Search Text Box and start typing the name or the ticker of the series. Possible options are displayed as you type.
                    Select the series you wish to graph. Series can be selected by clicking on one of the listed series or you can use the up and down arrow keys to
                    navigate between listed series and press the enter key to select.
                </p>
                <div style={{ height: '200px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingSymbolSearch.png' style={helpPageStyles.image} alt='Basic Graphing Symbol Search'></img>
                </div>

                <p>
                    If you already know the symbol of the series, you may enter the symbol and press the enter key or click on the graph button.
                </p>
                <p>
                    Graph is displayed after the selecting the series.
                </p>
                <div style={{ height: '588px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingSearchResult.png' style={helpPageStyles.image} alt='Basic Graphing Result'></img>
                </div>

                <p>
                    A line graph of the series' close price is displayed. By default, the graph with the maximum date range with the periodicity of quarterly is displayed on a log scale.
                    For further customization, see the Advanced Graphing Section.
                </p>
                <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                    Note: You must be subscribed to graph certain series.
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Details</h4>
                    </div>
                </Box>
                <div style={{ height: '484px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingDetails.png' style={helpPageStyles.image} alt='Basic Graphing Details'></img>
                </div>

                <p>
                    The details of the point can be viewed by hovering over a point on the graph.
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Zooming</h4>
                    </div>
                </Box>
                <div style={{ height: '452px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingZoom.png' style={helpPageStyles.image} alt='Basic Graphing Zoom'></img>
                </div>

                <p>
                    You can zoom into the graph by clicking and dragging to the left and then releasing the mouse click. The cursor must be within the coordinate.
                    This lets you easily filter the date range or "zoom" into the graph.
                </p>
                <div style={{ height: '449px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingZoomOutEnabled.png' style={helpPageStyles.image} alt='Basic Graphing Zoom Out Enabled'></img>
                </div>

                <p>
                    The zoomed graph will look like the following. This also enables the "Zoom Out" button. Clicking on the zoom out button will revert the graph to the original graph.
                </p>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Data/Image Download</h4>
                    </div>
                </Box>
                <p>
                    Data in the graph can be downloaded in the form of comma separated values (.csv) or excel (.xlsx).
                    In addition, image can be download in PNG/JPG/SVG formats.
                </p>
                <div style={{ height: '280px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingDownload.png' style={helpPageStyles.image} alt='Basic Graphing Download'></img>
                </div>

                <p>
                    First select the download type. (Download Data / Save Image)
                </p>
                <div style={{ height: '257px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingDownloadOptions.png' style={helpPageStyles.image} alt='Basic Graphing Download Options'></img>
                </div>

                <p>
                    Then, hover over the cursor to the download icon.
                </p>
                <div style={{ height: '210px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingDownloadOptionsFormats.png' style={helpPageStyles.image} alt='Basic Graphing Download Options Format'></img>
                </div>

                <p>
                    Click on format you wish to download the file in. This will download the file in the selected format.
                </p>
                <div style={{ height: '98px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingDownloadImage.png' style={helpPageStyles.image} alt='Basic Graphing Download'></img>
                </div>

                <br />
                <br />
                <div style={{ height: '168px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicGraphingDownloadImageFormats.png' style={helpPageStyles.image} alt='Basic Graphing Download Formats'></img>
                </div>

                <p>
                    Same steps apply for image download.
                </p>
            </div>
        </>
    )
}

export default BasicGraphing
