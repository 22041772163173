import { Box } from "@mui/material"
import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"
import { ImageNumberReference, MarkedRedRedText } from "../../HelpPageCommonComponents"

const BasicFinancialTools = () => {
    return (
        <React.Fragment>
            <Box
            >
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Basic Financial Tools</h2>
                </div>
            </Box>
            <br />
            <div style={{ height: '536px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20BasicHelp.png' style={helpPageStyles.image} alt='Basic Financial Tools Intro' />
            </div>
            <br />
            <br />
            <div style={{ textAlign: 'left' }}>
                <p>This is the Financial Tools page. To get to to this page, you can click on the "Financial Tools" button located within the <MarkedRedRedText /> ({ImageNumberReference(1)}) in the header.</p>
                <div>
                    The following is an overview of each tool item:
                    <ul>
                        <li>
                            <b>CAPE Ratios</b>
                            <ul>
                                <li>This tool allows for seven set periods of evaluation of inflation-adjusted price to earnings, including the traditional 10-year timeframe.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Capitalization</b>
                            <ul>
                                <li>The total value of a publicly traded company's outstanding common shares owned by stockholders.</li>
                                <li>This tool allows ranking the size of a company by market cap for all or specific index for the selected month and year.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Currency Converter</b>
                            <ul>
                                <li>This tool allows for downloading, and graphing of, historical exchange rate data of one currency (Source Currency) to one or more other currencies (Target Currency).</li>
                            </ul>
                        </li>
                        <li>
                            <b>Fama-French Stock Screener</b>
                            <ul>
                                <li>Tool to analyze a portfolio of equities to match the expected return based on the intended financial risk.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Custom Indices</b>
                            <ul>
                                <li>Tool to create own Custom Index as a collection of stocks.</li>
                                <li>The Price of the Index and Total Return of the Index can be calculated.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Yield Curve</b>
                            <ul>
                                <li>Tool to provide a quick visual representation of yields on a country's sovereign debt, from shortest duration out to longest duration</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BasicFinancialTools