export enum SearchFieldType
{
    All = 'All',
    Symbol = 'Symbol',
    Description = 'Description',
    Both = 'Both',
    Metadata = 'Metadata',
    CorpHist = 'CorpHist',
    ID = 'ID',
    CUSIP = 'CUSIP',
    NAICS = 'NAICS',
    SIC = 'SIC',
    CIK = 'CIK',
    Unknown = 'Unknown',
}
