import React from 'react'

const CapitalizationSideBar = () => {
  return (
    <>
        <p style={{paddingTop: 5}}>Market Capitalization (Market Cap) is the total value of a publicly traded company’s outstanding common shares owned by stockholders. The market cap of a company is determined by multiplying the share price (in some currency) by the total number of a company’s outstanding shares.</p>
        <p>This tool allows ranking the size of the companies by market cap for all or specific index for the selected month and year. In addition, the result can be further filtered by categories including country, sector, and industry. The result can be utilized to determine public opinion of a company’s net worth and to predict future market prices.</p>
    </>
  )
}

export default CapitalizationSideBar
