import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { SortFieldType, SortOrderType } from '../../../Models/DataModels/Common/SortModel'
import SearchResults, { PageProps, SortProps } from './SearchResults'
import { UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { abortKeys, abortRequest } from '../../../Services/APIService'
import { GFDToastInfo } from '../../Common/Utility/GFDToastify'
import { SearchDatabaseType, SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { getTitle } from './SelectEngine'
import { SubscriptionFilterType } from '../../../Models/DataModels/Common/SubscriptionFilter'
import SearchHeader, { SearchHeaderProps } from './SearchHeader'
import { SearchRequestModel } from '../../../Models/DataModels/Common/SearchModels'
import LoadingScreen from '../../Common/Modals/LoadingScreen'

export interface SearchPageProps {
    userInfo: UserInfo | null,
    engineOption: SearchDatabaseType,
    setEngineOption: (engineOption: SearchDatabaseType) => void,
    checkIsTrial: () => boolean,
    checkIsAnonymous: () => boolean,
    signOut: (logoutReason: LogoutReasonType) => void,
    setShowSaved: Dispatch<SetStateAction<boolean>>,
}

export const emptyTableData = { masterlist: [], columnDefinitions: [] }

const SearchPage = ({
    userInfo,
    engineOption,
    setEngineOption,
    checkIsTrial,
    checkIsAnonymous,
    signOut,
    setShowSaved,
}: SearchPageProps) => {

    const [tableData, setTableData] = useState<any>(emptyTableData)
    const [expanded, setExpanded] = React.useState<boolean>(true)
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)

    const expandedOptions = {
        expanded: expanded,
        setExpanded: setExpanded
    }

    const [searchTitle, setSearchTitle] = useState<string>(getTitle(engineOption || SearchDatabaseTypes.GFDUniverse))
    const [searchInProgress, setSearchInProgress] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [perPageCount, setPerPageCount] = useState(100)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [subSearchTerm, setSubSearchTerm] = useState<string>('')
    const [sortProps, setSortProps] = useState<SortProps>({ sortField: SortFieldType.None, sortOrder: SortOrderType.None })
    const [subscriptionFilter, setSubscriptionFilter] = useState<SubscriptionFilterType>(SubscriptionFilterType.All)
    const [searchRequestModel, setSearchRequestModel] = useState<SearchRequestModel | null>(null)

    const searchHeaderProps: SearchHeaderProps = {
        userInfo: userInfo,
        checkIsTrial: checkIsTrial,
        signOut: signOut,
        engineOption: engineOption,
        setEngineOption: setEngineOption,
        setTableData: setTableData,
        setShowSaved,
        expandedOptions: expandedOptions,
        searchInProgress: searchInProgress,
        setSearchInProgress: setSearchInProgress,
        searchTitle: searchTitle,
        setSearchTitle: setSearchTitle,
        pageNumber: pageNumber,
        setPageNumber: setPageNumber,
        perPageCount: perPageCount,
        setPerPageCount: setPerPageCount,
        totalCount: totalCount,
        setTotalCount: setTotalCount,
        subSearchTerm: subSearchTerm,
        setSubSearchTerm: setSubSearchTerm,
        sortProps: sortProps,
        setSortProps,
        subscriptionFilter,
        setSubscriptionFilter,
        setSearchRequestModel
    }

    const pageProps: PageProps = {
        title: searchTitle,
        inProgress: searchInProgress,
        pageNumber,
        perPageCount,
        totalCount,
        setPageNumber,
        setPerPageCount,
        subSearchTerm,
        setSubSearchTerm,
        sortProps,
        setSortProps,
        subscriptionFilter,
        setSubscriptionFilter
    }

    const cancelAction = () => {
        abortRequest(abortKeys.search)
        setSearchInProgress(false)
        GFDToastInfo('Search cancelled')
    }

    useEffect(() => {
        if (searchInProgress) {
            setShowLoadingModal(true)
        } else {
            setShowLoadingModal(false)
        }
    }, [searchInProgress])

    return (
        <React.Fragment>
            <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} cancelAction={cancelAction} />
            <SearchHeader {...searchHeaderProps} />
            <SearchResults userInfo={userInfo} engineOption={engineOption} tableData={tableData} pageData={pageProps} checkIsTrial={checkIsTrial} checkIsAnonymous={checkIsAnonymous} signOut={signOut} setShowSaved={setShowSaved} expanded={expanded} searchRequestModel={searchRequestModel} />
        </React.Fragment>
    )
}

export default SearchPage
