import React from "react"
import { ReactComponent as CapitalizationSVG } from "../../Images/svg/capitalization.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CapitalizationIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <CapitalizationSVG />
  </SvgIcon>
)

export default CapitalizationIcon