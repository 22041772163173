import { CircularProgress, IconButton, TextField, TextFieldProps, styled } from '@mui/material'
import { CSSProperties, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { CustomBtnCloseCancel, CustomBtnBlue } from '../GlobalSettings/CustomStyles'
import { globalStyles } from '../GlobalSettings/GlobalStyles'


const TextInput = styled((props: TextFieldProps) => (
    <TextField {...props} />
))(({ theme }) => ({
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
    },
    "& input[type=number]": {
        MozAppearance: "textfield",
    },
}))


const FamaSaveModal = ({ modalProps }: any) => {

    const [description, setDescription] = useState<string>(modalProps.famaValueProps.description)
    const [screenName, setScreenName] = useState<string>(modalProps.famaValueProps.screeningName)

    const readOnlyProp: CSSProperties = (modalProps.saveScreenReportInProgress ?? false) ? { pointerEvents: 'none'} : {}
    
    return (
        <>
            <Modal backdrop='static' style={{ ...readOnlyProp }} show={modalProps.showModal} onHide={modalProps.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Fama Screening Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextInput
                        style={{ ...readOnlyProp }} 
                        id='editScreenName'
                        variant='outlined'
                        size='small'
                        type={'string'}
                        label={'Screening Name'}
                        value={screenName}
                        onChange={(e: any) => {
                            e.preventDefault()
                            setScreenName(e.target.value)
                        }}
                    />
                    <TextInput
                        style={{ ...readOnlyProp }} 
                        id='editDescription'
                        variant='outlined'
                        size='small'
                        type={'string'}
                        label={'Description'}
                        value={description}
                        onChange={(e: any) => {
                            e.preventDefault()
                            setDescription(e.target.value)
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <CustomBtnCloseCancel variant='outlined' onClick={(e) => modalProps.handleClose()}>
                        Close
                    </CustomBtnCloseCancel>
                    <CustomBtnBlue variant='contained' onClick={(e) => modalProps.handleSuccess(screenName, description)}>
                        Save Report
                    </CustomBtnBlue>
                    <IconButton title='Save in Progress'
                        aria-label='Save in Progress' component='label' 
                        sx={{ 
                            p: '10px',
                            color: '#1976d2',
                            visibility: modalProps.saveScreenReportInProgress ? 'visible' : 'hidden'
                            }}
                        >
                        <CircularProgress style={globalStyles.circularProgress} title='Save in Progress' aria-label='Save in Progress' />
                    </IconButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default FamaSaveModal