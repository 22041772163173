import { LabelValuePair } from '../Models/DataModels/Common/LabelValuePairModel'
import { getCountryGroups, populateConstituentIndices, populateConstituentSubIndices, populateCountry, populateCountryExchange, populateCurrencies, populateEquityTypes, populateEventTopics, populateEventTypes, populateExchangeType, populateIndustry, populateMainIndicators, populateSectors, populateSeriesCategory, populateSeriesType, selectCountryGroup } from './FieldPopulationService'
import { NotOKResponseModel } from '../Models/DataModels/Common/NotOKResponseModel'
import { CountryGroupRequest, PopulateConstituentIndicesRequest, PopulateConstituentSubIndicesRequest, PopulateCountryRequest, PopulateCurrencyRequest, PopulateEquityTypesRequest, PopulateExchangeTypesRequest, PopulateIndustryRequest, PopulateMainIndicatorsRequest, PopulateSectorsRequest, PopulateSeriesCategoryRequest, PopulateSeriesTypeRequest } from '../Models/DataModels/Requests/PopulationRequests'
import { CurrencyGroup, INFCurrencyEntry, SOUCurrencyEntry, SearchDatabaseType, USDCurrencyValue, blankEntry } from '../Models/DataModels/Common/FieldPopulationModel'
import { getStoredLabelValuePair, setLabelValuePair } from './LocalStorageService'
import { countryGroupToolTipList, equityTypeToolTipList, mainIndicatorToolTipList, seriesCategoryToolTipList } from '../Components/Common/Utility/SearchPageToolTips'

const staticDropDownStorageKeys = {
    countryGroups: 'referenceCountryGroups',
    metadataCountries: 'metadataCountries',
    countries: 'countries',
    eventTypes: 'eventTypes',
    eventTopics: 'eventTopics'
}

const databaseTypeSuffix = (databaseType?: SearchDatabaseType): string => {
    return databaseType ? `-${databaseType}` : ''
}

const seriesCategorySuffix = (seriesCategory?: string): string => {
    return seriesCategory ? `-${seriesCategory}` : ''
}

const addSuffix = (suffixString?: string): string => {
    return suffixString ? `-${suffixString}` : ''
}

const createSeriesCategoryStorageKey = (databaseType?: SearchDatabaseType, seriesCategory?: string): string => {
    return `seriesCategory${databaseTypeSuffix(databaseType)}${seriesCategorySuffix(seriesCategory)}`
}

const createSeriesTypeStorageKey = (databaseType?: SearchDatabaseType, seriesCategory?: string): string => {
    return `seriesType${databaseTypeSuffix(databaseType)}${seriesCategorySuffix(seriesCategory)}`
}

const createMainIndicatorsStorageKey = (databaseType?: SearchDatabaseType): string => {
    return `mainIndicators${databaseTypeSuffix(databaseType)}`
}

const createSectorsStorageKey = (databaseType?: SearchDatabaseType): string => {
    return `sectors${databaseTypeSuffix(databaseType)}`
}

const createCountriesByGroupStorageKey = (countryGroup: string): string => {
    return `${staticDropDownStorageKeys.metadataCountries}-${countryGroup}`
}

const createCountriesStorageKey = (databaseType: SearchDatabaseType): string => {
    return `${staticDropDownStorageKeys.countries}${databaseTypeSuffix(databaseType)}`
}

const createExchangeTypesStorageKey = (databaseType?: SearchDatabaseType): string => {
    return `exchange${databaseTypeSuffix(databaseType)}`
}

const createIndustryStorageKey = (databaseType?: SearchDatabaseType, sector?: string): string => {
    return `industry${databaseTypeSuffix(databaseType)}${addSuffix(sector)}`
}

const createEquityTypesStorageKey = (databaseType?: SearchDatabaseType): string => {
    return `equity${databaseTypeSuffix(databaseType)}`
}

const createConstituentIndiceStorageKey = (categoryValue: string): string => {
    return `constituentIndices${addSuffix(categoryValue)}`
}

const createConstituentSubIndicesStorageKey = (indexValue?: string): string => {
    return `constituentSubIndices${addSuffix(indexValue)}`
}

const createExchangeCountriesStorageKey = (): string => {
    return `exchangeCountries`
}

const createCurrencyStorageKey = (): string => {
    return `currency`
}

const createGroupedCurrencyStorageKey = (group: CurrencyGroup): string => {
    return `currency${addSuffix(group)}`
}

const handleNotOKResponse = (notOKResponseModel: NotOKResponseModel) => {
    return Promise.reject()
}

const populateTooltips = (storedList: LabelValuePair[] | null, tooltipList: {label: string, toolTip: string}[]) => {
    storedList?.forEach(entry => {
        entry.tooltip = tooltipList.find((element: any) => element.label === entry.label)?.toolTip || ''
    })
}

export const getLocallyStoredCountryGroups = async (): Promise<LabelValuePair[] | null> => {
    const storageKey: string = staticDropDownStorageKeys.countryGroups

    const storedCountryGroups: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedCountryGroups) {
        populateTooltips(storedCountryGroups, countryGroupToolTipList)
        return storedCountryGroups
    }

    const result = getCountryGroups().then(
        (res: any) => {
            const countryGroupsResponse = res.value
            if (countryGroupsResponse) {
                const newCountryGroups: LabelValuePair[] = countryGroupsResponse.map((group: any) => ({ label: group, value: group }))
                populateTooltips(newCountryGroups, countryGroupToolTipList)
                setLabelValuePair(storageKey, newCountryGroups)
                return newCountryGroups
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredSeriesCategory = async (databaseType: SearchDatabaseType): Promise<LabelValuePair[] | null> => {

    const storageKey: string = createSeriesCategoryStorageKey(databaseType)

    const storedSeriesCategories: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedSeriesCategories) {
        populateTooltips(storedSeriesCategories, seriesCategoryToolTipList)
        return storedSeriesCategories
    }

    const seriesCategoryListRequest: PopulateSeriesCategoryRequest = {
        database: databaseType
    }
    const result = populateSeriesCategory(seriesCategoryListRequest).then(
        (value: LabelValuePair[]) => {
            if (value) {
                populateTooltips(value, seriesCategoryToolTipList)
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredSeriesTypes = async (databaseType: SearchDatabaseType, seriesCategory?: string): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createSeriesTypeStorageKey(databaseType, seriesCategory)
    const storedSeriesTypes: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedSeriesTypes) {
        return storedSeriesTypes
    }

    const seriesTypeListRequest: PopulateSeriesTypeRequest = {
        database: databaseType,
        ...(seriesCategory && { category: seriesCategory })
    }
    const result = populateSeriesType(seriesTypeListRequest).then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredMainIndicators = async (databaseType: SearchDatabaseType): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createMainIndicatorsStorageKey(databaseType)

    const storedMainIndicators: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedMainIndicators) {
        populateTooltips(storedMainIndicators, mainIndicatorToolTipList)
        return storedMainIndicators
    }

    const mainIndicatorsListRequest: PopulateMainIndicatorsRequest = {
        database: databaseType
    }
    const result = populateMainIndicators(mainIndicatorsListRequest).then(
        (value: LabelValuePair[]) => {
            if (value) {
                populateTooltips(value, mainIndicatorToolTipList)
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredCountriesByGroup = async (countryGroup: string): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createCountriesByGroupStorageKey(countryGroup)

    const storedCountries: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedCountries) {
        return storedCountries
    }

    const request: CountryGroupRequest = {
        group: countryGroup ?? 'All'
    }

    const result = selectCountryGroup(request).then(
        (res: any) => {
            const countries: LabelValuePair[] = res.value
            if (countries) {
                setLabelValuePair(storageKey, countries)
                return countries
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredCountries = async (databaseType: SearchDatabaseType): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createCountriesStorageKey(databaseType)

    const storedCountries: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedCountries) {
        return storedCountries
    }

    const request: PopulateCountryRequest = {
        database: databaseType
    }

    const result = populateCountry(request).then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredSectors = async (databaseType: SearchDatabaseType): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createSectorsStorageKey(databaseType)

    const storedSectors: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedSectors) {
        return storedSectors
    }

    const sectorListRequest: PopulateSectorsRequest = {
        database: databaseType,
    }

    const result = populateSectors(sectorListRequest).then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredExchangeTypes = async (databaseType: SearchDatabaseType): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createExchangeTypesStorageKey(databaseType)

    const storedExchangeTypes: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedExchangeTypes) {
        return storedExchangeTypes
    }

    const exchangeRequest: PopulateExchangeTypesRequest = {
        database: databaseType
    }

    const result = populateExchangeType(exchangeRequest).then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredIndustries = async (databaseType: SearchDatabaseType, sector?: string): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createIndustryStorageKey(databaseType, sector)

    const storedIndustries: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedIndustries) {
        return storedIndustries
    }

    const industryRequest: PopulateIndustryRequest = {
        database: databaseType,
        ...(sector && { sector: sector })
    }

    const result = populateIndustry(industryRequest).then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredEquityTypes = async (databaseType: SearchDatabaseType): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createEquityTypesStorageKey(databaseType)

    const storedEquityTypes: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedEquityTypes) {
        populateTooltips(storedEquityTypes, equityTypeToolTipList)
        return storedEquityTypes
    }

    const equityRequest: PopulateEquityTypesRequest = {
        database: databaseType
    }

    const result = populateEquityTypes(equityRequest).then(
        (value: LabelValuePair[]) => {
            if (value) {
                populateTooltips(value, equityTypeToolTipList)
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredEventTypes = async (): Promise<LabelValuePair[] | null> => {
    const storageKey: string = staticDropDownStorageKeys.eventTypes

    const storedEventTypes: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedEventTypes) {
        return storedEventTypes
    }

    const result = populateEventTypes().then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredEventTopics = async (): Promise<LabelValuePair[] | null> => {
    const storageKey: string = staticDropDownStorageKeys.eventTopics

    const storedEventTopics: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedEventTopics) {
        return storedEventTopics
    }

    const result = populateEventTopics().then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredConstituentIndices = async (categoryValue: string): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createConstituentIndiceStorageKey(categoryValue)

    const storedEquityTypes: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedEquityTypes) {
        return storedEquityTypes
    }

    const indexRequest: PopulateConstituentIndicesRequest = {
        category: categoryValue
    }

    const result = populateConstituentIndices(indexRequest).then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredConstituentSubIndices = async (indexValue: string): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createConstituentSubIndicesStorageKey(indexValue)

    const storedEquityTypes: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedEquityTypes) {
        return storedEquityTypes
    }

    const subIndexRequest: PopulateConstituentSubIndicesRequest = {
        index: indexValue
    }

    const result = populateConstituentSubIndices(subIndexRequest).then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredExchangeCountries = async (): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createExchangeCountriesStorageKey()

    const storedExchangeCountries: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedExchangeCountries) {
        return storedExchangeCountries
    }

    const result = populateCountryExchange().then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )
    return result
}

export const getLocallyStoredCurrencies = async (): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createCurrencyStorageKey()

    const storedCurrencies: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedCurrencies) {
        return storedCurrencies
    }

    const request: PopulateCurrencyRequest = {
        group: 'All'
    }

    const result = populateCurrencies(request).then(
        (value: LabelValuePair[]) => {
            if (value) {
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )

    return result
}

export const getLocallyStoredGroupCurrencies = async (group: CurrencyGroup): Promise<LabelValuePair[] | null> => {
    const storageKey: string = createGroupedCurrencyStorageKey(group)

    const storedCurrencies: LabelValuePair[] | null = getStoredLabelValuePair(storageKey)
    if (storedCurrencies) {
        return storedCurrencies
    }

    const request: PopulateCurrencyRequest = {
        group: group
    }

    const result = populateCurrencies(request).then(
        (value: LabelValuePair[]) => {
            if (value) {
                const USDEntryFound: LabelValuePair | null = value.find((element: LabelValuePair) => element.value === USDCurrencyValue) || null
                if (USDEntryFound) {
                    const USDEntry: LabelValuePair = { label: USDEntryFound.label, value: USDEntryFound.value }
                    value = value.filter((element: LabelValuePair) => element.value !== USDCurrencyValue)
                    value.unshift(USDEntry)
                    value.map((element: LabelValuePair) => {
                        element.label = element.value + ' - ' + element.label
                        return element
                    })
                    value.push(INFCurrencyEntry)
                    value.unshift(SOUCurrencyEntry)
                } else {
                    value.unshift(blankEntry)
                }
                setLabelValuePair(storageKey, value)
                return value
            }
            return null
        },
        handleNotOKResponse
    )

    return result
}
