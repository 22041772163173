import React, { useEffect, useState } from 'react'
import AlertModal, { AlertButtonType, AlertModalProps } from './AlertModal'
import { ChartType, ChartTypes } from '../../../Models/DataModels/Common/ToolsModel'
import { Box } from '@mui/material'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from 'moment'
import { SearchDatabaseType } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { bases, pathParts, paths } from '../../../Models/DataModels/Common/RedirectionModel'
import { useLocation, useNavigate } from 'react-router-dom'
import { seriesMinDate, seriesMaxDate, validDateInRange } from '../../../Services/UtilityService'
import { getLocalStorageOrDefault, localStorageKeys } from '../../../Services/LocalStorageService'
import { getSessionStorageOrDefault, sessionStorageKeys } from '../../../Services/SessionStorageService'

export interface SelectToolbarSelectedResultsChartSetDateModalProps {
    chartType: ChartType,
    engineOption?: SearchDatabaseType,
    show: boolean,
    setShow: (show: boolean) => void,
    defaultDate?: moment.Moment,
    toggled?: boolean,
    lastPathFromCharts?: string
}

const SelectToolbarSelectedResultsChartSetDateModal = ({
    chartType,
    engineOption,
    show,
    setShow,
    defaultDate,
    toggled,
    lastPathFromCharts
}: SelectToolbarSelectedResultsChartSetDateModalProps) => {

    const location: any = useLocation()
    const navigate = useNavigate()

    const [selectedSeriesMinDate, setSelectedSeriesMinDate] = useState<moment.Moment>(seriesMinDate)
    const [selectedSeriesMaxDate, setSelectedSeriesMaxDate] = useState<moment.Moment>(seriesMaxDate)
    const [selectedResultsChartDate, setSelectedResultsChartDate] = useState<moment.Moment>(defaultDate || moment())

    const getChartDisplayNameText = (chartType: ChartType) => {
        const viewText: string = chartType ? `View ${chartType.toString()} Chart` : 'View Chart'
        return viewText
    }

    const viewChartText: string = getChartDisplayNameText(chartType)

    const contents = (): JSX.Element => {
        return (
            <Box textAlign='left'>
                <p>Viewing the {chartType.toString()} Chart will navigate you away from this page.</p>
                <p>Select date setting for the {chartType.toString()} Chart data:</p>
                <div style={{ marginTop: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label='Select Close Date'
                            value={selectedResultsChartDate}
                            onChange={(newValue) => {
                                setSelectedResultsChartDate(newValue as moment.Moment)
                            }}
                            minDate={selectedSeriesMinDate}
                            maxDate={selectedSeriesMaxDate}
                        />
                    </LocalizationProvider>
                </div>
            </Box>
        )
    }

    const handleConfirm = () => {
        const navStatePayload: any = {
            state: {
                prevPage: location.pathname,
                ...(engineOption && { engineOption: engineOption }),
                selectedResultsChartDateISOString: selectedResultsChartDate.toISOString(),
                ...(toggled && { toggled: true }),
                lastPathFromCharts
            }
        }
        switch (chartType) {
            case ChartTypes.Bar:
                navigate(paths.graph.barChart, navStatePayload)
                break
            case ChartTypes.Pie:
                navigate(paths.graph.pieChart, navStatePayload)
                break
            default:
                break
        }
    }

    const setDateAlertProps: AlertModalProps = {
        showModal: show,
        setShowModal: setShow,
        AlertTitle: getChartDisplayNameText(chartType),
        AlertContent: contents(),
        AlertButtons: [
            {
                type: AlertButtonType.Cancel,
                display: 'Cancel',
                onClick: () => { setShow(false) },
                isPrimary: false,
            },
            {
                type: AlertButtonType.OK,
                display: viewChartText,
                onClick: () => {
                    handleConfirm()
                    setShow(false)
                },
                isPrimary: true,
                disable: !validDateInRange(selectedResultsChartDate),
                style: { marginRight: '1%' }
            },
        ],
        onAlertClose: () => {
            return true
        }
    }

    const isFromSessionStorage = (): boolean => {
        return [bases.searchHome, bases.searchHomeAlternative].includes(location?.state?.lastPathFromCharts) ||
            location?.state?.lastPathFromCharts?.includes(pathParts.search.searchSingle) ||
            location?.state?.lastPathFromCharts?.includes(pathParts.selectedResults) ||
            location?.state?.lastPathFromCharts?.includes(pathParts.tools.famaFrench) ||
            location?.pathname?.includes(pathParts.search.searchSingle) ||
            location?.pathname?.includes(pathParts.selectedResults) ||
            location?.pathname?.includes(pathParts.tools.famaFrench)
    }

    const getSavedGraphSeries = () => {
        return isFromSessionStorage() ?
            getSessionStorageOrDefault(sessionStorageKeys.selectedResultsForChartsData, [])
            :
            getLocalStorageOrDefault(localStorageKeys.selectedResultsData, true, [])
    }

    useEffect(() => {
        const savedGraphSeries: any[] = getSavedGraphSeries()
        const startDates: Date[] = []
        const endDates: Date[] = []
        savedGraphSeries.forEach((savedSeries: any) => {
            if (savedSeries?.startDate) {
                startDates.push(new Date(savedSeries.startDate))
            }
            if (savedSeries?.endDate) {
                endDates.push(new Date(savedSeries.endDate))
            }
        })
        if (startDates.length > 0) {
            const sortedStartDates: Date[] = startDates.sort((a: Date, b: Date) => a.getTime() - b.getTime())
            const savedSeriesEarliest: moment.Moment = moment(sortedStartDates[0])
            setSelectedSeriesMinDate(savedSeriesEarliest)
        }
        if (endDates.length > 0) {
            const sortedEndDates: Date[] = endDates.sort((a: Date, b: Date) => a.getTime() - b.getTime())
            const savedSeriesLatest: moment.Moment = moment(sortedEndDates[sortedEndDates.length - 1])
            setSelectedResultsChartDate(defaultDate || savedSeriesLatest)
            setSelectedSeriesMaxDate(savedSeriesLatest)
        }
    }, [])

    return (
        <AlertModal {...setDateAlertProps} />
    )
}

export default SelectToolbarSelectedResultsChartSetDateModal
