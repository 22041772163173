import { CircularProgress, Divider, Grid, IconButton, Paper, TextField, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import ClearIcon from '@mui/icons-material/Clear'
import Item from '../../Common/Utility/Item'
import { useEffect, useState } from 'react'
import { SearchUserRequest } from '../../../Models/DataModels/Requests/AccountRequests'
import { searchUser } from '../../../Services/AccountService'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import { SearchUserFields, SearchUserSortFieldEnum, SearchUserSortFields, SearchUserSortProps, UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import DefaultMaterialTheme from '../../Common/GlobalSettings/DefaultMaterialTheme'
import MaterialTable from '@material-table/core'
import MaterialTableOptions from '../../Common/GlobalSettings/MaterialTableOptions'
import { SearchUserResponse } from '../../../Models/DataModels/Responses/AccountResponses'
import { ImpersonateRequest, LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { impersonateUser } from '../../../Services/AuthenticationService'
import { LoginResponse } from '../../../Models/DataModels/Responses/AuthResponses'
import { pathParts } from '../../../Models/DataModels/Common/RedirectionModel'
import { useNavigate } from 'react-router-dom'
import { RecentlyLoadedUsersCard, RecentlyLoadedUsersCardProps } from './RecentlyLoadedUsersCard'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../Common/Modals/AlertModal'
import Impersonate from '../../Icons/ImpersonateIcon'
import SearchUser from '../../Icons/SearchUserIcon'
import EnhancedTableHead from '../../Common/Utility/EnhancedTableHead'
import { SortOrderString, SortOrderType } from '../../../Models/DataModels/Common/SortModel'
import GFDTablePagination, { GFDTablePaginationProps } from '../../Common/Utility/GFDTablePagination'

export interface ImpersonateUserProps {
  login: (loginResponse: LoginResponse) => void,
  signOut: (logoutReason: LogoutReasonType) => void
}

const ImpersonateUser = ({
  login,
  signOut
}: ImpersonateUserProps) => {
  const navigate = useNavigate()

  interface UserInfoWithIndex extends UserInfo {
    index: number
  }
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [users, setUsers] = useState<UserInfoWithIndex[] | null>()
  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [perPageCount, setPerPageCount] = useState(100)
  const [totalCount, setTotalCount] = useState<number>()
  const [sortData, setSortData] = useState<SearchUserSortProps>({ 
    sortField: SearchUserSortFieldEnum.None, 
    sortOrder: SortOrderType.None 
  })
  const [searchedTerm, setSearchedTerm] = useState<string>('')
  const [impersonatedUserID, setImpersonatedUserID] = useState<number | null>()
  const [impersonatedUserName, setImpersonatedUserName] = useState<string | null>()
  const [showModal, setShowModal] = useState<boolean>(false)

  const getListRequest = (resetPageNumber: boolean, requestTotalCount: boolean): SearchUserRequest => {
    return {
      searchTerm: searchTerm,
      perPageCount: !perPageCount || perPageCount <= 0 ? 100 : perPageCount,
      pageNumber: !pageNumber || pageNumber <= 0 || resetPageNumber ? 1 : pageNumber,
      isTotalCountRequested: requestTotalCount,
      sortField: sortData.sortField,
      sortOrder: sortData.sortOrder
    }
  }

  useEffect(() => {
    search(getListRequest(true, true))
  }, [])

  useEffect(() => {
    search(getListRequest(false, false))
  }, [pageNumber, sortData])

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1)
      return
    }
    search(getListRequest(true, false))
  }, [perPageCount])

  const search = (searchRequest: SearchUserRequest) => {
    if (searchInProgress) return

    if (!searchRequest) return

    if (!searchRequest.searchTerm?.trim()) return

    if (!searchRequest.perPageCount) {
      searchRequest.perPageCount = 100
    }

    if (!searchRequest.pageNumber || searchRequest.pageNumber <= 0) {
      searchRequest.pageNumber = 1
    }

    setSearchInProgress(true)

    searchUser(searchRequest)
      .then((result: any) => {
        const response = result as SearchUserResponse
        setSearchedTerm(response.searchTerm)

        const usersWithIndex = response.users.map((user, index) => {
          const result: UserInfoWithIndex = {
            ...user,
            index: index + 1
          }
          return result
        })

        setUsers(() => usersWithIndex)

        if (pageNumber !== response.pageNumber) setPageNumber(response.pageNumber)
        if (perPageCount !== response.perPageCount) setPerPageCount(response.perPageCount)

        if (response.totalCount || response.totalCount === 0) {
          setTotalCount(response.totalCount)
        }

        setSearchInProgress(false)
      },
      (notOKResponseModel: NotOKResponseModel) => {
        setSearchInProgress(false)
        setErrorResponse(notOKResponseModel)
      })
  }

  const doImpersonateUser = () => {
    if (!impersonatedUserID) return

    impersonateUser({
      impersonatedUserID,
      applicationType: 'Web'
    } as ImpersonateRequest)
    .then((response: LoginResponse) => {
      login(response)
      navigate(pathParts.search.searchDefault)
    },
    (notOKResponseModel: NotOKResponseModel) => {
      setErrorResponse(notOKResponseModel)
    })
  }

  const clearInput = () => {
    setSearchTerm('')
  }

  const onInputKeyDown = (event: any) => { 
    if (event.key === 'Enter') {
      event.preventDefault()
      search(getListRequest(true, true))
    }
  }

  const renderSearch = () => {
    return <Paper
      component='form'
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', marginBottom: '8px' }}
    >
      <Tooltip title='Click or press Enter to Search'>
        <IconButton color='primary' sx={{ p: '10px' }} aria-label='search' onClick={() => search(getListRequest(true, true))}>
          <SearchUser style={{fontSize: '1.2em'}}></SearchUser>
        </IconButton>
      </Tooltip>
      
      <TextField
        id='userSearchText'
        value={searchTerm}
        style={{ width: '90%' }}
        size='medium'       
        label='Search for users to impersonate'
        placeholder='Search for users to impersonate'
        onChange={e => setSearchTerm(e.target.value)}
        onKeyDown={onInputKeyDown}
        InputProps={{
          endAdornment: <IconButton title='Clear Search' aria-label='clear search' sx={{visibility: searchTerm?.trim() ? "visible": "hidden", marginLeft: '8px'}} onClick={clearInput}><ClearIcon/></IconButton>
        }}
      />

      <Tooltip title='Search in Progress'>
        <IconButton aria-label='Search in Progress' component='label' sx={{ 
          p: '10px',
          color: '#1976d2',
          visibility: searchInProgress ? "visible": "hidden"}}>
          <CircularProgress aria-label='Search in Progress' />
        </IconButton>
      </Tooltip>
    </Paper>
  }

  const errorHandlerProps: ErrorHandlerProps = {
    response: errorResponse,
    signOut: signOut
  }

  const requestImpersonateUser = (userID: number, name: string) => {
    setImpersonatedUserID(userID)
    setImpersonatedUserName(name)
    setShowModal(true)
  }

  const recentlyLoadedUsersCardProps: RecentlyLoadedUsersCardProps = {
    setErrorResponse,
    impersonateUser: requestImpersonateUser
  }

  const alertModalProps: AlertModalProps = {
    showModal,
    setShowModal,
    AlertTitle: 'Confirm',
    AlertContent: `Do you want to impersonate ${impersonatedUserName}?`,
    AlertButtons: [
      {
        type: AlertButtonType.Cancel,
        display: 'Cancel',
        onClick: () => {
          setShowModal(false)
        },
        isPrimary: false,
      },
      {
        type: AlertButtonType.OK,
        display: 'Yes',
        onClick: () => {
          setShowModal(false)
          doImpersonateUser()
        },
        isPrimary: true,
      }
    ],
    onAlertClose: () => {
      return true
    }
  }

  const columns: any[] = [
    { title: 'Row',
      field: 'index', 
      render: (rowData: UserInfoWithIndex) => <>{((pageNumber - 1) * perPageCount) + rowData.index}</>,
      sorting: false
    },
    { title: <IconButton title='Impersonate' aria-label='Impersonate' component='label'>
      <Impersonate style={{fontSize: '1.2em'}}></Impersonate>
    </IconButton>,
      render: (rowData: UserInfoWithIndex) => <Tooltip title="Impersonate">
        <IconButton
          sx={{position: 'static', color: '#1976d2'}}
          onClick={() => requestImpersonateUser(rowData.userID || 0, `${rowData.firstName} ${rowData.lastName}`)}
        >
          <Impersonate />
        </IconButton>
      </Tooltip>,
      sorting: false
    },
    { title: 'ID', field: 'userID', hidden: true },
    { title: 'Organization', field: 'orgName', type: 'string', sortField: SearchUserSortFieldEnum.OrganizationName },
    { title: 'Type', field: 'orgType', type: 'string', sortField: SearchUserSortFieldEnum.OrganizationType },
    { title: 'First Name', field: 'firstName', type: 'string', sortField: SearchUserSortFieldEnum.FirstName },
    { title: 'Last Name', field: 'lastName', type: 'string', sortField: SearchUserSortFieldEnum.LastName },
    { title: 'Position', field: 'position', type: 'string', sortField: SearchUserSortFieldEnum.Position },
    { title: 'Email', field: 'email', type: 'string', sortField: SearchUserSortFieldEnum.Email },
    { title: 'Phone', field: 'phone', type: 'string', sortField: SearchUserSortFieldEnum.Phone }
  ]

  const getDefaultSort = (column: string) => {
    if (SearchUserFields.includes(column) &&
      sortData.sortField === SearchUserSortFields[column]) {
        return SortOrderString[sortData.sortOrder]
    }
    return undefined
  }

  const onSort = (columnNumber: number) => {
    const props: SearchUserSortProps = {
      sortField: sortData.sortField,
      sortOrder: sortData.sortOrder
    }

    try {
      const column = columns[columnNumber]
      const columnName = column.field
      const field: SearchUserSortFieldEnum = SearchUserSortFields[columnName] || SearchUserSortFieldEnum.None

      if (field !== props.sortField) {
        props.sortField = field

        if (column.defaultSort === 'asc') {
          props.sortOrder = SortOrderType.Descending
        } else if (column.defaultSort === 'desc') {
          props.sortField = SearchUserSortFieldEnum.None
          props.sortOrder = SortOrderType.None
        } else {
          props.sortOrder = SortOrderType.Ascending
        }
      } else {
        if (props.sortOrder === SortOrderType.Ascending) {
          props.sortOrder = SortOrderType.Descending
        } else if (props.sortOrder === SortOrderType.Descending) {
          props.sortField = SearchUserSortFieldEnum.None
          props.sortOrder = SortOrderType.None
        } else {
          props.sortOrder = SortOrderType.Ascending
        }
      }

      setSortData(props)
    }
    catch (e) {
      console.log('Exception while sorting: ', e)
    }
  }

  const pageData: GFDTablePaginationProps = {
    perPageCount: perPageCount,
    pageNumber: pageNumber,
    totalCount: totalCount || 0,
    setPageNumber: setPageNumber,
    setPerPageCount: setPerPageCount
  }

  return (
    <div>
      <AlertModal {...alertModalProps} />
      <ErrorHandler {...errorHandlerProps}/>
        <div style={{ display: 'flex', flexWrap: 'wrap' as 'wrap', gridRow: '1 / span 2', marginTop: '8px' }}>
          <h3>Select or Search to Impersonate Users</h3>
        </div>
        <Divider sx={{ borderColor: 'black', marginBottom: 5, width: '100%' }} />

        <Grid container spacing={2} columns={16}>
          <Grid item xs={5}>
            <Item>
              <RecentlyLoadedUsersCard {...recentlyLoadedUsersCardProps} />
            </Item>
          </Grid>
          <Grid item xs={11}>
            {renderSearch()}
            <label style={{fontWeight: 'bold'}}>
              {totalCount || totalCount === 0 ? `${totalCount || 0} ${totalCount > 1 ? 'Users' : 'User'} found for '${searchedTerm}'` : ''}
            </label>
            <ThemeProvider theme={DefaultMaterialTheme}>
              <MaterialTable
                  columns={columns}
                  data={users || []}
                  title={totalCount || totalCount === 0 ? `${totalCount || 0} ${totalCount > 1 ? 'Users' : 'User'} found for '${searchedTerm}'` : ''}
                  options={{
                      ...MaterialTableOptions,
                      selection: false,
                      search: false,
                      toolbar: false,
                      padding: undefined
                  }}
                  components={{
                    Pagination: props => (<GFDTablePagination {...pageData} />),
                    Header:
                      props => (
                        <EnhancedTableHead { ...{ sortData: { getDefaultSort, onSort }, columns } } />
                      )
                  }}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
    </div>
  )
}

export default ImpersonateUser
