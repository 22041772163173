import { CapitalizationSummary } from '../../../../../../Models/DataModels/Common/ToolsModel'

export const createDownloadData = (headerName: string, capSummaryData: CapitalizationSummary[]): string[][] => {
    let downloadDataContents: string[][] = []
    downloadDataContents.push([headerName, 'Market Cap'])
    capSummaryData.forEach((value: CapitalizationSummary) => {
        downloadDataContents.push([value.sector, value.marketCap.toString()])
    })
    return downloadDataContents
}
