import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'

const ChartSettingsSection = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Chart Settings</h4>
                </div>
            </Box>
            <div style={{ height: '874px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettings.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings'></img>
            </div>

            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Apply Options / Closing Modal</h5>
                </div>
            </Box>
            <p>
                Once you are done with applying the chart options, Click on "Apply Options" button to graph.
                If the primary series was entered before opening the modal, a new graph that includes the options selected will be graphed.
                Otherwise, primary series must be entered to graph.
                To close the modal, click on the Close button or the X at the top right hand corner of the modal.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Date Range</h5>
                </div>
            </Box>
            <div style={{ height: '140px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsDateRange.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Date Range'></img>
            </div>

            <p>
                Date Range allows you to select date range for series' graph. You can select the "Select Date" Option to select the predetermined options or select "Custom Date" to enter the specific start date and end date.
            </p>
            <div style={{ height: '140px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsDateRangeSelect.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Date Range Select'></img>
            </div>

            <div>
                Select Date
                <br />
                Here are the explanations for the options.
                <ul>
                    <li>
                        <b>1MO</b>
                        <ul>
                            <li>From a month prior to the current date to the current date</li>
                        </ul>
                    </li>
                    <li>
                        <b>3MO</b>
                        <ul>
                            <li>From 3 months prior to the current date to the current date</li>
                        </ul>
                    </li>
                    <li>
                        <b>6MO</b>
                        <ul>
                            <li>From 6 months prior to the current date to the current date</li>
                        </ul>
                    </li>
                    <li>
                        <b>YEAR-TO-DATE</b>
                        <ul>
                            <li>From start of the current calendar year date to current date</li>
                        </ul>
                    </li>
                    <li>
                        <b>1YR</b>
                        <ul>
                            <li>From a year prior to the current date to the current date</li>
                        </ul>
                    </li>
                    <li>
                        <b>2YR</b>
                        <ul>
                            <li>From 2 years prior to the current date to the current date</li>
                        </ul>
                    </li>
                    <li>
                        <b>5YR</b>
                        <ul>
                            <li>From 5 years prior to the current date to the current date</li>
                        </ul>
                    </li>
                    <li>
                        <b>10YR</b>
                        <ul>
                            <li>From 10 years prior to the current date to the current date</li>
                        </ul>
                    </li>
                    <li>
                        <b>20</b>
                        <ul>
                            <li>From 20 years prior to the current date to the current date</li>
                        </ul>
                    </li>
                    <li>
                        <b>MAX</b>
                        <ul>
                            <li>From the earliest available date of the series to the current date</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div style={{ height: '142px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsDateRangeCustom.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Date Range Custom'></img>
            </div>

            <p>
                Custom Date
            </p>
            <div style={{ height: '559px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsDateRangeCustomEntry.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Date Range Custom Entry'></img>
            </div>
            <p>
                Alternatively, custom date range can be entered by manually entering the start and end date or by using the date picker.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Periodicity</h5>
                </div>
            </Box>
            <div style={{ height: '290px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsPeriodicity.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Periodicity'></img>
            </div>
            <div>
                Periodicity determines increments of the datapoint on the graph. For instance, if the periodicity is annual, datapoints in the graph are by 1 year increments.
                If the periodicity is monthly, datapoints in the graph are by 1 month increments.
                <ul>
                    <li>
                        <b>Natural Frequency</b>
                        <ul>
                            <li>All the available datapoints are displayed.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Daily</b>
                        <ul>
                            <li>Datapoints on the graph are in 1 day increments.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Weekly</b>
                        <ul>
                            <li>Datapoints on the graph are in 1 week increments.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Monthly</b>
                        <ul>
                            <li>Datapoints on the graph are in 1 month increments.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Quarterly</b>
                        <ul>
                            <li>Datapoints on the graph are in 3 months increments.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Annually</b>
                        <ul>
                            <li>Datapoints on the graph are in 1 year increments.</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Chart Type</h5>
                </div>
            </Box>
            <div style={{ height: '93px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsChartType.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Chart Type'></img>
            </div>
            <p>
                Chart Type options allows changing the Graph Type, scale of the graph, set the currency of the prices, and allows displaying the volume data on a separate graph.
            </p>
            <h6>Graph Type</h6>
            <div style={{ height: '335px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsGraphType.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Graph Type'></img>
            </div>
            <div>
                Sets the type of the graph
                <ul>
                    <li>
                        <b>Line (Close Only)</b>
                        <ul>
                            <li>
                                Graphs the Line Graph with the Close price for the data points.
                                <div style={{ height: '384px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSampleLineGraph.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Sample Line Graph'></img>
                                </div>
                            </li>

                        </ul>
                    </li>
                    <li>
                        <b>Candlestick</b>
                        <ul>
                            <li>
                                Graphs the Candlestick graph with Open, High, Low, and Close prices.
                                <div style={{ height: '369px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSampleCandlestick.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Sample Candlestick'></img>
                                </div>
                            </li>

                        </ul>
                    </li>
                    <li>
                        <b>Bars(HLC)</b>
                        <ul>
                            <li>
                                Graphs the Bar graph with High, Low, and Close prices.
                                <div style={{ height: '371px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSampleBarHLC.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Sample Bars HLC'></img>
                                </div>
                            </li>

                        </ul>
                    </li>
                    <li>
                        <b>Bars(OHLC)</b>
                        <ul>
                            <li>
                                Graphs the Bar graph with Open, High, Low, and Close prices.
                                <div style={{ height: '373px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSampleBarOHLC.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Sample Bars OHLC'></img>
                                </div>
                            </li>

                        </ul>
                    </li>
                    <li>
                        <b>Mountain</b>
                        <ul>
                            <li>
                                Graphs the Mountain graph.
                                <div style={{ height: '365px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSampleMountain.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Sample Mountain'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Column</b>
                        <ul>
                            <li>
                                Graphs the Column graph consisting of bars.
                                <div style={{ height: '369px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSampleColumns.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Sample Column'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <h6>Scale</h6>
            <div style={{ height: '215px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsScale.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Scale'></img>
            </div>
            <div>
                Sets the Scale of the Graph. Available options are Log, Linear, and Percentage.
                <ul>
                    <li>
                        <b>Log</b>
                        <ul>
                            <li>
                                Sets the scale of the graph to Log. (Default)
                                <div style={{ height: '371px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsScaleLog.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Scale Log'></img>
                                </div>
                                <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                                    Note: If the log scale is not available for the selected date range for the series, the scale will automatically adjust to linear.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Linear</b>
                        <ul>
                            <li>
                                Sets the scale of the graph to Linear.
                                <div style={{ height: '368px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsScaleLinear.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Scale Linear'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Percentage</b>
                        <ul>
                            <li>
                                Sets the scale of the graph to Percentage.
                                <div style={{ height: '362px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsScalePercentage.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Scale Percentage'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <h6>Select Currency</h6>
            <div style={{ height: '326px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsCurrency.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Currency'></img>
            </div>
            <p>
                Sets the price values of the series to the selected currency. Default is Source Currency which is the currency that the series is based.
                For instance, IBM (International Business Machines Corporation) is a Unites States based company so the source currency would be in USD (United States dollar).
                If IBM is entered for the series and if another currency besides "Source Currency" or "USD" is selected, the result graph will have data points price values converted to selected currency.
            </p>
            <h6>Volume</h6>
            <div style={{ height: '73px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsVolume.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Volume'></img>
            </div>
            <p>Checking this Volume will display a separate bar graph with Volume data. <span style={{ fontStyle: 'italic', color: 'darkred' }}>Note: This is only applicable for financial series.</span></p>
            <div style={{ height: '353px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsVolumeGraph.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Volume Graph'></img>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Data Fields/Adjustments/Series Options</h5>
                </div>
            </Box>
            <div style={{ height: '140px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSeriesOptions.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Series Options'></img>
            </div>
            <div>
                <ul>
                    <li>
                        <b>Show Splits</b>
                        <ul>
                            <li>
                                Displays the Splits Icon on the graph where the split occurred.
                                <div style={{ height: '376px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSeriesOptionsShowSplits.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Series Options Show Splits'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Show Dividends</b>
                        <ul>
                            <li>
                                Displays the Dividends Icon on the graph where the dividend was paid out.
                                <div style={{ height: '369px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSeriesOptionsShowDividends.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Series Options ShowDividends'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Split Adjust</b>
                        <ul>
                            <li>Calculates and set the data point to a split adjusted price. Checked by default.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Inflation Adjust</b>
                        <ul>
                            <li>Calculates and set the data point to a inflation adjusted price.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Total Return</b>
                        <ul>
                            <li>Sets the data point to a Total Return price.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Percent GPD</b>
                        <ul>
                            <li>
                                Sets the data point to the Percent GDP value
                                <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                                    Note: This is only applicable for Non Financial Series.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Annual Pct Change</b>
                        <ul>
                            <li>
                                Adds another line graph with percentage change.
                                <div style={{ height: '382px' }}>
                                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsSeriesOptionsAnnualPctChange.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Series Options Annual Pct Change'></img>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Moving Average</h5>
                </div>
            </Box>
            <div style={{ height: '103px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsMovingAverage.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Moving Average'></img>
            </div>
            <p>Additional moving average line graphs can be added by adding the period value.</p>
            <div style={{ height: '128px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsMovingAverageAddByButton.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Moving Average Button Add'></img>
            </div>
            <p>Period value can be added by toggle button. 50, 100, and 200 are the available options.</p>
            <div style={{ height: '152px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsMovingAverageAddByCustom.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Moving Average Custom Add'></img>
            </div>
            <p>If you wish to set the custom period value, you can do so by entering the period value and then by clicking on add button</p>
            <div style={{ height: '409px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsMovingAverageGraph.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Moving Average Graph'></img>
            </div>
            <p>Sample moving average line graphs with Moving Average of 100 and 10.</p>
            <div style={{ height: '170px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsMovingAverageRemove.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Moving Average Remove'></img>
            </div>
            <p>To remove a moving average period value that was entered, click on the circled red x button.</p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Other Notes</h5>
                </div>
            </Box>
            <div style={{ height: '529px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedGraphingChartSettingsOtherNotes.png' style={helpPageStyles.image} alt='Advanced Graphing Chart Settings Other Notes'></img>
            </div>
            <p>
                Depending on the series, some options may be disabled.
            </p>
        </div>
    )
}

export default ChartSettingsSection
