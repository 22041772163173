import React from "react"
import { ReactComponent as ImpersonateIcon } from "../../Images/svg/Impersonate.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const Impersonate = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <ImpersonateIcon />
  </SvgIcon>
)

export default Impersonate