import { MuiColorInput, MuiColorButtonProps } from "mui-color-input"
import { GraphColors, LineColor } from "../../../../Models/DataModels/Common/ColorModel"
import { Button, Grid, Icon, ListItem, Typography } from "@mui/material"
import { replaceSpecialDotSeparator } from "../../../GraphAndChartResults/GraphSubcomponents/GraphAndChartUtil"
import { ColorType } from "../ColorSelectionModal"

export interface ColorSettingsProps {
    colorSettings: GraphColors,
    setColor: (lineColor: LineColor, type: ColorType) => void
}

const ColorSettings = ({
    colorSettings,
    setColor
}: ColorSettingsProps) => {

    const CustomAdornment = (props: MuiColorButtonProps) => {
        const { onClick, bgColor } = props

        return (
            <Button sx={{ backgroundColor: bgColor, '&:hover': { backgroundColor: bgColor } }} onClick={onClick} title='Select Color' aria-label='Select Color'>
                <Icon />
            </Button>
        )
    }

    const applyColor = (lineColor: LineColor, colorValue: string, colorType: ColorType) => {
        const copy: LineColor = {
            name: lineColor.name,
            index: lineColor.index,
            strokeColor: colorValue,
            fillColor: lineColor.fillColor
        }
        setColor(copy, colorType)
    }

    const renderItem = (lineColor: LineColor, idColumn: string, colorType: ColorType) => {
        return <ListItem style={ {...(lineColor.hidden ?? false ? { display: 'none' } : {})} }>
            <Grid container spacing={2} columns={25}>
                <Grid item md={4}>
                    <Typography>{idColumn}</Typography>
                </Grid>
                <Grid item md={12}>
                    <MuiColorInput
                        key={`index${idColumn}${lineColor.name}`}
                        format="hex8"
                        value={lineColor.strokeColor}
                        onChange={(value, colors) => {
                            applyColor(lineColor, value, colorType)
                        }}
                        onBlur={() => {
                            if (lineColor.strokeColor === '') {
                                applyColor(lineColor, '#000000', colorType)
                            }
                        }}
                        inputProps={{
                            'title': 'Enter color code',
                            'aria-label': 'Enter color code'
                        }}
                        Adornment={CustomAdornment}
                    />
                </Grid>
                <Grid item md={9}>
                    <Typography color={lineColor.strokeColor}>
                        {replaceSpecialDotSeparator(lineColor.name)}
                    </Typography>
                </Grid>
            </Grid>
        </ListItem>
    }

    
    return <ul>
        {
            colorSettings.lineColors.map((lineColor, index) => <>
                {renderItem(lineColor, `${lineColor.index + 1}.`, ColorType.Main)}
            </>)
        }
        {colorSettings.technicalIndicator && renderItem(colorSettings.technicalIndicator, 'Technical Indicator', ColorType.TechnicalIndicator)}
        {colorSettings.valueIndicator && renderItem(colorSettings.valueIndicator, 'Value Indicator', ColorType.ValueIndicator)}
        {colorSettings.financialRatioIndicator && renderItem(colorSettings.financialRatioIndicator, 'Financial Ratio Indicator', ColorType.FinancialRatioIndicator)}
    </ul>
}

export default ColorSettings
