import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import InfoSvg from '../../Icons/InfoSvgIcon'
import DownLoad_Locked from '../../Icons/DownLoad_Locked'
import GraphwLines from '../../Icons/GraphwLines'
import GraphwLines_Locked from '../../Icons/GraphwLines_Locked'
import TickerInfoModal, { TickerInfoModalProps } from './TickerInfoModal'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import DownloadAction, { DownloadActionProps } from '../Utility/DownloadAction'
import GraphModal, { GraphModalProps } from '../../GraphAndChartResults/GraphSubcomponents/GraphModal'

export interface QuickActionsProps {
    rowData: any,
    graphAction?: (symbol: string) => void,
    maxTickerLimit: number
    signOut: (logoutReason: LogoutReasonType) => void
}

const QuickActions = ({
    rowData,
    graphAction,
    maxTickerLimit,
    signOut
}: QuickActionsProps) => {

    const [seriesIDForInfo, setSeriesIDForInfo] = useState<any>()
    const [showGraphModal, setShowGraphModal] = useState<boolean>(false)

    const infoModalProps: TickerInfoModalProps = {
        seriesID: seriesIDForInfo,
        clearSeriesID: () => { setSeriesIDForInfo(null) },
        signOut
    }

    const downloadActionsProps: DownloadActionProps = {
        isComposite: false,
        rowData,
        maxTickerLimit,
        signOut
    }

    const handlegraphButtonClick = (symbol: string) => {
        if (graphAction) {
            graphAction(symbol)
        } else {
            setShowGraphModal(true)
        }
    }

    const quickActions = (): JSX.Element => {
        if (rowData && rowData?.accessibleSeriesID !== rowData?.id) {
            return (
                <>
                    <IconButton
                        title={'Info'}
                        onClick={() => setSeriesIDForInfo(rowData?.id || rowData?.seriesID)}
                        size='small'
                    >
                        <InfoSvg />
                    </IconButton>

                    <IconButton aria-disabled
                        title={'You do not have access to this series'}
                        size='small'
                    >
                        <GraphwLines_Locked />
                    </IconButton>

                    <IconButton aria-disabled
                        title={'You do not have access to this series'}
                        size='small'
                    >
                        <DownLoad_Locked />
                    </IconButton>
                </>
            )
        } else {
            return (
                <>
                    <IconButton
                        title={'Info'}
                        onClick={() => setSeriesIDForInfo(rowData?.id || rowData?.seriesID)}
                        size='small'
                    >
                        <InfoSvg />
                    </IconButton>

                    <IconButton
                        title={'Graph'}
                        onClick={() => handlegraphButtonClick(rowData.symbol as string)}
                        size='small'
                    >
                        <GraphwLines />
                    </IconButton>

                    <DownloadAction {...downloadActionsProps} />
                </>
            )
        }
    }

    const graphModalProps: GraphModalProps = {
        showModal: showGraphModal,
        setShow: setShowGraphModal,
        seriesName: rowData.symbol as string,
        signOut
    }

    return (
        <>
            {quickActions()}
            <TickerInfoModal {...infoModalProps} />
            {showGraphModal && <GraphModal {...graphModalProps} />}
        </>
    )
}

export default QuickActions
