import { Box, Link, List, ListItem } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { financialToolsHelpParts, pathParts } from '../../../Models/DataModels/Common/RedirectionModel'
import BasicFinancialTools from './FinancialToolsHelpSections/BasicFinancialTools'
import CAPEratios from './FinancialToolsHelpSections/CAPEratios'
import Capitalization from './FinancialToolsHelpSections/Capitalization'
import CurrencyConverter from './FinancialToolsHelpSections/CurrencyConverter'
import FamaFrench from './FinancialToolsHelpSections/FamaFrench'
import MyIndices from './FinancialToolsHelpSections/MyIndices'
import YieldCurve from './FinancialToolsHelpSections/YieldCurve'
import { helpPageStyles } from '../HelpPageStyles'

export interface FinancialToolsHelpProps {
    subSection: string
}

const FinancialToolsHelp = ({
    subSection
}: FinancialToolsHelpProps) => {

    const basicSection = useRef<HTMLInputElement>(null)
    const capeRatiosSection = useRef<HTMLInputElement>(null)
    const capitalizationSection = useRef<HTMLInputElement>(null)
    const currencyConverterSection = useRef<HTMLInputElement>(null)
    const famaFrenchSection = useRef<HTMLInputElement>(null)
    const myIndicesSection = useRef<HTMLInputElement>(null)
    const yieldCurveSection = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()

    const executeScrollBasic = () => basicSection?.current?.scrollIntoView()
    const executeScrollCapeRatios = () => capeRatiosSection?.current?.scrollIntoView()
    const executeScrollCapitalization = () => capitalizationSection?.current?.scrollIntoView()
    const executeScrollCurrencyConverter = () => currencyConverterSection?.current?.scrollIntoView()
    const executeScrollFamaFrench = () => famaFrenchSection?.current?.scrollIntoView()
    const executeScrollMyIndices = () => myIndicesSection?.current?.scrollIntoView()
    const executeScrollYieldCurve = () => yieldCurveSection?.current?.scrollIntoView()

    const subSectionSwitch = () => {
        switch (subSection) {
            case financialToolsHelpParts.financialToolsSections.basic:
                executeScrollBasic()
                break
            case financialToolsHelpParts.financialToolsSections.CAPEratio:
                executeScrollCapeRatios()
                break
            case financialToolsHelpParts.financialToolsSections.capitalization:
                executeScrollCapitalization()
                break
            case financialToolsHelpParts.financialToolsSections.currencyConverter:
                executeScrollCurrencyConverter()
                break
            case financialToolsHelpParts.financialToolsSections.famaFrench:
                executeScrollFamaFrench()
                break
            case financialToolsHelpParts.financialToolsSections.myIndices:
                executeScrollMyIndices()
                break
            case financialToolsHelpParts.financialToolsSections.yieldCurve:
                executeScrollYieldCurve()
                break
            default:
                break
        }
    }

    useEffect(() => {
        setTimeout(subSectionSwitch, 200);
    }, [subSection])

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                p: 1,
                m: 1,
                borderRadius: 1
            }}>
            <Box style={{ width: 1200 }}>
                <List>
                    <ListItem sx={helpPageStyles.pointer}>
                        <p><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Keywords:</span> Tools</p>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.financialToolsSections.basic) }}>1. Basic Financial Tools</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.financialToolsSections.CAPEratio) }}>2. CAPE Ratios</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.financialToolsSections.capitalization) }}>3. Capitalization</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.financialToolsSections.currencyConverter) }}>4. Currency Converter</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.financialToolsSections.famaFrench) }}>5. Fama-French Stock Screener</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.financialToolsSections.myIndices) }}>6. Custom Indices</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.financialToolsSections.yieldCurve) }}>7. Yield Curve</Link>
                    </ListItem>
                </List>
                <div ref={basicSection}>
                    <BasicFinancialTools />
                </div>
                <div ref={capeRatiosSection}>
                    <CAPEratios />
                </div>
                <div ref={capitalizationSection}>
                    <Capitalization />
                </div>
                <div ref={currencyConverterSection}>
                    <CurrencyConverter />
                </div>
                <div ref={famaFrenchSection}>
                    <FamaFrench />
                </div>
                <div ref={myIndicesSection}>
                    <MyIndices />
                </div>
                <div ref={yieldCurveSection}>
                    <YieldCurve />
                </div>
            </Box>
        </Box>
    )
}

export default FinancialToolsHelp
