import { CircularProgress, IconButton, Link, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import MaterialTable from '@material-table/core'
import { useState, useEffect } from 'react'
import { DefaultDownloadSettings, DownloadSettingsModel, GetDownloadSettingsModel } from '../../../Models/DataModels/Common/DownloadSettingsModel'
import { DownloadQueueEntry, DownloadQueueResponse, DownloadSettingDictionary, DownloadSettingDictionarySerializer, Statuses } from '../../../Models/DataModels/Responses/AutoTracResponses'
import { downloadFile, getDownloadQueue, getDownloadSettingsForQueueEntry } from '../../../Services/AutoTracService'
import DefaultMaterialTheme from '../../Common/GlobalSettings/DefaultMaterialTheme'
import MaterialTableOptions from '../../Common/GlobalSettings/MaterialTableOptions'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../Common/Modals/AlertModal'
import DownloadOptions, { DownloadOptionsProps } from '../../Common/Utility/DownloadOptions'
import { DownloadQueueRequest } from '../../../Models/DataModels/Requests/AutoTracRequests'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../Common/Utility/ComponentMessageHandler'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { MessageResponse, MessageResponseTypes, MessageResponseValueType } from '../../../Models/DataModels/Responses/NotOKResponse'
import { PaginationRequest } from '../../../Models/DataModels/Requests/SearchRequests'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import GFDTablePagination, { GFDTablePaginationProps } from '../../Common/Utility/GFDTablePagination'
import { SortProps } from '../../Search/SubComponents/SearchResults'
import { SortFieldType, SortFields, SortOrderString, SortOrderType } from '../../../Models/DataModels/Common/SortModel'
import RefreshDownloadQueue from '../../Icons/RefreshDownloadQueueIcon'
import StyledAccordion from '../../Common/Utility/StyledAccordion'
import StyledAccordionSummary from '../../Common/Utility/StyledAccordionSummary'
import StyledAccordionDetails from '../../Common/Utility/StyledAccordionDetails'
import { useLocation } from 'react-router-dom'
import EnhancedTableHead from '../../Common/Utility/EnhancedTableHead'
import { GFDToastInfo } from '../../Common/Utility/GFDToastify'

export interface DownloadQueueProps {
  SelectedWorkbook: string | undefined,
  TriggerRefreshDownloadQueue: boolean,
  ResetTriggerRefreshDownloadQueue: () => void,
  signOut: (logoutReason: LogoutReasonType) => void,
  clearMessagesToggle: boolean,
  clearMessages: () => void,
  triggerRefreshAndShowDownloadQueue: boolean,
  resetTriggerRefreshAndShowDownloadQueue: () => void
}

const DownloadQueue = ({
  triggerRefreshAndShowDownloadQueue,
  resetTriggerRefreshAndShowDownloadQueue,
  TriggerRefreshDownloadQueue,
  ResetTriggerRefreshDownloadQueue,
  signOut,
  clearMessagesToggle,
  clearMessages
}: DownloadQueueProps) => {
  type LocationState = { expanded: boolean }

  const isLocationStateValid = (state: any): state is LocationState => {
    if (!state) return false // Makes sure it's not null
    if (typeof state !== "object") return false;
    if (typeof state.expanded !== "boolean") return false;
    return true
  }

  const location = useLocation()

  const getExpandedFromState = () => {
    return isLocationStateValid(location.state) && location.state?.expanded
  }

  const [expanded, setExpanded] = useState<boolean>(getExpandedFromState() || false)
  const [queueEntries, setQueueEntries] = useState<DownloadQueueEntry[]>([])
  const [downloadSettingsWorkbook, setDownloadSettingsWorkbook] = useState<string | undefined>()
  const [downloadSettings, setDownloadSettings] = useState<DownloadSettingsModel>(DefaultDownloadSettings)
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)

  const [backgroundRefreshInProgress, setBackgroundRefreshInProgress] = useState<boolean>(false)
  const [refreshInProgress, setRefreshInProgress] = useState<boolean>(false)
  const [downloadFileInProgress, setDownloadFileInProgress] = useState<boolean>(false)
  const [downloadSettingsFetchInProgress, setDownloadSettingsFetchInProgress] = useState<boolean>(false)

  const [messages, setMessages] = useState<MessageResponse[]>([])
  const [response, setResponse] = useState<NotOKResponseModel | null>()

  const [pageNumber, setPageNumber] = useState(1)
  const [perPageCount, setPerPageCount] = useState(AppConfigurations.workbooksPerPage || 100)
  const [totalCount, setTotalCount] = useState<number>(0)

  const [sortData, setSortData] = useState<SortProps>({ sortField: SortFieldType.None, sortOrder: SortOrderType.None })

  const clearAlertsInCurrentWindow = () => {
    setMessages([])
    setResponse(null)
  }

  const clearAlert = () => {
    clearAlertsInCurrentWindow()
    clearMessages()
  }

  useEffect(() => {
    clearAlertsInCurrentWindow()
  }, [clearMessagesToggle])

  const displayMessage = (message: string, type: MessageResponseValueType) => {
    const componentMessage: MessageResponse = {
      message: message as string,
      type
    }
    setResponse(null)
    setMessages([componentMessage])
    return
  }

  const displayResponse = (model: NotOKResponseModel) => {
    if (model) {
      setMessages([])
      setResponse(model as NotOKResponseModel)
      return
    }
  }

  const getPaginationRequest = (resetPageNumber: boolean, requestTotalCount: boolean): PaginationRequest => {
    let page = pageNumber
    if (!page || page <= 0) {
      page = 1
    }

    let perPage = perPageCount
    if (!perPage) {
      perPage = 100
    }

    return {
      pageNumber: resetPageNumber ? 1 : page,
      perPageCount: perPage,
      isTotalCountRequested: requestTotalCount
    } as PaginationRequest
  }

  useEffect(() => {
    const isExpanded = getExpandedFromState()
    setExpanded(isExpanded)
    if (isExpanded) refreshDownloadQueue(true, true, true)
  }, [location.state])

  useEffect(() => {
    refreshDownloadQueue(true, true, true)
  }, [])

  useEffect(() => {
    if (TriggerRefreshDownloadQueue) {
      refreshDownloadQueue(false, true, true)
      if (ResetTriggerRefreshDownloadQueue) ResetTriggerRefreshDownloadQueue()
    }
  }, [TriggerRefreshDownloadQueue])

  useEffect(() => {
    if (triggerRefreshAndShowDownloadQueue) {
      refreshDownloadQueue(false, true, true)
      setExpanded(true)
      if (resetTriggerRefreshAndShowDownloadQueue) resetTriggerRefreshAndShowDownloadQueue()
    }
  }, [triggerRefreshAndShowDownloadQueue])

  useEffect(() => {
    refreshDownloadQueue(false, false, true)
  }, [pageNumber, sortData])

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1)
      return
    }
    refreshDownloadQueue(true, false, true)
  }, [perPageCount])

  const getDownloadQueueRequest = (resetPageNumber: boolean, requestTotalCount: boolean): DownloadQueueRequest => {
    return {
      pagination: getPaginationRequest(resetPageNumber, requestTotalCount),
      sortField: sortData.sortField,
      sortOrder: sortData.sortOrder
    }
  }

  const processResponse = (result: DownloadQueueResponse, isBackgroundCall: boolean) => {
    if (result?.pagination) {
      if (pageNumber !== result.pagination.pageNumber) setPageNumber(result.pagination.pageNumber)
      if (perPageCount !== result.pagination.perPageCount) setPerPageCount(result.pagination.perPageCount)

      if (result.pagination.totalCount || result.pagination.totalCount === 0) {
        setTotalCount(result.pagination.totalCount)
      }
    }

    if (sortData.sortOrder !== (result?.sortOrder || SortOrderType.None)) result.sortOrder = result?.sortOrder || SortOrderType.None
    if (sortData.sortField !== (result?.sortField || SortFieldType.None)) result.sortField = result?.sortField || SortFieldType.None

    const entries: DownloadQueueEntry[] = result.worksheetQueue || []
    setQueueEntries(entries)
    isBackgroundCall ? setBackgroundRefreshInProgress(false) : setRefreshInProgress(false)
  }

  const refreshDownloadQueue = (resetPageNumber: boolean, requestTotalCount: boolean, isBackgroundCall: boolean | undefined = false) => {
    if (!isBackgroundCall) clearAlert()

    if (refreshInProgress || backgroundRefreshInProgress) {
      if (!isBackgroundCall) GFDToastInfo('Download queue refresh already in progress')
      return
    }

    if (downloadFileInProgress) {
      if (!isBackgroundCall) GFDToastInfo('Download file is in progress, please wait till it completes')
      return
    }

    if (downloadSettingsFetchInProgress) {
      if (!isBackgroundCall) GFDToastInfo('Fetch download settings is in progress, please wait till it completes')
      return
    }

    isBackgroundCall ? setBackgroundRefreshInProgress(true) : setRefreshInProgress(true)
    getDownloadQueue(getDownloadQueueRequest(resetPageNumber, requestTotalCount), isBackgroundCall)
      .then((result: DownloadQueueResponse) => {
        processResponse(result, isBackgroundCall)
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        isBackgroundCall ?
          console.log('failed to get download queue entries! retrying...')
          : displayResponse(notOKResponseModel)
          isBackgroundCall ? setBackgroundRefreshInProgress(false) : setRefreshInProgress(false)
      })
  }

  const showDownloadSettings = (QueueID: number | undefined, WorkbookName: string | undefined) => () => {
    clearAlert()

    if (refreshInProgress || backgroundRefreshInProgress) {
      GFDToastInfo('Download queue refresh in progress , please wait till it completes')
      return
    }

    if (downloadFileInProgress) {
      GFDToastInfo('File download is in progress, please wait till it completes')
      return
    }

    if (downloadSettingsFetchInProgress) {
      GFDToastInfo('Another fetch for download settings is in progress, please wait till it completes')
      return
    }

    if (!QueueID) return
    setDownloadSettingsFetchInProgress(true)
    getDownloadSettingsForQueueEntry(QueueID)
      .then((result: any) => {
        const downloadSettingsData: DownloadSettingDictionary = (result?.DictionarySerializer as DownloadSettingDictionarySerializer)?.dictionary
        if (downloadSettingsData) {
          const downloadSettingsModel = GetDownloadSettingsModel(downloadSettingsData)
          setDownloadSettingsWorkbook(WorkbookName)
          setDownloadSettings(downloadSettingsModel)
          setDownloadSettingsFetchInProgress(false)
          setShowDownloadModal(true)
        } else {
          displayMessage('Invalid workbook details for queue entry!', MessageResponseTypes.Error)
          setDownloadSettingsFetchInProgress(false)
          setShowDownloadModal(false)
        }
      },
        //Reject promise
        (notOKResponseModel: NotOKResponseModel) => {
          displayResponse(notOKResponseModel)
          setDownloadSettingsFetchInProgress(false)
          setShowDownloadModal(false)
        })
  }

  const downloadOptionsProps: DownloadOptionsProps = {
    downloadSettings: downloadSettings,
    saveDownloadSettings: () => { },
    triggerSaveDownloadSettings: false,
    resetTriggerDownloadSettings: () => { },
    readOnly: true
  }

  const getDownloadModalTitle = () => {
    const title = 'Download Options'
    return downloadSettingsWorkbook ? <>
      <div>{title}</div>
      <h5 style={styles.modalTitleWorkbook}>{downloadSettingsWorkbook}</h5>
    </> : title
  }

  const downloadModalProps: AlertModalProps = {
    showModal: showDownloadModal,
    setShowModal: setShowDownloadModal,
    AlertTitle: getDownloadModalTitle(),
    AlertContent: (<DownloadOptions {...downloadOptionsProps}></DownloadOptions>),
    AlertButtons: [
      {
        type: AlertButtonType.OK,
        display: 'OK',
        onClick: () => { setShowDownloadModal(false) },
        isPrimary: true,
        style: { backgroundColor: '#007ea8', border: 'none' }
      },
    ],
    onAlertClose: () => {
      return true
    },
    Size: 'xl'
  }

  const readOnly: boolean = refreshInProgress || backgroundRefreshInProgress || downloadFileInProgress || downloadSettingsFetchInProgress

  const getShowProgressIcon = (): boolean => {
    return refreshInProgress || backgroundRefreshInProgress || downloadFileInProgress || downloadSettingsFetchInProgress
  }

  const getProgressTitle = (): string => {
    if (refreshInProgress || backgroundRefreshInProgress) {
      return 'Refresh in progress'
    }
    if (downloadFileInProgress) {
      return 'File download in progress'
    }
    if (downloadSettingsFetchInProgress) {
      return 'Fetch download settings in progress'
    }
    return ''
  }

  const renderTitle = () => {
    const title = 'Download Queue'
    const showProgress = getShowProgressIcon()
    const progressTitle = getProgressTitle()

    return (<>
      {title}
      <IconButton title={progressTitle}
        aria-label={progressTitle} component='label' sx={{
          p: '10px',
          color: '#1976d2',
          visibility: showProgress ? 'visible' : 'hidden'
        }}>
        <CircularProgress title={progressTitle} aria-label={progressTitle} />
      </IconButton>
      <IconButton sx={{
        visibility: showProgress ? 'hidden' : 'visible'
      }}
        title='Refresh Download Queue' aria-label='Refresh Download Queue' component='label'
        onClick={() => {
          clearAlert()
          refreshDownloadQueue(false, true)
        }}>
        <RefreshDownloadQueue style={{ fontSize: '1.2em' }}></RefreshDownloadQueue>
      </IconButton>
    </>)
  }

  const downloadFileFromServer = (file: string) => () => {
    clearAlert()

    if (refreshInProgress || backgroundRefreshInProgress) {
      GFDToastInfo('Download queue refresh in progress , please wait till it completes')
      return
    }

    if (downloadFileInProgress) {
      GFDToastInfo('Another file download is in progress, please wait till it completes')
      return
    }

    if (downloadSettingsFetchInProgress) {
      GFDToastInfo('Fetch download settings is in progress, please wait till it completes')
      return
    }

    setDownloadFileInProgress(true)
    downloadFile(file).then(async (response: any) => {
      const b = await response.blob()
      let a = document.createElement('a')
      let url = URL.createObjectURL(b)
      a.href = url
      a.download = file
      a.click()
      setDownloadFileInProgress(false)
    }, 
    (notOKResponseModel: NotOKResponseModel) => {
      setDownloadFileInProgress(false)
      setResponse(notOKResponseModel)
    }).catch(err => {
      console.log(err)
      setDownloadFileInProgress(false)
    })
  }

  const getDefaultSort = (column: string) => {
    if (['worksheetName', 'createTime', 'startTime', 'finishTime', 'status', 'source'].includes(column) &&
      sortData.sortField === SortFields[column]) {
      return SortOrderString[sortData.sortOrder]
    }
    return undefined
  }

  const renderColumns = (): any[] => {
    return [
      {
        title: 'Workbook Name', field: 'worksheetName', sorting: true,
        render: (rowData: any) => rowData.filePath ? <Link
          onClick={downloadFileFromServer(rowData.filePath)}
          style={{ cursor: 'pointer' }}
        >
          {rowData.worksheetName}
        </Link> : <>{rowData.worksheetName}</>
      },
      { title: 'Create Time', field: 'createTime', type: 'datetime', sorting: true },
      { title: 'Start Time', field: 'startTime', type: 'datetime', sorting: true },
      { title: 'Finish Time', field: 'finishTime', type: 'datetime', sorting: true },
      {
        title: 'Status', field: 'status', sorting: true,
        render: (rowData: any) => (rowData.status >= 0 && rowData.status <= 3 ? Statuses[rowData.status] : 'Unknown')
      },
      {
        title: 'Settings',
        width: '10%',
        render: (rowData: any) => rowData?.source === 'PowerDownload' ? <></> : <Link
          component="button"
          variant="body2"
          onClick={showDownloadSettings(rowData?.queueID, rowData?.worksheetName)}>
          {'View'}
        </Link>
      },
      { title: 'Source', field: 'source', sorting: true },
      { title: '_id', field: 'queueID', type: 'numeric', hidden: true }
    ]
  }

  const componentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages,
    setMessages,
    response,
    signOut
  }

  const onSort = (columnNumber: number) => {
    clearAlert()

    const props: SortProps = {
      sortField: sortData.sortField,
      sortOrder: SortOrderType.Ascending
    }

    try {
      switch (columnNumber) {
        case 0:
          props.sortField = SortFieldType.WorksheetName
          break
        case 1:
          props.sortField = SortFieldType.CreateTime
          break
        case 2:
          props.sortField = SortFieldType.StartTime
          break
        case 3:
          props.sortField = SortFieldType.FinishTime
          break
        case 4:
          props.sortField = SortFieldType.Status
          break
        case 6:
          props.sortField = SortFieldType.Source
          break
        default:
          props.sortField = SortFieldType.None
          break
      }

      if (props.sortField === sortData.sortField) {
        if (sortData.sortOrder === SortOrderType.Ascending) {
          props.sortOrder = SortOrderType.Descending
        } else if (sortData.sortOrder === SortOrderType.Descending) {
          props.sortField = SortFieldType.None
          props.sortOrder = SortOrderType.None
        }
      }

      setSortData(props)
    }
    catch (e) {
      console.log('Exception while sorting: ', e)
    }
  }

  const pageData: GFDTablePaginationProps = {
    perPageCount: perPageCount,
    pageNumber: pageNumber,
    totalCount: totalCount,
    setPageNumber: setPageNumber,
    setPerPageCount: setPerPageCount,
    readOnly
  }

  const renderTable = () => {
    return <div style={{ overflow: 'auto' }}>
      <ThemeProvider theme={DefaultMaterialTheme}>
        <MaterialTable
          columns={renderColumns()}
          data={queueEntries}
          title={renderTitle()}
          options={{
            ...MaterialTableOptions,
            paginationPosition: 'top',
            maxBodyHeight: '100%',
            selection: false
          }}
          components={{
            Pagination: props => (<GFDTablePagination {...pageData} />),
            Header:
              props => (
                <EnhancedTableHead {...{ sortData: { getDefaultSort, onSort }, columns: renderColumns(), readOnly }} />
              )
          }}
        />
      </ThemeProvider>
    </div>
  }

  const renderAccordion = () => {
    return <StyledAccordion expanded={expanded} onChange={(e, expanded) => setExpanded(expanded)}
      disableGutters={true}
    >
      <StyledAccordionSummary
        aria-controls='compare-content'
        id='compare-header'
        style={{ margin: '0px', color: '#027e7e', fontSize: 20, borderColor: '#027e7e' }}
      >
        <Typography>Download Queue</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails style={{ padding: 0 }}>
        {renderTable()}
      </StyledAccordionDetails>
    </StyledAccordion>
  }

  return (
    <div>
      <div>
        <ComponentMessageHandler {...componentMessageHandlerProps} />
      </div>
      {renderAccordion()}
      <AlertModal {...downloadModalProps} />
    </div>
  )
}

const styles = {
  modalTitleWorkbook: {
    fontStyle: 'italic',
    marginTop: '8px'
  },
  container: {
    width: '100%',
    height: '100%',
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  item: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
    marginBottom: '4px',
  },
  fillItem: {
    width: '100%',
    flexBasis: '0',
    flexGrow: '1',
    display: 'flex',
    overflow: 'auto',
  },
}

export default DownloadQueue
