import { IconButton, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import TwoOverlappingMagnifyingGlassesIcon from '../../Icons/TwoOverlappingMagnifyingGlassesIcon'

export interface SubSearchProps {
  pageFilterTerm: string,
  subSearchTerm: string,
  onSubSearchResults: (term: string) => void,
  onSubSearchPage: (term: string) => void,
  clearSubSearch: () => void
}

export const SubSearch = ({
  pageFilterTerm,
  subSearchTerm,
  onSubSearchResults,
  onSubSearchPage,
  clearSubSearch
}: SubSearchProps) => {
  const [filterTerm, setFilterTerm] = useState<string>(subSearchTerm || pageFilterTerm || '')

  useEffect(() => {
    if (subSearchTerm) {
      if (filterTerm !== subSearchTerm) setFilterTerm(subSearchTerm || '')
    } else if (pageFilterTerm) {
      if (filterTerm !== pageFilterTerm) setFilterTerm(pageFilterTerm || '')
    } else {
      if (filterTerm) setFilterTerm('')
    }
  }, [subSearchTerm, pageFilterTerm])

  return <>
    <TextField id='filterTerm' label='Filter Term' variant='standard'
      size='small'
      value={filterTerm}
      onChange={(e) => {
        {
          e.preventDefault()
          setFilterTerm(e.target.value)
        }
      }}
      InputProps={{
        style: { paddingRight: '2px' },
        endAdornment: <>
          <IconButton id='clearFilter' title='Clear' size='small'
            sx={{ visibility: filterTerm?.trim() ? 'visible' : 'hidden' }}
            onClick={() => {
              setFilterTerm('')
              clearSubSearch()
            }}
          >
            <ClearIcon />
          </IconButton>

          <IconButton id='filterResultset' component='label'
            title={'Search in all pages and filter'}
            sx={{ borderColor: '#007ea8', color: '#007ea8', marginLeft: '2px', marginRight: '2px' }}
            onClick={() => onSubSearchResults(filterTerm)}
          >
            <TwoOverlappingMagnifyingGlassesIcon />
          </IconButton>
        </>
      }}
    />
  </>
}
