import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../HelpPageStyles'

const TipsGraphing = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Tips and tricks</h2>
                </div>
            </Box>
            <p>
                This section will describe about tips and tricks to use the graphing features.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Getting specific data</h4>
                </div>
            </Box>
            <div style={{ height: '345px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingAllData.png' style={helpPageStyles.image} alt='Tips Graphing All Data'></img>
            </div>
            <p>
                Graphing provides visual representation of the data. When the periodicity of daily is selected, there will be many datapoints and not all the datapoints may be plotted onto the graph.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Download data</h5>
                </div>
            </Box>
            <div style={{ height: '594px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingDownload.png' style={helpPageStyles.image} alt='Tips Graphing Download'></img>
            </div>
            <p>To get the data for the specific date, you may need to use the data download feature, AutoTrac download, or simply use the download button in the graph page.</p>
            <div style={{ height: '1024px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingDownloadResult.png' style={helpPageStyles.image} alt='Tips Graphing Download Result'></img>
            </div>
            <p>The result data file (in excel and csv), will have all the data for the selected periodicity.</p>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: There may not be many data at earlier times.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Zoom in or filter date range to get the data</h5>
                </div>
            </Box>
            <div style={{ height: '1047px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingZoomDataZoomed.png' style={helpPageStyles.image} alt='Tips Graphing Download Zoom data'></img>
            </div>
            <p>
                Alternatively, you can zoom into the graph to graph until you find the data you need. You can check the plotted data point by hovering over the line.
            </p>
            <div style={{ height: '311px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingFilterDateRange.png' style={helpPageStyles.image} alt='Tips Graphing Download Filter Date Range'></img>
            </div>
            <p>
                Instead of zoom, you can also filter the date range by entering the start and end date.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Graph Enhancement</h4>
                </div>
            </Box>
            <div style={{ height: '311px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingTooManyDividends.png' style={helpPageStyles.image} alt='Tips Graphing Too Many Dividends'></img>
            </div>
            <p>
                Depending on the series, there may be exceesive amount of data and the result graph may not look presentable. For these cases, date needs to be filtered by zoom or date range filter so that the result graph look presentable.
            </p>
            <div style={{ height: '303px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingDividendsFiltered.png' style={helpPageStyles.image} alt='Tips Graphing Dividends Filtered'></img>
            </div>
            <p>
                The filtered graph looks much presentable.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Show/Hide Graphs</h4>
                </div>
            </Box>
            <div style={{ height: '306px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingComparison.png' style={helpPageStyles.image} alt='Tips Graphing Comparison'></img>
            </div>
            <p>
                Graphs can be shown or hidden by clicking on the keys in the legend.
            </p>
            <div style={{ height: '304px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingComparisonHiddenSeries.png' style={helpPageStyles.image} alt='Tips Graphing Comparison Hidden Series'></img>
            </div>
            <p>
                Hidden series are greyed out. To show the hidden series, click on the applicable keys that are grey.
            </p>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: This is only applicable for the primary graph. Graph show/hide is not applicable for Volume, Technical Indicators, Value Indicators, and Financial Ratios graphs.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Using available date range</h4>
                </div>
            </Box>
            <div style={{ height: '259px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/tipsGraphingAvailableDateRanges.png' style={helpPageStyles.image} alt='Tips Graphing Available Date Ranges'></img>
            </div>
            <p>
                Using available date range is a quick way to filter the date range. "Year-To-Date" option is useful for quickly viewing current calendar year's progress of the series.
            </p>
        </div>
    )
}

export default TipsGraphing
