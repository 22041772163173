import React, { useEffect, useState } from 'react'
import { Select, FormControl, MenuItem, InputLabel, SelectChangeEvent, Typography, Tooltip } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getUserEngineAccessibilities } from '../../../Services/AccountService'
import { ResourceAccessType, UserResourceAccessibility } from '../../../Models/DataModels/Common/AccountModel'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { SearchDatabaseType, SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'
import CheckSubscribed from '../../Icons/CheckSubscribedIcon'
import CheckUnSubscribed from '../../Icons/CheckUnsubscribedIcon'
import CheckPartialAccess from '../../Icons/CheckPartialAccessIcon'
import { engineToolTipList } from '../../Common/Utility/SearchPageToolTips'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export const menuItems: { [key: string]: { title: string, value: SearchDatabaseType } } = {
    GFDUniverse: {
        title: 'GFD Universe Quick Search',
        value: SearchDatabaseTypes.GFDUniverse
    },
    GFDatabase2Point0: {
        title: 'GFDatabase 2.0',
        value: SearchDatabaseTypes.GFDatabase2Point0
    },
    GFDatabase: {
        title: 'GFDatabase (Full Access)',
        value: SearchDatabaseTypes.GFDatabase
    },
    GFDIndices: {
        title: 'GFD Indices',
        value: SearchDatabaseTypes.GFDIndices
    },
    RealEstate: {
        title: 'Real Estate Database',
        value: SearchDatabaseTypes.RealEstate
    },
    USStocks: {
        title: 'US Stocks',
        value: SearchDatabaseTypes.USStocks
    },
    UKStocks: {
        title: 'UK Stocks',
        value: SearchDatabaseTypes.UKStocks
    },
    ConstituentMembership: {
        title: 'Constituent Membership',
        value: SearchDatabaseTypes.ConstituentMembership
    },
    EventsInTime: {
        title: 'Events-In-Time',
        value: SearchDatabaseTypes.EventsInTime
    }
}

export const getTitle = (value: SearchDatabaseType): string => {
    const engine = value === SearchDatabaseTypes.Unknown ? SearchDatabaseTypes.GFDUniverse : value
    return Object.values(menuItems).find(item => item.value === engine)?.title || ''
}

export interface SelectEngineOptions {
    userInfo: UserInfo | null,
    engineOption: SearchDatabaseType,
    setEngineOption: (engine: SearchDatabaseType) => void,
    checkIsTrial: () => boolean,
    clearOptions: any
}

const menuItemBasedOnAccess = {
    [ResourceAccessType.None]: {
        tooltip: 'No Access',
        icon: <CheckUnSubscribed fontSize="small" style={{ marginRight: '4px' }} />
    },
    [ResourceAccessType.Partial]: {
        tooltip: 'Partial Access',
        icon: <CheckPartialAccess fontSize="small" style={{ marginRight: '4px' }} />
    },
    [ResourceAccessType.All]: {
        tooltip: 'Full Access',
        icon: <CheckSubscribed fontSize="small" style={{ marginRight: '4px' }} />
    }
}

const SelectEngine = ({ userInfo, engineOption, setEngineOption, checkIsTrial, clearOptions }: SelectEngineOptions) => {
    const renderMenuItems = () => {
        const isTrial = checkIsTrial()

        const { GFDatabase, ...withoutGFDatabase } = menuItems
        const { ConstituentMembership, ...withoutConstituentMembership } = withoutGFDatabase
        const { EventsInTime, ...limited } = withoutConstituentMembership
        const allowed = isTrial ? limited : 
        resourceAccess?.canAccessUSStocks === ResourceAccessType.All ? withoutGFDatabase : withoutConstituentMembership

        return Object.keys(allowed).map(key => {
            let allow: ResourceAccessType = ResourceAccessType.None

            if (resourceAccess) {
                switch (menuItems[key].value) {
                    case menuItems.GFDUniverse.value:
                        allow = resourceAccess.canAccessGFDUniverse
                        break
                    case menuItems.GFDatabase2Point0.value:
                        allow = resourceAccess.canAccessGFDatabase2Point0
                        break
                    case menuItems.GFDatabase.value:
                        allow = resourceAccess.canAccessGFDatabase
                        break
                    case menuItems.GFDIndices.value:
                        allow = resourceAccess.canAccessGFDIndices
                        break
                    case menuItems.RealEstate.value:
                        allow = resourceAccess.canAccessRealEstate
                        break
                    case menuItems.USStocks.value:
                        allow = resourceAccess.canAccessUSStocks
                        break
                    case menuItems.UKStocks.value:
                        allow = resourceAccess.canAccessUKStocks
                        break
                    case menuItems.ConstituentMembership.value:
                        allow = resourceAccess.canAccessConstituentMembership
                        break
                    case menuItems.EventsInTime.value:
                        allow = resourceAccess.canAccessEventsInTime
                        break
                }
            }

            return <MenuItem key={menuItems[key].value} value={menuItems[key].value} title={`[${menuItemBasedOnAccess[allow]?.tooltip}] ${engineToolTipList.find((element: any) => element.label === menuItems[key].value)?.toolTip || ''}`}>
                {
                    allow === null ?
                        menuItems[key].title
                        : <div style={{ display: 'flex' }}>
                            {menuItemBasedOnAccess[allow]?.icon}
                            <Typography style={{ lineHeight: '1.25rem' }}>{menuItems[key].title}</Typography>
                        </div>
                }

            </MenuItem>
        })
    }

    const [resourceAccess, setResourceAccess] = useState<UserResourceAccessibility | null>(null)
    const [engineMenuItems, setEngineMenuItems] = useState<JSX.Element[]>(renderMenuItems())
    const [engineToolTip, setEngineToolTip] = useState<string>('')

    const { engine } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const onPathChange = () => {
        let engineFromPath: SearchDatabaseType | null = engine as SearchDatabaseType
        if (engineOption && engineFromPath && engineOption !== engine) {
            setEngineOption(engineFromPath)
        }
    }

    const handleChange = (
        event: SelectChangeEvent, newEngine: any
    ) => {
        const engine = event.target.value as SearchDatabaseType
        navigate(`search/${engine}`)
    }

    useEffect(() => {
        onPathChange()
    }, [])

    useEffect(() => {
        onPathChange()
    }, [location.pathname])

    useEffect(() => {
        const toolTip: string = engineToolTipList.find((element: any) => element.label === engineOption)?.toolTip || ''
        setEngineToolTip(toolTip)
    }, [engineOption])

    useEffect(() => {
        getUserEngineAccessibilities().then(
            (accesses: UserResourceAccessibility) => {
                setResourceAccess(accesses)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                console.log(notOKResponseModel)
            }
        )
    }, [userInfo])

    useEffect(() => {
        setEngineMenuItems(renderMenuItems())
    }, [resourceAccess])

    return (
        <>
            <FormControl style={{ width: '100%' }}>
                <InputLabel id='engine-dropdown'>
                    <p style={{ fontSize: 18 }}>
                        <strong>Engines </strong>
                        {engineOption &&
                            <Tooltip title={engineToolTip}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        }
                    </p>
                </InputLabel>
                <Select
                    labelId='engine-dropdown'
                    id='engine-dropdown'
                    size='small'
                    value={engineOption}
                    label={
                        <p style={{ fontSize: 18 }}>
                            <strong>Engines</strong>
                        </p>}
                    onChange={handleChange}
                    style={{ display: 'flex' }}
                >
                    {engineMenuItems}
                </Select>
            </FormControl>
        </>)
}

export default SelectEngine
