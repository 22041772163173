import { BlobServiceClient } from '@azure/storage-blob'
import { AppConfigurations } from '../Models/DataModels/Common/AppConfigurationsModel'

export const blobURL: string = AppConfigurations.blobURL
const legalContainerName: string = 'legal'
const cookieAgreementTextFilename : string = 'CookieAgreementText.txt'

const blobToString = async (blob: any) => {

    const fileReader = new FileReader()

    return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
            resolve(ev.target?.result)
        }
        fileReader.onerror = reject
        fileReader.readAsText(blob)
    })
}

export const fetchCookieAgreementText = async (): Promise<any> => {

    const blobServiceClient = new BlobServiceClient(blobURL)
    const containerClient = blobServiceClient.getContainerClient(legalContainerName)
    const blobClient = containerClient.getBlobClient(cookieAgreementTextFilename)
    const downloadBlockBlobResponse = await blobClient.download()
    return await blobToString(await downloadBlockBlobResponse.blobBody)
}
