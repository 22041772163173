import { Box, Link, List, ListItem } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { actionsHelpParts, pathParts } from '../../../Models/DataModels/Common/RedirectionModel'
import { helpPageStyles } from '../HelpPageStyles'
import BasicActions from './ActionsHelpSections/BasicActions'
import ToolbarActions from './ActionsHelpSections/ToolbarActions'
import QuickActions from './ActionsHelpSections/QuickActions'

export interface ActionsHelpProps {
    subSection: string
}

const ActionsHelp = ({
    subSection
}: ActionsHelpProps) => {

    const basicSection = useRef<HTMLInputElement>(null)
    const toolBarSection = useRef<HTMLInputElement>(null)
    const quickActionsSection = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()

    const executeScrollBasic = () => basicSection?.current?.scrollIntoView()
    const executeScrollToolBar = () => toolBarSection?.current?.scrollIntoView()
    const executeScrollQuickActions = () => quickActionsSection?.current?.scrollIntoView()

    const subSectionSwitch = () => {
        switch (subSection) {
            case actionsHelpParts.actionsSections.basic:
                executeScrollBasic()
                break
            case actionsHelpParts.actionsSections.toolBar:
                executeScrollToolBar()
                break
            case actionsHelpParts.actionsSections.quickActions:
                executeScrollQuickActions()
                break
            default:
                break
        }
    }

    useEffect(() => {
        setTimeout(subSectionSwitch, 200);
    }, [subSection])

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                p: 1,
                m: 1,
                borderRadius: 1
            }}>
            <Box style={{ width: 1200 }}>
                <List>
                    <ListItem sx={helpPageStyles.pointer}>
                        <p><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Keywords:</span> Actions, Toolbar, QuickActions</p>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.actionsSections.basic) }}>1. Basic Actions Overview</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.actionsSections.toolbar) }}>2. Toolbar</Link>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.actionsSections.quickActions) }}>3. QuickActions</Link>
                    </ListItem>
                </List>
                <div ref={basicSection}>
                    <BasicActions />
                </div>
                <div ref={toolBarSection}>
                    <ToolbarActions />
                </div>
                <div ref={quickActionsSection}>
                    <QuickActions />
                </div>
            </Box>
        </Box>
    )
}

export default ActionsHelp
