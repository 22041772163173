import React, { useState } from "react"
import GraphAndChartResults from "./GraphSubcomponents/GraphAndChartResults"
import { NotOKResponseModel } from "../../Models/DataModels/Common/NotOKResponseModel"
import { ErrorHandler, ErrorHandlerProps } from "../Common/Utility/ErrorHandler"
import { LogoutReasonType } from "../../Models/DataModels/Requests/AuthRequests"
import { getAuthTokenFromCookie } from "../../Services/CookieAccessService"
import { RedirectLogin } from "../../Services/RedirectionService"
import { RefreshResponse } from "../../Models/DataModels/Responses/AuthResponses"
import SessionTimer, { SessionTimerProps } from "../Common/Modals/SessionTimer/SessionTimer"
import LoadingScreen from "../Common/Modals/LoadingScreen"

export interface GraphProps {
    checkIsTrial: () => boolean,
    signOut: (logoutReason: LogoutReasonType) => void,
    processRefreshTokenResponse: (response: RefreshResponse) => void
}

const Graph = ({ checkIsTrial, signOut, processRefreshTokenResponse }: GraphProps) => {
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    const sessionTimerProps: SessionTimerProps = {
        processRefreshTokenResponse,
        signOut: signOut
    }

    const auth: any = getAuthTokenFromCookie()
    if (!auth) {
        return RedirectLogin()
    }

    return (
        <React.Fragment>
            <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} />
            <ErrorHandler {...errorHandlerProps} />
            <GraphAndChartResults setErrorResponse={setErrorResponse} setShowLoadingModal={setShowLoadingModal} checkIsTrial={checkIsTrial} />
            <SessionTimer {...sessionTimerProps} />
        </React.Fragment>
    )
}

export default Graph
