import { Autocomplete, IconButton, Pagination, TextField, Typography } from "@mui/material"
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { ClearIcon } from "@mui/x-date-pickers"
import { useEffect, useState } from "react"
import TwoOverlappingMagnifyingGlassesIcon from "../../Icons/TwoOverlappingMagnifyingGlassesIcon"

export interface GFDPaginationProps {
  searchTerm: string,
  setSearchTerm: (term: string) => void,
  startCount: number,
  endCount: number,
  perPageCount: number,
  pageNumber: number,
  totalCount: number,
  setPageNumber: (page: number) => void,
  setPerPageCount: (perPage: number) => void,
  showCountLabel?: boolean
}

const GFDPaginationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          ".MuiPaginationItem-ellipsis": {
            display: 'none'
          }
        }
      }
    }
  }
})

const GFDPagination = ({
  searchTerm,
  setSearchTerm,
  startCount,
  endCount,
  perPageCount,
  pageNumber,
  totalCount,
  setPageNumber,
  setPerPageCount,
  showCountLabel
}: GFDPaginationProps) => {
    const getPageList = (): number[] => {
        const maxPageNumber = totalCount ? Math.ceil(totalCount/perPageCount) : 0
        const list: number[] = []
        let i: number
        if (maxPageNumber) {  
          for (i=1; i<=maxPageNumber; i++) {
            list.push(i)
          }
        } else {
          list.push(0)
        }
        return list
    }
    const [pageList, setPageList] = useState<number[]>(getPageList())
    const [filterTerm, setFilterTerm] = useState<string>(searchTerm || '')

    useEffect(() => {
        setPageList(getPageList())
    }, [perPageCount, totalCount])

    const maxPageNumber = Math.ceil(totalCount/perPageCount)
    const pageLabel = `Page (${pageNumber} of ${maxPageNumber})`
    const countLabel = `${startCount} - ${endCount} of ${totalCount}`

    const onFilter = () => {
      setSearchTerm(filterTerm)
    }

    const onClearFilter = () => {
      setFilterTerm('')
      setSearchTerm('')
    }
    
    return (
      <>
        <div style={{display: 'flex', flexDirection: 'row', overflow: 'auto' }}>
          <div style={{ flexGrow: 1, flexBasis: 1, marginTop: '8px', marginLeft: '4px' }}>
            <TextField id='searchTerm' label='Search Term' variant='outlined'
            size='small'
            value={filterTerm}
            onChange={(e) => {
              {
                e.preventDefault()
                setFilterTerm(e.target.value)
              }
            }}
            InputProps={{
              style: { paddingRight: '2px' },
              endAdornment: <>
                <IconButton id='clearFilter' title='Clear' size='small'
                  sx={{ visibility: filterTerm?.trim() ? 'visible' : 'hidden' }}
                  onClick={() => onClearFilter()}
                >
                  <ClearIcon />
                </IconButton>

                <IconButton id='filterResultset' component='label'
                  title={'Search in all pages and filter'}
                  sx={{ borderColor: '#007ea8', color: '#007ea8', marginLeft: '2px', marginRight: '2px' }}
                  onClick={() => onFilter()}
                >
                  <TwoOverlappingMagnifyingGlassesIcon style={{fontSize: '1.25em'}} />
                </IconButton>
              </>
              }}
            />
          </div>
          <div style={{ flexGrow: 1, flexBasis: 1 }}>
            <Autocomplete
              style={{ marginRight: '8px', marginTop: '8px', marginLeft: '8px', marginBottom: '8px' }}
              disablePortal
              id='selectPage'
              disableClearable
              options={pageList}
              value={totalCount ? pageNumber : 0}
              onChange={(event: any, newValue: number) => {
                setPageNumber(newValue)
              }}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => {
                return option.toString()
              }}
              renderInput={(params) =>
                <TextField {...params}
                  title={pageLabel}
                  style={{ paddingTop: '0px', paddingBottom: '0px', display: 'inline-block' }}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && !['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  label={totalCount ? pageLabel : 'Page'}
                  InputLabelProps={{ style: { width: 'auto'} }}
                  size='small'
                  fullWidth
                />
              }
            />
          </div>
          {
            showCountLabel ?? false ? <div style={{ flexGrow: 0, flexBasis: 1, marginLeft: '8px', marginRight: '8px', marginTop: '16px' }}>
              <Typography noWrap={true}>{countLabel}</Typography>
            </div> : <></>
          }
          <ThemeProvider theme={GFDPaginationTheme}>
            <Pagination
              style={{ flexGrow: 1, flexBasis: 1, paddingRight: '8px', display: 'flex', alignContent: 'center' }} 
              showFirstButton
              showLastButton
              boundaryCount={-1}
              siblingCount={-1}
              count={maxPageNumber}
              page={pageNumber}
              onChange={(e: any, page: number) => {
                setPageNumber(page)
              }}
            />
          </ThemeProvider>
        </div>
      </>
    )
}

export default GFDPagination
