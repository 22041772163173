export type CorporateActionFieldType = 'Splits and Dividends' | 'Share Information'
export const CorporateActionField = {
    SplitsAndDividends: 'Splits and Dividends' as CorporateActionFieldType,
    ShareInformation: 'Share Information' as CorporateActionFieldType
}

export interface CorporateActionFields {
    SplitsAndDividends: boolean
    ShareInformation: boolean
}

export default CorporateActionField
