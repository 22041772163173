import React from "react"
import { ReactComponent as YieldCurveIcon } from "../../Images/svg/yieldCurve.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const YieldCurve = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <YieldCurveIcon />
  </SvgIcon>
)

export default YieldCurve
