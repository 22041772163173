import { Box, Link } from "@mui/material"
import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"
import { useNavigate } from "react-router-dom"
import { pathParts } from "../../../../Models/DataModels/Common/RedirectionModel"
import { BlueBlueText, GreenGreenText, ImageNumberReference, MarkedOrangeOrangeText, MarkedRedRedText } from "../../HelpPageCommonComponents"
import SelectAll from "../../../Icons/SelectAllIcon"
import DeselectAll from "../../../Icons/DeselectAllIcon"
import ReloadWorkbookList from "../../../Icons/ReloadWorkbookListIcon"
import AddWorkbook from "../../../Icons/AddWorkbookIcon"
import DeleteWorkbook from "../../../Icons/DeleteWorkbookIcon"
import RenameWorkbook from "../../../Icons/RenameWorkbookIcon"
import AddSeries from "../../../Icons/AddSeriesIcon"
import AddSelectedSeries from "../../../Icons/AddSelectedSeriesIcon"
import GraphwLines from "../../../Icons/GraphwLines"
import PieGraph from "../../../Icons/PieGraphIcon"
import DownLoad from "../../../Icons/DownLoad"
import BarGraph from "../../../Icons/BarGraph"
import ExporttoList from "../../../Icons/ExporttoListIcon"

const ToolbarActions = () => {

    const navigate = useNavigate()

    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }} >
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <br />
                        <br />
                        <br />
                        <h2 style={{ textDecoration: 'underline' }}>
                            Toolbar Actions
                        </h2>
                    </div>
                </Box>
                <br />
                <div style={{ height: '365x' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20Toolbar-1.png' style={helpPageStyles.image} alt='Toolbar Actions' />
                </div>
                <br />
                <div>
                    Once you select one or more rows, the select toolbar will display on the homepage. There are several action buttons on the toolbar.
                    <ul>
                        <li>The Add Series Button <AddSeries /> ({ImageNumberReference(1)}) will add selected series to the selected results list.</li>
                        <li>The graph button <GraphwLines /> ({ImageNumberReference(2)}) will allow you to graph the selected data in a line graph format.</li>
                        <li>The pie chart button <PieGraph /> ({ImageNumberReference(3)}) will also graph the data but in a pie chart format.</li>
                        <li>The bar chart button <BarGraph /> ({ImageNumberReference(4)}) will graph the data in a bar chart format.</li>
                        <li>The download button <DownLoad /> ({ImageNumberReference(5)}) will allow you to download your selected data.</li>
                        <li>The add workbook button <AddWorkbook /> ({ImageNumberReference(6)}) will allow you to add your selected data to AutoTrac.</li>
                        <li>The export button <ExporttoList /> ({ImageNumberReference(7)}) will automatically download an Excel Spreadsheet of your selected data.</li>
                        <li>Add to custom indices button <AddSelectedSeries /> button ({ImageNumberReference(8)}) will let you add your selected data to a custom index.</li>
                    </ul>
                </div>
                <div style={{ height: '275px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20Toolbar%20MoreActions-1.png' style={helpPageStyles.image} alt='Toolbar Actions Two' />
                </div>
                <br />
                <p>
                    The last two buttons on the toolbar will let you view the selected results or delete them.
                    <br />
                    The <BlueBlueText /> box ({ImageNumberReference(1)}) contains the button which will take you to a page which lists all of your selected results.
                    <br />
                    The <MarkedRedRedText />box ({ImageNumberReference(2)}) contains the button which will delete all of your selected results.
                </p>
                <br />

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <h4>
                            Toolbar Linegraph
                        </h4>
                    </div>
                </Box>
                <div style={{ height: '596px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20Toolbar%20LineGraph.png' style={helpPageStyles.image} alt='Toolbar Linegraph' />
                </div>
                <br />
                <p>
                    Once you choose to view the linegraph, a modal will display with options to select a primary ticker and comparison tickers.
                    <br />
                    <br />
                    The primary ticker is selected in the leftmost column, as shown in the <BlueBlueText /> box ({ImageNumberReference(1)}).
                    Only one primary ticker is selected for the graph.
                    <br />
                    <br />
                    The comparison tickers are selecetd in the column to the right of the primary ticker column, as shown in the <MarkedRedRedText />box ({ImageNumberReference(2)}).
                    You may select up to 10 tickers to compare against the primary ticker.
                    Once you have selected your primary and comparison series, clicking the blue graph button at the bottom will take you the line graph page with your selected data graphed accordingly.
                </p>
                <br />

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <h4>
                            Toolbar PieChart
                        </h4>
                    </div>
                </Box>
                <div style={{ height: '543px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20Toolbar%20PieChart.png' style={helpPageStyles.image} alt='Toolbar PieChart' />
                </div>
                <br />
                <p>
                    Clicking the piechart button will display the modal seen in the above image.
                    <br />
                    You have the option to modify the close date for the data being charted. The date input is located within the <GreenGreenText /> box ({ImageNumberReference(1)}).
                    By default, the date in the box is the current date.
                </p>
                <br />

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <h4>
                            Toolbar Download
                        </h4>
                    </div>
                </Box>
                <div style={{ height: '605px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20DownloadOptions.png' style={helpPageStyles.image} alt='Toolbar Downloads' />
                </div>
                <p>
                    The download options will pop up if you click on the download button on the toolbar.
                    <br />
                    The <MarkedRedRedText /> arrow ({ImageNumberReference(1)}) shows the list of all of the series that you have selected.
                    You can click on the "hide series" bar above the list of series to collapse the list.
                    <br />
                    The <BlueBlueText /> arrow ({ImageNumberReference(2)}) points to options for modifying the year range for the data to be downloaded.
                    <br />
                    The <GreenGreenText /> arrow ({ImageNumberReference(3)}) points to options for the data fields you'd like to have included in the download data.
                    You can select and deselect these options to have them included or excluded.
                    <br />
                    The <MarkedOrangeOrangeText /> arrow ({ImageNumberReference(4)}) points to the adjustment options where, like the data field options, you may select and deselect them.
                    You may also select the moving average in the buttons below the checkboxes.
                </p>

                <br />
                <div style={{ height: '597px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20DownloadOptionsTwo.png' style={helpPageStyles.image} alt='Toolbar Downloads Two' />
                </div>
                <p>
                    The above picture shows the rest of the download options.
                    <br />
                    The <MarkedRedRedText /> arrow ({ImageNumberReference(1)}) shows you where you can select the data fill method.
                    <br />
                    The <BlueBlueText /> arrow ({ImageNumberReference(2)}) shows the corporate actions options, which also function the same way as the above checkbox options.
                    <br />
                    The <MarkedOrangeOrangeText /> arrow ({ImageNumberReference(3)}) points to the worksheet format.
                    The left set of buttons will let you choose whether to download the data in a stacked or pivoted format.
                    You can select the type of file the download data will be saved as in the right-hand set of buttons.
                    <br />
                    Clicking the blue "Enqueue Download" at the bottom of the popup will queue your download data. You may view your queued downloads upon clicking this button.
                    <br />
                    <br />
                </p>

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <h4>
                            Toolbar Autotrac
                        </h4>
                    </div>
                </Box>
                <div style={{ height: '594px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/Actions%20AutoTrac%20Options.png' style={helpPageStyles.image} alt='Toolbar Add to Workbook' />
                </div>
                <p>
                    In the <MarkedRedRedText /> box ({ImageNumberReference(1)}) are the options for adding your selected data to AutoTrac:
                    <br />
                    The Select All (<SelectAll />) button will select all of your created workbooks.
                    <br />
                    The Deselect All button (<DeselectAll />) will deselect all of the workbooks.
                    <br />
                    The Reload workbook (<ReloadWorkbookList />) button reloads the list of workbooks.
                    <br />
                    Clicking the Add workbook (<AddWorkbook />) button lets you create a new workbook.
                    <br />
                    The rename workbook (<RenameWorkbook />) button renames your select workbook.
                    <br />
                    Finally, the delete workbook (<DeleteWorkbook />) button deletes your selected workbook(s), which is irreversible.
                    <br />

                    <br />
                    In the <BlueBlueText /> box ({ImageNumberReference(2)}) you can find the list of workbooks you have created.
                    <br />
                    <br />
                    Clicking the "save" button at the bottom will add the selected data to the workbooks(s) you have selected.

                    <br />
                    For more information on adding or editing workbooks, see the <Link onClick={() => { navigate(pathParts.help.autoTracSections.basic) }}>AutoTrac Help Section</Link>.
                </p>
            </div>
        </React.Fragment>
    )
}

export default ToolbarActions