import React from 'react'

const CurrencyConverterSideBar = () => {
    return (
        <>
            <p style={{ paddingTop: 5 }}>This tool allows for downloading historical exchange rate data of one currency (Source Currency) to one or more other currencies (Target Currency).  In addition, a graph can be displayed to analyze the behavior or one or more exchange rates over a period of time.</p>
        </>
    )
}

export default CurrencyConverterSideBar
