import React from "react"
import { ReactComponent as EditIcon } from "../../Images/svg/Edit.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const Edit = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <EditIcon />
  </SvgIcon>
)

export default Edit