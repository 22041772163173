import React from "react"
import { ReactComponent as ArrowExpandIcon } from "../../Images/svg/ArrowExpand.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ArrowExpand = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <ArrowExpandIcon />
  </SvgIcon>
)

export default ArrowExpand