import { FormControlLabel, Grid, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { CheckboxBlue } from '../../Common/GlobalSettings/CustomStyles'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export interface DateYearRangeInputProps {
    setIsDatesValid: (isDatesValid: boolean) => void,
    setValidStartYear: (startYear: number | null) => void,
    setValidEndYear: (endYear: number | null) => void,
    setIsOnlyInYearsRange: (isOnlyInYearsRange: boolean) => void,
    hideActiveInRangeCheckBox?: boolean
    startYearGridItemSizeOverride?: number,
    EndYearGridItemSizeOverride?: number,
    inRangeCheckboxGridItemSizeOverride?: number
}

const DateYearRangeInput = ({
    setIsDatesValid,
    setValidStartYear,
    setValidEndYear,
    setIsOnlyInYearsRange,
    hideActiveInRangeCheckBox,
    startYearGridItemSizeOverride,
    EndYearGridItemSizeOverride,
    inRangeCheckboxGridItemSizeOverride
}: DateYearRangeInputProps) => {

    const errorMessages = {
        startGreaterThanEnd: 'Start Year cannot be more than End Year',
        EndlessThanStart: 'End Year cannot be less than Start Year'
    }

    const minimumDate: moment.Moment = moment(`${AppConfigurations.minimumDate}`)
    const maximumDate: moment.Moment = moment()

    const [inputStartYear, setInputStartYear] = useState<moment.Moment | null>(null)
    const [inputEndYear, setInputEndYear] = useState<moment.Moment | null>(null)
    const [inputIsOnlyInRange, setInputIsOnlyInRange] = useState<boolean>(false)
    const [helpTextStartYear, setHelpTextStartYear] = useState<string>('')
    const [helpTextEndYear, setHelpTextEndYear] = useState<string>('')

    const resetErrorStates = () => {
        setIsDatesValid(false)
        setHelpTextStartYear('')
        setHelpTextEndYear('')
    }

    const isStartYearValid = (): boolean => {
        if (!inputStartYear) {
            return true
        }
        if (!inputStartYear.isValid()) {
            return false
        }
        if (!inputEndYear || !inputEndYear.isValid()) {
            return true
        }
        if (inputStartYear > inputEndYear) {
            setHelpTextStartYear(errorMessages.startGreaterThanEnd)
            return false
        }
        return true
    }

    const isEndYearValid = (): boolean => {
        if (!inputEndYear) {
            return true
        }
        if (!inputEndYear.isValid()) {
            return false
        }
        if (!inputStartYear || !inputStartYear.isValid()) {
            return true
        }
        if (inputEndYear < inputStartYear) {
            setHelpTextEndYear(errorMessages.EndlessThanStart)
            return false
        }
        return true
    }

    const validateYearInputs = (): boolean => {
        resetErrorStates()
        if (isStartYearValid() && isEndYearValid()) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (validateYearInputs()) {
            setValidStartYear(inputStartYear?.year() || null)
            setValidEndYear(inputEndYear?.year() || null)
            setIsDatesValid(true)
            setIsOnlyInYearsRange(inputIsOnlyInRange)
        }
    }, [inputStartYear, inputEndYear, inputIsOnlyInRange])

    return (
        <>
            <Grid item md={startYearGridItemSizeOverride || 3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label='Start Year'
                        views={['year']}
                        value={inputStartYear}
                        onChange={(value: moment.Moment | null) => {
                            if (value === null || value >= minimumDate) {
                                setInputStartYear(value)
                            }
                        }}
                        slotProps={{
                            textField: {
                                id: 'startYear',
                                variant: 'outlined',
                                size: 'small',
                                InputLabelProps: {
                                    shrink: true
                                },
                                sx: GFDDatabaseStyles.wholeLineInput,
                                helperText: helpTextStartYear
                            },
                            field: { clearable: true, onClear: () => setInputStartYear(null) },
                        }}
                        minDate={minimumDate}
                        maxDate={maximumDate}
                    />

                </LocalizationProvider>
            </Grid>
            <Grid item md={EndYearGridItemSizeOverride || 3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label='End Year'
                        views={['year']}
                        value={inputEndYear}
                        onChange={(value: moment.Moment | null) => {
                            if (value === null || value >= minimumDate) {
                                setInputEndYear(value)
                            }
                        }}
                        slotProps={{
                            textField: {
                                id: 'endYear',
                                variant: 'outlined',
                                size: 'small',
                                InputLabelProps: {
                                    shrink: true
                                },
                                sx: GFDDatabaseStyles.wholeLineInput,
                                helperText: helpTextEndYear
                            },
                            field: { clearable: true, onClear: () => setInputEndYear(null) }
                        }}
                        minDate={minimumDate}
                        maxDate={maximumDate}
                    />
                </LocalizationProvider>
            </Grid>
            {!hideActiveInRangeCheckBox && (inputStartYear || inputEndYear) && (
                <Grid item md={inRangeCheckboxGridItemSizeOverride || 3}>
                    <FormControlLabel control={
                        <CheckboxBlue
                            id='isOnlyInYearsRangeCheckbox'
                            checked={inputIsOnlyInRange}
                            onChange={(e: any) => {
                                setInputIsOnlyInRange(e.currentTarget.checked)
                            }}
                            inputProps={{
                                'title': 'Only include series in range',
                                'aria-label': 'Only include series in range'
                            }}
                        />
                    } label='Only include series in years range' />
                    <Tooltip title={'Only include series in selected start and end years range'}>
                        <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                    </Tooltip>
                </Grid>
            )}
        </>
    )
}

const GFDDatabaseStyles = {
    row: {
        display: 'flex',
        flexWrap: 'wrap' as 'wrap'
    },
    wholeLineInput: {
        paddingBottom: 1,
        width: '100%'
    }
}

export default DateYearRangeInput
