import { Autocomplete, Box, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { PopulateCurrencyRequest } from '../../../../Models/DataModels/Requests/PopulationRequests'
import { populateCurrencies } from '../../../../Services/FieldPopulationService'
import { blankEntry } from '../../../../Models/DataModels/Common/FieldPopulationModel'
import { LabelValuePair } from '../../../../Models/DataModels/Common/LabelValuePairModel'
import { ChartScaleTypes, DateRangeSelections, PeriodicityTypes } from '../../../../Models/DataModels/Common/ToolsModel'
import { UnorderedMultiErrorLines } from '../../Utility/MultiLines'
import { SeriesChartOptionsResult } from '../../../../Models/DataModels/Responses/GraphResponses'
import { AppConfigurations } from '../../../../Models/DataModels/Common/AppConfigurationsModel'
import { CustomBtnBlue, CheckboxBlue, CustomToggle } from '../../GlobalSettings/CustomStyles'

const ChartSettings = (chartSettings: any) => {

    const [selectedCurrency, setSelectedCurrency] = useState<LabelValuePair>(blankEntry)
    const [currencyList, setCurrencyList] = useState<any[]>([blankEntry])
    const [currencyEnabled, setCurrencyEnabled] = useState<boolean>(true)

    const [includeVolumeChart, setIncludeVolumeChart] = useState<any>(false)
    const [isSecuritiesSearchTerm, setIsSecuritiesSearchTerm] = useState<boolean>(chartSettings?.chartSettingsSetters?.percentGDP === false ? false : true)

    const [showSplitsChecked, setShowSplitsChecked] = useState<any>(false)
    const [splitAdjustedChecked, setSplitAdjustedChecked] = useState<any>(true)
    const [totalReturnChecked, setTotalReturnChecked] = useState<any>(false)
    const [percentGDPChecked, setPercentGDPChecked] = useState<any>(false)
    const [showDividendsChecked, setShowDividendsChecked] = useState<any>(false)
    const [inflationAdjustedChecked, setInflationAdjustedChecked] = useState<any>(false)
    const [annualPctChgChecked, setAnnualPctChgChecked] = useState<any>(false)

    const [showSplitsEnabled, setShowSplitsEnabled] = useState<boolean>(true)
    const [showDividendsEnabled, setShowDividendsEnabled] = useState<boolean>(true)
    const [splitAdjustedEnabled, setSplitAdjustedEnabled] = useState<boolean>(true)
    const [inflationAdjustedEnabled, setInflationAdjustedEnabled] = useState<boolean>(true)
    const [totalReturnEnabled, setTotalReturnEnabled] = useState<boolean>(true)
    const [percentGDPEnabled, setPercentGDPEnabled] = useState<boolean>(true)
    const [annualPercentageChangeEnabled, setAnnualPercentageChangeEnabled] = useState<boolean>(true)

    const [isCustomIndex, setIsCustomIndex] = useState<boolean>(false)

    const [chartSettingsErrors, setChartSettingsErrors] = useState<string[]>([])
    const [nonFinancialSeriesMessage, setNonFinancialSeriesMessage] = useState<string>('')

    const minimumDate: moment.Moment = moment(AppConfigurations.minimumDate)

    const minMovingAveragePeriod: number = 1
    const maxMovingAveragePeriod: number = 2000

    const isTrialUser: boolean = chartSettings?.chartSettingsSetters?.checkIsTrial() || false

    const handleChangeDate = (
        event: React.MouseEvent<HTMLElement>,
        newDate: string,
    ) => {
        chartSettings.chartSettingsSetters.setCurrentDateSelection(newDate)
    }

    const handleChangeMode = (
        event: React.MouseEvent<HTMLElement>,
        newMode: string,
    ) => {
        chartSettings.chartSettingsSetters.setCurrentDateMode(newMode)
    }

    const handleChangePeriodicity = (
        event: SelectChangeEvent
    ) => {
        chartSettings.chartSettingsSetters.setPeriodicity(event.target.value)
    }

    const handleChangeAvg = (
        event: React.MouseEvent<HTMLElement>,
        newAvgs: string[]
    ) => {
        chartSettings.chartSettingsSetters.setMovingAvg(newAvgs)
    }

    const checkboxsStates = () => {
        chartSettings.chartSettingsSetters.showSplits ? setShowSplitsChecked(true) : setShowSplitsChecked(false)
        chartSettings.chartSettingsSetters.splitAdjusted ? setSplitAdjustedChecked(true) : setSplitAdjustedChecked(false)
        chartSettings.chartSettingsSetters.totalReturn ? setTotalReturnChecked(true) : setTotalReturnChecked(false)
        chartSettings.chartSettingsSetters.percentGDP ? setPercentGDPChecked(true) : setPercentGDPChecked(false)
        chartSettings.chartSettingsSetters.showDividends ? setShowDividendsChecked(true) : setShowDividendsChecked(false)
        chartSettings.chartSettingsSetters.inflationAdjusted ? setInflationAdjustedChecked(true) : setInflationAdjustedChecked(false)
        chartSettings.chartSettingsSetters.annualPctChg ? setAnnualPctChgChecked(true) : setAnnualPctChgChecked(false)
        chartSettings.chartSettingsSetters.includeVolumeChart ? setIncludeVolumeChart(true) : setIncludeVolumeChart(false)
    }

    const renderPeriodicityOptions = (): JSX.Element[] => {
        return [
            <MenuItem key={PeriodicityTypes.NaturalFrequency} value={PeriodicityTypes.NaturalFrequency}>Natural Frequency</MenuItem>,
            <MenuItem key={PeriodicityTypes.Daily} value={PeriodicityTypes.Daily}>Daily</MenuItem>,
            <MenuItem key={PeriodicityTypes.Weekly} value={PeriodicityTypes.Weekly}>Weekly</MenuItem>,
            <MenuItem key={PeriodicityTypes.Monthly} value={PeriodicityTypes.Monthly}>Monthly</MenuItem>,
            <MenuItem key={PeriodicityTypes.Quarterly} value={PeriodicityTypes.Quarterly}>Quarterly</MenuItem>,
            <MenuItem key={PeriodicityTypes.Annually} value={PeriodicityTypes.Annually}>Annually</MenuItem>
        ]
    }

    useEffect(() => {
        const request: PopulateCurrencyRequest = {
            group: 'All'
        }
        populateCurrencies(request).then((result: any) => {
            const currenciesToSet: any[] = [...currencyList, ...result]
            setCurrencyList(currenciesToSet)

            const defaultOrPreviousCurrency: string = chartSettings.chartSettingsSetters.currencyType
            const defaultOrPreviousCurrencyEntry: LabelValuePair | null = currenciesToSet.find((element: LabelValuePair) => element.value === defaultOrPreviousCurrency) || null

            if (defaultOrPreviousCurrencyEntry) {
                setSelectedCurrency(defaultOrPreviousCurrencyEntry)
                chartSettings.chartSettingsSetters.setCurrencyType(defaultOrPreviousCurrencyEntry.value)
            } else {
                setSelectedCurrency(currenciesToSet[0])
                chartSettings.chartSettingsSetters.setCurrencyType(currenciesToSet[0].value)
            }
        },
            //Reject promise
            () => {
                console.log('rejected promise!')
            })

        checkboxsStates()
    }, [])

    useEffect(() => {
        chartSettings.chartSettingsSetters.setIsApplyButtonDisabled(false)
        if (chartSettings.chartSettingsSetters.currentDateMode === 'customDate') {
            const startDate: moment.Moment = moment(chartSettings.chartSettingsSetters.currentStartDate)
            const endDate: moment.Moment = moment(chartSettings.chartSettingsSetters.currentEndDate)
            if (startDate >= endDate) {
                setChartSettingsErrors(['Start Date cannot be greater than End Date'])
                chartSettings.chartSettingsSetters.setIsApplyButtonDisabled(true)
            } else {
                setChartSettingsErrors([])
                chartSettings.chartSettingsSetters.setIsApplyButtonDisabled(false)
            }
        }
    }, [chartSettings.chartSettingsSetters.currentStartDate, chartSettings.chartSettingsSetters.currentEndDate])

    useEffect(() => {
        const extraOptions: SeriesChartOptionsResult = chartSettings.chartSettingsSetters.options

        if (!extraOptions) {
            setShowSplitsEnabled(true)
            setShowDividendsEnabled(true)
            setSplitAdjustedEnabled(true)
            setInflationAdjustedEnabled(true)
            setTotalReturnEnabled(true)
            setPercentGDPEnabled(true)
            setAnnualPercentageChangeEnabled(true)
            setIsCustomIndex(false)
            setNonFinancialSeriesMessage('')
            setIsSecuritiesSearchTerm(true)
            chartSettings.chartSettingsSetters.setPercentGDP(false)
            checkboxsStates()
            setCurrencyEnabled(true)
            return
        }

        if (extraOptions?.hasSecurities) {
            setNonFinancialSeriesMessage('')
            setIsSecuritiesSearchTerm(true)
            chartSettings.chartSettingsSetters.setPercentGDP(false)
        } else {
            setNonFinancialSeriesMessage('Non Financial Series will not have all the options applied.')
            setIsSecuritiesSearchTerm(false)
            chartSettings.chartSettingsSetters.setTotalReturn(false)
        }

        if (extraOptions?.seriesOptions?.containsSplits) {
            setShowSplitsEnabled(true)
        } else {
            setShowSplitsEnabled(false)
            setShowSplitsChecked(false)
            chartSettings.chartSettingsSetters.setShowSplits(false)
        }

        if (extraOptions?.seriesOptions?.containsDividends) {
            setShowDividendsEnabled(true)
        } else {
            setShowDividendsEnabled(false)
            setShowDividendsChecked(false)
            chartSettings.chartSettingsSetters.setShowDividends(false)
        }

        if (extraOptions?.seriesOptions?.containsSplitAdjusted) {
            setSplitAdjustedEnabled(true)
        } else {
            setSplitAdjustedEnabled(false)
            setSplitAdjustedChecked(false)
            chartSettings.chartSettingsSetters.setSplitAdjusted(false)
        }

        if (extraOptions?.seriesOptions?.containsInflationAdjusted) {
            setInflationAdjustedEnabled(true)
        } else {
            setInflationAdjustedEnabled(false)
            setInflationAdjustedChecked(false)
            chartSettings.chartSettingsSetters.setInflationAdjusted(false)
        }

        if (extraOptions?.seriesOptions?.containsTotalReturn) {
            setTotalReturnEnabled(true)
        } else {
            setTotalReturnEnabled(false)
            setTotalReturnChecked(false)
            chartSettings.chartSettingsSetters.setTotalReturn(false)
        }

        if (extraOptions?.seriesOptions?.containsPercentGDP) {
            setPercentGDPEnabled(true)
        } else {
            setPercentGDPEnabled(false)
            setPercentGDPChecked(false)
            chartSettings.chartSettingsSetters.setPercentGDP(false)
        }

        if (extraOptions?.seriesOptions?.containsAnnualPercentageChange) {
            setAnnualPercentageChangeEnabled(true)
        } else {
            setAnnualPercentageChangeEnabled(false)
            setAnnualPctChgChecked(false)
            chartSettings.chartSettingsSetters.setAnnualPctChg(false)
        }

        if (extraOptions?.isCustomIndex) {
            setIsCustomIndex(true)
        } else {
            setIsCustomIndex(false)
        }

        checkboxsStates()

        if (extraOptions?.isCurrencyApplicable) {
            setCurrencyEnabled(true)
        } else {
            setCurrencyEnabled(false)
            if (currencyList?.length > 0) {
                setSelectedCurrency(currencyList[0])
                chartSettings.chartSettingsSetters.setCurrencyType(currencyList[0].value)
            } else {
                setSelectedCurrency(blankEntry)
                chartSettings.chartSettingsSetters.setCurrencyType(blankEntry.value)
            }
        }

        if (extraOptions?.seriesMinDate) {
            const startDate: moment.Moment = moment(extraOptions.seriesMinDate)
            chartSettings.chartSettingsSetters.setCurrentStartDate(startDate)
        }

        if (extraOptions?.seriesMaxDate) {
            const endDate: moment.Moment = moment(extraOptions.seriesMaxDate)
            chartSettings.chartSettingsSetters.setCurrentEndDate(endDate)
        }

    }, [chartSettings.chartSettingsSetters.options])

    useEffect(() => {
        checkboxsStates()
    }, [chartSettings.chartSettingsSetters.showSplits, chartSettings.chartSettingsSetters.splitAdjusted, chartSettings.chartSettingsSetters.totalReturn, chartSettings.chartSettingsSetters.percentGDP, chartSettings.chartSettingsSetters.showDividends, chartSettings.chartSettingsSetters.inflationAdjusted, chartSettings.chartSettingsSetters.annualPctChg, chartSettings.chartSettingsSetters.includeVolumeChart])

    return (
        <div>
            <Grid container spacing={3} columns={25} sx={{ paddingBottom: 2 }}>
                <Grid item md={25}>
                    <ToggleButtonGroup
                        value={chartSettings.chartSettingsSetters.currentDateMode}
                        exclusive
                        onChange={handleChangeMode}
                        aria-label='Platform'
                        disabled={isTrialUser}
                    >
                        <CustomToggle size='small' value='selectDate'>Select Date</CustomToggle>
                        <CustomToggle size='small' value='customDate'>Custom Date</CustomToggle>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            {/* SelectDate */}
            {chartSettings.chartSettingsSetters.currentDateMode === 'selectDate' ?
                <Grid container spacing={3} columns={25} >
                    <Grid item md={3} flexDirection='column'>
                        <span style={{ fontWeight: 'bold' }}>Date Range</span>
                    </Grid>
                    <Grid item md={14}>
                        <ToggleButtonGroup
                            color='primary'
                            value={chartSettings.chartSettingsSetters.currentDateSelection || null}
                            exclusive
                            onChange={handleChangeDate}
                            aria-label='Platform'
                            disabled={isTrialUser}
                        >
                            <CustomToggle value={DateRangeSelections.Month1}>1mo</CustomToggle>
                            <CustomToggle value={DateRangeSelections.Month3}>3mo</CustomToggle>
                            <CustomToggle value={DateRangeSelections.Month6}>6mo</CustomToggle>
                            <CustomToggle value={DateRangeSelections.YearToDate} sx={{ fontSize: 9 }}>Year-To-Date</CustomToggle>
                            <CustomToggle value={DateRangeSelections.Year1}>1yr</CustomToggle>
                            <CustomToggle value={DateRangeSelections.Year2}>2yr</CustomToggle>
                            <CustomToggle value={DateRangeSelections.Year5}>5yr</CustomToggle>
                            <CustomToggle value={DateRangeSelections.Year10}>10yr</CustomToggle>
                            <CustomToggle value={DateRangeSelections.Year20}>20yr</CustomToggle>
                            <CustomToggle value={DateRangeSelections.Max}>Max</CustomToggle>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item md={2}>
                        <span id='periodicityLabel' style={{ fontWeight: 'bold' }}>Periodicity:</span>
                    </Grid>
                    <Grid item md={6}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <Select
                                    size='small'
                                    labelId='periodicityLabel'
                                    id='periodicitySelect'
                                    value={chartSettings.chartSettingsSetters.periodicity}
                                    onChange={handleChangePeriodicity}
                                    disabled={isTrialUser}
                                >
                                    {renderPeriodicityOptions()}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                : <></>}
            {/* CustomDate */}
            {chartSettings.chartSettingsSetters.currentDateMode === 'customDate' ?
                <>
                    <Grid container spacing={3} columns={25} >
                        <Grid item md={3} flexDirection='column'>
                            <span style={{ fontWeight: 'bold' }}>Date Range</span>
                        </Grid>
                        <Grid item md={7}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label='Start Date'
                                    value={chartSettings.chartSettingsSetters.currentStartDate}
                                    onChange={(newValue) => {
                                        chartSettings.chartSettingsSetters.setCurrentStartDate(newValue as moment.Moment)
                                    }}
                                    sx={{ fontWeight: 'bold' }}
                                    minDate={minimumDate}
                                    disabled={isTrialUser}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={7}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label='End Date'
                                    value={chartSettings.chartSettingsSetters.currentEndDate}
                                    onChange={(newValue) => {
                                        chartSettings.chartSettingsSetters.setCurrentEndDate(newValue as moment.Moment)
                                    }}
                                    sx={{ fontWeight: 'bold' }}
                                    minDate={minimumDate}
                                    disabled={isTrialUser}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={2}>
                            <span id='periodicityLabel' style={{ fontWeight: 'bold' }}>Periodicity:</span>
                        </Grid>
                        <Grid item md={6}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <Select
                                        size='small'
                                        labelId='periodicityLabel'
                                        id='periodicitySelect'
                                        value={chartSettings.chartSettingsSetters.periodicity}
                                        onChange={handleChangePeriodicity}
                                        disabled={isTrialUser}
                                    >
                                        {renderPeriodicityOptions()}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                    {chartSettingsErrors.length > 0 &&
                        <Grid container spacing={3} columns={25}>
                            <Grid item md={25} flexDirection='column'>
                                <UnorderedMultiErrorLines lines={chartSettingsErrors} />
                            </Grid>
                        </Grid>
                    }
                </>

                : <></>}

            <Divider sx={{ borderColor: 'black', paddingTop: 1 }} />

            <Grid container spacing={2} columns={15} sx={{ paddingBottom: 1, paddingTop: 3 }}>
                <Grid item md={3}>
                    <span style={{ fontWeight: 'bold' }}>Chart Type</span>
                </Grid>
                <Grid item md={3}>
                    <TextField
                        value={chartSettings.chartSettingsSetters.graphType}
                        onChange={(e) => chartSettings.chartSettingsSetters.setGraphType(e.target.value)}
                        select
                        label='Graph Type'
                        sx={{ minWidth: 120 }}
                        size={'small'}
                        disabled={isTrialUser}
                    >
                        <MenuItem value={'line'}>Line (Close Only)</MenuItem>
                        <MenuItem value={'candlestick'}>Candlestick</MenuItem>
                        <MenuItem value={'hlc'}>Bars(HLC)</MenuItem>
                        <MenuItem value={'ohlc'}>Bars(OHLC)</MenuItem>
                        <MenuItem value={'mountain'}>Mountain</MenuItem>
                        <MenuItem value={'bar'}>Column</MenuItem>
                    </TextField>
                </Grid>
                <Grid item md={2}>
                    <TextField
                        value={chartSettings.chartSettingsSetters.scaleType}
                        onChange={(e) => chartSettings.chartSettingsSetters.setScaleType(e.target.value)}
                        select
                        label='Scale'
                        sx={{ minWidth: 120 }}
                        size={'small'}
                        disabled={isTrialUser}
                    >
                        <MenuItem value={ChartScaleTypes.Log}>Log</MenuItem>
                        <MenuItem value={ChartScaleTypes.Linear}>Linear</MenuItem>
                        <MenuItem value={ChartScaleTypes.Percentage}>Percentage</MenuItem>
                    </TextField>
                </Grid>
                <Grid item md={4}>
                    <Autocomplete
                        disablePortal
                        id='selectCurrency'
                        options={currencyList}
                        value={selectedCurrency}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(e, value) => {
                            let actualValue: any = ''
                            typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                            chartSettings.chartSettingsSetters.setCurrencyType(actualValue)
                            setSelectedCurrency(value)
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                label='Select Currency'
                                size='small'
                                value={chartSettings.chartSettingsSetters.currencyType}
                                onChange={(e) => {
                                    e.preventDefault()
                                    chartSettings.chartSettingsSetters.setCurrencyType(e.target.value)
                                }}
                            />
                        }
                        disabled={isTrialUser || !currencyEnabled}
                    />
                </Grid>
                <Grid item md={3}>
                    {chartSettings.chartSettingsSetters.showVolumeOption && (
                        <FormGroup>
                            <FormControlLabel control={
                                <CheckboxBlue
                                    id='includeVolumeChart'
                                    checked={includeVolumeChart}
                                    onChange={(e: any) => {
                                        setIncludeVolumeChart(e.target.checked)
                                        e.target.checked ? chartSettings.chartSettingsSetters.setIncludeVolumeChart(true) : chartSettings.chartSettingsSetters.setIncludeVolumeChart(false)
                                    }}
                                />}
                                label='Volume'
                                disabled={isTrialUser} />
                        </FormGroup>
                    )}
                </Grid>
            </Grid>

            {['divideby', 'subtractfrom'].includes(chartSettings.chartSettingsSetters.comparisonType) &&
            <Grid container spacing={2} columns={15} sx={{ paddingBottom: 1 }}>
                <Grid item md={10}>
                    <Typography color='blue'>"Divide By" and "Subtract From" comparison settings apply only to Line graph(s), Mountain and Column graph types</Typography>
                </Grid>
            </Grid>
            }

            {nonFinancialSeriesMessage && nonFinancialSeriesMessage !== '' && (
                <Grid container spacing={2} columns={16}>
                    <Grid item md={8}>
                        <span style={{ color: 'darkred', fontStyle: 'italic', paddingLeft: 50 }}>{nonFinancialSeriesMessage}</span>
                    </Grid>
                </Grid>
            )}

            <Divider sx={{ borderColor: 'black', paddingTop: 1 }} />

            <Grid container spacing={0} columns={12}>
                <Grid item md={2}>
                    <span style={{ fontWeight: 'bold' }}><p style={{ paddingTop: 8 }}>Data Fields</p></span>
                    <span style={{ fontWeight: 'bold' }}><p>Adjustments</p></span>
                    <span style={{ fontWeight: 'bold' }}><p>Series</p></span>
                </Grid>
                <Grid item md={2}>
                    <FormGroup>
                        <FormControlLabel control={
                            <CheckboxBlue
                                id='showSplits'
                                checked={showSplitsChecked}
                                onChange={(e: any) => {
                                    setShowSplitsChecked(e.target.checked)
                                    e.target.checked ? chartSettings.chartSettingsSetters.setShowSplits(true) : chartSettings.chartSettingsSetters.setShowSplits(false)
                                }}
                                disabled={isTrialUser || !showSplitsEnabled}
                            />}
                            label='Show Splits' />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={
                            <CheckboxBlue
                                id='splitAdjusted'
                                checked={splitAdjustedChecked}
                                onChange={(e: any) => {
                                    setSplitAdjustedChecked(e.target.checked)
                                    e.target.checked ? chartSettings.chartSettingsSetters.setSplitAdjusted(true) : chartSettings.chartSettingsSetters.setSplitAdjusted(false)
                                }}
                                disabled={isTrialUser || !splitAdjustedEnabled}
                            />}
                            label='Split Adj' />
                    </FormGroup>
                    {/* add for indexes */}
                    {isSecuritiesSearchTerm ?
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <CheckboxBlue
                                        id='totalReturn'
                                        checked={totalReturnChecked}
                                        onChange={(e: any) => {
                                            setTotalReturnChecked(e.target.checked)
                                            e.target.checked ? chartSettings.chartSettingsSetters.setTotalReturn(true) : chartSettings.chartSettingsSetters.setTotalReturn(false)
                                        }}
                                        disabled={isTrialUser || !totalReturnEnabled}
                                    />
                                }
                                label='Total Return' />
                        </FormGroup>
                        :
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <CheckboxBlue
                                        id='percentGDP'
                                        checked={percentGDPChecked}
                                        value={chartSettings.chartSettingsSetters.percentGDP}
                                        onChange={(e: any) => {
                                            setPercentGDPChecked(e.target.checked)
                                            e.target.checked ? chartSettings.chartSettingsSetters.setPercentGDP(true) : chartSettings.chartSettingsSetters.setPercentGDP(false)
                                        }}
                                        disabled={isTrialUser || !percentGDPEnabled}
                                    />
                                }
                                label='Percent GDP' />
                        </FormGroup>
                    }
                </Grid>
                <Grid item md={3}>
                    <FormGroup>
                        <FormControlLabel control={
                            <CheckboxBlue
                                id='showDividends'
                                checked={showDividendsChecked}
                                onChange={(e: any) => {
                                    setShowDividendsChecked(e.target.checked)
                                    e.target.checked ? chartSettings.chartSettingsSetters.setShowDividends(true) : chartSettings.chartSettingsSetters.setShowDividends(false)
                                }}
                                disabled={isTrialUser || !showDividendsEnabled}
                            />
                        }
                            label='Show Dividends' />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={
                            <CheckboxBlue
                                id='inflationAdjusted'
                                checked={inflationAdjustedChecked}
                                onChange={(e: any) => {
                                    setInflationAdjustedChecked(e.target.checked)
                                    e.target.checked ? chartSettings.chartSettingsSetters.setInflationAdjusted(true) : chartSettings.chartSettingsSetters.setInflationAdjusted(false)
                                }}
                                disabled={isTrialUser || !inflationAdjustedEnabled}
                            />
                        }
                            label='Inflation Adj' />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={
                            <CheckboxBlue
                                id='annualPctChg'
                                checked={annualPctChgChecked}
                                onChange={(e: any) => {
                                    setAnnualPctChgChecked(e.target.checked)
                                    e.target.checked ? chartSettings.chartSettingsSetters.setAnnualPctChg(true) : chartSettings.chartSettingsSetters.setAnnualPctChg(false)
                                }}
                                disabled={isTrialUser || !annualPercentageChangeEnabled}
                            />
                        }
                            label='Annual Pct Change' />
                    </FormGroup>
                </Grid>
                <Grid item md={3}>
                    {isCustomIndex && !annualPercentageChangeEnabled && <div style={{ color: 'darkred', fontStyle: 'italic', paddingTop: 60 }}>Annual Pct Change is available for an index with a date range of at least a full year (uses date range for the custom index provided).</div>}
                </Grid>
            </Grid>

            <Divider sx={{ borderColor: 'black', paddingTop: 1 }} />

            <Grid container spacing={1} columns={25} sx={{ paddingBottom: 1, paddingTop: 3 }}>
                <Grid item md={2} flexDirection='column'>
                    <span style={{ fontWeight: 'bold' }}>Moving Avg</span>
                </Grid>
                <Grid item md={5}>
                    <span style={{ fontWeight: 'bold', paddingRight: 5 }}>Periods:</span>

                    <ToggleButtonGroup
                        color='primary'
                        value={chartSettings.chartSettingsSetters.movingAvg}
                        onChange={handleChangeAvg}
                        disabled={isTrialUser}
                    >
                        <CustomToggle value='50'>50</CustomToggle>
                        <CustomToggle value='100'>100</CustomToggle>
                        <CustomToggle value='200'>200</CustomToggle>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item md={8}>
                    <TextField
                        id='customMovingAvg'
                        type='number'
                        label='Custom Period Value'
                        variant='outlined'
                        size='small'
                        value={chartSettings.chartSettingsSetters.customAvg}
                        sx={{ width: 200, paddingRight: 1 }}
                        InputProps={{
                            inputProps: {
                                min: minMovingAveragePeriod,
                                max: maxMovingAveragePeriod
                            }
                        }}
                        onChange={(e: any) => {
                            e.preventDefault()
                            if (e?.target?.value) {
                                const inputMA: number = e.target.value
                                let committedInputMA: number
                                if (inputMA > maxMovingAveragePeriod) {
                                    committedInputMA = maxMovingAveragePeriod
                                } else if (inputMA < minMovingAveragePeriod) {
                                    committedInputMA = minMovingAveragePeriod
                                } else {
                                    committedInputMA = inputMA
                                }
                                chartSettings.chartSettingsSetters.setCustomAvg(committedInputMA)
                            }
                        }}
                        disabled={isTrialUser}
                    />
                    <CustomBtnBlue id='addCustomAvg' sx={{ backgroundColor: '#007ea8' }} variant='contained'
                        onClick={() => {
                            const mergedMAs: any[] = [...new Set([...chartSettings.chartSettingsSetters.movingAvg, ...[chartSettings.chartSettingsSetters.customAvg]])]
                            chartSettings.chartSettingsSetters.setMovingAvg(mergedMAs)
                            chartSettings.chartSettingsSetters.setCustomAvg(1)
                        }}
                        disabled={isTrialUser}>
                        Add
                    </CustomBtnBlue>
                </Grid>
                <Grid item md={10}>
                    <span style={{ fontWeight: 'bold' }}>Lines Added to Chart:</span>
                    {chartSettings.chartSettingsSetters.movingAvg.length === 0 ? <p>No Moving Avg selected...</p> : <></>}
                    <List sx={{ width: '100%', maxWidth: 360, paddingTop: 0 }}>
                        {chartSettings.chartSettingsSetters.movingAvg.map((value: any) => (
                            <ListItem
                                key={value}
                                disableGutters
                                secondaryAction={
                                    <IconButton
                                        title='Remove Period Value'
                                        aria-label='Remove Period Value'
                                        onClick={(e: any) => {
                                            chartSettings.chartSettingsSetters.setMovingAvg(chartSettings.chartSettingsSetters.movingAvg.filter((avg: any) => {
                                                return avg !== value
                                            }))
                                        }}
                                    >
                                        <HighlightOffIcon color='error' />
                                    </IconButton>
                                }
                            >
                                <ListItemText primary={`Period: ${value}`} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </div>
    )
}

export default ChartSettings