import { Box, Link } from "@mui/material"
import React from "react"
import { helpPageStyles } from "../../HelpPageStyles"
import { useNavigate } from "react-router-dom"
import { pathParts } from "../../../../Models/DataModels/Common/RedirectionModel"
import { ImageNumberReference, MarkedRedRedText, PurplePurpleText } from "../../HelpPageCommonComponents"
import EditIndices from "../../../Icons/EditIndicesIcon"
import GraphwLines from "../../../Icons/GraphwLines"
import DownLoad from "../../../Icons/DownLoad"

const MyIndices = () => {
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }}>
                <Box sx={helpPageStyles.heading}>
                    <div>
                        <br />
                        <br />
                        <br />
                        <h2 style={{ textDecoration: 'underline' }}>
                            Custom Indices
                        </h2>
                    </div>
                </Box>
                <div style={{ height: '469px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20MyIndices.png' alt='MyIndices' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>The Custom Indices tool contains any custom indices that you may have previously created from the <Link onClick={() => { navigate(pathParts.help.financialToolsSections.famaFrench) }}>Fama-French Stock Screener</Link>.
                </p>
                <br />

                <Box sx={helpPageStyles.heading}>
                    <div>
                        <h4>
                            Custom Indices Options
                        </h4>
                    </div>
                </Box>
                <div style={{ height: '240px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20MyIndices%20Options.png' alt='MyIndices Options' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    Once an index has been added to the Custom Indices tool, additional options become available to modify the index.
                    They may be found in the <MarkedRedRedText /> boxes ({ImageNumberReference(1)}).
                    The box pointed to by the <MarkedRedRedText /> arrow ({ImageNumberReference(1)}) contains options which will appear once one or more indices have been selected.
                    The selected indices may be deleted with the trash can icon to the left within the box, or they may be downloaded via the download icon located next to the delete icon.
                </p>
                <p>
                    The <PurplePurpleText /> arrow ({ImageNumberReference(2)}) points to the options to edit, graph, or download its respective index.
                    The Edit Indices (<EditIndices />) button lets you edit the selected index.
                    Clicking this button will take you to the "Edit Custom Index" page where you can make modifications to your selected index, as seen in the image below.
                    The Graph (<GraphwLines />) button and download (<DownLoad />) button in Quick Actions function the same way they do in the FamaFrench Screening Tool; the graph (<GraphwLines />) button will redirect you to a page with the selected index graphed.
                </p>
                <div style={{ height: '453px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/FinancialTools%20MyIndices%20EditIndex.png' alt='MyIndices Edit Index' style={helpPageStyles.image} />
                </div>
                <br />
                <br />
                <p>
                    This is the page where you can alter you custom index. Here, you have the ability to add additional members by searching for them in the search box under "Add Members".
                    Once you've found and selected the member, you can click on the purple icon next to the search bar to add it to your index.
                    You can also delete a member by clicking the red trash can icon in the row that you'd like to delete.
                </p>

            </div>
        </React.Fragment>
    )
}

export default MyIndices