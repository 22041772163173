export type AdjustmentType = 'Split Adjusted' | 'Inflation Adjusted' | 'Average' |
    'Annualized Flow' | 'Per Capita' | 'Annual Percent Change' |
    'Period Percent Change' | 'Total Return' | 'Percent GDP'
export const Adjustment = {
    SplitAdjusted: 'Split Adjusted' as AdjustmentType,
    InflationAdjusted: 'Inflation Adjusted' as AdjustmentType,
    Average: 'Average' as AdjustmentType,
    AnnualizedFlow: 'Annualized Flow' as AdjustmentType,
    PerCapita: 'Per Capita' as AdjustmentType,
    AnnualPercentChange: 'Annual Percent Change' as AdjustmentType,
    PeriodPercentChange: 'Period Percent Change' as AdjustmentType,
    TotalReturn: 'Total Return' as AdjustmentType,
    PercentGDP: 'Percent GDP' as AdjustmentType
}

export const currencyConverterAdjustmentOptions = {
    SplitAdjusted: 'Split Adjusted' as AdjustmentType,
    Average: 'Average' as AdjustmentType,
    PeriodPercentChange: 'Period Percent Change' as AdjustmentType,
    AnnualPercentChange: 'Annual Percent Change' as AdjustmentType,
}

export interface Adjustments {
    SplitAdjusted: boolean
    InflationAdjusted: boolean
    Average: boolean
    AnnualizedFlow: boolean
    PerCapita: boolean
    AnnualPercentChange: boolean
    PeriodPercentChange: boolean
    TotalReturn: boolean
    PercentGDP: boolean
}

export default Adjustments
