import React from 'react'
import { generateAPIDocsRedirectURL } from '../../Services/RedirectionService'
import { paths } from '../../Models/DataModels/Common/RedirectionModel'

const Bulkashx = () => {

    const finalRedirectURL: string = generateAPIDocsRedirectURL(paths.api.bulk)

    window.location.replace(finalRedirectURL)

    return (
        <div>
            Redirecting ...
        </div>
    )
}

export default Bulkashx
