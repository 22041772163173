import { Grid } from '@mui/material'
import React from 'react'
import CapitalizationListResults from './CapitalizationListResults'
import CapitalizationSummaryResults, { CapitalizationSummaryResultsProps } from './CapitalizationSummaryResults'
import { CapitalizationResultType } from '../../Capitalization'
import { CapitalizationData, CapitalizationSummary } from '../../../../../../Models/DataModels/Common/ToolsModel'
import { LogoutReasonType } from '../../../../../../Models/DataModels/Requests/AuthRequests'
import { NotOKResponseModel } from '../../../../../../Models/DataModels/Common/NotOKResponseModel'
import { LabelValuePair } from '../../../../../../Models/DataModels/Common/LabelValuePairModel'
import { GetCapitalizationSummaryRequest } from '../../../../../../Models/DataModels/Requests/ToolsRequests'
import { BtnClearReset } from '../../../../../Common/GlobalSettings/CustomStyles'
import { UserInfo } from '../../../../../../Models/DataModels/Common/UserInfoModel'
import { abortRequest, abortKeys } from '../../../../../../Services/APIService'

export interface CapitalizationResultsProps {
    userInfo: UserInfo | null,
    setShowStartScreen: (show: boolean) => void,
    capResultType: CapitalizationResultType,
    listResult: CapitalizationData[],
    summaryResult: CapitalizationSummary[],
    summaryCategory: string,
    signOut: (logoutReason: LogoutReasonType) => void,
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void,
    doGetCapitalizationListRequest: () => void,
    setSummaryLinkClicked: (summaryLinkClicked: boolean) => void,
    selectedCurrency: LabelValuePair,
    selectedIndex: LabelValuePair,
    previousYears: number,
    currentRequest: any,
    currentSummaryRequest: GetCapitalizationSummaryRequest | null
}

const CapitalizationResults = ({
    userInfo,
    setShowStartScreen,
    capResultType,
    listResult,
    summaryResult,
    summaryCategory,
    signOut,
    setErrorResponse,
    doGetCapitalizationListRequest,
    setSummaryLinkClicked,
    selectedCurrency,
    selectedIndex,
    previousYears,
    currentRequest,
    currentSummaryRequest
}: CapitalizationResultsProps) => {

    const handleBack = () => {
        abortRequest(abortKeys.capitalizationHistoricalChart)
        setShowStartScreen(true)
    }

    const summaryResultProps: CapitalizationSummaryResultsProps = {
        resultType: capResultType,
        summaryResult: summaryResult,
        summaryCategory: summaryCategory,
        setErrorResponse: setErrorResponse,
        doGetCapitalizationListRequest: doGetCapitalizationListRequest,
        setSummaryLinkClicked: setSummaryLinkClicked,
        selectedCurrency: selectedCurrency,
        selectedIndex: selectedIndex,
        previousYears: previousYears,
        currentSummaryRequest: currentSummaryRequest
    }

    return (
        <div>
            <Grid container columns={16} sx={{ paddingBottom: 1 }} justifyContent='flex-start'>
                <Grid item md={8}>
                    <h3>Capitalization Results</h3>
                </Grid>
                <Grid item md={8}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <BtnClearReset id='capBackButtonTop' variant='outlined' onClick={handleBack}>Back</BtnClearReset>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(capResultType & CapitalizationResultType.List) === CapitalizationResultType.List ?
                <CapitalizationListResults userInfo={userInfo} listResult={listResult} signOut={signOut} setErrorResponse={setErrorResponse} currentRequest={currentRequest} />
                :
                <CapitalizationSummaryResults {...summaryResultProps} />}
            <Grid container spacing={2} columns={16} sx={{ paddingTop: 1 }}>
                <Grid item md={2}>
                    <BtnClearReset id='capBackButtonBottom' variant='outlined' onClick={handleBack}>Back</BtnClearReset>
                </Grid>
            </Grid>
        </div>
    )
}

export default CapitalizationResults
