import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
import storageReducer from './globalStorageSlice'

const finaeonReduxStore = configureStore({
  reducer: {
    storage: storageReducer
  }
})

export default finaeonReduxStore

export type AppDispatch = typeof finaeonReduxStore.dispatch
export type RootState = ReturnType<typeof finaeonReduxStore.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
