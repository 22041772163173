import moment from "moment"
import { LabelValuePair } from "./LabelValuePairModel"

export type SearchDatabaseType = 'Unknown' | 'GFDatabase2Point0' | 'GFDatabase' | 'GFDUniverse' | 'GFDIndices'
    | 'USStocks' | 'UKStocks' | 'RealEstate' | 'ConstituentMembership' | 'EventsInTime'

export const SearchDatabaseTypes = {
    Unknown: 'Unknown' as SearchDatabaseType,
    GFDatabase2Point0: 'GFDatabase2Point0' as SearchDatabaseType,
    GFDatabase: 'GFDatabase' as SearchDatabaseType,
    GFDUniverse: 'GFDUniverse' as SearchDatabaseType,
    GFDIndices: 'GFDIndices' as SearchDatabaseType,
    USStocks: 'USStocks' as SearchDatabaseType,
    UKStocks: 'UKStocks' as SearchDatabaseType,
    RealEstate: 'RealEstate' as SearchDatabaseType,
    ConstituentMembership: 'ConstituentMembership' as SearchDatabaseType,
    EventsInTime: 'EventsInTime' as SearchDatabaseType
}

export type SearchConstMemberType = 'Unknown' | 'MajorUS' | 'GFDForeign'

export const SearchConstMemberTypes = {
    Unknown: 'Unknown' as SearchConstMemberType,
    MajorUS: 'MajorUS' as SearchConstMemberType,
    GFDForeign: 'GFDForeign' as SearchConstMemberType
}

export type CurrencyGroup = 'All' | 'Download' | 'Capitalization'

export const CurrencyGroups = {
    All: 'All' as CurrencyGroup,
    Download: 'Download' as CurrencyGroup,
    Capitalization: 'Capitalization' as CurrencyGroup
}

export const getMonthsDropdownOptions = (): number[] => {
    let months: number[] = []
    for (var i = 1; i <= 12; i++) {
        months.push(i)
    }
    return months
}

export const getYearsDropdownOptions = (): number[] => {
    let years: number[] = []
    const currentYear: number = moment().year()
    const lowestYear: number = 1693
    for (var i = currentYear; i >= lowestYear; i--) {
        years.push(i)
    }
    return years
}

export const blankEntry: LabelValuePair = { label: '', value: '' }
export const allEntry: LabelValuePair = { label: 'All', value: 'all' }

export const USDCurrencyValue: string = 'USD'
export const USDCurrencyEntry: LabelValuePair = { label: 'United States Dollar', value: 'USD' }
export const SOUCurrencyEntry: LabelValuePair = { label: 'Source Currency', value: 'SOU' }
export const nativeCurrencyEntry: LabelValuePair = { label: 'Source Currency', value: 'NATIVE' }
export const INFCurrencyEntry: LabelValuePair = { label: 'INF - Inflation Adjusted', value: 'INF' }
export const ALLCountryGroupEntry: LabelValuePair = { label: 'ALL', value: 'ALL'  }
