import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import FamaFrenchSideBar from './ToolSideBarSubComponents/FamaFrenchSideBar'
import YieldCurveSideBar from './ToolSideBarSubComponents/YieldCurveSideBar'
import { useNavigate, useParams } from 'react-router-dom'
import { pathParts, paths } from '../../../Models/DataModels/Common/RedirectionModel'
import CapitalizationSideBar from './ToolSideBarSubComponents/CapitalizationSideBar'
import CurrencyConverterSideBar from './ToolSideBarSubComponents/CurrencyConverterSideBar'
import YieldCurveIcon from '../../Icons/YieldCurveIcon'
import DownUpGraph from '../../Icons/DownUpGraph'
import CurrencyConvertIcon from '../../Icons/CurrencyConvertIcon'
import CapitalizationIcon from '../../Icons/CapitalizationIcon'
import FamaFrenchIcon from '../../Icons/FamaFrenchIcon'
import CustomIndicesSideBar from './ToolSideBarSubComponents/CustomIndicesSideBar'
import CAPERatioSideBar from './ToolSideBarSubComponents/CAPERatioSideBar'
import MyIndices from '../../Icons/MyIndicesIcon'

const ToolSideBar = ({ panelStates }: any) => {

  const navigate = useNavigate()
  const { tool } = useParams()
  const [expanded, setExpanded] = React.useState<string>('')

  const handleChangeAccordian = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    event.preventDefault()
    switch (panel) {
      case pathParts.tools.famaFrench:
        navigate(paths.financialTools.base + pathParts.tools.famaFrench)
        break
      case pathParts.tools.capitalization:
        navigate(paths.financialTools.base + pathParts.tools.capitalization)
        break
      case pathParts.tools.customIndices:
        navigate(paths.financialTools.base + pathParts.tools.customIndices)
        break
      case pathParts.tools.CAPERatio:
        navigate(paths.financialTools.base + pathParts.tools.CAPERatio)
        break
      case pathParts.tools.currencyConverter:
        navigate(paths.financialTools.base + pathParts.tools.currencyConverter)
        break
      case pathParts.tools.yieldCurve:
        navigate(paths.financialTools.base + pathParts.tools.yieldCurve)
        break
      default:
        break
    }
    setExpanded(newExpanded ? panel : '')
    panelStates.setCurrentPanel(panel)
  }

  useEffect(() => {
    panelStates.setCurrentPanel(tool ?? '')
    setExpanded(tool ?? '')
  }, [])

  return (
    <>
      <Paper elevation={3} style={{ padding: 5 }}>
        <h3 style={{ textDecoration: 'underline' }}>Financial Tools</h3>
        <p>The Financial Tools section provides robust tooling to allow users to find various types of series based on well accepted financial processes.</p>
        <Accordion expanded={expanded === pathParts.tools.CAPERatio} onChange={handleChangeAccordian(pathParts.tools.CAPERatio)}>
          <AccordionSummary id='CAPERatiosAccordian' style={toolSideBarStyles.caperatiosWrapper}>
            <DownUpGraph style={toolSideBarStyles.customIcon}></DownUpGraph><Typography sx={{ fontWeight: 'bold' }}>CAPE Ratios</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CAPERatioSideBar />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === pathParts.tools.capitalization} onChange={handleChangeAccordian(pathParts.tools.capitalization)}>
          <AccordionSummary id='capitalizationAccordian' style={toolSideBarStyles.capitalizationWrapper}>
            <CapitalizationIcon style={toolSideBarStyles.customIcon}></CapitalizationIcon><Typography sx={{ fontWeight: 'bold' }}>Capitalization</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CapitalizationSideBar />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === pathParts.tools.currencyConverter} onChange={handleChangeAccordian(pathParts.tools.currencyConverter)}>
          <AccordionSummary id='currencyConverterAccordian' style={toolSideBarStyles.currencyconverterWrapper}>
            <CurrencyConvertIcon style={toolSideBarStyles.customIcon}></CurrencyConvertIcon><Typography sx={{ fontWeight: 'bold' }}>Currency Converter</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CurrencyConverterSideBar />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === pathParts.tools.famaFrench} onChange={handleChangeAccordian(pathParts.tools.famaFrench)}>
          <AccordionSummary id='famaFrenchAccordian' style={toolSideBarStyles.famafrenchWrapper}>
            <FamaFrenchIcon style={toolSideBarStyles.customIcon}></FamaFrenchIcon><Typography sx={{ fontWeight: 'bold' }}>Fama-French Stock Screener</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FamaFrenchSideBar />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === pathParts.tools.customIndices} onChange={handleChangeAccordian(pathParts.tools.customIndices)}>
          <AccordionSummary id='customIndicesAccordian' style={toolSideBarStyles.customIndicesWrapper}>
            <MyIndices style={toolSideBarStyles.customIcon} /><Typography sx={{ fontWeight: 'bold' }}>Custom Indices</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomIndicesSideBar />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === pathParts.tools.yieldCurve} onChange={handleChangeAccordian(pathParts.tools.yieldCurve)}>
          <AccordionSummary id='yieldAccordian' style={toolSideBarStyles.yieldcurveWrapper}>
            <YieldCurveIcon style={toolSideBarStyles.customIcon}></YieldCurveIcon><Typography sx={{ fontWeight: 'bold' }}>Yield Curve</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <YieldCurveSideBar />
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  )
}

const toolSideBarStyles = {

  caperatiosWrapper: {
    background: 'linear-gradient(90deg, #bcb1e0, #e5e0f8)'
  },

  capitalizationWrapper: {
    background: 'linear-gradient(90deg, #81e5ca, #c7fdee)'
  },

  currencyconverterWrapper: {
    background: 'linear-gradient(90deg, #c8ed7e, #eafcc7)'
  },

  famafrenchWrapper: {
    background: 'linear-gradient(90deg, #c69adc, #e7c3fa)'
  },

  customIndicesWrapper: {
    background: 'linear-gradient(90deg, #8dc8e7, #c2e8fc)'
  },

  yieldcurveWrapper: {
    background: 'linear-gradient(90deg, #e8d57b, #fdf1bb)'
  },

  customIcon: {
    width: '32px',
    height: '32px',
    marginRight: '10px'
  }
}

export default ToolSideBar