import React from "react"
import { ReactComponent as DownLoadIcon } from "../../Images/svg/DownLoad.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const DownLoad = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <DownLoadIcon />
  </SvgIcon>
)

export default DownLoad