import React from 'react'
import { Navigate } from 'react-router-dom'
import { pathParts, paths } from '../Models/DataModels/Common/RedirectionModel'
import { AppConfigurations } from '../Models/DataModels/Common/AppConfigurationsModel'

export const RedirectHome = () => {
  return RedirectionService(pathParts.search.searchDefault)
}

export const RedirectLogin = () => {
  return RedirectionService(paths.login)
}

export const generateAPIDocsASHXpath = (ashxPath: string): string => {
  const hostURL: string = AppConfigurations.apiDocsURL
  return `${hostURL.replace(/\/+$/, '')}${ashxPath}`
}

export const generateAPIDocsRedirectURL = (apiASHXPath: string): string => {
  const currentURL: string = window.location.href

  const redirectURL: string = generateAPIDocsASHXpath(apiASHXPath)

  const queryStartIndex: number = currentURL.indexOf('?')

  let finalRedirectURL: string = redirectURL
  if (queryStartIndex > -1) {
    const queryParameters: string = currentURL.substring(queryStartIndex)
    finalRedirectURL = `${redirectURL}${queryParameters}`
  }
  return finalRedirectURL
}

const RedirectionService = (path: string) => {
  return (
    <Navigate replace to={path} />
  )
}

export default RedirectionService
