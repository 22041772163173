import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import styled from '@emotion/styled'
import { CircularProgress, IconButton } from '@mui/material'
import { globalStyles } from '../GlobalSettings/GlobalStyles'
import { CSSProperties } from 'react'

export enum AlertButtonType {
  OK,
  Cancel
}

interface AlertButton {
  type: AlertButtonType,
  display?: string,
  onClick?: () => any,
  isPrimary?: boolean,
  disable?: boolean,
  style?: any
}

export interface AlertModalProps {
  showModal: boolean,
  setShowModal: any,
  showProgress?: boolean,
  progressTitle?: string,
  onAlertClose?: () => boolean,
  AlertTitle?: string | JSX.Element,
  AlertContent?: string | JSX.Element,
  AlertButtons?: AlertButton[],
  Size?: 'xl' | 'lg' | 'sm' | undefined,
  showCloseButton?: boolean,
  centered?: boolean,
  fullScreen?: boolean,
  additionalClassnames?: string
}

const AlertModal = ({
  showModal,
  setShowModal,
  showProgress,
  progressTitle,
  onAlertClose,
  AlertTitle,
  AlertContent,
  AlertButtons,
  Size,
  showCloseButton,
  centered,
  fullScreen,
  additionalClassnames
}: AlertModalProps) => {

  const handleClose = () => {
    let close = true
    if (onAlertClose) close = onAlertClose()
    if (close) setShowModal(false)
  }

  const renderHeader = () => {
    return (
      <Modal.Header closeButton={showCloseButton ?? true}>
        <Modal.Title>{AlertTitle || ''}</Modal.Title>
      </Modal.Header>
    )
  }

  const readOnlyProp: CSSProperties = (showProgress ?? false) ? { pointerEvents: 'none'} : {}

  const renderButton = (button: AlertButton, index: number) => {
    if (button) {
      return (
        <CustomBtnPrimary
          style={{ ...readOnlyProp }}
          key={index}
          variant={button.isPrimary ? 'primary' : 'secondary'}
          onClick={button.onClick || handleClose}
          {...(button.disable ?? false) ? {disabled: true} : {}}
        >
          {button.display || AlertButtonType[button.type]}
        </CustomBtnPrimary>
      )
    }
    return (<></>)
  }

  const renderButtons = () => {
    return AlertButtons?.map((button, index) => renderButton(button, index))
  }

  const renderFooter = () => {
    const title = progressTitle || 'Request in progress'
    return (
      <Modal.Footer>
          {renderButtons()}
          {<IconButton title={title}
              aria-label={title} component='label' 
              sx={{ 
                  p: '10px',
                  color: '#1976d2',
                  visibility: showProgress ? 'visible' : 'hidden'
                  }}
              >
              <CircularProgress style={globalStyles.circularProgress} title={title} aria-label={title} />
          </IconButton>}
      </Modal.Footer>
    )
  }

  const renderBody = () => {
    return (
      <Modal.Body>
          {AlertContent || ''}
      </Modal.Body>
    )
  }
  
  return (
    <>
      <Modal backdrop='static' show={showModal} size={Size} onHide={handleClose} centered={centered || false} fullscreen={fullScreen || 'false'} className={additionalClassnames && additionalClassnames.length > 0 ? additionalClassnames : ''}> 
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </Modal>
    </>
  )
}

export const CustomBtnPrimary = styled(Button)(({ theme }) => ({

  "&.btn-primary": {
    color: "#FFFFFF",
    backgroundColor: "#007ea8",
    border: "1px solid #007ea8",
    textTransform: "uppercase",
    "&:hover": {
     color: "#FFFFFF",
     backgroundColor: "#0d7091",
     border: "1px solid #0d7091",
     boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  "&.btn-secondary": {
    color: "#007ea8",
    backgroundColor: "transparent",
    border: "1px solid #007ea8",
   textTransform: "uppercase",
    "&:hover": {
      color: "#b9394a",
      backgroundColor: "#fce3e7",
      border: "1px solid #b9394a",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
     },
   },
}));

export default AlertModal