import React, { useEffect, useState } from 'react'
import { getAuthTokenFromCookie, getRefreshTokenFromCookie } from '../../../../Services/CookieAccessService'
import { refreshTokenAPICall } from '../../../../Services/AuthenticationService'
import { NotOKResponseModel } from '../../../../Models/DataModels/Common/NotOKResponseModel'
import { RefreshRequest, LogoutReason, LogoutReasonType } from '../../../../Models/DataModels/Requests/AuthRequests'
import { RefreshResponse } from '../../../../Models/DataModels/Responses/AuthResponses'
import { ErrorHandlerProps, ErrorHandler } from '../../Utility/ErrorHandler'
import { LOG } from '../../../../Services/Log'

export interface SessionRefreshHandlerProps {
    processRefreshTokenResponse: (response: RefreshResponse) => void,
    signOut: (logoutReason: LogoutReasonType) => void
}

const SessionRefreshHandler = ({
    processRefreshTokenResponse,
    signOut
}: SessionRefreshHandlerProps) => {

    var loadRefreshTimeoutID: NodeJS.Timeout | undefined = undefined
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()

    const refreshToken = () => {
        LOG('refreshToken')
        const authToken: any = getAuthTokenFromCookie()
        const refreshToken: any = getRefreshTokenFromCookie()
        const refreshRequestObject: RefreshRequest = {
            authenticationToken: authToken,
            refreshToken: refreshToken
        }

        refreshTokenAPICall(refreshRequestObject).then(
            (result: RefreshResponse) => {
                //Refresh Successful, Update Tokens and store in Cookie
                processRefreshTokenResponse(result)
            },
            //Reject Promise
            (notOKResponseModel: NotOKResponseModel) => {
                setErrorResponse(notOKResponseModel)
                signOut(LogoutReason.Bumped)
            }
        )
    }

    const loadRefreshCallBack = () => {
        const authToken = getAuthTokenFromCookie()
        LOG('loadRefreshCallBack authToken: ' + authToken)
        if (authToken) {
            refreshToken()
        }
        loadRefreshTimeoutID = undefined
    }

    // Always refresh token and start session timeout for load event as long existing token is still valid/present.
    // This currently happens when the page is refreshed or user clicks on the top left GFD image that reloads the page.
    const loadRefreshEventHandler = <K extends keyof WindowEventMap>(et: K, e: WindowEventMap[K]) => {
        if (loadRefreshTimeoutID) {
            LOG('loadRefreshEventHandler Clearing: ' + loadRefreshTimeoutID)
            clearTimeout(loadRefreshTimeoutID)
        }
        loadRefreshTimeoutID = setTimeout(loadRefreshCallBack, 3000)
        LOG('loadRefreshEventHandler Set: ' + loadRefreshTimeoutID)
    }
    
    const loadEvent = 'load' as (keyof WindowEventMap)

    const loadEventHandler = <K extends keyof WindowEventMap>(e: WindowEventMap[K]) => {
        LOG(loadEvent)
        loadRefreshEventHandler(loadEvent, e)
    }

    const addEvents = () => {
        LOG('SessionRefreshHandler.addEvents')
        window.addEventListener(loadEvent, loadEventHandler)
    }

    const removeEvents = () => {
        LOG('SessionRefreshHandler.removeEvents')
        window.addEventListener(loadEvent, loadEventHandler)
    }

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    useEffect(() => {
        addEvents()
        return (() => {
            removeEvents()
            LOG('UseEffect [] Return Clearing: ' + loadRefreshTimeoutID)
            clearTimeout(loadRefreshTimeoutID)
        })
    }, [])

    return (
        <>
            <ErrorHandler {...errorHandlerProps} />
        </>
    )
}

export default SessionRefreshHandler
