import React from 'react'

export const MaxChar = {
    FirstName: 32,
    LastName: 32,
    Position: 32,
    FullAddress: 256,
    Phone: 24,
    Email: 64,
    Password: 256
}

const phoneNumberFormatRegex: RegExp = new RegExp('[0-9() .+-]{7,}((x|ext)[0-9 .-]+)?')
const inValidEmailFormatRegex: RegExp = new RegExp('^[a-zA-Z0-9._%+-]+@[0-9]+(\\.[0-9]+){3}')
const validEmailFormatRegex: RegExp = new RegExp('[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?')

export const isNullOrUndefined = (value: any): boolean => {
    return value === null || value === undefined
}

export const isNullOrUndefinedOrEmpty = (value: any): boolean => {
    return isNullOrUndefined(value) || value === ''
}

export const isNullOrUndefinedOrWhiteSpace = (value: string): boolean => {
    return isNullOrUndefined(value) || value.trim() === ''
}

export const validateFirstNameChar = (firstName: string): boolean => {
    return isNullOrUndefinedOrEmpty(firstName) || firstName.length <= MaxChar.FirstName
}

export const validateLastNameChar = (lastName: string): boolean => {
    return isNullOrUndefinedOrEmpty(lastName) || lastName.length <= MaxChar.LastName
}

export const validatePositionChar = (position: string): boolean => {
    return isNullOrUndefinedOrEmpty(position) || position.length <= MaxChar.Position
}

export const validateFullAddressChar = (fullAddress: string): boolean => {
    return isNullOrUndefinedOrEmpty(fullAddress) || fullAddress.length <= MaxChar.FullAddress
}

export const validateEmailChar = (email: string): boolean => {
    return isNullOrUndefinedOrEmpty(email) || email.length <= MaxChar.Email
}

export const validatePhoneChar = (phone: string): boolean => {
    return isNullOrUndefinedOrEmpty(phone) || phone.length <= MaxChar.Phone
}

export const validatePhoneNumberFormat = (phone: string): boolean => {
    return !isNullOrUndefinedOrEmpty(phone) && !phoneNumberFormatRegex.test(phone) ? false : true
}

export const validateEmailAddressFormat = (email: string): boolean => {
    if (isNullOrUndefinedOrWhiteSpace(email)) {
        return false
    }
    if (inValidEmailFormatRegex.test(email)) {
        return false
    }
    if (!validEmailFormatRegex.test(email)) {
        return false
    }
    return true
}

const ValidationService = () => {
  return (
    <div>
    </div>
  )
}

export default ValidationService
