import React from 'react'

export const chartDarkColorCodes: string[] = [
    '#000000',
    '#b8143d',
    '#006400',
    '#018484',
    '#2b50d4',
    '#7b1a99',
    '#a50b5e'
]

export const chartLightColorCodes: string[] = [
    '#a1a1a1',
    '#fad1db',
    '#d9f2dc',
    '#cff4fc',
    '#d5dcf6',
    '#efd3f8',
    '#fbd0f9'
]

// Using Kelly's colors of Maximum Contrast
// Original
export const originalKellyColors = ['#F2F3F4', '#222222', '#F3C300', '#875692', '#F38400', '#A1CAF1', '#BE0032', '#C2B280', '#848482', '#008856', '#E68FAC', '#0067A5', '#F99379', '#604E97', '#F6A600', '#B3446C', '#DCD300', '#882D17', '#8DB600', '#654522', '#E25822', '#2B3D26']

// Kelly's color updated to 75% and shifted first 2 color to the end.
export const lightKellyColors = ['#ffe680', '#caafd0', '#ffc680', '#90c1ee', '#ff80a2', '#d6caa9', '#c0c0bf', '#80ffd0', '#e896b2', '#80ceff', '#f99c85', '#b5abd4', '#ffd480', '#dca3b8', '#fffb80', '#eda492', '#e1ff80', '#dfc09f', '#f0aa8f', '#b7ceb1', '#babfc4', '#bfbfbf']

export const kellyColorsSkipLight = ['#222222', '#F3C300', '#875692', '#F38400', '#A1CAF1', '#BE0032', '#C2B280', '#848482', '#008856', '#E68FAC', '#0067A5', '#F99379', '#604E97', '#F6A600', '#B3446C', '#DCD300', '#882D17', '#8DB600', '#654522', '#E25822', '#2B3D26']

const getNextColor = (counter: number, colorCodes: string[], limit?: number): string => {
    let divider: number = colorCodes.length
    if (limit && limit < divider) {
        divider = limit
    }
    if (counter < divider) {
        return colorCodes[counter]
    } else {
        const modValue: number = counter % divider
        return colorCodes[modValue]
    }
}

export const getNextChartDarkColor = (counter: number, limit?: number): string => {
    return getNextColor(counter, chartDarkColorCodes, limit)
}

export const getNextChartLightColor = (counter: number, limit?: number): string => {
    return getNextColor(counter, chartLightColorCodes, limit)
}

export const getNextAreaColor = (counter: number, limit?: number): string => {
    return getNextColor(counter, lightKellyColors, limit)
}

export const getNextKellyColor = (counter: number, limit?: number): string => {
    return getNextColor(counter, originalKellyColors, limit)
}

export const getNextKellyColorSkipLight = (counter: number, limit?: number): string => {
    return getNextColor(counter, kellyColorsSkipLight, limit)
}
