import { AppConfigurations } from '../Models/DataModels/Common/AppConfigurationsModel'
import { AutoCompleteRequest, ConstituentMembershipRequest, EventsInTimeRequest, GFDatabaseRequest, GFDIndicesRequest, GFDUniverseRequest, QuikSearchRequest, RealEstateDatabaseRequest, UKStocksRequest, USStocksRequest } from '../Models/DataModels/Requests/SearchRequests'
import getAPICallResult, { abortKeys, APIRequest, HttpRequest } from './APIService'

//MISC/Utility
export const autoComplete = async (request: AutoCompleteRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Search/AutoComplete',
        method: HttpRequest.Post,
        requestObject: {
            ...request,
            limitOption: '20',
        }
    }
    return getAPICallResult(apiRequest)
}

export const autoCompleteLabelValues = async (request: AutoCompleteRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Search/AutoCompleteLabelValues',
        method: HttpRequest.Post,
        requestObject: {
            ...request,
            limitOption: '20',
        }
    }
    return getAPICallResult(apiRequest)
}

// SEARCHES and EXPORTS
const searchController = 'search'
const exportController = 'export'

const applicationDetails = {
    applicationType: AppConfigurations.applicationType,
    applicationNameType: AppConfigurations.applicationNameType
}

const getController = (exportResults: boolean): string => {
    return exportResults ? exportController : searchController
}

export const searchQuik = async (request: QuikSearchRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/Request`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const searchGFDatabase2Point0 = async (request: GFDatabaseRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/GFDatabase2Point0`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.search)
}

export const searchGFDatabase = async (request: GFDatabaseRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/GFDatabase`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.search)
}

export const searchGFDIndices = async (request: GFDIndicesRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/GFDIndices`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.search)
}

export const searchRealEstateDatabase = async (request: RealEstateDatabaseRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/RealEstate`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.search)
}

export const searchUSStocks = async (request: USStocksRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/USStocks`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.search)
}

export const searchUKStocks = async (request: UKStocksRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/UKStocks`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.search)
}

export const searchUniverse = async (request: GFDUniverseRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/GFDUniverse`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.search)
}

export const searchConstituentMembership = async (request: ConstituentMembershipRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/ConstituentMembership`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.search)
}

export const searchEventsInTime = async (request: EventsInTimeRequest, exportResults: boolean = false) => {
    const apiRequest: APIRequest = {
        relativePath: `/${getController(exportResults)}/EventsInTime`,
        method: HttpRequest.Post,
        requestObject: {
            ...request,
            ...applicationDetails
        }
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.search)
}
