import { abortReasonKeys } from "../../../Services/APIService"
import { NotOKResponse } from "../Responses/NotOKResponse"

export interface NotOKResponseModel {
    isBackgroundCall: boolean,
    statusCode: number | string,
    notOKResponse?: NotOKResponse,
    anyOtherError?: any,
}

export const isNotOKResponseModel = (obj: any): obj is NotOKResponseModel  => {
    return typeof obj.isBackgroundCall === "boolean" && (typeof obj.statusCode === "number" || typeof obj.statusCode === "string")
}

export const InternalErrorMessage = 'An internal error occurred. Please try again later.'
export const ServerErrorMessage = 'We are experiencing some issues. Please try again later.'
export const EmptyStatusCode = "None"

export const getStatusCodeFromError = (obj: any): number | string => {
    
    // FireFox browser returns a cancel string set in abort controller when the request is cancelled.
    if (obj === abortReasonKeys.cancel) {
        return 499
    }

    switch(obj?.name) {
        case 'AbortError':
            return 499
        default:
            return EmptyStatusCode
    }
}
