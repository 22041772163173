import { useEffect, useMemo, useState} from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import Papa from 'papaparse'
import { CSSProperties } from '@mui/material/styles/createTypography'
import { Box, Button, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import DefaultMaterialTheme from '../GlobalSettings/DefaultMaterialTheme'
import MaterialTable, { MTableBody } from '@material-table/core'
import { addTickersToWorkbookByCUSIP, addTickersToWorkbookByName } from '../../../Services/AutoTracService'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import AddTicker from '../../Icons/AddTickerIcon'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { MessageResponse } from '../../../Models/DataModels/Responses/NotOKResponse'
import { errorRedColor } from '../GlobalSettings/GlobalStyles'
import { SortOrderStringType, getComparator, sort } from '../../../Models/DataModels/Common/SortModel'
import EnhancedTableHead from './EnhancedTableHead'

export interface TickerDropZoneProps {
  clearAlert: () => void,
  displayMessages: (componentMessages: MessageResponse[]) => void,
  displayResponse: (model: NotOKResponseModel) => void,
  CurrentTickerCount: number,
  SelectedWorkbook: string | undefined,
  RefreshWorkbookDetails: () => void
}

const CUISPRegEx = RegExp('(^|\s)[0-9]{3}[a-zA-Z0-9]{6}(\s|$)')

const TickerDropZone = ({
  clearAlert,
  displayMessages,
  displayResponse,
  CurrentTickerCount,
  SelectedWorkbook,
  RefreshWorkbookDetails
}: TickerDropZoneProps) => {
  const [itemType, setItemType] = useState<string>()
  const [items, setItems] = useState<any[]>([])
  const [error, setError] = useState<string>()
  const [chosenFile, setChosenFile] = useState<File | null>()

  const CUSIP = 'CUSIP'
  const Symbol = 'Symbol'

  const Errors = {
    NoTickers: 'Please upload CSV file with list of tickers',
    NoWorkbook: 'Please select a Workbook'
  }

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': ['.csv']
    }
  })

  useEffect(() => {
    const rejectionError = fileRejections.map(({ file, errors }: FileRejection) => 
      errors.map(e => e.message).join(", ")
    ).join("; ")
    setError(rejectionError)
  }, [fileRejections])

  useEffect(() => {
    if (acceptedFiles?.length) setChosenFile(acceptedFiles[0])
  }, [acceptedFiles])

  useEffect(() => {
    if (chosenFile === null) return
    clearAlert()
    parseFileItems()
  }, [chosenFile])

  const executeAddTickersToWorkbookByCUSIP = (CUSIPs: string[]) => {
    if (CUSIPs.length === 0
      || !SelectedWorkbook) return
    addTickersToWorkbookByCUSIP({ workbookName: SelectedWorkbook, CUSIPs: CUSIPs })
      .then((result: any) => {
        clearFileSelection()
        result?.messages?.length ?
        displayMessages(result.messages) 
        :
        console.log('added tickers by CUSIP to workbook!')
        if (RefreshWorkbookDetails) RefreshWorkbookDetails()
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        displayResponse(notOKResponseModel)
      })
  }

  const executeAddTickersToWorkbookByTickerNames = (TickerNames: string[]) => {
    if (TickerNames.length === 0
      || !SelectedWorkbook) return
    addTickersToWorkbookByName({ workbookName: SelectedWorkbook, tickerNames: TickerNames })
      .then((result: any) => {
        clearFileSelection()
        result?.messages?.length ?
        displayMessages(result.messages) 
        :
        console.log('added ticker names to workbook!')
        if (RefreshWorkbookDetails) RefreshWorkbookDetails()
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        displayResponse(notOKResponseModel)
      })
  }

  const processAddToWorkbook = () => {
    if (!items || !items?.length) {
      setError(Errors.NoTickers)
      return
    }

    if (!SelectedWorkbook) {
      setError(Errors.NoWorkbook)
      return
    }

    setError('')

    const tickers = Object.values(items).map((d: any) => d?.id)

    itemType === CUSIP ?
      executeAddTickersToWorkbookByCUSIP(tickers)
      : executeAddTickersToWorkbookByTickerNames(tickers)
  }

  const clearFileSelection = () => {
    setChosenFile(null)
    setItemType('')
    setItems([])
    setError('')
  }

  const onParseComplete = (csv: Papa.ParseResult<unknown>) => {
    setItemType('')
    setItems([])
    setError('')

    if (!csv) {
      setItems([])
      return
    }

    if (csv.data.length + CurrentTickerCount > AppConfigurations.maximumTickers) {
      setError(`Ticker count exceeds maximum limit of ${AppConfigurations.maximumTickers}. [Tickers in workbook: ${CurrentTickerCount}; Tickers in file: ${csv.data.length}]`)
      return
    }

    let type = Symbol
    const content = csv.data.reduce((tickers: any[], item, index) => {
      const row = Object.values(item as object)
      if (index === 0) {
        if (row[0] === 'Ticker') return tickers
        
        if (CUISPRegEx.test(row[0])) {
          type = CUSIP
        }
      }
      const entry = row[0].trim()
      if (entry) tickers.push({id: entry})
      return tickers
    }, [])

    setItemType(type)
    setItems(content)
  }

  const parseFileItems = () => {
    if (!chosenFile) return
    
    Papa.parse(chosenFile, {
      skipEmptyLines: true,
      complete: onParseComplete
    })
  }
  
  const renderTitle = () => {
    if (!chosenFile) return (<>No file chosen</>)

    const text = `${chosenFile.name} - ${chosenFile.size} bytes`
    return (<Tooltip title={text}>
      <label style={Styles.FileLabel as CSSProperties}>{text}</label>
    </Tooltip>)
  }

  const renderAddTickers = () => {
    if (!chosenFile) return (<></>)

    return (
      <>
        <Button aria-label='Add Tickers to Workbook' variant='outlined' onClick={processAddToWorkbook}>
          <AddTicker style={{fontSize: '1.8em', marginRight: '10px'}}></AddTicker>  Add Tickers to Workbook
        </Button>
      </>
    )
  }

  const renderTableBody = (props: any) => {
    if (!chosenFile) return (<></>)
    return (<>
      <MTableBody {...props} style={Styles.Table} />
    </>)
  }

  const renderColumns = () => {
    if (!chosenFile) return []
    return [
      { title: `${itemType} (Count:${items?.length || 0})`, field: 'id', sorting: true }
    ]
  }

  const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
  const [sortColumn, setSortColumn] = useState<string>('')
  const sortedData: any[] = useMemo(
      () =>
      sort(items, getComparator(sortOrder, sortColumn)),
      [sortOrder, sortColumn, items],
  )

  const renderItems = () => {
    return (
      chosenFile ?
      <Box>
        <ThemeProvider theme={DefaultMaterialTheme}>
          <MaterialTable
            style={Styles.Table as CSSProperties}
            columns={renderColumns()}
            data={sortedData}
            title={renderTitle()}
            options={{
              draggable: false,
              search: false,
              paging: false,
              padding: 'dense',
              maxBodyHeight: '200px',
              emptyRowsWhenPaging: false,
              headerStyle: {
                fontWeight: 'bold'
              }
            }}
            components={{
              Body: props => renderTableBody(props),
              Header:
                props => (
                  <EnhancedTableHead { ...{ sortData: {sortColumn, sortOrder, setSortColumn, setSortOrder }, columns: renderColumns() } } />
                )
            }}
          />
        </ThemeProvider>
        {renderAddTickers()}
      </Box>
      : <></>
    )
  }

  const renderError = () => {
    if (error) return (<p style={Styles.Error as CSSProperties}>{error}</p>)
    return (<></>)
  }

  return (
    <section className="container" style={Styles.Container as CSSProperties}>
      <div {...getRootProps({ className: 'dropzone' })} style={Styles.Dropzone as CSSProperties}>
        <input {...getInputProps()} />
        Drag 'n' drop CSV file here, or click to select file
        <em>{`Maximum of ${AppConfigurations.maximumTickers} series allowed per workbook`}</em>
      </div>
      <aside>
        {renderItems()}
        {renderError()}
      </aside>
    </section>
  )
}

const Styles = {
  Dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#362d2d',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  Container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'sans-serif'
  },
  Error: {
    flex:2,
    color: errorRedColor,
    fontStyle: 'italic',
    marginTop: '8px',
    marginBottom: '8px'
  },
  Footer: {
    position: 'sticky',
    bottom: 0
  },
  FooterContent: {
    textAlign: 'left',
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    fontWeight: 'bold'
  },
  FileLabel: {
    wordWrap: 'break-word',
    display: 'default',
    fontWeight: 'bold'
  },
  Table: {
    marginTop: '8px',
    marginBottom: '8px',
    "div[class~='MuiToolbar-gutters']": {
      paddingLeft: '0px!important'
    }
  }
}
export default TickerDropZone
