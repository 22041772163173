export type DataFieldType = 'Open' | 'High' | 'Low' | 'Close' | 'Volume' | 'Open Interest' | 'Close Only'
export const DataField = {
    Open: 'Open' as DataFieldType,
    High: 'High' as DataFieldType,
    Low: 'Low' as DataFieldType,
    Close: 'Close' as DataFieldType,
    Volume: 'Volume' as DataFieldType,
    OpenInterest: 'Open Interest' as DataFieldType,
    CloseOnly: 'Close Only' as DataFieldType
}

export interface DataFields {
    Open: boolean
    High: boolean
    Low: boolean
    Close: boolean
    Volume: boolean
    OpenInterest: boolean
    CloseOnly: boolean
}

export default DataField
