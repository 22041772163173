import { Box, CircularProgress, Tabs } from '@mui/material'
import React, { useState, useEffect, CSSProperties } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { SignalRMessageRequest, SignalRSignOutRequest } from '../../Models/DataModels/SignalR/NotificationHubRequests'
import { NotificationHubUser } from '../../Models/DataModels/SignalR/NotificationHubUser'
import { getIsManager } from '../../Services/AccountService'
import { getAuthTokenFromCookie } from '../../Services/CookieAccessService'
import { RedirectLogin } from '../../Services/RedirectionService'
import ImpersonateUser, { ImpersonateUserProps } from './AdminSubComponents/ImpersonateUser'
import OnlineUsers, { OnlineUserProps } from './AdminSubComponents/OnlineUsers'
import { LogoutReasonType } from '../../Models/DataModels/Requests/AuthRequests'
import { LoginResponse, RefreshResponse } from '../../Models/DataModels/Responses/AuthResponses'
import { pathParts } from '../../Models/DataModels/Common/RedirectionModel'
import AccessCacheQueue from './AdminSubComponents/AccessCacheQueue'
import { TabPanel } from '../Common/Utility/TabPanel'
import SessionTimer, { SessionTimerProps } from '../Common/Modals/SessionTimer/SessionTimer'
import { CustomTab } from '../Common/GlobalSettings/CustomStyles'
import { globalStyles } from '../Common/GlobalSettings/GlobalStyles'

const adminTabs: any = {
  onlineUsers: 0,
  impersonateUser: 1,
  accessCacheQueue: 2
}

export interface AdminProps {
  activeUsers: NotificationHubUser[],
  setSignalRMessageRequest: (request: SignalRMessageRequest | null) => void,
  setSignalRSignOutRequest: (request: SignalRSignOutRequest | null) => void,
  authToken?: string | null,
  login: (loginResponse: LoginResponse) => void,
  signOut: (logoutReason: LogoutReasonType) => void,
  processRefreshTokenResponse: (response: RefreshResponse) => void
}

const Admin = ({
  activeUsers,
  setSignalRMessageRequest,
  setSignalRSignOutRequest,
  authToken,
  login,
  signOut,
  processRefreshTokenResponse
}: AdminProps) => {
  const [isManager, setIsManager] = useState<boolean>()
  const [tabIndex, setTabIndex] = useState<number>()
  const navigate = useNavigate()
  const { adminSection } = useParams()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
    switch (newValue) {
      case adminTabs.onlineUsers:
        navigate(pathParts.admin.onlineUsers)
        break
      case adminTabs.impersonateUser:
        navigate(pathParts.admin.impersonateUser)
        break
      case adminTabs.accessCacheQueue:
        navigate(pathParts.admin.accessCacheQueue)
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (authToken === null) {
      setIsManager(false)
      return
    }

    getIsManager()
      .then((result: any) => {
        setIsManager(result === true)
      },
        //Reject promise
        () => {
          console.log('Manager check failed!')
          setIsManager(false)
        })
  }, [authToken])

  useEffect(() => {
    if (adminSection === undefined) {
      setTabIndex(0)
      navigate(pathParts.admin.onlineUsers)
    } else {
      setTabIndex(adminTabs[adminSection])
    }
  }, [])

  const auth: any = getAuthTokenFromCookie()
  if (!auth) {
    return RedirectLogin()
  }

  if (isManager === undefined) {
    return <>
      <CircularProgress title='Loading...' aria-label='Loading...' />
    </>
  }

  if (isManager === false) {
    return <>
      <Navigate replace to='/' />
    </>
  }

  const onlineUserProps: OnlineUserProps = {
    activeUsers: activeUsers,
    setSignalRMessageRequest: setSignalRMessageRequest,
    setSignalRSignOutRequest: setSignalRSignOutRequest,
  }

  const impersonateUserProps: ImpersonateUserProps = {
    login,
    signOut
  }

  const sessionTimerProps: SessionTimerProps = {
    processRefreshTokenResponse,
    signOut: signOut
  }

  return (
    <div style={ { ...globalStyles.mainPageBody } as CSSProperties }>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleChange} aria-label='basic tabs example' sx={{ "& .MuiTabs-indicator": { backgroundColor: "#007ea8" } }}>
          <CustomTab label='Online Users' />
          <CustomTab label='Impersonate User' />
          <CustomTab label='Access Cache Queue' />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex ?? 0} index={adminTabs.onlineUsers}>
        <OnlineUsers {...onlineUserProps} />
      </TabPanel>
      <TabPanel value={tabIndex ?? 0} index={adminTabs.impersonateUser}>
        <ImpersonateUser {...impersonateUserProps} />
      </TabPanel>
      <TabPanel value={tabIndex ?? 0} index={adminTabs.accessCacheQueue}>
        <AccessCacheQueue {...impersonateUserProps} />
      </TabPanel>
      <SessionTimer {...sessionTimerProps} />
    </Box>
    </div>
  )
}

export default Admin
