import React, { useEffect, useState } from 'react'
import { NotOKResponseModel } from '../../../../Models/DataModels/Common/NotOKResponseModel'
import { ErrorHandlerProps, ErrorHandler } from '../../Utility/ErrorHandler'
import { LogoutReason, LogoutReasonType, RefreshRequest } from '../../../../Models/DataModels/Requests/AuthRequests'
import { RefreshResponse } from '../../../../Models/DataModels/Responses/AuthResponses'
import { getAuthExpirationFromCookie, getAuthTokenFromCookie, getRefreshTokenFromCookie } from '../../../../Services/CookieAccessService'
import { AppConfigurations } from '../../../../Models/DataModels/Common/AppConfigurationsModel'
import { refreshTokenAPICall } from '../../../../Services/AuthenticationService'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../Models/DataModels/Common/RedirectionModel'
import { LOG } from '../../../../Services/Log'
import SessionInactivityWarningCountdownModal from './SessionInactivityWarningCountdownModal'

const MilliSecondsInSecond: number = 1000

export interface SessionTimerProps {
    processRefreshTokenResponse: (response: RefreshResponse) => void,
    signOut: (logoutReason: LogoutReasonType) => void
}

const SessionTimer = ({
    processRefreshTokenResponse,
    signOut
}: SessionTimerProps) => {

    const navigate = useNavigate()

    var refreshTimeoutID: NodeJS.Timeout | undefined = undefined

    const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    const redirectLogin = () => {
        navigate(paths.login)
    }

    const clearRefreshTimeout = () => {
        LOG('clearRefreshTimeout Warning Timeout ID: ' + refreshTimeoutID)
        if (refreshTimeoutID) {
            LOG('clearRefreshTimeout Warning Timeout ID about to clear: ' + refreshTimeoutID)
            clearTimeout(refreshTimeoutID)
            refreshTimeoutID = undefined
        }
    }

    const isTokenExpired = (): boolean => {
        const tokenExpirationDate: Date | null = getAuthExpirationFromCookie()
        LOG('isTokenExpired() - Auth Token Expiration: ' + tokenExpirationDate)
        if (!tokenExpirationDate) {
            LOG('isTokenExpired() - Already Expired ')
            return true
        }
        const currentDateTime: Date = new Date()
        if (currentDateTime > tokenExpirationDate) {
            return true
        }
        return false
    }

    const isTokenNearExpiration = (): boolean => {
        const tokenExpirationDate: Date | null = getAuthExpirationFromCookie()
        LOG('isTokenNearExpiration() - Auth Token Expiration: ' + tokenExpirationDate)
        if (!tokenExpirationDate) {
            LOG('isTokenNearExpiration() - Already Expired ')
            return true
        }

        const currentDateTime: Date = new Date()
        const dateDifferenceMilliSeconds: number = tokenExpirationDate.getTime() - currentDateTime.getTime()
        LOG(`dateDifferenceMilliSeconds: ${dateDifferenceMilliSeconds.toString()}`)

        const secondsDifference: number = Math.round(dateDifferenceMilliSeconds / 1000)
        LOG(`secondsDifference: ${secondsDifference.toString()}`)
        if (secondsDifference < AppConfigurations.TokenRefreshThresholdInSeconds) {
            LOG('isTokenNearExpiration() - below threshold: ' + secondsDifference + ' < Threshold: ' + AppConfigurations.TokenRefreshThresholdInSeconds)
            return true
        }

        return false
    }

    const refreshToken = () => {
        const authToken: any = getAuthTokenFromCookie()
        const refreshToken: any = getRefreshTokenFromCookie()
        const refreshRequestObject: RefreshRequest = {
            authenticationToken: authToken,
            refreshToken: refreshToken
        }

        refreshTokenAPICall(refreshRequestObject).then(
            (result: RefreshResponse) => {
                //Refresh Successful, Update Tokens and store in Cookie
                processRefreshTokenResponse(result)
            },
            //Reject Promise
            (notOKResponseModel: NotOKResponseModel) => {
                setErrorResponse(notOKResponseModel)
                signOut(LogoutReason.Kicked)
                redirectLogin()
            }
        )
    }

    const inactivityCheck = () => {
        const authToken = getAuthTokenFromCookie()
        LOG(`inactivityCheck authToken: ${authToken && authToken?.length > 5 ? authToken?.substring(0, 5) : authToken}`)
        if (!authToken || isTokenExpired()) {
            LOG('inactivityCheck Token Expired!')
            signOut(LogoutReason.Timeout)
            redirectLogin()
            return
        }

        // display warning modal
        const tokenExpirationDate: Date | null = getAuthExpirationFromCookie()
        LOG('inactivityCheck.isTokenExpired() - Auth Token Expiration: ' + tokenExpirationDate)
        if (!tokenExpirationDate) {
            LOG('inactivityCheck.isTokenExpired() - Already Expired ')
            signOut(LogoutReason.Timeout)
            redirectLogin()
            return
        }
        const currentDateTime: Date = new Date()
        const dateDifferenceMilliSeconds: number = tokenExpirationDate.getTime() - currentDateTime.getTime()
        const secondsDifference: number = Math.round(dateDifferenceMilliSeconds / MilliSecondsInSecond)
        const secondsThreshold: number = AppConfigurations.WarningCountDownInSeconds
        if (secondsDifference <= secondsThreshold && !showWarningModal) {
            setShowWarningModal(true)
        } else {
            setShowWarningModal(false)
        }
    }

    const userInteractedCallBack = () => {
        setShowWarningModal(false)
        const authToken = getAuthTokenFromCookie()
        LOG(`userInteractedCallBack authToken: ${authToken && authToken?.length > 5 ? authToken?.substring(0, 5) : authToken}`)
        if (authToken) {
            // Expired token may not get cleared from cookie right away so we still need to check the expiration date.
            if (isTokenExpired()) {
                LOG('Token Expired!')
                signOut(LogoutReason.Timeout)
                redirectLogin()
            } else if (isTokenNearExpiration()) {
                LOG('attemping to refresh token...')
                refreshToken()
            }
            // Note: else case - let the user continue
        } else {
            // should not happen
            signOut(LogoutReason.Timeout)
            redirectLogin()
        }
        LOG(refreshTimeoutID + ' excuted by click')
        refreshTimeoutID = undefined
    }

    const userInteractionEventHandler = () => {
        clearRefreshTimeout()
        refreshTimeoutID = setTimeout(userInteractedCallBack, 500)
        LOG('userInteractionEventHandler Set: ' + refreshTimeoutID)
    }

    const clickEvent = 'click' as (keyof WindowEventMap)
    const keypressEvent = 'keypress' as (keyof WindowEventMap)
    const scrollEvent = 'scroll' as (keyof WindowEventMap)
    // const mousemoveEvent = 'mousemove' as (keyof WindowEventMap)

    const userEventHandler = <K extends keyof WindowEventMap>(e: WindowEventMap[K]) => {
        LOG('userEventHandler.event')
        userInteractionEventHandler()
    }

    const addEvents = () => {
        LOG('SessionTimer.addEvents')
        window.addEventListener(clickEvent, userEventHandler)
        window.addEventListener(keypressEvent, userEventHandler)
        window.addEventListener(scrollEvent, userEventHandler)
        // window.addEventListener(mousemoveEvent, userEventHandler)
    }

    const removeEvents = () => {
        LOG('SessionTimer.removeEvents')
        window.removeEventListener(clickEvent, userEventHandler)
        window.removeEventListener(keypressEvent, userEventHandler)
        window.removeEventListener(scrollEvent, userEventHandler)
        // window.removeEventListener(mousemoveEvent, userEventHandler)
    }

    // Used for extending the session for any user activity
    useEffect(() => {
        LOG('SessionTimer Use Effect []')
        addEvents()
        return (() => {
            removeEvents()
            LOG('SessionTimer Use Effect [] - running clears')
        })
    }, [])

    // Used for checking inactivity
    useEffect(() => {

        const interval: NodeJS.Timeout = setInterval(inactivityCheck, 5000)

        return () => {
            clearInterval(interval)
            LOG('interval cleared')
        }

    }, [])

    return (
        <>
            <ErrorHandler {...errorHandlerProps} />
            {showWarningModal && <SessionInactivityWarningCountdownModal showModal={showWarningModal} setShowModal={setShowWarningModal} signOut={signOut} />}
        </>
    )
}

export default SessionTimer
