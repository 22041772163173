import { Tooltip } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export const customToolTip = (message: string): JSX.Element => {
    return (
        <Tooltip
            style={{ marginLeft: '2px', width: 20, height: 20 }}
            title={message}
        >
            <HelpOutlineIcon style={{fill: '#8e95ce'}} />
        </Tooltip>
    )
}

export const customLabelToolTip = (label: string, message: string): JSX.Element => {
    return (
        <>
            {label}
            <Tooltip
                style={{ marginLeft: '2px' }}
                title={message}
            >
                <HelpOutlineIcon style={{fill: '#8e95ce'}} />
            </Tooltip>
        </>

    )
}

export const customConditionalLabelToolTip = (label: string, message: string, show: boolean): JSX.Element => {
    return (
        <>
            {label}
            {show && (
                <Tooltip
                    style={{ marginLeft: '2px' }}
                    title={message}
                >
                    <HelpOutlineIcon />
                </Tooltip>
            )}

        </>
    )
}

export const customInLineLabelToolTip = (label: string, message: string): JSX.Element => {
    return (
        <>
            {label}
            {customToolTip(message)}
        </>
    )
}
