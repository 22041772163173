import { Typography } from '@mui/material'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'

const AccountAPI = () => {

  const ApiSandboxLink = (): JSX.Element => {
    return <a style={{ color: '#007ea8' }} href={AppConfigurations.apiDocsURL} target='_blank' rel='noopener noreferrer'>API sandbox</a>
  }

  return (
    <div>
      <h4>JSON API sandbox</h4>
      <Typography component={'span'} variant={'body2'}>
        <p>You can practice API calls using <ApiSandboxLink /> link as a trial user and see what the expected responses will be.</p>
        <p>Trial users have access to Dow 30 series.</p>
        <p>Dow 30 series include the following: <b>V, GS, UNH, CSCO, MSFT, TRV, INTC, NKE, AAPL, JPM, WBA, VZ, RTX, KO, BA, MMM, MRK, JNJ, IBM, XOM, WMT, MCD, HD, CAT, PG, DD2, DIS, AXP, CVX, PFE</b></p>
        <p>
          Here is the api sandbox for you to use: <ApiSandboxLink />
        </p>
        <p>
          Use the trial user account <span style={{ fontStyle: 'italic' }}>tryapi@gfdfinaeon.com</span> in the sandbox to retrieve the token and use the token to call the available endpoints.
          Note that only Dow 30 series are allowed for the applicable endpoints.
        </p>
      </Typography>
    </div>
  )
}

export default AccountAPI
