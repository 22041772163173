import { Box } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import DefaultMaterialTheme from '../GlobalSettings/DefaultMaterialTheme'
import MaterialTable from '@material-table/core'
import { sessionStorageKeys } from '../../../Services/SessionStorageService'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from '../../../Models/DataModels/Common/RedirectionModel'
import { getLocalStorageOrDefault, localStorageKeys } from '../../../Services/LocalStorageService'
import { CustomBtnCloseCancel, BtnEnabledDisabled, RadioBlue, CheckboxBlue } from '../GlobalSettings/CustomStyles'
import { errorRedColor } from '../GlobalSettings/GlobalStyles'
import { SortOrderStringType, getComparator, sort } from '../../../Models/DataModels/Common/SortModel'
import EnhancedTableHead from '../Utility/EnhancedTableHead'


const SelectToolbarLineGraphModal = ({ modalProps }: any) => {

    const [savedSearchData, setSavedSearchData] = useState<any[]>([])
    const [selectedTicker, setSelectedTicker] = useState<any>()
    const [compareSeries, setCompareSeries] = useState<string[]>([])
    const [showMaxCompareMessage, setShowMaxCompareMessage] = useState<boolean>(false)
    const [compareCount, setCompareCount] = useState<number>(0)

    const maxCompareMessage = "Only a max of 10 tickers can be selected as comparisons."

    const handleChangeCheckBox = (symbolValue: string) => {
        (compareSeries?.indexOf(symbolValue) === -1 && compareSeries?.length < 10) ? setCompareSeries([...compareSeries, symbolValue]) :
            setCompareSeries([...compareSeries?.filter((symbol: any) => {
                return symbol !== symbolValue
            })])
    }
    const incrementAndCheckMessage = () => {
        setCompareCount(compareSeries?.length)
        compareSeries?.length === 10 ? setShowMaxCompareMessage(true) : setShowMaxCompareMessage(false)
    }

    const location: any = useLocation()
    const navigate = useNavigate()

    const handleClose = () => {
        modalProps.setShowLineGraphModal(false)
    }

    const handleGraph = () => {
        if (modalProps?.selectedResults) {
            sessionStorage.setItem(sessionStorageKeys.selectedResultsForGraphData, JSON.stringify([...savedSearchData.values()]))
        }
        if (compareSeries?.length > 0) {
            sessionStorage.setItem(sessionStorageKeys.selectedComparisonGraphData, JSON.stringify([...compareSeries]))
        } else {
            sessionStorage.removeItem(sessionStorageKeys.selectedComparisonGraphData)
        }
        navigate(paths.graph.lineGraph + `/${selectedTicker}`, {
            state: {
                homePageModal: 'true',
                compareSeries: compareSeries,
                prevPage: location.pathname,
                ...(modalProps?.engineOption && { engineOption: modalProps.engineOption }),
                ...(location?.state?.lastPathFromCharts && { lastPathFromCharts: location.state.lastPathFromCharts })
            }
        })
    }

    const handleSetTicker = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedTicker(event.target.value)
    }

    const removeFromList = (symbol: string, currentBool: boolean) => {
        if (symbol === selectedTicker) {
            handleChangeCheckBox(symbol)
            return false
        }
        return currentBool
    }

    const savedSearchColumns: any[] = [
        { 'title': 'id', 'field': 'id', type: 'numeric', 'hidden': true },
        { 'title': 'Symbol', 'field': 'symbol', 'hidden': false },
        { 'title': 'Start', 'field': 'startDate', 'hidden': false },
        { 'title': 'End', 'field': 'endDate', 'hidden': false },]

    const selectedResultsColumn = [
        {
            title: 'Primary Series',
            field: 'primary',
            sorting: false,
            render: (rowData: any) =>
                <RadioBlue
                    checked={selectedTicker === rowData.symbol}
                    onChange={handleSetTicker}
                    value={rowData.symbol}
                    name='radio-buttons'
                    inputProps={{
                        'title': 'Select Primary Series',
                        'aria-label': 'Select Primary Series'
                    }}
                />
        },

        {
            title: 'Compare Series',
            field: 'compare',
            sorting: false,
            render: (rowData: any) =>

            (
                (compareSeries && compareSeries?.indexOf(rowData.symbol)) !== -1 ?
                    <CheckboxBlue
                        disabled={rowData.symbol === selectedTicker ? true : false}
                        checked={removeFromList(rowData.symbol, true)}
                        onChange={(e: any) => { handleChangeCheckBox(rowData.symbol) }}
                        inputProps={{
                            'title': 'Uncheck Compare Series',
                            'aria-label': 'Uncheck Compare Series'
                        }}
                    />
                    :
                    <CheckboxBlue
                        disabled={rowData.symbol === selectedTicker ? true : false}
                        checked={false}
                        onChange={(e: any) => { handleChangeCheckBox(rowData.symbol) }}
                        inputProps={{
                            'title': 'Check Compare Series',
                            'aria-label': 'Check Compare Series'
                        }}
                    />)
        }
    ]

    const columnDefinitions = [...selectedResultsColumn, ...savedSearchColumns]


    useEffect(() => {
        if (modalProps?.selectedResults) {
            setSavedSearchData(modalProps.selectedResults)
        } else {
            const stored = getLocalStorageOrDefault(localStorageKeys.selectedResultsData, true, [])
            if (savedSearchData.length !== stored.length) {
                setSavedSearchData(stored)
            }
        }
    })

    useEffect(() => {
        incrementAndCheckMessage()
    }, [compareSeries])

    const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
    const [sortColumn, setSortColumn] = useState<string>('')
    const sortedData: any[] = useMemo(
        () =>
        sort(savedSearchData, getComparator(sortOrder, sortColumn)),
        [sortOrder, sortColumn, savedSearchData],
    )

    const renderList = () => {
        return (
            <React.Fragment>
                <ThemeProvider theme={DefaultMaterialTheme}>
                    <MaterialTable
                        columns={columnDefinitions}
                        data={sortedData}
                        title='Primary Ticker Selection'
                        options={
                            {
                                maxBodyHeight: (window.innerHeight - 524).toString() + 'px',
                                showTextRowsSelected: false,
                                pageSizeOptions: [10, 50, 100, 300],
                                pageSize: 100,
                                emptyRowsWhenPaging: false,
                                headerStyle: {
                                    fontWeight: 'bold',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 'inherit',
                                },
                            }}
                        components={{
                        Header:
                            props => (
                                <EnhancedTableHead { ...{ sortData: {sortColumn, sortOrder, setSortColumn, setSortOrder }, columns: columnDefinitions } } />
                            )
                        }}
                    ></MaterialTable>
                </ThemeProvider>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Modal show={modalProps.showLineGraphModal} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Select Primary Ticker And Up To 10 Comparisons</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Box>
                        <Box textAlign={'center'} paddingBottom={1}>Graphing options will be applied to primary ticker only.
                            <p>Comparisons Selected: {compareCount}</p>
                            {showMaxCompareMessage ?
                                <p style={{ color: errorRedColor, fontStyle: 'italic' }}>{maxCompareMessage}</p> : <></>
                            }
                        </Box>
                        <Box>
                            {renderList()}
                        </Box>
                    </Box>
                </Modal.Body>

                <Modal.Footer>
                    <CustomBtnCloseCancel variant='outlined' onClick={handleClose}>Cancel</CustomBtnCloseCancel>
                    <BtnEnabledDisabled disabled={!selectedTicker} variant='contained' onClick={handleGraph}> Graph </BtnEnabledDisabled>
                </Modal.Footer>
            </Modal>

        </React.Fragment>
    )
}

export default SelectToolbarLineGraphModal