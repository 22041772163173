export type MovingAverageDisplayType = '20 Day' | '50 Day' | '200 Day' | '1 Month' | '3 Month' | '1 Year' | '5 Year' | '10 Year' | 'None'
export const MovingAverageDisplay = {
    None: 'None' as MovingAverageDisplayType,
    _20Day: '20 Day' as MovingAverageDisplayType,
    _50Day: '50 Day' as MovingAverageDisplayType,
    _200Day: '200 Day' as MovingAverageDisplayType,
    _1Month: '1 Month' as MovingAverageDisplayType,
    _3Month: '3 Month' as MovingAverageDisplayType,
    _1Year: '1 Year' as MovingAverageDisplayType,
    _5Year: '5 Year' as MovingAverageDisplayType,
    _10Year: '10 Year' as MovingAverageDisplayType
}

export type MovingAverageValueType = '20d' | '50d' | '200d' | '1m' | '3m' | '1y' | '5y' | '10y' | ''
export const MovingAverageValue = {
    None: 'None' as MovingAverageValueType,
    _20Day: '20d' as MovingAverageValueType,
    _50Day: '50d' as MovingAverageValueType,
    _200Day: '200d' as MovingAverageValueType,
    _1Month: '1m' as MovingAverageValueType,
    _3Month: '3m' as MovingAverageValueType,
    _1Year: '1y' as MovingAverageValueType,
    _5Year: '5y' as MovingAverageValueType,
    _10Year: '10y' as MovingAverageValueType
}

export const MovingAverageModel ={
    [MovingAverageDisplay.None]: MovingAverageValue.None,
    [MovingAverageDisplay._20Day]: MovingAverageValue._20Day,
    [MovingAverageDisplay._50Day]: MovingAverageValue._50Day,
    [MovingAverageDisplay._200Day]: MovingAverageValue._200Day,
    [MovingAverageDisplay._1Month]: MovingAverageValue._1Month,
    [MovingAverageDisplay._3Month]: MovingAverageValue._3Month,
    [MovingAverageDisplay._1Year]: MovingAverageValue._1Year,
    [MovingAverageDisplay._5Year]: MovingAverageValue._5Year,
    [MovingAverageDisplay._10Year]: MovingAverageValue._10Year
}

export default MovingAverageModel
