import React, { useEffect, useState } from 'react'
import { getAuthExpirationFromCookie } from '../../../../Services/CookieAccessService'
import AlertModal, { AlertModalProps, AlertButtonType } from '../AlertModal'
import { LOG } from '../../../../Services/Log'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../Models/DataModels/Common/RedirectionModel'
import { LogoutReason, LogoutReasonType } from '../../../../Models/DataModels/Requests/AuthRequests'

interface SimpleTime {
  hours: number,
  minutes: number,
  seconds: number
}

const MilliSecondsInSecond: number = 1000

// https://stackoverflow.com/questions/40885923/countdown-timer-in-react
const SecondsToSimpleTime = (totalSeconds: number): SimpleTime => {
  const hours = Math.floor(totalSeconds / (60 * 60));

  const divisor_for_minutes = totalSeconds % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const totalTime: SimpleTime = {
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }

  return totalTime
}

export interface SessionInactivityWarningCountdownModalProps {
  showModal: boolean,
  setShowModal: (showModal: boolean) => void,
  signOut: (logoutReason: LogoutReasonType) => void
}

const SessionInactivityWarningCountdownModal = ({
  showModal, setShowModal, signOut
}: SessionInactivityWarningCountdownModalProps) => {

  const navigate = useNavigate()

  const getSecondsRemaining = (): number => {
    const tokenExpirationDate: Date | null = getAuthExpirationFromCookie()
    if (!tokenExpirationDate) {
      LOG('getSecondsRemaining - Expired')
      return 0
    }
    const currentDateTime: Date = new Date()
    const dateDifferenceMilliSeconds: number = tokenExpirationDate.getTime() - currentDateTime.getTime()
    const secondsDifference: number = Math.round(dateDifferenceMilliSeconds / MilliSecondsInSecond)
    return secondsDifference
  }

  const [remainingTime, setRemainingTime] = useState<SimpleTime>(SecondsToSimpleTime(getSecondsRemaining()));

  const updateCountDown = () => {
    const secondsRemaining: number = getSecondsRemaining()
    LOG(`updateCountDown Seconds remaining: ${secondsRemaining} Seconds`)
    if (secondsRemaining < 1) {
      signOut(LogoutReason.Timeout)
      navigate(paths.login)
    } else {
      setRemainingTime(SecondsToSimpleTime(secondsRemaining))
    }
  }

  const handleClick = () => {
    LOG('handleClick called')
    setShowModal(false)
    return true
  }

  const minutesDisplay = (minutes: number) => {
    if (minutes > 0) {
      return <span><b>{minutes}</b> Minutes </span>
    }
    return <></>
  }

  const contents = () => {
    return (
      <>
        <div>You will be logged out in {minutesDisplay(remainingTime.minutes)}<b>{remainingTime.seconds}</b> Seconds</div>
        <div>Close this window or click OK to refresh your session.</div>
      </>
    )
  }

  const sessionTimedOutProps: AlertModalProps = {
    showModal: showModal,
    setShowModal: setShowModal,
    onAlertClose: handleClick,
    AlertTitle: 'Session Warning',
    AlertContent: contents(),
    AlertButtons: [
      {
        type: AlertButtonType.OK,
        display: 'OK',
        onClick: handleClick,
        isPrimary: true
      }
    ],
    Size: 'lg'
  }

  useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(updateCountDown, 1000)

    // Always include cleanup for setInterval
    return () => {
      clearInterval(interval)
      LOG('interval cleared - CountDown')
    }
  }, [])

  return AlertModal(sessionTimedOutProps)
}

export default SessionInactivityWarningCountdownModal
