import React from "react"
import { ReactComponent as DeleteSelectedIcon } from "../../Images/svg/DeleteSelected.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const DeleteSelected = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <DeleteSelectedIcon />
  </SvgIcon>
)

export default DeleteSelected