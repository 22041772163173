export const errorRedColor: string = '#e00000'

export const globalStyles = {
    circularProgress: {
        height: '24px',
        width: '24px',
        color: '#1976d2'
    },
    disabledButton: {
        backgroundColor: 'grey', 
        color: '#FFF'
    },
    mainPageBody: {
        scroll: {
            overflow: 'auto'
        }
    }
}