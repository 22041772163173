import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { ALLCountryGroupEntry } from '../../../Models/DataModels/Common/FieldPopulationModel'
import DateYearRangeInput from './DateYearRangeInput'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import { getLocallyStoredCountriesByGroup, getLocallyStoredCountryGroups } from '../../../Services/DropDownValuesService'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { countryGroupToolTipList } from '../../Common/Utility/SearchPageToolTips'
import React from 'react'
import { renderAutoComputeOption } from './GFDatabase2Point0'


const RealEstateDatabase = ({ searchEngineOptions, theme }: any) => {

  const subDatabaseList = [
    { label: 'GFD Real Estate Data', value: 'GFD Real Estate Data' },
    { label: "Winan's Real Estate Data", value: 'Winans Real Estate Data' }
  ]

  const [countryGroupList, setCountryGroupList] = useState<LabelValuePair[]>([ALLCountryGroupEntry])
  const [countriesByGroup, setCountriesByGroup] = useState<LabelValuePair[]>([])
  const [countryGroupToolTip, setCountryGroupToolTip] = useState<string>('')

  useEffect(() => {
    getLocallyStoredCountryGroups().then((value: LabelValuePair[] | null) => value && setCountryGroupList(value), () => { })
  }, [])

  useEffect(() => {
    getLocallyStoredCountriesByGroup(searchEngineOptions.countryGroup).then((value: LabelValuePair[] | null) => value && setCountriesByGroup(value), () => { })
  }, [searchEngineOptions.countryGroup])

  useEffect(() => {
    const toolTip: string = countryGroupToolTipList.find((element: any) => element.label === searchEngineOptions?.countryGroup)?.toolTip || ''
    setCountryGroupToolTip(toolTip)
  }, [searchEngineOptions.countryGroup])

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Grid container spacing={2} >
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              options={countryGroupList}
              defaultValue={ALLCountryGroupEntry}
              isOptionEqualToValue={(option: any, value) => option?.value === value?.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setCountryGroup(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label={<React.Fragment>
                    <span>Filter by Country Group </span>
                    {searchEngineOptions.countryGroup &&
                      <Tooltip title={countryGroupToolTip}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    }
                  </React.Fragment>}
                  size='small'
                />
              }
              renderOption={renderAutoComputeOption}
              sx={realEstateDatabaseStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectCountry'
              options={countriesByGroup}
              isOptionEqualToValue={(option: any, value) => option.value === value.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setCountry(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Select a Country'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={realEstateDatabaseStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectSubDatabase'
              options={subDatabaseList}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setSubDatabase(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Select Sub Database'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={realEstateDatabaseStyles.wholeLineInput}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <DateYearRangeInput setIsDatesValid={searchEngineOptions.setDatesValid} setValidStartYear={searchEngineOptions.setStartYear} setValidEndYear={searchEngineOptions.setEndYear} setIsOnlyInYearsRange={searchEngineOptions.setIsOnlyInYearsRange} startYearGridItemSizeOverride={4} EndYearGridItemSizeOverride={4} inRangeCheckboxGridItemSizeOverride={4} />
        </Grid>
      </div>
    </ThemeProvider>
  )
}

const realEstateDatabaseStyles = {
  row: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap'
  },
  wholeLineInput: {
    paddingBottom: 1,
    width: '100%'
  }
}

export default RealEstateDatabase
