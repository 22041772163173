import { NewUserInfoRequest } from "../Models/DataModels/Requests/NewUserInfoRequests"
import getAPICallResult, { APIRequest, HttpRequest } from "./APIService"

export const getUser = async () => {
    const apiRequest: APIRequest = {
        relativePath: '/User',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const editUserInfo = async (req: NewUserInfoRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/User/EditUserInfo',
        method: HttpRequest.Post,
        requestObject: req
    }
    return getAPICallResult(apiRequest)
}

const BasicInfoService = () => {
    return (
      <div></div>
    )
  }
  
  export default BasicInfoService
  