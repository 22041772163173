import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { SubscriptionFilterType } from '../../../Models/DataModels/Common/SubscriptionFilter'
import { RadioBlue } from '../GlobalSettings/CustomStyles'

export interface SubscriptionFilterProps {
  subscriptionFilter: SubscriptionFilterType,
  setSubscriptionFilter: (type: SubscriptionFilterType) => void  
}

export const SubscriptionFilter = ({
  subscriptionFilter,
  setSubscriptionFilter
}: SubscriptionFilterProps) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscriptionFilter(event.target.value as SubscriptionFilterType)
  }

  return <>
    <div style={{ marginTop: '8px'}}>
      <FormLabel id="row-radio-buttons-subscriptionFilter-label">Filter by Subscription</FormLabel>
      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-subscriptionFilter-label"
        name="row-radio-buttons-subscriptionFilter-group"
      >
        <FormControlLabel control={<RadioBlue
                onChange={handleChange}
                value={SubscriptionFilterType.All} 
                checked={!subscriptionFilter || subscriptionFilter === SubscriptionFilterType.All} 
                />} 
            label="All"
        />
        <FormControlLabel control={<RadioBlue
                onChange={handleChange}
                value={SubscriptionFilterType.Subscribed}
                checked={subscriptionFilter === SubscriptionFilterType.Subscribed} 
                />} 
            label="Subscribed"
        />
        <FormControlLabel control={<RadioBlue
                onChange={handleChange}
                value={SubscriptionFilterType.Unsubscribed}
                checked={subscriptionFilter === SubscriptionFilterType.Unsubscribed} 
                />} 
            label="Unsubscribed"
        />
      </RadioGroup>
    </div>
  </>
}