import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../HelpPageStyles'

const TipsAutoTrac = () => {
    return (
        <>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Tips and Tricks</h2>
                </div>
            </Box>
            <p style={{ textAlign: 'left' }}>This section will discuss about the Tips and Tricks for AutoTrac feature.</p>
            <div style={{ textAlign: 'left' }}>
                <Box
                    sx={helpPageStyles.heading}>
                    <div>
                        <h4>Data Count limit</h4>
                    </div>
                </Box>
                <div style={{ height: '412px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracTips_dataCountLimit.png' style={helpPageStyles.image} alt='Tips AutoTrac Data Count Limit'></img>
                </div>
                <p>
                    If excel output format is selected and if the total data count of the workbook exceeds the limit of 1048575, price data will be split into multiple worksheets in the Excel Workbook.
                </p>
                <div style={{ height: '587px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracTips_dataCountWithinLimit.png' style={helpPageStyles.image} alt='Tips AutoTrac Data Count Within Limit'></img>
                </div>
                <p>
                    You can keep track of the limit by viewing the data count total at the bottom right hand side of the Workbook Contents area.
                </p>
                <div style={{ height: '513px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracTips_dataCountExceedLimit.png' style={helpPageStyles.image} alt='Tips AutoTrac Data Count Exceeds Limit'></img>
                </div>
                <p>
                    If the total exceeds the limit, price data will be split into multiple sheets. It's recommended to keep the data count small per workbook.
                </p>
                <p>Sample workbook with multiple worksheets:</p>
                <div style={{ height: '375px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracTips_MultipleSheets.png' style={helpPageStyles.image} alt='Tips AutoTrac Multiple Sheets'></img>
                </div>
            </div>
        </>
    )
}

export default TipsAutoTrac
