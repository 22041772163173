import { ToastContainer, toast, ToastOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const gfdToastOptions: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light"
}

const formatMessage = (message: string) => {
    return <div
        style={{
            maxHeight: '500px',
            overflow: 'auto'
        }}
    >
        {message}
    </div>
}

export const GFDToastInfo = (message: string) => {
    toast.info(formatMessage(message), gfdToastOptions)
}

export const GFDToastSuccess = (message: string) => {
    toast.success(formatMessage(message), gfdToastOptions)
}

export const GFDToastWarning = (message: string) => {
    toast.warning(formatMessage(message), gfdToastOptions)
}

export const GFDToastError = (message: string) => {
    toast.error(formatMessage(message), gfdToastOptions)
}

const GFDToastContainer = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    )
}

export default GFDToastContainer
