import { CSSProperties } from '@mui/material/styles/createTypography';

export const helpPageStyles = {
    heading: {
        display: 'flex',
        justifyContent: 'center',
        p: 1,
        m: 1,
        borderRadius: 1
    } as CSSProperties,
    image: { height: 'auto', maxWidth: '100%', border: '5px solid #555' } as CSSProperties,
    pointer: { cursor: 'pointer' } as CSSProperties,
    hyperlinkStyle: {
        color: '#006080',
        textDecoration: 'underline',
        cursor: 'pointer'
    } as CSSProperties
}
