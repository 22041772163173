import React from "react"
import { ReactComponent as AddSelectedSeriesIcon } from "../../Images/svg/AddSelectedSeries.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const AddSelectedSeries = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <AddSelectedSeriesIcon />
  </SvgIcon>
)

export default AddSelectedSeries