import React, { useEffect, useState } from 'react'
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area, Legend } from 'recharts'
import { ChartDefinition, ChartMetaData } from '../../../../../../Models/DataModels/Common/ToolsModel'
import { graphCompanyLogoSVG, rechartsSourceTextLabelObject } from '../../../../../Common/Modals/GraphUtil'
import OutputFormatValue, { OutputFormatValueType } from '../../../../../../Models/DataModels/Common/OutputFormatModel'
import GraphDownloadOptions, { GraphDownloadOptionsProps } from '../../../../../Common/Modals/GraphDownloadOptions/GraphDownloadOptions'
import { CreateDataFileRequest, FileDownloadRequest, GetCapitalizationSummaryChartRequest, GetCapitalizationSummaryRequest } from '../../../../../../Models/DataModels/Requests/ToolsRequests'
import { createDownloadDataFile, getCapitalizationSummaryChart } from '../../../../../../Services/ToolsService'
import { CreateDataFileResponse } from '../../../../../../Models/DataModels/Responses/ToolsResponses'
import { downloadFileFromServer } from '../../../../../../Services/DownloadService'
import { NotOKResponseModel } from '../../../../../../Models/DataModels/Common/NotOKResponseModel'
import { getNextKellyColorSkipLight } from '../../../../../../Services/ColorService'
import { IconButton, CircularProgress, Tooltip as SearchInProgressToolTip, Button, Grid, Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material'
import { abortRequest, abortKeys } from '../../../../../../Services/APIService'
import { chartSizes } from '../../../../../../Models/DataModels/Common/AppConfigurationsModel'
import { DateFormatValueType } from '../../../../../../Models/DataModels/Common/DateFormatModel'
import moment from 'moment'
import ExportOutputFormatSelectionModal from '../../../../../Common/Modals/ExportOutputFormatSelectionModal'

interface YearRange {
    startYear: number,
    endYear: number
}

export interface CapSummaryPercentAreaChartProps {
    previousYears: number,
    currentSummaryRequest: GetCapitalizationSummaryRequest | null,
    chartTitle: string,
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void
}

const CapSummaryPercentAreaChart = ({
    previousYears,
    currentSummaryRequest,
    chartTitle,
    setErrorResponse
}: CapSummaryPercentAreaChartProps) => {

    const rangeValue: number = 10
    const approximateRange: number = (rangeValue / previousYears) * 100
    const [triggerOnce, setTriggerOnce] = useState<boolean>(false)
    const [requestYearRanges, setRequestYearRanges] = useState<YearRange[]>([])
    const [dataSources, setDataSources] = useState<any[]>([])
    const [chartNamesArray, setChartNamesArray] = useState<string[]>([])
    const [searchInProgress, setSearchInProgress] = useState<boolean>(false)
    const [progress, setProgress] = useState<number>(0)
    const [isCanceled, setIsCanceled] = useState<boolean>(false)
    const [showExportOutputFormatModal, setShowExportOutputFormatModal] = useState<boolean>(false)
    const [selectedOutputFormat, setSelectedOutputFormat] = useState<OutputFormatValueType>(OutputFormatValue.Excel)

    const cancelLoadAction = () => {
        abortRequest(abortKeys.capitalizationHistoricalChart)
        setIsCanceled(true)
        setSearchInProgress(false)
    }

    const createYearRanges = () => {

        if (!currentSummaryRequest || !currentSummaryRequest.date || previousYears < 1) {
            return
        }

        const rangeEndYear: number = currentSummaryRequest.date?.getFullYear()
        const rangeStartYear: number = rangeEndYear - previousYears
        const yearRanges: YearRange[] = []

        let yearCounter: number = rangeStartYear
        while (yearCounter < rangeEndYear) {
            const currentRangeStartYear: number = yearCounter
            const currentRangeStartYearPlusRangeValue: number = currentRangeStartYear + rangeValue
            const currentRangeEndYear: number = currentRangeStartYearPlusRangeValue > rangeEndYear ? rangeEndYear : currentRangeStartYearPlusRangeValue
            const currentYearRange: YearRange = {
                startYear: currentRangeStartYear,
                endYear: currentRangeEndYear
            }
            yearRanges.push(currentYearRange)
            yearCounter += (rangeValue + 1)
        }

        setSearchInProgress(true)
        setRequestYearRanges(yearRanges)
    }

    const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant='determinate' {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant='body2' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        )
    }

    const LoadingSearchInProgress = (): JSX.Element => {
        return (
            <>
                <SearchInProgressToolTip title='Search in Progress'>
                    <Grid container columns={16} alignItems='center'>
                        <Grid item md={3}>
                            <IconButton aria-label='Search in Progress' component='label' sx={{
                                p: '10px',
                                color: '#1976d2',
                                visibility: 'visible'
                            }}>
                                <CircularProgress title='Search in Progress' aria-label='Search in Progress' />
                            </IconButton>
                            <span className='loading' style={{ fontSize: 20, fontWeight: 'bold' }}>Loading</span>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Button id='cancelSummaryLoadButton' variant='outlined' onClick={cancelLoadAction}>Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SearchInProgressToolTip>
                <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel title='Search in Progress' aria-label='Search in Progress' value={progress} />
                </Box>
            </>
        )
    }

    const getCurrentProgressChunck = (previousYears: number, currentCompletedStartYear: number, currentCompletedEndYear: number): number => {
        const totalCoverageYears: number = previousYears + 1
        const currentCompletedYears: number = (currentCompletedEndYear - currentCompletedStartYear) + 1
        return (currentCompletedYears / totalCoverageYears) * 100
    }

    useEffect(() => {
        setTriggerOnce(true)
    }, [])

    useEffect(() => {
        if (triggerOnce) {
            createYearRanges()
            setTriggerOnce(false)
        }
    }, [triggerOnce])

    useEffect(() => {
        if (isCanceled) {
            return
        }
        if (requestYearRanges.length > 0) {
            const currentRequestYearRanges: YearRange[] = [...requestYearRanges]
            const poppedYearRange: YearRange | null = currentRequestYearRanges.pop() || null

            if (poppedYearRange && currentSummaryRequest) {
                const getCapSummaryChartRequest: GetCapitalizationSummaryChartRequest = {
                    ...currentSummaryRequest,
                    rangeStartYear: poppedYearRange.startYear,
                    rangeEndYear: poppedYearRange.endYear
                }

                getCapitalizationSummaryChart(getCapSummaryChartRequest).then(
                    (charData: ChartDefinition) => {
                        if (charData?.charts && charData.charts.length > 0 && charData.charts[0]?.dataSources && charData.charts[0].dataSources.length > 0) {
                            const currentDataSources: any[] = charData?.charts[0]?.dataSources[0]
                            currentDataSources.push(...dataSources)
                            setDataSources(currentDataSources)
                            const metaDataArray: ChartMetaData[] = charData?.charts[0]?.metaDataArray || []
                            const merged: string[] = metaDataArray.map((value: ChartMetaData) => value.chartName)
                            merged.push(...chartNamesArray)
                            const mergedUniqueNames: string[] = [...new Set(merged)]
                            const mergedUniqueNamesSorted: string[] = mergedUniqueNames.sort()
                            setChartNamesArray(mergedUniqueNamesSorted)
                            const currentCompletedChunck: number = getCurrentProgressChunck(previousYears, charData.capitalizationSummaryChartRequest?.rangeStartYear || 0, charData.capitalizationSummaryChartRequest?.rangeEndYear || 0)
                            setProgress(progress + currentCompletedChunck)
                        } else {
                            setProgress(progress + approximateRange)
                        }
                        setRequestYearRanges(currentRequestYearRanges)
                    },
                    //Reject Promise
                    (notOKResponseModel: NotOKResponseModel) => {
                        if (notOKResponseModel.statusCode === 404) {
                            setProgress(progress + approximateRange)
                            setRequestYearRanges(currentRequestYearRanges)
                        } else {
                            setErrorResponse(notOKResponseModel)
                        }
                    }
                )
            }
        } else {
            setSearchInProgress(false)
        }
    }, [requestYearRanges])

    if (searchInProgress) {
        return <LoadingSearchInProgress />
    }

    if (isCanceled) {
        return <>Canceled</>
    }

    if (!dataSources || dataSources.length === 0) {
        return <>No Data</>
    }

    const generateArea = () => {
        const jsxAreaArray: JSX.Element[] = []
        const metaDataArray: string[] = chartNamesArray
        if (metaDataArray) {
            metaDataArray.forEach((chartName: string, index: number) => {
                const color: string = getNextKellyColorSkipLight(index)
                const keyName: string = `${chartName}-${index}`
                jsxAreaArray.push(
                    <Area key={keyName} name={chartName} type='monotone' dataKey={chartName} stackId='1' stroke={color} fill={color} />
                )
            })
        }

        return jsxAreaArray
    }

    const toPercent = (decimal: number, fixed: number = 0) => {
        return `${(decimal * 100).toFixed(fixed)}%`
    }

    const getPercent = (value: number, total: number) => {
        const ratio = total > 0 ? value / total : 0
        return toPercent(ratio, 2)
    }

    const renderTooltipContent = (o: any) => {
        const { payload = [], label } = o
        const total: number = payload.reduce(
            (result: number, entry: any) => result + entry.value,
            0
        )

        const columnCount: number = payload?.length > 40 ? 2 : 1

        return (
            <div style={{ background: 'white', borderStyle: 'solid', columnCount: columnCount }}>
                <p>{`${label} (Total: ${total.toFixed(2)})`}</p>
                <ul>
                    {payload.map((entry: any, index: number) => (
                        <li key={`item-${index}`} style={{ color: entry.color }}>
                            {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    const processDownloadDataFile = (dateFormat: DateFormatValueType) => {
        const dataFileRequest: CreateDataFileRequest = {
            fileName: createExportFilename(),
            fileData: createDownloadData(dateFormat),
            outputFormat: selectedOutputFormat
        }
        createDownloadDataFile(dataFileRequest).then(
            (fileCreateResponse: CreateDataFileResponse) => {
                const downloadRequest: FileDownloadRequest = {
                    filename: fileCreateResponse.filename,
                    mimeType: fileCreateResponse.mimeType
                }
                downloadFileFromServer(downloadRequest, setErrorResponse)
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
            }
        )
    }

    const downloadDataFile = (format: OutputFormatValueType) => {
        setSelectedOutputFormat(format)
        setShowExportOutputFormatModal(true)
    }

    const downloadCSV = () => {
        downloadDataFile(OutputFormatValue.CSV)
    }

    const downloadExcel = () => {
        downloadDataFile(OutputFormatValue.Excel)
    }

    const createDownloadData = (dateFormat: DateFormatValueType): string[][] => {
        const downloadDataContents: string[][] = []

        const uniqueKeys: string[] = ['date', ...chartNamesArray || []]
        downloadDataContents.push(uniqueKeys)

        dataSources.forEach((value: any) => {
            const singleEntryArray: string[] = []
            uniqueKeys.forEach((uniqueKey: string) => {
                const keyLookup: string = uniqueKey === 'date' ? 'name' : uniqueKey
                const valueFromKey: string = uniqueKey === 'date' ? moment(value[keyLookup]).format(dateFormat.toUpperCase()) : value[keyLookup]
                valueFromKey ? singleEntryArray.push(valueFromKey) : singleEntryArray.push('')
            })
            downloadDataContents.push(singleEntryArray)
        })

        return downloadDataContents
    }

    const createExportFilename = (): string => {
        return `${chartTitle.split(':')[0]}`
    }

    const imageID: string = 'capSummaryPercentAreaGraph'

    const graphDownloadOptionsProps: GraphDownloadOptionsProps = {
        downloadCSV: downloadCSV,
        downloadExcel: downloadExcel,
        imageIDValue: imageID
    }

    return (
        <>
            <GraphDownloadOptions {...graphDownloadOptionsProps} />
            <Box
                id={imageID}
                marginBottom={'40px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                style={{ backgroundColor: 'white' }}
            >
                <strong style={{ paddingLeft: '10%', fontSize: '20px' }}>{chartTitle}</strong>
                <ResponsiveContainer className='no-background large-graph-container' width='100%' height={chartSizes.large}>
                    <AreaChart
                        width={900}
                        height={800}
                        data={dataSources}
                        stackOffset='expand'
                        margin={{
                            top: 0,
                            right: 20,
                            left: 30,
                            bottom: 10,
                        }}
                    >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' label={rechartsSourceTextLabelObject} padding={{ right: 22 }} />
                        <YAxis reversed={true} tickFormatter={toPercent} interval='preserveStartEnd' />
                        <Tooltip content={renderTooltipContent} />
                        {generateArea()}
                        <Legend iconType='square' />
                        {graphCompanyLogoSVG()}
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <ExportOutputFormatSelectionModal show={showExportOutputFormatModal} setShow={setShowExportOutputFormatModal} exportAction={processDownloadDataFile} />
        </>
    )
}

export default CapSummaryPercentAreaChart
