import React from 'react'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import AlertModal, { AlertModalProps, AlertButtonType } from '../../Common/Modals/AlertModal'
import SimpleGraph, { SimpleGraphProps } from './SimpleGraph'

export interface GraphModalProps {
    showModal: boolean,
    setShow: (show: boolean) => void,
    seriesName: string,
    signOut: (logoutReason: LogoutReasonType) => void
}

const GraphModal = ({
    showModal,
    setShow,
    seriesName,
    signOut
}: GraphModalProps) => {

    const modalTitle = (): string => {
        return seriesName
    }

    const simpleGraphProps: SimpleGraphProps = {
        seriesName,
        signOut: signOut
    }

    const CCChartAlertModalProps: AlertModalProps = {
        showModal: showModal,
        setShowModal: setShow,
        AlertTitle: modalTitle(),
        AlertContent: <SimpleGraph {...simpleGraphProps} />,
        AlertButtons: [
            {
                type: AlertButtonType.OK,
                display: 'Close',
                onClick: () => setShow(false),
                isPrimary: false,
            }
        ],
        Size: 'xl',
        additionalClassnames: 'wider'
    }

    return AlertModal(CCChartAlertModalProps)
}

export default GraphModal
