import { WorkbookListRequest } from '../Models/DataModels/Requests/AuthRequests'
import {
    WorkbookTickersByIDRequest,
    WorkbookRequest,
    WorkbooksRequest,
    WorkbookTickersByNameRequest,
    WorkbookRenameRequest,
    WorkbookTickersByCUSIPRequest,
    WorkbookDownloadRequest,
    DownloadQueueRequest,
    DownloadPropertiesRequest,
    WorkbookCopyRequest
} from '../Models/DataModels/Requests/AutoTracRequests'
import getAPICallResult, { APIRequest, HttpRequest } from './APIService'

const resources = {
    autoTrac: 'AutoTrac',
    workbooks: 'Workbooks',
    tickerIDs: 'TickerIDs',
    tickerNames: 'TickerNames',
    CUSIPs: 'CUSIPs',
    download: 'Download',
    directDownload: 'DirectDownload',
    downloadFile: 'DownloadFile',
    downloadQueue: 'DownloadQueue',
    downloadSettings: 'DownloadSettings',
    list: 'List',
    powerDownloads: 'PowerDownloads',
    downloadProperties: 'DownloadProperties',
    maximumTickerLimit: 'MaximumTickerLimit'
}

const workbooksPath = resources.autoTrac + '/' + resources.workbooks
const workbookListPath = workbooksPath + '/' + resources.list
const tickerIDsPath = resources.autoTrac + '/' + resources.tickerIDs
const tickerNamesPath = resources.autoTrac + '/' + resources.tickerNames
const CUSIPsPath = resources.autoTrac + '/' + resources.CUSIPs
const downloadPath = resources.autoTrac + '/' + resources.download 
const downloadQueuePath = resources.autoTrac + '/' + resources.downloadQueue
const downloadSettingsPath = downloadQueuePath + '/' + resources.downloadSettings
const downloadFilePath = resources.autoTrac + '/' + resources.downloadFile
const powerDownloadsPath = resources.autoTrac + '/' + resources.powerDownloads

export const autoTracEndPoints = {
    getWorkbooks: workbookListPath,
    getWorkbookDetails: workbooksPath + '/Fetch',
    addWorkbook: workbooksPath + '/Create',
    deleteWorkbook: workbooksPath + '/Delete',
    deleteWorkbooks: workbooksPath,
    renameWorkbook: workbooksPath,
    deleteTickersFromWorkbook: tickerIDsPath,
    addTickersToWorkbookByName: tickerNamesPath,
    addTickersToWorkbookByID: tickerIDsPath,
    addTickersToWorkbookByCUSIP: CUSIPsPath,
    downloadWorkbook: downloadPath,
    downloadFile: downloadFilePath,
    getDownloadQueue: downloadQueuePath,
    getDownloadSettingsForQueueEntry: downloadSettingsPath
}

// get workbook list
export const getWorkbooks = async (request: WorkbookListRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.getWorkbooks,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// get workbook details
export const getWorkbookDetails = async (request: WorkbookRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.getWorkbookDetails,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// add workbook
export const addWorkbook = async (request: WorkbookRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.addWorkbook,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// delete workbook
export const deleteWorkbook = async (request: WorkbookRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.deleteWorkbook,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// delete workbooks
export const deleteWorkbooks = async (request: WorkbooksRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.deleteWorkbooks,
        method: HttpRequest.Delete,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// rename workbook
export const renameWorkbook = async (request: WorkbookRenameRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.renameWorkbook,
        method: HttpRequest.Put,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// copy workbook
export const copyWorkbook = async (request: WorkbookCopyRequest) => {
    const apiRequest: APIRequest = {
        relativePath: `${resources.autoTrac}/Copy`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// delete tickers from workbook
export const deleteTickersFromWorkbook = async (request: WorkbookTickersByIDRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.deleteTickersFromWorkbook,
        method: HttpRequest.Delete,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// add tickers to workbook by name
export const addTickersToWorkbookByName = async (request: WorkbookTickersByNameRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.addTickersToWorkbookByName,
        method: HttpRequest.Put,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// add tickers to workbook by ID
export const addTickersToWorkbookByID = async (request: WorkbookTickersByIDRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.addTickersToWorkbookByID,
        method: HttpRequest.Put,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// add tickers to workbook
export const addTickersToWorkbookByCUSIP = async (request: WorkbookTickersByCUSIPRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.addTickersToWorkbookByCUSIP,
        method: HttpRequest.Put,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// download workbook
export const downloadWorkbook = async (request: WorkbookDownloadRequest) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.downloadWorkbook,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const directDownloadWorkbook = async (request: WorkbookDownloadRequest) => {
    const apiRequest: APIRequest = {
        relativePath: `${resources.autoTrac}/${resources.directDownload}`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const downloadProperties = async (request: DownloadPropertiesRequest) => {
    const apiRequest: APIRequest = {
        relativePath: `${resources.autoTrac}/${resources.downloadProperties}`,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// download file
export const downloadFile = async (file: string) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.downloadFile + '/' + file,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest, false, true)
}

// maximum ticker limit
export const maximumTickerLimit = async () => {
    const apiRequest: APIRequest = {
        relativePath: `${resources.autoTrac}/${resources.maximumTickerLimit}`,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest, false)
}

// get download queue
export const getDownloadQueue = async (request: DownloadQueueRequest, isBackgroundCall: boolean | undefined = false) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.getDownloadQueue,
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, isBackgroundCall)
}

// get download settings for queue entry
export const getDownloadSettingsForQueueEntry = async (QueueID: number) => {
    const apiRequest: APIRequest = {
        relativePath: autoTracEndPoints.getDownloadSettingsForQueueEntry + '/' + encodeURIComponent(QueueID),
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

// get power downloads
export const canAccessPowerDownloads = async () => {
    const apiRequest: APIRequest = {
        relativePath: powerDownloadsPath + '/CanAccess',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const getPowerDownloads = async () => {
    const apiRequest: APIRequest = {
        relativePath: powerDownloadsPath,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

// enqueue power download
export const enqueuePowerDownload = async (downloadID: number) => {
    const apiRequest: APIRequest = {
        relativePath: powerDownloadsPath + '/' + encodeURIComponent(downloadID),
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}
