import getAPICallResult, { APIRequest, HttpRequest, abortKeys } from './APIService'
import { CAPERatioRequest, CreateCapitalizationSummaryChartDownloadDataRequest, CreateCurrencyConversionChartDownloadDataRequest, CreateDataFileRequest, CurrencyConversionChartRequest, CurrencyConversionDataCountRequest, CurrencyConversionDownloadRequest, FileDownloadRequest, GetCapitalizationListRequest, GetCapitalizationSummaryChartRequest, GetCapitalizationSummaryRequest } from '../Models/DataModels/Requests/ToolsRequests'

export const getCurrencyConversionDataCount = async (request: CurrencyConversionDataCountRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/GetCurrencyConversionDataCount',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const getCurrencyConversionData = async (request: CurrencyConversionDownloadRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/GetCurrencyConversionData',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const getCurrencyConversionChart = async (request: CurrencyConversionChartRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/GetCurrencyConversionChart',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const createCurrencyConversionChartDownloadData = async (request: CreateCurrencyConversionChartDownloadDataRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/CreateCurrencyConversionChartDataFile',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const getCapitalizationList = async (request: GetCapitalizationListRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/GetCapitalizationList',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const getCapitalizationSummary = async (request: GetCapitalizationSummaryRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/GetCapitalizationSummary',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const getCapitalizationSummaryChart = async (request: GetCapitalizationSummaryChartRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/GetCapitalizationSummaryChart',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest, false, false, null, abortKeys.capitalizationHistoricalChart)
}

export const createCapitalizationSummaryChartChartDownloadData = async (request: CreateCapitalizationSummaryChartDownloadDataRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/CreateCapitalizationSummaryChartDownloadData',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const getCAPERatioData = async (request: CAPERatioRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/GetCAPEData',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const downloadFile = async (request: FileDownloadRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/DownloadFile',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest, false, true)
}

export const createDownloadDataFile = async (request: CreateDataFileRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/Tools/CreateDataDownloadFile',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

//Exports
export const exportCapitalizationList = async (request: GetCapitalizationListRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/export/CapitalizationListExportData',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}

export const exportCapitalizationSummary = async (request: GetCapitalizationSummaryRequest) => {
  const apiRequest: APIRequest = {
    relativePath: '/export/CapitalizationSummaryExportData',
    method: HttpRequest.Post,
    requestObject: request
  }
  return getAPICallResult(apiRequest)
}
