import { Box } from '@mui/material'
import React from 'react'
import { GreenGreenText, ImageNumberReference, MarkedMargentaPinkText, MarkedOrangeOrangeText, MarkedRedRedText, MarkedYellowYellowText } from '../../HelpPageCommonComponents'

const BasicSearch = () => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Basic Search</h2>
                </div>

            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h4>Search Bar</h4>
                </div>
            </Box>



            <div style={{ textAlign: 'left' }}>
                <div style={{ height: '545px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicSearch01.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Basic Search 1'></img>
                </div>
                <p style={{ paddingTop: 25 }}>
                    Here is the basic search Screen.  In <MarkedRedRedText /> ({ImageNumberReference(1)}) you can see the primary search bar, regardless of what engine is selected, you
                    will enter your search terms here.  By default, the engine selected is GFD Universe (Engine Select Dropdown highlighted in <GreenGreenText /> ({ImageNumberReference(2)})), this will search through all of our engines and is useful
                    if you are subscribed to multiple engines and want to search through all of them.  As you type, the autocomplete field will attempt to give you options to select, in this case we are
                    typing ibm and it is showing us the US stock IBM as one of the options.  If you click any of the options it will do an EXACT MATCH search, so if I click on IBM
                    at the top of the autocomplete list, it will just return IBM in our results.
                </p>

                <p>
                    If you just want to search for something that contains IBM in it, then just click the button with a search icon (<MarkedYellowYellowText /> ({ImageNumberReference(3)})) instead of an autocomplete option and it will do a 'contains' Search
                    on the data.
                </p>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                        m: 1,
                        borderRadius: 1
                    }}>
                    <div>
                        <h4>Search Options</h4>
                    </div>
                </Box>

                <div style={{ height: '670px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicSearch02.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Basic Search 2'></img>
                </div>

                <p style={{ paddingTop: 25 }}>
                    You can also use the search options highlighed in <MarkedMargentaPinkText /> ({ImageNumberReference(1)}) above.  The menu it opens will change how the search term affects the results.
                    As soon as you hit 'Save Changes' it will update the results list with the current search term.
                </p>
                <p>
                    In <MarkedOrangeOrangeText /> ({ImageNumberReference(2)}) you can see the search term options.
                    By default it's set to  contains.
                </p>

                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Contains</span> will look for anything that contains that term.</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Starts With</span> will check anything that starts with the search term.</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Exact Match</span> will attempt to match the term exactly.</p>

                <p>
                    In <span style={{ color: 'blue', fontWeight: 'bold' }}>blue</span> ({ImageNumberReference(3)}) you can see the sort options.  These will sort your results based on the selected option. Default is Top Downloads first.
                </p>

                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Top GFD Downloads First</span> will sort by our most popular series (symbol) by download, these will be first.</p>
                <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Sort Alphabetically</span> will sort by our most alphabetical series (symbol) name.</p>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                        m: 1,
                        borderRadius: 1
                    }}>
                    <div>
                        <h4>Engine Options</h4>
                    </div>
                </Box>

                <div style={{ height: '393px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicSearch03.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Basic Search 3' ></img>
                </div>

                <p style={{ paddingTop: 25 }}>
                    In <MarkedRedRedText /> ({ImageNumberReference(1)}) you can see the Engine Options, click to see the options.
                    By default it's set to  Universe Quick Search.
                </p>

                <p>
                    This can be useful if you are only subscribed to a specific engine or if you are curious about what another engine contains.  You can select the desired engine on the dropdown
                    and it will change the engine.  Upon selection, the search results will update with the new engine and all of the options will be swaped out to engine specific ones.
                </p>

                <p>
                    Upon selecting US stocks for example (see image below), we can see that the options we used to have, are now different:
                </p>

                <div style={{ height: '546px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/basicSearch04.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Basic Search 4'></img>
                </div>

                <p style={{ paddingTop: 25 }}>
                    In addition, we can see that the results were also updated to reflect the new engine.  The search bar has also changed a bit, as we added a new drop down 'Search Field Type',
                    we will go over that dropdown in the advanced search section.
                </p>
            </div>
        </>
    )
}

export default BasicSearch