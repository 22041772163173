import React from "react"
import { ReactComponent as GraphDataIcon } from "../../Images/svg/GraphData.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const GraphData = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <GraphDataIcon />
  </SvgIcon>
)

export default GraphData;