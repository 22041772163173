import { Box, Link } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { pathParts } from "../../../../Models/DataModels/Common/RedirectionModel"
import { ImageNumberReference, MarkedRedRedText } from "../../HelpPageCommonComponents"

const SearchWithTools = () => {
    const navigate = useNavigate()

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>Using Tools to Find Series</h2>
                </div>

            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1
                }}>
                <div>
                    <h4>Fama-French Stock Screener</h4>
                </div>
            </Box>
            <div style={{ textAlign: 'left' }}>
                <p>
                    Various tools will allow one to see and view certain stocks that fall within the tool criteria.  For example, using a Fama-French Stock screener is a perfectly valid way for finding and selecting stocks or series.  You can then take actions on those stocks you find via the screener, just like you would after a search:
                </p>
                <div style={{ height: '753px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/searchTools01.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Using Tools to Find Series Help Image 1'></img>
                </div>
                <p style={{ paddingTop: 25 }}>
                    Above in <MarkedRedRedText /> ({ImageNumberReference(1)}) you can see the members of this particular screening report.  Once your screening report is completed, you can view and take actions on the constituents at any time. For more info about Fama French Stock Screener please see: <Link onClick={() => { navigate(pathParts.help.financialToolsSections.famaFrench) }}>FamaFrench Help</Link>.
                </p>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                        m: 1,
                        borderRadius: 1
                    }}>
                    <div>
                        <h4>Capitalization Tool</h4>
                    </div>
                </Box>
                <p>
                    Capitalization tool is a tool which lets a user visualize certain indexes and the constituents that make up those indexes.  This can be another way for someone to look through our data and find what they want.  The picture below shows the tool right before we go to the table view (at a minimum select an index and a date and then hit submit):
                </p>
                <div style={{ height: '583px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/searchTools02.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Using Tools to Find Series Help Image 2'></img>
                </div>

                <p style={{ paddingTop: 25 }}>
                    After hitting submit, the captitalization tool will go and find all the constituents in that index.  The slider provided (see picture below) lets one filter by different ranges of market cap. By looking at the index and providing a slider to capture only certain ranges of market caps, you can look at the constituents of many indexes and select based on range of market cap.  Once the desired range has been selected you can take actions on the various members of the table, like sending to autotrac to make a workbook for downloads.  For more info on captilization tool see : <Link onClick={() => { navigate(pathParts.help.financialToolsSections.capitalization) }}>Capitalization Help</Link>.
                </p>
                <div style={{ height: '600px' }}>
                    <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/searchTools03.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Using Tools to Find Series Help Image 3'></img>
                </div>

                <p style={{ paddingTop: 25 }}>
                    In conclusion, the tools can be a great way to find stocks that meet certain criteria or be used to help narrow down indexes to just series of interest.
                </p>

            </div>
        </>
    )
}

export default SearchWithTools