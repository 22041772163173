import React from "react"
import { ReactComponent as MyIndicesIcon } from "../../Images/svg/MyIndices.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const MyIndices = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <MyIndicesIcon />
  </SvgIcon>
)

export default MyIndices