
const FamaFrenchSideBar = () => {
  return (
    <>
        <a href="https://en.wikipedia.org/wiki/Fama%E2%80%93French_three-factor_model" target="_blank" rel="noopener noreferrer">Fama-French Wikipedia</a>

        <p style={{paddingTop: 5}}>Highly unusual markets will make finding profitable investments that outperform a traditional risk-free rate of return much more difficult.  A Fama-French model is a screening tool that takes three factors, expanded on the traditional Capital Asset Pricing Model to adjust for a portfolio's proportion of value and small-cap equities. </p>
        <p>Finaeon's Fama-French screener has been developed to provide a straightforward tool to analyze a portfolio of equities to match the expected return based on the intended financial risk.  Our rich financial history provides an exceptional foundation to measure the small-cap to large-cap return as well as the value to growth return. </p>
    </>
  )
}

export default FamaFrenchSideBar