import React, { useEffect, useState } from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts'
import { SearchResultData } from '../../../Models/DataModels/Common/ToolsModel'
import { Box, Grid } from '@mui/material'
import { getNextKellyColorSkipLight } from '../../../Services/ColorService'
import { AppConfigurations, chartSizes } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { graphCompanyLogoSVG, sourceTextSVG } from '../../Common/Modals/GraphUtil'
import { Payload } from 'recharts/types/component/DefaultLegendContent'

export interface SelectedSearchResultsPieChartProps {
    imageID: string
    chartTitle: string,
    searchData: SearchResultData[]
}

const SelectedSearchResultsPieChart = ({
    imageID,
    chartTitle,
    searchData
}: SelectedSearchResultsPieChartProps) => {

    const [pieData, setPieData] = useState<SearchResultData[]>([])
    const [inValidPieData, setInValidPieData] = useState<SearchResultData[]>([])

    const renderCustomizedLabelOnly = (props: any) => {
        const { x, y, width, height, cx, cy, midAngle, innerRadius, outerRadius, color, startAngle, endAngle, points, stroke, percent, index, fill } = props
        const labelText: string = `${pieData[index].ticker}: ${(percent * 100).toFixed(2)}%`
        return labelText
    }

    const CustomTooltip = ({ payload }: any) => {
        const entry: any | null = payload && payload.length > 0 ? payload[0].payload : null
        const toolTipLabel: string = `${entry?.ticker} - ${entry?.seriesName}`
        return (
            <div>
                <div className='ant-popover-arrow' />
                <div style={{ backgroundColor: '#FFF9' }}>
                    {`${toolTipLabel}: `}
                    <span className={'info'}>
                        <b>
                            <p className='desc'>
                                {entry?.closePrice}
                            </p>
                        </b>
                    </span>
                </div>
            </div>
        )
    }

    const legendData = (): Payload[] => {
        const payload: Payload[] = []
        pieData.forEach(
            (entry: SearchResultData, index: number) => {
                const nextColorCode: string = getNextKellyColorSkipLight(index + 1)
                payload.push(
                    {
                        id: entry.ticker,
                        type: 'square',
                        value: `${entry.ticker}: ${entry.closePrice}`,
                        color: nextColorCode
                    }
                )
            }
        )
        return payload
    }

    const renderErrorMessage = (): JSX.Element => {
        if (inValidPieData && inValidPieData.length > 0) {
            let errorMessage: string = ''
            if (inValidPieData.length === searchData.length) {
                errorMessage = 'All the selected series contains negative values which cannot be used to chart on pie chart.'
            } else {
                const excludedSeriesCommaSeparated: string = inValidPieData.map((value: SearchResultData) => value.ticker).join(', ')
                errorMessage = `Negative values cannot be used for pie chart. Following series were excluded: ${excludedSeriesCommaSeparated}`
            }
            return (
                <Grid container direction='row' justifyContent='center' alignItems='center'>
                    <Grid item sx={{ color: 'darkred', fontStyle: 'italic' }}>
                        {errorMessage}
                    </Grid>
                </Grid>
            )
        }
        return <></>
    }

    useEffect(() => {
        const validData: SearchResultData[] = []
        const invalidData: SearchResultData[] = []
        searchData.forEach((originalResultData: SearchResultData) => {
            if (originalResultData.closePrice > 0) {
                validData.push(originalResultData)
            } else {
                invalidData.push(originalResultData)
            }
        })
        if (invalidData.length > 0 && invalidData.length === searchData.length) {
            setPieData(invalidData)
        } else {
            setPieData(validData)
        }
        setInValidPieData(invalidData)
    }, [searchData])

    return (
        <Box
            id={imageID}
            marginBottom={'40px'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            style={{ backgroundColor: 'white' }}>

            <strong style={{ paddingLeft: '10%', fontSize: '20px' }}>{chartTitle}</strong>

            {renderErrorMessage()}

            <ResponsiveContainer width='100%' height={chartSizes.large} className='large-graph-container'>
                <PieChart>
                    <Pie
                        data={pieData}
                        dataKey='closePrice'
                        nameKey='ticker'
                        fill='#8884d8'
                        minAngle={6}
                        labelLine={false}
                        label={pieData.length <= AppConfigurations.chartLabelDisplayThreshold ? renderCustomizedLabelOnly : false}
                    >
                        {pieData.map((entry: any, index: number) => {
                            const nextColorCode: string = getNextKellyColorSkipLight(index + 1)
                            const ariaLabelValue: string = entry?.ticker || `Series-${index}`
                            return <Cell key={`cell-${index}`} fill={nextColorCode} aria-label={ariaLabelValue} />
                        })}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend payload={(legendData())} />
                    {graphCompanyLogoSVG()}
                    {sourceTextSVG()}
                </PieChart>
            </ResponsiveContainer>
        </Box>
    )
}

export default SelectedSearchResultsPieChart
