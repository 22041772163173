import React from 'react'
import { generateAPIDocsRedirectURL } from '../../Services/RedirectionService'
import { paths } from '../../Models/DataModels/Common/RedirectionModel'

const APIashx = () => {

  const finalRedirectURL: string = generateAPIDocsRedirectURL(paths.api.api)

  window.location.replace(finalRedirectURL)

  return (
    <div>
      Redirecting ...
    </div>
  )
}

export default APIashx
