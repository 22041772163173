import { Box } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../../HelpPageStyles'
import { ItalicizedWorkbookText } from '../../../HelpPageCommonComponents'
import AddToWorkbook from '../../../../Icons/AddtoWorkbookIcon'

const AddSeriesToWorkbook = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h4>Adding Series to the Workbook</h4>
                </div>
            </Box>
            <p>
                Series can be added to the <ItalicizedWorkbookText /> from various sections of the site.
                In addition, <ItalicizedWorkbookText />(s) can be created, added, or deleted as well.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Adding from Search Results</h5>
                </div>
            </Box>
            <div style={{ height: '602px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_AddingSeries_SearchResults.png' style={helpPageStyles.image} alt='Advanced AutoTrac Adding Series Search Results'></img>
            </div>
            <p>
                In the Search Results, you can select the series from the Search Results table. Selecting the series will display the toolbars. Clicking on <AddToWorkbook /> button will display the modal that
                lets you modify your <ItalicizedWorkbookText />s. To add the selected series to the <ItalicizedWorkbookText />, select the <ItalicizedWorkbookText /> and click on "Save" button.
            </p>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Adding from Selected Results</h5>
                </div>
            </Box>
            <div style={{ height: '255px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_AddingSeries_SelectedResults.png' style={helpPageStyles.image} alt='Advanced AutoTrac Adding Series Selected Results'></img>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Adding from Capitalization List View</h5>
                </div>
            </Box>
            <div style={{ height: '478px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_AddingSeries_Cap.png' style={helpPageStyles.image} alt='Advanced AutoTrac Adding Series Capitalization List View'></img>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Adding from Fama French</h5>
                </div>
            </Box>
            <div style={{ height: '832px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_AddingSeries_Fama.png' style={helpPageStyles.image} alt='Advanced AutoTrac Adding Series Fama French'></img>
            </div>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h5>Workbook Series Limit</h5>
                </div>
            </Box>
            <div style={{ height: '270px' }}>
                <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/autoTracAdvanced_AddingSeries_Limit.png' style={helpPageStyles.image} alt='Advanced AutoTrac Adding Limit'></img>
            </div>
            <p style={{ fontStyle: 'italic', color: 'darkred' }}>
                Note: A maximum of 300 series can be added to the workbook.
            </p>
        </div>
    )
}

export default AddSeriesToWorkbook
