import { Box } from '@mui/material'
import React from 'react'
import { ImageNumberReference, MarkedRedRedText } from '../../HelpPageCommonComponents'

const AdvancedSearch = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 1,
          m: 1,
          borderRadius: 1
        }}>
        <div>
          <h2 style={{ textDecoration: 'underline' }}>Advanced Search with Options</h2>
        </div>

      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 1,
          m: 1,
          borderRadius: 1
        }}>
        <div>
          <h4>Engine Based Search Options</h4>
        </div>
      </Box>
      <div style={{ textAlign: 'left' }}>

        <p>
          Finaeon provides several differnt engines to subscribe to.  Most have differnt sets of options which can be narrowed down to help filter the search results.
        </p>

        <div style={{ height: '282px' }}>
          <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedSearch01.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Search help image 1'></img>
        </div>
        <div style={{ height: '288px', paddingTop: '10px' }}>
          <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedSearch03.png' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Search help image 3'></img>
        </div>
        <p style={{ paddingTop: 25 }}>
          In <MarkedRedRedText /> ({ImageNumberReference(1)}) you can see the button for hiding and showing options.  This can be useful if you want to see more results or if you are done with selecting your options.  In <span style={{ color: 'blue', fontWeight: 'bold' }}>blue</span> ({ImageNumberReference(2)}) we can see the Country selection dropdown.  These dropdowns can work like normal dropdowns (scroll to the one you want) OR you can also type. And as you type, the drop down will get smaller as it filters the results.  In the screen above we can see that as I typed 'a' the menu shifted to show me words that start with a, use the dropdown normally from there to select your result. (<span style={{ fontWeight: 'bold' }}>Note: </span>Here you can see a couple that dont start with a, this is because if any word in the country name like "United Arab Emirates" starts with an A, it will include it in the list)
        </p>
        <p>
          We provide many filter options for each engine type.  Sometimes they are different.  In this case, our Universe has the following options:
        </p>
        <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Country Group</span> This will filter by country groups like BRICS or G7.</p>
        <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Country</span> Select a number of different countries.  Depending on your country group selection, this list can change.</p>
        <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Series Category</span> Series (symbol) Category, things like commodity, economics or other categories of series.</p>
        <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Series Type</span> Series (symbol) Type, examples would be agriculture, bonds, debt and other types of series.</p>
        <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Main Indicators</span> Main Indicators to see economic indicators, such as  "Annual Change in Central Government Debt"</p>
        <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>Start Year</span> Start Year of the seires, this can be the first point of data, or in the case of anything using GFDExtension (description will contain "GFDExtension"), the start for these series can be an extrapolated number based on the existing methodology and using available data resources from the period(s).</p>
        <p style={{ paddingLeft: 30 }}><span style={{ fontWeight: 'bold' }}>End Year</span> End year of the data, last point of data we have for that series, typically the end of the series historically as well.</p>
        <p style={{ paddingLeft: 30 }}>
          <span style={{ fontWeight: 'bold' }}>Only include series in years range (Checkbox)</span> This checkbox appears after either start or end year is populated. Checking this checkbox will only return series that are in the start and end years range.
          By default (Unchecked), any series active between the start and end years range will be displayed.
        </p>
        <p>
          In <span style={{ color: 'green', fontWeight: 'bold' }}>green</span> ({ImageNumberReference(1)}) you can see the filter term.  This can be useful if you have something with multiple categories.  Take for example oil in <MarkedRedRedText /> ({ImageNumberReference(2)} below), we have both vegetable oil and crude oil series in addition to a few others.  So in the main search bar I put "oil" but then I added "vegetable" to the filter term and get just vegetable oil related series.  If I changed this to "crude" we would only see crude oil series. See picture below:
        </p>
        <div style={{ height: '329px' }}>
          <img src='https://finaeonsiteimages.blob.core.windows.net/helpimages/advancedSearch02.PNG' style={{ height: 'auto', maxWidth: '100%', border: '5px solid #555' }} alt='Search help image 2'></img>
        </div>
        <p style={{ paddingTop: 25 }}>
          Once you have added your Filter Term, hit the search icon next to it and it will apply the filter to the whole list.  If you clear the filter term it will return to the original list.
        </p>
        <p>
          In conclusion, the search options are a great way to find exactly what you are looking for.  Utilizing them will narrow the hundreds of thousands of records to a much more managable number, allowing for a better search experience.
        </p>
      </div>
    </>

  )
}

export default AdvancedSearch