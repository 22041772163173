import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useEffect, useState } from 'react'
import AutoTracTickerSearch, { TickerSearchProps } from '../../../../Common/Utility/AutoTracTickerSearch'
import { SearchOptionsData } from '../../../../../Models/DataModels/Common/SearchModels'
import AddTicker from '../../../../Icons/AddTickerIcon'

export interface MemberSearchProps {
  addMemberToCustomIndex: (tickerName: string) => void,
  resetSearchTermToggle: boolean
}

const MemberSearch = ({ 
  addMemberToCustomIndex,
  resetSearchTermToggle
}: MemberSearchProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [addTickerIconColorIsPrimary, setAddTickerIconColorIsPrimary] = useState<boolean>(false)

  useEffect(() => {
    if (searchTerm && searchTerm?.indexOf('~') !== -1) {
      setAddTickerIconColorIsPrimary(true)
    } else {
      setAddTickerIconColorIsPrimary(false)
    }
  }, [searchTerm])

  const onAddTickerToCustomIndex = () => {
    addMemberToCustomIndex(searchTerm)
  }

  const onSetSearchTerm = (searchTerm: string, searchOptions: SearchOptionsData) => {
    setSearchTerm(searchTerm)
  }

  const tickerSearchTickerProps: TickerSearchProps = {
    onSetSearchTerm,
    onAcceptSearchTerm: addMemberToCustomIndex,
    resetSearchTermToggle
  }

  return (
    <div
      style={{ padding: '8px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
    >
      <Tooltip title='Add Ticker to Custom Index'>
        <IconButton 
          color={addTickerIconColorIsPrimary ? 'primary' : 'default'}
          sx={{ p: '10px' }}
          aria-label='add ticker'
          onClick={onAddTickerToCustomIndex}>
            <AddTicker style={{fontSize: '1.2em'}}></AddTicker>
        </IconButton>
      </Tooltip>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

      <AutoTracTickerSearch {...tickerSearchTickerProps} />
    </div>
  )
}

export default MemberSearch
