import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { CapitalizationData } from '../../../../../../Models/DataModels/Common/ToolsModel'
import { CSSProperties } from '@mui/material/styles/createTypography'

export interface DenseMCapTableProps {
    title: string,
    rows: CapitalizationData[],
    titleBackgroundColor?: string
}

const DenseMCapTable = ({
    title,
    rows,
    titleBackgroundColor
}: DenseMCapTableProps) => {

    const Styles = {
        chip: {
            color: '#404040',
            fontWeight: 'bold',
            fontSize: 20,
            width: '100%',
            backgroundColor: titleBackgroundColor ?? 'teal',
            borderRadius: 2
        },
        denseTable: {
            minWidth: 550, maxWidth: 550, minHeight: 300, maxHeight: 300
        }
    }

    return (
        <Paper elevation={6}>
            <Chip label={title} style={Styles.chip as CSSProperties} />
            <TableContainer component={Paper}>
                <Table sx={Styles.denseTable as CSSProperties} size='small' aria-label='a dense table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Symbol</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>MCap</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.symbol}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component='th' scope='row'>
                                    {row.symbol}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default DenseMCapTable
