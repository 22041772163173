import { useState } from 'react'
import AlertModal, { AlertButtonType, AlertModalProps } from './AlertModal'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import CustomIndexSelection, { CustomIndexSelectionProps } from '../../FinancialTools/FinancialToolsSubComponents/ToolPanelSubComponents/CustomIndices/CustomIndexSelection'
import { CustomIndexMember } from '../../../Models/DataModels/Responses/ToolsResponses'

export interface CustomIndexSelectionModalProps {
  membersToBeAdded: Map<string, CustomIndexMember>,
  showModal: boolean,
  setShowModal: (show: boolean) => void,
  signOut: (logoutReason: LogoutReasonType) => void,
  clearMessagesToggle: boolean,
  clearMessages: () => void
}

const CustomIndexSelectionModal = ({
  membersToBeAdded,
  showModal,
  setShowModal,
  signOut
}: CustomIndexSelectionModalProps) => {
  
  const body = () => {
    const customIndexSelectionProps: CustomIndexSelectionProps = {
      onClose: () => setShowModal(false),
      membersToBeAdded,
      signOut
    }
    
    return (<div style={Styles.Selection}>
      <CustomIndexSelection {...customIndexSelectionProps}/>
    </div>)
  }
  
  const saveModalProps: AlertModalProps = {
    showModal,
    setShowModal,
    AlertTitle: 'Add Stocks To Custom Index',
    AlertContent: body(),
    Size: 'xl',
    onAlertClose: () => {
      // onFinal(false, selected)
      return true
    }
  }

  return (
    <AlertModal {...saveModalProps}/>
  )
}

const Styles = { 
  Selection: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    flexBasis: '1',
    overflow: 'auto',
  }
}

export default CustomIndexSelectionModal
